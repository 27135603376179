import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DatePipe } from '@angular/common';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';

import { City } from '../../../../classes/city';
import { CityService } from '../../../../services/crud/city.service';

import { BaseFormComponent } from '../../../base/base-form/base-form.component';
import { RESTResponseVO } from '../../../../classes/restresponsevo';
import { Vehicle } from '../../../../classes/vehicle';
import { VehicleType } from '../../../../classes/vehicle-type';
import { VehicleTypeService } from '../../../../services/crud/vehicle-type.service';
import { VehicleService } from '../../../../services/crud/vehicle.service';
import { CountryState } from '../../../../classes/country-state';
import { AuthGuard } from '../../../../guards/auth.guard';

@Component({
    selector: 'app-company-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css'],
    providers: [DatePipe]
})
export class VehicleFormComponent extends BaseFormComponent<Vehicle> {
    constructor(private vehicleService: VehicleService,
        private cityService: CityService,
        private vehicleTypeService: VehicleTypeService,
        private datePipe: DatePipe,
        private authGuard: AuthGuard,
        route: ActivatedRoute,
        router: Router) { super(route, router, 'adm/vehicle/list'); }

    assembleObjectFromForm(): void {
    }

    loadAuxiliaryLists(): void {
        this.stateList = new Array();
        this.originalCityList = new Array();
        var ids = [];
        this.cityService.getList().then(response => {
            this.originalCityList = response.response;
            this.originalCityList.forEach((city) => {
                if (!ids.includes(city.ufCodIbge.sigla)) {
                    this.stateList.push(city.ufCodIbge);
                    ids.push(city.ufCodIbge.sigla);
                }
            });

            this.stateList.sort((s1, s2) => s1.sigla.localeCompare(s2.sigla));
        });
        this.vehicleTypeService.getList().then(response => this.typeList = response.response);
    }

    loadObject(): void {
        if (this.activeID && (this.activeID !== 0)) {
            this.vehicleService.getById(this.activeID).then(response => {
                var resultItem: Vehicle = response.response;
                this.activeObject = resultItem;
            });
        }
        this.loading = false;
    }

    initObject(): void {
        this.activeObject = new Vehicle();
        this.activeObject.empresaId = this.authGuard.loggedUser().selectedCompany.id;
        this.loading = false;
    }

    doCreate(t: Vehicle): Promise<RESTResponseVO<Vehicle>> {
        return this.vehicleService.create(t);
    }

    doEdit(t: Vehicle): Promise<RESTResponseVO<Vehicle>> {
        return this.vehicleService.update(t);
    }

    doDelete(t: Vehicle): Promise<RESTResponseVO<string>> {
        return this.vehicleService.delete(t.placa);
    }

    // City
    originalCityList: City[];
    stateList: CountryState[];
    countryState: CountryState;

    get cityList() {
        if (this.countryState) {
            return this.originalCityList.filter((c) => c.ufCodIbge.sigla === this.countryState.sigla);
        } else {
            return [];
        }
    }

    cityFormatter = (x: City) => x.nome;

    searchCity = (text$: Observable<string>) =>
        text$
            .debounceTime(200)
            .distinctUntilChanged()
            .map(term => term.length < 2 ? [] : this.cityList.filter(v => v.nome.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10));

    cityTemplate(r) {
        return r.ufCodIbge.sigla + " - " + r.nome;
    }

    stateTemplate(s) {
        return s.sigla + " - " + s.nome;
    }

    compareWith(c1: any, c2: any): boolean {
        return c1 && c2 ? c1.codIbge === c2.codIbge : c1 === c2;
    }

    stateCompare(s1: any, s2: any): boolean {
        return s1 && s2 ? s1.sigla === s2.sigla : s1 === s2;
    }

    // Type
    typeList: VehicleType[];

    typeFormatter = (x: VehicleType) => x.descricao;

    searchType = (text$: Observable<string>) =>
        text$
            .debounceTime(200)
            .distinctUntilChanged()
            .map(term => term.length < 2 ? [] : this.typeList.filter(v => v.descricao.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10));

    typeTemplate(r) {
        return r.descricao;
    }

    setActiveID(): void {
        this.activeID = this.activeObject.id;
    }

    cityCompare(c1, c2): boolean {
        return c1 && c2 ? c1.codIbge === c2.codIbge : c1 === c2;
    }
}
