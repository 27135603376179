import { BaseListComponent } from "../../../base/base-list/base-list.component";
import { Component, ViewEncapsulation } from "@angular/core";
import { ModeloVeiculoService } from "../../../../services/crud/modelo-veiculo.service";
import { UtilService } from "../../../../services/utils.service";
import { PDFService } from "../../../../services/pdf.service";
import { AuthGuard } from "../../../../guards/auth.guard";
 

@Component({
  templateUrl: '../../../base/base-list/base-list.component.html',
  styleUrls: [ '../../../base/base-list/base-list.component.css' ],
  encapsulation: ViewEncapsulation.None
})

export class ModeloVeiculoListComponent extends BaseListComponent {
  constructor(private  modeloVeiculoService:  ModeloVeiculoService,
      private utilService: UtilService,
      pdfService: PDFService,
      authGuard: AuthGuard) {
      super(utilService, pdfService, authGuard);
      this.searchField = "marca";
  }

  ngOnInit() {
      this.pageTitle = "Cadastros | Modelo de Veículo";
      this.formPath = "/adm/modelo-veiculo/form";

      this.tableHeaders = [
          [
              this.createHeader("ID", 0, true),
              this.createHeader("Marca", 1, true),
              this.createHeader("Fabricante", 2, true)
          ]
      ];

      this.dataFields = [
          this.createField("id", "string"),
          this.createField("fabricante", "string"),
          this.createField("marca", "string")
      ];

      this.modeloVeiculoService.getLista().then(response => {
          this.data = response.listaDeModelo;
          this.loading = false;
      });
  }
}
