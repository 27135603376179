import * as Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { Injectable } from '@angular/core';

NoDataToDisplay(Highcharts);
@Injectable()
export class HighchartsService {
    constructor() {
    }

    createChart(el, cfg) {
      Highcharts.chart(el, cfg);
    }
}
