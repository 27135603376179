import { Component, Input} from '@angular/core';
import { MatDialog } from '@angular/material';
import { InformacoesRegras } from 'app/classes/informacoes-regras';
import { InformacaoRegraAbrirBau } from 'app/classes/informcoes-regra-abrir-bau';
import { InformacaoRegraBauAbertoForaCerca } from 'app/classes/informcoes-regra-bau-aberto-fora_cerca';
import { InformacaoRegraFecharBau } from 'app/classes/informcoes-regra-fechar-bau';
import { InformacaoRegraSairLoja } from 'app/classes/informcoes-regra-sair-loja';
import { DetalhesAberturaBauForaCercaComponent } from '../detalhes-abertura-bau-fora-cerca/detalhes-abertura-bau-fora-cerca';

@Component({
  selector: 'ocorrencias-carga-descarga',
  templateUrl: './ocorrencias-carga-descarga.html',
  styleUrls: ['./ocorrencias-carga-descarga.scss']
})
export class OcorrenciasCargaDescarga {
  valorDefault: InformacoesRegras = { ocorrencias: 0, lojas: 0, tempoAtraso: 0 };

  @Input()
  atrasoParaInicioDescarga: InformacaoRegraAbrirBau;

  @Input()
  atrasoParaFimDescarga: InformacaoRegraFecharBau;

  @Input()
  atrasoParaSaidaDoLocal: InformacaoRegraSairLoja;

  @Input()
  aberturaForaCerca: InformacaoRegraBauAbertoForaCerca;

  constructor(public dialog: MatDialog) {}

  exibirDetalheAberturaForaDaCerca() {
    this.dialog.open(DetalhesAberturaBauForaCercaComponent, { data: { detalhesAberturaForaCerca: this.aberturaForaCerca.listaAberturasBauForaCerca }, panelClass: 'indicadores-dialog' });
  }
}
