import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSort, MatTableDataSource, MatDatepicker, MatSelect, MatOption } from '@angular/material';
import { Filtro } from 'app/classes/filtro';
import { AuthGuard } from 'app/guards/auth.guard';
import { LocalService } from 'app/services/crud/local.service';
import { RankingMotoristaService } from 'app/services/crud/ranking-motorista.service';
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { ReportService } from 'app/services/report/report.service';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { utils, writeFile } from 'xlsx';
import { PDFService } from 'app/services/pdf.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { BranchService } from 'app/services/crud/branch.service';
import { GrupoLocaisService } from 'app/services/crud/grupo-locais.service';
import { ControleIndicadoresService } from 'app/services/crud/controle-indicadores.service';
import { ModalAberturaBauForaLocalCadastradoComponent } from './modal-abertura-bau-fora-local-cadastrado-component';
import { ModalTodosLocaisPeriodoComponent } from './modal-todos-locais-periodo-component';
import { ModalDetalhesLocalComponent } from './modal-detalhes-local-component';
import { ModalDetalhesOcorrenciaComponent } from './modal-detalhes-ocorrencia-component';

@Component({
  selector: "app-tela-indicador-gestao",
  templateUrl: "./tela-indicador-gestao.component.html",
  styleUrls: ["./tela-indicador-gestao.component.css"],
})
export class TelaIndicadorGestaoComponent implements OnInit {
  isLoading: boolean = false;
  formGroup: FormGroup;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('selectVeiculos') selectVeiculos: MatSelect;
  valueLocais: number = 0;
  valueOcorrencias: number = 0;
  ocorrenciasAberturaBau: number = 0;
  lojasAberturaBau: number = 0;
  tempoTotalAberturaBau: string = '0';
  ocorrenciasFechacaoBau: number = 0;
  lojasFechacaoBau: number = 0;
  tempoTotalFechacaoBau: string = '0';
  ocorrenciasSaidaLoja: number = 0;
  lojasSaidaLoja: number = 0;
  tempoTotalSaidaLoja: string = '0';
  ocorrenciasAberturaBauForaLocalCadastrado: number = 0;
  listaAberturasBauForaCerca: any;
  listaAtrasoPorLocal: any;
  listaPrincipalAtrasos: any;
  listaOcorrenciasAberturaBau: any;
  listaOcorrenciasFecharBau: any;
  listaOcorrenciasSairLoja: any;
  gruposLocais: any[] = [];
  unidades: any[] = [];
  veiculos: any[] = [];
  locais: any[] = [];
  xpandStatus = true;
  public gruposLocais2: any;
  public filteredList3: any;
  public unidades2: any;
  public filteredList4: any;
  public veiculos2: any;
  public filteredList5: any;
  public locais2: any;
  public filteredList6: any;
  minDate: any;
  maxDate: any;
  disabled: any;
  showSpinners: any;
  showSeconds: any;
  stepHour: any;
  stepMinute: any;
  stepSecond: any;
  hidden: boolean = true;

  displayedColumns: string[] = [
    'local',
    'ocorrencias',
    'atraso',
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public filtro: Filtro;
  contentToExport: any;
  pageOrientation: string = 'portrait';

  constructor(
    private formBuilder: FormBuilder,
    private veiculoService: VeiculoService,
    private localService: LocalService,
    private reportService: ReportService,
    private authGuard: AuthGuard,
    private modalService: NgbModal,
    private pdfService: PDFService,
    private branchService: BranchService,
    private grupoLocaisService: GrupoLocaisService,
    private controleIndicadoresService: ControleIndicadoresService,
  ) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      unidades: [[]],
      dataInicial: [null, [Validators.required]],
      dataFinal: [null, [Validators.required]],
      veiculos: [[]],
      locais: [[]],
      gruposLocais: [[]],
    });
    this.listarGruposLocais();
    this.listarUnidades();
    this.listarVeiculos();
    this.listarLocais();
  }

  filterVeiculos(filtro: any) {
    return filtro;
  }

  abrirModal(row: any, type: string) {
    if (type === 'ocorrenciasAberturaBauForaLocalCadastrado') {
      const modalRef = this.modalService.open(ModalAberturaBauForaLocalCadastradoComponent, { size: <any>'mysize', backdrop: 'static', keyboard: false, windowClass: 'vehicle-modal' });
      modalRef.componentInstance.dados = this.listaAberturasBauForaCerca;
      modalRef.result.then((result) => {
        if (result) {
        }
      }).catch((res) => { });
      return
    }
    if (type === 'ocorrenciasAtrasoPorLocal') {
      const modalRef = this.modalService.open(ModalTodosLocaisPeriodoComponent, { size: <any>'mysize', backdrop: 'static', keyboard: false, windowClass: 'vehicle-modal' });
      modalRef.componentInstance.dados = this.listaAtrasoPorLocal;
      modalRef.result.then((result) => {
        if (result) {
        }
      }).catch((res) => { });
      return
    }
    if (type === 'ocorrenciasDetalhesDoLocal') {
      const modalRef = this.modalService.open(ModalDetalhesLocalComponent, { size: <any>'mysize', backdrop: 'static', keyboard: false, windowClass: 'vehicle-modal' });
      modalRef.componentInstance.dados = this.listaPrincipalAtrasos.filter((ocorrencia) => ocorrencia.idLocal === row.idLocal);
      modalRef.result.then((result) => {
        if (result) {
        }
      }).catch((res) => { });
      return
    }
    if (type === 'ocorrenciasAbrirBau') {
      const modalRef = this.modalService.open(ModalDetalhesOcorrenciaComponent, { size: <any>'mysize', backdrop: 'static', keyboard: false, windowClass: 'vehicle-modal' });
      modalRef.componentInstance.dados = this.listaOcorrenciasAberturaBau;
      modalRef.componentInstance.tipo = 'ocorrenciasAbrirBau';
      modalRef.result.then((result) => {
        if (result) {
        }
      }).catch((res) => { });
      return
    }
    if (type === 'ocorrenciasFechacaoBau') {
      const modalRef = this.modalService.open(ModalDetalhesOcorrenciaComponent, { size: <any>'mysize', backdrop: 'static', keyboard: false, windowClass: 'vehicle-modal' });
      modalRef.componentInstance.dados = this.listaOcorrenciasFecharBau;
      modalRef.componentInstance.tipo = 'ocorrenciasFechacaoBau';
      modalRef.result.then((result) => {
        if (result) {
        }
      }).catch((res) => { });
      return
    }
    if (type === 'ocorrenciasSaidaLoja') {
      const modalRef = this.modalService.open(ModalDetalhesOcorrenciaComponent, { size: <any>'mysize', backdrop: 'static', keyboard: false, windowClass: 'vehicle-modal' });
      modalRef.componentInstance.dados = this.listaOcorrenciasSairLoja;
      modalRef.componentInstance.tipo = 'ocorrenciasSaidaLoja';
      modalRef.result.then((result) => {
        if (result) {
        }
      }).catch((res) => { });
      return
    }
    return
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  listarLocais() {
    this.isLoading = true;
    this.localService.getListaLocaisSemCoordenadas().then(response => {
      this.locais = response.listaDeLocal;
      this.isLoading = false;
      this.locais2 = response.listaDeLocal;
      this.filteredList6 = this.locais2.slice()
    });
  }

  listarGruposLocais() {
    this.grupoLocaisService.getLista().then(response => {
      this.gruposLocais = response.listaDeGrupoLocais;
      this.gruposLocais2 = response.listaDeGrupoLocais;
      this.filteredList3 = this.gruposLocais2.slice()
    })
  }

  listarUnidades() {
    this.branchService.getList().then(response => {
      this.unidades = response.response;
      this.unidades2 = response.response;
      this.filteredList4 = this.unidades2.slice();
    })
  }

  listarVeiculos() {
    this.veiculoService.getLista().then(response => {
      this.veiculos = response.listaDeVeiculos;
      this.veiculos2 = response.listaDeVeiculos;
      this.filteredList5 = this.veiculos2.slice();
    });
  }

  selecionarTodos(matSelect: MatSelect) {
    const isSelected: boolean = matSelect.options
      .filter((item: MatOption) => item.value === 0)
      .map((item: MatOption) => item.selected)
    [0];

    if (isSelected) {
      matSelect.options.forEach((item: MatOption) => item.select());
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  getHours(seconds) {
    var date = new Date(null);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 8);
  }

  doSearch() {
    this.hidden = true;
    if (this.formGroup.value.unidades.length > 0) {
      this.formGroup.value.unidades = this.formGroup.value.unidades.filter(i => i !== 0);
    }
    if (this.formGroup.value.gruposLocais.length > 0) {
      this.formGroup.value.gruposLocais = this.formGroup.value.gruposLocais.filter(i => i !== 0);
    }
    if (this.formGroup.value.locais.length > 0) {
      this.formGroup.value.locais = this.formGroup.value.locais.filter(i => i !== 0);
    }
    if (this.formGroup.value.veiculos.length > 0) {
      this.formGroup.value.veiculos = this.formGroup.value.veiculos.filter(i => i !== 0);
    }
    const searchBody = {
      idUnidade: this.formGroup.value.unidades,
      dataInicio: moment(this.formGroup.value.dataInicial).toISOString(),
      datafim: moment(this.formGroup.value.dataFinal).toISOString(),
      idGrupoDeLocais: this.formGroup.value.gruposLocais,
      idLocal: this.formGroup.value.locais,
      idVeiculo: this.formGroup.value.veiculos,
    }
    this.isLoading = true;
    this.controleIndicadoresService.buscarIndicadoresFiltrados(searchBody).then((response) => {
      if (response) {
        this.hidden = false;
        const listaResumo = response.listaResumo;
        const result = response;
        const dataTable = result.listaTopDez.map((ocorrencia) => ({
          idLocal: ocorrencia.idLocal,
          local: ocorrencia.nomeLocal,
          ocorrencias: ocorrencia.totalOcorrencias,
          atraso: this.getHours(ocorrencia.totalAtrasoSegundos)
        }))
        this.dataSource = new MatTableDataSource(dataTable)
        this.dataSource.sort = this.sort;
        this.valueLocais = listaResumo.length;
        this.valueOcorrencias = listaResumo.reduce((prev, curr) => {
          return prev + curr.totalOcorrencias;
        }, 0);
        this.ocorrenciasAberturaBau = result.informacaoRegraAbrirBau.ocorrencias;
        this.lojasAberturaBau = result.informacaoRegraAbrirBau.lojas;
        this.tempoTotalAberturaBau = this.getHours(result.informacaoRegraAbrirBau.tempoAtraso)
        this.ocorrenciasFechacaoBau = result.informacaoRegraFecharBau.ocorrencias;
        this.lojasFechacaoBau = result.informacaoRegraFecharBau.lojas;
        this.tempoTotalFechacaoBau = this.getHours(result.informacaoRegraFecharBau.tempoAtraso)
        this.ocorrenciasSaidaLoja = result.informacaoRegraSairLoja.ocorrencias;
        this.lojasSaidaLoja = result.informacaoRegraSairLoja.lojas;
        this.tempoTotalSaidaLoja = this.getHours(result.informacaoRegraSairLoja.tempoAtraso)
        this.ocorrenciasAberturaBauForaLocalCadastrado = result.informacaoRegraAberturaForaCerca.listaAberturasBauForaCerca.length;
        this.listaAberturasBauForaCerca = result.informacaoRegraAberturaForaCerca.listaAberturasBauForaCerca;
        this.listaAtrasoPorLocal = [[...result.listaResumo], [...result.listaPrincipal]];
        this.listaPrincipalAtrasos = result.listaPrincipal;
        this.listaOcorrenciasAberturaBau = result.informacaoRegraAbrirBau.listaOcorrencias;
        this.listaOcorrenciasFecharBau = result.informacaoRegraFecharBau.listaOcorrencias;
        this.listaOcorrenciasSairLoja = result.informacaoRegraSairLoja.listaOcorrencias;
        return this.isLoading = false;
      }
      this.isLoading = false;
      console.log('Ocorreu um erro ao recuperar os dados, favor contactar o suporte')
    })
  }
}