export class ItemEficienciaTelemetria {
  constructor() {
    this.porcentagem = "0%"
    this.eventos = 0;
    this.tempoTotal = "00:00";
    this.listaDeDetalhesEficiencia = [];
  }

  porcentagem: string;
  eventos: number;
  tempoTotal: string;
  listaDeDetalhesEficiencia: null | Array<ListaDeDetalhesEficiencia>;
}

export type ListaDeDetalhesEficiencia = {
  idPlaca: string;
  nomeModelo: string;
  totalEventos: number;
  tempoTotal: string;
  listaIdTelemetria: number[];
};
