import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { catchError, debounceTime, distinctUntilChanged, finalize, switchMap, tap, map } from 'rxjs/operators';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs';
import { DashboardHomeService } from 'app/services/dashboard/dashboard.service';
import { AuthGuard } from 'app/guards/auth.guard';
import Swal from 'sweetalert2';
import { ValidadorService } from 'app/services/validador.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-map-details',
  templateUrl: './map-details.component.html',
  styleUrls: ['./map-details.component.scss']
})
export class MapDetailsComponent implements OnInit {

  @Input() public dados: any;
  dadosCarregados = false;
  titulo: string;
  erromessage = '';
  erromessageAba8 = '';
  formGroup: FormGroup;
  formGroupBloqueio: FormGroup;
  formGroupTrajeto: FormGroup;
  formGroupObservacoes: FormGroup;
  inSpinner = false;
  mensagem = '';
  errorMsg: string;
  isLoadingResult: boolean;
  active = 1;
  detalhes: any = {};
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  mapMarkers: any[] = [];
  listaDeInconformidades: any[] = [];
  listaDeAlertas: any[] = [];
  lng: any;
  lat: any;
  rotas: any[] = [];
  observacoes: any[] = [];
  eventos: any[] = [];
  rotaSelecionada: any;
  rotaLats = -20.2821696531275;
  rotaLngs = -45.5421109031886;
  abrirJustificativa = false;
  mensagemErroBloqueio = '';
  isLoading = false;
  corButtonComandoDesbloqueio = '#007bff';
  corButtonComandoBloqueio = '#007bff';
  isHidden = false;

  constructor(
    public activeModal: NgbActiveModal,
    private authGuard: AuthGuard,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    public validadorService: ValidadorService,
    private spinner: NgxSpinnerService,
    public formatter: NgbDateParserFormatter,
    private dashboardHomeService: DashboardHomeService
  ) {
    this.formGroup = this.formBuilder.group({
      motorista: false,
      frota: false,
      locais: false,
      localModel: [null],
      motoristaModel: [null],
      frotaModel: [null]
    });

    this.formGroupTrajeto = this.formBuilder.group({
      dataInicial: [null, Validators.required],
      dataFinal: [null, Validators.required]
    });

    this.formGroupBloqueio = this.formBuilder.group({
      justificativa: [null, Validators.required],
      comando: [1, Validators.required]
    });

    this.formGroupObservacoes = this.formBuilder.group({
      observacao: [null, Validators.required],
      id: null
    });
  }

  ngOnInit(): void {
    console.log(' =======> ', this.dados);
    if (!!this.dados) {
      this.titulo = this.dados.placa;
      this.obterDadosDetalhes(this.dados.idObjeto);
    }
  }


  onUpdateLoader(event) {
    this.isLoading = event;
  }

  salvarObservacao() {
    if (this.formGroupObservacoes.valid) {
      this.erromessageAba8 = '';
      try {
        let obs = {};
        const vei = {
          id: this.dados.id
        };
        obs = {
          veiculo: vei,
          texto: this.formGroupObservacoes.value,
          tipo: 'VIASAT'
        };
        const json = JSON.stringify(obs);
        this.dashboardHomeService.salvarObservacao(json).then(response => {
          this.observacoes.push(this.formGroupObservacoes.value);
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      this.erromessageAba8 = 'Você deve preencher o campo observação para salvar.';
    }
  }

  montarRotaNoMapa(id) {

  }

  deletarRotaVeiculo(id) {
    try {
      this.dashboardHomeService.excluirRotaVeiculo(id, this.dados.id).then(response => {
        this.rotas = [];
        this.rotas = response.listaDeRota;
      });
    } catch (error) {
      console.error(error);
    }
  }

  enviarComandoBloquear(comando) {
    // 1 = bloqueio / 2 = desbloqueio
    this.abrirJustificativa = true;
    this.formGroupBloqueio.patchValue({
      comando: comando
    });
  }

  salvarComando() {
    if (this.formGroupBloqueio.invalid) {
      this.isLoading = false;
      this.validadorService.validarTodosCampos(this.formGroupBloqueio);
      Object.keys(this.formGroup.controls).forEach(key => {
        this.formGroup.get(key).markAsTouched();
      });
      this.mensagemErroBloqueio = 'Justificativa é um campo obrigatório.';
    } else {
      try {
        this.mensagemErroBloqueio = '';
        const loggedUser = this.authGuard.loggedUser();
        const model = {
          idComando: this.formGroupBloqueio.value.comando,
          idUsuarioLogado: loggedUser.id,
          idVeiculo: this.dados.idObjeto,
          justificativa: this.formGroupBloqueio.value.justificativa
        };
        this.isLoading = true;

        this.dashboardHomeService.enviarComandoBloqueio(model).then(response => {
          if (response.codigoRetorno === 1) {

            const emb = `${this.formGroupBloqueio.value.comando === 1 ?
              // tslint:disable-next-line:max-line-length
              'A Rodovia Soluções não se responsabiliza na ativação desse comando em um veículo em movimento. Bloqueio realizado com sucesso!'
              : 'Desbloqueio realizado com sucesso!'}`;
            // tslint:disable-next-line:max-line-length
            this.formGroupBloqueio.value.comando === 1 ? this.corButtonComandoBloqueio = '#39b54a' : this.corButtonComandoDesbloqueio = '#39b54a';
            Swal.fire({
              position: 'top-end',
              type: 'success',
              title: `${this.formGroupBloqueio.value.comando === 1 ? 'Atenção!' : 'Sucesso!'}`,
              text: emb,
              showConfirmButton: false,
              timer: 4000
            });
            this.detalhes.detalheDashboard.bloqueado = this.formGroupBloqueio.value.comando === 1;
          }
          this.isLoading = false;
        }).catch((res) => {
          this.isLoading = false;
          // tslint:disable-next-line:max-line-length
          this.formGroupBloqueio.value.comando === 1 ? this.corButtonComandoBloqueio = '#e53935' : this.corButtonComandoDesbloqueio = '#e53935';
          const em = `${this.formGroupBloqueio.value.comando === 1 ? 'Bloqueio' : 'Desbloqueio'}`;
          Swal.fire({
            type: 'error',
            title: 'Ops...',
            text: `${em} não realizado, contatar o suporte.`,
            showConfirmButton: true,
            confirmButtonText: 'Ok',
          });
        });

      } catch (ex) {

      }
    }
  }


  enviarComandos(comando) {
    try {
      this.dashboardHomeService.executarComando(comando, this.dados.id).then(retorno => {
        if (retorno.response.codigoRetorno === 1) {
          Swal.fire({
            position: 'top-end',
            type: 'success',
            title: 'Sucesso.',
            text: 'Comando enviado com sucesso!',
            showConfirmButton: false,
            timer: 2500
          });
        } else {
          Swal.fire({
            type: 'error',
            title: 'Ops...',
            text: 'Erro ao enviar comando!',
            showConfirmButton: true,
            confirmButtonText: 'Ok',
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  getTextoLocalization(endereco) {
    // if (this.dados.estaEmUmaCerca) {
    return `${endereco} | ${this.dados.cercaAtual}`;
    // } else {
    //   return endereco;
    // }
  }

  obterDadosDetalhes(id) {
    try {
      if (id === null || id === undefined) {
        this.isLoading = false;
        this.dadosCarregados = false;
      } else {
        const loggedUser = this.authGuard.loggedUser();
        this.isLoading = true;
        this.dashboardHomeService.getDadosDetalhesVeiculo(id).then(response => {
          console.log('=============>', response);
          this.detalhes = response;
          this.isLoading = false;
          this.observacoes = response.detalheDashboard.listaObservacao === null ? [] : response.detalheDashboard.listaObservacao;
          this.eventos = response.detalheDashboard.listaEvento === null ? [] : response.detalheDashboard.listaEvento;
          if (response.detalheDashboard.latLong !== null) {
            const split = response.detalheDashboard.latLong.split('/');
            this.lng = parseFloat(split[1]);
            this.lat = parseFloat(split[0]);
            this.mapMarkers.push({
              latitude: parseFloat(split[0]),
              longitude: parseFloat(split[1]),
              descricao: ''
            });
          }
          this.dadosCarregados = true;
        });
      }
    } catch (error) {
      this.isLoading = false;
      console.error(error);
    }
  }

  onMapReady(mapa) {
    console.log('map >>>>>>>>>>>>>>>>> ', mapa);
  }

  markerDragEnd(mapMarker, event) {
    console.log('mapMarker >>>>>>>>>>>>>>>>> ', mapMarker);
    console.log('event >>>>>>>>>>>>>>>>> ', event);
  }

  onMarkerClickEvent(event, i) {
    console.log('i >>>>>>>>>>>>>>>>> ', i);
    console.log('event >>>>>>>>>>>>>>>>> ', event);
  }

  marcarComoLido(id) {
    const loggedUser = this.authGuard.loggedUser();
    try {
      this.dashboardHomeService.marcarComoLido(id, loggedUser.id).then(response => {

      });
    } catch (error) {
      console.error(error);
    }
  }

  filtrar() {
    if (this.formGroupTrajeto.valid) {
      this.erromessage = '';
      const year1 = this.formGroupTrajeto.value.dataInicial.year;
      const date1 = this.formGroupTrajeto.value.dataInicial.day;
      const month1 = this.formGroupTrajeto.value.dataInicial.month;
      const newDate1 = new Date(year1, month1 - 1, date1);

      const year2 = this.formGroupTrajeto.value.dataFinal.year;
      const date2 = this.formGroupTrajeto.value.dataFinal.day;
      const month2 = this.formGroupTrajeto.value.dataFinal.month;
      const newDate2 = new Date(year2, month2 - 1, date2);

      const dateInicial = newDate1.getTime();
      const dateFinal = newDate2.getTime();
      const diferenca = dateFinal - dateInicial;
      if (diferenca > 2592000000) {
        Swal.fire({
          type: 'info',
          title: 'Ops...',
          text: 'Período não permitido. Acima de 31 dias.',
          showConfirmButton: true,
          confirmButtonText: 'Ok',
        });
      } else {
        try {
          this.dashboardHomeService.buscarTrajettoPorPeriodo(this.dados.id, dateInicial, dateFinal).then(response => {
            if (response.codigoRetorno === 85) {
              if (response.listaDeTrajeto.length !== 0) {
                this.mapMarkers = response.listaDeTrajeto;
              }
            }

          });
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      this.erromessage = 'Você deve selecionar o periódo para filtrar o trajeto.';
    }
  }

  cadastrarRotaVeiculo() {
    if (this.rotaSelecionada.id && this.dados.id) {
      const rotaVeiculo = {
        rota: this.rotaSelecionada.id,
        veiculo: this.dados.id
      };
      const json = JSON.stringify(rotaVeiculo);
      try {
        this.dashboardHomeService.cadastrarRotaVeiculo(json).then(retorno => {
          this.rotas.push(retorno.response.listaDeRota);
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      Swal.fire({
        type: 'info',
        title: 'Ops...',
        text: 'Selecionar uma Rota.',
        showConfirmButton: true,
        confirmButtonText: 'Ok',
      });
    }
  }

  trajeto() {
    try {
      this.dashboardHomeService.buscarTrajettoUltimas24horas(this.dados.id).then(response => {
        if (response.codigoRetorno === 85) {
          if (response.listaDeTrajeto.length !== 0) {
            this.mapMarkers = response.listaDeTrajeto;
          }
        }

      });
    } catch (error) {
      console.error(error);
    }
  }

  ocultar() {
    console.log('===========ocultar========>>>>');
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.formGroupTrajeto.patchValue({
        dataInicial: date,
      });
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      this.formGroupTrajeto.patchValue({
        dataFinal: date
      });
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.formGroupTrajeto.patchValue({
        dataInicial: date,
        dataFinal: null
      });
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  close() {
    this.activeModal.close();
  }

  openMap(type, search) {
    let url = '';
    if (type === 'latlong') {
      const lat = search.split('/')[0];
      const long = search.split('/')[1];
      url = 'http://maps.google.com/maps?q=' + lat + ',' + long;
    } else {
      url = 'http://maps.google.com/maps?q=' + search;
    }

    window.open(url, '_blank');
  }
}
