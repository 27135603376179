import { OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { RESTResponseVO } from "../../../classes/restresponsevo";

export abstract class BaseFormComponent<T> implements OnInit {
  public activeID: any;
  public activeObject: T;
  public stateName: string;
  public state: string;

  public loading: boolean;
  public errorMessage: string;
  public successMessage: string;

  // -----------------------------------------------------------
  // Constructors and Initializers
  // -----------------------------------------------------------

  constructor(
    private route: ActivatedRoute,
    private rtr: Router,
    public readonly listPath: string
  ) { }

  ngOnInit(): void {
    this.loading = true;

    this.route.paramMap.subscribe(params => {
      this.state = params.get("state");
      this.activeID = params.get("id");
    });

    this.loadAuxiliaryLists();
    this.processState();
  }

  // -----------------------------------------------------------
  // Abstracts
  // -----------------------------------------------------------

  abstract assembleObjectFromForm(): void;
  abstract loadAuxiliaryLists(): void;
  abstract loadObject(): void;
  abstract initObject(): void;
  abstract doCreate(t: T): Promise<RESTResponseVO<T>>;
  abstract doEdit(t: T): Promise<RESTResponseVO<T>>;
  abstract doDelete(t: T): Promise<RESTResponseVO<string>>;
  abstract setActiveID(): void;

  // -----------------------------------------------------------
  // Button Action Methods
  // -----------------------------------------------------------

  public btnCancelClick(t: T) {
    this.rtr.navigate([this.listPath], { skipLocationChange: true });
  }

  public btnCloseClick() {
    this.rtr.navigate([this.listPath], { skipLocationChange: true });
  }

  public btnRemoveClick(t: T) {
    this.loading = true;

    this.assembleObjectFromForm();
    this.doDelete(t).then(resultItem => {
      this.errorMessage = resultItem.error;

      if (!this.errorMessage) {
        this.rtr.navigate([this.listPath], { skipLocationChange: true });
      }

      this.loading = false;
    });
  }

  public btnSaveClick(t: T) {
    this.loading = true;

    this.assembleObjectFromForm();
    if (this.state === "I") {
      this.doCreate(t)
        .then(response => {
          this.loading = false;
          this.errorMessage = response.error;

          this.activeObject = response.response;
          this.successMessage = "";

          if (!this.errorMessage) {
            this.setActiveID();
            this.state = "V";
            this.processState();
          }
        })
        .catch(error => {
          console.log(`>>>> ERROR: <<<<< ${error}`);
        });
    } else if (this.state === "E") {
      this.doEdit(t)
        .then(response => {
          this.loading = false;
          this.errorMessage = response.error;
          this.activeObject = response.response;
          this.successMessage = "";

          if (!this.errorMessage) {
            this.setActiveID();
            this.state = "V";
            this.processState();
          }
        })
        .catch(error => {
          console.log(`>>>> ERROR: <<<<< ${error}`);
        });
    }
  }

  // -----------------------------------------------------------
  // Utilities
  // -----------------------------------------------------------

  protected isEditing() {
    return this.state === "E" || this.state === "I";
  }

  protected isInserting() {
    return this.state === "I";
  }

  protected isRemoving() {
    return this.state === "X";
  }

  protected processState() {
    this.stateName = "";

    if (this.state === "E") {
      this.stateName = "Editar";
      this.loadObject();
    } else if (this.state === "X") {
      this.stateName = "Remover";
      this.loadObject();
    } else if (this.state === "I") {
      this.stateName = "Incluir";
      this.initObject();
    } else {
      this.stateName = "Visualizar";
      this.loadObject();
    }
  }
}
