import { Component, OnInit, ViewChild, Compiler } from '@angular/core';

import { BaseListComponent } from '../../../base/base-list/base-list.component';
import { UtilService } from '../../../../services/utils.service';
import { PDFService } from '../../../../services/pdf.service';
import { JourneyService } from '../../../../services/crud/journey.service';
import { DriverService } from '../../../../services/crud/driver.service';
import { Driver } from '../../../../classes/driver';
import { Observable } from 'rxjs/Observable';
import { JourneySummary } from '../../../../classes/journey-summary';
import { Router } from '@angular/router';
import { JourneyEventsService } from '../../../../services/crud/journey-events.service';
import { AllowanceReason } from '../../../../classes/allowance-reason';
import { AuthGuard } from '../../../../guards/auth.guard';
import { EventsService } from '../../../../services/crud/events.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {BranchService} from '../../../../services/crud/branch.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.css']
})
export class DiariaMotoristaListComponent extends BaseListComponent {

    constructor(
        private journeyService: JourneyService,
        private driverService: DriverService,
        private utilService: UtilService,
        private journeyEventsService: JourneyEventsService,
        private eventService: EventsService,
        private router: Router,
        pdfService: PDFService,
        private authGuard: AuthGuard,
        private modalService: NgbModal,
        private branchService: BranchService,
        private _compiler: Compiler
    ) {
        super(utilService, pdfService, authGuard);
        this.searchField = '';
        this._compiler.clearCache();
    }

    public searchObject: {
        driver: Driver;
        year: Number;
        month: { name: string; start: any; end: any };
        driverStatus: string;
        manualStart: Date;
        manualEnd: Date;
        searchType: string;
    } = {
        driver: undefined, year: undefined, month: { name: undefined, end: undefined, start: undefined },
            driverStatus: undefined, manualEnd: undefined, manualStart: undefined, searchType: 'M'
        };
    public showAllowance: boolean;
    public showConfirmAllowance: boolean;
    public allowance: any;
    public reasonList: AllowanceReason[];
    public period: string;
    public modalMessage: string;
    public showDuplicates: boolean;

    public showYearList: boolean;
    public showMonthList: boolean;
    public yearList: any[];
    public monthList: any[];
    public valorDiariaMotorista: String;

    public tooltips = {
        dayAllowance: 'Aplicar diária em todo o período',
        diariaMotoristaAplicado: 'Diária aplicada na jornada?',
        marcarDiaria: 'Marcar diária na jornada',
        edit: 'Editar Jornada',
        dsrHE: 'Marcar jornada como Hora Extra DSR',
        waitingWorked: 'Marcar tempo de espera como trabalhado',
        motorista: 'Nome do motorista',
        unidade: 'Unidade que o motorista esta cadastrado',
        inicioJornada: 'Inicio da Jornada',
        ultimoEvento: 'Ultimo evento',
        tipoJornada: 'Tipo da jornada',
        dsrHorasExtras: 'DSR horas extras',
        horasFaltantes: 'Horas faltantes',
        horasTrabalhadas: 'Horas trabalhadas',
        compTempo: 'Compensação de tempo',
        tempoEspera: 'Tempo de espera'
    };

    @ViewChild('confirmDialog') confirmDialog;

    ngOnInit() {
        this.showAllowance = false;
        this.showConfirmAllowance = false;
        this.showDuplicates = false;

        this.pageTitle = 'Controle de Diárias Motorista | Consultas | Lista de Diárias Motorista';

        this.loading = true;

        this.tableHeaders = [
            [
                this.createHeader('Motorista', 0, true, 1, null, this.tooltips.motorista),
                this.createHeader('Unidade', 1, true, 1, null, this.tooltips.unidade),
                this.createHeader('Início Jornada', 2, true, 1, null, this.tooltips.inicioJornada),
                this.createHeader('Último Evento', 3, true, 1, null, this.tooltips.ultimoEvento),
                this.createHeader('Tipo Jornada', 4, true, 1, null, this.tooltips.tipoJornada),
                this.createHeader('Diária', 6, true, 1, null, this.tooltips.diariaMotoristaAplicado)
            ]
        ];

        this.dataFields = [
            this.createField('nomeMotorista', 'string', undefined, 'sobrenomeMotorista', ' '),
            this.createField('unidade', 'string'),
            this.createField('inicio', 'datetime'),
            this.createField('ultimoLancamento', 'datetime'),
            this.createField('tipoEvento', 'string'),
            this.createField('diariaMotorista', 'boolean')
        ];

        this.driverService.getListMinimo().then(response => {
            this.originalDriverList = response.response;
            this.driverList = response.response;
            this.driverList.sort((d1, d2) => (d1.nome > d2.nome ? 1 : -1));

            this.journeyEventsService.getAllowances().then(r1 => {
                this.reasonList = r1.response;

                this.loading = false;

                if (localStorage.getItem('journey')) {
                    let temp: any = JSON.parse(localStorage.getItem('journey'));
                    if (temp.searchObject && temp.data) {
                        this.searchObject = temp.searchObject;

                        var start, end;
                        if (this.searchObject.searchType === "M") {
                            start = new Date(this.searchObject.month.start);
                            end = new Date(this.searchObject.month.end);
                        } else {
                            start = new Date(this.searchObject.manualStart);
                            end = new Date(this.searchObject.manualEnd);
                        }

                        this.period = this.utilService.formatDate(start, 'DD/MM/YYYY') + ' - ' + this.utilService.formatDate(end, 'DD/MM/YYYY');
                        this.data = temp.data;

                        this.onStatusChange(this.searchObject.driverStatus);

                        this.doSearch();
                    }
                }
            });
        });
        this.sortType = 'inicio';
    }

    searchPeriod() {
        if (!this.searchObject.driver) {
            return;
        }
        this.loading = true;
        this.yearList = [];
        this.monthList = [];

        this.showMonthList = false;
        this.showYearList = false;

        this.searchObject.year = undefined;
        this.searchObject.month = undefined;
        this.journeyService
            .searchPeriod(this.searchObject.driver.id)
            .then(response => {
                var result = response.response;

                Object.keys(result).forEach(k => {
                    this.yearList.push({ year: k, months: result[k] });
                });

                this.searchObject.year = this.yearList[0];
                this.showYearList = true;

                this.onYearChange(this.yearList[0]);
                this.loading = false;
            });


    }

    isAdmin() {
        return this.authGuard.isAdmin();
    }

    toggleInsertAllowance() {
        this.showAllowance = !this.showAllowance;
        this.allowance = {
            tipo: undefined,
            inicio: this.searchObject.month ? new Date(this.searchObject.month.start) : this.searchObject.manualStart,
            fim: this.searchObject.month ? new Date(this.searchObject.month.end) : this.searchObject.manualEnd
        };
    }


    isValidEvent(event) {
        let start = this.searchObject.month ? new Date(this.searchObject.month.start) : this.searchObject.manualStart;
        let end = this.searchObject.month ? new Date(this.searchObject.month.end) : this.searchObject.manualEnd;

        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);

        return event.instanteEvento >= start && event.instanteEvento <= end;
    }

    createMockAllowanceEvent(code: number) {
        var loggedUser = this.authGuard.loggedUser();
        return {
            tipoEvento: code,
            instanteEvento: code > 0 ? this.allowance.inicio : this.allowance.fim,
            justificativa: this.allowance.tipo.descricao,
            empresaId: loggedUser.selectedCompany.id,
            instanteLancamento: Date.now(),
            operadorLancamento: loggedUser.id,
            motivoAbono: this.allowance.tipo
        };
    }

    markWaitingAsWorked(row: JourneySummary) {
        this.modalMessage =
            'Confirma marcação da jornada para compensação de horário trabalhado com tempo de espera?';

        this.modalService
            .open(this.confirmDialog, { centered: true, size: 'lg' })
            .result.then(
                result => {
                    this.loading = true;

                    this.journeyService
                        .markWaitingAsWorked(row.id, this.authGuard.loggedUser().id)
                        .then(response => {
                            this.errorMessage = response.error;
                            if (!this.errorMessage) {
                                this.doSearch();
                            } else {
                                this.loading = false;
                            }
                        });
                },
                reason => { }
            );
    }

    marcarDiariaMotorista(row: JourneySummary) {
        this.modalMessage = 'Confirma diária motorista?';

        this.modalService
            .open(this.confirmDialog, { centered: true, size: 'lg' })
            .result.then(
                result => {
                    this.loading = true;

                    this.journeyService
                        .marcarDiariaMotorista(row.id, this.authGuard.loggedUser().id)
                        .then(response => {
                            this.errorMessage = response.error;
                            if (!this.errorMessage) {
                                this.doSearch();
                            } else {
                                this.loading = false;
                            }
                        });
                },
                reason => { }
            );
    }

    async doSearch() {
        this.loading = true;
        this.showDuplicates = false;

        localStorage.removeItem('journey');

        let startDate, endDate;
        if (this.searchObject.searchType === 'D') {
            if (!this.searchObject.manualStart || !this.searchObject.manualEnd) {
                this.loading = false;
                return await Swal.fire({
                    type: 'error',
                    title: 'Erro',
                    text: 'É obrigatório informar a data de inicio e fim do período',
                    showConfirmButton: true,
                    confirmButtonText: 'Ok',
                });
            }
            startDate = new Date(this.searchObject.manualStart).getTime();
            endDate = new Date(this.searchObject.manualEnd).getTime();

            this.searchObject.month = undefined;
        } else {
            if (!this.searchObject.month.start || !this.searchObject.month.end) {
                this.loading = false;
                return await Swal.fire({
                    type: 'error',
                    title: 'Erro',
                    text: 'É obrigatório informar o mês e ano',
                    showConfirmButton: true,
                    confirmButtonText: 'Ok',
                });
            }
            startDate = this.searchObject.month.start;
            endDate = this.searchObject.month.end;

            this.searchObject.manualEnd = undefined;
            this.searchObject.manualStart = undefined;
        }

        this.branchService.getValorDiaria(this.searchObject.driver.id).then(response => {
            this.valorDiariaMotorista = response.response;
        });

        this.journeyService.getSummaryByDriver(this.searchObject.driver.id, startDate, endDate)
            .then(
                response => {
                    if (!response.error) {
                        this.data = response.response;
                        let start = this.searchObject.month ? new Date(this.searchObject.month.start) : this.searchObject.manualStart;
                        let end = this.searchObject.month ? new Date(this.searchObject.month.end) : this.searchObject.manualEnd;

                        this.period = this.utilService.formatDate(start, 'DD/MM/YYYY') + ' - ' + this.utilService.formatDate(end, 'DD/MM/YYYY');
                    } else {
                        this.data = [];
                        this.errorMessage = response.error;
                    }

                    var journeyIds = [];

                    this.data.forEach(d => {
                        var format = 'DDMMYYYYhhmmss';
                        var id = this.utilService.formatDate(d.inicio, format) + ';' + this.utilService.formatDate(d.ultimoLancamento, format) + ';' + d.tipoEvento;
                        if (journeyIds.includes(id)) {
                            this.showDuplicates = true;
                        } else {
                            journeyIds.push(id);
                        }

                        if (!d.locked) {
                            d.locked = false;
                        }
                    });

                    this.loading = false;
                    this.utilService.fixIcons();
                },
                error => {

                }
            );
    }

    // Driver Search
    driverList: Driver[];
    originalDriverList: Driver[];

    driverFormatter = (x: Driver) => x.nome + ' ' + x.sobrenome;

    searchDriver = (text$: Observable<string>) =>
        text$
            .debounceTime(200)
            .map(term =>
                this.driverList
                    .filter(v => v.nome.toLowerCase().indexOf(term.toLowerCase()) > -1)
                    .slice(0, 10)
            );

    driverTemplate(r) {
        return r.nome + ' ' + r.sobrenome;
    }

    onStatusChange(value) {
        if (value !== '') {
            this.driverList = this.originalDriverList.filter(
                d => d.situacao === +value
            );
        } else {
            this.driverList = this.originalDriverList;
        }
    }

    yearTemplate(y) {
        return y.year;
    }

    monthTemplate(m) {
        return m.name;
    }

    onYearChange(value) {
        this.monthList = value.months;
        this.searchObject.month = this.monthList[0];
        this.showMonthList = true;
    }

    enviarTodoPeriodoADiaria() {
        this.modalMessage = 'Aplicar diária nas ' + this.data.length + ' jornadas?';

        this.modalService
            .open(this.confirmDialog, { centered: true, size: 'lg' })
            .result.then(
                result => {
                    this.loading = true;
                    this.journeyService
                        .enviarTodoPeriodoADiaria(this.data)
                        .then(response => {
                            this.errorMessage = response.error;
                            if (!this.errorMessage) {
                                this.doSearch();
                            } else {
                                this.loading = false;
                            }
                        });
                },
                reason => { }
            );
    }

}
