import { Injectable } from '@angular/core';
import { CRUDService } from '../base/crud.service';
import { Http } from '@angular/http';
import { Vehicle } from '../../classes/vehicle';
import { environment } from '../../../environments/environment';
import { User } from '../../classes/user';
import { AuthGuard } from '../../guards/auth.guard';

@Injectable()
export class VehicleService extends CRUDService<Vehicle> {
    constructor(http: Http,
        authGuard: AuthGuard) {
        super(http, environment.baseURL + 'App/v2/web/veiculos', true, "placa");

        var loggedUser: User = authGuard.loggedUser();
        var company: number = loggedUser.selectedCompany.id;
        var branch: string = loggedUser.selectedBranch.id === 0 && '0' || loggedUser.selectedBranch.id.toString();

        // TODO: branch not implemented on vehicle
        this.listURL = environment.baseURL + 'App/v2/web/veiculos/listarVeiculo/' + company + '/' + branch;
    }
}