import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { catchError, debounceTime, distinctUntilChanged, finalize, switchMap, tap, map } from 'rxjs/operators';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs';
import { DashboardHomeService } from 'app/services/dashboard/dashboard.service';
import { AuthGuard } from 'app/guards/auth.guard';
import Swal from 'sweetalert2';
import { ValidadorService } from 'app/services/validador.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { ReportService } from 'app/services/report/report.service';
import { Veiculo } from 'app/classes/veiculo';
import { Filtro } from 'app/classes/filtro';
import { InformacaoRelatorio } from 'app/classes/informacao-relatorio';
import { Transmissao } from 'app/classes/transmissao';
import { LEFT_ARROW } from '@angular/cdk/keycodes';
declare const google: any;

@Component({
  selector: 'app-map-details-trajeto',
  templateUrl: './map-details-trajeto.component.html',
  styleUrls: ['./map-details-trajeto.component.scss']
})
export class MapDetailsTrajetoComponent implements OnInit {

  @Output() onload: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public dadosVeiculo: any;
  // @ViewChild('dataTable') table;
  // dataTable: any;
  // dtOption: any = {};

  mapa: any;
  lat = -19.923395;
  lng = -43.944809;
  showVeiculos = false;
  dropdownList = [];
  dropdownSettings: {};
  public selectedItems = [];
  veiculo: Veiculo;
  public dataInicial: String;
  public dataFinal: String;
  showBotaoPesquisa = false;
  showMostrarTabela = false;
  public filtro: Filtro;
  public dadosVeiuculo: any;
  public velocidadeMedia = '';
  public distanciaTotal = '';
  public qtdePosicao: any;
  listaTransmissao: Transmissao[] = [];
  listaDePolilyne: any[] = [];
  markerInicio: any;
  markerFinal: any;
  mapMarkers: any[] = [];
  formGroup: FormGroup;
  zoomMapa = 8;

  displayedColumns: string[] = ['campo01', 'campo02', 'campo03', 'campo04'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public activeModal: NgbActiveModal,
    private authGuard: AuthGuard,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    public validadorService: ValidadorService,
    private spinner: NgxSpinnerService,
    public formatter: NgbDateParserFormatter,
    private dashboardHomeService: DashboardHomeService,
    private veiculoService: VeiculoService,
    private reportService: ReportService,
  ) {
    this.formGroup = this.formBuilder.group({
      inicio: [null, Validators.required],
      fim: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    try {
      this.veiculo = new Veiculo();
      this.veiculo.id = this.dadosVeiculo.idObjeto;
      this.veiculo.placa = this.dadosVeiculo.placa;
    } catch (error) {
      console.error(error);
    }
  }

  getDirecao(marker: any) {
    if (marker.direcao === null) { return `transform: rotate(45deg)`; }
    if (marker.direcao === undefined) { return `transform: rotate(90deg)`; }
    return `transform: rotate(80deg)`;
  }

  onMapReady(item: any) {
    this.mapa = item;
  }

  onMarkerClickEvent(marker, index) {

  }

  exibirNoMapa(row) {
    this.mapMarkers = [];
    this.zoomMapa = 16;
    this.mapMarkers.push(
      {
        longitude: Number(row.campo06),
        latitude: Number(row.campo05),
        latlong: `${row.campo05} / ${row.campo06}`,
        placa: this.dadosVeiculo.placa,
        descricao: row.campo04,
        icone: this.dadosVeiculo.tipo,
        dataHora: row.campo02,
        velocidade: row.campo03,
        direcao: row.campo07
      }
    );
  }

  doSearch() {
    console.log('--->', this.formGroup.value.inicio);
    console.log('--->', this.formGroup.value.fim);
    console.log('inicio', moment(this.formGroup.value.inicio).format('DDMMYYYYHHmm'));
    console.log('fim', moment(this.formGroup.value.fim).format('DDMMYYYYHHmm'));
    //  return;

    if (this.formGroup.invalid) {
      Object.keys(this.formGroup.controls).forEach(key => {
        this.formGroup.get(key).markAsTouched();
      });
    } else {
      this.onload.emit(true);
      this.showBotaoPesquisa = true;
      this.showMostrarTabela = false;
      try {
        const filtro = new Filtro();
        filtro.dataInicialString = moment(this.formGroup.value.inicio).format('DDMMYYYYHHmm');
        filtro.dataFinalString = moment(this.formGroup.value.fim).format('DDMMYYYYHHmm');
        filtro.listaDeVeiculos = [{ ...this.veiculo }];
        this.reportService.getReportPontosPercorridos(filtro).then(response => {
          this.onload.emit(false);
          const informacaoRelatorio = response.informacaoRelatorio;

          this.dataSource = new MatTableDataSource(response.informacaoRelatorio.listaInformacaoRelatorio);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;

          this.dadosVeiuculo = informacaoRelatorio.campo01;
          this.velocidadeMedia = informacaoRelatorio.campo02 + ' Km/h';
          this.distanciaTotal = informacaoRelatorio.campo03 + ' km';
          this.qtdePosicao = informacaoRelatorio.campo04;

          this.showMostrarTabela = true;
          this.ajustarTabelaDeDetalhes(informacaoRelatorio);
          this.showBotaoPesquisa = false;
        });
      } catch (error) {
        this.onload.emit(false);
        console.log(error);
      }
    }
  }

  ajustarTabelaDeDetalhes(informacaoRelatorio: InformacaoRelatorio) {
    try {
      this.listaTransmissao = [];

      informacaoRelatorio.listaInformacaoRelatorio.forEach(i1 => {

        let ing = i1.campo01;
        if (ing === 'D') {
          ing = 'Off';
        } else {
          ing = 'On';
        }
        const data = i1.campo02;
        const vel = i1.campo03 + ' Km/h';
        const end = i1.campo04;

        const t = new Transmissao();
        t.velocidade = Number(i1.campo03);
        t.latitude = Number(i1.campo05);
        t.longitude = Number(i1.campo06);
        t.direcao = Number(i1.campo07);
        this.listaTransmissao.push(t);
      });
      this.ajustarPercursoNoMapa();
    } catch (error) {
      console.log(error);
    }
  }

  ajustarPercursoNoMapa() {
    try {
      this.listaDePolilyne.forEach(e => {
        e.setMap(null);
      });
      if (this.markerInicio) {
        this.markerInicio.setMap(null);
      }
      if (this.markerFinal) {
        this.markerFinal.setMap(null);
      }

      if (this.listaTransmissao && this.listaTransmissao.length <= 0) {
        return;
      }

      for (let i = 0; i < this.listaTransmissao.length - 1; i++) {
        const t = this.listaTransmissao[i];
        const a1 = new google.maps.LatLng(t.latitude, t.longitude);
        const a2 = new google.maps.LatLng(this.listaTransmissao[i + 1].latitude, this.listaTransmissao[i + 1].longitude);
        let cor = '';
        if (t.velocidade >= 0 && t.velocidade <= 5) {
          cor = '#5f9ea0';
        }
        if (t.velocidade >= 6 && t.velocidade <= 15) {
          cor = '#00ffff';
        }
        if (t.velocidade >= 16 && t.velocidade <= 30) {
          cor = '#a9a9a9';
        }
        if (t.velocidade >= 31 && t.velocidade <= 45) {
          cor = '#ff8c00';
        }
        if (t.velocidade >= 46 && t.velocidade <= 60) {
          cor = '#ff4500';
        }
        if (t.velocidade >= 61 && t.velocidade <= 80) {
          cor = '#800080';
        }
        if (t.velocidade >= 81 && t.velocidade <= 100) {
          cor = '#663399';
        }
        if (t.velocidade >= 101 && t.velocidade <= 200) {
          cor = '#ff0000';
        }
        const flightPath = new google.maps.Polyline({
          path: [a1, a2],
          geodesic: true,
          strokeColor: cor,
          strokeOpacity: 1.0,
          strokeWeight: 2,
        });
        flightPath.setMap(this.mapa);
        this.listaDePolilyne.push(flightPath);
      }

      const center = new google.maps.LatLng(this.listaTransmissao[0].latitude, this.listaTransmissao[0].longitude);
      this.mapa.panTo(center);
      this.mapa.setZoom(14);

      const inicialLatlng = new google.maps.LatLng(this.listaTransmissao[0].latitude, this.listaTransmissao[0].longitude);
      // tslint:disable-next-line:max-line-length
      const finalLatlng = new google.maps.LatLng(this.listaTransmissao[this.listaTransmissao.length - 1].latitude, this.listaTransmissao[this.listaTransmissao.length - 1].longitude);

      const svgMarkerInicio = {
        // tslint:disable-next-line:max-line-length
        path: 'M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z',
        fillColor: 'blue',
        fillOpacity: 0.6,
        strokeWeight: 0,
        rotation: 0,
        scale: 2,
        anchor: new google.maps.Point(15, 30),
      };

      const svgMarkerFim = {
        // tslint:disable-next-line:max-line-length
        path: 'M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z',
        fillColor: 'green',
        fillOpacity: 0.6,
        strokeWeight: 0,
        rotation: 0,
        scale: 2,
        anchor: new google.maps.Point(15, 30),
      };

      this.markerInicio = new google.maps.Marker({
        position: inicialLatlng,
        icon: svgMarkerInicio,
        label: 'Inicio',
        map: this.mapa
      });

      this.markerFinal = new google.maps.Marker({
        position: finalLatlng,
        icon: svgMarkerFim,
        label: 'Fim',
        map: this.mapa
      });

    } catch (error) {
      console.log(error);
    }
  }

  getImageMapMarker(mapMarker) {
    if (mapMarker.icone === 'MOTORISTA') {
      switch (mapMarker.cor) {
        case '#CCCCCC':
          return './../../assets/img/mapa/celular/pin-cinza.png';
        case '#CCCCC':
          return './../../assets/img/mapa/celular/pin-cinza.png';
        case '#FDB836':
          return './../../assets/img/mapa/celular/pin-amarelo.png';
        case '#0000FF':
          return './../../assets/img/mapa/celular/pin-azul.png';
        case '#FF0000':
          return './../../assets/img/mapa/celular/pin-vermelho.png';
        default:
          return './../../assets/img/mapa/celular/pin-vermelho.png';
      }
    } else if (mapMarker.icone === 'CAMINHAO') {
      switch (mapMarker.cor) {
        case '#CCCCCC':
          return './../../assets/img/mapa/caminhao/pin-cinza.png';
        case '#CCCCC':
          return './../../assets/img/mapa/caminhao/pin-cinza.png';
        case '#FFFF00':
          return './../../assets/img/mapa/caminhao/pin-amarelo.png';
        case '#0000FF':
          return './../../assets/img/mapa/caminhao/pin-azul.png';
        case '#FF0000':
          return './../../assets/img/mapa/caminhao/pin-vermelho.png';
        default:
          return './../../assets/img/mapa/caminhao/pin-vermelho.png';
      }
    } else if (mapMarker.icone === 'CARRO') {
      switch (mapMarker.cor) {
        case '#CCCCCC':
          return './../../assets/img/mapa/carro/carro-cinza.png';
        case '#CCCCC':
          return './../../assets/img/mapa/carro/carro-cinza.png';
        case '#FFFF00':
          return './../../assets/img/mapa/carro/carro-amarelo.png';
        case '#0000FF':
          return './../../assets/img/mapa/carro/carro-azul.png';
        case '#FF0000':
          return './../../assets/img/mapa/carro/carro-red.png';
        default:
          return './../../assets/img/mapa/carro/carro-red.png';
      }
    } else if (mapMarker.icone === 'MOTO') {
      switch (mapMarker.cor) {
        case '#CCCCCC':
          return './../../assets/img/mapa/moto/pin-cinza.png';
        case '#CCCCC':
          return './../../assets/img/mapa/moto/pin-cinza.png';
        case '#FFFF00':
          return './../../assets/img/mapa/moto/pin-amarelo.png';
        case '#0000FF':
          return './../../assets/img/mapa/moto/pin-azul.png';
        case '#FF0000':
          return './../../assets/img/mapa/moto/pin-vermelho.png';
        default:
          return './../../assets/img/mapa/moto/pin-vermelho.png';
      }
    } else if (mapMarker.icone === 'CELULAR') {
      switch (mapMarker.cor) {
        case '#CCCCCC':
          return './../../assets/img/mapa/celular/pin-cinza.png';
        case '#CCCCC':
          return './../../assets/img/mapa/celular/pin-cinza.png';
        case '#FFFF00':
          return './../../assets/img/mapa/celular/pin-amarelo.png';
        case '#0000FF':
          return './../../assets/img/mapa/celular/pin-azul.png';
        case '#FF0000':
          return './../../assets/img/mapa/celular/pin-vermelho.png';
        default:
          return './../../assets/img/mapa/celular/pin-vermelho.png';
      }
    } else if (mapMarker.icone === 'ONIBUS') {
      switch (mapMarker.cor) {
        case '#CCCCCC':
          return './../../assets/img/mapa/onibus/pin-cinza.png';
        case '#CCCCC':
          return './../../assets/img/mapa/onibus/pin-cinza.png';
        case '#FFFF00':
          return './../../assets/img/mapa/onibus/pin-amarelo.png';
        case '#0000FF':
          return './../../assets/img/mapa/onibus/pin-azul.png';
        case '#FF0000':
          return './../../assets/img/mapa/onibus/pin-vermelho.png';
        default:
          return './../../assets/img/mapa/onibus/pin-vermelho.png';
      }
    } else if (mapMarker.icone === 'PEPETE') {
      switch (mapMarker.cor) {
        case '#CCCCCC':
          return './../../assets/img/mapa/pepete/pin-cinza.png';
        case '#CCCCC':
          return './../../assets/img/mapa/pepete/pin-cinza.png';
        case '#FFFF00':
          return './../../assets/img/mapa/pepete/pin-amarelo.png';
        case '#0000FF':
          return './../../assets/img/mapa/pepete/pin-azul.png';
        case '#FF0000':
          return './../../assets/img/mapa/pepete/pin-vermelho.png';
        default:
          return './../../assets/img/mapa/pepete/pin-vermelho.png';
      }
    } else if (mapMarker.icone === 'TRATOR') {
      switch (mapMarker.cor) {
        case '#CCCCCC':
          return './../../assets/img/mapa/trator/pin-cinza.png';
        case '#CCCCC':
          return './../../assets/img/mapa/trator/pin-cinza.png';
        case '#FFFF00':
          return './../../assets/img/mapa/trator/pin-amarelo.png';
        case '#0000FF':
          return './../../assets/img/mapa/trator/pin-azul.png';
        case '#FF0000':
          return './../../assets/img/mapa/trator/pin-vermelho.png';
        default:
          return './../../assets/img/mapa/trator/pin-vermelho.png';
      }
    } else if (mapMarker.icone === 'PORTATIL') {
      return './../../assets/img/mapa/portatil/pin-portatil.png';
    } else {
      return './../../assets/img/mapa/caminhao/pin-vermelho.png';
    }
  }

}
