import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'select-input',
  templateUrl: './select-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectInputComponent),
      multi: true
    }
  ]
})
export class SelectInputComponent implements ControlValueAccessor {
  @Input('compareWith') compareWith: Function = (c1: any, c2: any) => {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  };

  @Input() getDisplayText: Function;
  @Input('obj') _obj: any;
  @Input('objList') objList: any;
  @Input() prop: string;

  get obj() {
    return this._obj;
  }

  set obj(val) {
    this._obj = val;
    this.propagateChange(this._obj);
  }

  propagateChange = (_: any) => {};

  writeValue(obj: any): void {
    this._obj = obj;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {}

  runBlur(event) {
    if (typeof this.obj === 'string' && this.obj !== '') {
      this.obj = '';
    }
  }
}
