import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatOption, MatSelect } from "@angular/material";
import { FiltroEspelhoPonto } from "app/classes/filtro-espelho-ponto";
import { User } from "app/classes/user";
import { AuthGuard } from "app/guards/auth.guard";
import { BranchService } from "app/services/crud/branch.service";
import { DriverService } from "app/services/crud/driver.service";
import { ReportService } from "app/services/report/report.service";
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';


@Component({
    selector: 'espelho-ponto',
    styleUrls: ['./espelho-ponto.component.scss'],
    templateUrl: './espelho-ponto.component.html'
})
export class EspelhoPontoCompoenent implements OnInit {


    loggedUser: User;
    isLoading: boolean = false;
    formGroup: FormGroup;
    motoristas: any = [];
    public motoristas2: any;
    public filteredList5: any;
    allSelected: boolean = false;
    unidades: any;
    filtro =  {} as FiltroEspelhoPonto;

    errorMessage: any;

    constructor(private driverService: DriverService,
                private reportService: ReportService,
                private branchService: BranchService,
                private authGuard: AuthGuard,
                private fb: FormBuilder) {

      this.loggedUser = this.authGuard.loggedUser();
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            dataInicial: [null, [Validators.required]],
            dataFinal: [null, [Validators.required]],
            motoristas: [null, [Validators.required]],
            ativo: [null, [Validators.required]],
            idUnidade: [null, [Validators.required]]
          });
          this.listarMotoristas();
          this.obterUnidades();
    }

    obterUnidades() {
      this.branchService.getList().then(response => {
        this.unidades = response.response;
      });
    }

    listarMotoristas() {
        this.isLoading = true;
        this.driverService.getMotoristas().then(response => {
          const refactoredResult = response.response.map(item => {
            item.nome = `${item.nome} ${item.sobrenome}`
            delete item.sobrenome;
            return item;
          })
          this.isLoading = false;
          this.motoristas = refactoredResult;
        });
    }

    selecionarTodos(matSelect: MatSelect) {
      const isSelected: boolean = matSelect.options
        .filter((item: MatOption) => item.value === 0)
        .map((item: MatOption) => item.selected)
      [0];
  
      if (isSelected) {
        this.allSelected = true;
        matSelect.options.forEach((item: MatOption) => item.select());
      } else {
        this.allSelected = false;
        matSelect.options.forEach((item: MatOption) => item.deselect());
      }
    }

    onStatusChange(event, matSelect: MatSelect) {

        if (event == "1") {
          this.motoristas2 = this.motoristas.filter(d => d.situacao);
        } else {
          this.motoristas2 = this.motoristas.filter(d => !d.situacao);

        }
          const filteredList = this.motoristas2.slice()
          this.filteredList5 = [...filteredList].sort((a, b): any => {
            if (a.nome < b.nome) {
              return -1;
            }
            if (a.nome > b.nome) {
              return 1;
            }
            return 0;
          });

          this.allSelected = false;
          matSelect.options.forEach((item: MatOption) => item.deselect());
      }

      doSearch() {

        if (this.allSelected) {
          const oldValue = this.formGroup.value.motoristas;
          this.formGroup.value.motoristas = oldValue.filter((item) => {
            if (item !== 0) {
              return item;
            }
          })
        }
        this.formGroup.get('ativo').markAsTouched();
        this.formGroup.get('idUnidade').markAsTouched();
        this.formGroup.get('motoristas').markAsTouched();
        this.formGroup.get('dataInicial').markAsTouched();
        this.formGroup.get('dataFinal').markAsTouched();

        if (this.formGroup.value.ativo ===  null || this.formGroup.value.ativo ===  undefined) {
          return;
        }

        if (!this.formGroup.value.motoristas) {
          return;
        }

        if (!this.formGroup.value.idUnidade) {
          return;
        }

        if (!this.formGroup.value.dataInicial || !this.formGroup.value.dataFinal) {
          return;
        }

        this.isLoading = true;

        try {
          const dataInicial = new Date(this.formGroup.value.dataInicial);
          const dataFinal = new Date(this.formGroup.value.dataFinal);

          dataInicial.setHours(0,0,0,0);
          dataFinal.setHours(23,59,59,999);


          this.filtro.dataInicial = dataInicial.toISOString();
          this.filtro.dataFinal = dataFinal.toISOString();

          if (this.allSelected) {
            this.filtro.listaDeMotoristas = [];
          } else {
            this.filtro.listaDeMotoristas = this.formGroup.value.motoristas.map((motoristaId) => {
              return {id: motoristaId}
            });
          }
          
          this.filtro.idEmpresa = this.loggedUser.selectedCompany.id;

          this.filtro.idUnidade = this.formGroup.value.idUnidade;

          this.filtro.situacao =  this.formGroup.value.ativo;

          

          this.reportService.getReportEspelhoPonto(this.filtro)
          .then((response: any) => {
            if(response) {
              if(response.codigoRetorno > 0 && response.informacaoRelatorio
                && response.informacaoRelatorio.listaInformacaoRelatorio) {
                const results = response.informacaoRelatorio.listaInformacaoRelatorio.map(
                  c=> {
                    return this.reportService.printEspelhoPontoReport(c, response.base64);
                  })

                this.createObjToPrint(results);
                
              }
            }
          } )
          .catch(error=> console.error(error));

          this.isLoading = false;

        } catch (error) {
          console.error(error);
        }
        finally {
          this.isLoading = false;
        }
      }

      createObjToPrint(array) {
        var obj: any = {};
        array.map((result, index, arr) => {
          if (index === 0) {
            obj.content = result.content;
            obj.footer = result.footer;
            obj.header = result.header;
            obj.pageMargins = result.pageMargins;
            obj.pageOrientation = result.pageOrientation;
            return obj.styles = result.styles;
          }
          obj.content[0] = [...obj.content[0], ...result.content[0]];
          // this.printPdf(result);
        });
        this.printPdf(obj);
        this.isLoading = false;
      }

      printPdf(dd) {
        (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
        pdfMake.createPdf(dd).getBase64((result) => {
          this.base64toPDF(result);
        });
      }

      base64toPDF(data) {
        var bufferArray = this.base64ToArrayBuffer(data);
        var blobStore = new Blob([bufferArray], { type: "application/pdf" });
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
          (window.navigator as any).msSaveOrOpenBlob(blobStore);
          return;
        }
        var data: any = window.URL.createObjectURL(blobStore);
        var link = document.createElement('a');
        document.body.appendChild(link);
        link.href = data;
        link.download = "file.pdf";
        link.click();
        window.URL.revokeObjectURL(data);
        link.remove();
      }

      base64ToArrayBuffer(data) {
        var bString = window.atob(data);
        var bLength = bString.length;
        var bytes = new Uint8Array(bLength);
        for (var i = 0; i < bLength; i++) {
          var ascii = bString.charCodeAt(i);
          bytes[i] = ascii;
        }
        return bytes;
      };



}
