import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-map-motorista-details',
  templateUrl: './modal-abertura-bau-fora-local-cadastrado-component.html',
  styleUrls: ['./modal-abertura-bau-fora-local-cadastrado-component.css']
})
export class ModalAberturaBauForaLocalCadastradoComponent implements OnInit {

  @Input() public dados: any;
  @ViewChild(MatSort) sort: MatSort;
  titulo: string;

  displayedColumns: string[] = [
    'dataRegistro',
    'placa',
    'motorista',
    'endereco'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private paginator: MatPaginatorIntl
  ) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.dados.map((o) => ({
      dataRegistro: o.dataRegistro !== null ? o.dataRegistro : 'Dado não encontrado',
      placa: o.placa !== null ? o.placa : 'Dado não encontrado',
      motorista: o.nomeMotorista !== null ? o.nomeMotorista : 'Dado não encontrado',
      endereco: o.endereco !== null ? o.endereco : 'Dado não encontrado',
      latitude: o.latitude,
      longitude: o.longitude
    })));
    this.dataSource.sort = this.sort;
    this.titulo = `Ocorrências - Abertura do baú fora de local cadastrado`;
  }

  redirecionar(row) {
    if (row.latitude && row.longitude) {
      const latitude = row.latitude;
      const longitude = row.longitude;
      return window.open(
        'http://maps.google.com/maps?q=' + latitude + ',' + longitude,
        '_blank'
      );
    }
  }

  close() {
    this.activeModal.close();
  }
}
