import { Injectable } from '@angular/core';
import { Company } from '../../classes/company';
import { CRUDService } from '../base/crud.service';
import { Http } from '@angular/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class CompanyService extends CRUDService<Company> {
  constructor(http: Http) {
    super(http, environment.baseURL + 'App/v2/web/empresas', true);
  }

  buscarTodosParaContratos() {
    const url = `${this.defaultUrl}/buscarTodosParaContrato`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json();
        }
      })
      .catch(this.handleError);
  }


}