import { Component, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-permissao-input',
  template: '<div>TESTE</div>',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PermissaoInputComponent),
      multi: true
    }
  ]
})

export class PermissaoInputComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  loadComponent(){

  }

}
