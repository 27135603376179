import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseListComponent } from '../../../base/base-list/base-list.component';
import { ToleranceService } from '../../../../services/crud/tolerance.service';
import { UtilService } from '../../../../services/utils.service';
import { PDFService } from '../../../../services/pdf.service';
import { AuthGuard } from '../../../../guards/auth.guard';

@Component({
    templateUrl: '../../../base/base-list/base-list.component.html',
    styleUrls: [ '../../../base/base-list/base-list.component.css' ],
    encapsulation: ViewEncapsulation.None
})
export class ToleranceListComponent extends BaseListComponent {

    constructor(private toleranceService: ToleranceService,
        private utilService: UtilService,
        pdfService: PDFService,
        authGuard: AuthGuard) {
        super(utilService, pdfService, authGuard);
        this.searchField = "descricao";
    }

    ngOnInit() {
        this.pageTitle = "Cadastros | Tolerância";
        this.formPath = "/adm/tolerance/form";

        this.tableHeaders = [
            [
                this.createHeader(""),
                this.createHeader("Atraso", -1, false, 4, "title-center"),
                this.createHeader("Hora Extra", -1, false, 4, "title-center")
            ],
            [
                this.createHeader("Descrição", 0, true),
                this.createHeader("Entrada", 1, true),
                this.createHeader("Saída", 2, true),
                this.createHeader("Almoço", 3, true),
                this.createHeader("Total Diário", 4, true),
                this.createHeader("Entrada", 5, true),
                this.createHeader("Saída", 6, true),
                this.createHeader("Almoço", 7, true),
                this.createHeader("Total Diário", 8, true)
            ]
        ];

        this.dataFields = [
            this.createField("descricao", "string"),
            this.createField("atrasoEntrada", "time"),
            this.createField("atrasoSaida", "time"),
            this.createField("atrasoAlmoco", "time"),
            this.createField("totalAtrasoDiario", "time"),
            this.createField("horaExtraEntrada", "time"),
            this.createField("horaExtraSaida", "time"),
            this.createField("horaExtraAlmoco", "time"),
            this.createField("totalHoraExtraDiario", "time")
        ];

        this.toleranceService.getList().then(response => {
            this.data = response.response;
            this.loading = false;
        });
    }
}
