import { CRUDService } from "../base/crud.service";
import { Http } from "@angular/http";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { AuthGuard } from "../../guards/auth.guard";
import { User } from '../../classes/user';
import { RESTResponseVO } from "app/classes/restresponsevo";
import { Macros } from "app/classes/macros";
import { RetornoMacros } from "app/classes/retorno-macros";


@Injectable({ 
  providedIn: 'root'
})
export class MacrosService extends CRUDService<Macros>{

  public company: number;
  constructor(http: Http, public authGuard: AuthGuard) {
    super(http, environment.baseURL + 'App/v2/web/macros', true);
    var loggedUser: User = authGuard.loggedUser();
    this.company = loggedUser.selectedCompany.id;
    this.listURL = environment.baseURL + "App/v2/web/macros/buscarMacros/"+ this.company;
  }


  getLista() {
    const url: string =
      (this.listURL === undefined && `${this.defaultUrl}`) || `${this.listURL}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoMacros;
        }
      })
      .catch(this.handleError);
  }


  getById(id: number) {
    const url = `${this.defaultUrl}/buscarPeloId/${id}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoMacros;
        }
      })
      .catch(this.handleError);
  }


  cadastrarMacros(macros: Macros): Promise<RESTResponseVO<Macros>> {
    const url = `${this.defaultUrl}/cadastrar`;
    macros.empresa = this.company;
    return this.enviarRequisicao(macros, url);
  }


  editarMacros(macros: Macros): Promise<RESTResponseVO<Macros>> {
    const url = `${this.defaultUrl}/editar`;
    macros.empresa = this.company;
    return this.enviarRequisicao(macros, url);
  }

  enviarRequisicao(macros: Macros, url: string): Promise<RESTResponseVO<Macros>> {
    try {
      return this.http
        .post(url, JSON.stringify(macros), { headers: this.headers })
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            var retorno: RetornoMacros = response.json() as RetornoMacros;
            var ret: Macros = retorno.macros;
            if (ret === null) {
              ret = new Macros();
            }
            ret.id = 0;
            return ret;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }

  protected handleError(error: any): Promise<any> {
    console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
    return Promise.reject(error.message || error);
  }

}
