import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ItemEficienciaTelemetria, ListaDeDetalhesEficiencia } from "app/classes/ItemEficienciaTelemetria";

@Component({
  selector: "quadro-eficiencia",
  templateUrl: "./quadro-eficiencia.component.html",
  styleUrls: ["./quadro-eficiencia.component.scss"],
})
export class QuadroEficienciaComponent {
  @Input()
  marchaLentaParado = new ItemEficienciaTelemetria();

  @Input()
  batendoTransmissaoSemAceleracao = new ItemEficienciaTelemetria();

  @Input()
  ecominicaSemAceleracao = new ItemEficienciaTelemetria();

  @Input()
  marchaLentaEmMovimento = new ItemEficienciaTelemetria();

  @Input()
  batendoTransmissaoComAceleracao = new ItemEficienciaTelemetria();

  @Input()
  ecominocaComAceleracao = new ItemEficienciaTelemetria();

  @Input()
  superEconomicaSemAceleracao = new ItemEficienciaTelemetria();

  @Input()
  sobrerotacaoSemAceleracao = new ItemEficienciaTelemetria();

  @Input()
  amarelaSemAceleracao = new ItemEficienciaTelemetria();

  @Input()
  superEconomicaComAceleracao = new ItemEficienciaTelemetria();

  @Input()
  sobrerotacaoComAceleracao = new ItemEficienciaTelemetria();

  @Input()
  amarelaComAceleracao = new ItemEficienciaTelemetria();

  @Input()
  rpmMaximo = new ItemEficienciaTelemetria();

  @Input()
  limiteDeVelocidade = new ItemEficienciaTelemetria();

  @Output()
  onClick = new EventEmitter<{ label: string, item: ItemEficienciaTelemetria }>();

  constructor() {}

  emitEvent(label: string, item: ItemEficienciaTelemetria) {
    label = `${label} ${item.porcentagem}`;
    this.onClick.emit({ label, item});
  }
}
