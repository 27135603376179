import { IDEntity } from "./IDEntity";
import { Driver } from "./driver";
import { User } from "./user";

export class DSR extends IDEntity {
    empresaId: number;
    motorista: Driver;
    inicioDSR: Date;
    fimDSR: Date;
    inicioInterjornada: Date;
    fimInterjornada: Date;
    processada: string;
    operadorLancamento: User;
}
