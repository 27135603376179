import { BaseListComponent } from "../../base/base-list/base-list.component";
import { Component, ViewEncapsulation,Compiler } from "@angular/core";
import { UtilService } from "../../../services/utils.service";
import { PDFService } from "../../../services/pdf.service";
import { AuthGuard } from "../../../guards/auth.guard";
import { RotaService } from "app/services/rota.service";

@Component({
  templateUrl: '../../base/base-list/base-list.component.html',
  styleUrls: ['../../base/base-list/base-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RotaListComponent extends BaseListComponent {

  constructor(private rotaService: RotaService,
    private utilService: UtilService,
    pdfService: PDFService,
    authGuard: AuthGuard,
    private _compiler: Compiler) {
    super(utilService, pdfService, authGuard);
    this.searchField = "";
    this._compiler.clearCache();
    this.data = [];
  }

  ngOnInit() {
    this.pageTitle = "Cadastros | Rota";
    this.formPath = "/adm/rota/form";

    this.tableHeaders = [
        [
            this.createHeader("Nome", 0, true),
            this.createHeader("Saída", 1, true),
            this.createHeader("Chegada", 2, true),
            this.createHeader("Status", 4, true)
        ]
    ];

    this.dataFields = [
        this.createField("nome", "string"),
        this.createField("localInicio.nome", "string"),
        this.createField("localFinal.nome", "string"),
        this.createField("ativo", "boolean")
    ];

    this.rotaService.getLista().then(response => {
        this.data = response.listaDeRota;
        this.loading = false;
    });
  }

}
