import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseFormComponent } from '../../../base/base-form/base-form.component';
import { RESTResponseVO } from '../../../../classes/restresponsevo';
import { AuthGuard } from '../../../../guards/auth.guard';
import { TipoRanking } from 'app/classes/tipo-ranking';
import { TipoRankingService } from 'app/services/crud/tipo-ranking.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class TipoRankingFormComponent extends BaseFormComponent<TipoRanking> {
  constructor(private tipoRankingService: TipoRankingService,
    route: ActivatedRoute,
    private authGuard: AuthGuard,
    router: Router) { super(route, router, 'adm/tipo-ranking/list'); }


  assembleObjectFromForm(): void {
  }

  loadAuxiliaryLists(): void {
  }

  loadObject(): void {
    if (this.activeID && (this.activeID !== 0)) {
      this.tipoRankingService.getById(this.activeID).then(response => {
        var resultItem: TipoRanking = response.tipoRanking;
        this.activeObject = resultItem;
      });
    }
    this.loading = false;
  }

  initObject(): void {
    this.activeObject = new TipoRanking();
    this.activeObject.cliente = this.authGuard.loggedUser().selectedCompany.id;
    var branch: string = this.authGuard.loggedUser().selectedBranch.id === 0 && '0' || this.authGuard.loggedUser().selectedBranch.id.toString();
    this.activeObject.unidade = Number(branch);
    this.loading = false;
  }

  doCreate(t: TipoRanking): Promise<RESTResponseVO<TipoRanking>> {
    return this.tipoRankingService.cadastrarTipoRanking(t);
  }

  doEdit(t: TipoRanking): Promise<RESTResponseVO<TipoRanking>> {
    return this.tipoRankingService.editarTipoRanking(t);
  }

  doDelete(t: TipoRanking): Promise<RESTResponseVO<string>> {
    return this.tipoRankingService.delete(t.id);
  }

  setActiveID(): void {
    this.activeID = this.activeObject.id;
  }


  get listaTipoRanking(){
    return this.tipoRankingService.getTipoRanking();
  }

  
}
