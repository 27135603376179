import { Component, OnInit } from '@angular/core';
import { FiltrosJornadaMotorista } from 'app/classes/filtro-jornada-motorista';

@Component({
  selector: 'app-jornada-motorista',
  templateUrl: './jornada-motorista.component.html',
  styleUrls: ['./jornada-motorista.component.css']
})
export class JornadaMotoristaComponent implements OnInit {

  retorno: any;

  constructor() { }

  ngOnInit(): void {
  }

  doPesquisar(filtro: FiltrosJornadaMotorista) {
    this.pesquisar(filtro);
  }

  private pesquisar(filtro: FiltrosJornadaMotorista) {

    // this.controleIndicadoresService.buscarIndicadoresFiltrados(filtro).then(response => {
    //     this.retorno = response;
    //     console.log("FILTRO", filtro);
    // });
  }
}
