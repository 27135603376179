import { Component } from "@angular/core";
import { BaseFormComponent } from "../../../base/base-form/base-form.component";
import { ActivatedRoute, Router } from "@angular/router";
import { RESTResponseVO } from "../../../../classes/restresponsevo";
import { Paramcfg } from "../../../../classes/paramcfg";
import { ParamcfgService } from "../../../../services/crud/paramcfg.service";
import { Company } from "../../../../classes/company";
import { Observable } from "rxjs/Observable";
import { CompanyService } from "../../../../services/crud/company.service";
import { BranchService } from "../../../../services/crud/branch.service";
import { Branch } from "../../../../classes/branch";

@Component({
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css']
})
export class ParamcfgFormComponent extends BaseFormComponent<Paramcfg> {
    constructor(private service: ParamcfgService,
        private companyService: CompanyService,
        private branchService: BranchService,
        route: ActivatedRoute,
        router: Router) { super(route, router, 'adm/paramcfg/list'); }

    assembleObjectFromForm(): void {
    }

    loadAuxiliaryLists(): void {
        this.companyService.getList().then(response => this.companyList = response.response);
        this.branchService.getList().then(response => this.branchList = response.response);
    }

    loadObject(): void {
        if (this.activeID && (this.activeID !== 0)) {
            this.service.getById(this.activeID).then(response => {
                var resultItem: Paramcfg = response.response;
                this.activeObject = resultItem;
            });
        }
        this.loading = false;
    }

    initObject(): void {
        this.activeObject = new Paramcfg();
        this.loading = false;
    }

    doCreate(t: Paramcfg): Promise<RESTResponseVO<Paramcfg>> {
        return this.service.create(t);
    }

    doEdit(t: Paramcfg): Promise<RESTResponseVO<Paramcfg>> {
        return this.service.update(t);
    }

    doDelete(t: Paramcfg): Promise<RESTResponseVO<string>> {
        return this.service.delete(t);
    }

    // Company
    companyList: Company[];

    companyFormatter = (x: Company) => x.nome;

    searchCompany = (text$: Observable<string>) =>
        text$
            .debounceTime(200)
            .distinctUntilChanged()
            .map(term => term.length < 2 ? [] : this.companyList.filter(v => v.nome.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10));

    companyTemplate(r) {
        return r.nome;
    }

    // Branch
    branchList: Branch[];

    branchFormatter = (x: Branch) => x.apelido;

    searchBranch = (text$: Observable<string>) =>
        text$
            .debounceTime(200)
            .distinctUntilChanged()
            .map(term => term.length < 2 ? [] : this.branchList.filter(v => v.apelido.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10));

    branchTemplate(r) {
        return r.apelido;
    }

    setActiveID(): void {
        this.activeID = this.activeObject.id;
    }
}