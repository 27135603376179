import {
  Compiler,
  Component,
  OnInit,
  ViewChild
} from "@angular/core";
import { ReportService } from "app/services/report/report.service";
import Swal from "sweetalert2";
import { Filtro } from "app/classes/filtro";
import { AuthGuard } from "app/guards/auth.guard";
import { User } from "app/classes/user";
import { DriverService } from '../../../services/crud/driver.service';
import { Motorista } from 'app/classes/motorista';
import { RelatorioDirecaoVersusPlaca } from "app/classes/relatorio-direcao-versus-placa";
import { JourneyService } from 'app/services/crud/journey.service';
import {RelatorioHorasExtras} from 'app/classes/relatorio-horas-extras';
import { RESTResponseVO } from "app/classes/restresponsevo";

@Component({
  selector: "app-relatorio-horas-extras",
  templateUrl: "./relatorio-horas-extras.component.html",
  styleUrls: ['./relatorio-horas-extras.component.css']
})
export class RelatorioHorasExtrasComponent implements OnInit {
  dropdownList = [];
  dropdownListMotorista = [];
  listaDeMotoristas: Motorista[] = [];
  motorista: Motorista;
  public selectedItems = [];
  public selectedItemsMotorista = [];
  dropdownSettings: {};
  dropdownSettingsDois: {};
  public filtro: Filtro;
  public pdfSrc: any;
  listaInformacaoRelatorio: any[];
  showVeiculos: boolean = false;
  showLocalOrigem: boolean = false;
  showBotaoPesquisa: boolean = true;
  isLoading = false;
  showMotoristas: boolean = false;
  public dataInicial: string;
  public dataFinal: string;
  eventoSensores: number = 0;
  public company: number;
  public loggedUser: User;
  public authGuardM: AuthGuard;
  situacao = 'null';
  driverList = [];

  relatorioHorasExtrasList: RelatorioHorasExtras[] = [];
  dtOption: any = {};
  @ViewChild("dataTable") table;
  dataTable: any;

  constructor(
    private driverService: DriverService,
    private journeyService: JourneyService,
    private reportService: ReportService,
    private _compiler: Compiler,
    public authGuard: AuthGuard
  ) {
    this.loggedUser = authGuard.loggedUser();
    this.company = this.loggedUser.selectedCompany.id;
    this._compiler.clearCache();
  }

  ngOnInit() {
    this.filtro = new Filtro();
    this.getMotoristas();
    this.prepareDataTable();
  }

  prepareDataTable() {
    try {
      this.dtOption = {
        iDisplayLength: 17,
        bPaginate: false,
        fixedHeader: true,
        bLengthChange: false,
        bFilter: true,
        bInfo: false,
        bAutoWidth: false,
        scrollY: 400,
        deferRender: true,
        scroller: true,
        language: {
          lengthMenu: "Mostrando _MENU_ registros por pagina",
          zeroRecords: "Nenhum registro encontrado",
          search: "Pesquisar",
          oPaginate: {
            sNext: "Próximo",
            sPrevious: "Anterior",
            sFirst: "Primeiro",
            sLast: "Último",
          },
        },
        dom: "Bfrtip",
        buttons: ["csv", "excel", "print"],
      };
      this.dataTable = $(this.table.nativeElement);
      this.dataTable.DataTable(this.dtOption);
    } catch (error) {
      console.error(error);
    }
  }

  getMotoristas() {
    this.driverService.getListMinimo().then(response => {
      this.driverList = response.response;
      this.formartDriverList(this.driverList);
    })
      .catch((error) => console.error(error));;
  }

  async doSearch() {
    if (!this.dataInicial || !this.dataFinal) {
      return Swal.fire({
        position: 'top-end',
        type: 'error',
        title: `Erro`,
        text: 'Os campos "Data inicial" e "Data final" são obrigatórios',
        showConfirmButton: false,
        timer: 4000
      });
    }
    try {
      this.showBotaoPesquisa = false;
      this.isLoading = true;
      this.filtro = new Filtro();
      this.filtro.dataInicialString = this.dataInicial;
      this.filtro.dataFinalString = this.dataFinal;
      this.filtro.listaDeMotoristas = this.listaDeMotoristas;
      this.filtro.idCliente = this.company;
      const response = await this.journeyService.getBuscarHorasExtrarRelatorio(this.filtro)
      this.ajustarTabelaDeDetalhes(response);
    } catch (error) {
      console.error(error);
    } finally {
      this.showBotaoPesquisa = true;
      this.isLoading = false;
    }
  }

  ajustarTabelaDeDetalhes(relatorioHorasExtrasListVO: RelatorioHorasExtras[]) {
    try {
      this.relatorioHorasExtrasList = relatorioHorasExtrasListVO;
      this.dataTable.DataTable().clear().draw();
      relatorioHorasExtrasListVO.forEach((item) => {
        const motoristaNome = item.motoristaNome || '-';
        const data = item.data || '-';
        const horasExtrasDiurnas = item.horasExtrasDiurnas || '-';
        const horasExtrasNoturnas = item.horasExtrasNoturnas || '-';
        const dsr = item.dsr || '-';
        const feriado = item.feriado || '-';
        const intrajornada = item.intrajornada || '-';
        const interjornada = item.interjornada || '-';
        const adicionalNoturno = item.adicionalNoturno || '-';
        const saldoFinal = item.saldoFinal || '-';
        this.dataTable
          .DataTable()
          .row.add([
            motoristaNome,
            data,
            horasExtrasDiurnas,
            horasExtrasNoturnas,
            dsr,
            feriado,
            intrajornada,
            interjornada,
            adicionalNoturno,
            saldoFinal
          ]);
      });
      this.dataTable.DataTable().draw();
    } catch (error) {
      console.log(error);
    }
  }

  onStatusChange(value) {
    let list = [];
    if (value !== '' && value != "null") {
      list = this.driverList.filter(
        d => d.situacao === +value
      );
    } else {
      list = this.driverList;
    }
    this.formartDriverList(list);
  }

  formartDriverList(list) {
    this.dropdownListMotorista = [];
    for (var i = 0; i < list.length; i++) {
      var motorista = list[i];
      var nome = motorista.nome + " " + motorista.sobrenome;
      this.dropdownListMotorista.push({ item_id: motorista.id, item_text: nome });
    }
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Todos',
      unSelectAllText: 'Desmarcar todos',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      searchPlaceholderText: 'Pesquisar'
    };
    this.showMotoristas = true;
  }

  onItemSelectMotorista(item: any) {
    this.inserirMotoristaSelecionado(item);
  }

  onDeSelectMotorista(item: any) {
    this.removerMotoristaSelecionado(item);
  }

  onSelectAllMotorista(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirMotoristaSelecionado(items[i]);
    }
  }

  inserirMotoristaSelecionado(item: any): void {
    try {
      var moto = this.listaDeMotoristas.find(m => m.id == item.item_id);
      if (!moto) {
        this.motorista = new Motorista();
        this.motorista.id = item.item_id;
        this.listaDeMotoristas.push(this.motorista);
      }
    } catch (error) {
      console.log(error);
    }
  }

  removerMotoristaSelecionado(item: any): void {
    try {
      let index = this.listaDeMotoristas.findIndex(m => m.id == item.item_id);
      this.listaDeMotoristas.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }

}
