import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { environment } from "../../environments/environment";
import * as QRCode from 'qrcode';
import { LiberacaoPonto } from 'app/classes/liberacao-ponto';


@Injectable({
  providedIn: 'root'
})
export class LiberacaoPontoService {

    defaultUrl: string;
    headers = new Headers({ 'Content-Type': 'application/json' });
    
    constructor(private http: Http) {
        this.defaultUrl = environment.baseURL + 'App/v2/web/qrcode';
    }

    async gerarImagemQRCode(text: string, width: number = 400, height: number = 400): Promise<string> {
      try {
        const qrCodeDataURL = await QRCode.toDataURL(text,  
          { 
            width,
            height,
            rendererOptions: {
              background: "#ff0000"
            }
          });
        return qrCodeDataURL;
      } catch (error) {
        console.error('Erro ao gerar QR code:', error);
        throw error;
      }
    }

    gerarQrcode(idUnidade: number) {
      try {
        return this.http.get(`${this.defaultUrl}/gerarQrcode/${idUnidade}`)
        .toPromise()
        .then(response => {
          if (response) {
            return response.json() as LiberacaoPonto;
        } else {
            return {};
        }
        }).catch(this.handleError);
      } catch {

      }
    }

    verificarLeituraQrcode(idUnidade: number, code : string) {
      try {
        return this.http.get(`${this.defaultUrl}/verificarLeitura/${idUnidade}/${code}`)
        .toPromise()
        .then(response => {
          if (response) {
            return response.json() as LiberacaoPonto;
        } else {
            return {};
        }
        }).catch(this.handleError);
      } catch {

      }
    }

    private handleError(error: any): Promise<any> {
      console.error('Ocorreu um erro: ', error);
      return Promise.reject(error.message || error);
    }

}
