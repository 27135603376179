import { Injectable } from '@angular/core';
import { RESTResponseVO } from '../../classes/restresponsevo';
import { Http } from '@angular/http';
import { AuthGuard } from '../../guards/auth.guard';
import { Event } from '../../classes/event';
import { environment } from '../../../environments/environment';

@Injectable()
export class WebserverService {
constructor(private http: Http, public authGuard: AuthGuard) { }

saveSascarWebServerEvent(driver: number, user: number, startDate: string, endDate: string){
        const url: string = environment.baseURL + 'App/v2/web/sascarwebserver' + '/' + driver + '/'
        + startDate + '/' + endDate;
        return this.http
            .post(url, user)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<Event>;
            })
            .catch(this.handleError);
    }

    protected handleError(error: any): Promise<any> {
        console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
        return Promise.reject(error.message || error);
    }
    searchPeriod(driver: number): Promise<RESTResponseVO<any>> {
      let url: string = environment.baseURL + 'App/v2/web/sascarwebserver' + driver;
      return this.http.get(url)
          .toPromise()
          .then(response => {
              return response.json() as RESTResponseVO<any>;
          })
          .catch(this.handleError);
  }
}
