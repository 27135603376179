import { Component, OnInit, Compiler } from '@angular/core';
import { ControleCombustivel } from 'app/classes/controle-combustivel';
import { RetornoControleCombustivel } from 'app/classes/retorno-controle-combustivel';
import { ControleCombustivelService } from 'app/services/crud/controle-combustivel.service';
import { VeiculoService } from 'app/services/crud/veiculo.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class ControleCombustivelFormComponent implements OnInit {

  public controleCombustivel: ControleCombustivel;
  dropdownList = [];
  public selectedItems = [];
  dropdownSettings: {};
  showVeiculos: boolean = false;
  showTabela: boolean = true;
  public listaControleCombustivel: any[];
  private listaParaExcluir: any[];
  private veiculo: number;



  constructor(
    private veiculoService: VeiculoService,
    private controleCombustivelService: ControleCombustivelService,
    private _compiler: Compiler) {
    this._compiler.clearCache();
  }


  ngOnInit() {
    try {
      this.controleCombustivel = new ControleCombustivel();
      this.veiculoService.getLista().then(response => {
        var data = response.listaDeVeiculos;
        this.dropdownList = [];
        for (var i = 0; i < data.length; i++) {
          var veiculo = data[i];
          this.dropdownList.push({ item_id: veiculo.id, item_text: veiculo.placa });

        }
        this.dropdownSettings = {
          singleSelection: true,
          idField: 'item_id',
          textField: 'item_text',
          selectAllText: 'Todos',
          unSelectAllText: 'Desmarcar todos',
          itemsShowLimit: 3,
          allowSearchFilter: true,
          searchPlaceholderText: 'Pesquisar'
        };
        this.showVeiculos = true;
      });

    } catch (error) {
      console.error(error);
    }

  }

  onDeSelect(event) {
  }

  onItemSelect(event) {
    try {
      this.veiculo = event.item_id;
      this.showVeiculos = false;
      this.showTabela = true;
      this.buscarListaDeCombustivel(this.veiculo);
    } catch (error) {
      console.error(error);
    }
  }

  convertCombustivel(valor) {
    if (valor === "AL") {
      return "Alcool"
    }
    if (valor === "DI") {
      return "Diesel";
    }
    return "Gasolina";
  }


  deleteControleCombustivelValue(index) {
    var cc = this.listaControleCombustivel[index];
    this.listaParaExcluir.push(cc);
    this.listaControleCombustivel.splice(index, 1);
  }

  adicionarControleCombustivel() {
    this.controleCombustivel.isNovo = true;
    this.controleCombustivel.idVeiculo = this.veiculo;
    this.listaControleCombustivel.push(this.controleCombustivel);
    this.controleCombustivel = new ControleCombustivel();
  }

  gravarControleCombutivel() {
    this.showVeiculos = false;


    let ccInserir = this.listaControleCombustivel.filter(x => x.isNovo == true);
    let ccExcluir: number[] = [];
    this.listaParaExcluir.forEach(x => {
      if (x.id) {
        ccExcluir.push(x.id);
      }
    });

    this.controleCombustivelService.ajustar(ccInserir, ccExcluir).then(response => {
      this.listaControleCombustivel.forEach(x => {
        x.isNovo = false;
      });
      this.showVeiculos = true;
      this.listaParaExcluir = [];
      this.controleCombustivel = new ControleCombustivel();
      this.buscarListaDeCombustivel(this.veiculo);
    });
  }

  buscarListaDeCombustivel(idVeiculo) {
    try {
      this.controleCombustivelService.getLista(idVeiculo).then(response => {
        var resultItem: RetornoControleCombustivel = response;
        this.listaControleCombustivel = resultItem.listaControleCombustivel;
        this.showVeiculos = true;
        this.showTabela = false;
        this.listaParaExcluir = [];
        this.controleCombustivel = new ControleCombustivel();
      });
    } catch (error) {
      console.error(error);
    }
  }
}
