import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatOption, MatSelect } from "@angular/material";
import { FiltroArquivoAFD } from "app/classes/filtro-arquivo-afd";
import { User } from "app/classes/user";
import { AuthGuard } from "app/guards/auth.guard";
import { BranchService } from "app/services/crud/branch.service";
import { DriverService } from "app/services/crud/driver.service";
import { PortariaService } from "app/services/crud/portaria.service";
import { ProcessoArquivoService } from "app/services/processo-arquivo.service";

@Component({
  templateUrl: './arquivo-afd.component.html',
  styleUrls: ['./arquivo-afd.component.css']
})
export class ArquivoAfdComponent implements OnInit {

  loggedUser: User;
  isLoading: boolean = false;
  formGroup: FormGroup;
  motoristas: any = [];
  public filteredList5: any;
  allSelected: boolean = false;
  unidades: any;
  filtro = {} as FiltroArquivoAFD;
  opcaoSelecionada: number;

  errorMessage: any;

  @ViewChild('dataTable') table;
  dataTable: any;
  dtOption: any = {};

  constructor(private driverService: DriverService,
    private branchService: BranchService,
    private portariaService: PortariaService,
    private processoArquivoService: ProcessoArquivoService,
    private authGuard: AuthGuard,
    private fb: FormBuilder) {

    this.loggedUser = this.authGuard.loggedUser();
  }
  ngOnInit(): void {
    this.formGroup = this.fb.group({
      dataInicial: [null, [Validators.required]],
      dataFinal: [null, [Validators.required]],
      motoristas: [null, [Validators.required]],
      idUnidade: [null, [Validators.required]]

    });
    this.listarMotoristas();
    this.obterUnidades();

    this.dtOption = {
      "iDisplayLength": 17,
      "bPaginate": false,
      "fixedHeader": true,
      "bLengthChange": false,
      "bFilter": true,
      "bInfo": false,
      "bAutoWidth": false,
      "scrollY": 400,
      "deferRender": true,
      "scroller": true,
      "language": {
        "lengthMenu": "Mostrando _MENU_ registros por pagina",
        "zeroRecords": "Nenhum registro encontrado",
        "search": "Pesquisar",
        "oPaginate": {
          "sNext": "Próximo",
          "sPrevious": "Anterior",
          "sFirst": "Primeiro",
          "sLast": "Último"
        }
      },
      'dom': 'Bfrtip',
      rowCallback: (row, data) => {
        if (data[3] === 'Gerado') {
          $('td:eq(5)', row).html('<button type="button" class="btn btn-primary">Baixar</button>');
          $('td:eq(5)', row).on('click', () => {
            this.buscarArquivo(data);
          });
        } else {
          $('td:eq(5)', row).html('<button type="button" disabled class="btn btn-primary">Baixar</button>');
        }
        return row;
      }
    };
    this.dataTable = $(this.table.nativeElement);
    this.dataTable.DataTable(this.dtOption);
    this.ajustarTabelaDeDetalhes();

  }

  listarMotoristas() {
    this.isLoading = true;
    this.driverService.getListMinimo().then(response => {
      const refactoredResult = response.response.map(item => {
        item.nome = `${item.nome} ${item.sobrenome}`
        delete item.sobrenome;
        return item;
      })
      this.isLoading = false;
      this.motoristas = refactoredResult;

      const filteredList = this.motoristas.slice()
      this.filteredList5 = [...filteredList].sort((a, b): any => {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      });

      this.allSelected = false;
    });
  }

  selecionarTodos(matSelect: MatSelect) {
    const isSelected: boolean = matSelect.options
      .filter((item: MatOption) => item.value === 0)
      .map((item: MatOption) => item.selected)
    [0];

    if (isSelected) {
      this.allSelected = true;
      matSelect.options.forEach((item: MatOption) => item.select());
    } else {
      this.allSelected = false;
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  obterUnidades() {
    this.branchService.getList().then(response => {
      this.unidades = response.response;
      if (this.unidades && this.unidades.length && this.unidades.length == 1) {
        this.opcaoSelecionada = this.unidades[0].id
      }
    });
  }

  doSearch() {

    if (this.allSelected) {
      const oldValue = this.formGroup.value.motoristas;
      this.formGroup.value.motoristas = oldValue.filter((item) => {
        if (item !== 0) {
          return item;
        }
      })
    }
    this.formGroup.get('idUnidade').markAsTouched();
    this.formGroup.get('motoristas').markAsTouched();
    this.formGroup.get('dataInicial').markAsTouched();
    this.formGroup.get('dataFinal').markAsTouched();


    if (!this.formGroup.value.motoristas) {
      return;
    }

    if (Number.isNaN(this.formGroup.value.idUnidade) || this.formGroup.value.idUnidade < 0) {
      return;
    }

    if (!this.formGroup.value.dataInicial || !this.formGroup.value.dataFinal) {
      return;
    }

    this.isLoading = true;

    try {
      const dataInicial = new Date(this.formGroup.value.dataInicial);
      const dataFinal = new Date(this.formGroup.value.dataFinal);

      dataInicial.setHours(0, 0, 0, 0);
      dataFinal.setHours(23, 59, 59, 999);


      this.filtro.dataInicial = dataInicial.toISOString();
      this.filtro.dataFinal = dataFinal.toISOString();


      this.filtro.listaIdMotoristas = this.formGroup.value.motoristas;

      this.filtro.idEmpresa = this.loggedUser.selectedCompany.id;

      this.filtro.idUnidade = this.formGroup.value.idUnidade;

      this.portariaService.getArquivoAFD(this.filtro)
        .then((response: any) => {
          this.ajustarTabelaDeDetalhes();
        })
        .catch(error => console.error(error));

      this.isLoading = false;

    } catch (error) {
      console.error(error);
    }
    finally {
      this.isLoading = false;
    }
  }

  private handleDownload(response: any): void {

    const blob = new Blob([response], {
      type: 'application/zip'
    });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  ajustarTabelaDeDetalhes() {
    try {//processoArquivoService
      this.dataTable.DataTable().clear().draw();
      this.processoArquivoService.getProcessoArquivo("afd").then(response => {
        response.forEach(p => {
          this.dataTable.DataTable().row.add([p.dataInicioS, p.dataFinalS, p.tipoArquivo, p.status, p.motivo, p.id]);
        })
        this.dataTable.DataTable().draw();
      });

    } catch (error) {
      console.log(error);
    }
  }

  buscarArquivo(id: any) {
    try {
      this.processoArquivoService.getArquivo(id[5])
        .then((response: any) => {
          this.handleDownload(response)
        })
      console.log(id[5]);
    } catch (error) {
      console.error(error);
    }
  }

  doAtualizar() {
    try {
      this.ajustarTabelaDeDetalhes();
    } catch (error) {
      console.error(error);
    }
  }

}