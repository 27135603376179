import { Branch } from "./branch";
import { IDEntity } from "./IDEntity";
import { Tolerance } from "./tolerance";
export class TurnoEscala extends IDEntity{
    identificacao: string;
    unidadeEmpresa:Branch;
    empresaId: Number;
    dataCadastro:Date;
    quantidadeDiasTrabalhado:number;
    horasMensais: number;
    horasSemanais: any;
    toleranciasId: Tolerance;
    tipo: number;
    horarioEntrada:number;
    horarioSaida:number;
    inicioRefeicao:number;
    fimRefeicao:number;
    tipoEscala:number;
    calandario:String;
    horaDiaria:String;
    inicioEscalaDia: number;
    inicioEscalaMes: String;
    inicioEscalaAno: number;
    fimEscalaDia: number;
    fimEscalaMes: String;
    fimEscalaAno: number;
}
