import { BaseListComponent } from "../../../base/base-list/base-list.component";
import { Component, ViewEncapsulation } from "@angular/core";
import { UtilService } from "../../../../services/utils.service";
import { PDFService } from "../../../../services/pdf.service";
import { AuthGuard } from "../../../../guards/auth.guard";
import { InconformidadeService } from "app/services/crud/inconformidade.service";
import { Inconformidade } from "app/classes/inconformidade";
import { Company } from "app/classes/company";
import { Driver } from "app/classes/driver";

@Component({
  templateUrl: '../../../base/base-list/base-list.component.html',
  styleUrls: ['../../../base/base-list/base-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InconformidadeListComponent extends BaseListComponent {

  constructor(private inconformidadeService: InconformidadeService,
    private utilService: UtilService,
    pdfService: PDFService,
    authGuard: AuthGuard) {
    super(utilService, pdfService, authGuard);
    this.searchField = "";
  }

  ngOnInit() {
    this.pageTitle = "Inconformidade";
    this.formPath = "/adm/inconformidade/form";
    this.allowInsert = false;

    this.tableHeaders = [
      [
        this.createHeader("ID", 0, true),
        this.createHeader("Motorista", 1, true),
        this.createHeader("Placa", 2, true),
        this.createHeader("Data", 3, true),
        this.createHeader("Causa", 4, true),
        this.createHeader("Lido?", 5, true),
      ]
    ];

    this.dataFields = [
      this.createField("id", "string"),
      this.createField("nomeMotoristaCompleto", "string"),
      this.createField("placa", "string"),
      this.createField("dataInconformidadeFormatada", "string"),
      this.createField("nome", "string"),
      this.createField("lido", "boolean")
    ];

    this.inconformidadeService.getLista().then(response => {

      var listaDeInconformidade:Inconformidade[] = response.response.listaDeInconformidade;

      listaDeInconformidade.forEach(i=>{
          if(!i.motoristas){
            i.nomeMotoristaCompleto = " - ";
          }else{
            i.nomeMotoristaCompleto = i.motoristas.nome + " " + i.motoristas.sobrenome;
          }
          if(!i.placa){
            i.placa = " - ";
          }
      });

      this.data = listaDeInconformidade;
      this.loading = false;
    });
  }

}
