import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ResumoMonitoramentoJornada } from 'app/classes/resumo-monitoramento-jornada';

@Component({
  selector: 'app-detalhes-monitoramento-jornada-ausente',
  templateUrl: './detalhes-monitoramento-jornada-ausente.component.html',
  styleUrls: ['./detalhes-monitoramento-jornada-ausente.component.scss']
})
export class DetalhesMonitoramentoJornadaAusenteComponent {

  @Input()
  resumoMonitoramentoJornadaAusente: ResumoMonitoramentoJornada[] = [];

  resumoMonitoramentoJornadaAusentePaginado: ResumoMonitoramentoJornada[] = [];

  length: number = this.resumoMonitoramentoJornadaAusente.length;
  pageSize: number = 10;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  nomeTabela = "DetalhesIndicadoresLocais.xlsx";

  constructor(public dialogRef: MatDialogRef<DetalhesMonitoramentoJornadaAusenteComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) {
    this.resumoMonitoramentoJornadaAusente = data.resumoMonitoramentoJornadaAusente;
    this.length = this.resumoMonitoramentoJornadaAusente.length;

    this.paginarResultados(0);
  }

  exibirTabela: Boolean = true;

  exportexcel() {
    // let element = document.getElementById('detalhes_Indicadores_Locais');
    // const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Tabela 1');

    // XLSX.writeFile(wb, this.nomeTabela);

  }

  page(event) {
    this.pageSize = event.pageSize;
    this.paginarResultados(event.pageIndex);
  }

  paginarResultados(pageIndex: number) {
    this.resumoMonitoramentoJornadaAusentePaginado = this.resumoMonitoramentoJornadaAusente.slice(pageIndex * this.pageSize, (pageIndex + 1) * this.pageSize);
  }

  close() {
    this.dialogRef.close();
  }

}
