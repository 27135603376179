import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { FiltroOpcoesTelemetria } from "app/classes/filtro-opcoes-telemetria";
import { FiltroPlacas } from "app/classes/filtro-placas";
import { User } from "app/classes/user";
import { AuthGuard } from "app/guards/auth.guard";
import { VeiculoService } from "app/services/crud/veiculo.service";
import { ReplaySubject } from "rxjs";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-filtro-telemetria-component",
  templateUrl: "./filtro-telemetria-component.html",
  styleUrls: ["./filtro-telemetria-component.scss"],
})
export class FiltroTelemetriaComponent implements OnInit, OnDestroy {
  @Output()
  public onPesquisar = new EventEmitter<any>();

  @Input()
  public carregando: boolean = false;

  public unidadesFiltradas: ReplaySubject<any> = new ReplaySubject<any>();
  public unidadeFilterCtrl: FormControl = new FormControl();

  dropdownPlacasAllList = [];
  dropdownPlacasList = [];
  dropdownPlacasSettings: {};

  dropdownModelosList = [];
  dropdownModelosSettings: {};

  listaDePlacas: FiltroPlacas[] = [];
  placa: FiltroPlacas;

  unidades: any[];

  exibirFiltros: Boolean = true;

  loggedUser: User;

  _onDestroy = new Subject<void>();

  formFiltro = this.fb.group({
    idsUnidades: [[]],
    dataInicial: [null],
    dataFinal: [null],
    listaDeModelos: [[]],
    listaDeVeiculos: [[]],
    listaDeMotorista: [[]],
    veiculo: [null],
  });

  lastFormValue: FiltroOpcoesTelemetria = {
    idsUnidades: [],
    dataInicial: null,
    dataFinal: null,
    listaDeModelos: [],
    listaDeVeiculos: [],
    listaDeMotorista: [],
    veiculo: null,
  };

  constructor(
    private veiculoService: VeiculoService,
    private authGuard: AuthGuard,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.unidades = [];
    this.listaDePlacas = [];
    this.loggedUser = this.authGuard.loggedUser();

    this.buscarUnidades();
    this.buscarPlacas();
  }

  protected filtrarUnidades() {
    if (!this.unidades) {
      return;
    }
    let search = this.unidadeFilterCtrl.value;
    if (!search) {
      this.unidadesFiltradas.next(this.unidades.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.unidadesFiltradas.next(
      this.unidades.filter(
        (unidades) => unidades.name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  buscarPlacas() {
    try {
      this.veiculoService.getAllWithTelemetria().then((response) => {
        var data = response.listaDeVeiculos;
        this.dropdownPlacasAllList = [];
        for (var i = 0; i < data.length; i++) {
          var veiculo = data[i];
          this.dropdownPlacasAllList.push({
            id: veiculo.id,
            placa: veiculo.placa,
            unidade: veiculo.unidadeId
          });
        }
        this.dropdownPlacasSettings = {
          singleSelection: false,
          idField: "id",
          textField: "placa",
          selectAllText: "Todos",
          unSelectAllText: "Desmarcar todos",
          itemsShowLimit: 2,
          allowSearchFilter: true,
          searchPlaceholderText: "Pesquisar",
        };
      });
    } catch (error) {
      console.error(error);
    }
  }

  onPlacaSelect(item: any) {
    this.inserirPlacasSelecionadas(item);
  }

  onSelecAllPlacas(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirPlacasSelecionadas(items[i]);
    }
  }

  onDeSelectPlaca(item: any) {
    this.removerPlacasSelecionadas(item);
  }

  inserirPlacasSelecionadas(item: any): void {
    try {
      var placa = this.listaDePlacas.find((v) => v.id == item.id);
      if (!placa) {
        this.placa = new FiltroPlacas();
        this.placa.id = item.id;
        this.placa.placa = item.placa;
        this.listaDePlacas.push(this.placa);
      }
    } catch (error) {
      console.log(error);
    }
  }

  removerPlacasSelecionadas(item: any): void {
    try {
      let index = this.listaDePlacas.findIndex((v) => v.id == item.id);
      this.listaDePlacas.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }

  buscarUnidades() {
    if (this.loggedUser.selectedCompany) {
      this.unidades =
        this.loggedUser.branchMap[this.loggedUser.selectedCompany.name];
      this.unidadesFiltradas.next(this.unidades.slice());
      this.unidadeFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filtrarUnidades();
        });
    }
  }

  escodeOuExibeDiv() {
    this.exibirFiltros = !this.exibirFiltros;
  }

  pesquisar() {
    this.onPesquisar.emit(this.formFiltro.value);
  }

  filtrarPlacas(event:Array<any>) {

    this.dropdownPlacasList = [];
    this.listaDePlacas = [];
    this.placa = new FiltroPlacas();

    if(this.dropdownPlacasAllList && this.dropdownPlacasAllList.length) {
      if(event.includes(0)) {
        this.dropdownPlacasList = this.dropdownPlacasAllList
        .map((c)=> {return {id: c.id, placa: c.placa};});
      } else {
        this.dropdownPlacasList = this.dropdownPlacasAllList
        .filter(d=> event.includes(d.unidade)).map((c)=> {return {id: c.id, placa: c.placa};});
      }
    }
  }
}
