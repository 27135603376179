import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DetalhesListaCompletaMotoristaComponent } from '../detalhes-lista-completa-motorista/detalhes-lista-completa-motorista.component';

@Component({
  selector: 'app-lista-motoristas',
  templateUrl: './lista-motoristas.component.html',
  styleUrls: ['./lista-motoristas.component.scss']
})
export class ListaMotoristasComponent implements OnInit {

  @Input() modelRetorno: any;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  openDialogDetalhesMotirista(){
    this.dialog.open(DetalhesListaCompletaMotoristaComponent, { data: { listaJornadaMotoristaDashboard: this.modelRetorno.listaJornadaMotoristaDashboard }, panelClass: 'indicadores-dialog' });
  }

}
