import { Input, Component } from "@angular/core";

@Component({
    selector: 'message-container',
    styleUrls: ['./message-container.component.css'],
    template: `
        <section class="error-message" [hidden]="!error">
            <div class="container-fluid">
                <span class="fa fa-exclamation-triangle"></span>
                <br />
                <span [innerHtml]="error"></span>
            </div>
        </section>
        <section class="success-message" [hidden]="!success">
            <div class="container-fluid">
                <span class="fa fa-check"></span>
                <br />
                <span [innerHtml]="success"></span>
            </div>
        </section>
        <section class="info-message" [hidden]="!info">
            <div class="container-fluid">
                <span class="fa fa-info-circle"></span>
                <br />
                <span [innerHtml]="info"></span>
            </div>
        </section>
    `
})
export class MessageContainerComponent {
    @Input() error: string;
    @Input() success: string;
    @Input() info: string;
}