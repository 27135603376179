/// <reference types="@types/googlemaps" />
import { Component, NgZone, ElementRef, ViewChild } from "@angular/core";
import { Local } from "../../../../classes/local";
import { BaseFormComponent } from "../../../base/base-form/base-form.component";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalService } from "../../../../services/crud/local.service";
import { RESTResponseVO } from "../../../../classes/restresponsevo";
import { AuthGuard } from "../../../../guards/auth.guard";
import { RetornoLocal } from "app/classes/retorno-local";
import { Address } from "../../../../classes/address";
import { City } from "../../../../classes/city";
import { CountryState } from "../../../../classes/country-state";
import { Coordenada } from "app/classes/coordenada";
import { ParametroLocal } from "app/classes/parametro-local";
import { GrupoLocaisService } from "app/services/crud/grupo-locais.service";
import { GrupoLocais } from "app/classes/grupoLocais";

declare const google: any;

@Component({ 
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class LocalFormComponent extends BaseFormComponent<Local> {

  poligonosSalvos: any[] = [];
  poligonosCriados: any[] = [];
  coordenadas: Coordenada[] = [];
  markers: any[] = [];
  mapa: any;
  @ViewChild('search')
  public searchElementRef: ElementRef;
  public tcode: string;
  private grupoLocaisCarregados: GrupoLocais[];


  constructor(private localService: LocalService,
    private authGuard: AuthGuard,
    private grupoLocaisService: GrupoLocaisService,
    route: ActivatedRoute,
    private ngZone: NgZone,
    private router: Router) { super(route, router, 'adm/local/list'); }

  assembleObjectFromForm(): void {
  }

  loadAuxiliaryLists(): void {
  }

  loadObject(): void {
    this.buscarGrupoLocais();
    if (this.activeID && (this.activeID !== 0)) {
      this.localService.getById(this.activeID).then(response => {
        var resultItem: RetornoLocal = response;
        this.activeObject = resultItem.local;
      });
    } else {
      this.router.navigate(['adm/local/list']);
    }
    this.loading = false;
  }

  initObject(): void {
    this.activeObject = new Local();
    this.activeObject.parametroLocal = new ParametroLocal();
    this.loading = false;
    this.buscarGrupoLocais();
  }

  doCreate(t: Local): Promise<RESTResponseVO<Local>> {
    try {
      t.listaDeCoordenadas = this.coordenadas;
      return this.localService.cadastrarLocal(t);
    } catch (error) {
      console.error(error);
    }

  }

  doEdit(t: Local): Promise<RESTResponseVO<Local>> {
    try {
      t.listaDeCoordenadas = this.coordenadas;
      return this.localService.editarLocal(t);
    } catch (e) {
      console.error(e);
    }

  }

  doDelete(t: Local): Promise<RESTResponseVO<string>> {
    return this.localService.delete(t.id);
  }
  
  setActiveID(): void {
    try {
      if (this.activeObject == undefined) {
        this.activeID = 0;
      } else {
        this.activeID = this.activeObject.id;
      }
    } catch (e) {
      console.log(e);
    }
  }

  center: any = {
    lat: -19.931281,
    lng: -43.939283,
    // mapType: 'satellite'
  };

  onMapReady(map) {
    this.initDrawingManager(map);
    this.pesquisarEnderecoAutomatico();
  }

  initDrawingManager(map: any) {
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ["polygon"]
      },
      polygonOptions: {
        draggable: true,
        editable: true,
        strokeWeight: 0,
        fillOpacity: 0.45,
      },
      polylineOptions: {
        editable: true
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON
    };

    const drawingManager = new google.maps.drawing.DrawingManager(options);
    drawingManager.setMap(map);

    var polylineOptions = drawingManager.get('polylineOptions');
    polylineOptions.fillColor = '#FF8C00';
    drawingManager.set('polylineOptions', polylineOptions);

    var polygonOptions = drawingManager.get('polygonOptions');
    polygonOptions.fillColor = '#FF8C00';
    drawingManager.set('polygonOptions', polygonOptions);


    google.maps.event.addListener(drawingManager, 'polygoncomplete', (polygon) => {
      this.btnLimparCerca();
      this.inserirPoligonosCriados(polygon);
      var coordinates = (polygon.getPath().getArray());
      this.processarListaDeCoordenadas(coordinates);
    });

    this.desenharPoligonoNoMapa(map);

    this.mapa = map;
  }

  buscarGrupoLocais(){
    try{
      var ret: Promise<any> = this.grupoLocaisService.getLista();
      ret.then(res => {
        this.grupoLocaisCarregados = res.listaDeGrupoLocais;
      })
    }
    catch{
      console.error();
    }
  }

  processarListaDeCoordenadas(coordinates) {
    try {
      var cont: number = 1;
      this.coordenadas = [];
      coordinates.forEach(retorno => {
        var latitude = retorno.lat();
        var longitude = retorno.lng();
        var coordenada: Coordenada = new Coordenada();
        coordenada.isRaio = false;
        coordenada.latitude = latitude;
        coordenada.longitude = longitude;
        coordenada.sequencia = cont;
        cont++;
        this.coordenadas.push(coordenada);
      });
    } catch (error) {
      console.error(error);
    }
  }

  inserirPoligonosCriados(polygon) {
    this.poligonosCriados.push(polygon);
  }

  desenharPoligonoNoMapa(map) {
    if (this.activeObject.listaDeCoordenadas == null) {
      this.buscarLocalizacaoUsuario();
    } else {
      try {
        if (this.activeObject.listaDeCoordenadas[0]) {
          var coordinadasPoligonos = [];
          this.center.lat = this.activeObject.listaDeCoordenadas[0].latitude;
          this.center.lng = this.activeObject.listaDeCoordenadas[0].longitude;
          this.activeObject.listaDeCoordenadas.forEach((coordenada => {
            coordinadasPoligonos.push({ lat: coordenada.latitude, lng: coordenada.longitude });
          }));

          var poligono = new google.maps.Polygon({
            paths: coordinadasPoligonos,
            strokeColor: '#FF8C00',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF8C00',
            fillOpacity: 0.35
          });
          poligono.setMap(map);
          this.poligonosSalvos.push(poligono);
          map.zoom = 16;
        }else{
          map.zoom = 12;
        }

        map.panTo(this.center);
      
        map.setMapTypeId(google.maps.MapTypeId.HYBRID);
      } catch (e) {
        console.error(e);
      }
    }
  }

  public btnLimparCerca() {
    try {
      this.poligonosCriados.forEach(poligono => {
        poligono.setMap(null);
      });

      this.poligonosSalvos.forEach(poligono => {
        poligono.setMap(null);
      });

      this.poligonosSalvos.length = 0;
      this.poligonosCriados.length = 0;
    } catch (e) {
      console.log(e);
    }
  }

  private buscarLocalizacaoUsuario() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center.lat = position.coords.latitude;
        this.center.lng = position.coords.longitude;
        this.mapa.panTo(this.center);
      });
    }
  }


  pesquisarEnderecoAutomatico() {
    try {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.markers.length = 0;
          var icon = {
            url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
            size: new google.maps.Size(71, 71),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            scaledSize: new google.maps.Size(25, 25)
          };

          this.center.lat = place.geometry.location.lat();
          this.center.lng = place.geometry.location.lng();
          this.mapa.panTo(this.center);
          this.markers.push(new google.maps.Marker({
            map: this.mapa,
            icon: icon,
            title: place.name,
            position: place.geometry.location
          }));
          this.mapa.zoom = 18;

        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  public btnBuscarEnderecoNoMapa(t: Local) {
    try {
      var address: Address = new Address();
      address.logradouro = t.endereco;
      address.bairro = t.bairro;
      var city: City = new City();
      city.nome = t.cidade;
      var ufCodIbge: CountryState = new CountryState();
      ufCodIbge.nome = t.uf;
      city.ufCodIbge = ufCodIbge;
      address.cidade = city;

      this.localService.buscarLatLngPeloEndereco(address).then(response => {
        var latLng = response.latLng;
        if (latLng) {
          this.markers.length = 0;
          var icon = {
            url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
            size: new google.maps.Size(71, 71),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            scaledSize: new google.maps.Size(25, 25)
          };

          this.center.lat = latLng.latitude;
          this.center.lng = latLng.longitude;
          this.mapa.panTo(this.center);
          this.markers.push(new google.maps.Marker({
            map: this.mapa,
            icon: icon,
            title: "Pesquisa de Endereço",
            position: { lat: latLng.latitude, lng: latLng.longitude }
          }));
          this.mapa.zoom = 18;
        }
      });

    } catch (error) {
      console.error(error);
    }
  }

  get listaTipoLocal() {
    return this.localService.getTipoLocal();
  }

  pesquisarPorCoordenada() {
    try {
      var valor = this.tcode;
      if (valor.includes(",")) {
        var icon = {
          url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25)
        };
        var valores = valor.split(",");
        this.center.lat = Number(valores[0]);
        this.center.lng = Number(valores[1]);
        this.mapa.panTo(this.center);
        this.markers.push(new google.maps.Marker({
          map: this.mapa,
          icon: icon,
          title: "Pesquisa por Coordenadas",
          position: { lat: Number(valores[0]), lng: Number(valores[1]) }
        }));
        this.mapa.zoom = 18;
      }
    } catch (error) {
      console.log(error);
    }
  }
}
