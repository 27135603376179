import { Component, Inject, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertaService } from 'app/services/crud/alerta.service';
import { AuthGuard } from 'app/guards/auth.guard';
import { User } from 'app/classes/user';

@Component({
  selector: 'alerta-dialog',
  templateUrl: './alerta-dialog.component.html',
  styleUrls: ['./alerta-dialog.component.css']
})
export class AlertaDialogComponent {

  totalPages: number[];
  currentPage: number = 0;
  cardsAlertas: any[];
  loggedUser: User;
  accordionStates: { [key: number]: boolean } = {};
  @ViewChildren('contentContainer') contentContainers: QueryList<ElementRef>;
  loading: boolean = false;
  naoMostrar: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AlertaDialogComponent>,
    private alertaService: AlertaService,
    private authGuard: AuthGuard,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.loggedUser = this.authGuard.loggedUser();
  }

  onCancel(): void {
    if (this.naoMostrar) {
      console.log('varrer alertas e marcar como lido');
      this.cardsAlertas.forEach(item => {
        console.log('item', item);
        if(item.visualizado === '-') {
          let loggedUser = this.authGuard.loggedUser();
          console.log('nmarcando como lido', item);
          this.alertaService.marcadoComoLido(loggedUser.id,item.id).then((response)=>{
            if (response.error) {
              console.error(response.error);
            } else {
              this.alertaService.getTotalAlertaNovos(loggedUser.id).then(response =>{
                if (response.error) {
                  console.error(response.error);
                } else {
                  var count = response.response;
                  this.alertaService.updateNotificationsCount(count);
                }
              })
            }
          });
        }
      });
      this.dialogRef.close(false);
    } else {
      this.dialogRef.close(false);
    }
    
  }

  ngOnInit(): void {
    this.getAllAvisosById();
    this.adjustImageSizes();
    const accordionButtons = document.querySelectorAll('.accordion-button');

    accordionButtons.forEach(button => {
      button.addEventListener('click', () => {
        const content = button.nextElementSibling;

        if (content instanceof HTMLElement) {

          if (content.style.display === 'block') {
            content.style.display = 'none';
          } else {
            content.style.display = 'block';
          }
        }
      });
    });


  }

  ngAfterViewInit(): void {
    this.adjustImageSizes();
  }

  getAllAvisosById(page = 0) {
    this.loading = true;
    let loggedUser = this.authGuard.loggedUser();
    this.alertaService.getAllAlertas(loggedUser.id,page).then((response)=>{
      this.loading = false;
      if (response.error) {
        console.error(response.error);
      } else {
        this.cardsAlertas = response.response.content;
        this.currentPage =  response.response.number;
        this.generateTotalPages(response.response.totalPages);
      }
    })
  }

  generateTotalPages(sizePages){
    this.totalPages = [];
    for(let i = 0; i < sizePages; i++){
      this.totalPages.push(i);
    }
  }

  toggleSection(index: number,alertaid: number, visualizado: string): void {
    this.accordionStates[index] = !this.accordionStates[index];
    if(visualizado === '-'){
      let loggedUser = this.authGuard.loggedUser();
      this.alertaService.marcadoComoLido(loggedUser.id,alertaid).then((response)=>{
        if (response.error) {
          console.error(response.error);
        } else {
          const alertaEncontrado = this.encontrarAlertaPorId( this.cardsAlertas, alertaid);
          alertaEncontrado.visualizado = response.response;
          this.alertaService.getTotalAlertaNovos(loggedUser.id).then(response =>{
            if (response.error) {
              console.error(response.error);
          } else {
              var count = response.response;
              this.alertaService.updateNotificationsCount(count);
          }
          })
        }
      });
    }
  }

  isSectionOpen(index: number): boolean {
    return this.accordionStates[index] || false;
  }

  montarTextoTipoAtualizacao(tipoAtualizacao): string {
      switch (tipoAtualizacao) {
        case 'CorrecaoBug':
          return 'Correção de Bug';
        case 'AtualizacaoSeguranca':
          return 'Atualização de Segurança';
        case 'MelhoriaUsabilidade':
          return 'Melhoria de Usabilidade';
        case 'AvisoManutencaoProgramada':
          return 'Aviso de Manutenção Programada';
        case 'InformacaoNovoRecurso':
          return 'Informação sobre Novo Recurso';
        case 'InterrupcaoServico':
          return 'Notificação de Interrupção do Serviço';
        case 'FeedbackUsuarioImplementado':
          return 'Feedback do Usuário Implementado';
        default:
          return '';
      }
    }

    montarClasseTipoAtualizacao(tipoAtualizacao: string): string {
      switch (tipoAtualizacao) {
        case 'CorrecaoBug':
          return 'tipoAtualizacao-correcao-bug';
        case 'AtualizacaoSeguranca':
          return 'tipoAtualizacao-atualizacao-seguranca';
        case 'MelhoriaUsabilidade':
          return 'tipoAtualizacao-melhoria-usabilidade';
        case 'AvisoManutencaoProgramada':
          return 'tipoAtualizacao-manutencao-programada';
        case 'InformacaoNovoRecurso':
          return 'tipoAtualizacao-informacao-recurso';
        case 'InterrupcaoServico':
          return 'tipoAtualizacao-interrupcao-servico';
        case 'FeedbackUsuarioImplementado':
          return 'tipoAtualizacao-feedback-usuario';
        default:
          return 'tipoAtualizacao';
      }
    }

    encontrarAlertaPorId(alertas: any, id: number): any | undefined {
      return alertas.find(alerta => alerta.id === id);
    }


    adjustImageSizes() {
      this.contentContainers.forEach(container => {
        const element = container.nativeElement;
        const images = element.querySelectorAll('img');
        images.forEach((img: HTMLImageElement) => {
          img.style.maxWidth = '100%';
          img.style.height = 'auto';
        });
      });
    }

    soData(data: string) {
      return data.split(' ')[0];
    }
}
