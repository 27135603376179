import { Component } from "@angular/core";
import { BaseFormComponent } from "../../../base/base-form/base-form.component";
import { ActivatedRoute, Router } from "@angular/router";
import { RESTResponseVO } from "../../../../classes/restresponsevo";
import { Operador } from "../../../../classes/operador";
import { OperadorService } from "../../../../services/crud/operador.service";
import { MacrosService } from "../../../../services/crud/macros.service";
import { AuthGuard } from "../../../../guards/auth.guard";
import { RetornoOperador } from "app/classes/retorno-operador";
import { Macros } from "app/classes/macros";
import { RetornoMacros } from "app/classes/retorno-macros";
import { VehicleService } from "app/services/crud/vehicle.service";
import { Veiculo } from "app/classes/veiculo";
import { OperadorVeiculo } from "app/classes/operador-veiculo";

@Component({
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class OperadorFormComponent extends BaseFormComponent<Operador> {

  private listaDeMacros: Macros[];
  retornoVeiculos: any;
  listaVeiculos: Veiculo[];
  listaOperadorVeiculo: OperadorVeiculo[] = [];
  operadorVeiculo: OperadorVeiculo;

  newVehicle: Object;
  editingVehicle: boolean[] = new Array();

  showWait: boolean = false;


  constructor(private service: OperadorService,
    private macrosService: MacrosService,
    private vehicleService: VehicleService,
    route: ActivatedRoute,
    private authGuard: AuthGuard,
    private router: Router) {
    super(route, router, 'adm/operador/list');
    this.buscarListaMacros();
  }

  assembleObjectFromForm(): void {
  }

  loadAuxiliaryLists(): void {
  }

  loadObject(): void {
    if (this.activeID && (this.activeID !== 0)) {
      this.service.getById(this.activeID).then(response => {
        var resultItem: RetornoOperador = response;
        this.activeObject = resultItem.operador;
        this.loadVehiclesList(resultItem.operador.listaOperadorVeiculo);
        this.operadorVeiculo = new OperadorVeiculo();
      });
    } else {
      this.router.navigate(['adm/operador/list']);
    }
    this.loading = false;
  }


  initObject(): void {
    this.activeObject = new Operador();
    this.loading = false;
    this.buscarListaMacros();
    this.loadVehiclesList(null);
    this.operadorVeiculo = new OperadorVeiculo();
  }


  doCreate(t: Operador): Promise<RESTResponseVO<Operador>> {
    t.listaOperadorVeiculo = this.listaOperadorVeiculo;
    return this.service.cadastrarOperador(t);
  }

  doEdit(t: Operador): Promise<RESTResponseVO<Operador>> {
    try {
      t.listaOperadorVeiculo = this.listaOperadorVeiculo;
      return this.service.editarOperador(t);
    } catch (e) {
      console.error(e);
    }

  }

  doDelete(t: Operador): Promise<RESTResponseVO<string>> {
    return null;
  }


  setActiveID(): void {
    try {
      if (this.activeObject == undefined) {
        this.activeID = 0;
      } else {
        this.activeID = this.activeObject.id;
      }
    } catch (e) {
      console.log(e);
    }

  }

  buscarListaMacros() {
    try {
      var ret: Promise<any> = this.macrosService.getLista();
      ret.then(res => {
        let retornoMacros: RetornoMacros = res;
        this.listaDeMacros = retornoMacros.listaDeMacros;
      });
    } catch (e) {
      console.error(e);
    }
  }

  toggleVehicleInsert() {
    this.editingVehicle[-1] = !this.editingVehicle[-1];
    this.newVehicle = new Object();
  }

  loadVehiclesList(listaOperadorVeiculoTemp) {
    this.editingVehicle[-1] = false;
    this.vehicleService.getList().then(response => {
      this.retornoVeiculos = response;
      if (this.retornoVeiculos.codigoRetorno == 61) {
        this.listaVeiculos = this.retornoVeiculos.listaDeVeiculos;
        if (listaOperadorVeiculoTemp) {
          for (var i = 0; i < this.listaVeiculos.length; i++) {
            var veiculo = this.listaVeiculos[i];

            var result = listaOperadorVeiculoTemp.find(f => f.veiculoOrion == veiculo.id);
            if (result) {
              var operadorVeiculoT = new OperadorVeiculo();
              operadorVeiculoT.placa = veiculo.placa;
              operadorVeiculoT.veiculoOrion = veiculo.id;
              this.listaOperadorVeiculo.push(operadorVeiculoT);
              
            }
          }
        }
      }
      this.showWait = true;
    });
  }

  deleteOperadorVeiculoValue(index) {
    this.listaOperadorVeiculo.splice(index, 1);
  }

  addOperadorVeiculoValue() {

    var result = this.listaOperadorVeiculo.find(f => f.veiculoOrion == this.operadorVeiculo.veiculoOrion);
    if (result) {
      return;
    }

    for (var i = 0; i < this.listaVeiculos.length; i++) {
      var veiculo = this.listaVeiculos[i];
      if (veiculo.id == this.operadorVeiculo.veiculoOrion) {
        this.operadorVeiculo.placa = veiculo.placa;
        break;
      }
    }
    this.listaOperadorVeiculo.push(this.operadorVeiculo);
    this.operadorVeiculo = new OperadorVeiculo();
  }

}
