import { Component, OnInit, ViewChild } from '@angular/core';
import { Filtro } from 'app/classes/filtro';
import { RankingMotoristaService } from 'app/services/crud/ranking-motorista.service';

@Component({
  selector: 'app-tempo-faixa',
  templateUrl: './tempo-faixa.component.html',
  styleUrls: ['./tempo-faixa.component.css']
})
export class TempoFaixaComponent implements OnInit {

  @ViewChild('dataTable') table;
  dataTable: any;
  dtOption: any = {};
  public filtro: Filtro;

  constructor(private rankingMotoristaService: RankingMotoristaService) { }

  ngOnInit() {
    try {
      this.filtro = new Filtro();
      this.dtOption = {
        "iDisplayLength": 7,
        "bPaginate": true,
        "bLengthChange": false,
        "bFilter": true,
        "bInfo": false,
        "bAutoWidth": false,
        "language": {
          "lengthMenu": "Mostrando _MENU_ registros por pagina",
          "zeroRecords": "Nenhum registro encontrado",
          "search": "Pesquisar",
          "oPaginate": {
            "sNext": "Próximo",
            "sPrevious": "Anterior",
            "sFirst": "Primeiro",
            "sLast": "Último"
          }
        }, "columnDefs": [
          //Largura das Colunas
          { "className": "text-center", targets: [0, 1, 2, 3, 4, 5] },
          { "width": "20%", targets: 0 },
          { "width": "16%", targets: 1 },
          { "width": "16%", targets: 2 },
          { "width": "16%", targets: 3 },
          { "width": "16%", targets: 4 },
          { "width": "16%", targets: 5 }

        ],
        'dom': 'Bfrtip',
        'buttons': [
          'csv', 'excel', 'print'
        ]
      };
      this.dataTable = $(this.table.nativeElement);
      this.dataTable.DataTable(this.dtOption);
    } catch (error) {
      console.log(error);
    }
  }

  doSearch() {
    try {
      this.rankingMotoristaService.getTempoFaixa(this.filtro).then(response => {
        let listaTemposDeFaixa = response.listaTemposDeFaixa;
        this.dataTable.DataTable().clear().draw();
        if (listaTemposDeFaixa && listaTemposDeFaixa.length > 0) {
          listaTemposDeFaixa.forEach(e=>{
            this.dataTable.DataTable().row.add([e.motorista, e.tempoFaixaAzul, e.tempoFaixaExtraEconomica, e.tempoFaixaVerde,
               e.tempoFaixaAmarela, e.tempoFaixaVermelha]);
          });
          this.dataTable.DataTable().draw();
        }
        
      });
    } catch (error) {
      console.log(error);
    }
  } 
}
