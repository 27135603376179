import { CRUDService } from "../base/crud.service";
import { Http } from "@angular/http";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { AuthGuard } from "../../guards/auth.guard";
import { RESTResponseVO } from "app/classes/restresponsevo";
import { User } from "../../classes/user";
import { FiltroOpcoesTelemetria } from "app/classes/filtro-opcoes-telemetria";
import { RetornoTelemetria } from "app/classes/retorno-telemetria";
import { RetornoTelemetriaFront } from "app/classes/retorno-telemetria-front";
import { DetalheEficienciaVeiculo } from "app/classes/detalhe-eficiencia-veiculo";
import { ResumoContadoresTelemetria } from "app/classes/resumo-contadores-telemetria";
import { ResumoEficienciaTelemetria } from "app/classes/resumo-eficiencia-telemetria";
import { RetornoMapaEficiencia } from "app/classes/retorno-mapa-eficiencia";
import { ItemEficienciaTelemetria } from "app/classes/ItemEficienciaTelemetria";
import { DetalheDoIndicador } from "app/classes/detalhe-do-indicador";

@Injectable({
  providedIn: "root",
})
export class ControleIndicadoresTelemetriaService extends CRUDService<FiltroOpcoesTelemetria> {
  public company: number;
  constructor(http: Http, public authGuard: AuthGuard) {
    super(http, environment.baseURL + "App/v2/web/telemetria/", true);
    var loggedUser: User = authGuard.loggedUser();
    this.company = loggedUser.selectedCompany.id;
  }

  async buscarDetalheDoIndicador(listaDeTelemetria: number[], porcentagemSelecionada: string = null): Promise<DetalheDoIndicador[]> {
    const url = `${environment.baseURL}/App/v2/web/telemetria/buscarDetalheDoIndicador`;
    const payload = {
      tipo: porcentagemSelecionada.startsWith('Limite de velocidade') ? 1 : 0,
      listaDeTelemetria
    };
    console.info(JSON.stringify(payload));
    return this.http
      .post(url, JSON.stringify(payload), { headers: this.headers })
      .toPromise()
      .then((response) => {
        return response.json();
      })
      .then((response: { listaDetalheDoIndicador: DetalheDoIndicador[] }) => {
        return response.listaDetalheDoIndicador;
      })
      .catch(this.handleError);
  }

  buscarIndicadoresTelemetriaFiltrados(
    filtro: FiltroOpcoesTelemetria
  ): Promise<RESTResponseVO<RetornoTelemetriaFront>> {
    const url = `${environment.baseURL}/App/v2/web/telemetria/buscar`;
    return this.enviarRequisicao(filtro, url);
  }

  buscarMapaEficiencia(
    filtro: FiltroOpcoesTelemetria
  ): Promise<RetornoMapaEficiencia> {
    //const url = `${environment.baseURL}/App/v2/web/telemetria/mapaDeEficiencia`;
    const url =
      "http://sbh.rodoviasolucoes.com.br/CJMHOMOLSBH/App/v2/web/telemetria/mapaDeEficiencia";
    try {
      return this.http
        .post(url, JSON.stringify(filtro), { headers: this.headers })
        .toPromise()
        .then((response) => {
          if (this.restResponseWrapper) {
            var retorno: any = response.json();
            if (retorno.codigoRetorno == -1) {
              return new RetornoMapaEficiencia();
            } else {
              var retornoBackend: RetornoMapaEficiencia =
                response.json() as RetornoMapaEficiencia;
              return retornoBackend;
            }
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }

  enviarRequisicao(
    filtro: FiltroOpcoesTelemetria,
    url: string
  ): Promise<RESTResponseVO<RetornoTelemetriaFront>> {
    try {
      return this.http
        .post(url, JSON.stringify(filtro), { headers: this.headers })
        .toPromise()
        .then((response) => {
          if (this.restResponseWrapper) {
            var retorno: any = response.json();
            if (retorno.codigoRetorno == -1) {
              return new RetornoTelemetriaFront();
            } else {
              var retornoBackend: RetornoTelemetria =
                response.json() as RetornoTelemetria;
              var retornoFront = this.alterarRetorno(retornoBackend);
              return retornoFront;
            }
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }

  alterarRetorno(retorno: RetornoTelemetria) {
    var retornoFront = new RetornoTelemetriaFront();
    var listaTopDez = this.adicionarListaTopDez(retorno);
    var lista = this.adicionarLista(retorno);
    var contadores = this.adicionarIndicadores(retorno);
    var eficiencia = this.adicionarEficiencia(retorno);

    retornoFront.consumoCombustivel = 0;
    retornoFront.contadoresIndicadoresTelemetria = contadores;
    retornoFront.detalhesEficienciaVeiculoTopDez = listaTopDez;
    retornoFront.listaDetalhesEficienciaVeiculo = lista;
    retornoFront.indicadoresEficienciaTelemetria = eficiencia;

    return retornoFront;
  }

  adicionarEficiencia(retorno: RetornoTelemetria) {
    var eficiencia = new ResumoEficienciaTelemetria();

    eficiencia.amarelaComAceleracao = retorno.totalAmarelaComAcelaracao ? retorno.totalAmarelaComAcelaracao : new ItemEficienciaTelemetria();
    eficiencia.amarelaSemAceleracao = retorno.totalAmarelaSemAcelaracao ? retorno.totalAmarelaSemAcelaracao : new ItemEficienciaTelemetria();
    eficiencia.batendoTransmissaoComAceleracao = retorno.totalBatendoTransmissaoComAcelaracao ? retorno.totalBatendoTransmissaoComAcelaracao : new ItemEficienciaTelemetria();
    eficiencia.batendoTransmissaoSemAceleracao = retorno.totalBatendoTransmissaoSemAcelaracao ? retorno.totalBatendoTransmissaoSemAcelaracao : new ItemEficienciaTelemetria();
    eficiencia.ecominicaSemAceleracao = retorno.totalEconomicaSemAcelaracao ? retorno.totalEconomicaSemAcelaracao : new ItemEficienciaTelemetria();
    eficiencia.ecominocaComAceleracao = retorno.totalEconomicaComAcelaracao ? retorno.totalEconomicaComAcelaracao : new ItemEficienciaTelemetria();
    eficiencia.limiteDeVelocidade =
      retorno.totalLimiteDeVelocidade ? retorno.totalLimiteDeVelocidade : new ItemEficienciaTelemetria();
    eficiencia.marchaLentaEmMovimento =
      retorno.totalMarchaLentaEmMovimento ? retorno.totalMarchaLentaEmMovimento : new ItemEficienciaTelemetria();
    eficiencia.marchaLentaParado =
      retorno.totalMarchaLentaParado ? retorno.totalMarchaLentaParado : new ItemEficienciaTelemetria();
    eficiencia.rpmMaximo =
      retorno.totalRPMMaximo ? retorno.totalRPMMaximo : new ItemEficienciaTelemetria();
    eficiencia.sobrerotacaoComAceleracao =
      retorno.totalSobrerotacaoComAcelaracao ? retorno.totalSobrerotacaoComAcelaracao : new ItemEficienciaTelemetria();

    eficiencia.sobrerotacaoSemAceleracao =
      retorno.totalSobrerotacaoSemAcelaracao ? retorno.totalSobrerotacaoSemAcelaracao : new ItemEficienciaTelemetria();
    eficiencia.superEconomicaComAceleracao =
      retorno.totalSuperEconomicaComAcelaracao ? retorno.totalSuperEconomicaComAcelaracao : new ItemEficienciaTelemetria();
    eficiencia.superEconomicaSemAceleracao =
      retorno.totalSuperEconomicaSemAcelaracao ? retorno.totalSuperEconomicaSemAcelaracao : new ItemEficienciaTelemetria();

    return eficiencia;
  }

  adicionarIndicadores(retorno: RetornoTelemetria) {
    var contadores = new ResumoContadoresTelemetria();

    contadores.horasLigado =
      retorno.horasLigados != null ? retorno.horasLigados.toString() : "00:00";
    contadores.horasOciosas =
      retorno.horasDesligado != null
        ? retorno.horasDesligado.toString()
        : "00:00";
    contadores.kmLitro =
      retorno.mediaKmLitro != null ? retorno.mediaKmLitro : 0;
    contadores.kmRodado =
      retorno.totalKmRodado != null ? retorno.totalKmRodado : 0;
    contadores.litrosConsumidos =
      retorno.totalLitrosConsumidos != null ? retorno.totalLitrosConsumidos : 0;
    contadores.veiculos =
      retorno.listaDeVeiculos == null ? 0 : retorno.listaDeVeiculos.length;
    contadores.motoristas = 0;

    return contadores;
  }

  adicionarListaTopDez(retorno: RetornoTelemetria) {
    var listaTopDez = new Array<DetalheEficienciaVeiculo>();

    if (retorno.listaDeVeiculos != null && retorno.listaDeVeiculos.length > 0) {
      retorno.listaDeVeiculos.forEach((element) => {
        var detalhes = new DetalheEficienciaVeiculo();

        detalhes.placa = element.placa != null ? element.placa : "0";
        detalhes.modelo = element.modelo != null ? element.modelo : "-";
        detalhes.kmRodado = element.kmRodado != null ? element.kmRodado : 0;
        detalhes.consumoEmLitros =
          element.consumoEmLitros != null ? element.consumoEmLitros : 0;
        detalhes.kmLitros = element.kmLitros != null ? element.kmLitros : 0;
        detalhes.horasLigado =
          element.horasLigado != null ? element.horasLigado : 0;
        detalhes.marchaLentaParadoPorcentagem =
          element.marchaLentaParadoPorcentagem != null
            ? element.marchaLentaParadoPorcentagem
            : "0";
        detalhes.marchaLentaDeslocandoPorcentagem =
          element.marchaLentaDeslocandoPorcentagem != null
            ? element.marchaLentaDeslocandoPorcentagem
            : "0";
        detalhes.batendoTransmissaoSemAcelaracaoPorcentagem =
          element.batendoTransmissaoSemAcelaracaoPorcentagem != null
            ? element.batendoTransmissaoSemAcelaracaoPorcentagem
            : "0";
        detalhes.batendoTransmissaoComAcelaracaoPorcentagem =
          element.batendoTransmissaoComAcelaracaoPorcentagem != null
            ? element.batendoTransmissaoComAcelaracaoPorcentagem
            : "0";
        detalhes.economiaComAcelaracaoPorcentagem =
          element.economiaComAcelaracaoPorcentagem != null
            ? element.economiaComAcelaracaoPorcentagem
            : "0";
        detalhes.economiaSemAcelaracaoPorcentagem =
          element.economiaSemAcelaracaoPorcentagem != null
            ? element.economiaSemAcelaracaoPorcentagem
            : "0";
        detalhes.superEconomiaSemAcelaracaoPorcentagem =
          element.superEconomiaSemAcelaracaoPorcentagem != null
            ? element.superEconomiaSemAcelaracaoPorcentagem
            : "0";
        detalhes.superEconomiaComAcelaracaoPorcentagem =
          element.superEconomiaComAcelaracaoPorcentagem != null
            ? element.superEconomiaComAcelaracaoPorcentagem
            : "0";
        detalhes.amarelaComAcelaracaoPorcentagem =
          element.amarelaComAcelaracaoPorcentagem != null
            ? element.amarelaComAcelaracaoPorcentagem
            : "0";
        detalhes.amarelaSemAcelaracaoPorcentagem =
          element.amarelaSemAcelaracaoPorcentagem != null
            ? element.amarelaSemAcelaracaoPorcentagem
            : "0";
        detalhes.rpmMaximoPorcentagem =
          element.rpmMaximoPorcentagem != null
            ? element.rpmMaximoPorcentagem
            : "0";
        detalhes.limiteDeVelocidade =
          element.limiteDeVelocidade != null ? element.limiteDeVelocidade : 0;
        detalhes.sobrerotacaoSemAcelaracaoPorcentagem =
          element.sobrerotacaoSemAcelaracaoPorcentagem != null
            ? element.sobrerotacaoSemAcelaracaoPorcentagem
            : "0";
        detalhes.sobrerotacaoComAcelaracaoPorcentagem =
          element.sobrerotacaoComAcelaracaoPorcentagem != null
            ? element.sobrerotacaoComAcelaracaoPorcentagem
            : "0";

        detalhes.erro = element.erro != null ? element.erro : "";

        listaTopDez.push(detalhes);
      });

      return listaTopDez.sort((item) => item.kmRodado).slice(0, 9);
    }
  }

  adicionarLista(retorno: RetornoTelemetria) {
    var lista = new Array<DetalheEficienciaVeiculo>();

    if (retorno.listaDeVeiculos != null && retorno.listaDeVeiculos.length > 0) {
      retorno.listaDeVeiculos.forEach((element) => {
        var detalhes = new DetalheEficienciaVeiculo();

        detalhes.placa = element.placa != null ? element.placa : "0";
        detalhes.modelo = element.modelo != null ? element.modelo : "-";
        detalhes.kmRodado = element.kmRodado != null ? element.kmRodado : 0;
        detalhes.consumoEmLitros =
          element.consumoEmLitros != null ? element.consumoEmLitros : 0;
        detalhes.kmLitros = element.kmLitros != null ? element.kmLitros : 0;
        detalhes.horasLigado =
          element.horasLigado != null ? element.horasLigado : 0;
        detalhes.marchaLentaParadoPorcentagem =
          element.marchaLentaParadoPorcentagem != null
            ? element.marchaLentaParadoPorcentagem
            : "0";
        detalhes.marchaLentaDeslocandoPorcentagem =
          element.marchaLentaDeslocandoPorcentagem != null
            ? element.marchaLentaDeslocandoPorcentagem
            : "0";
        detalhes.batendoTransmissaoSemAcelaracaoPorcentagem =
          element.batendoTransmissaoSemAcelaracaoPorcentagem != null
            ? element.batendoTransmissaoSemAcelaracaoPorcentagem
            : "0";
        detalhes.batendoTransmissaoComAcelaracaoPorcentagem =
          element.batendoTransmissaoComAcelaracaoPorcentagem != null
            ? element.batendoTransmissaoComAcelaracaoPorcentagem
            : "0";
        detalhes.economiaComAcelaracaoPorcentagem =
          element.economiaComAcelaracaoPorcentagem != null
            ? element.economiaComAcelaracaoPorcentagem
            : "0";
        detalhes.economiaSemAcelaracaoPorcentagem =
          element.economiaSemAcelaracaoPorcentagem != null
            ? element.economiaSemAcelaracaoPorcentagem
            : "0";
        detalhes.superEconomiaSemAcelaracaoPorcentagem =
          element.superEconomiaSemAcelaracaoPorcentagem != null
            ? element.superEconomiaSemAcelaracaoPorcentagem
            : "0";
        detalhes.superEconomiaComAcelaracaoPorcentagem =
          element.superEconomiaComAcelaracaoPorcentagem != null
            ? element.superEconomiaComAcelaracaoPorcentagem
            : "0";
        detalhes.amarelaComAcelaracaoPorcentagem =
          element.amarelaComAcelaracaoPorcentagem != null
            ? element.amarelaComAcelaracaoPorcentagem
            : "0";
        detalhes.amarelaSemAcelaracaoPorcentagem =
          element.amarelaSemAcelaracaoPorcentagem != null
            ? element.amarelaSemAcelaracaoPorcentagem
            : "0";
        detalhes.rpmMaximoPorcentagem =
          element.rpmMaximoPorcentagem != null
            ? element.rpmMaximoPorcentagem
            : "0";
        detalhes.limiteDeVelocidade =
          element.limiteDeVelocidade != null ? element.limiteDeVelocidade : 0;
        detalhes.sobrerotacaoSemAcelaracaoPorcentagem =
          element.sobrerotacaoSemAcelaracaoPorcentagem != null
            ? element.sobrerotacaoSemAcelaracaoPorcentagem
            : "0";
        detalhes.sobrerotacaoComAcelaracaoPorcentagem =
          element.sobrerotacaoComAcelaracaoPorcentagem != null
            ? element.sobrerotacaoComAcelaracaoPorcentagem
            : "0";

        detalhes.erro = element.erro != null ? element.erro : "";

        lista.push(detalhes);
      });

      return lista;
    }
  }
}
