import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-base-container',
  templateUrl: './base-container.component.html',
  styleUrls: ['./base-container.component.css']
})
export class BaseContainerComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    // Workaround to get the icons to show after logoff
    $.getScript('./assets/js/iconutil.js');
  }
}