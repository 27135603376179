import { Component, ViewChild, AfterViewInit, OnDestroy } from "@angular/core";
import { Veiculo } from "../../../../classes/veiculo";
import { Modelo } from "../../../../classes/modelo";
import { VehicleType } from "../../../../classes/vehicle-type";
import { BaseFormComponent } from "../../../base/base-form/base-form.component";
import { ActivatedRoute, Router } from "@angular/router";
import { VeiculoService } from "../../../../services/crud/veiculo.service";
import { ModeloVeiculoService } from "../../../../services/crud/modelo-veiculo.service";
import { EquipamentoService } from "../../../../services/crud/equipamento.service";
import { VehicleTypeService } from "../../../../services/crud/vehicle-type.service";
import { RESTResponseVO } from "../../../../classes/restresponsevo";
import { AuthGuard } from "../../../../guards/auth.guard";
import { RetornoVeiculo } from "app/classes/retorno-veiculo";
import { Equipamento } from "app/classes/equipamento";
import { FormGroup, FormControl } from "@angular/forms";
import { ReplaySubject, Subject } from "rxjs";
import { MatSelect } from "@angular/material";
import { take, takeUntil } from 'rxjs/operators';
import { Select } from "../../../../classes/select";
import { User } from "app/classes/user";



@Component({
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class VeiculoFormComponent extends BaseFormComponent<Veiculo> implements AfterViewInit, OnDestroy {

  /**
   * Para cada lista criar da seguinte maneira
   * 
   */
  //INICIO EQUIPAMENTO
  protected equipamentos: Equipamento[];
  public equipamentoCtrl: FormControl = new FormControl();
  public equipamentoFilterCtrl: FormControl = new FormControl();
  public filteredEquipamentos: ReplaySubject<Equipamento[]> = new ReplaySubject<Equipamento[]>(1);
  @ViewChild('singleSelect') singleSelect: MatSelect;
  protected _onDestroy = new Subject<void>();
  //FIM EQUIPAMENTO

  //INICIO EQUIPAMENTO DOIS
  protected equipamentosDois: Equipamento[];
  public equipamentoCtrlDois: FormControl = new FormControl();
  public equipamentoFilterCtrlDois: FormControl = new FormControl();
  public filteredEquipamentosDois: ReplaySubject<Equipamento[]> = new ReplaySubject<Equipamento[]>(1);
  @ViewChild('singleSelectDois') singleSelectDois: MatSelect;
  protected _onDestroyDois = new Subject<void>();
  //FIM EQUIPAMENTO DOIS

  branchList: any;
  isAdmin: boolean;


  //INICIO MODELO EQUIPAMENTO
  protected modelosVeiculos: Modelo[];
  public modeloVeiculoCtrl: FormControl = new FormControl();
  public modeloVeiculoFilterCtrl: FormControl = new FormControl();
  public filteredModelosVeiculos: ReplaySubject<Modelo[]> = new ReplaySubject<Modelo[]>(1);
  @ViewChild('singleSelectModeloVeiculo') singleSelectModeloVeiculo: MatSelect;
  protected _onDestroyModeloVeiculo = new Subject<void>();
  //FIM MODELO EQUIPAMENTO

  //INICIO TIPO DE ARTICULACAO
  protected tiposArticulacaoVeiculos: VehicleType[];
  public tipoArticulacaoVeiculoCtrl: FormControl = new FormControl();
  public tipoArticulacaoVeiculoFilterCtrl: FormControl = new FormControl();
  public filteredTiposArticulacaoVeiculos: ReplaySubject<VehicleType[]> = new ReplaySubject<VehicleType[]>(1);
  @ViewChild('singleSelectTipoArticulacaoVeiculo') singleSelectTipoArticulacaoVeiculo: MatSelect;
  protected _onDestroyTipoArticulacaoVeiculo = new Subject<void>();
  //FIM TIPO DE ARTICULACAO

  //INICIO TIPO DE VEICULO
  protected tiposVeiculos: Select[];
  public tipoVeiculoCtrl: FormControl = new FormControl();
  public tipoVeiculoFilterCtrl: FormControl = new FormControl();
  public filteredTiposVeiculos: ReplaySubject<Select[]> = new ReplaySubject<Select[]>(1);
  @ViewChild('singleSelectTipoVeiculo') singleSelectTipoVeiculo: MatSelect;
  protected _onDestroyTipoVeiculo = new Subject<void>();
  //FIM TIPO DE VEICULO

  private addVeiculoForm: FormGroup = new FormGroup({});

  constructor(private veiculoService: VeiculoService,
    private equipamentoService: EquipamentoService,
    private modeloVeiculoService: ModeloVeiculoService,
    private vehicleTypeService: VehicleTypeService,
    private authGuard: AuthGuard,
    route: ActivatedRoute,
    private router: Router) {
    super(route, router, 'adm/veiculo/list');
    var loggedUser: User = authGuard.loggedUser();
    this.isAdmin = loggedUser.claimList.some(x => x.name === "admin");
    this.branchList = loggedUser.branchList;
  }

  assembleObjectFromForm(): void {
  }

  loadAuxiliaryLists(): void {
  }

  loadObject(): void {
    if (this.activeID && (this.activeID !== 0)) {
      this.veiculoService.getById(this.activeID).then(response => {
        var resultItem: RetornoVeiculo = response;
        this.activeObject = resultItem.veiculo;
        if (resultItem.veiculo.unidadeId != null && resultItem.veiculo.unidadeId > 0) {
          var ret = this.branchList.find(x => x.id == resultItem.veiculo.unidadeId);
          this.activeObject.unidade = ret;
        }
        this.buscarListaDeEquipamentos();
        this.buscarListaDeModeloVeiculo();
        this.buscarListaDeTipoArticulacaoVeiculo();
        this.buscarListaDeTipoVeiculo();
      });
    } else {
      this.router.navigate(['adm/veiculo/list']);
    }
    this.loading = false;
  }

  initObject(): void {
    this.activeObject = new Veiculo();
    this.loading = false;
    this.buscarListaDeEquipamentos();
    this.buscarListaDeModeloVeiculo();
    this.buscarListaDeTipoArticulacaoVeiculo();
    this.buscarListaDeTipoVeiculo();
  }

  doCreate(t: Veiculo): Promise<RESTResponseVO<Veiculo>> {
    return this.veiculoService.cadastrarVeiculo(t);
  }

  doEdit(t: Veiculo): Promise<RESTResponseVO<Veiculo>> {
    try {
      return this.veiculoService.editarVeiculo(t);
    } catch (e) {
      console.error(e);
    }

  }

  doDelete(t: Veiculo): Promise<RESTResponseVO<string>> {
    return null;
  }

  setActiveID(): void {
    try {
      if (this.activeObject == undefined) {
        this.activeID = 0;
      } else {
        this.activeID = this.activeObject.id;
      }
    } catch (e) {
      console.log(e);
    }

  }

  buscarListaDeEquipamentos() {
    try {
      var ret: Promise<any> = this.equipamentoService.getLista();
      ret.then(res => {
        this.equipamentos = res.listaEquipamento;
        if (this.activeObject && this.activeObject.equipamento) {
          var eqp = this.equipamentos.find(x => x.id == this.activeObject.equipamento.id);
          this.equipamentoCtrl.setValue(eqp);
        }
        this.filteredEquipamentos.next(this.equipamentos.slice());
        this.equipamentoFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterEquipamentos();
          });


        this.equipamentosDois = res.listaEquipamento;
        if (this.activeObject && this.activeObject.equipamentoDois) {
          var eqp = this.equipamentosDois.find(x => x.id == this.activeObject.equipamentoDois.id);
          this.equipamentoCtrlDois.setValue(eqp);
        }
        this.filteredEquipamentosDois.next(this.equipamentosDois.slice());
        this.equipamentoFilterCtrlDois.valueChanges
          .pipe(takeUntil(this._onDestroyDois))
          .subscribe(() => {
            this.filterEquipamentosDois();
          });

      });
    } catch (error) {
      console.error(error);
    }
  }

  buscarListaDeModeloVeiculo() {
    try {
      var retModelo: Promise<any> = this.modeloVeiculoService.getLista();
      retModelo.then(rModelo => {
        this.modelosVeiculos = rModelo.listaDeModelo;
        if (this.activeObject && this.activeObject.modelo) {
          var mod = this.modelosVeiculos.find(x => x.id == this.activeObject.modelo.id);
          this.modeloVeiculoCtrl.setValue(mod);
        } else {
          this.modeloVeiculoCtrl.setValue(this.modelosVeiculos[0]);
        }
        this.filteredModelosVeiculos.next(this.modelosVeiculos.slice());
        this.modeloVeiculoFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroyModeloVeiculo))
          .subscribe(() => {
            this.filterModelosVeiculos();
          });
      });
    } catch (error) {
      console.error(error);
    }
  }

  buscarListaDeTipoArticulacaoVeiculo() {
    try {
      var retTipoVeiculo: Promise<any> = this.vehicleTypeService.getListByCompany();
      retTipoVeiculo.then(rTipoVeiculo => {
        this.tiposArticulacaoVeiculos = rTipoVeiculo.response;
        if (this.activeObject && this.activeObject.tipoDeVeiculo) {
          var tipo = this.tiposArticulacaoVeiculos.find(x => x.id == this.activeObject.tipoDeVeiculo);
          this.tipoArticulacaoVeiculoCtrl.setValue(tipo);
        }
        this.filteredTiposArticulacaoVeiculos.next(this.tiposArticulacaoVeiculos.slice());
        this.tipoArticulacaoVeiculoFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroyTipoArticulacaoVeiculo))
          .subscribe(() => {
            this.filterTiposArticuladoVeiculos();
          });
      });
    } catch (error) {
      console.error(error);
    }
  }

  buscarListaDeTipoVeiculo() {
    try {
      this.tiposVeiculos = this.veiculoService.getTipoVeiculo();;
      if (this.activeObject && this.activeObject.tipo) {
        var tipo = this.tiposVeiculos.find(x => x.valor == this.activeObject.tipo);
        this.tipoVeiculoCtrl.setValue(tipo);
      } else {
        this.tipoVeiculoCtrl.setValue(this.tiposVeiculos[0]);
      }
      this.filteredTiposVeiculos.next(this.tiposVeiculos.slice());
      this.tipoVeiculoFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroyTipoVeiculo))
        .subscribe(() => {
          this.filterTiposVeiculos();
        });

    } catch (error) {
      console.error(error);
    }
  }

  protected filterEquipamentos() {
    if (!this.equipamentos) {
      return;
    }
    let search = this.equipamentoFilterCtrl.value;
    if (!search) {
      this.filteredEquipamentos.next(this.equipamentos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredEquipamentos.next(
      this.equipamentos.filter(equipamento => equipamento.imei.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterEquipamentosDois() {
    if (!this.equipamentosDois) {
      return;
    }
    let search = this.equipamentoFilterCtrlDois.value;
    if (!search) {
      this.filteredEquipamentosDois.next(this.equipamentosDois.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredEquipamentosDois.next(
      this.equipamentosDois.filter(equipamento => equipamento.imei.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterModelosVeiculos() {
    if (!this.modelosVeiculos) {
      return;
    }
    let search = this.modeloVeiculoFilterCtrl.value;
    if (!search) {
      this.filteredModelosVeiculos.next(this.modelosVeiculos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredModelosVeiculos.next(
      this.modelosVeiculos.filter(modelo => modelo.marca.toLowerCase().indexOf(search) > -1)
    );
  }


  protected filterTiposArticuladoVeiculos() {
    if (!this.tiposArticulacaoVeiculos) {
      return;
    }
    let search = this.tipoArticulacaoVeiculoFilterCtrl.value;
    if (!search) {
      this.filteredTiposArticulacaoVeiculos.next(this.tiposArticulacaoVeiculos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredTiposArticulacaoVeiculos.next(
      this.tiposArticulacaoVeiculos.filter(tipo => tipo.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterTiposVeiculos() {
    if (!this.tiposVeiculos) {
      return;
    }
    let search = this.tipoVeiculoFilterCtrl.value;
    if (!search) {
      this.filteredTiposVeiculos.next(this.tiposVeiculos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredTiposVeiculos.next(
      this.tiposVeiculos.filter(tipo => tipo.descricao.toLowerCase().indexOf(search) > -1)
    );
  }


  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this._onDestroyDois.next();
    this._onDestroyDois.complete();
    this._onDestroyModeloVeiculo.next();
    this._onDestroyModeloVeiculo.complete();
    this._onDestroyTipoArticulacaoVeiculo.next();
    this._onDestroyTipoArticulacaoVeiculo.complete();
    this._onDestroyTipoVeiculo.next();
    this._onDestroyTipoVeiculo.complete();
  }


  protected setInitialValue() {
    this.filteredEquipamentos
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.singleSelect) {
          this.singleSelect.compareWith = (a: Equipamento, b: Equipamento) => a && b && a.id === b.id;
        }
      });

    this.filteredEquipamentosDois
      .pipe(take(1), takeUntil(this._onDestroyDois))
      .subscribe(() => {
        if (this.singleSelectDois) {
          this.singleSelectDois.compareWith = (a: Equipamento, b: Equipamento) => a && b && a.id === b.id;
        }
      });


    this.filteredModelosVeiculos
      .pipe(take(1), takeUntil(this._onDestroyModeloVeiculo))
      .subscribe(() => {
        this.singleSelectModeloVeiculo.compareWith = (a: Modelo, b: Modelo) => a && b && a.id === b.id;
      });


    this.filteredTiposArticulacaoVeiculos
      .pipe(take(1), takeUntil(this._onDestroyTipoArticulacaoVeiculo))
      .subscribe(() => {
        this.singleSelectTipoArticulacaoVeiculo.compareWith = (a: VehicleType, b: VehicleType) => a && b && a.id === b.id;
      });


    this.filteredTiposVeiculos
      .pipe(take(1), takeUntil(this._onDestroyTipoVeiculo))
      .subscribe(() => {
        if (this.singleSelectTipoVeiculo) {
          this.singleSelectTipoVeiculo.compareWith = (a: Select, b: Select) => a && b && a.valor === b.valor;
        }
      });
  }

  get listaTipoEntradaDigital() {
    return this.veiculoService.getTipoEntradaDigital();
  }

  get listaTipoCombustivel() {
    return this.veiculoService.getTipoCombustivel();
  }

  validarTipo(valor:String):String{
    if(valor){
      if("A" == valor){
        return "Automatico";
      }
      if("M" == valor){
        return "Manual";
      }
      if("V" == valor){
        return "Via-M";
      }
    }else{
      return "-"
    }
  }

}
