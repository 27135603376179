import { DetalheAcionamentoRegra } from "./detalhe-acionamento-regra";
import { InformacaoRegraAbrirBau } from "./informcoes-regra-abrir-bau";
import { InformacaoRegraBauAbertoForaCerca } from "./informcoes-regra-bau-aberto-fora_cerca";
import { InformacaoRegraFecharBau } from "./informcoes-regra-fechar-bau";
import { InformacaoRegraSairLoja } from "./informcoes-regra-sair-loja";
import { ResumoAcionamentoRegra } from "./resumo-acionamento-regra";
import { ResumoDetalheAcionamentoRegra } from "./resumo-detalhe-acionamento-regra";
import { Retorno } from "./retorno";

export class RetornoAcionamentoRegra extends Retorno {
  resumoAcionamentoRegra: ResumoAcionamentoRegra = new ResumoAcionamentoRegra();
  informacaoRegraSairLoja: InformacaoRegraSairLoja =
    new InformacaoRegraSairLoja();
  informacaoRegraAbrirBau: InformacaoRegraAbrirBau =
    new InformacaoRegraAbrirBau();
  informacaoRegraFecharBau: InformacaoRegraFecharBau =
    new InformacaoRegraFecharBau();
  informacaoRegraAberturaForaCerca: InformacaoRegraBauAbertoForaCerca =
    new InformacaoRegraBauAbertoForaCerca();
  listaPrincipal: Array<DetalheAcionamentoRegra> = [];
  listaResumo: Array<ResumoDetalheAcionamentoRegra> = [];
  listaTopDez: Array<ResumoDetalheAcionamentoRegra> = [];
}
