import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Filtro } from 'app/classes/filtro';
import { ReportService } from 'app/services/report/report.service';
import { Company } from 'app/classes/company';
import { CompanyService } from 'app/services/crud/company.service';

@Component({
  selector: 'app-produtividade-motorista-adm',
  templateUrl: './produtividade-motorista-adm.component.html',
  styleUrls: ['./produtividade-motorista-adm.component.css']
})
export class ProdutividadeMotoristaAdmComponent implements OnInit {

  dropdownList = [];
  selectedItems = [];
  dropdownSettings: {};
  public filtro: Filtro;
  public mostrarPdf: boolean = false;
  public pdfSrc: any;
  listaDeEmpresas: Company[] = [];
  empresa: Company;
  responseBase64: String;
  tipoEvento: number = 0;
  tipoRelatorio: String = "PDF";

  @ViewChild('iframe') iframe: ElementRef;

  constructor(private companyService: CompanyService, private reportService: ReportService) { }

  ngOnInit() {
    try {
      this.filtro = new Filtro();
      this.companyService.buscarTodosParaContratos().then(response => {
        var data = response.response;
        this.dropdownList = [];
        for (var i = 0; i < data.length; i++) {
          var empresa = data[i];
          this.dropdownList.push({ item_id: empresa.id, item_text: empresa.nome });

        }
        this.dropdownSettings = {
          singleSelection: true,
          idField: 'item_id',
          textField: 'item_text',
          selectAllText: 'Todos',
          unSelectAllText: 'Desmarcar todos',
          itemsShowLimit: 3,
          allowSearchFilter: true,
          searchPlaceholderText: 'Pesquisar'
        };
      });

    } catch (error) {
      console.error(error);
    }
  }

  onItemSelect(item: any) {
    this.inserirEmpresaSelecionado(item);
  }
  onSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirEmpresaSelecionado(items[i]);
    }
  }
  onDeSelect(item: any) {
    this.removerEmpresaSelecionado(item);
  }

  inserirEmpresaSelecionado(item: any): void {
    try {
      //Nesse caso limpar a lista, pois é somente um
      this.empresa = new Company();
      this.empresa.id = item.item_id;
    } catch (error) {
      console.log(error);
    }
  }

  removerEmpresaSelecionado(item: any): void {
    try {
      this.empresa = new Company();
    } catch (error) {
      console.log(error);
    }
  }


  doSearch() {
    try {
      this.filtro.idCliente = this.empresa.id;
      this.filtro.tipoArquivo = this.tipoRelatorio;
      this.reportService.getReportRelatorioProdutividadeMotoristaAdm(this.filtro).then(response => {
        this.iframe.nativeElement.setAttribute('src', response);
      });
    } catch (error) {
      console.error(error);
    }
  }

  trocarRadioButton(value) {
    try {
      this.tipoRelatorio = value;
    } catch (error) {
      console.log(error);
    }
  }


}
