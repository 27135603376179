import { Component, Injectable } from '@angular/core';
import { NgbTimeStruct, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';

/**
 * Example of a String Time adapter
 */
@Injectable()
export class TimePickerAdapter extends NgbTimeAdapter<number> {

    public HHmmToMili(hhmm: string): number {
        if (!hhmm) return 0;

        let time = hhmm.split(":");
        let hours = new Number(time[0]).valueOf() * 60 * 60 * 1000;
        let minutes = new Number(time[1]).valueOf() * 60 * 1000;

        return hours + minutes;
    }

    public miliToHHmm(mili: number): string {
        if (!mili) return "00:00";

        var hh = Math.floor(mili / 1000 / 60 / 60);
        mili -= hh * 1000 * 60 * 60;
        var mm = Math.floor(mili / 1000 / 60);
        mili -= mm * 1000 * 60;

        let HH = hh < 10 ? "0" + hh : hh;
        let MM = mm < 10 ? "0" + mm : mm;

        return HH + ':' + MM;
    }

    fromModel(value: number): NgbTimeStruct {
        if (!value) {
            return null;
        }
        const split = this.miliToHHmm(value).split(':');
        return {
            hour: parseInt(split[0], 10),
            minute: parseInt(split[1], 10),
            second: 0
        };
    }

    toModel(time: NgbTimeStruct): number {
        if (!time) {
            return null;
        }
        let timeStr = `${this.pad(time.hour)}:${this.pad(time.minute)}`;
        return this.HHmmToMili(timeStr);
    }

    private pad(i: number): string {
        return i < 10 ? `0${i}` : `${i}`;
    }
}