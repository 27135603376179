import { Injectable } from '@angular/core';
import { CRUDService } from '../base/crud.service';
import { Http } from '@angular/http';
import { Branch } from '../../classes/branch';
import { environment } from '../../../environments/environment';
import { AuthGuard } from '../../guards/auth.guard';
import { RESTResponseVO } from '../../classes/restresponsevo';

@Injectable()
export class BranchService extends CRUDService<Branch> {
  localAuthGuard: AuthGuard;

  constructor(http: Http, authGuard: AuthGuard) {
    super(http, environment.baseURL + 'App/v2/web/unidades', true);

    this.localAuthGuard = authGuard;
    this.setListUrl();
  }

  setListUrl() {
    const loggedUser = this.localAuthGuard.loggedUser();
    const company: number = loggedUser.selectedCompany.id;

    this.listURL = environment.baseURL + 'App/v2/web/unidades/lista/' + company;
  }

  getList(): Promise<RESTResponseVO<Branch[]>> {
    this.setListUrl();
    return super.getList();
  }

  getValorDiaria(idMotorista): Promise<RESTResponseVO<String>> {
    let url: string = environment.baseURL + 'App/v2/web/unidades/buscarValorDiaria/' + idMotorista;
    return this.http.get(url)
        .toPromise()
        .then(response => {
            return response.json() as RESTResponseVO<String>;
        })
        .catch(this.handleError);
}
}
