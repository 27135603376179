import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-tela-indicadores",
  templateUrl: "./tela-indicadores-bau.component.html",
  styleUrls: ["./tela-indicadores-bau.component.css"],
})
export class TelaIndicadoresBauComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
