import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Contratos } from 'app/classes/contratos';
import { AuthGuard } from 'app/guards/auth.guard';
import { CRUDService } from '../base/crud.service';
import { environment } from "../../../environments/environment";
import { RetornoContrato } from 'app/classes/retorno-contrato';
import { RESTResponseVO } from 'app/classes/restresponsevo';

@Injectable({
  providedIn: 'root'
})
export class ContratosService extends CRUDService<Contratos> {

  public company: number;
  public unidade: number;

  constructor(http: Http, public authGuard: AuthGuard) {
    super(http, environment.baseURL + 'App/v2/web/contratos', true);
  }

  getPorEmpresa(id: number) {
    const url = `${this.defaultUrl}/buscarContratoPorEmpresa/${id}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoContrato;
        }
      })
      .catch(this.handleError);
  }


  getPorItem(id: number) {
    const url = `${this.defaultUrl}/buscarPorItem/${id}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoContrato;
        }
      })
      .catch(this.handleError);
  }


  buscarContadores() {
    try {
      const url = `${this.defaultUrl}/buscarContadores`;
      return this.http
        .get(url)
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            return response.json() as RetornoContrato;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }

  cadastrarContrato(contrato: Contratos): Promise<RESTResponseVO<Contratos>> {
    const url = `${this.defaultUrl}/salvarContratos`;
    return this.enviarRequisicao(contrato, url);
  }

  editarContratos(listaContratos: Contratos[]) {
    try {
      const url = `${this.defaultUrl}/editarContratos`;
      return this.http
        .post(url, JSON.stringify(listaContratos), { headers: this.headers })
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            var retorno: RetornoContrato = response.json() as RetornoContrato;
            var ret: Contratos = retorno.contratos;
            if (ret === null) {
              ret = new Contratos();
            }
            ret.id = 0;
            return ret;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }

  enviarRequisicao(contrato: Contratos, url: string): Promise<RESTResponseVO<Contratos>> {
    try {
      return this.http
        .post(url, JSON.stringify(contrato), { headers: this.headers })
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            var retorno: RetornoContrato = response.json() as RetornoContrato;
            var ret: Contratos = retorno.contratos;
            if (ret === null) {
              ret = new Contratos();
            }
            ret.id = 0;
            return ret;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }

}
