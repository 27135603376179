/// <reference types="@types/googlemaps" />
import { Component, OnInit, Compiler, ElementRef, ViewChild } from '@angular/core';
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { ReportService } from 'app/services/report/report.service';
import { Veiculo } from 'app/classes/veiculo';
import { Filtro } from 'app/classes/filtro';
import { InformacaoRelatorio } from 'app/classes/informacao-relatorio';
import { Transmissao } from 'app/classes/transmissao';
declare const google: any;

@Component({
  selector: 'app-pontos-percorridos',
  templateUrl: './pontos-percorridos.component.html',
  styleUrls: ['./pontos-percorridos.component.css']
})
export class PontosPercorridosComponent implements OnInit {

  @ViewChild('dataTable') table;
  dataTable: any;
  dtOption: any = {};

  mapa: any;
  lat: number = -19.923395;
  lng: number = -43.944809;
  showVeiculos: boolean = false;
  dropdownList = [];
  dropdownSettings: {};
  public selectedItems = [];
  veiculo: Veiculo;
  public dataInicial: String;
  public dataFinal: String;
  showBotaoPesquisa: boolean = false;
  showMostrarTabela: boolean = false;
  public filtro: Filtro;
  public dadosVeiuculo: String = "";
  public velocidadeMedia: String = "";
  public distanciaTotal: String = "";
  public qtdePosicao: String = "";
  listaTransmissao: Transmissao[] = [];
  listaDePolilyne: any[] = [];
  markerInicio: any;
  markerFinal: any;

  constructor(
    private veiculoService: VeiculoService,
    private reportService: ReportService,
    private _compiler: Compiler) {
    this._compiler.clearCache();
  }

  ngOnInit() {
    try {
      this.filtro = new Filtro();
      this.veiculoService.getLista().then(response => {
        var data = response.listaDeVeiculos;
        this.dropdownList = [];
        for (var i = 0; i < data.length; i++) {
          var veiculo = data[i];
          this.dropdownList.push({ item_id: veiculo.id, item_text: veiculo.placa });
        }

        this.dropdownSettings = {
          singleSelection: true,
          idField: 'item_id',
          textField: 'item_text',
          selectAllText: 'Todos',
          unSelectAllText: 'Desmarcar todos',
          itemsShowLimit: 3,
          allowSearchFilter: true,
          searchPlaceholderText: 'Pesquisar'
        };
        this.showVeiculos = true;
      });
      this.dtOption = {
        "iDisplayLength": 17,
        "bPaginate": false,
        "fixedHeader": true,
        "bLengthChange": false,
        "bFilter": true,
        "bInfo": false,
        "bAutoWidth": false,
        "scrollY": 400,
        "deferRender": true,
        "scroller": true,
        "language": {
          "lengthMenu": "Mostrando _MENU_ registros por pagina",
          "zeroRecords": "Nenhum registro encontrado",
          "search": "Pesquisar",
          "oPaginate": {
            "sNext": "Próximo",
            "sPrevious": "Anterior",
            "sFirst": "Primeiro",
            "sLast": "Último"
          }
        }, "columnDefs": [
          //Largura das Colunas
          { "className": "text-center", targets: [0, 1, 2, 3] },
          { "width": "8%", targets: 0 },
          { "width": "12%", targets: 1 },
          { "width": "8%", targets: 2 },
          { "width": "76%", targets: 3 }
        ],
        'dom': 'Bfrtip',
        'buttons': [
          'csv', 'excel'
        ]
      };
      this.dataTable = $(this.table.nativeElement);
      this.dataTable.DataTable(this.dtOption);
    } catch (error) {
      console.error(error);
    }
  }

  onMapReady(map) {
    this.mapa = map;
  }

  onItemSelect(item: any) {
    this.inserirVeiculoSelecionado(item);
  }

  onSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirVeiculoSelecionado(items[i]);
    }
  }

  onDeSelect(item: any) {
    this.removerVeiculoSelecionado(item);
  }

  inserirVeiculoSelecionado(item: any): void {
    try {
      this.veiculo = new Veiculo();
      this.veiculo.id = item.item_id;
      this.veiculo.placa = item.item_text;
    } catch (error) {
      console.log(error);
    }
  }

  removerVeiculoSelecionado(item: any): void {

  }

  doSearch() {
    this.showBotaoPesquisa = true;
    this.showMostrarTabela = false;
    try {
      this.filtro.dataInicialString = this.dataInicial;
      this.filtro.dataFinalString = this.dataFinal;
      this.filtro.veiculo = this.veiculo;
      this.reportService.getReportPontosPercorridos(this.filtro).then(response => {
        let informacaoRelatorio = response.informacaoRelatorio;
        this.showMostrarTabela = true;
        this.ajustarTabelaDeDetalhes(informacaoRelatorio);
        this.showBotaoPesquisa = false;
      });
    } catch (error) {
      console.log(error);
    }
  }

  ajustarTabelaDeDetalhes(informacaoRelatorio: InformacaoRelatorio) {
    try {
      this.listaTransmissao = [];

      this.dataTable.DataTable().clear().draw();
      this.dadosVeiuculo = informacaoRelatorio.campo01;
      this.velocidadeMedia = informacaoRelatorio.campo02 + " Km/h";
      this.distanciaTotal = informacaoRelatorio.campo03 + " km";
      this.qtdePosicao = informacaoRelatorio.campo04;

      informacaoRelatorio.listaInformacaoRelatorio.forEach(i1 => {

        let ing = i1.campo01;
        if (ing == "D") {
          ing = "Off";
        } else {
          ing = "On";
        }
        let data = i1.campo02;
        let vel = i1.campo03 + " Km/h";
        let end = i1.campo04;

        let t = new Transmissao();
        t.velocidade = Number(i1.campo03);
        t.latitude = Number(i1.campo05);
        t.longitude = Number(i1.campo06);
        t.direcao = Number(i1.campo07);
        this.listaTransmissao.push(t);
        this.dataTable.DataTable().row.add([ing, data, vel, end]);
      });
      this.dataTable.DataTable().draw();
      this.ajustarPercursoNoMapa();
    } catch (error) {
      console.log(error);
    }
  }

  ajustarPercursoNoMapa() {
    try {
      this.listaDePolilyne.forEach(e => {
        e.setMap(null);
      });
      if (this.markerInicio) {
        this.markerInicio.setMap(null);
      }
      if (this.markerFinal) {
        this.markerFinal.setMap(null);
      }

      if (this.listaTransmissao && this.listaTransmissao.length <= 0) {
        return;
      }

      for (var i = 0; i < this.listaTransmissao.length - 1; i++) {
        var t = this.listaTransmissao[i];
        var a1 = new google.maps.LatLng(t.latitude, t.longitude);
        var a2 = new google.maps.LatLng(this.listaTransmissao[i+1].latitude, this.listaTransmissao[i+1].longitude);
        var cor = "";
        if(t.velocidade >= 0 && t.velocidade <= 5){
          cor = "#5f9ea0";
        }
        if(t.velocidade >= 6 && t.velocidade <= 15){
          cor = "#00ffff";
        }
        if(t.velocidade >= 16 && t.velocidade <= 30){
          cor = "#a9a9a9";
        }
        if(t.velocidade >= 31 && t.velocidade <= 45){
          cor = "#ff8c00";
        }
        if(t.velocidade >= 46 && t.velocidade <= 60){
          cor = "#ff4500";
        }
        if(t.velocidade >= 61 && t.velocidade <= 80){
          cor = "#800080";
        }
        if(t.velocidade >= 81 && t.velocidade <= 100){
          cor = "#663399";
        }
        if(t.velocidade >= 101 && t.velocidade <= 200){
          cor = "#ff0000";
        }
        var flightPath = new google.maps.Polyline({
          path: [a1,a2],
          geodesic: true,
          strokeColor: cor,
          strokeOpacity: 1.0,
          strokeWeight: 2,
        });
        flightPath.setMap(this.mapa);
        this.listaDePolilyne.push(flightPath);
      }

      var center = new google.maps.LatLng(this.listaTransmissao[0].latitude, this.listaTransmissao[0].longitude);
      this.mapa.panTo(center);
      this.mapa.setZoom(14);

      var inicialLatlng = new google.maps.LatLng(this.listaTransmissao[0].latitude, this.listaTransmissao[0].longitude);
      var finalLatlng = new google.maps.LatLng(this.listaTransmissao[this.listaTransmissao.length - 1].latitude, this.listaTransmissao[this.listaTransmissao.length - 1].longitude);

      const svgMarkerInicio = {
        path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
        fillColor: "blue",
        fillOpacity: 0.6,
        strokeWeight: 0,
        rotation: 0,
        scale: 2,
        anchor: new google.maps.Point(15, 30),
      };

      const svgMarkerFim = {
        path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
        fillColor: "green",
        fillOpacity: 0.6,
        strokeWeight: 0,
        rotation: 0,
        scale: 2,
        anchor: new google.maps.Point(15, 30),
      };

      this.markerInicio = new google.maps.Marker({
        position: inicialLatlng,
        icon: svgMarkerInicio,
        label: 'Inicio',
        map: this.mapa
      });

      this.markerFinal = new google.maps.Marker({
        position: finalLatlng,
        icon: svgMarkerFim,
        label: 'Fim',
        map: this.mapa
      });

    } catch (error) {
      console.log(error);
    }
  }

}
