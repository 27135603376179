/// <reference types="@types/googlemaps" />
import { ElementRef } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MonitoramentoViasatService } from 'app/services/monitoramento-viasat.service';


declare var test: any;

@Component({
  selector: 'app-monitoramento',
  templateUrl: './monitoramento.component.html',
  styleUrls: ['./monitoramento.component.css']
})
export class MonitoramentoComponent implements OnInit {
  @ViewChild('content') content;
  @ViewChild('iframeMonitoramento') iframeMonitoramento: ElementRef;


  constructor(
    private monitorService: MonitoramentoViasatService) {

  }

  ngOnInit() {
  }

  f(){
    new test();
  }

}
