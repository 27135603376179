import { Component, OnInit } from '@angular/core';
import {User} from '../../classes/user';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {AuthGuard} from '../../guards/auth.guard';
import { environment } from 'environments/environment.dev';

@Component({
  selector: 'app-painel-controle-jornadas',
  templateUrl: './painel-controle-jornadas.component.html',
})
export class PainelControleJornadasComponent implements OnInit {

  empresaAtiva: number;
  loggedUser: User;
  safeUrl: SafeResourceUrl;

  constructor(
    private authGuard: AuthGuard,
    private sanitizer: DomSanitizer
  ) {
    this.loggedUser =  this.authGuard.loggedUser();
    this.empresaAtiva = this.loggedUser.selectedCompany.id;
    const baseUrl = 'https://metabase.rodoviasolucoes.com.br/public/dashboard/02e02051-8ed3-4600-b668-ffea0fbfafb7';
    const queryParams = `?tab=5-horas-extras&id=${this.empresaAtiva}&unidade=&motorista=&data_inicial=&data_final=`
      + `&per%25C3%25ADodo=#hide_parameters=id`;
    const url = `${baseUrl}${queryParams}`;
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit() {

  }

}
