import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { BaseListComponent } from '../../../base/base-list/base-list.component';
import { VehicleService } from '../../../../services/crud/vehicle.service';
import { UtilService } from '../../../../services/utils.service';
import { PDFService } from '../../../../services/pdf.service';
import { AuthGuard } from '../../../../guards/auth.guard';

@Component({
    templateUrl: '../../../base/base-list/base-list.component.html',
    styleUrls: ['../../../base/base-list/base-list.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class VehicleListComponent extends BaseListComponent {

    constructor(private vehicleService: VehicleService,
        private utilService: UtilService,
        pdfService: PDFService,
        authGuard: AuthGuard) {
        super(utilService, pdfService, authGuard);
        this.searchField = "placa";
    }

    ngOnInit() {
        this.pageTitle = "Cadastros | Veículos";
        this.formPath = "/adm/vehicle/form";

        this.tableHeaders = [
            [
                this.createHeader("Placa", 0, true),
                this.createHeader("Marca", 1, true),
                this.createHeader("Modelo", 2, true),
                this.createHeader("Cor", 3, true),
                this.createHeader("Cidade Registro", 4, true),
                this.createHeader("Tipo Veiculo", 5, true),
                this.createHeader("Renavan", 6, true)
            ]
        ];

        this.dataFields = [
            this.createField("placa", "string"),
            this.createField("marca", "string"),
            this.createField("modelo", "string"),
            this.createField("cor", "string"),
            this.createField("cidadeRegistro.nome", "string"),
            this.createField("tiposDeVeiculo.descricao", "string"),
            this.createField("renavan", "string")
        ];

        this.vehicleService.getList()
            .then(response => {
                this.data = response.response
                this.data.forEach(d => d.id = d.placa);
                this.loading = false;
            });
    }
}
