import { Component, OnInit } from '@angular/core';
import { MonitoramentoMotorista } from 'app/classes/monitoramento-motorista';
import { MonitoramentoMotoristaHora } from 'app/classes/monitoramento-motorista-hora';

@Component({
  selector: 'app-monitoramento-jornada',
  templateUrl: './monitoramento-jornada.component.html',
  styleUrls: ['./monitoramento-jornada.component.scss']
})
export class MonitoramentoJornadaComponent implements OnInit {

  public retorno: any;

  constructor() { }

  ngOnInit() {
  }

}
