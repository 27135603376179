import { Component, OnInit } from '@angular/core';
import { Inconformidade } from 'app/classes/inconformidade';
import { BaseFormComponent } from 'app/components/base/base-form/base-form.component';
import { AuthGuard } from 'app/guards/auth.guard';
import { ActivatedRoute, Router } from "@angular/router";
import { InconformidadeService } from 'app/services/crud/inconformidade.service';
import { User } from "app/classes/user";
import { RetornoInconformidade } from 'app/classes/retorno-inconformidade';
import { RESTResponseVO } from 'app/classes/restresponsevo';
import { Driver } from 'app/classes/driver';
import { Event } from 'app/classes/event';
import { JourneyEvent } from 'app/classes/journey-event';
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class InconformidadeFormComponent extends BaseFormComponent<Inconformidade> {

  public loggedUser: User;
  isAdmin: boolean = false;
  public nomeMotorista: String;
  constructor(private inconformidadeService: InconformidadeService,
    private authGuard: AuthGuard,
    route: ActivatedRoute,
    private router: Router) {
    super(route, router, 'adm/inconformidade/list');
    this.loggedUser = authGuard.loggedUser();
  }

  assembleObjectFromForm(): void {
  }

  loadAuxiliaryLists(): void {
  }

  loadObject(): void {
    if (this.activeID && (this.activeID !== 0)) {
      this.inconformidadeService.getById(this.activeID).then(response => {
        var resultItem: RetornoInconformidade = response.response;
        var i = resultItem.inconformidade;
        if (!i.motoristas) {
          i.nomeMotoristaCompleto = " - ";
        } else {
          i.nomeMotoristaCompleto = i.motoristas.nome + " " + i.motoristas.sobrenome;
        }
        if (!i.placa) {
          i.placa = " - ";
        }
        if(!i.usuario){
          var u: User = new User();
          u.userName = " - ";
          i.usuario = u;

        }
        if(!i.evento){
          var event:Event = new Event();
          var tipoEvento: JourneyEvent = new JourneyEvent();
          tipoEvento.descricao = " - ";
          event.tipoEvento = tipoEvento;
          i.evento = event;
        }
        this.activeObject = i;
      });
    } else {
      this.router.navigate(['adm/inconformidade/list']);
    }
    this.loading = false;
  }

  initObject(): void {
    this.activeObject = new Inconformidade();
    this.loading = false;
  }

  doCreate(t: Inconformidade): Promise<RESTResponseVO<Inconformidade>> {
    return null;
  }

  doEdit(t: Inconformidade): Promise<RESTResponseVO<Inconformidade>> {
    try {
      return this.inconformidadeService.editarInconformidade(t);
    } catch (e) {
      console.error(e);
    }

  }

  doDelete(t: Inconformidade): Promise<RESTResponseVO<string>> {
    return null;
  }

  setActiveID(): void {
    try {
      if (this.activeObject == undefined) {
        this.activeID = 0;
      } else {
        this.activeID = this.activeObject.id;
      }
    } catch (e) {
      console.log(e);
    }

  }

}
