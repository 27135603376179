import { Nonconformity } from "./nonconformity";

export class NonconformityTreatment {
    startDate: any;
    endDate: any;
    type: string;
    userId: number;
    driverId: number;
    journeyId: number;
    previousType: string;

    // UI
    description: string;
    nonconformity: Nonconformity;

    interjourneyStart: any;
    interjourneyEnd: any;
}