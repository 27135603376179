import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseListComponent } from '../../../base/base-list/base-list.component';
import { HolidayService } from '../../../../services/crud/holiday.service';
import { UtilService } from '../../../../services/utils.service';
import { PDFService } from '../../../../services/pdf.service';
import { ShiftService } from '../../../../services/crud/shift.service';
import { AuthGuard } from '../../../../guards/auth.guard';

@Component({
    templateUrl: '../../../base/base-list/base-list.component.html',
    styleUrls: [ '../../../base/base-list/base-list.component.css' ],
    encapsulation: ViewEncapsulation.None
})
export class ShiftListComponent extends BaseListComponent {

    constructor(private service: ShiftService,
        private utilService: UtilService,
        pdfService: PDFService,
        authGuard: AuthGuard) {
        super(utilService, pdfService, authGuard);
        this.searchField = "descricao";
    }

    ngOnInit() {
        this.pageTitle = "Cadastros | Turnos";
        this.formPath = "/adm/shift/form";

        this.tableHeaders = [
            [
                this.createHeader("Identificação", 0, true),
                this.createHeader("Horas Mensais", 1, true),
                this.createHeader("Horas Semanais", 2, true),
                this.createHeader("Acordo de Compensação", 3, true),
                this.createHeader("Tolerância", 4, true)
            ]
        ];

        this.dataFields = [
            this.createField("identificacao", "string"),
            this.createField("horasMensais", "string"),
            this.createField("horasSemanais", "time"),
            this.createField("acordoCompensacao", "boolean"),
            this.createField("toleranciasId.descricao", "string")
        ];

        this.service.getList().then(response => {
            this.data = response.response; 
            this.loading = false;
        });
    }
}
