import { ItemEficienciaTelemetria } from "./ItemEficienciaTelemetria";

export class ResumoEficienciaTelemetria {
  marchaLentaParado: ItemEficienciaTelemetria;
  batendoTransmissaoSemAceleracao: ItemEficienciaTelemetria;
  ecominicaSemAceleracao: ItemEficienciaTelemetria;
  marchaLentaEmMovimento: ItemEficienciaTelemetria;
  batendoTransmissaoComAceleracao: ItemEficienciaTelemetria;
  ecominocaComAceleracao: ItemEficienciaTelemetria;
  superEconomicaSemAceleracao: ItemEficienciaTelemetria;
  sobrerotacaoSemAceleracao: ItemEficienciaTelemetria;
  amarelaSemAceleracao: ItemEficienciaTelemetria;
  superEconomicaComAceleracao: ItemEficienciaTelemetria;
  sobrerotacaoComAceleracao: ItemEficienciaTelemetria;
  amarelaComAceleracao: ItemEficienciaTelemetria;
  rpmMaximo: ItemEficienciaTelemetria;
  limiteDeVelocidade: ItemEficienciaTelemetria;






}
