import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { Filtro } from 'app/classes/filtro';
import { ReportService } from 'app/services/report/report.service';
import { Veiculo } from 'app/classes/veiculo';
import { DisponibilidadeFisica } from 'app/classes/disponibilidade-fisica';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


@Component({
  selector: 'app-disponibilidade',
  templateUrl: './disponibilidade.component.html',
  styleUrls: ['./disponibilidade.component.css']
})
export class DisponibilidadeComponent implements OnInit {

  @ViewChild('iframe') iframe: ElementRef;

  dropdownList = [];
  selectedItems = [];
  dropdownSettings: {};
  public filtro: Filtro;
  listaDeVeiculos: Veiculo[] = [];
  veiculo: Veiculo;
  tipoRelatorio: String = "PDF";
  public isGrafico: boolean = false;
  public isPdf: boolean = true;

  constructor(private veiculoService: VeiculoService, private reportService: ReportService) { }

  ngOnInit() {
    try {
      this.filtro = new Filtro();
      this.veiculoService.getLista().then(response => {
        var data = response.listaDeVeiculos;
        this.dropdownList = [];
        for (var i = 0; i < data.length; i++) {
          var veiculo = data[i];
          this.dropdownList.push({ item_id: veiculo.id, item_text: veiculo.placa });
        }

        this.dropdownSettings = {
          singleSelection: false,
          idField: 'item_id',
          textField: 'item_text',
          selectAllText: 'Todos',
          unSelectAllText: 'Desmarcar todos',
          itemsShowLimit: 3,
          allowSearchFilter: true,
          searchPlaceholderText: 'Pesquisar'
        };
      });

    } catch (error) {
      console.error(error);
    }
  }


  onItemSelect(item: any) {
    this.inserirVeiculoSelecionado(item);
  }
  onSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirVeiculoSelecionado(items[i]);
    }
  }
  onDeSelect(item: any) {
    this.removerVeiculoSelecionado(item);
  }

  inserirVeiculoSelecionado(item: any): void {
    try {
      var veiculo = this.listaDeVeiculos.find(v => v.id == item.item_id);
      if (!veiculo) {
        this.veiculo = new Veiculo();
        this.veiculo.id = item.item_id;
        this.veiculo.placa = item.item_text;
        this.listaDeVeiculos.push(this.veiculo);
      }
    } catch (error) {
      console.log(error);
    }
  }

  removerVeiculoSelecionado(item: any): void {
    try {
      let index = this.listaDeVeiculos.findIndex(v => v.id == item.item_id);
      this.listaDeVeiculos.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }

  doSearch() {
    try {
      this.filtro.listaDeVeiculos = this.listaDeVeiculos;
      this.filtro.tipoArquivo = this.tipoRelatorio;
      if (this.tipoRelatorio === 'GRA') {

        this.reportService.getReportDisponibilidadeGrafico(this.filtro).then(response => {
          try {
            this.isGrafico = true;
            this.isPdf = false;
            let disponibilidadeFisica = response;
            this.montarGrafico(disponibilidadeFisica); 

          } catch (error) {
            console.error(error);
          }
        });

      } else {
        this.reportService.getReportDisponibilidade(this.filtro).then(response => {
          try {
            this.isGrafico = false;
            this.isPdf = true;
            this.iframe.nativeElement.setAttribute('src', response);
          } catch (error) {
            console.error(error);
          }
        });
      }

    } catch (error) {
      console.error(error);
    }
  }


  trocarRadioButton(value) {
    try {
      this.tipoRelatorio = value;
    } catch (error) {
      console.log(error);
    }
  }

  montarGrafico(disponibilidadeFisica: DisponibilidadeFisica) {
    try {
      am4core.useTheme(am4themes_animated);
      let chart = am4core.create("chartdivdisponibilidadefisica", am4charts.XYChart);
      chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
      chart.data = disponibilidadeFisica.listaDadosDisponibilidadeFisica;
      chart.colors.step = 2;
      chart.padding(30, 30, 10, 30);
      chart.legend = new am4charts.Legend();

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "placaData";
      categoryAxis.renderer.grid.template.location = 0;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.max = 100;
      valueAxis.strictMinMax = true;
      valueAxis.calculateTotals = true;
      valueAxis.renderer.minWidth = 50;

      let series1 = chart.series.push(new am4charts.ColumnSeries());
      series1.columns.template.width = am4core.percent(80);
      series1.columns.template.tooltipText =
        "{name}: {totalLigado}h-{valueY.totalPercent.formatNumber('#.00')}%";
      series1.name = "Horas Ligado";
      series1.dataFields.categoryX = "placaData";
      series1.dataFields.valueY = "totalLigado";
      series1.dataFields.valueYShow = "totalPercent";
      series1.dataItems.template.locations.categoryX = 0.5;
      series1.stacked = true;
      series1.tooltip.pointerOrientation = "vertical";

      let bullet1 = series1.bullets.push(new am4charts.LabelBullet());
      bullet1.interactionsEnabled = false;
      bullet1.label.text = "{totalLigado}h-{valueY.totalPercent.formatNumber('#.00')}%";
      bullet1.label.fill = am4core.color("#ffffff");
      bullet1.locationY = 0.5;

      let series2 = chart.series.push(new am4charts.ColumnSeries());
      series2.columns.template.width = am4core.percent(80);
      series2.columns.template.tooltipText =
        "{name}: {totalDesligado}h-{valueY.totalPercent.formatNumber('#.00')}%";
      series2.name = "Total Desligado";
      series2.dataFields.categoryX = "placaData";
      series2.dataFields.valueY = "totalDesligado";
      series2.dataFields.valueYShow = "totalPercent";
      series2.dataItems.template.locations.categoryX = 0.5;
      series2.stacked = true;
      series2.tooltip.pointerOrientation = "vertical";

      let bullet2 = series2.bullets.push(new am4charts.LabelBullet());
      bullet2.interactionsEnabled = false;
      bullet2.label.text = "{totalDesligado}h-{valueY.totalPercent.formatNumber('#.00')}%";
      bullet2.locationY = 0.5;
      bullet2.label.fill = am4core.color("#ffffff");

      let series3 = chart.series.push(new am4charts.ColumnSeries());
      series3.columns.template.width = am4core.percent(80);
      series3.columns.template.tooltipText =
        "{name}: {totalManutencao}h-{valueY.totalPercent.formatNumber('#.00')}%";
      series3.name = "Total Manutenção";
      series3.dataFields.categoryX = "placaData";
      series3.dataFields.valueY = "totalManutencao";
      series3.dataFields.valueYShow = "totalPercent";
      series3.dataItems.template.locations.categoryX = 0.5;
      series3.stacked = true;
      series3.tooltip.pointerOrientation = "vertical";

      let bullet3 = series3.bullets.push(new am4charts.LabelBullet());
      bullet3.interactionsEnabled = false;
      bullet3.label.text = "{totalManutencao}h-{valueY.totalPercent.formatNumber('#.00')}%";
      bullet3.locationY = 0.5;
      bullet3.label.fill = am4core.color("#ffffff");

      chart.scrollbarX = new am4core.Scrollbar();

    } catch (error) {
      console.log(error);
    }
  }
}
