import { Component, OnInit } from "@angular/core";
import { AuthGuard } from "../../../../guards/auth.guard";
import { User } from "../../../../classes/user";
import { Router } from "@angular/router";
import { UserService } from "../../../../services/crud/user.service";

@Component({
    templateUrl: "./change-password.component.html",
    styleUrls: ["./change-password.component.css"]
})
export class ChangePasswordComponent implements OnInit {
    constructor(private authGuard: AuthGuard,
        private router: Router,
    private userService: UserService) { }

    public activeObject: { id: number, userName: string, password: string, newPassword: string, newPasswordConfirm: string };
    public loading: boolean;
    public errorMessage: string;

    ngOnInit(): void {
        this.loading = true;

        let loggedUser = this.authGuard.loggedUser();
        this.activeObject = {
            id: loggedUser.id,
            userName: loggedUser.userName,
            password: undefined,
            newPassword: undefined,
            newPasswordConfirm: undefined
        };

        this.loading = false;
    }

    btnCancelClick() {
        this.router.navigate(['/'], { skipLocationChange: false });
    }

    btnSaveClick() {
        if (!this.activeObject.newPassword || !this.activeObject.newPasswordConfirm || !this.activeObject.password) {
            this.errorMessage = "Todos os campos são obrigatórios!";
        } else if (this.activeObject.newPassword !== this.activeObject.newPasswordConfirm) {
            this.errorMessage = "Nova senha e Confirmação de nova senha não são iguais!";
        } else {
            this.errorMessage = undefined;
            this.loading = true;

            this.userService.resetPassword(this.activeObject.id, this.activeObject.password, this.activeObject.newPassword).then(response => {
                this.errorMessage = response.error;
                if (!this.errorMessage) {
                    this.btnCancelClick();
                }
                this.loading = false;
            })
        }
    }
}