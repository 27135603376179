import { Component, OnInit, ViewChild } from "@angular/core";
import { UtilService } from "../../services/utils.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthGuard } from "../../guards/auth.guard";
import { Driver } from "../../classes/driver";
import { DriverService } from "../../services/crud/driver.service";
import { NonconformityService } from "../../services/crud/nonconformity.service";
import { CalendarVO } from "../../classes/calendarvo";
import { CalendarWeekVO } from "../../classes/calendarweekvo";
import { CalendarDayVO } from "../../classes/calendardayvo";
import { JourneyService } from "../../services/crud/journey.service";
import { Router } from "@angular/router";
import { CalendarJourneyVO } from "../../classes/calendarjourneyvo";
import { CalendarEventVO } from "../../classes/calendareventvo";
import { EventsService } from "../../services/crud/events.service";
import * as moment from 'moment';
import { NonconformityTreatment } from "../../classes/nonconformity-treatment";
import { JourneyEvent } from "../../classes/journey-event";
import { JourneyEventsService } from "../../services/crud/journey-events.service";
import { AllowanceReason } from "../../classes/allowance-reason";
import { JourneyLogReport } from "../../classes/journey-log-report";
import { ReportService } from "../../services/report/report.service";
import { PDFService } from "../../services/pdf.service";
import { JourneyCalculationReport } from "../../classes/journey-calculation-report";
import { OperationDiff } from "../../classes/operation-diff";
import { User } from "app/classes/user";
import { LegendDataItem } from "@amcharts/amcharts4/charts";
import { FormControl } from "@angular/forms";
import { number } from "@amcharts/amcharts4/core";
import { DSRService } from "app/services/crud/dsr.service";
import { DSR } from "app/classes/dsr";

@Component({
    templateUrl: "./nonconformity-calendar.component.html",
    styleUrls: ["./nonconformity-calendar.component.css"]
})
export class NonconformityCalendarComponent implements OnInit {

    isAdmin: boolean;
    habilitarRmLobore: boolean = false;
    listaDetalheDaJornada: any[];

    constructor(private utilService: UtilService,
        private modalService: NgbModal,
        private authGuard: AuthGuard,
        private nonconformityService: NonconformityService,
        private journeyService: JourneyService,
        private driverService: DriverService,
        private journeyEventService: JourneyEventsService,
        private reportService: ReportService,
        protected pdfService: PDFService,
        private dsrService: DSRService,
        private router: Router) {
        var loggedUser: User = authGuard.loggedUser();
        this.isAdmin = loggedUser.claimList.some(x => x.name === "admin");
        this.habilitarRmLobore = loggedUser.selectedCompany.habilitarRmLobore;
    }

    public errorMessage: string;
    public successMessage: string;
    public infoMessage: string;
    public loading: boolean;
    public searchObject: { driver: Driver, preview: boolean, fired: boolean, firedDate: Date, year: any, month: { name: string, start: number, end: number }, driverStatus: string };
    public driverList: Driver[];
    public originalDriverList: Driver[];
    public showYearList: boolean;
    public showMonthList: boolean;
    public yearList: any[];
    public monthList: any[];
    public diff: OperationDiff;
    public justificativa: String;
    public dataPagamento: Date;
    public jornadaSelecionadaAbonarHorasExtra: any;
    public interjourneyStart: any;
    public dsrList: DSR[];
    public temDsrAutomatica: boolean = false;


    public searchType = 'D';
    public manualStart: Date;
    public manualEnd: Date;
    public selected = '';
    public statusControl = new FormControl();
    public justificativaHoraCinquentaPorcentoDialogTitulo = '';
    public justificativaDSRDialogTitulo = '';

    public tooltips: {} = {
        waitingWorked: {
            false: 'Marcar Jornada para compensação com espera',
            true: 'Retirar Jornada para compensação com espera'
        },
        dsrHE: 'Jornada como HE de DSR',
        buttonDsrHE: {
            false: 'Marcar Jornada como HE de DSR',
            true: 'Retirar Jornada como HE de DSR'
        },
        allowance: 'Incluir abono',
        allowanceRemaining: 'Abonar todas horas restantes',
        allowanceLunchRemaining: 'Abonar horas restantes de almoço',
        buttonDSR: 'DSR',
        manualDsrCreation: 'DSR',
        newEvent: 'Novo Evento',
        newAllowance: 'Novo Abono',
        abonarHorasExtras: 'Abonar Horas Extras do Dia',
        aplicar50Dehoras: 'Aplicar 50% de horas?',
        reverterHorasExtras50: 'Reverter as horas extras do dia de folga?',
        bancoDeHoras: 'Adicionar Banco de Horas',
        aplicarMarcarDSR: 'Marcar dia como DSR para gerar horas extras?',
        reverterMarcarDSR: 'Reverter as horas extras do dia de DSR?'
    };

    public toolTipsHorasExtrasCinquentaPorcento = '';

    public calendar: CalendarVO;
    public selectedWeek: CalendarWeekVO;
    public modalMessage: string;
    public selectedEvent: CalendarEventVO;
    public manualDsr: NonconformityTreatment;

    public modalErrorMessage: string;
    public modalInfoMessage: string;

    @ViewChild('messageDialog') messageDialog;
    @ViewChild('confirmDialog') confirmDialog;
    @ViewChild('eventDetailsDialog') eventDetailsDialog;
    @ViewChild('manualDSRCreationDialog') manualDSRCreationDialog;
    @ViewChild('newAllowanceDialog') newAllowanceDialog;
    @ViewChild('newEventDialog') newEventDialog;
    @ViewChild('operationDiffDialog') operationDiffDialog;
    @ViewChild('justificativaDialog') justificativaDialog;
    @ViewChild('justificativaHoraCinquentaPorcentoDialog') justificativaHoraCinquentaPorcentoDialog;
    @ViewChild('justificativaHoraDSRDialog') justificativaHoraDSRDialog;
    @ViewChild('dataPagamentoDialog') dataPagamentoDialog;
    @ViewChild('dsrAutomaticaDialog') dsrAutomaticaDialog;


    public newEvent: any;
    public eventTypeList: JourneyEvent[];
    public newAllowance: any;
    public allowanceList: AllowanceReason[];

    ngOnInit(): void {
        this.loading = true;
        this.searchObject = { driver: undefined, preview: false, fired: false, firedDate: undefined, year: undefined, month: undefined, driverStatus: '0' };
        this.statusControl.setValue('');

        this.driverService.getListMinimo().then(response => {
            this.originalDriverList = response.response;
            this.originalDriverList.sort((d1, d2) => d1.nome > d2.nome ? 1 : -1);

            this.driverList = this.originalDriverList;

            this.journeyEventService.getAllowances().then(response => {
                this.allowanceList = response.response;

                if (localStorage.getItem('calendar')) {
                    var localCalendar = JSON.parse(localStorage.getItem('calendar'));
                    
                    this.statusControl.setValue(localCalendar.Situacao);
                    this.calendar = localCalendar.calendar;
                    this.searchObject = localCalendar.searchObject;
                    
                    this.selectedWeek = localCalendar.selectedWeek;
                    if(localCalendar.searchObject.driver) {
                        this.searchObject.driver = 
                            this.driverList.find(x=> x.id == localCalendar.searchObject.driver.id);
                    }

                    if(localCalendar.searchObject) {
                        if(localCalendar.searchObject.month) {
                            if(localCalendar.searchObject.month.name === "Período Manual") {
                                this.manualStart = new Date(localCalendar.searchObject.month.start);
                                this.manualEnd = new Date(localCalendar.searchObject.month.end);
                                this.searchType = "D";
                            } else {
                                this.searchType = "M";
                                this.searchObject.year = localCalendar.searchObject.year;
                            }
                        }
                    }

                    localStorage.removeItem('calendar');
                }
                this.loading = false;
                
            });
        });
    }

    onChangeDriver() {
        if (this.searchType === 'M') {
            this.searchPeriod()
        }
    }

    onChangeFilter() {
        if (this.searchObject.driver) {
            this.searchPeriod()
        }
    }


    searchPeriod() {
        if (!this.searchObject.driver) {
            return;
        }

        const previousYear = this.searchObject.year;
        const previousMonth = this.searchObject.month;

        this.listaDetalheDaJornada = null;
        this.loading = true;
        this.yearList = [];
        this.monthList = [];

        this.showMonthList = false;
        this.showYearList = false;

        this.journeyService.searchPeriod(this.searchObject.driver.id).then(response => {
            var result = response.response;

            Object.keys(result).forEach(k => {
                this.yearList.push({ year: k, months: result[k] });
            });
            
            if(previousYear) {
                
                const yearPreviousOnResultList = this.yearList.find(
                    x=> x.year == previousYear.year);

                if(yearPreviousOnResultList) {
                
                    this.searchObject.year = yearPreviousOnResultList;
                    this.monthList = yearPreviousOnResultList.months;
                    
                    if(previousMonth) {
                        const monthPreviousOnResultList = this.monthList.find(
                            x=> x.name == previousMonth.name);
        
                        if(monthPreviousOnResultList) {
                        
                            this.searchObject.month = monthPreviousOnResultList;
                        } else {
                            this.searchObject.month = this.monthList[0];                            
                        }
                            
                    } else {
                        this.searchObject.month = this.monthList[0];
                    }
                    

                } else {
                    this.searchObject.year = this.yearList[0];
                    this.monthList = this.yearList[0].months;
                    this.searchObject.month = this.monthList[0];
                }
            } else {
                this.searchObject.year = this.yearList[0];
                this.monthList = this.yearList[0].months;
                this.searchObject.month = this.monthList[0];
            }

            
            this.showYearList = true; 
            this.showMonthList = true; 
            this.loading = false;
        });
    }

    yearTemplate(y) {
        return y.year;
    }

    monthTemplate(m) {
        return m.name;
    }

    driverTemplate(r) {
        return r.nome + " " + r.sobrenome;
    }

    resetMessages() {
        this.errorMessage = undefined;
        this.infoMessage = undefined;
        this.successMessage = undefined;
    }

    userHasApuracao() {
        return this.authGuard.isApuracao();
    }

    eventDetails(dayEvent: CalendarEventVO) {
        if (dayEvent.type !== null) {
            this.selectedEvent = dayEvent;
            this.selectedEvent.instanteEvento = this.utilService.formatDatetoDatetimeLocalInput(new Date(this.selectedEvent.instanteEvento));

            this.modalService.open(this.eventDetailsDialog, { centered: true }).result.then(result => {
                if (!this.selectedEvent.locked || this.authGuard.isApuracao()) {
                    this.loading = true;

                    if (result === "save") {
                        this.saveEvent();
                    } else if (result === "delete") {
                        this.removeEvent();
                    }
                }
            }, (reason) => {
                this.selectedEvent = undefined;
            });
        }
    }

    isValidEvent(event) {
        let start = new Date(this.searchObject.month.start);
        let end = new Date(this.searchObject.month.end);

        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);

        let eventDate = event.instanteEvento;
        if (typeof eventDate !== "object") {
            eventDate = new Date(event.instanteEvento);
        }

        return eventDate >= start && eventDate <= end;
    }

    removeEvent() {
        if (!this.selectedEvent.justificativa) {
            this.errorMessage = "Campo justificativa é obrigatório!";
        } else {
            delete this.selectedEvent.css;
            delete this.selectedEvent.circle;

            if (this.selectedEvent.locked && !this.authGuard.isApuracao()) {
                this.errorMessage = "Evento bloqueado e usuário não possui permissão para efetuar alteração!";
            } else {
                this.loading = true;
                this.errorMessage = undefined;

                // Fix date for the map parser
                this.selectedEvent.instanteEvento = new Date(this.selectedEvent.instanteEvento);

                this.doOperation(this.selectedEvent, "DELETE_EVENT");
            }
        }
    }

    hideReports() {
        // When in preview mode, no reports are available
        //if (this.searchObject.preview) {
        //    return true;
        //}

        //for (var week in this.calendar.weeks) {
        //    if (this.calendar.weeks[week].status === 'ERROR') {
        //        return true;
        //    }
        //}

        return false;
    }

    closeCalculation() {
        this.resetMessages();

        this.loading = true;

        let start = this.searchObject.month.start;
        let end = this.searchObject.month.end;

        this.journeyService.closeCalculation(this.searchObject.driver.id, start, end, this.authGuard.loggedUser().id).then(response => {
            this.errorMessage = response.error;

            if (this.errorMessage) {
                this.errorMessage = response.response;
                this.loading = false;
            } else {
                this.askSearchAgain();
            }
        });
    }

    convertHex(hexa: string, opacity: number) {
        let hex = hexa.replace('#', '');
        let r = parseInt(hex.substring(0, 2), 16);
        let g = parseInt(hex.substring(2, 4), 16);
        let b = parseInt(hex.substring(4, 6), 16);

        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
    }

    calculateColor(current: number, max: number, baseColor: string) {
        let opacity = (100 * current) / max;
        if (opacity < 40) { opacity = 40; }
        return this.convertHex(baseColor, opacity);
    }

    printReport(id: number) {
        this.resetMessages();
        this.listaDetalheDaJornada = null;
        this.loading = true;

        let start = this.searchObject.month.start;
        let end = this.searchObject.month.end;

        switch (id) {
            case 1:
                this.journeyService.getJourneyLog(this.searchObject.driver.id, start, end).then(response => {
                    if (response.error) {
                        this.errorMessage = response.error;
                    } else {
                        let report: JourneyLogReport = response.response;
                        let loggedUser = this.authGuard.loggedUser();
                        let dd = this.reportService.printJourneyLogReport(report, false, loggedUser.selectedCompany.name, true);
                        this.pdfService.print(dd);
                    }
                    this.loading = false;
                });
                break;
            case 2:
                this.journeyService.getJourneyCalculation(this.searchObject.driver.id, start, end, true).then(response => {
                    if (response.error) {
                        this.errorMessage = response.error;
                    } else {
                        let report: JourneyCalculationReport = response.response;
                        let loggedUser = this.authGuard.loggedUser();
                        let dd = this.reportService.printJourneyCalculationReport(loggedUser.selectedCompany.name, report, undefined, true);
                        this.pdfService.print(dd);
                        this.atribuirrRMLabore(report);
                    }
                    this.loading = false;
                });
                break;
            case 3:
                this.journeyService.getJourneyLog(this.searchObject.driver.id, start, end).then(response => {
                    if (response.error) {
                        this.errorMessage = response.error;
                    } else {
                        let report: JourneyLogReport = response.response;
                        let loggedUser = this.authGuard.loggedUser();
                        let dd = this.reportService.printJourneyCorrectionReport(report, false, loggedUser.selectedCompany.name);
                        this.pdfService.print(dd);
                    }
                    this.loading = false;
                });
                break;
            case 4:
                this.journeyService.getJourneyCalculation(this.searchObject.driver.id, start, end, true).then(response => {
                    if (response.error) {
                        this.errorMessage = response.error;
                    } else {
                        let report: JourneyCalculationReport = response.response;
                        this.journeyService.getJourneyLog(this.searchObject.driver.id, start, end).then(rLog => {
                            let journeyLog = rLog.response;
                            let loggedUser = this.authGuard.loggedUser();
                            let dd = this.reportService.printJourneyCalculationReport(loggedUser.selectedCompany.name, report, journeyLog, true);
                            this.pdfService.print(dd);
                            this.atribuirrRMLabore(report);
                        });
                    }
                    this.loading = false;
                });
                break;
            case 5:
                    this.journeyService.getJourneyLog(this.searchObject.driver.id, start, end).then(response => {
                        if (response.error) {
                            this.errorMessage = response.error;
                        } else {
                            let report: JourneyLogReport = response.response;
                            let loggedUser = this.authGuard.loggedUser();
                            let dd = this.reportService.printJourneyLogReport(report, false, loggedUser.selectedCompany.name, false);
                            this.pdfService.print(dd);
                        }
                        this.loading = false;
                    });
                    break;
        }
    }


    atribuirrRMLabore(valor) {
        try {
            if (valor.listaDetalheDaJornada) {
                this.listaDetalheDaJornada = valor.listaDetalheDaJornada;
            }
        } catch (error) {
            console.error(error);
        }
    }
    openMap(row) {
        if (row.latitude && row.longitude) {
            window.open('http://maps.google.com/maps?q=' + row.latitude + ',' + row.longitude, '_blank');
        }
    }

    doOperation(target: any, type: string) {
        var start = this.searchObject.month.start;
        var end = this.searchObject.month.end;
        var driver = this.searchObject.driver.id;
        var loggedUser = this.authGuard.loggedUser().id;

        this.nonconformityService.requestOperation(target, type, start, end, driver, loggedUser).then(response => {
            this.errorMessage = response.error;
            if (!this.errorMessage) {
                this.diff = response.response;
                this.modalService.open(this.operationDiffDialog, { centered: true, size: 'lg' }).result.then(result => {
                    if (result === 'y') {
                        this.askSearchAgain();
                    } else {
                    }
                });
            }
        });
    }

    saveEvent() {
        if (!this.selectedEvent.justificativa) {
            this.errorMessage = "Campo justificativa é obrigatório!";
        } else if (!this.isValidEvent(this.selectedEvent)) {
            this.errorMessage = "Evento fora do período permitido!";
        } else if (this.selectedEvent.locked && !this.authGuard.isApuracao()) {
            this.errorMessage = "Evento bloqueado e usuário não possui permissão para efetuar alteração!";
        } else {
            this.loading = true;
            this.errorMessage = undefined;

            this.selectedEvent.instanteEvento = new Date(this.selectedEvent.instanteEvento);

            delete this.selectedEvent.css;
            delete this.selectedEvent.circle;

            this.doOperation(this.selectedEvent, "SAVE_EVENT");
        }
    }

    askSearchAgain() {
        this.modalMessage = "Deseja refazer a busca para atualizar o calendário?";

        this.modalService.open(this.confirmDialog, { centered: true }).result.then(result => {
            this.resetCalendar();
            this.doSearch();
        }, (reason) => {
            this.loading = false;
        });
    }

    allowanceLunchRemaining(journey: CalendarJourneyVO) {
        this.modalMessage = 'Confirma abono de todas as horas restantes de almoço desta jornada? Este abono será lançado no final da jornada.';

        this.modalService.open(this.confirmDialog, { centered: true, size: 'lg' }).result.then(result => {
            this.loading = true;

            if (journey.locked && !this.authGuard.isApuracao()) {
                this.errorMessage = "Jornada bloqueada e usuário não possui permissão para efetuar alteração!";
                this.loading = false;
            } else {
                var target = {
                    id: journey.id
                };

                this.doOperation(target, 'ALLOWANCE_LUNCH_REMAINING');

            }
        }, (reason) => {
        });
    }

    modalAddToStart(hours: number) {
        this.manualDsr.endDate = moment(this.manualDsr.startDate).add(hours, 'hours').format('YYYY-MM-DD[T]HH:mm:ss');
    }

    createAllowance(journey: CalendarJourneyVO) {
        this.newAllowance = {};

        this.modalService.open(this.newAllowanceDialog, { centered: true, size: 'lg' }).result.then(result => {
            if (journey.locked && !this.authGuard.isApuracao()) {
                this.errorMessage = "Jornada bloqueada e usuário não possui permissão para efetuar alteração!";
            } else {
                this.errorMessage = undefined;
                this.loading = true;

                this.newAllowance.jornadaId = journey.id;

                let startCode = 17;
                let endCode = -17;

                if (this.newAllowance.tipo.id === 15) {
                    startCode = 21;
                    endCode = -21;
                }

                let startEvent = this.createMockAllowanceEvent(startCode);
                let endEvent = this.createMockAllowanceEvent(endCode);

                if (this.isValidEvent(startEvent) && this.isValidEvent(endEvent)) {
                    var indata = { eventoInicio: startEvent, eventoFinal: endEvent };

                    this.doOperation(indata, 'CREATE_ALLOWANCE');

                } else {
                    this.errorMessage = "Abono fora do período permitido!";
                    this.loading = false;
                }
            }
        }, (reason) => {
        });
    }

    createEvent(journey: CalendarJourneyVO) {
        this.newEvent = {
            inicio: this.utilService.formatDatetoDatetimeLocalInput(new Date(journey.jornadaInicio))
        };

        let start;
        if (typeof journey.jornadaInicio === "number") {
            start = journey.jornadaInicio;
        } else {
            start = journey.jornadaInicio.getTime();
        }

        this.dateChange(start + 1, journey);

        this.modalService.open(this.newEventDialog, { centered: true, size: 'lg' }).result.then(result => {
            if (journey.locked && !this.authGuard.isApuracao()) {
                this.errorMessage = "Evento bloqueado e usuário não possui permissão para efetuar alteração!";
            } else {
                this.errorMessage = undefined;

                let startCode = this.newEvent.tipoEvento.id;
                let endCode = this.newEvent.tipoEvento.id * -1;

                if (startCode === 1) {
                    endCode = 0;
                }

                let startEvent = this.createMockEvent(startCode, journey.id);
                let endEvent = this.createMockEvent(endCode, journey.id);

                if (journey.locked && !this.authGuard.isApuracao()) {
                    this.errorMessage = "Jornada bloqueada e usuário não possui permissão para efetuar alteração!";
                } else {
                    if (this.isValidEvent(startEvent) && this.isValidEvent(endEvent)) {
                        var indata = { eventoInicio: startEvent, eventoFinal: endEvent };
                        this.loading = true;

                        this.doOperation(indata, 'CREATE_EVENT');

                    } else {
                        this.modalErrorMessage = "Evento fora do período permitido";
                        this.showMessageModal();
                        return;
                    }
                }
            }
        }, (reason) => {
        });
    }

    createMockEvent(code, journeyId) {
        let loggedUser = this.authGuard.loggedUser();
        return {
            tipoEvento: code,
            instanteEvento: new Date(code > 0 ? this.newEvent.inicio : this.newEvent.fim),
            justificativa: this.newEvent.justificativa,
            empresaId: loggedUser.selectedCompany.id,
            jornadaId: journeyId,
            instanteLancamento: new Date(Date.now()),
            operadorLancamento: loggedUser.id
        }
    }

    createMockAllowanceEvent(code: number) {
        var loggedUser = this.authGuard.loggedUser();
        return {
            tipoEvento: code,
            instanteEvento: new Date(code > 0 ? this.newAllowance.inicio : this.newAllowance.fim),
            justificativa: this.newAllowance.tipo.descricao,
            empresaId: loggedUser.selectedCompany.id,
            jornadaId: this.newAllowance.jornadaId,
            instanteLancamento: new Date(Date.now()),
            operadorLancamento: loggedUser.id,
            motivoAbono: this.newAllowance.tipo
        };
    }

    manualDsrCreation(journey: CalendarJourneyVO) { 
        this.manualDsr = new NonconformityTreatment();
        this.manualDsr.driverId = this.searchObject.driver.id;
        // this.manualDsr.startDate = this.utilService.formatDatetoDatetimeLocalInput(new Date(journey.jornadaInicio));
        this.interjourneyStart = this.utilService.formatDatetoDatetimeLocalInput(new Date(journey.jornadaInicio));
        this.manualDsr.startDate = this.utilService.formatDatetoDatetimeLocalInput(moment(this.retrieveInterjourneyEnd(this.interjourneyStart)).add(1, 'seconds').toDate());

        if (journey.previousType === 'FOLGA' || journey.previousType === 'FERIAS' || journey.previousType === 'FERIADO') {
            this.manualDsr.endDate = this.utilService.formatDatetoDatetimeLocalInput(moment(this.manualDsr.startDate).add(24, 'hours').add(11, 'hours').add(1, 'seconds').toDate());
        } else {
            this.manualDsr.endDate = this.utilService.formatDatetoDatetimeLocalInput(moment(new Date(journey.jornadaFim)).add(11, 'hours').add(1, 'seconds').toDate());;
        }

        this.manualDsr.userId = this.authGuard.loggedUser().id;
        this.manualDsr.type = 'MANUAL_DSR_CREATION';
        this.manualDsr.previousType = journey.previousType;

        this.modalService.open(this.manualDSRCreationDialog, { centered: true, size: 'lg' }).result.then(result => {
            if (journey.locked && !this.authGuard.isApuracao()) {
                this.modalErrorMessage = "Jornada bloqueada e usuário não possui permissão para efetuar alteração!";
                this.showMessageModal();
                return;
            }

            if (!this.manualDsr.startDate || !this.manualDsr.endDate) {
                this.modalErrorMessage = "Datas início e final são de preenchimento obrigatório!";
                this.showMessageModal();
                return;
            } else {
                this.manualDsr.startDate = moment(this.manualDsr.startDate, "YYYY-MM-DD[T]HH:mm:ss").toDate();
                this.manualDsr.endDate = moment(this.manualDsr.endDate, "YYYY-MM-DD[T]HH:mm:ss").toDate();

                if (this.manualDsr.startDate > this.manualDsr.endDate || this.manualDsr.startDate === this.manualDsr.endDate) {
                    this.modalErrorMessage = "Data início maior ou igual que data final!";
                    this.showMessageModal();
                    return;
                }
            }

            if (journey.previousType === 'FOLGA' || journey.previousType === 'FERIAS' || journey.previousType === 'FERIADO') {
                this.manualDsr.endDate = moment(this.manualDsr.startDate).add(24, 'hours').toDate();
            }

            if (this.temDsrAutomatica) {
                this.modalService.open(this.dsrAutomaticaDialog, { centered: true, size: 'lg' }).result.then(result => {
                    this.loading = true;
                    this.temDsrAutomatica = false;
                    this.doOperation(this.manualDsr, "MANUAL_DSR");
                }, (reason) => {
                });
            } else {
                this.loading = true;
                this.doOperation(this.manualDsr, "MANUAL_DSR");
            }

        }, (reason) => {
        });
    }

    allowanceRemaining(journey: CalendarJourneyVO) {
        this.modalMessage = 'Confirma abono de todas as horas restantes desta jornada? Este abono será lançado no final da jornada.';

        this.modalService.open(this.confirmDialog, { centered: true, size: 'lg' }).result.then(result => {
            if (journey.locked && !this.authGuard.isApuracao()) {
                this.errorMessage = "Jornada bloqueada e usuário não possui permissão para efetuar alteração!";
            } else {
                this.loading = true;

                var target = {
                    id: journey.id
                };

                this.doOperation(target, "ALLOWANCE_REMAINING");

            }
        }, (reason) => {
        });
    }

    dsr(journey: CalendarJourneyVO) {
        if (journey.type === 'JORNADA') {
            return this.markDSRHE(journey);
        } else {
            this.manualDsrCreation(journey);
        }
    }

    markDSRHE(journey: CalendarJourneyVO) {
        this.modalMessage = 'Confirma marcação da jornada para pagamento de HE de DSR?';

        this.modalService.open(this.confirmDialog, { centered: true, size: 'lg' }).result.then(result => {
            if (journey.locked && !this.authGuard.isApuracao()) {
                this.errorMessage = "Jornada bloqueada e usuário não possui permissão para efetuar alteração!";
            } else {
                this.loading = true;

                this.doOperation({ id: journey.id }, "MARK_DSR_HE");

            }
        }, (reason) => {
        });
    }

    markWaitingAsWorked(journey: CalendarJourneyVO) {
        this.modalMessage = "Confirma marcação da jornada para compensação de horário trabalhado com tempo de espera?";

        this.modalService.open(this.confirmDialog, { centered: true, size: 'lg' }).result.then(result => {
            if (journey.locked && !this.authGuard.isApuracao()) {
                this.errorMessage = "Jornada bloqueada e usuário não possui permissão para efetuar alteração!";
            } else {
                this.loading = true;

                this.doOperation({ id: journey.id }, "MARK_AWAITING_AS_WORKED");

            }
        }, (reason) => {
        });
    }

    disableExecutarButton() {
        if (this.searchType === 'M') {
          return this.searchObject.year === undefined;
        } else {
            return (this.manualStart === undefined || this.manualEnd === undefined);
        }        
    }

    doSearch() {
        this.resetMessages();

        if (!this.searchObject) {
            this.errorMessage = "Favor preencher os campos do formulário.";
        } else if (!this.searchObject.driver) {
            this.errorMessage = "Favor selecionar um motorista.";
        } else {
            this.loading = true;

            let start, end;
            if (this.searchType === 'D') {
                start = this.manualStart.getTime();
                end = this.manualEnd.getTime();
                this.searchObject.month = {
                    end: this.manualEnd.getTime(),
                    start: this.manualStart.getTime(),
                    name: "Período Manual"
                };
            } else {
                start = this.searchObject.month.start;
                end = this.searchObject.month.end;
            }

            let firedDate = 0;

            if (this.searchObject.firedDate) {
                firedDate = this.searchObject.firedDate.getTime();
            }

            this.searchObject.preview = true;
            var loggedUser = this.authGuard.loggedUser();

            this.nonconformityService.calendar(start, end, this.searchObject.driver.id, this.searchObject.preview, this.searchObject.fired, firedDate, loggedUser.id).then(response => {
                this.errorMessage = response.error;
                if (!this.errorMessage) {
                    this.calendar = response.response;
                    for (var week in this.calendar.weeks) {
                        this.calendar.weeks[week].days.forEach((day: CalendarDayVO) => {
                            if (day.journey) {
                                if (!day.journey.locked) {
                                    day.journey.locked = false;
                                }

                                day.journey.events.forEach((event) => {
                                    if (event.icone) {
                                        event.icone = "./assets/img/tipoevento/" + event.icone + ".png";
                                    } else if (event.type === 1) {
                                        event.css = "fa fa-sun";
                                    } else if (event.type === 0) {
                                        event.css = "fa fa-moon";
                                    } else if (event.type === 20 || event.type === -20 || event.type === 21 || event.type === -21) {
                                        event.css = "fa fa-umbrella-beach";
                                    } else if (event.type === 17 || event.type === -17) {
                                        event.circle = "A";
                                    } else if (event.type === 26 || event.type === -26) {
                                        event.css = "fa fa-exchange-alt";
                                    }

                                    if (!event.locked) {
                                        event.locked = false;
                                    }
                                });

                                for (var type in day.journey.typeSummary) {
                                    var summary = day.journey.typeSummary[type];
                                    if (summary) {
                                        if (summary.icon) {
                                            day.journey.typeSummary[type].icon = "./assets/img/tipoevento/" + summary.icon + ".png";
                                        } else if (type === 'Abono') {
                                            day.journey.typeSummary[type].circle = "A";
                                        } else if (type === 'Interjornada') {
                                            day.journey.typeSummary[type].css = "fa fa-exchange-alt";
                                        } else if (type === 'DSR') {
                                            day.journey.typeSummary[type].css = "fa fa-umbrella-beach";
                                        }
                                    }
                                }
                            }
                        });
                    };
                }
                this.loading = false;
            });
        }
    }

    resetCalendar() {
        this.loading = true;
        this.calendar = undefined;
        this.selectedWeek = undefined;
        this.errorMessage = undefined;
        this.successMessage = undefined;
        this.infoMessage = undefined;
        this.loading = false;
    }

    toggleWeek(week) {
        this.selectedWeek = week;
    }

    showJourney(journey) {
        this.journeyService.getSingleSummary(journey).then(response => {
            localStorage.setItem('journey', JSON.stringify({
                summary: response.response,
                period: this.utilService.formatDate(response.response.inicio, "DD/MM/YYYY"),
                returnURL: 'adm/nonconformityCalendar'
            }));

            localStorage.setItem('calendar', JSON.stringify({
                selectedWeek: this.selectedWeek,
                searchObject: this.searchObject,
                calendar: this.calendar,
                Situacao: this.statusControl.value
            }));

            this.router.navigate(['adm/journey/form'], { skipLocationChange: true });
        });
    }

    dateDiff(start, end) {
        var ms = moment(end).add(11, 'hours').diff(moment(start));
        var d = moment.duration(ms);
        return Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
    }

    retrieveInterjourneyStart(start) {
        var mStart = moment(start).utc();
        return mStart.toDate();
    }

    retrieveInterjourneyEnd(start) {
        var end = moment(start).add(11, 'hours');
        return end.toDate();
    }

    showMessageModal() {
        this.modalService.open(this.messageDialog, { centered: true });
    }

    showDataPagamentoModal() {
        this.modalService.open(this.dataPagamentoDialog, { centered: true });
    }

    showMessageJustificativaDialog() {
        this.modalService.open(this.justificativaDialog, { centered: true });
    }

    showMessageJustificativaHoraCinquentaPorcentoDialog() {

        this.modalService.open(this.justificativaHoraCinquentaPorcentoDialog, { centered: true });
    }



    showMessageJustificativaDSRDialog() {

        this.modalService.open(this.justificativaHoraDSRDialog, { centered: true });
    }

    dateChange(date: number, journey: CalendarJourneyVO) {
        this.errorMessage = undefined;
        this.loading = true;

        if (!date) {
            this.loading = false;
            this.errorMessage = "Data inválida!";
            return;
        }

        this.journeyEventService.findByDateJourney(journey.id, date).then(response => {
            this.errorMessage = response.error;
            if (!this.errorMessage) {
                this.eventTypeList = response.response;
            }
            this.loading = false;
        }).catch(reason => {
            this.errorMessage = reason;
            this.loading = false;
        });
    }

    onStatusChange(value) {
        if (value !== "") {
            this.driverList = this.originalDriverList.filter(d => d.situacao === +value);
        } else {
            this.driverList = this.originalDriverList;
        }
    }

    onYearChange(value) {

        this.monthList = value.months;
        this.searchObject.month = this.monthList[0];
        this.showMonthList = true;           
    }

    abonarHorasExtras() {
        try {
            if (!this.justificativa || this.justificativa.length == 0) {
                this.modalErrorMessage = "Uma justificativa deve ser inserida.";
                this.showMessageModal();
            } else {
                this.journeyService.abonarHorasExtras(this.jornadaSelecionadaAbonarHorasExtra, this.justificativa)
                    .then(reponse => {
                        this.justificativa = "";
                        this.modalService.dismissAll();
                        this.doSearch();
                    });

            }
        } catch (error) {
            console.error(error);
        }
    }

    horasExtrasCinquentaPorcento() {
        try {
            if (!this.justificativa || this.justificativa.length == 0) {
                this.modalErrorMessage = "Uma justificativa deve ser inserida.";
                this.showMessageModal();
            } else {
                this.journeyService.horasExtrasCinquentaPorcento(this.jornadaSelecionadaAbonarHorasExtra, this.justificativa)
                    .then(reponse => {
                        this.justificativa = "";
                        this.modalService.dismissAll();
                        this.doSearch();
                    });

            }
        } catch (error) {
            console.error(error);
        }
    }


    horasExtrasDSRTurno() {
        try {
            if (!this.justificativa || this.justificativa.length == 0) {
                this.modalErrorMessage = "Uma justificativa deve ser inserida.";
                this.showMessageModal();
            } else {
                this.journeyService.horasExtrasDSRTurno(this.jornadaSelecionadaAbonarHorasExtra, this.justificativa)
                    .then(reponse => {
                        this.justificativa = "";
                        this.modalService.dismissAll();
                        this.doSearch();
                    });

            }
        } catch (error) {
            console.error(error);
        }
    }

    abrirTelaDeJustificativa(value) {
        try {
            this.jornadaSelecionadaAbonarHorasExtra = value;
            this.showMessageJustificativaDialog();
        } catch (error) {
            console.error(error);
        }
    }

    abrirTelaDeJustificativaHoraCinquentaPorcentoDialog(value, status) {
        try {
            this.jornadaSelecionadaAbonarHorasExtra = value;
            this.justificativaDSRDialogTitulo = status?'Reverter as horas extras do dia de folga.':'Aplicar 50% de horas.';
            this.showMessageJustificativaHoraCinquentaPorcentoDialog();
        } catch (error) {
            console.error(error);
        }
    }

    abrirTelaDeJustificativaHoraDSRDialog(value, status) {
        try {
            this.jornadaSelecionadaAbonarHorasExtra = value;
            this.justificativaDSRDialogTitulo =  status?'Reverter as horas extras do dia de DSR.':'Marcar dia como DSR para gerar horas extras.';
            this.showMessageJustificativaDSRDialog();
        } catch (error) {
            console.error(error);
        }
    }


    gerarArquivoRmLabore() {
        try {
            if (!this.dataPagamento) {
                this.modalErrorMessage = "Para gerar o arquivo RM Labore, a Data do Pagamento deve ser informada.";
                this.showMessageModal();
            } else {
                if (this.listaDetalheDaJornada && this.listaDetalheDaJornada.length > 0) {
                    let chapa = "";
                    let texto = "";
                    var dd =  this.pad(this.dataPagamento.getDate().toString(), 2);
                    var mm = this.pad((this.dataPagamento.getMonth() + 1).toString(), 2); //because January is 0! 
                    var yyyy = this.dataPagamento.getFullYear();
                    var dataFinal = dd+""+mm+""+yyyy;
                    this.listaDetalheDaJornada.forEach(e => {
                        chapa = e.chapa;
                        texto = texto + this.pad(e.chapa, 6);
                        texto = texto + "          "
                        texto = texto + dataFinal;
                        texto = texto + this.pad(e.evento, 4);
                        texto = texto + this.pad(e.hora, 6);
                        texto = texto + '           0.00';
                        texto = texto + '           0.00';
                        texto = texto + '           0.00';
                        texto = texto + "N";
                        texto = texto + "N";
                        texto = texto + '\n';
                    });
                    this.dyanmicDownloadByHtmlTag({
                        fileName: chapa,
                        text: texto
                    });
                    this.modalService.dismissAll(this.dataPagamentoDialog);
                } else {
                    this.modalErrorMessage = "Para gerar o arquivo RM Labore, a APURAÇÂO deve ser feita primeiro.";
                    this.showMessageModal();
                }
            }
        } catch (error) {
            console.error(error);
        }
    }


    pad(num: string, size: number): string {
        while (num.length < size) num = "0" + num;
        return num;
    }

    private setting = {
        element: {
            dynamicDownload: null as HTMLElement
        }
    }

    private dyanmicDownloadByHtmlTag(arg: {
        fileName: string,
        text: string
    }) {
        if (!this.setting.element.dynamicDownload) {
            this.setting.element.dynamicDownload = document.createElement('a');
        }
        const element = this.setting.element.dynamicDownload;
        const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
        element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
        element.setAttribute('download', arg.fileName);

        var event = new MouseEvent("click");
        element.dispatchEvent(event);
    }

    gerarApuracaoExcel() {
        try {
            this.resetMessages();
            this.loading = true;
            let start = this.searchObject.month.start;
            let end = this.searchObject.month.end;
            this.journeyService.getApuracaoJornadaExcel(this.searchObject.driver.id, start, end).then(response => {
                if (response.error) {
                    this.errorMessage = response.error;
                } else {
                    let urls: string = response.response;
                    if (urls.length > 1) {
                        window.open(urls, "_blank");
                    }
                }
                this.loading = false;
            });
        } catch (error) {
            console.error(error);
        }
    }

    inserirDsrManual() {

    }

}