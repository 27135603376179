/// <reference types="@types/googlemaps" />
import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from "@angular/router";
import { Coordenada } from 'app/classes/coordenada';
import { Local } from 'app/classes/local';
import { Rota } from 'app/classes/rota';
import { LocalService } from 'app/services/crud/local.service';
import { RotaService } from 'app/services/rota.service';
import { uniqueKey } from 'highcharts';
declare const google: any;

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class RotaFormComponent implements OnInit {

  displayedColumns: string[] = ['primeiroPonto', 'segundoPonto', 'velocidadeMaxima'];
  dataSource: MatTableDataSource<any>;
  mapa: any;
  public loading: boolean = false;
  showBotaoPesquisa: boolean = false;
  dropdownListOrigem = [];
  public selectedItemsOrigem = [];
  dropdownListDestino = [];
  public selectedItemsDestino = [];
  dropdownSettings: {};
  private listaDeLocal: Local[] = [];
  private localOrigem: Local;
  private localDestino: Local;
  lat: number = -19.923395;
  lng: number = -43.944809;
  public rota: Rota;
  directionsDisplayGlobal: any;
  public coordenadas: Coordenada[] = [];
  private tipoAcao: number;
  public activeID: any;
  public state: string;
  public marcadores = [];
  public polylines = [];
  public inputsBloqueados: boolean = false;
  public paginaDeCriar: boolean = true;
  public paginaDeEditar: boolean = true;
  public regrasAdicionadas: any[] = [];
  public primeiroPonto: any;
  public segundoPonto: any;
  public primeiroEndereco: string;
  public segundoEndereco: string;
  formGroup: FormGroup;
  private flightPath: google.maps.Polyline;
  private marcadoresVelocidade = {};
  public velocidadeMaxima: any;
  private limitsPreset: [] = [];
  xpandStatus: boolean = false;

  constructor(private localService: LocalService,
    private rotaService: RotaService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,) { }

  ngOnInit() {
    this.loading = true;
    this.formGroup = this.formBuilder.group({
      primeiroPonto: [null, [Validators.required]],
      segundoPonto: [null, [Validators.required]],
      velocidadeMaxima: [null, [Validators.required]],
    });
    try {
      //this.listaDeCoordenadas = [];
      if (!this.rota) {
        this.rota = new Rota();
      }
      this.route.paramMap.subscribe(params => {
        this.state = params.get("state");
        this.activeID = params.get("id");
      });

      if (this.state == "I") {
        this.tipoAcao = 1;
        this.localService.getListaLocaisSemCoordenadas().then(response => {
          this.listaDeLocal = response.listaDeLocal;
          this.dropdownListOrigem = [];
          this.dropdownListDestino = [];
          for (var i = 0; i < this.listaDeLocal.length; i++) {
            var local = this.listaDeLocal[i];
            this.dropdownListOrigem.push({ item_id: local.id, item_text: local.nome });
            this.dropdownListDestino.push({ item_id: local.id, item_text: local.nome });
          }
          this.dropdownSettings = {
            singleSelection: true,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Todos',
            unSelectAllText: 'Desmarcar todos',
            allowSearchFilter: true,
            searchPlaceholderText: 'Pesquisar'
          };
          this.loading = false;
          this.paginaDeEditar = false;
        });
      } else {
        this.tipoAcao = 2;
        this.localService.getListaLocaisSemCoordenadas().then(response => {
          this.rotaService.getById(this.activeID).then(response2 => {
            this.rota = response2.rota;
            this.listaDeLocal = response.listaDeLocal;
            this.dropdownListOrigem = [];
            this.dropdownListDestino = [];
            for (var i = 0; i < this.listaDeLocal.length; i++) {
              var local = this.listaDeLocal[i];
              this.dropdownListOrigem.push({ item_id: local.id, item_text: local.nome });
              this.dropdownListDestino.push({ item_id: local.id, item_text: local.nome });
            }

            this.localDestino = this.rota.localFinal;
            this.selectedItemsDestino = [
              { item_id: this.rota.localFinal.id, item_text: this.rota.localFinal.nome }
            ];
            this.localOrigem = this.rota.localInicio;
            this.selectedItemsOrigem = [
              { item_id: this.rota.localInicio.id, item_text: this.rota.localInicio.nome }
            ];
            this.dropdownSettings = {
              singleSelection: true,
              idField: 'item_id',
              textField: 'item_text',
              selectAllText: 'Todos',
              unSelectAllText: 'Desmarcar todos',
              allowSearchFilter: true,
              searchPlaceholderText: 'Pesquisar'
            };
            this.ajustarGoogleMaps();
            this.loading = false;
            this.toggleInputs();
            this.paginaDeCriar = false;
            if (response2.rota.listaRegrasRotas[0]) {
              response2.rota.listaRegrasRotas.forEach((regra, index) => {
                const primeiroLatLng = new google.maps.LatLng(regra.pontoInicialLat, regra.pontoInicialLng);
                const segundoLatLng = new google.maps.LatLng(regra.pontoFinalLat, regra.pontoFinalLng);
                this.regrasAdicionadas = [
                  ...this.regrasAdicionadas,
                  {
                    idRegra: regra.id,
                    id: regra.id,
                    primeiroPonto: regra.enderecoInicial,
                    segundoPonto: regra.enderecoFinal,
                    latLngPrimeiro: primeiroLatLng,
                    latLngSegundo: segundoLatLng,
                    velocidadeMaxima: regra.velocidadeMaxima,
                  }
                ];
                if (index === response2.rota.listaRegrasRotas.length - 1) {
                  this.dataSource = new MatTableDataSource(this.regrasAdicionadas);
                }
                const id1 = `${primeiroLatLng.lat()}@${primeiroLatLng.lng()}`;
                const marker1 = new google.maps.Marker({
                  id: id1,
                  position: primeiroLatLng,
                  title: "Primeiro Ponto",
                  map: this.mapa,
                  icon: "https://maps.google.com/mapfiles/marker.png",
                });
                this.marcadoresVelocidade[id1] = marker1;
                const id2 = `${segundoLatLng.lat()}@${segundoLatLng.lng()}`
                const marker2 = new google.maps.Marker({
                  id: id2,
                  position: segundoLatLng,
                  title: "Segundo Ponto",
                  map: this.mapa,
                  icon: "https://maps.google.com/mapfiles/marker.png",
                });
                this.marcadoresVelocidade[id2] = marker2;
              });
              this.xpandStatus = true;
            }
          });
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  getLocation = (latLng: google.maps.LatLng, address: string) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'latLng': latLng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[1]) {
          this[address] = results[1].formatted_address;
        }
      }
    });
  }

  onChangeVelocidade(event: any) {
    this.velocidadeMaxima = event.target.value;
  }

  onItemSelectOrigem(item: any) {
    this.inserirLocalOrigemSelecionado(item);
  }

  onItemSelectDestino(item: any) {
    this.inserirLocalDestinoSelecionado(item);
  }

  onSelectAll(items: any) {
    console.log("Todos");
  }

  onDeSelectOrigem(item: any) {
    this.removerLocalOrigemSelecionado(item);
  }

  onDeSelectDestino(item: any) {
    this.removerLocalDestinoSelecionado(item);
  }

  inserirLocalOrigemSelecionado(item: any): void {
    try {
      var local = this.listaDeLocal.find(e => e.id == item.item_id);
      if (local) {
        this.localOrigem = local;
      }
    } catch (error) {
      console.log(error);
    }
  }

  inserirLocalDestinoSelecionado(item: any): void {
    try {
      var local = this.listaDeLocal.find(e => e.id == item.item_id);
      if (local) {
        this.localDestino = local;
      }
    } catch (error) {
      console.log(error);
    }
  }

  removerLocalOrigemSelecionado(item: any): void {
    try {
      this.localOrigem = null;
    } catch (error) {
      console.log(error);
    }
  }

  removerLocalDestinoSelecionado(item: any): void {
    try {
      this.localDestino = null;
    } catch (error) {
      console.log(error);
    }
  }

  onMapReady(map) {
    this.mapa = map;
  }

  ajustarGoogleMaps() {
    try {
      this.mapa.setMapTypeId('hybrid');
      var pathLL = [];
      this.rota.listaDeCoordenadas.forEach(c => {
        var a1 = new google.maps.LatLng(c.latitude, c.longitude);
        pathLL.push(a1);
      });

      var markerInicial = new google.maps.Marker({
        position: new google.maps.LatLng(this.rota.listaDeCoordenadas[0].latitude, this.rota.listaDeCoordenadas[0].longitude),
        map: this.mapa,
        icon: "https://maps.google.com/mapfiles/markerA.png",
      });
      this.marcadores.push(markerInicial);

      var markerFinal = new google.maps.Marker({
        position: new google.maps.LatLng(this.rota.listaDeCoordenadas[(this.rota.listaDeCoordenadas.length - 1)].latitude, this.rota.listaDeCoordenadas[(this.rota.listaDeCoordenadas.length - 1)].longitude),
        map: this.mapa,
        icon: "https://maps.google.com/mapfiles/markerB.png",
      });
      this.marcadores.push(markerFinal);

      this.flightPath = new google.maps.Polyline({
        path: pathLL,
        geodesic: true,
        strokeColor: "#0000FF",
        strokeOpacity: 2.0,
        strokeWeight: 3,
      });
      google.maps.event.addListener(this.flightPath, 'click', this.handleClickPolyline)
      this.flightPath.setMap(this.mapa);
      this.polylines.push(this.flightPath);
      var center = new google.maps.LatLng(this.rota.listaDeCoordenadas[0].latitude, this.rota.listaDeCoordenadas[0].longitude);
      this.mapa.panTo(center);
      this.mapa.setZoom(10);
    } catch (error) {
      console.error(error);
    }
  }

  handleClickPolyline = async (event: google.maps.MapMouseEvent) => {
    if (this.primeiroPonto && this.segundoPonto) {
      return;
    }
    const myLatLng = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    const id = `${myLatLng.lat}@${myLatLng.lng}`;
    const marker = new google.maps.Marker({
      id,
      position: myLatLng,
      title: this.primeiroPonto ? "Segundo Ponto" : "Primeiro Ponto",
      map: this.mapa,
      icon: "https://maps.google.com/mapfiles/marker.png",
    });
    this.marcadoresVelocidade[id] = marker;
    const point = `${myLatLng.lat}@${myLatLng.lng}`
    const latLng = new google.maps.LatLng(myLatLng.lat, myLatLng.lng);
    if (this.primeiroPonto) {
      this.getLocation(latLng, 'segundoEndereco');
      return this.segundoPonto = point;
    }
    this.getLocation(latLng, 'primeiroEndereco');
    return this.primeiroPonto = point;
  }

  adicionarRegra() {
    if (!this.primeiroPonto || !this.segundoPonto || !this.velocidadeMaxima) {
      return;
    }
    const primeiroEndereco = this.primeiroEndereco;
    const segundoEndereco = this.segundoEndereco;
    const velocidadeMaxima = this.velocidadeMaxima;
    const primeiroLatLng = this.primeiroPonto;
    const segundoLatLng = this.segundoPonto;
    const formattedRule = {
      idRota: this.rota.id,
      pontoInicialLat: this.primeiroPonto.split('@')[0],
      pontoInicialLng: this.primeiroPonto.split('@')[1],
      pontoFinalLat: this.segundoPonto.split('@')[0],
      pontoFinalLng: this.segundoPonto.split('@')[1],
      velocidadeMaxima: this.velocidadeMaxima,
      enderecoInicial: this.primeiroEndereco,
      enderecoFinal: this.segundoEndereco,
    };
    this.rotaService.gravarRegraRota(formattedRule).then(response => {
      const idRegra = response.idRegraRota;
      this.regrasAdicionadas = [
        ...this.regrasAdicionadas,
        {
          idRegra,
          id: idRegra,
          primeiroPonto: primeiroEndereco,
          segundoPonto: segundoEndereco,
          latLngPrimeiro: primeiroLatLng,
          latLngSegundo: segundoLatLng,
          velocidadeMaxima: velocidadeMaxima,
        }
      ];
      this.dataSource = new MatTableDataSource(this.regrasAdicionadas);
    });
    this.primeiroPonto = "";
    this.segundoPonto = "";
    this.primeiroEndereco = "";
    this.segundoEndereco = "";
    this.velocidadeMaxima = "";
    this.xpandStatus = true;
  }

  excluirRegra(regra: any): void {
    const primeiroId = regra.latLngPrimeiro.lat ? `${regra.latLngPrimeiro.lat()}@${regra.latLngPrimeiro.lng()}` : regra.latLngPrimeiro;
    const segundoId = regra.latLngSegundo.lat ? `${regra.latLngSegundo.lat()}@${regra.latLngSegundo.lng()}` : regra.latLngSegundo;
    const marker1 = this.marcadoresVelocidade[primeiroId];
    const marker2 = this.marcadoresVelocidade[segundoId];
    marker1.setMap(null);
    marker2.setMap(null);
    const regrasFiltradas = this.regrasAdicionadas.filter(x => x.id !== regra.id);
    this.regrasAdicionadas = regrasFiltradas;
    this.dataSource = new MatTableDataSource(regrasFiltradas);
    this.rotaService.excluirRegraRota(regra.idRegra);
    if (this.regrasAdicionadas.length === 0) {
      this.xpandStatus = false;
    }
  }

  removePoints() {
    if (!this.primeiroPonto || !this.segundoPonto) {
      return;
    }
    const primeiro = this.primeiroPonto;
    const primeiroId = `${primeiro.split('@')[0]}@${primeiro.split('@')[1]}`;
    const segundo = this.segundoPonto;
    const segundoId = `${segundo.split('@')[0]}@${segundo.split('@')[1]}`;
    const marker1 = this.marcadoresVelocidade[primeiroId];
    const marker2 = this.marcadoresVelocidade[segundoId];
    marker1.setMap(null);
    marker2.setMap(null);
    this.primeiroPonto = '';
    this.segundoPonto = '';
    this.primeiroEndereco = '';
    this.segundoEndereco = '';
    this.velocidadeMaxima = '';
  }

  toggleInputs() {
    this.inputsBloqueados = !this.inputsBloqueados;
  }

  gerarRota() {
    try {
      if (!this.coordenadas || !this.localOrigem || !this.localDestino) {
        return;
      }
      this.limparRota();
      this.toggleInputs();
      this.mapa.setMapTypeId('hybrid');
      if (this.localOrigem && this.localDestino) {
        var rendererOptions = {
          draggable: true
        };
        var directionsDisplay = new google.maps.DirectionsRenderer(rendererOptions);
        this.directionsDisplayGlobal = directionsDisplay;
        var directionsService = new google.maps.DirectionsService();
        directionsDisplay.setMap(this.mapa);

        this.localService.getListaCoordenadasPorId(
          this.localOrigem.id, this.localDestino.id)
          .then(response => {
            if (response) {

              if (!response.hasOwnProperty("listaDeLocal") || !response.listaDeLocal ||
                !response.listaDeLocal.length)
                return;

              let coordenadaInit = response.listaDeLocal[0].listaDeCoordenadas[0];
              let coordenadaFim = response.listaDeLocal[1].listaDeCoordenadas[0];

              var start = new google.maps.LatLng(coordenadaInit.latitude, coordenadaInit.longitude);
              var end = new google.maps.LatLng(coordenadaFim.latitude, coordenadaFim.longitude);
              var request = {
                origin: start,
                destination: end,
                travelMode: google.maps.TravelMode.DRIVING
              };

              directionsService.route(request, (response, status) => this.ngZone.run(() => {
                if (status == google.maps.DirectionsStatus.OK) {
                  directionsDisplay.setDirections(response);
                  try {
                    this.coordenadas = [];
                    var directionResult = directionsDisplay.directions;
                    var myRoute = directionResult.routes[0].legs[0];
                    var contCoor = 1;
                    for (var iw = 0; iw < myRoute.steps.length; iw++) {
                      var step = myRoute.steps[iw];
                      var latitude = step.start_location.lat();
                      var longitude = step.start_location.lng();
                      let coordenada = new Coordenada();
                      coordenada.sequencia = contCoor;
                      coordenada.latitude = latitude;
                      coordenada.longitude = longitude;
                      coordenada.isRaio = false;
                      this.coordenadas.push(coordenada);

                      for (var a = 0; a < step.lat_lngs.length; a++) {
                        var lat = step.lat_lngs[a].lat();
                        var lng = step.lat_lngs[a].lng();
                        coordenada = new Coordenada();
                        coordenada.sequencia = ++contCoor;
                        coordenada.latitude = lat;
                        coordenada.longitude = lng;
                        coordenada.isRaio = false;
                        this.coordenadas.push(coordenada);
                      }

                      latitude = step.end_location.lat();
                      longitude = step.end_location.lng();
                      coordenada = new Coordenada();
                      coordenada.sequencia = ++contCoor;
                      coordenada.latitude = latitude;
                      coordenada.longitude = longitude;
                      coordenada.isRaio = false;
                      this.coordenadas.push(coordenada);
                      ++contCoor;
                    }
                  } catch (error) {
                    console.error(error);
                  }
                  google.maps.event.addListener(directionsDisplay, 'directions_changed', () => {
                    this.ngZone.run(() => {
                      try {
                        this.regrasAdicionadas = [];
                        this.coordenadas = [];
                        var directionResult = directionsDisplay.directions;
                        var myRoute = directionResult.routes[0].legs[0];
                        var contCoor = 1;
                        for (var iw = 0; iw < myRoute.steps.length; iw++) {
                          var step = myRoute.steps[iw];
                          var latitude = step.start_location.lat();
                          var longitude = step.start_location.lng();
                          let coordenada = new Coordenada();
                          coordenada.sequencia = contCoor;
                          coordenada.latitude = latitude;
                          coordenada.longitude = longitude;
                          coordenada.isRaio = false;
                          this.coordenadas.push(coordenada);

                          for (var a = 0; a < step.lat_lngs.length; a++) {
                            var lat = step.lat_lngs[a].lat();
                            var lng = step.lat_lngs[a].lng();
                            coordenada = new Coordenada();
                            coordenada.sequencia = ++contCoor;
                            coordenada.latitude = lat;
                            coordenada.longitude = lng;
                            coordenada.isRaio = false;
                            this.coordenadas.push(coordenada);
                          }

                          latitude = step.end_location.lat();
                          longitude = step.end_location.lng();
                          coordenada = new Coordenada();
                          coordenada.sequencia = ++contCoor;
                          coordenada.latitude = latitude;
                          coordenada.longitude = longitude;
                          coordenada.isRaio = false;
                          this.coordenadas.push(coordenada);
                          ++contCoor;
                        }
                      } catch (error) {
                        console.error(error);
                      }
                    });

                  });
                }
              }));
            }
          })

      }
    } catch (error) {
      console.log(error);
    }
  }


  acaoDaTela() {
    try {
      if (!this.coordenadas || !this.localOrigem || !this.localDestino || !this.inputsBloqueados) {
        return;
      }
      this.showBotaoPesquisa = true;
      if (this.tipoAcao === 1) {
        this.rota.listaDeCoordenadas = this.coordenadas;
        this.rota.localInicio = this.localOrigem;
        this.rota.localFinal = this.localDestino;
        this.rotaService.cadastrarRota(this.rota).then(response => {
          this.showBotaoPesquisa = false;
          this.voltar();
        });
      } else {
        if (this.coordenadas && this.coordenadas.length > 1) {
          this.rota.listaDeCoordenadas = this.coordenadas;
        }
        this.rotaService.editarRota(this.rota).then(response => {
          this.showBotaoPesquisa = false;
          this.voltar();
        });
      }
    } catch (error) {
      console.error(error);
    }

  }

  excluirRota() {
    try {
      if (!this.coordenadas || !this.localOrigem || !this.localDestino || !this.inputsBloqueados) {
        return;
      }
      this.rotaService.excluirRota(this.rota).then(response => {
        this.voltar();
      });
    } catch (error) {
      console.error(error);
    }
  }

  limparRota() {
    try {
      if (this.inputsBloqueados) {
        this.toggleInputs();
      }
      this.limparMarcadores();
      this.coordenadas = [];
      if (this.directionsDisplayGlobal) {
        this.directionsDisplayGlobal.setMap(null);
        this.directionsDisplayGlobal = null;
      }
    } catch (error) {
      console.log(error);
    }
  }

  limparMarcadores() {
    try {
      this.marcadores.forEach(m => {
        m.setMap(null);
      });
      this.marcadores = [];
      this.polylines.forEach(m => {
        m.setMap(null);
      });
      this.polylines = [];
    } catch (error) {

    }
  }

  voltar() {
    try {
      this.router.navigate(['adm/rota/list']);
    } catch (error) {
      console.log(error);
    }
  }

}
//https://stackoverflow.com/questions/50290087/unable-to-set-angular-variable-inside-callback
//https://stackoverflow.com/questions/43164426/angular-2-google-maps-javascript-api-route-service-function-doesnt-exist