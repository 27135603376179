import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { Filtro } from 'app/classes/filtro';
import { ReportService } from 'app/services/report/report.service';
import { Veiculo } from 'app/classes/veiculo';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { NumeroDeViagemRelatorio } from 'app/classes/numero-de-viagem-relatorio';

@Component({
  selector: 'app-grafico-velocidade',
  templateUrl: './grafico-velocidade.component.html',
  styleUrls: ['./grafico-velocidade.component.css']
})
export class GraficoVelocidadeComponent implements OnInit {

  @ViewChild('iframe') iframe: ElementRef;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: {};
  public filtro: Filtro;
  public mostrarPdf: boolean = false;
  public pdfSrc: any;
  listaDeVeiculos: Veiculo[] = [];
  veiculo: Veiculo;
  responseBase64: String;
  funcao: number = 2;
  showGrafico: boolean = false;
  totalDeViagens: number = 0;
  pesoMedio: number = 45;
  public peso: String;
  totalProducao: number = 0;
  erro: String;

  constructor(private veiculoService: VeiculoService, private reportService: ReportService) { }

  ngOnInit() {
    try {
      this.filtro = new Filtro();
      this.veiculoService.getLista().then(response => {
        var data = response.listaDeVeiculos;
        this.dropdownList = [];
        for (var i = 0; i < data.length; i++) {
          var veiculo = data[i];
          this.dropdownList.push({ item_id: veiculo.id, item_text: veiculo.placa });
        }

        this.dropdownSettings = {
          singleSelection: false,
          idField: 'item_id',
          textField: 'item_text',
          selectAllText: 'Todos',
          unSelectAllText: 'Desmarcar todos',
          itemsShowLimit: 3,
          limitSelection: 10,
          allowSearchFilter: true,
          searchPlaceholderText: 'Pesquisar'
        };
      });

    } catch (error) {
      console.error(error);
    }
  }


  onItemSelect(item: any) {
    this.inserirVeiculoSelecionado(item);
  }
  onSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirVeiculoSelecionado(items[i]);
    }
  }
  onDeSelect(item: any) {
    this.removerVeiculoSelecionado(item);
  }

  inserirVeiculoSelecionado(item: any): void {
    try {
      var veiculo = this.listaDeVeiculos.find(v => v.id == item.item_id);
      if (!veiculo) {
        this.veiculo = new Veiculo();
        this.veiculo.id = item.item_id;
        this.veiculo.placa = item.item_text;
        this.listaDeVeiculos.push(this.veiculo);
      }
    } catch (error) {
      console.log(error);
    }
  }

  removerVeiculoSelecionado(item: any): void {
    try {
      let index = this.listaDeVeiculos.findIndex(v => v.id == item.item_id);
      this.listaDeVeiculos.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }

  doSearch() {
    try {
      this.filtro.listaDeVeiculos = this.listaDeVeiculos;
      this.reportService.getReportVelocidadeParaRelatorio(this.filtro).then(response => {
        let retorno = response.relatorioGrafico;
        let lista = retorno.listaRelatorioVelocidade;
        this.carregarGrafico(lista);
        this.showGrafico = true;

      });
    } catch (error) {
      console.error(error);
    }
  }

  carregarGrafico(lista) {
    try {
      am4core.useTheme(am4themes_animated);
      let chart = am4core.create("chartdivvelocidade", am4charts.XYChart);

      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      lista.forEach(vo => {
        this.createSeries(vo.placa, vo.placa, chart, vo);
      });

      chart.legend = new am4charts.Legend();
      chart.legend.position = "right";
      chart.legend.scrollable = true;
    
      chart.legend.markers.template.states.create("dimmed").properties.opacity = 0.3;
      chart.legend.labels.template.states.create("dimmed").properties.opacity = 0.3;
      
      chart.legend.itemContainers.template.events.on("over", function(event) {
        //this.processOver(event.target.dataItem.dataContext, chart);
      })
      
      chart.legend.itemContainers.template.events.on("out", function(event) {
        //processOut(event.target.dataItem.dataContext);
      })
        
      document.getElementById("button").addEventListener("click", function(){
       //this.processOver(chart.series.getIndex(3), chart);
      })
    } catch (error) {
      console.error(error);
    }
  }

createSeries(s, name, chart, vo) {
    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = s;
    series.dataFields.dateX = "date";
    series.name = name;
  
    let segment = series.segments.template;
    segment.interactionsEnabled = true;
  
    let hoverState = segment.states.create("hover");
    hoverState.properties.strokeWidth = 3;
  
    let dimmed = segment.states.create("dimmed");
    dimmed.properties.stroke = am4core.color("#dadada");
  
    segment.events.on("over", function(event) {
      this.processOver(event.target.parent.parent.parent);
    });
  
    segment.events.on("out", function(event) {
      this.processOut(event.target.parent.parent.parent);
    });
  
    let data = [];
    let dados = vo.listaVelocidadeGrafico;
    
    for (var i = 0; i < dados.length ; i++) {
      let value = dados[i].velocidade;
      let dataItem = { date: dados[i].dataDaTransmissao};
      dataItem[s] = value;
      data.push(dataItem);
    }
  
    series.data = data;
    return series;
  }

 processOut(chart) {
    chart.series.each(function(series) {
      series.segments.each(function(segment) {
        segment.setState("default");
      })
      series.bulletsContainer.setState("default");
      series.legendDataItem.marker.setState("default");
      series.legendDataItem.label.setState("default");
    });
  }


processOver(hoveredSeries, chart) {
    hoveredSeries.toFront();
  
    hoveredSeries.segments.each(function(segment) {
      segment.setState("hover");
    })
    
    hoveredSeries.legendDataItem.marker.setState("default");
    hoveredSeries.legendDataItem.label.setState("default");
  
    chart.series.each(function(series) {
      if (series != hoveredSeries) {
        series.segments.each(function(segment) {
          segment.setState("dimmed");
        })
        series.bulletsContainer.setState("dimmed");
        series.legendDataItem.marker.setState("dimmed");
        series.legendDataItem.label.setState("dimmed");
      }
    });
  }

}
