import { Injectable } from '@angular/core';
import { User } from '../../classes/user';
import { CRUDService } from '../base/crud.service';
import { Http } from '@angular/http';
import { AuthGuard } from '../../guards/auth.guard';
import { RESTResponseVO } from '../../classes/restresponsevo';
import { environment } from '../../../environments/environment';

@Injectable()
export class UserService extends CRUDService<User> {
    constructor(http: Http, public authGuard: AuthGuard) {
        super(http, environment.baseURL + 'App/v2/web/users', true);

        var loggedUser: User = authGuard.loggedUser();
        var company: number = loggedUser.selectedCompany.id;
        var branch: string = loggedUser.selectedBranch.id === 0 && '0' || loggedUser.selectedBranch.id.toString();

        this.listURL = environment.baseURL + 'App/v2/web/users/' + loggedUser.selectedCompany.id + '/' + branch;
        this.updateURL = environment.baseURL + 'App/v2/web/users/' + loggedUser.selectedCompany.id + '/' + branch;
        this.createURL = environment.baseURL + 'App/v2/web/users/create/' + loggedUser.selectedCompany.id + '/' + branch;
    }

    public resetPassword(id: number, oldPassword: string, newPassword: string): Promise<RESTResponseVO<string>> {
        var url: string = this.defaultUrl + '/password/' + id + '/' + oldPassword + '/' + newPassword;
        return this.http.post(url, {})
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<string>;
            })
            .catch(this.handleError);
    }

    public removeBranch(branch: any) {
        return this.http
            .delete(this.defaultUrl + '/branch/' + this.authGuard.loggedUser().id + '/' + branch.id)
            .toPromise()
            .then(res => {
                return res.json() as RESTResponseVO<string>;
            })
            .catch(this.handleError);
    }

    getPermissionsById(id:number) {
        const url = `${this.defaultUrl}/buildMenuWithPermissions/${id}`;
        return this.http
          .get(url)
          .toPromise()
          .then(response => {
            if (this.restResponseWrapper) {
              return response.json() as RESTResponseVO<string>;
            }
          })
          .catch(this.handleError);
      }

    public removeCompany(company: any) {
        return this.http
            .delete(this.defaultUrl + '/company/' + this.authGuard.loggedUser().id + '/' + company.id)
            .toPromise()
            .then(res => {
                return res.json() as RESTResponseVO<string>;
            })
            .catch(this.handleError);
    }

    public removeClaim(claim: any) {
        return this.http
            .delete(this.defaultUrl + '/claim/' + claim.id, { headers: this.headers })
            .toPromise()
            .then(res => {
                return res.json() as RESTResponseVO<string>;
            })
            .catch(this.handleError);
    }
}