import { Injectable } from '@angular/core';
import { RESTResponseVO } from '../../classes/restresponsevo';
import { Http } from '@angular/http';
import { AuthGuard } from '../../guards/auth.guard';
import { Event } from '../../classes/event';
import { environment } from '../../../environments/environment';
import { EventReportVO } from '../../classes/event-report-vo';
import { JornadaMotorista } from '../../classes/jornadaMotorista';

@Injectable()
export class SascarEventsService {
    constructor(private http: Http, public authGuard: AuthGuard) { }

    saveSascarEvent(file: FormData, driver: number, user: number){
        const url: string = environment.baseURL + 'App/v2/web/sascarupload/'  + driver + '/' + user ;
        return this.http
            .post(url, file)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<Event>;
            })
            .catch(this.handleError);
    }

    protected handleError(error: any): Promise<any> {
        console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
        return Promise.reject(error.message || error);
    }
}
