import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { BaseListComponent } from '../../../base/base-list/base-list.component';
import { BranchService } from '../../../../services/crud/branch.service';
import { UtilService } from '../../../../services/utils.service';
import { PDFService } from '../../../../services/pdf.service';
import { AuthGuard } from '../../../../guards/auth.guard';

@Component({
    templateUrl: '../../../base/base-list/base-list.component.html',
    styleUrls: ['../../../base/base-list/base-list.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class BranchListComponent extends BaseListComponent {

    constructor(private branchService: BranchService,
        private utils: UtilService,
        pdfService: PDFService,
        private authGuard: AuthGuard) {
        super(utils, pdfService, authGuard);
        this.searchField = "identificacao";
    }

    ngOnInit() {
        this.pageTitle = "Cadastros | Unidades";
        this.formPath = "/adm/branch/form";

        this.tableHeaders = [
            [
                this.createHeader("Unidade", 0, true),
                this.createHeader("Apelido", 1, true),
                this.createHeader("Logradouro", 2, true),
                this.createHeader("Número", 3, true),
                this.createHeader("Cidade", 4, true),
                this.createHeader("UF", 5, true),
                this.createHeader("Tratamento descarga", 6, true)
            ]
        ];

        this.dataFields = [
            this.createField("identificacao", "string"),
            this.createField("apelido", "string"),
            this.createField("endereco.logradouro", "string"),
            this.createField("endereco.numero", "string"),
            this.createField("endereco.cidade.nome", "string"),
            this.createField("endereco.cidade.ufCodIbge.sigla", "string"),
            this.createField("tempoEspera", "string")
        ];

        this.branchService.getList()
            .then(response => {
                const newData = response.response.map((d) => {
                    if (d.tempoEspera) {
                        d.tempoEspera = 'Sim';
                        return d;
                    }
                    d.tempoEspera = 'Não';
                    return d;
                })
                this.data = newData;
                this.loading = false;
            });
    }
}
