import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatDialog, MatSort, MatTableDataSource } from "@angular/material";
import { DetalheEficienciaVeiculo } from "app/classes/detalhe-eficiencia-veiculo";
import { FiltroOpcoesTelemetria } from "app/classes/filtro-opcoes-telemetria";
import { DetalhesEficienciaVeiculosComponent } from "../detalhes-eficiencia-veiculos/detalhes-eficiencia-veiculos";

@Component({
  selector: "quadro-veiculos-component",
  templateUrl: "./quadro-veiculos-component.html",
  styleUrls: ["./quadro-veiculos-component.scss"],
})
export class QuadroVeiculosComponent implements AfterViewInit, OnChanges {
  @Input()
  listaTopDez: Array<DetalheEficienciaVeiculo> = [];

  @Input()
  detalhesEficienciaVeiculo: Array<DetalheEficienciaVeiculo> = [];

  @Input()
  filtroFront: FiltroOpcoesTelemetria;

  @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource(this.listaTopDez);

  displayedColumns = ["placa", "kmRodado", "consumoEmLitros", "kmLitros"];

  constructor(public dialog: MatDialog) {}

  exibirTabela: Boolean = true;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.data = this.listaTopDez;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.listaTopDez && changes.listaTopDez.currentValue) {
      this.dataSource.data = changes.listaTopDez.currentValue;
    }
  }

  escodeOuExibeDiv() {
    this.exibirTabela = !this.exibirTabela;
  }

  exibirEficienciaVeiculos() {
    this.dialog.open(DetalhesEficienciaVeiculosComponent, {
      data: {
        detalhesEficienciaVeiculo: this.detalhesEficienciaVeiculo,
        filtro: this.filtroFront,
      },
      panelClass: "indicadores-dialog",
    });
  }
}
