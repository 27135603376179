import { BaseFormComponent } from "../../../base/base-form/base-form.component";
import { Shift } from "../../../../classes/shift";
import { RESTResponseVO } from "../../../../classes/restresponsevo";
import { ShiftService } from "../../../../services/crud/shift.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, ChangeDetectorRef, AfterViewInit } from "@angular/core";
import { Tolerance } from "../../../../classes/tolerance";
import { Observable } from "rxjs/Observable";
import { ToleranceService } from "../../../../services/crud/tolerance.service";
import { UtilService } from "../../../../services/utils.service";
import { AuthGuard } from "../../../../guards/auth.guard";
import { ContractualSchedule } from "app/classes/contractual-schedule";

@Component({
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css']
})
export class ShiftFormComponent extends BaseFormComponent<Shift> {
    constructor(private shiftService: ShiftService,
        private toleranceService: ToleranceService,
        route: ActivatedRoute,
        router: Router,
        private authGuard: AuthGuard,
        private utils: UtilService,
        private cdr: ChangeDetectorRef) {
        super(route, router, 'adm/shift/list');
    }

    private dailyTotals: string[];
    private toleranceList: Tolerance[];
    private monthlyHours: number[] = [180, 220];
    private daysPerWeek: number;
    private shiftTypes: { id: number, value: string }[] = [
        { id: 1, value: "Jornada Fixa" },
        { id: 2, value: "Jornada Flexível com Grade de Horário" },
        { id: 3, value: "Jornada Flexível com Limite Diário" }
    ];

    post = false;

    daysContractualCheck = {
        DOMINGO: false,
        SEGUNDA: false,
        TERCA: false,
        QUARTA: false,
        QUINTA: false,
        SEXTA: false,
        SABADO: false
    }

    dayOffProxy: any;
    weekTurnDayProxy: any;
    dayOffDSRProxy: any;

    dayOffFormatter = (x: any) => x.nome;

    days = [{ id: null, nome: 'Selecione' }, { id: 0, nome: "Domingo" }, { id: 1, nome: "Segunda-feira" }, { id: 2, nome: "Terça-feira" },
    { id: 3, nome: "Quarta-feira" }, { id: 4, nome: "Quinta-feira" }, { id: 5, nome: "Sexta-feira" }, { id: 6, nome: "Sabado" }];

    searchDayOff = (text$: Observable<string>) =>
        text$.debounceTime(200)
            .distinctUntilChanged()
            .map(term => term.length < 2 ? []
                : this.days.filter(d => d.nome.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10));

    assembleObjectFromForm(): void {
        if (this.dayOffProxy) {
            this.activeObject.diaDeFolga = this.dayOffProxy.id;
        }
        if (this.weekTurnDayProxy) {
            this.activeObject.weekTurnDay = this.weekTurnDayProxy.id;
        }
        if (this.dayOffDSRProxy) {
            this.activeObject.diaDeDsr = this.dayOffDSRProxy.id;
        }
    }

    loadAuxiliaryLists(): void {
        this.toleranceService.getList().then(response => {
            this.toleranceList = response.response
        });
    }

    loadObject(): void {
        if (this.activeID && (this.activeID !== 0)) {
            this.shiftService.getById(this.activeID).then(response => {
                var resultItem: Shift = response.response;

                if (resultItem.diaDeFolga !== undefined) {
                    this.dayOffProxy = this.days.filter(d => d.id === resultItem.diaDeFolga)[0];
                }
                if (resultItem.weekTurnDay !== undefined) {
                    this.weekTurnDayProxy = this.days.filter(d => d.id === resultItem.weekTurnDay)[0];
                }
                if (resultItem.diaDeDsr !== undefined) {
                    this.dayOffDSRProxy = this.days.filter(d => d.id === resultItem.diaDeDsr)[0];
                }

                
                this.activeObject = resultItem;
                const horariosContratuais =  Object.assign(resultItem.horariosContratuais, {})
                this.activeObject.horariosContratuais = this.initializeSchedule();
                if(horariosContratuais && horariosContratuais.length &&  horariosContratuais.length > 0) {
                    horariosContratuais.forEach(schedule => {
                        if(schedule && schedule.diaSemana && schedule.diaSemana != '') {
                            const index = this.activeObject.horariosContratuais.findIndex(c=> c.diaSemana == schedule.diaSemana);
                            if(index >= 0) {
                                const item = this.activeObject.horariosContratuais[index];

                                item.duracaoJornada = schedule.duracaoJornada;
                                item.horaPrimeiraEntrada = schedule.horaPrimeiraEntrada;
                                item.horaPrimeiraSaida = schedule.horaPrimeiraSaida;
                                item.horaSegundaEntrada = schedule.horaSegundaEntrada;
                                item.horaSegundaSaida = schedule.horaSegundaSaida;
                                item.id = schedule.id;

                                this.enableContractialSchedule(schedule.diaSemana, true);
                            }
                        }
                    })
                }
                this.dailyTotals = new Array();
                this.loading = false;
                this.activeObject.horasSemanais = this.utils.miliToHHmm(this.activeObject.horasSemanais);
            });
        } else {
            this.loading = false;
        }
    }

    initObject(): void {
        this.activeObject = new Shift();
        this.activeObject.empresaId = this.authGuard.loggedUser().selectedCompany.id;
        this.dailyTotals = new Array();
        this.activeObject.horariosContratuais = this.initializeSchedule();
        this.loading = false;
    }

    doCreate(t: Shift): Promise<RESTResponseVO<Shift>> {
        t.horasSemanais = this.utils.HHmmToMili(t.horasSemanais);

        return this.shiftService.create(this.tratarPayload(t));
    }

    doEdit(t: Shift): Promise<RESTResponseVO<Shift>> {
        t.horasSemanais = this.utils.HHmmToMili(t.horasSemanais);
        
        return this.shiftService.update(this.tratarPayload(t));
    }

    doDelete(t: Shift): Promise<RESTResponseVO<string>> {
        return this.shiftService.delete(t.id);
    }

    checkOverflow(value: string, weekly?: boolean): boolean {
        let limit;
        if (weekly) {
            limit = this.utils.HHmmToMili(this.activeObject.horasSemanais);
        } else {
            limit = this.utils.HHmmToMili(this.activeObject.acordoCompensacao ? "10:00" : "08:00");
        }

        return this.utils.HHmmToMili(value) > limit;
    }

    // Tolerance
    toleranceFormatter = (x: Tolerance) => x.descricao;

    searchTolerance = (text$: Observable<string>) =>
        text$
            .debounceTime(200)
            .distinctUntilChanged()
            .map(term => term.length < 2 ? [] : this.toleranceList.filter(v => v.descricao.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10));

    toleranceTemplate(r) {
        return r.descricao;
    }

    setActiveID(): void {
        this.activeID = this.activeObject.id;
    }

    initializeSchedule(): ContractualSchedule[] {

        this.daysContractualCheck = {
            DOMINGO: false,
            SEGUNDA: false,
            TERCA: false,
            QUARTA: false,
            QUINTA: false,
            SEXTA: false,
            SABADO: false
        }

        return [
            { id: 0, horaPrimeiraEntrada: null, horaPrimeiraSaida: null, horaSegundaEntrada : null, horaSegundaSaida: null, duracaoJornada: '0', diaSemana: 'DOMINGO'},
            { id: 0, horaPrimeiraEntrada: null, horaPrimeiraSaida: null, horaSegundaEntrada : null, horaSegundaSaida: null, duracaoJornada: '0', diaSemana: 'SEGUNDA'},
            { id: 0, horaPrimeiraEntrada: null, horaPrimeiraSaida: null, horaSegundaEntrada : null, horaSegundaSaida: null, duracaoJornada: '0', diaSemana: 'TERCA'},
            { id: 0, horaPrimeiraEntrada: null, horaPrimeiraSaida: null, horaSegundaEntrada : null, horaSegundaSaida: null, duracaoJornada: '0', diaSemana: 'QUARTA'},
            { id: 0, horaPrimeiraEntrada: null, horaPrimeiraSaida: null, horaSegundaEntrada : null, horaSegundaSaida: null, duracaoJornada: '0', diaSemana: 'QUINTA'},
            { id: 0, horaPrimeiraEntrada: null, horaPrimeiraSaida: null, horaSegundaEntrada : null, horaSegundaSaida: null, duracaoJornada: '0', diaSemana: 'SEXTA'},
            { id: 0, horaPrimeiraEntrada: null, horaPrimeiraSaida: null, horaSegundaEntrada : null, horaSegundaSaida: null, duracaoJornada: '0', diaSemana: 'SABADO'}
        ]
    }

    checkValue(event) {

        this.enableContractialSchedule(event.target.defaultValue, event.target.checked);
    }

    enableContractialSchedule(day: string, enable: boolean) {

        this.daysContractualCheck[day] = enable;

        if(!enable) {
            const item =  this.activeObject.horariosContratuais.find(
                c=> c.diaSemana === day);
            
                if(item) {
                    item.horaPrimeiraEntrada = null;
                    item.horaPrimeiraSaida = null;
                    item.horaSegundaEntrada = null;
                    item.horaSegundaSaida = null;
                    item.duracaoJornada = null;
                }
        } 
    }

    checkGravar(activeObject) {
        this.post = true;

        if(this.activeObject.horariosContratuais
            .some(element => this.contemErro(element.diaSemana))) return;

        this.btnSaveClick(activeObject);
    }

    tratarPayload(payload: Shift) {
        var objetoClonado = Object.assign({}, payload)
        objetoClonado.horariosContratuais = 
        objetoClonado.horariosContratuais.filter(c=> this.daysContractualCheck[c.diaSemana]);
        return objetoClonado;

    }

    contemErro(day: string) {
        const item =  this.activeObject.horariosContratuais.find(
            c=> c.diaSemana === day);
        if(item) {
            if(this.daysContractualCheck[day] && (!item.horaPrimeiraEntrada || 
                !item.horaPrimeiraSaida || !item.horaSegundaEntrada || !item.horaSegundaSaida || !item.duracaoJornada)) {
                return true;
            }
            return false;
        } else {
            return false
        }
    }
}