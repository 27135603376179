import { Company } from './company';
import { Coordenada } from './coordenada';
import { IDEntity } from './IDEntity';
import { Local } from './local';
export class Rota extends IDEntity {
    nome: String;
    ativo: boolean;
    localInicio: Local;
    localFinal: Local;
    idUnidade: number;
    cliente: Company;
    listaDeCoordenadas: Coordenada[];
}
