import { Injectable } from '@angular/core';
import { Headers, Http } from '@angular/http';

import 'rxjs/add/operator/toPromise';

import { User } from '../classes/user';
import { RESTResponseVO } from '../classes/restresponsevo';
import { environment } from '../../environments/environment';

@Injectable()
export class LoginService {
  
  private headers = new Headers({'Content-Type': 'application/json'});
  
  private loginUrl = environment.baseURL + 'App/v2/web/login/manual';  // URL to web api desenv
  // private loginUrl = './App/web/login/manual';  // URL to web api producao

  constructor(private http: Http) { }

  login(item: {userName: string, senha: string}): Promise<RESTResponseVO<User>> {
    return this.http
      .post(this.loginUrl, JSON.stringify(item), {headers: this.headers})
      .toPromise()
      .then(res => {
        let result;
        result = res.json() as RESTResponseVO<User>;

        return result;
      })
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
    return Promise.reject(error.message || error);
  }

}
