import { Component, OnInit, Compiler, ElementRef, ViewChild } from '@angular/core';
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { ReportService } from 'app/services/report/report.service';
import { Veiculo } from 'app/classes/veiculo';
import { Filtro } from 'app/classes/filtro';
import { Local } from 'app/classes/local';
import { LocalService } from 'app/services/crud/local.service';
import * as moment from 'moment';
import { InformacaoRelatorio } from 'app/classes/informacao-relatorio';

@Component({
  selector: 'app-tempo-referencia',
  templateUrl: './tempo-referencia.component.html',
  styleUrls: ['./tempo-referencia.component.css']
})
export class TempoReferenciaComponent implements OnInit {

  @ViewChild('iframe') iframe: ElementRef;
  dropdownList = [];
  dropdownListLocal = [];
  public selectedItems = [];
  public selectedItemsLocal = [];
  dropdownSettings: {};
  public filtro: Filtro;
  public pdfSrc: any;
  listaDeVeiculos: Veiculo[] = [];
  listaDeLocais:Local[] = [];
  veiculo: Veiculo;
  local:Local;
  listaInformacaoRelatorio: any[];
  showVeiculos: boolean = false;
  showBotaoPesquisa: boolean = false;
  public dataInicial:String;
  public dataFinal:String;

  @ViewChild('dataTable') table;
  dataTable: any;
  dtOption: any = {};

  constructor(
    private veiculoService: VeiculoService,
    private localService:LocalService,
    private reportService: ReportService,
    private _compiler: Compiler) {
    this._compiler.clearCache();
  }


  ngOnInit() {
    try {
      this.filtro = new Filtro();
      this.veiculoService.getLista().then(response => {
        this.localService.getLista().then(r=>{
          var data = response.listaDeVeiculos;
          var locais = r.listaDeLocal;
          this.dropdownList = [];
          this.dropdownListLocal = [];
          for (var i = 0; i < data.length; i++) {
            var veiculo = data[i];
            this.dropdownList.push({ item_id: veiculo.id, item_text: veiculo.placa });
          }

          for (var i = 0; i < locais.length; i++) {
            var local = locais[i];
            this.dropdownListLocal.push({ item_id: local.id, item_text: local.nome });
          }

          this.dropdownSettings = {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Todos',
            unSelectAllText: 'Desmarcar todos',
            itemsShowLimit: 3,
            allowSearchFilter: true,
            searchPlaceholderText: 'Pesquisar'
          };
          this.showVeiculos = true;
        })
      });

      this.dtOption = {
        "iDisplayLength": 17,
        "bPaginate": false,
        "fixedHeader": true,
        "bLengthChange": false,
        "bFilter": true,
        "bInfo": false,
        "bAutoWidth": false,
        "scrollY":  400,
        "deferRender": true,
        "scroller": true,
        "language": {
          "lengthMenu": "Mostrando _MENU_ registros por pagina",
          "zeroRecords": "Nenhum registro encontrado",
          "search": "Pesquisar",
          "oPaginate": {
            "sNext": "Próximo",
            "sPrevious": "Anterior",
            "sFirst": "Primeiro",
            "sLast": "Último"
          }
        },
        'dom': 'Bfrtip',
        'buttons': [
          'csv', 'excel'
        ]
      };
      this.dataTable = $(this.table.nativeElement);
      this.dataTable.DataTable(this.dtOption);

    } catch (error) {
      console.error(error);
    }

  }


  doSearch() {
    this.showBotaoPesquisa = true;
    try {
      this.filtro.dataInicialString = this.dataInicial;
      this.filtro.dataFinalString = this.dataFinal;
      this.filtro.listaDeVeiculos = this.listaDeVeiculos;
      this.filtro.listaDeLocais = this.listaDeLocais;
      this.reportService.getReportReferenciaLocal(this.filtro).then(response => {
        let informacaoRelatorio = response.listaInformacaoRelatorio;
        this.ajustarTabelaDeDetalhes(informacaoRelatorio);
        this.showBotaoPesquisa = false;
      });
    } catch (error) {
      console.error(error);
    }
  }

  onItemSelect(item: any) {
    this.inserirVeiculoSelecionado(item);
  }

  onItemSelectLocal(item: any) {
    this.inserirLocalSelecionado(item);
  }

  onSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirVeiculoSelecionado(items[i]);
    }
  }

  onSelectLocal(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirLocalSelecionado(items[i]);
    }
  }

  onDeSelect(item: any) {
    this.removerVeiculoSelecionado(item);
  }

  onDeSelectLocal(item: any) {
    this.removerLocalSelecionado(item);
  }

  inserirVeiculoSelecionado(item: any): void {
    try {
      var veiculo = this.listaDeVeiculos.find(v => v.id == item.item_id);
      if (!veiculo) {
        this.veiculo = new Veiculo();
        this.veiculo.id = item.item_id;
        this.veiculo.placa = item.item_text;
        this.listaDeVeiculos.push(this.veiculo);
      }
    } catch (error) {
      console.log(error);
    }
  }

  inserirLocalSelecionado(item: any): void {
    try {
      var local = this.listaDeLocais.find(l => l.id == item.item_id);
      if (!local) {
        this.local = new Local();
        this.local.id = item.item_id;
        this.local.nome = item.item_text;
        this.listaDeLocais.push(this.local);
      }
    } catch (error) {
      console.log(error);
    }
  }
  removerVeiculoSelecionado(item: any): void {
    try {
      let index = this.listaDeVeiculos.findIndex(v => v.id == item.item_id);
      this.listaDeVeiculos.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }

  removerLocalSelecionado(item: any): void {
    try {
      let index = this.listaDeLocais.findIndex(v => v.id == item.item_id);
      this.listaDeLocais.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }

  ajustarTabelaDeDetalhes(informacaoRelatorio: InformacaoRelatorio[]){
    try {
      this.dataTable.DataTable().clear().draw();
      informacaoRelatorio.forEach(i1=>{
          if(i1.listaInformacaoRelatorio){
            i1.listaInformacaoRelatorio.forEach(i2=>{
              let veiculo = i2.campo01;
              let local = i2.campo02;
              let entrada = i2.campo03;
              let saida = i2.campo04;
              let permanencia = i2.campo05;
              let motorista = i2.campo06;
              this.dataTable.DataTable().row.add([veiculo, local, entrada, saida, permanencia, motorista]);
            });
          }
      });
      this.dataTable.DataTable().draw();
    } catch (error) {
      console.log(error);
    }
  }

}
