import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

export interface KeyValue {
  key: string;
  value: any;
}

@Component({
  selector: 'app-title-page',
  templateUrl: './title-page.component.html',
  styleUrls: ['./title-page.component.css']
})
export class TitlePageComponent implements OnInit {

  @Input() tituloPage: string;
  @Input() keyValueList: KeyValue[] = [];

  constructor(private router: Router) { }

  ngOnInit() {
  }

}
