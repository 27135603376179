import { Component, OnInit } from '@angular/core';

import { LoginService } from '../../services/login.service';
import { User } from '../../classes/user';
import { RESTResponseVO } from '../../classes/restresponsevo';
import { Router } from '@angular/router';
import { Compiler } from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  onError: boolean;
  res: RESTResponseVO<User>;
  usuario = new User();
  errorMessage = '';
  isLoading = false;

  constructor(private loginService: LoginService, 
              private router: Router, 
              private _compiler: Compiler,
              private snackBar: MatSnackBar) {
    this._compiler.clearCache();
  }

  ngOnInit() {
    this.onError = false;
    localStorage.removeItem('currentUser'); 
  }

  onBtnEntrarClick() {
    this.onError = false;
    this.isLoading = true;
    this.loginService.login({ userName: this.usuario.userName, senha: this.usuario.password })
      .then(res => {
        this.isLoading = false;
        this.res = res;
        if(!res.response || !res.response.id) {
          this.openSnackBar('Usuário ou senha incorreto', 'fechar');
        } else {
          localStorage.setItem('currentUser', JSON.stringify(res.response));
          this.router.navigate(['context-picker'], { skipLocationChange: true });
        }
          
        
        console.error.apply(console);
      }).catch((res) => {
        this.isLoading = false;
      });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }
}
