export class DetalheTelemetria {

    start: String;
    end: String;
    color: String;
    duracao: String;
    velocidade: String;
    ignicao: String;
    hodometroInicio: String;
    hodometroFim: String;
    latitude: number;
    longitude: number;
    nomeEvento:String;
    resumo:String;
    endereco:String;
}
