import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { EquipamentoPortatilService } from 'app/services/crud/equipamento-portatil.service';
import { Filtro } from 'app/classes/filtro';
import { ReportService } from 'app/services/report/report.service';
import { EquipamentoPortatil } from 'app/classes/equipamento-portatil';

@Component({
  selector: 'app-dispositivoportatil',
  templateUrl: './dispositivoportatil.component.html',
  styleUrls: ['./dispositivoportatil.component.css']
})
export class DispositivoportatilComponent implements OnInit {

  @ViewChild('iframe') iframe: ElementRef;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: {};
  public filtro: Filtro;
  public mostrarPdf: boolean = false;
  public pdfSrc: any;
  listaDeEquipamentoPortatil: EquipamentoPortatil[] = [];
  equipamentoPortatil: EquipamentoPortatil;
  responseBase64: String;
  tipoDeIgnicao:number = 0;

  constructor(private equipamentoPortatilService: EquipamentoPortatilService, private reportService: ReportService) { }

  ngOnInit() {
    try {
      this.filtro = new Filtro();
      this.equipamentoPortatilService.getLista().then(response => {
        var data = response.listaEquipamentoPortatil;
        this.dropdownList = [];
        for (var i = 0; i < data.length; i++) {
          var equipamentoPortatil = data[i];
          this.dropdownList.push({ item_id: equipamentoPortatil.id, item_text: equipamentoPortatil.apelido });
        }


        this.dropdownSettings = {
          singleSelection: true,
          idField: 'item_id',
          textField: 'item_text',
          selectAllText: 'Todos',
          unSelectAllText: 'Desmarcar todos',
          itemsShowLimit: 3,
          allowSearchFilter: true,
          searchPlaceholderText: 'Pesquisar'
        };
      });

    } catch (error) {
      console.error(error);
    }
  }


  onItemSelect(item: any) {
    this.inserirEquipamentoPortatilSelecionado(item);
  }
  onSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirEquipamentoPortatilSelecionado(items[i]);
    }
  }
  onDeSelect(item: any) {
    this.removerEquipamentoPortatilSelecionado(item);
  }

  inserirEquipamentoPortatilSelecionado(item: any): void {
    try {
      var equipamentoPortatil = this.listaDeEquipamentoPortatil.find(v => v.id == item.item_id);
      if (!equipamentoPortatil) {
        this.equipamentoPortatil = new EquipamentoPortatil();
        this.equipamentoPortatil.id = item.item_id;
        this.equipamentoPortatil.apelido = item.item_text;
        this.listaDeEquipamentoPortatil.push(this.equipamentoPortatil);
      }
    } catch (error) {
      console.log(error);
    }
  }

  removerEquipamentoPortatilSelecionado(item: any): void {
    try {
      let index = this.listaDeEquipamentoPortatil.findIndex(v => v.id == item.item_id);
      this.listaDeEquipamentoPortatil.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }

  doSearch() {
    try {
      this.filtro.equipamentoPortatil  = this.listaDeEquipamentoPortatil[0];
      if(this.tipoDeIgnicao){
        if(this.tipoDeIgnicao == 1){
          this.filtro.ignicaoAtiva = true;
        }
        if(this.tipoDeIgnicao == 2){
          this.filtro.ignicaoAtiva = false;
        }
      }
      this.reportService.getReportEquipamentoPortatil(this.filtro).then(response => {
        this.iframe.nativeElement.setAttribute('src', response);
      });
    } catch (error) {
      console.error(error);
    }
  }

}
