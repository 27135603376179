import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Portaria671 } from "app/classes/portaria-671";
import { PortariaService } from "app/services/crud/portaria.service";
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
    
})

export class Portaria671FormComponent implements OnInit {

    activeObject: Portaria671;
    formPortaria: FormGroup;
    stateName: string = 'Incluir'
    loading = false;
    action: string = 'Salvar'
    id: number;
    errorMessage: string;
    desabilitar = false;
    isSubmited = false;
    
    constructor(private portariaService: PortariaService,
        private fb: FormBuilder,
        private router: Router,
        private snackBar: MatSnackBar) {
        this.initObject();
        this.formPortaria = this.fb.group({
            tipoIdentificador: [1, Validators.required],
            status: [null, Validators.required],
            email: [null, Validators.required],
            cpfCnpj: [null, Validators.required],
            razaoSocial: [null, Validators.required],
            inpi: [null, Validators.required],
            nomePtrp: [null, Validators.required],
            versaoPtrp: [null, Validators.required],
            versaoLayoutAej: [null, Validators.required],
            versaoLayoutAfd: [null, Validators.required],
        })
     }


    ngOnInit(): void {
        this.loading = true;
        this.loadObject();
    }
    
    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {duration: 2000});
      }

    loadObject(): void {
        this.portariaService.getList().then((response: any) => {
            this.loading = false;
            if( response && response.id > 0) {
                this.formPortaria.setValue({
                    tipoIdentificador: response.tipoIdentificador,
                    status: response.status,
                    cpfCnpj: response.tipoIdentificador === 1 ? response.cnpj: response.cpf,
                    razaoSocial: response.razaoSocial,
                    inpi: response.inpi,
                    nomePtrp: response.nomePtrp,
                    versaoPtrp: response.versaoPtrp,
                    versaoLayoutAej: response.versaoLayoutAej,
                    versaoLayoutAfd: response.versaoLayoutAfd,
                    email: response.email
                    });

                    this.id = response.id;

                    this.stateName = 'Editar';
                    this.action = 'Editar';
                    this.mensagemSucesso();
            }
      
      
        }).catch(d=> {this.loading = false; this.errorMessage = d})
    }

    initObject(): void {
        this.activeObject = new Portaria671();
    }

    submit() {

        this.isSubmited = true;

        if(this.formPortaria.valid) {

            this.activeObject = this.formPortaria.value as Portaria671;

            if(this.activeObject.tipoIdentificador == 1) {
                this.activeObject.cnpj = this.formPortaria.get('cpfCnpj').value;
                this.activeObject.cpf = null;
            } else {
                this.activeObject.cpf = this.formPortaria.get('cpfCnpj').value;
                this.activeObject.cnpj = null;
            }

            if(this.id) {
                this.activeObject.id = this.id;
                this.portariaService.update(this.activeObject).then(response => {
                    this.desabilitar = false;
                    this.mensagemSucesso();
                }).catch(reason => {
                    this.errorMessage = reason;
                });
            } else {
                this.doCreate(this.activeObject);
            }
        }
    }

    doCreate(t: Portaria671) {
        try {
            this.loading = true;
            return this.portariaService.create(t).then(c=> {
                this.loading = false;
                this.loadObject();
            }).catch(e=> {this.loading = false;});
          } catch (error) {
            console.error(error);
            this.loading = false;
            this.mensagemErro();
          }
    }

    getMascaraDocumento() {
        return this.formPortaria.get('tipoIdentificador').value == 2 ? '000.000.000-00' : '00.000.000/0000-00'
    }

    cancel() {
        this.router.navigate(['adm/main/'])
    }

    mensagemSucesso() {
        this.openSnackBar('Salvo com sucesso!', 'fechar')
    }

    mensagemErro() {
        this.openSnackBar('Erro ao salvar!', 'fechar')
    }

}
