import { Component, OnInit } from '@angular/core';

import { BaseListComponent } from '../../../base/base-list/base-list.component';
import { UtilService } from '../../../../services/utils.service';
import { PDFService } from '../../../../services/pdf.service';
import { ColorVOService } from '../../../../services/crud/color-vo.service';
import { ColorVO } from '../../../../classes/color-vo';
import { AuthGuard } from '../../../../guards/auth.guard';

@Component({
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.css']
})
export class ColorListComponent extends BaseListComponent {
    constructor(private colorService: ColorVOService,
        private utils: UtilService,
        pdfService: PDFService,
        authGuard: AuthGuard) {
        super(utils, pdfService, authGuard);
        this.searchField = "";
    }

    searchObject: { type: string, name: string } = { type: undefined, name: undefined };
    typeList: { name: string, value: string }[] = [
        { name: 'Estados da Jornada', value: 'estados_jornada' },
        { name: 'Eventos da Jornada', value: 'eventos_jornada' },
        { name: 'Tipos de Estados da Jornada', value: 'tipos_estados' }
    ];

    ngOnInit() {
        this.pageTitle = "Cadastros | Cores";

        this.tableHeaders = [
            [
                this.createHeader("Tipo", 0, true),
                this.createHeader("Nome", 1, true)
            ]
        ];

        this.dataFields = [
            this.createField("tipo", "string"),
            this.createField("nome", "string")
        ];

        this.loading = false;
    }

    toggleEdit(row) {
        row.editing = !row.editing;
    }

    update(row: ColorVO) {
        this.loading = true;
        this.errorMessage = undefined;

        if (!row.cor) {
            this.errorMessage = "Campo Cor não pode ser vazio!";
            return;
        }

        delete row.editing;

        this.colorService.update(row).then(response => {
            this.errorMessage = response.error;
            if (!this.errorMessage) {
                this.doSearch();
            }
        }).catch(reason => {
            this.errorMessage = reason;
        });
    }

    reset(row: ColorVO) {
        this.loading = true;
        this.errorMessage = undefined;

        this.colorService.delete(row.tipoID, row.id).then(response => {
            this.errorMessage = response.error;
            if (!this.errorMessage) {
                this.doSearch();
            }
        }).catch(reason => {
            this.errorMessage = reason;
        });
    }

    doSearch() {
        this.loading = true;

        if (!this.searchObject.type) {
            this.searchObject.type = "*";
        }
        if (!this.searchObject.name) {
            this.searchObject.name = "*";
        }

        this.errorMessage = undefined;

        this.colorService.getList(this.searchObject.type, this.searchObject.name).then(response => {
            this.errorMessage = response.error;
            if (!this.errorMessage) {
                this.data = response.response.map(d => {
                    d.editing = false;
                    return d;
                });
            }
            this.loading = false;
        }).catch(reason => {
            this.errorMessage = reason;
            this.loading = false;
        });
    }
}
