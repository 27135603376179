import { IDEntity } from "./IDEntity";
import { Company } from "./company";

export class Tolerance extends IDEntity {
    descricao: string;
    atrasoEntrada: Number;
    atrasoSaida: Number;
    horaExtraEntrada: Number;
    horaExtraSaida: Number;
    atrasoAlmoco: Number;
    horaExtraAlmoco: Number;
    totalAtrasoDiario: Number;
    totalHoraExtraDiario: Number;
    empresaId: Number;
}