import { IDEntity } from "./IDEntity";
import { Company } from "./company";
import { ContractualSchedule } from "./contractual-schedule";
import { Tolerance } from "./tolerance";

export class Shift extends IDEntity {
    tipo: number;
    horarioEntrada01: number;
    horarioEntrada02: number;
    horarioEntrada03: number;
    horarioEntrada04: number;
    horarioEntrada05: number;
    horarioEntrada06: number;
    horarioEntrada07: number;
    horarioSaida01: number;
    horarioSaida02: number;
    horarioSaida03: number;
    horarioSaida04: number;
    horarioSaida05: number;
    horarioSaida06: number;
    horarioSaida07: number;
    tempoDiario01: number;
    tempoDiario02: number;
    tempoDiario03: number;
    tempoDiario04: number;
    tempoDiario05: number;
    tempoDiario06: number;
    tempoDiario07: number;
    tempoAlimentacao01: any;
    tempoAlimentacao02: number;
    tempoAlimentacao03: number;
    tempoAlimentacao04: number;
    tempoAlimentacao05: number;
    tempoAlimentacao06: number;
    tempoAlimentacao07: number;
    identificacao: string;
    horasMensais: number;
    horasSemanais: any;
    acordoCompensacao: boolean;
    empresaId: Number;
    toleranciasId: Tolerance;
    diaDeFolga: number;
    weekTurnDay: number;
    weekDays: number;
    seg50Porcento: boolean;
    ter50Porcento: boolean;
    qua50Porcento: boolean;
    qui50Porcento: boolean;
    sex50Porcento: boolean;
    sab50Porcento: boolean;
    dom50Porcento: boolean;
    seg100Porcento: boolean;
    ter100Porcento: boolean;
    qua100Porcento: boolean;
    qui100Porcento: boolean;
    sex100Porcento: boolean;
    sab100Porcento: boolean;
    dom100Porcento: boolean;
    diaDeDsr: number;
    tempoJornadaExcedida:number;
    tempoMinimoInterjornada:number;
    tempoInterjornada:number;
    horariosContratuais: ContractualSchedule[];
    dsrAutomatica: boolean;
}