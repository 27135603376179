import { WorkBook, WorkSheet, utils } from 'xlsx';
import { DatePipe } from '@angular/common';
import { isNullOrUndefined } from 'util';
import { JornadaMotorista } from '../../../classes/jornadaMotorista';
import { EventoPeriodo } from '../../../classes/eventoPeriodo';
import * as moment from 'moment';

const sheetColumns: Array<String> = 'A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z,AA,AB,AC,AD,AE,AF,AG,AH,AI,AJ,AK,AL,AM,AN,AO,AP,AQ,AR,AS,AT,AU,AV'.split(',');

export default class EventsReportWorkbook {
    private pipe: DatePipe = new DatePipe('pt-BR');

    private old(data: Array<JornadaMotorista>, isAnalitic: boolean): WorkBook {
        let rowsCount = 1;
        const ws: WorkSheet = {
            '!merges': []
        };

        data.forEach((jm: JornadaMotorista) => {
            const sortedPeriods: Array<string> = [];
            const types: Array<String> = [];

            for (var key in jm.resumo) {
                var value = jm.resumo[key];
                sortedPeriods.push(key);

                value.forEach((event: EventoPeriodo) => {
                    if (!types.includes(event.tipo)) {
                        types.push(event.tipo);
                    }
                });
            }

            sortedPeriods.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

            ws[`A${rowsCount}`] = { t: 's', v: `Motorista: ${jm.nomeMotorista}` };
            rowsCount++;

            ws[`A${rowsCount}`] = { t: 's', v: `Empresa: ${jm.nomeEmpresa}` };
            rowsCount++;

            ws[`A${rowsCount}`] = { t: 's', v: `Unidade: ${jm.nomeUnidade}` };
            rowsCount++;

            if (isAnalitic) {
                ws[`A${rowsCount}`] = { t: 's', v: 'DATA' };
                ws['!merges'].push({
                    s: { r: rowsCount - 1, c: 0 },
                    e: { r: rowsCount, c: 0 }
                });
            } else {
                ws[`A${rowsCount}`] = { t: 's', v: 'EVENTO' };
            }

            this.createHeader(ws, types, rowsCount, isAnalitic);
            rowsCount += 2;

            if (isAnalitic) {
                this.addData(sortedPeriods, ws, jm.resumo, types, rowsCount);
                rowsCount += sortedPeriods.length;
            }

            rowsCount++;
            this.getTotal(ws, types, jm.resumo, rowsCount);

            rowsCount += 2;
        });

        ws['!ref'] = `A1:AV${rowsCount + 3}`;

        /* add to workbook */
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Relatório');

        return wb;
    }

    public mount(data: Array<JornadaMotorista>, type: string): WorkBook {
        if (type === "N") {
            return this.newMount(data);
        } else {
            return this.old(data, type === "A");
        }
    }

    public newMount(data: Array<JornadaMotorista>): WorkBook {
        const ws: WorkSheet = {
            '!merges': []
        };

        let col = 0, row = 1;

        let dataModel: { header: string, type: string, collection: EventoPeriodo[] }[] = [
            { header: "Inicio Jornada", type: "Inicio Jornada", collection: undefined },
            { header: "Inicio Direção", type: "Inicio Direção", collection: undefined },
            { header: "Fim Direção", type: "Fim de Direção", collection: undefined },
            { header: "Inicio Aguardando Carga", type: "Inicio Aguardando Carga", collection: undefined },
            { header: "Fim Aguardando Carga", type: "Fim Aguardando Carga", collection: undefined },
            { header: "Inicio Carga", type: "Inicio Carga", collection: undefined },
            { header: "Fim Carga", type: "Fim Carga", collection: undefined },
            { header: "Inicio Aguardando Descarga", type: "Inicio Aguardando Descarga", collection: undefined },
            { header: "Fim Aguardando Descarga", type: "Fim Aguardando Descarga", collection: undefined },
            { header: "Inicio Descarga", type: "Inicio Descarga", collection: undefined },
            { header: "Fim Descarga", type: "Fim Descarga", collection: undefined },
            { header: "Inicio Refeição", type: "Inicio Refeição", collection: undefined },
            { header: "Fim Refeição", type: "Fim Refeição", collection: undefined },
            { header: "Inicio Abastecimento", type: "Inicio Abastecimento", collection: undefined },
            { header: "Fim Abastecimento", type: "Fim de Abastecimento", collection: undefined },
            { header: "Inicio Lanche", type: "Inicio Lanche", collection: undefined },
            { header: "Fim Lanche", type: "Fim de Lanche", collection: undefined },
            { header: "Inicio Banheiro", type: "Inicio Banheiro", collection: undefined },
            { header: "Fim Banheiro", type: "Fim de Banheiro", collection: undefined },
            { header: "Inicio Fiscalização", type: "Inicio Fiscalização", collection: undefined },
            { header: "Fim Fiscalização", type: "Fim Fiscalização", collection: undefined },
            { header: "Inicio Repouso Direção", type: "Inicio Repouso Direção", collection: undefined },
            { header: "Fim Repouso Direção", type: "Fim Repouso Direção", collection: undefined },
            { header: "Inicio Manutenção", type: "Inicio Manutenção", collection: undefined },
            { header: "Fim Manutenção", type: "Fim Manutenção", collection: undefined },
            { header: "Inicio Sinistro", type: "Inicio Sinistro", collection: undefined },
            { header: "Fim Sinistro", type: "Fim de Sinistro", collection: undefined },
            { header: "Inicio Pista Interditada", type: "Inicio Pista Interditada", collection: undefined },
            { header: "Fim Pista Interditada", type: "Fim Pista Interditada", collection: undefined },
            { header: "Inicio Intervalo Pessoal", type: "Inicio Intervalo Pessoal", collection: undefined },
            { header: "Fim Intervalo Pessoal", type: "Fim Intervalo Pessoal", collection: undefined },
            { header: "Fim de Jornada", type: "Fim de Jornada", collection: undefined }
        ];

        ws[`${sheetColumns[col++]}${row}`] = { t: 's', v: 'Ano' };
        ws[`${sheetColumns[col++]}${row}`] = { t: 's', v: 'Mês' };
        ws[`${sheetColumns[col++]}${row}`] = { t: 's', v: 'Nome Empresa' };
        ws[`${sheetColumns[col++]}${row}`] = { t: 's', v: 'Unidade/Filial' };
        ws[`${sheetColumns[col++]}${row}`] = { t: 's', v: 'Data da Jornada de Trabalho' };
        ws[`${sheetColumns[col++]}${row}`] = { t: 's', v: 'Nome Completo Motorista' };

        dataModel.forEach(dt => {
            ws[`${sheetColumns[col++]}${row}`] = { t: 's', v: dt.header };
        });

        ws[`${sheetColumns[col++]}${row}`] = { t: 's', v: 'Horário de Duração da Jornada de Trabalho' };
        ws[`${sheetColumns[col++]}${row}`] = { t: 's', v: 'Nº MDFE' };

        data.forEach(j => {
            var keys: any[] = [];

            for (var d in j.resumo) {
                keys.push(d);
            }

            keys = keys.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

            keys.forEach((d) => {
                var r: EventoPeriodo[] = j.resumo[d];

                dataModel.forEach(dt => {
                    dt.collection = r.filter(periodo => periodo.tipo === dt.type);
                });

                let max = Math.max(...dataModel.map(dt => dt.collection.length));

                var inicioJornada = r.filter(periodo => periodo.tipo === "Inicio Jornada")[0];
                var fimJornada = r.filter(periodo => periodo.tipo === "Fim de Jornada")[0];

                row++;

                for (var x = 0; x < max; x++) {
                    col = 0;

                    var lineDate = new Date(d);
                    ws[`${sheetColumns[col++]}${x + row}`] = { t: 's', v: moment(lineDate).year() };
                    ws[`${sheetColumns[col++]}${x + row}`] = { t: 's', v: moment(lineDate).format('MMMM') };
                    ws[`${sheetColumns[col++]}${x + row}`] = { t: 's', v: j.nomeEmpresa };
                    ws[`${sheetColumns[col++]}${x + row}`] = { t: 's', v: j.nomeUnidade };
                    ws[`${sheetColumns[col++]}${x + row}`] = { t: 's', v: moment(lineDate).format('DD/MM/YYYY') };
                    ws[`${sheetColumns[col++]}${x + row}`] = { t: 's', v: j.nomeMotorista };
                }

                dataModel.forEach(dt => {
                    this.createLine(ws, dt.collection, col, row);
                    col++;
                });

                if (fimJornada && inicioJornada) {
                    ws[`${sheetColumns[col]}${row}`] = { t: 's', v: moment(moment(new Date(fimJornada.dataInicio)).diff(new Date(inicioJornada.dataInicio))).format("HH:mm") };
                }
                
                row += max - 1;
            });
        });

        ws['!ref'] = `A1:AV${row}`;

        /* add to workbook */
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Relatório de Eventos');

        return wb;
    }

    private createLine(ws, data, col, row) {
        var initialRow = row;
        data.forEach(evt => {
            ws[`${sheetColumns[col]}${initialRow++}`] = { t: 's', v: moment(new Date(evt.dataInicio)).format("HH:mm") };
        });
    }

    private createHeader(ws: WorkSheet, types: Array<String>, row: number, isAnalitic: boolean) {
        types.forEach((type, i) => {
            const col = i * 2 + 1;
            ws[`${sheetColumns[col]}${row}`] = { t: 's', v: type };

            if (isAnalitic) {
                ws[`${sheetColumns[col]}${row + 1}`] = { t: 's', v: 'Início' };
                ws[`${sheetColumns[col + 1]}${row + 1}`] = { t: 's', v: 'Fim' };
            }

            // Types take 2 columns
            ws['!merges'].push({
                s: { r: row - 1, c: col },
                e: { r: row - 1, c: col + 1 }
            });
        });
    }

    private addData(periods: Array<string>, worksheet: WorkSheet, list: Map<Date, Array<EventoPeriodo>>, types: Array<String>, row: number) {
        periods.forEach((period) => {
            worksheet[`A${row}`] = { t: 's', v: this.pipe.transform(period, 'dd/MM/yyyy') };

            list[period].forEach((evento: EventoPeriodo) => {
                let col = 0;

                types.forEach((type, k) => {
                    if (evento.tipo === type) {
                        col = k * 2 + 1;
                    }
                });

                if (isNullOrUndefined(evento.dataInicio) || isNullOrUndefined(evento.dataFim)) {
                    return;
                }

                const datei = new Date(evento.dataInicio);
                const datef = new Date(evento.dataFim);

                worksheet[`${sheetColumns[col]}${row}`] = { t: 's', v: this.pipe.transform(datei, 'HH:mm') };

                worksheet[`${sheetColumns[col + 1]}${row}`] = { t: 's', v: this.pipe.transform(datef, 'HH:mm') };
            });

            row++;
        });
    }

    private getTotal(ws: WorkSheet, types: Array<String>, list: Map<Date, Array<EventoPeriodo>>, row: number) {
        ws[`A${row}`] = { t: 's', v: 'TOTAL (H:mm)' };

        const typesTotal: Array<number> = this.getTotalList(list, types);

        types.forEach((type, i) => {
            const col = i * 2 + 1;
            ws[`${sheetColumns[col]}${row}`] = {
                t: 's',
                v: `${Math.floor(typesTotal[i] / 60)}:${Math.floor(((typesTotal[i] / 60) % 1) * 60)}`
            };

            ws['!merges'].push({
                s: { r: row - 1, c: col },
                e: { r: row - 1, c: col + 1 }
            });
        });
    }

    private getTotalList(list: Map<Date, Array<EventoPeriodo>>, types: Array<String>): Array<number> {
        const typesTotal: Array<number> = [...Array(types.length).fill(0.0)];

        for (var key in list) {
            let value = list[key];

            types.forEach((type, i) => {
                value.forEach((event: EventoPeriodo) => {
                    if (type === event.tipo) {
                        if (event.dataInicio === null || event.dataFim === null) {
                            return;
                        }

                        const di = new Date(event.dataInicio);
                        const df = new Date(event.dataFim);
                        const timeDiff = Math.abs(df.getTime() - di.getTime());
                        const minDiff = timeDiff / (1000 * 60);
                        typesTotal[i] = (typesTotal[i] as number) + minDiff;
                    }
                });
            });
        }

        return typesTotal;
    }
}
