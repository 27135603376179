import { Http } from "@angular/http";
import { RESTResponseVO } from "../../classes/restresponsevo";
import { environment } from "../../../environments/environment";
import { ColorVO } from "../../classes/color-vo";
import { Injectable } from "@angular/core";

@Injectable()
export class ColorVOService {
    constructor(protected http: Http) { }

    getList(tipo: string, nome: string): Promise<RESTResponseVO<ColorVO[]>> {
        var url: string = environment.baseURL + 'App/v2/web/cor/' + tipo + '/' + nome;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<ColorVO[]>;
            })
            .catch(this.handleError);
    }

    delete(tipo: string, id: Number): Promise<RESTResponseVO<boolean>> {
        var url = environment.baseURL + 'App/v2/web/cor/' + tipo + '/' + id;
        return this.http.delete(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<boolean>;
            })
            .catch(this.handleError);
    }

    update(cor: ColorVO): Promise<RESTResponseVO<ColorVO>> {
        var url: string = environment.baseURL + 'App/v2/web/cor/';
        return this.http.put(url, cor)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<ColorVO>;
            })
            .catch(this.handleError);
    }

    protected handleError(error: any): Promise<any> {
        console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
        return Promise.reject(error.message || error);
    }
}