import { BaseFormComponent } from "../../../base/base-form/base-form.component";
import { RESTResponseVO } from "../../../../classes/restresponsevo";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, ChangeDetectorRef } from "@angular/core";
import { Tolerance } from "../../../../classes/tolerance";
import { Observable } from "rxjs/Observable";
import { ToleranceService } from "../../../../services/crud/tolerance.service";
import { UtilService } from "../../../../services/utils.service";
import { AuthGuard } from "../../../../guards/auth.guard";
import { TurnoEscala } from "app/classes/turno-escala";
import { TurnoEscalaService } from "app/services/crud/turno-escala.service";
import { User } from "app/classes/user";
import { Branch } from "app/classes/branch";
import { BranchService } from "app/services/crud/branch.service";
import { CalendarioTurnoEscala } from "app/classes/calendario-turno-escala";

@Component({
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class TurnoEscalaFormComponent extends BaseFormComponent<TurnoEscala> {
  branchList: Branch[];
  private loggedUser: User;
  private company: number;
  private branch: string;
  private listaCalendarioTurnoEscala:CalendarioTurnoEscala[] = [];
  constructor(private turnoEscalaService: TurnoEscalaService,
    private toleranceService: ToleranceService,
    private branchService: BranchService,
    route: ActivatedRoute,
    router: Router,
    private authGuard: AuthGuard,
    private utils: UtilService,
    private cdr: ChangeDetectorRef) {
    super(route, router, 'adm/turno-escala/list');
    this.loggedUser = authGuard.loggedUser();
    this.company = this.loggedUser.selectedCompany.id;
    this.branch = this.loggedUser.selectedBranch.id === 0 && '0' || this.loggedUser.selectedBranch.id.toString();
  }

  private dailyTotals: string[];
  private toleranceList: Tolerance[];
  private monthlyHours: number[] = [180, 220];
  private diasTrabalhados: number[] = [1, 2, 3, 4, 5, 6];
  private listaDeTipoDeDias: String[] = ["Jornada", "Folga", "DSR"];
  private listaTipoEscala: String[] = ["Escala Fixa", "Escala Flexivel"];
  private listaDeHorasDiaria: String[] = ["7:20", "8:00", "8:48"];
  private listaDeHorasAlmoco: String[] = ["1:00", "1:12"];
  private listaDeDias: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 24, 25, 26, 27, 28, 29, 30, 31];
  private listaDeMeses: String[] = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
  private listaDeAnos: number[] = [2021, 2022, 2023, 2024, 2025];


  private inicioEscalaDia: number;
  private inicioEscalaMes: String;
  private inicioEscalaAno: number;
  private fimEscalaDia: number;
  private fimEscalaMes: String;
  private fimEscalaAno: number;


  private daysPerWeek: number;

  private shiftTypes: { id: number, value: string }[] = [
    { id: 1, value: "Jornada Fixa" },
    { id: 2, value: "Jornada Flexível com Grade de Horário" },
    { id: 3, value: "Jornada Flexível com Limite Diário" }
  ];

  dayOffProxy: any;
  weekTurnDayProxy: any;
  colortd: String[] = [];
  valoresDiaCalendario: String[] = [];
  tipoEscala: String;
  desahabilitarHorasDiaria: boolean = true;
  desahabilitarHorasFixa: boolean = true;

  dayOffFormatter = (x: any) => x.nome;

  days = [{ id: 0, nome: "Domingo" }, { id: 1, nome: "Segunda-feira" }, { id: 2, nome: "Terça-feira" },
  { id: 3, nome: "Quarta-feira" }, { id: 4, nome: "Quinta-feira" }, { id: 5, nome: "Sexta-feira" }, { id: 6, nome: "Sabado" }];

  searchDayOff = (text$: Observable<string>) =>
    text$.debounceTime(200)
      .distinctUntilChanged()
      .map(term => term.length < 2 ? []
        : this.days.filter(d => d.nome.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10));

  assembleObjectFromForm(): void {
    if (this.dayOffProxy) {
      //this.activeObject.diaDeFolga = this.dayOffProxy.id;
    }
    if (this.weekTurnDayProxy) {
      //this.activeObject.weekTurnDay = this.weekTurnDayProxy.id;
    }
  }

  loadAuxiliaryLists(): void {
    this.toleranceService.getList().then(response => {
      this.toleranceList = response.response;
      this.branchService.getList().then(r1 => {
        this.branchList = r1.response;
      });
    });
  }

  loadObject(): void {
    this.criarColorTd();
    this.criarValoresDiaCalendarioTd();
    this.valoresDiaCalendario = [];
    if (this.activeID && (this.activeID !== 0)) {
      this.turnoEscalaService.getById(this.activeID).then(response => {
        var resultItem: TurnoEscala = response.response;
        this.activeObject = resultItem;
        this.dailyTotals = new Array();
        this.loading = false;
        this.inicioEscalaDia = this.activeObject.inicioEscalaDia;
        this.inicioEscalaMes = this.activeObject.inicioEscalaMes;
        this.inicioEscalaAno = this.activeObject.inicioEscalaAno;
        this.fimEscalaDia = this.activeObject.fimEscalaDia;
        this.fimEscalaMes = this.activeObject.fimEscalaMes;
        this.fimEscalaAno = this.activeObject.fimEscalaAno;
        this.gerarCalendario();
        this.activeObject.horasSemanais = this.utils.miliToHHmm(this.activeObject.horasSemanais);
        if (resultItem.calandario) {
          let cc = resultItem.calandario.split("|", 33).filter((item) => item !== "");
          for (var i = 1; i <= cc.length; i++) {
            var cont = i - 1;
            var texto = cc[i]
            if (texto !== undefined) {
              this.valoresDiaCalendario[cont] = texto;
              if (texto.indexOf("Jornada") !== -1) {
                this.colortd[cont] = 'verdetd';
              } else if (texto.indexOf("Folga") !== -1) {
                this.colortd[cont] = 'azultd';
              } else if (texto.indexOf("DSR") !== -1) {
                this.colortd[cont] = 'cinzatd';
              } else {
                this.colortd[cont] = 'brancotd';
              }
            }
          }
        }
        if (resultItem.tipoEscala && resultItem.tipoEscala == 1) {
          this.tipoEscala = "Escala Fixa";
          this.desahabilitarHorasDiaria = true;
          this.desahabilitarHorasFixa = false;
        } else {
          this.tipoEscala = "Escala Flexivel";
          this.desahabilitarHorasDiaria = false;
          this.desahabilitarHorasFixa = true;
        }
      });
    } else {
      this.loading = false;
    }
  }

  initObject(): void {
    this.criarColorTd();
    this.criarValoresDiaCalendarioTd();
    this.activeObject = new TurnoEscala();
    this.activeObject.empresaId = this.authGuard.loggedUser().selectedCompany.id;
    this.dailyTotals = new Array();
    this.loading = false;
  }

  doCreate(t: TurnoEscala): Promise<RESTResponseVO<TurnoEscala>> {
    let diasSemana = "|";
    for (var i = 0; i <= this.valoresDiaCalendario.length; i++) {
      let v = this.valoresDiaCalendario[i];
      if (v) {
        let as = v.split(":", 2);
        if (as[1] !== undefined) {
          diasSemana = diasSemana + as[1].trim() + "|";
        } else {
          diasSemana = diasSemana + v + "|";
        }
      } else {
        diasSemana = diasSemana + "VAZIO|";
      }
    }
    if ("Escala Fixa" === this.tipoEscala) {
      t.tipoEscala = 1;
    } else {
      t.tipoEscala = 2;
    }
    t.calandario = diasSemana;
    t.horasSemanais = this.utils.HHmmToMili(t.horasSemanais);
    t.tipo = 1;
    t.empresaId = this.company;
    return this.turnoEscalaService.create(t);

  }

  doEdit(t: TurnoEscala): Promise<RESTResponseVO<TurnoEscala>> {
    let diasSemana = "|";
    for (var i = 0; i <= this.valoresDiaCalendario.length; i++) {
      let v = this.valoresDiaCalendario[i];
      if (v) {
        let as = v.split(":", 2);
        if (as[1] !== undefined) {
          diasSemana = diasSemana + as[1].trim() + "|";
        } else {
          diasSemana = diasSemana + v + "|";
        }
      } else {
        diasSemana = diasSemana + "VAZIO|";
      }
    }
    if ("Escala Fixa" === this.tipoEscala) {
      t.tipoEscala = 1;
    } else {
      t.tipoEscala = 2;
    }
    t.calandario = diasSemana;
    t.horasSemanais = this.utils.HHmmToMili(t.horasSemanais);
    return this.turnoEscalaService.update(t);
  }

  doDelete(t: TurnoEscala): Promise<RESTResponseVO<string>> {
    return this.turnoEscalaService.delete(t.id);
  }

  checkOverflow(value: string, weekly?: boolean): boolean {
    let limit;
    if (weekly) {
      limit = this.utils.HHmmToMili(this.activeObject.horasSemanais);
    }
    return this.utils.HHmmToMili(value) > limit;
  }

  // Tolerance
  toleranceFormatter = (x: Tolerance) => x.descricao;

  searchTolerance = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map(term => term.length < 2 ? [] : this.toleranceList.filter(v => v.descricao.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10));

  toleranceTemplate(r) {
    return r.descricao;
  }

  setActiveID(): void {
    this.activeID = this.activeObject.id;
  }

  onChangeSelectCalendar(valor, dia) {
    if (valor.indexOf("Jornada") !== -1) {
      this.colortd[dia] = 'verdetd';
    } else if (valor.indexOf("Folga") !== -1) {
      this.colortd[dia] = 'azultd';
    } else if (valor.indexOf("DSR") !== -1) {
      this.colortd[dia] = 'cinzatd';
    } else {
      this.colortd[dia] = 'brancotd';
    }
    this.valoresDiaCalendario[dia] = valor;
  }

  onChangeSelectTipoEscala(valor) {
    if (valor.indexOf("Escala Fixa") !== -1) {
      this.desahabilitarHorasDiaria = true;
      this.desahabilitarHorasFixa = false;
    } else {
      this.desahabilitarHorasDiaria = false;
      this.desahabilitarHorasFixa = true;
    }
  }

  criarColorTd() {
    this.colortd = [];
    for (var i = 0; i <= 31; i++) {
      this.colortd[i] = 'brancotd'
    }
  }

  criarValoresDiaCalendarioTd() {
    this.valoresDiaCalendario = [];
    for (var i = 0; i <= 31; i++) {
      this.valoresDiaCalendario[i] = "";
    }
  }

  gerarCalendario() {
    try {
      this.activeObject.inicioEscalaDia = this.inicioEscalaDia;
      this.activeObject.inicioEscalaMes = this.inicioEscalaMes;
      this.activeObject.inicioEscalaAno = this.inicioEscalaAno;
      this.activeObject.fimEscalaDia = this.fimEscalaDia;
      this.activeObject.fimEscalaMes = this.fimEscalaMes;
      this.activeObject.fimEscalaAno = this.fimEscalaAno;
      this.errorMessage = "";
      var date1 = new Date(this.converterMesEmNumero(this.inicioEscalaMes) + "/" + this.inicioEscalaDia + "/" + this.inicioEscalaAno);
      var date2 = new Date(this.converterMesEmNumero(this.fimEscalaMes) + "/" + this.fimEscalaDia + "/" + this.fimEscalaAno);
      if (date1 > date2) {
        this.errorMessage = "Data inicial da apuração MAIOR que o fim da apuração.";
      } else {
        var time = date2.getTime() - date1.getTime();
        var days = time / (1000 * 3600 * 24); //Diference in Days
        if (days > 32) {
          this.errorMessage = "Limite entre as datas MAIOR que 31 dias.";
        } else {
          var diff = Math.round((time) / (1000 * 60 * 60 * 24));
          var dayMap = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado", "Domingo"];
          this.listaCalendarioTurnoEscala = [];
          let contTemp = 0;
          while (diff > -1) {
            let calendarioTurnoEscala = new CalendarioTurnoEscala();
            let dataAtual = new Date(date1);
            calendarioTurnoEscala.diaDaSemana = dayMap[dataAtual.getDay()];
            let ss = dataAtual.toUTCString();
            calendarioTurnoEscala.diaDoMes = new Number(ss.split(" ")[1]);
            calendarioTurnoEscala.data = date1;
            calendarioTurnoEscala.cont = contTemp;
            this.listaCalendarioTurnoEscala.push(calendarioTurnoEscala);
            date1.setDate(date1.getDate() + 1);
            diff--;
            contTemp++;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }


  buscarSemana(diaInicial:number, diaFinal:number):CalendarioTurnoEscala[]{
    try{
      return this.listaCalendarioTurnoEscala.slice(diaInicial, diaFinal);
    }catch(error){
      console.error(error);
    }
    return [];
  }

  converterMesEmNumero(mes: String): String {
    switch (mes) {
      case "Janeiro": return "01"; break;
      case "Fevereiro": return "02"; break;
      case "Março": return "03"; break;
      case "Abril": return "04"; break;
      case "Maio": return "05"; break;
      case "Junho": return "06"; break;
      case "Julho": return "07"; break;
      case "Agosto": return "08"; break;
      case "Setembro": return "09"; break;
      case "Outubro": return "10"; break;
      case "Novembro": return "11"; break;
      case "Dezembro": return "12"; break;
    }
  }

}