import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appDynamicGradient]'
})
export class DynamicGradientDirective implements OnInit {
  @Input() appDynamicGradient: string;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.el.nativeElement.style.backgroundImage = `linear-gradient(90deg, ${this.appDynamicGradient} 0%, rgba(255,255,255,1) 300%)`;
  }
}