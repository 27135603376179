import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class DateTimePickerAdapter extends NgbDateAdapter<Date> {
  fromModel(date: Date): NgbDateStruct {
    if (date) {
      // Date
      if (date.getFullYear) {
        return {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate()
        };
        // yyyy-MM-dd (WebService response)
      } else if (typeof date === 'string') {
        const pieces = (date as string).split('-');
        return {
          year: Number(pieces[0]),
          month: Number(pieces[1]),
          day: Number(pieces[2])
        };
      } else if (typeof date === 'number') {
        return this.fromModel(new Date(date));
      }
    }

    return null;
  }

  toModel(date: NgbDateStruct): Date {
    return date ? new Date(date.year, date.month - 1, date.day) : null;
  }
}
