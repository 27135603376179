import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ElementRef} from '@angular/core'
import { environment } from 'environments/environment';
import * as firebaseApp from '../../../assets/js/firebase/firebase-app.js'
import * as firebaseAnalytics from '../../../assets/js/firebase/firebase-analytics.js'
import * as firebaseDataBase from '../../../assets/js/firebase/firebase-database.js'
import {Subject, fromEvent} from 'rxjs'
import {filter, takeUntil} from 'rxjs/operators'
import { AuthGuard } from '../../../app/guards/auth.guard';
import { User } from 'app/classes/user.js';
import { BranchService } from "app/services/crud/branch.service";
import { DriverService } from "app/services/crud/driver.service";
import { MotoristaChatVO } from "../../classes/motorista-chat-vo";
import { MatDialog } from '@angular/material/dialog';
import { ChatConfirmDialogComponent } from './confirm-dialog/chat-confirm-dialog.component';
import { PainelIndicadoresService } from 'app/services/administrador/painel-indicadores.service.js';

interface Mensagem {
    msg: string;
    dataHora: string;
    sender: boolean;
    time: number;
}

@Component({
    selector: 'chat-firebase',
    templateUrl: './chat-firebase.component.html',
    styleUrls: ['./chat-firebase.component.css']
})
export class ChatFireBaseComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('chatContent') chatContent!: ElementRef;
    @ViewChild('chatCaixaTexto') chatCaixaTexto!: ElementRef;

    loading: boolean = false;
    app: any;
    analytics: any;
    dbRead:any;
    listenerOnChildAdded = null;
    notifier = new Subject();
    loggedUser: User;

    janelaChatAberta: boolean = false;
    unidades: any;
    motoristas: MotoristaChatVO[] = [];
    motoristasFiltrados: MotoristaChatVO[] = [];
    mensagens: Mensagem[] = [];
    mensagensFetch: Mensagem[] = [];
    unidadeSelecionada: number;
    motoristaSelecionado: any;
    countNovasMensagens: number = 0;
    mensagemDigitada: string = '';
    searchMotorista: string = '';
    imagemPadrao: string = 'https://cdn-icons-png.flaticon.com/512/1535/1535791.png';

    initialDataLoaded: boolean = true;
    initialDataCount: number = 0;

    constructor(private authGuard: AuthGuard,
                private driverService: DriverService,
                private branchService: BranchService,
                public dialog: MatDialog,
                public painelIndicadoresService: PainelIndicadoresService
    ) {
        this.loggedUser = this.authGuard.loggedUser();
    }

    ngOnInit(): void {
        this.app =  firebaseApp.initializeApp(environment.firebaseConfig);
        this.analytics = firebaseAnalytics.getAnalytics(this.app);
        this.dbRead = firebaseDataBase.getDatabase();
        this.obterUnidades();
    }

    ngAfterViewInit(): void {
        
    }

    ngOnDestroy(): void {
        this.notifier.next()
        this.notifier.complete()
    }

    scrollToBottom(): void {
        setTimeout(() => {
            this.chatContent.nativeElement.scrollTop = this.chatContent.nativeElement.scrollHeight;
        }, 500);
    }

    milissegundosParaDataHora(milissegundos) {
        const data = new Date(milissegundos);
        const dia = data.getDate();
        const mes = data.getMonth() + 1; // Meses são indexados de 0 a 11
        const ano = data.getFullYear();
        const horas = data.getHours();
        const minutos = data.getMinutes();
        const segundos = data.getSeconds();
        const dataHoraFormatada = `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${ano} ${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`;
        return dataHoraFormatada;
    }

    enviarMensagem(destino: number, unidade: number, tokenFcm: string) {
        const db = firebaseDataBase.getDatabase();
        const milissegundos = new Date().getTime();
        const mensagem = this.mensagemDigitada;

        const postData = {
            sender: this.loggedUser.id+"",
            receiver: destino+"",
            message: mensagem,
            time: milissegundos,
            lido: true
        };

        const caminho = this.defineCaminho(unidade);
        const newPostKey = firebaseDataBase.push(
            firebaseDataBase.child(
                firebaseDataBase.ref(db), caminho)).key;

        const updates = {};
        updates[caminho + newPostKey] = postData;
        firebaseDataBase.update(firebaseDataBase.ref(db), updates)
            .then(() => {
                
                //enviar notificacao
                this.painelIndicadoresService.enviarNotificacao(tokenFcm, mensagem)
                    .then(response=> {
                        console.log(response);
                    });
            })
            .catch((error) => {
                console.log("ERRO ao enviar mensagem: " + error);
            });

        this.chatCaixaTexto.nativeElement.focus();
    }

    enviarMotoristaSelecionado() {
        if (this.mensagemDigitada.length > 0 && this.motoristaSelecionado) {
            this.enviarMensagem(this.motoristaSelecionado.userId, this.motoristaSelecionado.unidadeId, this.motoristaSelecionado.tokenFcm);
            this.mensagemDigitada = '';
        }
    }

    enviarUnidadeSelecionada() {
        if (this.mensagemDigitada.length > 0 && this.unidadeSelecionada > 0) {
            const dialogRef = this.dialog.open(ChatConfirmDialogComponent, {
                data: {
                    message: 'Você tem certeza que deseja enviar para a unidade selecionada?'
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.motoristas.forEach((m) => {
                        if (m.unidadeId == this.unidadeSelecionada) {
                            this.enviarMensagem(m.userId, this.unidadeSelecionada, m.tokenFcm);
                        }
                    });
                    this.mensagemDigitada = '';
                }
            });
        }
    }

    enviarTodasUnidades() {
        if (this.mensagemDigitada.length > 0) {
            const dialogRef = this.dialog.open(ChatConfirmDialogComponent, {
                data: {
                    message: 'Você tem certeza que deseja enviar para todas as unidades?'
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.motoristas.forEach((m) => {
                        this.enviarMensagem(m.userId, m.unidadeId, m.tokenFcm);
                    });
                    this.mensagemDigitada = '';
                }
            });
        }
    }

    toggleDiv(): void {
        this.countNovasMensagens = 0;
        this.janelaChatAberta = !this.janelaChatAberta;
    }

    ativarListenerNotificacoes() {
        if (this.loggedUser.selectedBranch.id > 0) {
            this.criarListenerUnidade(this.loggedUser.selectedBranch.id);
        } else {
            this.unidades.forEach((u) => {
                this.criarListenerUnidade(u.id);
            });
        }
    }

    criarListenerUnidade(unidadeId: number) {
        let caminho = this.defineCaminho(unidadeId);
        const chatsRef = firebaseDataBase.ref(this.dbRead, caminho);
        
        firebaseDataBase.onChildAdded(chatsRef, (snapshot) => {
            //debugger;
            const newChild = snapshot.val();
            const motorista = this.motoristas.find(m => m.userId == Number(newChild.sender) || m.userId == Number(newChild.receiver));
            
            if (motorista) {
                motorista.timeUltimaMensagem = newChild.time;
                this.motoristasFiltrados.sort((a, b) => {
                    if (a.timeUltimaMensagem > b.timeUltimaMensagem) {
                        return -1;
                    }
                    if (a.timeUltimaMensagem < b.timeUltimaMensagem) {
                        return 1;
                    }
                    return 0;
                });
            }

            if (newChild.message && !newChild.lido) {
                this.countNovasMensagens++;
                if (motorista && 
                    (!this.janelaChatAberta || 
                        (this.janelaChatAberta && 
                            (newChild.sender != this.motoristaSelecionado.userId +"") && 
                            (newChild.receiver != this.motoristaSelecionado.userId +"")))) {
                    motorista.novaMensagem = true;
                }
            }
        });
        
    }

    obterUnidades() {
        this.branchService.getList().then(response => {
            this.unidades = response.response;
            this.unidades.sort((a, b) => {
                if (a.apelido < b.apelido) {
                    return -1;
                }
                if (a.apelido > b.apelido) {
                    return 1;
                }
                return 0;
            });
    
            this.unidadeSelecionada = this.loggedUser.selectedBranch.id;
            this.listarMotoristas();
        });
    }

    listarMotoristas() {
        this.driverService.getMotoristasChat().then(response => {
            this.motoristas = response.response;
            this.motoristasFiltrados = this.motoristas;
            this.ativarListenerNotificacoes();
        });
    }

    onClickMotorista(motorista: any) {
        this.motoristaSelecionado = motorista;
        if(this.motoristaSelecionado && this.motoristaSelecionado.userId > 0) {
            const motorista = this.motoristas.find(m => m.userId+"" == this.motoristaSelecionado.userId+"");
            if (motorista) {
                motorista.novaMensagem = false;
            }
            const caminho = this.defineCaminho(this.motoristaSelecionado.unidadeId);
            this.fetchMessagesFirebase(this.motoristaSelecionado.userId, caminho);
        }
    }

    marcarComoLido(caminho: string) {
        const dbRef = firebaseDataBase.ref(this.dbRead, caminho);
        firebaseDataBase.update(dbRef, {
            lido: true
        }).then(() => {
        }).catch((error) => {
            console.log("ERRO ao marcar como lido: " + error);
        });
    }

    defineCaminho(unidade: number) {
        return environment.firebaseRootNode + 
               '/empresas/' + this.loggedUser.selectedCompany.id + 
               '/unidades/' + unidade + '/mensagens/';
    }

    getApelidoUnidade(id: number) {
        const unidade = this.unidades.find(u => u.id === id);
        return unidade.apelido;
    }

    filterMotoristas() {
        this.motoristasFiltrados = this.motoristas.filter(motorista =>
            motorista.nome.toLowerCase().includes(this.searchMotorista.toLowerCase())
        );
    }

    onChangeUnidade(event: any) {
        if (this.unidadeSelecionada > 0) {
            this.motoristasFiltrados = this.motoristas.filter(m => m.unidadeId == this.unidadeSelecionada);
        } else {
            this.motoristasFiltrados = this.motoristas;
        }   
    }

    async fetchMessagesFirebase(userId, caminho) {
        this.mensagensFetch = [];
        this.mensagens = [];

        const messagesRef = firebaseDataBase.ref(this.dbRead, caminho);
        const queryByReceiver = firebaseDataBase.query(messagesRef, firebaseDataBase.orderByChild('receiver'), firebaseDataBase.equalTo(userId+""));
        const snapshot = await firebaseDataBase.get(queryByReceiver);
        snapshot.forEach(childSnapshot => {
            const childKey = childSnapshot.key;
            const childData = childSnapshot.val();

            var dataHora = this.milissegundosParaDataHora(childData.time);
            var msg = childData.message;
            var sender = false;
            var time = childData.time;
            const objMsg: Mensagem = {
                msg,
                dataHora,
                sender,
                time
            };
            this.mensagensFetch.push(objMsg);

            //marcar mensagem como lido
            if (!childData.lido) {
                this.marcarComoLido(caminho + childKey);
            }
        });

        const queryBySender = firebaseDataBase.query(messagesRef, firebaseDataBase.orderByChild('sender'), firebaseDataBase.equalTo(userId+""));
        const snapshotSender = await firebaseDataBase.get(queryBySender);
        snapshotSender.forEach(childSnapshot => {
            const childKey = childSnapshot.key;
            const childData = childSnapshot.val();

            var dataHora = this.milissegundosParaDataHora(childData.time);
            var msg = childData.message;
            var sender = true;
            var time = childData.time;
            const objMsg: Mensagem = {
                msg,
                dataHora,
                sender,
                time
            };
            this.mensagensFetch.push(objMsg);

            //marcar mensagem como lido
            if (!childData.lido) {
                this.marcarComoLido(caminho + childKey);
            }
        });

        this.mensagensFetch.sort((a, b) => {
            if (a.time < b.time) {
                return -1;
            }
            if (a.time > b.time) {
                return 1;
            }
            return 0;
        });

        this.mensagens = this.mensagensFetch;
        this.scrollToBottom();

        const messagesQuery = firebaseDataBase.query(messagesRef);

        // Primeiro, conte os dados iniciais
        await firebaseDataBase.get(messagesQuery).then(snapshot => {
            this.initialDataCount = snapshot.size;
            this.initialDataLoaded = true;
        }).catch(error => {
            console.error("Erro ao contar os dados iniciais: ", error);
        });

        this.removerListenerOnChildAdded();
        this.listenerOnChildAdded = firebaseDataBase.onChildAdded(messagesQuery, (snapshot) => {
            if (this.initialDataLoaded) {
                this.initialDataCount--;
                if (this.initialDataCount === 0) {
                    this.initialDataLoaded = false;
                }
            } else {
                //console.log('Novo filho adicionado:', snapshot.val());

                const childKey = snapshot.key;
                const childData = snapshot.val();
                
                if ((childData.sender == this.motoristaSelecionado.userId +"") || (childData.receiver == this.motoristaSelecionado.userId +"")) {
                    const objMsg: Mensagem = {
                        msg: childData.message,
                        dataHora: this.milissegundosParaDataHora(childData.time),
                        sender: childData.sender == this.motoristaSelecionado.userId +"",
                        time: childData.time
                    };
                    this.mensagens.push(objMsg);
                    //console.log('acerta scroll');
                    this.scrollToBottom();

                    //marca mensagem como lido
                    if (this.janelaChatAberta) {
                        if (!childData.lido) {
                            this.marcarComoLido(caminho + childKey);
                        }
                    }
                }

            }
        });

    }

    removerListenerOnChildAdded() {
        if (this.listenerOnChildAdded) {
            this.listenerOnChildAdded();
            this.listenerOnChildAdded = null;
            console.log('Listener desligado');
        }
    }

}
