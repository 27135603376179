import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { MatDialog, MatSort, MatTableDataSource } from "@angular/material";
import { DetalheDoIndicador } from "app/classes/detalhe-do-indicador";
import { ListaDeDetalhesEficiencia } from "app/classes/ItemEficienciaTelemetria";
import { TabelaDetalhesDoIndicador } from "app/classes/tabela-detalhes-do-indicador";
import { ControleIndicadoresTelemetriaService } from "app/services/crud/controle-indicadores-telemetria.service";
import { DetalhesDoIndicadorComponent } from "../detalhes-do-indicador/detalhes-do-indicador.component";

@Component({
  selector: "lista-detalhes-eficiencia-component",
  templateUrl: "./lista-detalhes-eficiencia-component.html",
  styleUrls: ["./lista-detalhes-eficiencia-component.scss"],
})
export class ListaDeDetalhesEficienciaComponent implements AfterViewInit, OnChanges, OnInit {
  @Input() data: ListaDeDetalhesEficiencia[] = [];
  @Input() porcentagemSelecionada: string;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<ListaDeDetalhesEficiencia>;
  displayedColumns = ["idPlaca", "nomeModelo", "totalEventos", "tempoTotal"];
  exibirTabela = true;
  label: string = 'Veículos';

  constructor(
    private readonly controleIndicadoresTelemetriaService: ControleIndicadoresTelemetriaService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data);
  }

  formatLabel(label) {
    const output = label.replace(/[0-9]/g, '').replace(/\./g, '').replace(/%/g,"");
    return output;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.data = this.data;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.data && changes.data.currentValue) {
        if (this.dataSource) {
          this.dataSource.data = changes.data.currentValue;
          this.label = this.formatLabel(this.porcentagemSelecionada);
        }
      }
    }
  }

  escodeOuExibeDiv() {
    this.exibirTabela = !this.exibirTabela;
  }

  async buscarDetalheDoIndicador(event: ListaDeDetalhesEficiencia) {
    const result = await this.controleIndicadoresTelemetriaService
      .buscarDetalheDoIndicador(event.listaIdTelemetria, this.porcentagemSelecionada);
    const tabela: TabelaDetalhesDoIndicador[] = result.map(item => {
      return {
        dataHora: item.dataHora,
        placa: item.placa,
        modeloFabricante: item.modeloFabricante,
        idMotorista: item.idMotorista,
        motorista: item.motorista,
        duracao: item.tempoEvento,
        kmRodado: item.kmRodado,
        enderecoCompleto: item.endereco,
        localNoMapa: `Latitude: ${item.latitude} - Longitude: ${item.longitude}`
      }
    });
    this.exibirDetalhesDoIndicador(tabela);
  }

  exibirDetalhesDoIndicador(dados: TabelaDetalhesDoIndicador[]) {
    this.dialog.open(DetalhesDoIndicadorComponent, {
      data: { dados, porcentagemSelecionada: this.porcentagemSelecionada },
      panelClass: "indicadores-dialog",
    });
  }
}
