import { Component, OnInit, ViewChild, Compiler } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import 'fullcalendar';
import * as $ from 'jquery';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { Observable } from 'rxjs/Observable';
import { Driver } from '../../classes/driver';
import { DSR } from '../../classes/dsr';
import { DSRGridItemVO } from '../../classes/dsr-grid-item-vo';
import { AuthGuard } from '../../guards/auth.guard';
import { DriverService } from '../../services/crud/driver.service';
import { DSRService } from '../../services/crud/dsr.service';
import { PDFService } from '../../services/pdf.service';
import { UtilService } from '../../services/utils.service';
import { BaseListComponent } from '../base/base-list/base-list.component';
import { NonconformityTreatment } from '../../classes/nonconformity-treatment';

@Component({
  selector: 'app-dsr-grid',
  templateUrl: './dsr-grid.component.html',
  styleUrls: ['./dsr-grid.component.css']
})
export class DSRGridComponent extends BaseListComponent implements OnInit {
  constructor(
    private driverService: DriverService,
    private dsrService: DSRService,
    private utilService: UtilService,
    pdfService: PDFService,
    private modelService: NgbModal,
    private authGuard: AuthGuard,
    private _compiler: Compiler
  ) {
    super(utilService, pdfService, authGuard);
    this.searchField = '';
    this._compiler.clearCache();
  }

  @ViewChild('dialogContent') dialogContent;

  public searchObject: { driver: Driver; start: Date; end: Date } = {
    driver: undefined,
    start: undefined,
    end: undefined
  };
  public calendarOptions: any;
  public calendarEventSource: any[];
  public driverList: Driver[];
  public treatment: NonconformityTreatment;

  ngOnInit() {
    this.driverService.getListMinimo().then(response => {
      this.driverList = response.response;
    });
    this.calendarEventSource = new Array();

    this.calendarOptions = {
      themeSystem: 'bootstrap4',
      height: 480,
      editable: true,
      locale: 'pt-br',
      defaultView: 'month',
      eventLimit: true,
      weekNumbers: false,
      header: {
        left: 'prev,next',
        center: 'title',
        right: ''
      },
      dayRender: this.onDayRender.bind(this),
      dayClick: this.onDayClick.bind(this),
      timeFormat: 'H(:mm)'
    };
    moment.locale('pt-br');
    $('#calendario').fullCalendar(this.calendarOptions);

    this.data = new Array();

    this.tableHeaders = [
      [
        this.createHeader('Motorista', 0, true),
        this.createHeader('Início DSR', 1, true),
        this.createHeader('Fim DSR', 2, true),
        this.createHeader('Início Interjornada', 3, true),
        this.createHeader('Fim Interjornada', 4, true)
      ]
    ];
    this.dataFields = [
      this.createField(
        'motorista.nome',
        'string',
        undefined,
        'motorista.sobrenome',
        ' '
      ),
      this.createField('inicioDSR', 'datetime'),
      this.createField('fimDSR', 'datetime'),
      this.createField('inicioInterjornada', 'datetime'),
      this.createField('fimInterjornada', 'datetime')
    ];

    this.loading = false;
  }

  validDate(date: moment.Moment) {
    if (date && this.searchObject) {
      // Workaround
      let offsetDate = date.utc().add(3, 'hours');

      return (
        date.isSameOrAfter(this.searchObject.start) &&
        date.isSameOrBefore(this.searchObject.end)
      );
    }
    return false;
  }

  eventExists(date: moment.Moment) {
    let result: boolean = false;

    this.calendarEventSource.forEach(event => {
      if (moment(event.start, 'YYYY-MM-DD').isSame(date)) {
        result = true;
        return;
      }
    });

    return result;
  }

  removeDSR(row) {
    this.calendarEventSource = this.calendarEventSource.filter(
      event => !moment(event.start).isSame(row.inicioDSR) && !event.id
    );
    this.data = this.data.filter(d => d != row);
    this.updateCalendar();
  }

  uploadDSR(row) {
    this.errorMessage = undefined;

    delete row.operadorLancamento.password;
    delete row.operadorLancamento.criptoPassword;
    delete row.operadorLancamento.companyList;
    delete row.operadorLancamento.branchList;
    delete row.operadorLancamento.claimList;
    delete row.operadorLancamento.selectedBranch;
    delete row.operadorLancamento.selectedCompany;
    delete row.operadorLancamento.branchMap;

    this.dsrService
      .create(row)
      .then(response => {
        if (!response.error) {
          this.removeDSR(row);
          this.doSearch();
        } else {
          this.errorMessage = response.error;
        }
      })
      .catch(reason => {
        this.errorMessage = reason;
      });
  }

  onDayRender(date: moment.Moment, cell) {
    if (this.searchObject && this.searchObject.start && this.searchObject.end) {
      if (!this.validDate(date)) {
        $(cell).css('background-color', '#CFCFCF');
        $(cell).css('color', '#FFF');
        $(cell).css('cursor', 'default');
      }
    }
  }

  onDayClick(date: moment.Moment, jsEvent, view) {
    this.errorMessage = undefined;

    if (!this.searchObject.driver || !this.validDate(date)) return;
    if (this.eventExists(date)) {
      this.errorMessage = 'DSR já existe na data escolhida!';
      return;
    }

    let offsetDate = date
      .clone()
      .utc()
      .subtract(3, 'hours');

    this.treatment = new NonconformityTreatment();
    this.treatment.startDate = moment(offsetDate).format(
      'YYYY-MM-DD[T]HH:mm:ss'
    );
    this.treatment.endDate = moment(offsetDate)
      .add(24, 'hours')
      .format('YYYY-MM-DD[T]HH:mm:ss');

    this.modelService
      .open(this.dialogContent, { centered: true, size: 'lg' })
      .result.then(
        result => {
          let interjourneyStart = this.retrieveInterjourneyStart(
            this.treatment.startDate
          );
          let interjourneyEnd = this.retrieveInterjourneyEnd(
            this.treatment.startDate
          );

          this.calendarEventSource.push({
            editable: false,
            title: 'DSR',
            start: moment(interjourneyStart)
              .utc()
              .subtract(3, 'hours'),
            end: moment(this.treatment.endDate)
              .utc()
              .subtract(3, 'hours'),
            tipo: 1,
            id: undefined
          });

          var dsr: DSR = new DSR();
          dsr.motorista = this.searchObject.driver;
          dsr.empresaId = this.authGuard.loggedUser().selectedCompany.id;
          dsr.inicioDSR = moment(
            this.treatment.startDate,
            'YYYY-MM-DD[T]HH:mm:ss'
          ).toDate();
          dsr.fimDSR = moment(
            this.treatment.endDate,
            'YYYY-MM-DD[T]HH:mm:ss'
          ).toDate();
          dsr.operadorLancamento = this.authGuard.loggedUser();

          dsr.inicioInterjornada = moment(interjourneyStart).toDate();
          dsr.fimInterjornada = moment(interjourneyEnd).toDate();

          this.data.push(dsr);

          this.updateCalendar();
        },
        reason => {
          this.updateCalendar();
        }
      );
  }

  updateCalendar() {
    var calendar = $('#calendario');
    var localView = calendar.fullCalendar('getView');

    calendar.fullCalendar('changeView', 'basicDay');
    calendar.fullCalendar('changeView', localView.name);

    calendar.fullCalendar('removeEvents');
    calendar.fullCalendar('addEventSource', this.calendarEventSource);

    $('#calendario').fullCalendar('gotoDate', this.searchObject.start);
  }

  retrieveEventTitle(vo: DSRGridItemVO) {
    let result = vo.eventDescription;
    if (vo.tipoEvento === 1) {
      result += ' ' + this.utilService.miliToHHmm(vo.tempoTrabalhado);
    }
    return result;
  }

  modalAddToStart(hours: number) {
    this.treatment.endDate = moment(this.treatment.startDate)
      .add(hours, 'hours')
      .format('YYYY-MM-DD[T]HH:mm:ss');
  }

  doSearch() {
    if (
      this.searchObject &&
      this.searchObject.driver &&
      this.searchObject.start &&
      this.searchObject.end
    ) {
      this.loading = true;
      this.dsrService
        .driverDSR(
          this.searchObject.start.getTime(),
          this.searchObject.end.getTime(),
          this.searchObject.driver.id
        )
        .then(response => {
          if (!response.error) {
            let responseList = response.response;
            let voList = responseList.map((value: DSRGridItemVO) => {
              return {
                editable: false,
                title: this.retrieveEventTitle(value),
                start: moment(value.dataInicio)
                  .utc()
                  .subtract(3, 'hours'),
                end: moment(value.dataFim)
                  .utc()
                  .subtract(3, 'hours'),
                tipo: value.tipoEvento,
                id: value.eventId
              };
            });
            this.calendarEventSource.splice(0, this.calendarEventSource.length);
            this.calendarEventSource = this.calendarEventSource.concat(voList);
          } else {
            this.errorMessage = response.error;
          }
          this.loading = false;
          this.updateCalendar();
        })
        .catch(reason => {
          this.errorMessage = reason;
        });
    }
  }

  retrieveInterjourneyStart(start) {
    var mStart = moment(start)
      .utc()
      .subtract({ hours: 11, seconds: 1 });
    return mStart.toDate();
  }

  retrieveInterjourneyEnd(start) {
    var end = moment(start)
      .utc()
      .subtract(1, 'seconds');
    return end.toDate();
  }

  dateDiff(start, end) {
    var ms = moment(end).diff(moment(start));
    var d = moment.duration(ms);
    return Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
  }

  driverFormatter = (x: Driver) => x.nome + ' ' + x.sobrenome;

  searchDriver = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map(term =>
        term.length < 2
          ? []
          : this.driverList
              .filter(
                v => v.nome.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
              .slice(0, 10)
      );

  driverTemplate(r) {
    return r.nome + ' ' + r.sobrenome;
  }
}
