import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DatePipe } from '@angular/common';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import { BaseFormComponent } from '../../../base/base-form/base-form.component';
import { RESTResponseVO } from '../../../../classes/restresponsevo';
import { Holiday } from '../../../../classes/holiday';
import { HolidayService } from '../../../../services/crud/holiday.service';
import { City } from '../../../../classes/city';
import { CityService } from '../../../../services/crud/city.service';
import { HolidayScope } from '../../../../classes/holiday-scope';
import { HolidayScopeService } from '../../../../services/crud/holiday-scope.service';
import { FederativeUnit } from '../../../../classes/federative-unit';
import { FederativeUnitService } from '../../../../services/crud/federative-unit.service';
import { CountryState } from '../../../../classes/country-state';
import { Company } from '../../../../classes/company';
import { AuthGuard } from '../../../../guards/auth.guard';

@Component({
    selector: 'app-holiday-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css'],
    providers: [DatePipe]
})
export class HolidayFormComponent extends BaseFormComponent<Holiday> {

    habilitarCidadeMunicipio:boolean = true;

    constructor(private holidayService: HolidayService,
        private holidayScopeService: HolidayScopeService,
        private cityService: CityService,
        private datePipe: DatePipe,
        private authGuard: AuthGuard,
        route: ActivatedRoute,
        router: Router) {
        super(route, router, 'adm/holiday/list');

    }

    assembleObjectFromForm(): void {
        delete this.activeObject.inicioFeriado;
        delete this.activeObject.fimFeriado;
    }

    loadAuxiliaryLists(): void {
        this.holidayScopeService.getList().then(response => this.scopeList = response.response);

        this.ufList = new Array();
        this.originalCityList = new Array();
        var ids = [];
        this.cityService.getList().then(response => {
            this.originalCityList = response.response;
            this.originalCityList.forEach((city) => {
                if (!ids.includes(city.ufCodIbge.sigla)) {
                    this.ufList.push(city.ufCodIbge);
                    ids.push(city.ufCodIbge.sigla);
                }
            });

            this.ufList.sort((s1, s2) => s1.sigla.localeCompare(s2.sigla));
        });
    }

    loadObject(): void {
        if (this.activeID && (this.activeID !== 0)) {
            this.holidayService.getById(this.activeID).then(response => {
                var resultItem: Holiday = response.response;
                this.activeObject = resultItem;
                if(this.activeObject.ambito.id == 3){
                    this.habilitarCidadeMunicipio = true;
                }else{
                    this.habilitarCidadeMunicipio = false;
                }
            });
        }
        this.loading = false;
    }

    initObject(): void {
        this.activeObject = new Holiday();
        this.activeObject.empresaId = new Company();
        this.activeObject.empresaId.id = this.authGuard.loggedUser().selectedCompany.id;
        this.loading = false;
    }

    doCreate(t: Holiday): Promise<RESTResponseVO<Holiday>> {
        let loggedUser = this.authGuard.loggedUser();
        t.idUnidade = loggedUser.selectedBranch.id;
        return this.holidayService.create(t);
    }

    doEdit(t: Holiday): Promise<RESTResponseVO<Holiday>> {
        if (!t.idUnidade) {
            let loggedUser = this.authGuard.loggedUser();
            t.idUnidade = loggedUser.selectedBranch.id;
        }
        return this.holidayService.update(t);
    }

    doDelete(t: Holiday): Promise<RESTResponseVO<string>> {
        return this.holidayService.delete(t.id);
    }

    originalCityList: City[];

    get cityList() {
        if (this.activeObject.uf) {
            return this.originalCityList.filter((c) => c.ufCodIbge.sigla === this.activeObject.uf.sigla);
        } else {
            return [];
        }
    }

    cityFormatter = (x: City) => x.nome;

    searchCity = (text$: Observable<string>) =>
        text$
            .debounceTime(200)
            .distinctUntilChanged()
            .map(term => term.length < 2 ? [] : this.cityList.filter(v => v.nome.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10));

    cityTemplate(r) {
        return r.ufCodIbge.sigla + " - " + r.nome;
    }

    // UF
    ufList: CountryState[];

    ufFormatter = (x: CountryState) => x.sigla;

    searchUF = (text$: Observable<string>) =>
        text$
            .debounceTime(200)
            .distinctUntilChanged()
            .map(term => this.ufList.filter(v => v.sigla.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10));

    ufTemplate(r) {
        return r.sigla;
    }

    ufCompare(u1: any, u2: any): boolean {
        return u1 && u2 ? u1.codIbge === u2.codIbge : u1 === u2;
    }

    // Holiday Scope
    scopeList: HolidayScope[];

    scopeFormatter = (x: HolidayScope) => x.descricao;

    searchScope = (text$: Observable<string>) =>
        text$
            .debounceTime(200)
            .distinctUntilChanged()
            .map(term => term.length < 2 ? []
                : this.scopeList.filter(v => v.descricao.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10));

    scopeTemplate(r) {
        return r.descricao;
    }

    setActiveID(): void {
        this.activeID = this.activeObject.id;
    }

    cityCompare(c1, c2): boolean {
        return c1 && c2 ? c1.codIbge === c2.codIbge : c1 === c2;
    }

    ambitoChanged():void{
        if(this.activeObject.ambito.id == 3){
            this.habilitarCidadeMunicipio = true;
        }else{
            this.habilitarCidadeMunicipio = false;
        }
    }
}