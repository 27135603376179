import { Component, OnInit, Compiler, ElementRef, ViewChild } from '@angular/core';
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { ReportService } from 'app/services/report/report.service';
import { Veiculo } from 'app/classes/veiculo';
import { Filtro } from 'app/classes/filtro';


@Component({
  selector: 'app-uso-betoneira',
  templateUrl: './uso-betoneira.component.html',
  styleUrls: ['./uso-betoneira.component.css']
})
export class UsoBetoneiraComponent implements OnInit {

  @ViewChild('iframe') iframe: ElementRef;
  dropdownList = [];
  public selectedItems = [];
  dropdownSettings: {};
  public filtro: Filtro;
  public pdfSrc: any;
  listaDeVeiculos: Veiculo[] = [];
  veiculo: Veiculo;
  listaInformacaoRelatorio: any[];
  showVeiculos: boolean = false;
  showBotaoPesquisa: boolean = false;

  constructor(private veiculoService: VeiculoService,
    private reportService: ReportService,
    private _compiler: Compiler) {
    this._compiler.clearCache();
  }


  ngOnInit() {
    try {
      this.filtro = new Filtro();
      this.veiculoService.getLista().then(response => {
        var data = response.listaDeVeiculos;
        this.dropdownList = [];
        for (var i = 0; i < data.length; i++) {
          var veiculo = data[i];
          this.dropdownList.push({ item_id: veiculo.id, item_text: veiculo.placa });

        }
        this.dropdownSettings = {
          singleSelection: false,
          idField: 'item_id',
          textField: 'item_text',
          selectAllText: 'Todos',
          unSelectAllText: 'Desmarcar todos',
          itemsShowLimit: 3,
          allowSearchFilter: true,
          searchPlaceholderText: 'Pesquisar'
        };
        this.showVeiculos = true;
      });

    } catch (error) {
      console.error(error);
    }

  }

  doSearch() {
    this.showBotaoPesquisa = true;
    try {
      this.filtro.listaDeVeiculos = this.listaDeVeiculos;
      this.reportService.getReportSensorBetoneira(this.filtro).then(response => {
        this.iframe.nativeElement.setAttribute('src', response);
        this.showBotaoPesquisa = false;
      });
    } catch (error) {
      console.error(error);
    }
  }

  onItemSelect(item: any) {
    this.inserirVeiculoSelecionado(item);
  }

  onSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirVeiculoSelecionado(items[i]);
    }
  }

  onDeSelect(item: any) {
    this.removerVeiculoSelecionado(item);
  }


  inserirVeiculoSelecionado(item: any): void {
    try {
      var veiculo = this.listaDeVeiculos.find(v => v.id == item.item_id);
      if (!veiculo) {
        this.veiculo = new Veiculo();
        this.veiculo.id = item.item_id;
        this.veiculo.placa = item.item_text;
        this.listaDeVeiculos.push(this.veiculo);
      }
    } catch (error) {
      console.log(error);
    }
  }

  removerVeiculoSelecionado(item: any): void {
    try {
      let index = this.listaDeVeiculos.findIndex(v => v.id == item.item_id);
      this.listaDeVeiculos.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }

}
