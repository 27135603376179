import { Component, OnInit } from '@angular/core';
import { AuthGuard } from '../../../guards/auth.guard';
import { User } from 'app/classes/user';

@Component({
    selector: 'app-nav',
    templateUrl: './app-nav.component.html',
    styleUrls: ['./app-nav.component.css']
})
export class AppNavComponent implements OnInit {

    private loggedUser: User;
    public menuVisivel:boolean = false;
    painelV1 = true;

    constructor(private authGuard: AuthGuard) {
        this.loggedUser = this.authGuard.loggedUser();
        this.painelV1 = this.loggedUser.selectedCompany.id !== 59

     }

    ngOnInit() {
       
    }

    isAdmin(): boolean {
        return this.authGuard.isAdmin();
    }

    usuario():User{
        return this.loggedUser;
    }


    alterarMenu():void{
        if(this.menuVisivel){
            this.menuVisivel = false;
        }else{
            this.menuVisivel = true;
        }
    }
}
