import { Component, Inject, Input} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DetalheAcionamentoRegra } from 'app/classes/detalhe-acionamento-regra';
import * as XLSX from 'xlsx';

@Component({
  selector: 'detalhes-acionamentos-locais',
  templateUrl: './detalhes-acionamentos-locais.html',
  styleUrls: ['./detalhes-acionamentos-locais.scss']
})
export class DetalhesAcionamentosLocaisComponent {

  nomeLoja: string = "";

  @Input()
  detalhesPorLocal: DetalheAcionamentoRegra[] = [];

  resumoPorLocalPaginado: DetalheAcionamentoRegra[] = [];

  length: number = this.detalhesPorLocal.length;
  pageSize: number = 10;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  nomeTabela = "DetalhesAcionamentosLocais.xlsx";

  constructor(public dialogRef: MatDialogRef<DetalhesAcionamentosLocaisComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.detalhesPorLocal = data.detalhesPorLocal;
    this.nomeLoja = this.detalhesPorLocal.length > 0 ? this.detalhesPorLocal[0].nomeLocal : "";

    this.length = this.detalhesPorLocal.length;

    this.paginarResultados(0);
  }

  exibirTabela: Boolean = true;

  exportexcel() {
    let element = document.getElementById('detalhes_Acionamentos_Locais_Completo');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
 
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Tabela 1');
   
    XLSX.writeFile(wb, this.nomeTabela);
 
  }

  page(event) {
    this.pageSize = event.pageSize;
    this.paginarResultados(event.pageIndex);
  }

  paginarResultados(pageIndex: number) {
    this.resumoPorLocalPaginado = this.detalhesPorLocal.slice(pageIndex * this.pageSize, (pageIndex + 1) * this.pageSize);
  }

  close() {
    this.dialogRef.close();
  }
}