import {
    Compiler,
    Component,
    OnInit,
    ViewChild
} from "@angular/core";
import { Motorista } from 'app/classes/motorista';
import { DriverService } from '../../../services/crud/driver.service';
import Swal from "sweetalert2";
import { Filtro } from "app/classes/filtro";
import { RelatorioInconformidades } from "app/classes/relatorio-inconformidades";
import { BranchService } from "app/services/crud/branch.service";
import { User } from "app/classes/user";
import { AuthGuard } from "app/guards/auth.guard";
import { Branch } from "app/classes/branch";
import { EventsService } from '../../../services/crud/events.service';
import { ExcelService } from '../../../services/excel.service';
import EventsReportWorkbook from './events-report-workbook';


@Component({
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.css']
})
export class EventsListComponent implements OnInit {
    driverList = [];
    dropdownList = [];
    dropdownListLocal = [];
    dropdownListUnidades = []
    public selectedItems = [];
    public selectedItemsTiposInconformidades = [];
    public selectedItemsLocal = [];
    public selectedItemsUnidades = [];
    dropdownSettings: {};
    dropdownSettingsDois: {};
    dropdownSettingsUnidade: {};
    public pdfSrc: any;
    listaDeMotoristas: Motorista[] = [];
    listaDeUnidades: Branch[] = []
    motorista: Motorista;
    unidade: Branch;
    listaInformacaoRelatorio: any[];
    showMotoristas: boolean = false;
    showBotaoPesquisa: boolean = true;
    isLoading = false;
    public dataInicial: string;
    public dataFinal: string;
    situacao = 'null';
    eventoSensores: number = 0;
    loggedUser: User;
    public type = 'A';


    relatorioInconformidades: RelatorioInconformidades[] = [];
    dtOption: any = {};
    @ViewChild("dataTable") table;

    constructor(
        private driverService: DriverService,
        private branchService: BranchService,
        private eventsService: EventsService,
        private excelService: ExcelService,
        private _compiler: Compiler,
        private authGuard: AuthGuard,
    ) {
        this._compiler.clearCache();
        this.loggedUser = this.authGuard.loggedUser();
    }

    ngOnInit() {
        this.getMotoristas();
        this.obterUnidades();
    }

    obterUnidades() {
        this.branchService.getList().then(response => {
            const unidades = response.response;
            this.dropdownListUnidades = [];

            for (var i = 0; i < unidades.length; i++) {
                const unidade = unidades[i];
                this.dropdownListUnidades.push({ item_id: unidade.id, item_text: unidade.apelido });
            }
        });

        this.dropdownSettingsUnidade = {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Todos',
            unSelectAllText: 'Desmarcar todos',
            itemsShowLimit: 3,
            allowSearchFilter: true,
            searchPlaceholderText: 'Pesquisar'
        };
    }

    getMotoristas() {
        this.driverService.getListMinimo().then(response => {
            this.driverList = response.response;

            this.formartDriverList(this.driverList);
        })
            .catch((error) => console.error(error));;
    }

    async doSearch() {
        try {
            this.showBotaoPesquisa = false;
            this.isLoading = true;
            //this.filtro.listaDeUnidades = this.listaDeUnidades;
            const listDrivers = [...this.listaDeMotoristas.map((driver: Motorista) => driver.id)];

            listDrivers.sort((a, b) => a - b);
            const listUnidades = [...this.listaDeUnidades.map((unidade: Branch) => unidade.id)];
            listUnidades.sort((a, b) => a - b);
            this.eventsService.getList(this.dataInicial, this.dataFinal, listDrivers, this.authGuard.loggedUser().id, this.type, listUnidades)
                .then(data => {
                    try {
                        if (data.response.length > 0) {
                            const erw = new EventsReportWorkbook();
                            this.excelService.exportAsExcelFile(erw.mount(data.response, this.type), 'relatório');
                        }
                    } catch (e) {
                        console.log(e);
                    }
                    this.showBotaoPesquisa = true;
                    this.isLoading = false;
                })
                .catch(() => {
                    this.showBotaoPesquisa = true;
                    this.isLoading = false;
                });
        } catch (error) {
            console.error(error);
        }
    }

    onDeSelect(item: any) {
        this.removerMotoristaSelecionado(item);
    }

    onDeSelectUnidade(item: any) {
        this.removerUnidadeSelecionada(item);
    }

    onItemSelect(item: any) {
        this.inserirMotoristaSelecionado(item);
    }

    onItemSelectUnidade(item: any) {
        this.inserirUnidadeSelecionada(item);
    }

    onSelectAll(items: any) {
        for (var i = 0; i < items.length; i++) {
            this.inserirMotoristaSelecionado(items[i]);
        }
    }

    onSelectAllUnidades(items: any) {
        for (var i = 0; i < items.length; i++) {
            this.inserirUnidadeSelecionada(items[i]);
        }
    }

    inserirUnidadeSelecionada(item: any): void {
        try {
            const unidade = this.listaDeUnidades.find(m => m.id == item.item_id);
            if (!unidade) {
                this.unidade = new Branch();
                this.unidade.id = item.item_id;
                this.listaDeUnidades.push(this.unidade);
            }
        } catch (error) {
            console.log(error);
        }
    }

    inserirMotoristaSelecionado(item: any): void {
        try {
            var moto = this.listaDeMotoristas.find(m => m.id == item.item_id);
            if (!moto) {
                this.motorista = new Motorista();
                this.motorista.id = item.item_id;
                this.listaDeMotoristas.push(this.motorista);
            }
        } catch (error) {
            console.log(error);
        }
    }

    removerMotoristaSelecionado(item: any): void {
        try {
            let index = this.listaDeMotoristas.findIndex(m => m.id == item.item_id);
            this.listaDeMotoristas.splice(index, 1);
        } catch (error) {
            console.log(error);
        }
    }

    removerUnidadeSelecionada(item: any): void {
        try {
            const index = this.listaDeUnidades.findIndex(m => m.id == item.item_id);
            this.listaDeUnidades.splice(index, 1);
        } catch (error) {
            console.log(error);
        }
    }

    onDeSelectAllUnidades(items: any) {
        this.listaDeUnidades = [];
    }

    onStatusChange(value) {
        let list = [];
        if (value !== '' && value != "null") {
            list = this.driverList.filter(
                d => d.situacao === +value
            );
        } else {
            list = this.driverList;
        }

        this.formartDriverList(list);
    }

    formartDriverList(list) {
        this.dropdownList = [];
        for (var i = 0; i < list.length; i++) {
            var motorista = list[i];
            var nome = motorista.nome + " " + motorista.sobrenome;
            this.dropdownList.push({ item_id: motorista.id, item_text: nome });
        }
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Todos',
            unSelectAllText: 'Desmarcar todos',
            itemsShowLimit: 3,
            allowSearchFilter: true,
            searchPlaceholderText: 'Pesquisar'
        };
        this.dropdownSettingsDois = {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Todos',
            unSelectAllText: 'Desmarcar todos',
            itemsShowLimit: 3,
            allowSearchFilter: true,
            searchPlaceholderText: 'Pesquisar'
        };
        this.showMotoristas = true;
    }
}
