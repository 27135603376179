import { Company } from "./company";
import { IDEntity } from "./IDEntity";

export class IdentificacaoOperador extends IDEntity{
    descricao:String;
    empresaId:Company;
    idUnidade:number;
    listaMotoristas:String;
    listaVeiculos:String;
    listaOperadores:String;
    quantidadeOperadorMotorista:number;
    quantidadDeVeiculos:number;
    
}
