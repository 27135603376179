import { CRUDService } from "../base/crud.service";
import { Http } from "@angular/http";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { Paramcfg } from "../../classes/paramcfg";
import { RESTResponseVO } from "../../classes/restresponsevo";

@Injectable()
export class ParamcfgService extends CRUDService<Paramcfg> {
    constructor(http: Http) {
        super(http, environment.baseURL + 'App/v2/web/paramcfg', true);
    }

    getList(group?: string): Promise<RESTResponseVO<Paramcfg[]>> {
        let url: string = this.defaultUrl;
        if (group) {
            url += '/group/' + group;
        }

        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<Paramcfg[]>;
            })
            .catch(this.handleError);
    }
}