import { IDEntity } from './IDEntity';
import { EventoMacros } from './evento-macros';
export class Macros extends IDEntity{
    nome: string;
    empresa:  number;
    dataCriacao: number;
    status: boolean;
    numeroMatricula: string;
    tipo:number;
    listaDeEventos: EventoMacros[];
}
