import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';
import { MatSort, MatTableDataSource, MatDatepicker, MatSelect, MatOption, MatTable } from '@angular/material';
import { DriverService } from 'app/services/crud/driver.service';
import { Driver } from 'app/classes/driver';
import { AuthGuard } from 'app/guards/auth.guard';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ReportService } from 'app/services/report/report.service';
import { UtilService } from 'app/services/utils.service';
import { utils, writeFile } from 'xlsx';
import { array } from '@amcharts/amcharts4/core';
import { PDFService } from 'app/services/pdf.service';
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { EquipamentoPortatilService } from 'app/services/crud/equipamento-portatil.service';
import { RetornoEquipamentoPortatil } from 'app/classes/retorno-equipamento-portatil';
import { AgmMarker } from '@agm/core';
declare const google: any;

@Component({
  selector: 'app-detalhes-rastreador-portatil',
  templateUrl: './detalhes-rastreador-portatil.component.html',
  styleUrls: ['./detalhes-rastreador-portatil.component.css']
})
export class DetalhesRastreadorPortatilComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public lat = -19.912998;
  public lng = -43.940933;
  public mapa: any;
  public zoomMapa = 10;

  public placaControl = new FormControl();
  public filtroControl = new FormControl('placa');
  public velMinControl = new FormControl();
  public velMaxControl = new FormControl();
  public imeiControl = new FormControl();
  public periodoInicial: Date;
  public periodoFinal: Date;
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[] = ['ignicao', 'motorista', 'imei', 'placa', 'bateria', 'dataHora', 'velocidade', 'endereco', 'local'];
  public placaList: any[] = [];
  public originalPlacaList: any[];
  public empresaId: number;
  public unidadeId: number;
  public idCliente: number;
  public unidadeName: string;
  public dataToExport: any[] = [];
  public showResult: boolean = false;
  public loading: boolean = false;
  public selectedVeiculos: any[] = [];
  public dataRastreadorPortatil: any[];
  public listaApelidosOriginal: any[];
  public listaApelidos: any[] = [];
  public listaImei: any[] = [];
  public listaImeiOriginal: any[] = [];
  public showFiltroPlaca: boolean = true;
  public showFiltroImei: boolean = false;

  constructor(private driverService: DriverService, private authGuard: AuthGuard, private veiculoService: VeiculoService,
    private datePipe: DatePipe, private reportService: ReportService, private equipamentoPortatilService: EquipamentoPortatilService, private pdfService: PDFService,) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.unidadeId = this.authGuard.loggedUser().selectedBranch.id;
    this.empresaId = this.authGuard.loggedUser().selectedCompany.id;
    this.unidadeName = this.authGuard.loggedUser().selectedBranch.name;
    this.idCliente = this.authGuard.loggedUser().id;

    this.getApelidosEquipamentoPortatil();
  }

  getApelidosEquipamentoPortatil() {
    this.equipamentoPortatilService.getLista().then(response => {
      this.dataRastreadorPortatil = response.listaEquipamentoPortatil ? response.listaEquipamentoPortatil : [];
      this.listaApelidos = this.dataRastreadorPortatil.map((item) => { return { apelido: item.apelido } });      
      this.listaApelidos = this.listaApelidos.filter((item) => item.apelido !== "");
      const numberAndLetter = /^(?=.*[a-zA-Z])(?=.*\d).+$/;
      this.listaApelidos = this.listaApelidos.filter((item) => numberAndLetter.test(item.apelido));
      this.listaApelidosOriginal = this.listaApelidos;
      this.listaImei = this.dataRastreadorPortatil.map((item) => { return { imei: item.imei } });
      this.listaImeiOriginal = this.listaImei;
    });
  }

  disableExecutarButton() {
    return this.periodoInicial === undefined || this.periodoFinal === undefined || this.placaControl === undefined;
  }

  showExportsButtons() {
    return this.dataToExport.length > 0;
  }

  selecionarTodos(matSelect: MatSelect) {
    const isSelected: boolean = matSelect.options
      .filter((item: MatOption) => item.value === 0)
      .map((item: MatOption) => item.selected)
    [0];

    if (isSelected) {
      matSelect.options.forEach((item: MatOption) => item.select());
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  exportExcel() {
    if (this.dataToExport.length > 0) {
      const data = this.dataToExport.map((item) => {
        return {
          Motorista: item.motorista,
          Placa: item.placa,
          IMEI: item.imei,
          Bateria: item.bateria,
          Ignicao: item.ignicao,
          DataHora: item.dataHora,
          Velocidade: item.velocidade,
          Endereco: item.endereco,
          Local: item.local,
        }
      });
      const ws = utils.json_to_sheet(data);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'Relatório Percurso e Velocidade');
      return writeFile(wb, `RelatorioPercursoVelocidadeRastreadorPortatil${moment(this.periodoInicial).format(`DDMMYYYYHHmm`)}/${moment(this.periodoFinal).format(`DDMMYYYYHHmm`)}.xlsx`);
    }
  }

  createHeader(
    name: string,
    dataIndex?: number,
    canSort?: boolean,
    colspan?: number,
    css?: string,
    tooltip?: string
  ) {
    if (canSort == null) canSort = false;
    if (colspan == null) colspan = 1;
    if (dataIndex == null) dataIndex = -1;
    if (css == null) css = 'title-left';
    if (tooltip == null) tooltip = '';

    return {
      name: [name],
      canSort: canSort,
      colspan: colspan,
      dataIndex: dataIndex,
      css: css,
      tooltip: tooltip
    };
  }

  exportPrint() {
    const data = this.dataToExport.map((item) => {
      return {
        motorista: item.motorista,
        placa: item.placa,
        imei: item.imei,
        bateria: item.bateria,
        ignicao: item.ignicao,
        dataHora: item.dataHora,
        velocidade: item.velocidade,
        endereco: item.endereco,
        local: item.local,
      }
    });
    const dataFields = Object.keys(data[0]).map((field) => ({
      name: field,
      prefix: '',
      separator: "",
      sufix: "",
      type: "string",
    }));
    const headers = [
      [
        this.createHeader("Motorista", 0, true),
        this.createHeader("Placa", 1, true),
        this.createHeader("IMEI", 2, true),
        this.createHeader("Bateria", 3, true),
        this.createHeader("Ignição", 4, true),
        this.createHeader("Data/Hora", 5, true),
        this.createHeader("Velocidade", 6, true),
        this.createHeader("Endereço", 7, true),
        this.createHeader("Local", 8, true),
      ]
    ];
    const pageOrientation = 'landscape';
    this.pdfService.printList(
      data,
      dataFields,
      headers,
      pageOrientation
    );
  }

  obterCorChave(row: any) {
    if (row.ignicao === 'A') {
      return '#268917';
    } else {
      return '#cc3632';
    }
  }

  obterToolTip(row: any) {
    if (row.ignicao === 'A') {
      return 'Ligado';
    } else {
      return 'Desligado';
    }
  }

  onMapReady(item: any) {
    this.mapa = item;
  }

  exibirNoMapa(row) {
    this.lat = row.latitude;
    this.lng = row.longitude;
    const center = new google.maps.LatLng(this.lat, this.lng);
    this.mapa.panTo(center);
    this.mapa.setZoom(14);
  }

  setarFiltro() {
    if (this.filtroControl.value === 'placa') {
      this.showFiltroImei = false;
      this.showFiltroPlaca = true;
    } else {
      this.showFiltroImei = true;
      this.showFiltroPlaca = false;
    }
  }

  async doSearch() {
    this.dataSource = new MatTableDataSource([]);
    this.dataToExport = [];
    this.loading = true;
    const placas = this.placaControl.value ? this.placaControl.value.filter((placa) => placa !== 0) : [];
    const imeis = this.imeiControl.value ? this.imeiControl.value.filter((num) => num !== 0) : [];
    const searchValues = this.showFiltroPlaca ? placas : imeis;
    const body = {
      dataInicialString: moment(this.periodoInicial).format(`DDMMYYYYHHmmss`),
      dataFinalString: moment(this.periodoFinal).format(`DDMMYYYYHHmmss`),
      idCliente: this.empresaId,
      velocidadeMinima: this.velMinControl.value,
      velocidadeMaxima: this.velMaxControl.value
    };
    if (this.showFiltroImei) {
      body["listaImei"] = searchValues;
    }
    if (this.showFiltroPlaca) {
      body["listaDePlacas"] = searchValues;
    }
    const bodyEncoded = encodeURIComponent(JSON.stringify(body));
    this.reportService.RelatorioPercursoVelocidadeRastreadorPortatil(bodyEncoded)
      .then(response => {
        const parsed = JSON.parse(response._body)
        if (parsed.codigoRetorno === 110) {
          const listaInformacaoRelatorio = parsed.informacaoRelatorio.listaInformacaoRelatorio ? parsed.informacaoRelatorio.listaInformacaoRelatorio : [];
          const tableData = listaInformacaoRelatorio.map((item) => {
            const imeiCampo = item.campo12;
            const bateriaCampo = item.campo13;
            return {
              motorista: item.campo09 === '-1' ? 'Sem motorista associado' : item.campo11,
              placa: item.campo14,
              ignicao: item.campo01,
              dataHora: item.campo02,
              velocidade: `${item.campo03} km/h`,
              endereco: item.campo04,
              local: item.campo08,
              latitude: item.campo05,
              longitude: item.campo06,
              imei: imeiCampo ? imeiCampo : '-',
              bateria: bateriaCampo ? `${bateriaCampo}%` : '-'
            }
          })
          this.dataSource = new MatTableDataSource(tableData);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataToExport = tableData;
        }
        this.loading = false;
      })

  }
}