import { Http, Headers } from "@angular/http";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { AuthGuard } from "../../guards/auth.guard";
import { User } from '../../classes/user';
import { RetornoRankingMototrista } from "app/classes/retorno-ranking-mototrista";
import { Filtro } from "app/classes/filtro";
import { RESTResponseVO } from "app/classes/restresponsevo";

@Injectable({
  providedIn: 'root'
})
export class RankingMotoristaService {
  protected headers = new Headers({ 'Content-Type': 'application/json' });
  public loggedUser: User;
  public listURL: string;
  public company: number;
  constructor(public http: Http, public authGuard: AuthGuard) {
    this.loggedUser = authGuard.loggedUser();
    this.company = this.loggedUser.selectedCompany.id;
    this.listURL = environment.baseURL + "App/v2/web/rankingmotoristas/"
  }


  getResumo() {
    var branch: string = this.loggedUser.selectedBranch.id === 0 && '0' || this.loggedUser.selectedBranch.id.toString();
    const url = `${this.listURL}/resumoRanking/${this.company}` + "/" + branch;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        return response.json() as RetornoRankingMototrista;
      })
      .catch(this.handleError);
  }


  getPontuacaoAvancada(filtro: Filtro) {
    try {
      var branch: string = this.loggedUser.selectedBranch.id === 0 && '0' || this.loggedUser.selectedBranch.id.toString();
      const url = `${this.listURL}/buscarPontuacaoAvancada`;
      filtro.idCliente = this.company;
      filtro.idUnidade = Number(branch);
      var json = JSON.stringify(filtro);
      return this.http
        .post(url, json, { headers: this.headers })
        .toPromise()
        .then(response => {
          var retorno: RetornoRankingMototrista = response.json() as RetornoRankingMototrista;
          if (retorno) {
            return retorno;
          }
        }).catch(this.handleError);
    } catch (error) {
      console.error(error);
    }
  }

  getTempoFaixa(filtro: Filtro) {
    try {
      var branch: string = this.loggedUser.selectedBranch.id === 0 && '0' || this.loggedUser.selectedBranch.id.toString();
      const url = `${this.listURL}/buscarTempoFaixa`;
      filtro.idCliente = this.company;
      filtro.idUnidade = Number(branch);
      var json = JSON.stringify(filtro);
      return this.http
        .post(url, json, { headers: this.headers })
        .toPromise()
        .then(response => {
          var retorno: RetornoRankingMototrista = response.json() as RetornoRankingMototrista;
          if (retorno) {
            return retorno;
          }
        }).catch(this.handleError);
    } catch (error) {
      console.error(error);
    }
  }

  getRankingMotorista(idMotorista: number) {
    const url = `${this.listURL}/buscarRankingMotorista/${idMotorista}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        return response.json() as RetornoRankingMototrista;
      })
      .catch(this.handleError);
  }


  enviarRequisicao(filtro: Filtro, url: string): Promise<RESTResponseVO<RetornoRankingMototrista>> {
    try {
      return this.http
        .post(url, JSON.stringify(filtro), { headers: this.headers })
        .toPromise()
        .then(response => {
          var retorno: RetornoRankingMototrista = response.json() as RetornoRankingMototrista;
          return retorno;
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }



  private handleError(error: any): Promise<any> {
    console.error('Ocorreu um erro: ', error);
    return Promise.reject(error.message || error);
  }
}
