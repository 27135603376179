import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-map-motorista-details',
  templateUrl: './modal-registro-alteracoes.component.html',
  styleUrls: ['./modal-registro-alteracoes.component.css']
})
export class ModalRegistroAlteracoes implements OnInit {

  @Input() public dados: any;
  titulo: string;

  displayedColumns: string[] = [
    'local',
    'dataInicio',
    'dataFinal',
    'tempoTotal',
    'latitude',
    'longitude',
  ];
  dataSource: MatTableDataSource<any>;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private paginator: MatPaginatorIntl
  ) { }

  ngOnInit(): void {
    // this.dataSource = this.dados.listaDetalhePeriodoParado.map((dado) => {
    //   if (dado.endereco && dado.local) {
    //     return {
    //       local: `${dado.endereco} - ${dado.local}`,
    //       dataInicio: dado.dataInicio,
    //       dataFinal: dado.dataFinal,
    //       tempoTotal: dado.tempoTotal,
    //       latitude: dado.latitude,
    //       longitude: dado.longitude
    //     }
    //   } else if (dado.local && !dado.endereco) {
    //     return {
    //       local: dado.local,
    //       dataInicio: dado.dataInicio,
    //       dataFinal: dado.dataFinal,
    //       tempoTotal: dado.tempoTotal,
    //       latitude: dado.latitude,
    //       longitude: dado.longitude
    //     }
    //   } else if (dado.endereco && !dado.local) {
    //     return {
    //       local: dado.endereco,
    //       dataInicio: dado.dataInicio,
    //       dataFinal: dado.dataFinal,
    //       tempoTotal: dado.tempoTotal,
    //       latitude: dado.latitude,
    //       longitude: dado.longitude
    //     }
    //   } else {
    //     return {
    //       local: 'Sem registros',
    //       dataInicio: dado.dataInicio,
    //       dataFinal: dado.dataFinal,
    //       tempoTotal: dado.tempoTotal,
    //       latitude: dado.latitude,
    //       longitude: dado.longitude
    //     }
    //   }
    // });
    // this.titulo = `Detalhes do evento - ${this.dados.placa}`;
  }

  redirecionar(row) {
    // if (row.latitude && row.longitude) {
    //   const latitude = row.latitude;
    //   const longitude = row.longitude;
    //   return window.open(
    //     'http://maps.google.com/maps?q=' + latitude + ',' + longitude,
    //     '_blank'
    //   );
    // }
  }

  close() {
    this.activeModal.close();
  }
}
