import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormGroup
} from '@angular/forms';
import { forwardRef, Component, Input } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { City } from '../../../classes/city';
import { CityService } from '../../../services/crud/city.service';
import { CountryState } from '../../../classes/country-state';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'address-input',
  template: `
  <div *ngIf="obj">
        <input type="hidden" id="addressId" name="addressId" [(ngModel)]="obj.id" />
        <div class="row form-group">
            <label class="col-sm-3 form-control-label" for="logradouro">Logradouro</label>
            <div class="col-sm-9">
                <input class="form-control form-control-sm" type="text" id="logradouro" name="logradouro" placeholder="Logradouro" required [(ngModel)]="obj.logradouro" />
            </div>
        </div>
        <div class="row form-group">
            <label class="col-sm-3 form-control-label" for="numero">Número</label>
            <div class="col-sm-9">
                <input class="form-control form-control-sm" type="text" id="numero" name="numero" placeholder="Número" required [(ngModel)]="obj.numero" />
            </div>
        </div>
        <div class="row form-group">
            <label class="col-sm-3 form-control-label" for="complemento">Complemento</label>
            <div class="col-sm-9">
                <input class="form-control form-control-sm" type="text" id="complemento" name="complemento" placeholder="Complemento" [(ngModel)]="obj.complemento" />
            </div>
        </div>
        <div class="row form-group">
            <label class="col-sm-3 form-control-label" for="estado">Estado</label>
            <div class="col-sm-9">
                <select-input name="stateSelect" [(ngModel)]="state" [getDisplayText]="stateTemplate" [objList]="stateList" [compareWith]="stateCompare"></select-input>
            </div>
        </div>
        <div class="row form-group">
            <label class="col-sm-3 form-control-label" for="cidade">Cidade</label>
            <div class="col-sm-9">
                <select-input name="citySelect" [(ngModel)]="obj.cidade" [getDisplayText]="cityTemplate" [objList]="cityList" [compareWith]="compareWith"></select-input>
                <!--<autocomplete-input name="cityAutoComplete" [(ngModel)]="obj.cidade" [inputFormatter]="cityFormatter" [typeahead]="searchCity" [getDisplayText]="cityTemplate"></autocomplete-input>-->
            </div>
        </div>
        <div class="row form-group">
            <label class="col-sm-3 form-control-label" for="bairro">Bairro</label>
            <div class="col-sm-9">
                <input class="form-control form-control-sm" type="text" id="bairro" name="bairro" placeholder="Bairro" [(ngModel)]="obj.bairro" />
            </div>
        </div>
        <div class="row form-group">
            <label class="col-sm-3 form-control-label" for="cep">CEP</label>
            <div class="col-sm-9">
                <input class="form-control form-control-sm" type="text" id="cep" name="cep" placeholder="CEP" [(ngModel)]="obj.cep" />
            </div>
        </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressInputComponent),
      multi: true
    }
  ]
})
export class AddressInputComponent implements ControlValueAccessor {
  @Input('obj') _obj: any;

  @Input()
  public form: FormGroup;

  get obj() {
    return this._obj;
  }

  set obj(val) {
    this._obj = val;
    if (val && val.cidade) {
      this.state = val.cidade.ufCodIbge;
    }
    this.propagateChange(this._obj);
  }

  isStateUndefined(state: any) {
    return isNullOrUndefined(state);
  }

  propagateChange = (_: any) => {};

  writeValue(obj: any): void {
    this.obj = obj;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {}

  constructor(private cityService: CityService) {
    this.stateList = new Array();
    this.originalCityList = new Array();
    var ids = [];

    this.cityService.getList().then(response => {
      this.originalCityList = response.response;
      this.originalCityList.forEach(city => {
        if (!ids.includes(city.ufCodIbge.sigla)) {
          this.stateList.push(city.ufCodIbge);
          ids.push(city.ufCodIbge.sigla);
        }
      });

      this.stateList.sort((s1, s2) => s1.sigla.localeCompare(s2.sigla));
    });
  }

  // City
  originalCityList: City[];
  stateList: CountryState[];
  state: CountryState;

  get cityList() {
    if (this.state) {
      return this.originalCityList.filter(
        c => c.ufCodIbge.sigla === this.state.sigla
      );
    } else {
      return [];
    }
  }

  cityFormatter = (x: City) => x.nome;

  searchCity = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map(term =>
        term.length < 2
          ? []
          : this.cityList
              .filter(
                v => v.nome.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
              .slice(0, 10)
      );

  cityTemplate(r) {
    return r.ufCodIbge.sigla + ' - ' + r.nome;
  }

  stateTemplate(s) {
    return s.sigla + ' - ' + s.nome;
  }

  compareWith(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.codIbge === c2.codIbge : c1 === c2;
  }

  stateCompare(s1: any, s2: any): boolean {
    return s1 && s2 ? s1.sigla === s2.sigla : s1 === s2;
  }
}
