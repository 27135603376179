import { Pipe, PipeTransform } from "@angular/core";
import { UtilService } from "../../services/utils.service";

@Pipe({
    name: "convertToHour"
})
export class ConvertToHourPipe implements PipeTransform {
    constructor(private utils: UtilService) {}

    transform(value: number, ...args: any[]) {
        return this.utils.secondsToHHmm(value);
    }
}