import { IDEntity } from "./IDEntity";
export class Motorista extends IDEntity{
    id: number;
    nome: string;
    sobrenome: string;
    dataNascimento: Date;
    sexo: string;
    cpf: string; 
    numeroCnh: string;
    validadeCnh: Date;
    dataAdmissao: Date;
    categoriaCnh: string;
    telefone: string;
    unidadesId: number;
    turno: string;
    escala: string;
    status: string;
    parada: string;
    acimaDuasHorasExtras: string;
    acimaQuatroHorasExtras: string;
}
