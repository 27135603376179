import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { GrupoLocais } from 'app/classes/grupoLocais';
import { Local } from 'app/classes/local';
import { User } from 'app/classes/user';
import { AuthGuard } from 'app/guards/auth.guard';
import { GrupoLocaisService } from 'app/services/crud/grupo-locais.service';
import { LocalService } from 'app/services/crud/local.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-filtro-grupos-e-locais',
  templateUrl: './filtro-grupos-e-locais.component.html',
  styleUrls: ['./filtro-grupos-e-locais.component.scss']
})
export class FiltroGrupoELocaisComponent implements OnInit, OnDestroy {
  @Output()
  public onPesquisar = new EventEmitter<any>();

  locaisCarregados: Local[];
  grupoLocaisCarregados: GrupoLocais[];
  unidades: any[];
  exibirFiltros: Boolean = true;
  loggedUser: User;
  _onDestroy = new Subject<void>();

  formFiltro = this.fb.group({
    idUnidade: [0],
    dataInicio: [null],
    datafim: [null],
    idGrupoDeLocais: [0],
    idLocal: [0]
  });

  constructor(private locaisService: LocalService, private grupoLocaisService: GrupoLocaisService, private authGuard: AuthGuard, private fb: FormBuilder) {
  }

  ngOnInit() {
    this.locaisCarregados = [];
    this.grupoLocaisCarregados = [];
    this.unidades = [];

    this.loggedUser = this.authGuard.loggedUser();

    this.buscarLocais();
    this.buscarGrupoLocais();
    this.obterUnidades();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  buscarGrupoLocais() {
    var ret: Promise<any> = this.grupoLocaisService.getLista();
    ret.then(res => {
      this.grupoLocaisCarregados = res.listaDeGrupoLocais;
    })
  }

  buscarLocais() {
    var ret: Promise<any> = this.locaisService.getLista();
    ret.then(res => {
      this.locaisCarregados = res.listaDeLocal;
    })
  }

  obterUnidades() {
    if (this.loggedUser.selectedCompany) {
      this.unidades = this.loggedUser.branchMap[this.loggedUser.selectedCompany.name];
    }
  }

  escodeOuExibeDiv() {
    this.exibirFiltros = !this.exibirFiltros;
  }

  pesquisar() {
    this.onPesquisar.emit(this.formFiltro.value);
  }
}
