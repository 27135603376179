import {
  Component,
  OnInit,
  AfterViewInit,
  ViewEncapsulation
} from '@angular/core';
import { UtilService } from '../../../services/utils.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { PDFService } from '../../../services/pdf.service';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { AuthGuard } from '../../../guards/auth.guard';
declare var $: any;

@Component({
  selector: 'app-base-list',
  templateUrl: './base-list.component.html',
  styleUrls: ['./base-list.component.css']
})
export class BaseListComponent implements AfterViewInit {
  pageTitle: string = '';
  searchPlaceholder: string = '';
  searchText: string = '';
  formPath: string = '';

  sortType: string;
  sortReverse: boolean = false;

  allowDelete: boolean = true;
  allowInsert: boolean = true;

  tableHeaders: {
    name: string[];
    canSort: boolean;
    colspan: number;
    dataIndex?: number;
  }[][];
  dataFields: {
    name: string;
    type: string;
    sufix?: string;
    separator?: string;
  }[];

  data: any[];
  originalData: any[];

  errorMessage: string;
  successMessage: string;
  infoMessage: string;
  loading: boolean;

  pageOrientation: string = 'portrait';
  searchField: string;

  constructor(
    private u: UtilService,
    protected pdfService: PDFService,
    private ag: AuthGuard
  ) {
    this.loading = false;
  }

  public isAdmin() {
    return this.ag.isAdmin();
  }

  resetMessages() {
    this.errorMessage = undefined;
    this.infoMessage = undefined;
    this.successMessage = undefined;
  }

  createHeader(
    name: string,
    dataIndex?: number,
    canSort?: boolean,
    colspan?: number,
    css?: string,
    tooltip?: string
  ) {
    if (canSort == null) canSort = false;
    if (colspan == null) colspan = 1;
    if (dataIndex == null) dataIndex = -1;
    if (css == null) css = 'title-left';
    if (tooltip == null) tooltip = '';

    return {
      name: [name],
      canSort: canSort,
      colspan: colspan,
      dataIndex: dataIndex,
      css: css,
      tooltip: tooltip
    };
  }

  createHeaderMultipleLine(
    name: string[],
    dataIndex?: number,
    canSort?: boolean,
    colspan?: number,
    css?: string
  ) {
    if (canSort == null) canSort = false;
    if (colspan == null) colspan = 1;
    if (dataIndex == null) dataIndex = -1;
    if (css == null) css = 'title-left';

    return {
      name: name,
      canSort: canSort,
      colspan: colspan,
      dataIndex: dataIndex,
      css: css
    };
  }

  createField(
    name: string,
    type: string,
    prefix?: string,
    sufix?: string,
    separator?: string
  ) {
    if (!prefix) prefix = '';
    if (!sufix) sufix = '';
    if (!separator) separator = '';

    return {
      name: name,
      prefix: prefix,
      sufix: sufix,
      separator: separator,
      type: type
    };
  }

  ngAfterViewInit(): void {
    moment.locale('pt-br');
    // Workaround to get the icons to show after logoff
    $.getScript('./assets/js/iconutil.js');
  }

  search() {
    this.loading = true;

    if (this.originalData === undefined) {
      this.originalData = this.data;
    }

    if (this.searchText === undefined || this.searchText === '') {
      this.data = this.originalData;
    } else {
      this.data = this.originalData.filter(obj => {
        let found = false;
        this.dataFields.forEach(field => {
          if (!found) {
            let final = this.u.getValue(obj, field.name, '');
            if (field.sufix) {
              let sufix = this.u.getValue(obj, field.sufix, '');
              if (sufix) {
                final += field.separator + sufix;
              }
            }
            if (final) {
              found =
                String(final)
                  .toLowerCase()
                  .indexOf(this.searchText.toLowerCase()) !== -1;
            }
          }
        });
        return found;
      });
    }

    this.loading = false;
  }

  isSort(titulo: {
    name: string;
    canSort: boolean;
    colspan: number;
    dataIndex: number;
  }): boolean {
    if (titulo.canSort) {
      try {
        return this.sortType === this.dataFields[titulo.dataIndex].name;
      } catch (error) {
        return false;
      }
      
    }
    return false;
  }

  sortTable(titulo: {
    name: string;
    canSort: boolean;
    colspan: number;
    dataIndex: number;
  }) {
    var newSort: string = this.dataFields[titulo.dataIndex].name;
    if (this.sortType === newSort) {
      this.sortReverse = !this.sortReverse;
    }

    this.sortType = newSort;
  }

  getValueByName(row: any, field: any): any {
    if (!row) return '';

    if (field.type === 'date') {
      let value = this.u.getValue(row, field.name, '');
      return this.u.formatDate(value, 'DD/MM/YYYY');
    }  if (field.type === 'dateFromTime') {
      let value = this.u.getValue(row, field.name, '');
      if(!value) {
        return null
      }
      return this.u.formatDate(new Date(value));
    } else if (field.type === 'boolean') {
      let value = this.u.getValue(row, field.name, false);
      if (value) {
        return "<span class='fa fa-check'></span>";
      } else {
        return "<span class='fa fa-times'></span>";
      }
    } else if (field.type === 'datetime') {
      let value = this.u.getValue(row, field.name, '');
      return this.u.formatDate(value);
    } else if (field.type === 'month') {
      let value = this.u.getValue(row, field.name, '');
      return this.u.getMonth(value);
    } else if (field.type === 'time') {
      let value = this.u.getValue(row, field.name, '');
      return this.u.miliToHHmm(value);
    } else if (field.type === 'treatable') {
      let value = this.u.getValue(row, field.name, '');
      if (!value) {
        return 'Não tratável';
      } else {
        return 'Tratável';
      }
    } else if (field.type === 'location') {
      let value = this.u.getValue(row, field.name, '');
      if (!value) {
        return 'Não cadastrado';
      }
      return value;
    } else if(field.type === 'distance') {
      let value = this.u.getValue(row, field.name, '');
      if (!value) {
        return '';
      }
      return `${Math.round(value)} metros`;
    }
    else {
      let final: string;
      final = this.u.getValue(row, field.name, '');
      if (field.prefix) {
        let prefix = this.u.getValue(row, field.prefix, '');
        if (prefix) {
          final = prefix + field.separator + final;
        }
      }
      if (field.sufix) {
        let sufix = this.u.getValue(row, field.sufix, '');
        if (sufix) {
          final += field.separator + sufix;
        }
      }
      return final;
    }
  }

  printList() {
    let headers = Object.assign([], this.tableHeaders);
    this.pdfService.printList(
      this.data,
      this.dataFields,
      headers,
      this.pageOrientation
    );
  }

  openMap(row) {
    if (row.latitude && row.longitude) {
      window.open(
        'http://maps.google.com/maps?q=' + row.latitude + ',' + row.longitude,
        '_blank'
      );
    }
  }

  checkFirst(i) {
    return i > 1;
  }
}
