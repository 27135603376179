import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { BaseListComponent } from '../../../base/base-list/base-list.component';

import { LoadingUnloadingPointsService } from '../../../../services/crud/loading-unloading-points.service';
import { UtilService } from '../../../../services/utils.service';
import { PDFService } from '../../../../services/pdf.service';
import { AuthGuard } from '../../../../guards/auth.guard';

@Component({
    templateUrl: './list.component.html',
    styleUrls: [ '../../../base/base-list/base-list.component.css' ],
    encapsulation: ViewEncapsulation.None
})
export class LoadingUnloadingPointListComponent extends BaseListComponent {

    public status: any;

    constructor(private loadingService: LoadingUnloadingPointsService,
        private utilService: UtilService,
        pdfService: PDFService,
        authGuard: AuthGuard) {
        super(utilService, pdfService, authGuard);
        this.searchField = "pontosDeEsperaId.identificacao";
    }

    ngOnInit() {
        this.pageTitle = "Cadastros | Pontos de Carga e Descarga";
        this.formPath = "/adm/loadingunloadingpoints/form";

        this.tableHeaders = [
            [
                this.createHeader("Identificação", 0, true),
                this.createHeader("Permite descanso", 1, true),
                this.createHeader("Logradouro", 2, true),
                this.createHeader("Número", 3, true),
                this.createHeader("Cidade", 4, true),
                this.createHeader("UF", 5, true),
                this.createHeader("Telefone", 6, true),
                this.createHeader("Responsável", 7, true),
                this.createHeader("Raio localidade", 8, true)
            ]
        ];

        this.dataFields = [
            this.createField("pontosDeEsperaId.identificacao", "string"),
            this.createField("pontosDeEsperaId.permiteDescanso", "boolean"),
            this.createField("pontosDeEsperaId.endereco.logradouro", "string"),
            this.createField("pontosDeEsperaId.endereco.numero", "string"),
            this.createField("pontosDeEsperaId.endereco.cidade.nome", "string"),
            this.createField("pontosDeEsperaId.endereco.cidade.ufCodIbge.sigla", "string"),
            this.createField("telefone", "string"),
            this.createField("pessoaResponsavel", "string"),
            this.createField("raioLocalidade", "string")
        ];

        this.loadingService.getListByCompany().then(response => {
            this.data = response.response;
            this.loading = false;
        });
    }

    search() {
        this.loading = true;
    
        if (this.originalData === undefined) {
          this.originalData = this.data;
        }
    
        if (this.searchText === undefined || this.searchText === '') {
            if (this.status) {
                this.data = this.originalData.filter(obj => obj.status === this.status);
            } else {
                this.data = this.originalData;
            }
        } else {
          this.data = this.originalData.filter(obj => {
            return obj.pontosDeEsperaId.identificacao.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1;
          });
          if (this.status) {
            this.data = this.data.filter(obj => obj.status === this.status);
          }
        }
    
        this.loading = false;
      }
}