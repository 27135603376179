import { IDEntity } from './IDEntity';
import { City } from './city';

export class Address extends IDEntity {
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: City;
  cep: string;
}
