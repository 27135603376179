import { IDEntity } from './IDEntity';
export class TipoRanking extends IDEntity{
    dataCadastro:Date;
    titulo:String;
    descricao:String;
    cliente:number;
    unidade:number;
    tipo:number;
    maximoEventoMinimo:number;
    maximoEventoMedio:number;
    maximoEventomaximoAcimaDe:number;
    status:boolean;

}
