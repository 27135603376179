import { Component} from '@angular/core';

@Component({
  selector: 'sensor-temperatura',
  templateUrl: './sensor-temperatura.html',
  styleUrls: ['./sensor-temperatura.scss']
})
export class SensorTemperatura {
  constructor() {}

  clickNoBotaoTeste() {
  } 

}