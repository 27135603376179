import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { environment } from "../../../environments/environment";
import { RESTResponseVO } from 'app/classes/restresponsevo';
import { TaskLog } from 'app/classes/task-log';


@Injectable({
  providedIn: 'root'
})
export class TaskLogsService {

    defaultUrl: string;
    headers = new Headers({ 'Content-Type': 'application/json' });
    
    constructor(private http: Http) {
        this.defaultUrl = environment.baseUrlNovaDashboard + 'app/v1/dashboard';
    }

    getListaTaskLogs(dataInicio: number, dataFim: number, status: string): Promise<RESTResponseVO<Array<TaskLog>>> {
      const url: string = environment.baseURL + 'App/v2/web/tasklogs/buscarTaskLogs/' + dataInicio + '/' + dataFim + '/' + status;
      return this.http
          .get(url)
          .toPromise()
          .then(response => {
              return response.json() as RESTResponseVO<Array<TaskLog>>;
          })
          .catch(this.handleError);
    }

    reenviarTaskLogs(id: number): Promise<RESTResponseVO<boolean>> {
      const url: string = environment.baseURL + 'App/v2/web/tasklogs/reenviarFila/' + id;
      return this.http
          .get(url)
          .toPromise()
          .then(response => {
              return response.json() as RESTResponseVO<boolean>;
          })
          .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
      console.error('Ocorreu um erro: ', error);
      return Promise.reject(error.message || error);
  }

}
