import { Pipe, PipeTransform } from "@angular/core";
import { UtilService } from "../../services/utils.service";

@Pipe({
    name: "DayOfWeek"
})
export class DayOfWeekPipe implements PipeTransform {
    constructor(private utils: UtilService) {}

    transform(value: string) {
        return this.utils.getDayOfWeek(value);
    }
}