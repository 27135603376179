import { CRUDService } from "../base/crud.service";
import { Equipamento } from "../../classes/equipamento";
import { Http } from "@angular/http";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { AuthGuard } from "../../guards/auth.guard";
import { RetornoEquipamento } from "../../classes/retorno-equipamento";
import { RESTResponseVO } from "app/classes/restresponsevo";
import { User } from '../../classes/user';
import { OrigemEquipamento } from "../../classes/origem-equipamento";
import { Company } from "app/classes/company";

 
@Injectable({
  providedIn: 'root'
})
export class EquipamentoService extends CRUDService<Equipamento> {
  public company: number;
  constructor(http: Http, public authGuard: AuthGuard) {
    super(http, environment.baseURL + 'App/v2/web/equipamento', true);
    this.listURL = environment.baseURL + "App/v2/web/equipamento/listarEquipamentos";
    var loggedUser: User = authGuard.loggedUser();
    this.company = loggedUser.selectedCompany.id;
  } 
 
  getLista() {
    const url: string =
      (this.listURL === undefined && `${this.defaultUrl}`) || `${this.listURL}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoEquipamento;
        }
      })
      .catch(this.handleError);
  }


  getById(id: number) {
    const url = `${this.defaultUrl}/buscarEquipamentoPeloCodigo/${id}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoEquipamento;
        }
      })
      .catch(this.handleError);
  }


  cadastrarEquipamento(equipamento: Equipamento): Promise<RESTResponseVO<Equipamento>> {
    const url = `${this.defaultUrl}/cadastrarEquipamento`;
    var empresa: Company = new Company();
    empresa.id = this.company;
    equipamento.empresa = empresa;
    return this.enviarRequisicao(equipamento, url);
  }

  editarEquipamento(equipamento: Equipamento): Promise<RESTResponseVO<Equipamento>> {
    const url = `${this.defaultUrl}/editarEquipamento`;
    var empresa: Company = new Company();
    empresa.id = this.company;
    equipamento.empresa = empresa;
    return this.enviarRequisicao(equipamento, url);
  }

  enviarRequisicao(equipamento: Equipamento, url: string): Promise<RESTResponseVO<Equipamento>> {
    try {
      return this.http
        .post(url, JSON.stringify(equipamento), { headers: this.headers })
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            var retorno: RetornoEquipamento = response.json() as RetornoEquipamento;
            var equipamento: Equipamento = retorno.equipamento;
            if (equipamento === null) {
              equipamento = new Equipamento();
            }
            equipamento.id = 0;
            return equipamento;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }

  getOrigemEquipamento(): OrigemEquipamento[]{
    let listaOrigemEquipamentos =[
      new OrigemEquipamento("PR", "Proprietario"),
      new OrigemEquipamento("PA", "Particular"),
      new OrigemEquipamento("AL", "Alugado"),
    ];
    return listaOrigemEquipamentos;
  }

} 
