import { BaseListComponent } from "../../../base/base-list/base-list.component";
import { Component } from "@angular/core";
import { UtilService } from "../../../../services/utils.service";
import { PDFService } from "../../../../services/pdf.service";
import { ParamcfgService } from "../../../../services/crud/paramcfg.service";
import { AuthGuard } from "../../../../guards/auth.guard";

@Component({
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.css']
})
export class ParamcfgListComponent extends BaseListComponent {
    constructor(private service: ParamcfgService,
        private utilService: UtilService,
        pdfService: PDFService,
        authGuard: AuthGuard) {
        super(utilService, pdfService, authGuard);
        this.searchField = "";
    }

    public searchObject: { group: string } = { group: undefined };

    ngOnInit() {
        this.pageTitle = "Cadastros | Parâmetros";
        this.formPath = "/adm/paramcfg/form";

        this.tableHeaders = [
            [
                this.createHeader("Grupo", 0, true),
                this.createHeader("Parâmetro", 1, true),
                this.createHeader("Empresa", 2, true),
                this.createHeader("Unidade", 3, true),
                this.createHeader("Valor", 4, true)
            ]
        ];

        this.dataFields = [
            this.createField("grcodigo", "string"),
            this.createField("ticodigo", "string"),
            this.createField("empresaId.nome", "string"),
            this.createField("unidadeId.apelido", "string"),
            this.createField("dado", "string")
        ];

        this.loading = false;
    }

    doSearch() {
        this.loading = true;
        this.service.getList(this.searchObject.group).then(response => {
            this.errorMessage = response.error;
            if (!this.errorMessage) {
                this.data = response.response;
            }
            this.loading = false;
        });
    }
}