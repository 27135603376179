import { BaseListComponent } from "../../../base/base-list/base-list.component";
import { Component, ViewEncapsulation } from "@angular/core";
import { UtilService } from "../../../../services/utils.service";
import { PDFService } from "../../../../services/pdf.service";
import { AuthGuard } from "../../../../guards/auth.guard";
import { TipoFuncionarioService } from "app/services/crud/tipo-funcionario.service";

@Component({
  templateUrl: '../../../base/base-list/base-list.component.html',
  styleUrls: ['../../../base/base-list/base-list.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class TipoFuncionarioListComponent extends BaseListComponent {
  branchId: any;
  companyId: any;

  constructor(
    private service: TipoFuncionarioService,
    private utilService: UtilService,
    pdfService: PDFService,
    public authGuard: AuthGuard) {
    super(utilService, pdfService, authGuard);
    this.searchField = "descricao";
    
    this.branchId = this.authGuard.loggedUser().selectedBranch.id;
    this.companyId = this.authGuard.loggedUser().selectedCompany.id;
  }

  ngOnInit() {
    this.pageTitle = "Cadastros | Tipo Funcionario";
    this.formPath = "/adm/tipofuncionario/form";

    this.tableHeaders = [
      [
        this.createHeader("Descrição", 1, true),
      ]
    ];

    this.dataFields = [
      this.createField("descricao", "string"),
    ];

    this.service.getAll(this.companyId, this.branchId).then(response => {
      this.data = response;
      this.loading = false;
    });
  }
}
