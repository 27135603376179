import { Component, OnInit } from '@angular/core';
import {User} from '../../classes/user';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {AuthGuard} from '../../guards/auth.guard';
import { environment } from 'environments/environment.dev';

@Component({
  selector: 'app-new-banco-horas',
  templateUrl: './new-banco-horas.component.html',
})
export class NewBancoHorasComponent implements OnInit {

  empresaAtiva: number;
  loggedUser: User;
  safeUrl: SafeResourceUrl;

  constructor(
    private authGuard: AuthGuard,
    private sanitizer: DomSanitizer
  ) {
    this.loggedUser =  this.authGuard.loggedUser();
    this.empresaAtiva = this.loggedUser.selectedCompany.id;
    const url = `${environment.baseWebUrlV2}${this.loggedUser.id}/banco-horas/${this.empresaAtiva}`;
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit() {

  }

}
