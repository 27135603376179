import { Injectable } from '@angular/core';
import { AuthGuard } from 'app/guards/auth.guard';
import { Headers, Http } from '@angular/http';
import { User } from 'app/classes/user';
import { environment } from '../../environments/environment';
import { RetornoMonitor } from 'app/classes/retorno-monitor';


@Injectable({
  providedIn: 'root'
})
export class MonitoramentoViasatService {

  public loggedUser: User;
  public authGuardM: AuthGuard;
  public company: number;
  private urlPrincial = environment.baseURL + 'App/v2/web/faturamento/';  // URL to web api desenv


  constructor(private http: Http, public authGuard: AuthGuard) {
    this.authGuardM = authGuard;
    this.loggedUser = authGuard.loggedUser();
    this.company = this.loggedUser.selectedCompany.id;
    const branch: string = this.loggedUser.selectedBranch.id === 0 && '0' || this.loggedUser.selectedBranch.id.toString();
  }


  getLista() {
    try {
      this.loggedUser = this.authGuardM.loggedUser();
      this.company = this.loggedUser.selectedCompany.id;
      const branch: string = this.loggedUser.selectedBranch.id === 0 && '0' || this.loggedUser.selectedBranch.id.toString();
      // tslint:disable-next-line:max-line-length
      const url = `${environment.baseURL}App/v2/web/monitorviasat/buscarDados/${this.company}/${branch}`;
      // environment.baseURL + "App/v2/web/monitorviasat/buscarDados/" + this.company + "/" + branch;
      return this.http
        .get(url)
        .toPromise()
        .then(response => {
          return response.json() as RetornoMonitor;
        })
        .catch(this.handleError);
    } catch (error) {
      console.error(error);
    }
  }

  getUrlIframe() {
    try {
      var loggedUser: User = this.authGuard.loggedUser();
      let company = loggedUser.selectedCompany.id;
      let unidade = loggedUser.selectedBranch.id
      const urlDasboard = environment.baseURLMonitoramento + "?idEmpresa=" + company + "&unidade=" + unidade + "&dUsuario="+loggedUser.id;
      console.log(urlDasboard);
      return urlDasboard;
    } catch (error) {
      console.error(error);
    }
  }

  protected handleError(error: any): Promise<any> {
    console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
    return Promise.reject(error.message || error);
  }
}
