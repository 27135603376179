import { Injectable } from "@angular/core";
import * as moment from 'moment';
import 'moment/locale/pt-br';
declare var $: any;

@Injectable()
export class UtilService {

    public fixIcons() {
        $.getScript('./assets/js/iconutil.js');
    }

    public addSeconds(dateTime: string, seconds: number) {
        const date = this.transformDateTimeStringToDate(dateTime);
        return moment(date).locale('pt-br').add(seconds, "seconds").format("DD/MM/YYYY HH:mm:ss");
    }

    public transformDateTimeStringToDate(dateTime: string) {
        // 14/11/2022 07:04:46
        try {
            const [ date, time ] = dateTime.split(" ");
            const [ day, month, year ] = date.split("/");
            const [ hour, min, sec ] = time.split(":");
            
            const isoString = `${year}-${month}-${day}T${hour}:${min}:${sec}`;
            return new Date(isoString);
        } catch(error) {
            console.error("UtilService.transformDateTimeStringToDate: invalid date - the dateTime param must be 'DD/MM/YYYY HH:mm:ss' format.");
        }
    }

    public getValue(obj: any, path: string, defaultValue: any): any {
        if (path.indexOf('.') !== -1) {
            let subPath = path.split('.');
            let subObj = obj[subPath[0]];

            subPath.splice(0, 1);

            if (subObj) {
                return this.getValue(subObj, subPath.join('.'), defaultValue);
            }
        } else if (obj[path]) {
            return obj[path];
        }

        return defaultValue;
    }

    public formatDatetoDatetimeLocalInput(date: Date) {
        return (date.getFullYear().toString() + '-'
            + ("0" + (date.getMonth() + 1)).slice(-2) + '-'
            + ("0" + (date.getDate())).slice(-2))
            + 'T' + date.toTimeString().slice(0, 8);
    }

    public formatDate(value: any, format?: string): string {
        if (value) {
            let finalDate;
            if (typeof value === "string") {
                let pieces = value.split("-");
                finalDate = new Date(Number(pieces[0]), Number(pieces[1]) - 1, Number(pieces[2]));
            } else if (typeof value === "number") {
                finalDate = new Date(value);
            } else if (value instanceof Date) {
                finalDate = value;
            }
            if (format && finalDate) {
                return moment(finalDate).format(format);
            } else {
                return finalDate ? finalDate.toLocaleString("pt-BR") : "";
            }
        }
        return "";
    }

    public now(): number {
        return moment.now();
    }

    public getMonth(value: number): string {
        if (value !== undefined) {
            moment.locale('pt-br');
            return moment(value + 1, 'MM').format('MMMM');
        }
    }

    public HHmmToMili(hhmm: string): number {
        if (!hhmm) return 0;
        let time = [];
        if(hhmm.includes(":")){
            time = hhmm.split(":");
        }else{
           time.push(hhmm.substring(0,2));
           time.push(hhmm.substring(2,4)); 
        }
        let hours = new Number(time[0]).valueOf() * 60 * 60 * 1000;
        let minutes = new Number(time[1]).valueOf() * 60 * 1000;
        
        return hours + minutes;
    }

    public secondsToHHmm(seconds: number): string {
        if (!seconds) return "00:00";

        var hh = Math.floor(seconds / 60 / 60);
        seconds -= hh * 60 * 60;
        var mm = Math.floor(seconds / 60);
        seconds -= mm * 60;

        let HH = hh < 10 ? "0" + hh : hh;
        let MM = mm < 10 ? "0" + mm : mm;

        return HH + ':' + MM;
    }

    public miliToHHmm(mili: number): string {
        if (!mili) return "00:00";

        var hh = Math.floor(mili / 1000 / 60 / 60);
        mili -= hh * 1000 * 60 * 60;
        var mm = Math.floor(mili / 1000 / 60);
        mili -= mm * 1000 * 60;

        let HH = hh < 10 ? "0" + hh : hh;
        let MM = mm < 10 ? "0" + mm : mm;

        return HH + ':' + MM;
    }

    public getDayOfWeek(day: string): string {
        let retorno = '';
        switch (day.toUpperCase()) {
            case 'DOMINGO':
                retorno = 'Domingo'
                break;
            case 'SEGUNDA':
                retorno = 'Segunda'
                break;
            case 'TERCA':
                retorno = 'Terça'
                break;
                case 'QUARTA':
                retorno = 'Quarta'
                break;
                case 'QUINTA':
                retorno = 'Quinta'
                break;
                case 'SEXTA':
                retorno = 'Sexta'
                break;
                case 'SABADO':
                retorno = 'Sábado'
                break;
            
        }  

        return retorno;
    }

    public miliToHHmmss(msec: number): string {
        let formatted = undefined;

        if ((msec) && (msec >= 60000)) {

            let hh = Math.floor(msec / 1000 / 60 / 60);
            msec -= hh * 1000 * 60 * 60;
            let mm = Math.floor(msec / 1000 / 60);
            msec -= mm * 1000 * 60;
            let ss = Math.floor(msec / 1000);
            msec -= ss * 1000;


            let HH = hh < 10 ? "0" + hh : hh;
            let MM = mm < 10 ? "0" + mm : mm;
            let SS = ss < 10 ? "0" + ss : ss;

            formatted = HH + ':' + MM + ':' + SS;
        }

        if (!formatted) formatted = " ";

        return formatted;
    }
}