import { IDEntity } from './IDEntity';

export class GrupoLocais extends IDEntity  {
    
    id: number;
    nome: string;
    empresaId: number;
    tempoMaxLojaBauFechado: string;
    tempoMaxBauAberto: string;
    tempoMaxSemAbrirBau: string;
    tempoMaxPermanenciaLocal: number;

}
