import { Component } from "@angular/core";
import { Motive } from "../../../../classes/motive";
import { BaseFormComponent } from "../../../base/base-form/base-form.component";
import { ActivatedRoute, Router } from "@angular/router";
import { MotiveService } from "../../../../services/crud/motive.service";
import { RESTResponseVO } from "../../../../classes/restresponsevo";
import { AuthGuard } from "../../../../guards/auth.guard"; 

@Component({
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css']
})
export class MotiveFormComponent extends BaseFormComponent<Motive> {
    constructor(private motiveService: MotiveService,
        private authGuard: AuthGuard,
        route: ActivatedRoute,
        router: Router) { super(route, router, 'adm/motive/list'); }

    assembleObjectFromForm(): void {
    }

    loadAuxiliaryLists(): void {
    }

    loadObject(): void {
        if (this.activeID && (this.activeID !== 0)) {
            this.motiveService.getById(this.activeID).then(response => {
                var resultItem: Motive = response.response;
                this.activeObject = resultItem;
            });
        }
        this.loading = false;
    }
    
    initObject(): void {
        this.activeObject = new Motive();
        this.activeObject.empresaId = this.authGuard.loggedUser().selectedCompany.id;
        this.loading = false;
    }
    
    doCreate(t: Motive): Promise<RESTResponseVO<Motive>> {
        return this.motiveService.create(t);
    }
    
    doEdit(t: Motive): Promise<RESTResponseVO<Motive>> {
        return this.motiveService.update(t);
    }
    
    doDelete(t: Motive): Promise<RESTResponseVO<string>> {
        return this.motiveService.delete(t.id);
    }

    setActiveID(): void {
        this.activeID = this.activeObject.id;
    }
}