import { BaseListComponent } from "../../base/base-list/base-list.component";
import { OnInit, Component, AfterViewInit } from "@angular/core";
import { UtilService } from "../../../services/utils.service";
import { DriverService } from "../../../services/crud/driver.service";
import { PDFService } from "../../../services/pdf.service";
import { AuthGuard } from "../../../guards/auth.guard";
import { Driver } from "../../../classes/driver";
import { Observable } from "rxjs/Observable";
import { EventsService } from "../../../services/crud/events.service";

@Component({
    templateUrl: "./list.component.html",
    styleUrls: ["./list.component.css"]
})
export class ClockingReportListComponent extends BaseListComponent implements OnInit, AfterViewInit {
    constructor(private utilService: UtilService,
        private driverService: DriverService,
        private eventService: EventsService,
        pdfService: PDFService,
        private authGuard: AuthGuard) {
        super(utilService, pdfService, authGuard);
        this.searchField = "";
    }

    public driverList: Driver[];
    public searchObject: { driver: Driver, start: Date, end: Date } = { driver: undefined, start: undefined, end: undefined };

    ngOnInit() {
        this.loading = true;
        this.driverService.getListMinimo().then(response => {
            this.driverList = response.response;

            this.loading = false;
        });
        this.pageOrientation = 'landscape';
    }

    doSearch() {
        this.errorMessage = undefined;

        if (!this.searchObject) {
            this.errorMessage = "Favor preencher os campos do formulário.";
        } else if (!this.searchObject.driver) {
            this.errorMessage = "Favor selecionar um motorista.";
        } else if (!this.searchObject.start || !this.searchObject.end) {
            this.errorMessage = "Data inicial ou final inválidas ou não preenchidas!";
        } else {
            this.loading = true;

            let start = this.searchObject.start.getTime();
            let end = this.searchObject.end.getTime();

            this.eventService.getClockingReport(this.searchObject.driver.id, start, end).then(response => {
                if (response.error) {
                    this.errorMessage = response.error;
                } else {
                    this.tableHeaders = [
                        [
                            this.createHeader("Evento", 0, true),
                            this.createHeader("Latitude", 1, true),
                            this.createHeader("Longitude", 2, true),
                            this.createHeader("Local", 3, true),
                            this.createHeader("Endereço", 4, true),
                            this.createHeader("Data", 5, true),
                            this.createHeader("Motorista", 6, true)
                        ]
                    ];

                    this.dataFields = [
                        this.createField("nomeEvento", "string"),
                        this.createField("latitude", "string"),
                        this.createField("longitude", "string"),
                        this.createField("local", "string"),
                        this.createField("endereco", "string"),
                        this.createField("data", "datetime"),
                        this.createField("origem", "string")
                    ];

                   let dados = response.response;
                   dados.forEach(e=>{
                        e.origem = this.searchObject.driver.nome + "" + this.searchObject.driver.sobrenome;
                   });

                    this.data = dados;
                }
                this.loading = false;
            });
        }
    }

    driverFormatter = (x: Driver) => x.nome + ' ' + x.sobrenome;

    searchDriver = (text$: Observable<string>) =>
        text$
            .debounceTime(200)
            .distinctUntilChanged()
            .map(term => term.length < 2 ? [] : this.driverList.filter(v => v.nome.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10));

    driverTemplate(r) {
        return r.nome + " " + r.sobrenome;
    }
}
