import { BaseListComponent } from "../../../base/base-list/base-list.component";
import { Component, ViewEncapsulation,Compiler } from "@angular/core";
import { VeiculoService } from "../../../../services/crud/veiculo.service";
import { UtilService } from "../../../../services/utils.service";
import { PDFService } from "../../../../services/pdf.service";
import { AuthGuard } from "../../../../guards/auth.guard";

@Component({
  templateUrl: '../../../base/base-list/base-list.component.html',
  styleUrls: ['../../../base/base-list/base-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class VeiculoListComponent extends BaseListComponent {

  constructor(private veiculoService: VeiculoService,
    private utilService: UtilService,
    pdfService: PDFService,
    authGuard: AuthGuard,
    private _compiler: Compiler) {
    super(utilService, pdfService, authGuard);
    this.searchField = "placa";
    this._compiler.clearCache();
    this.data = [];
  }

  ngOnInit() {
    this.pageTitle = "Cadastros | Veículo";
    this.formPath = "/adm/veiculo/form";

    this.tableHeaders = [
        [
            this.createHeader("Placa", 0, true),
            this.createHeader("Marca", 1, true),
            this.createHeader("Modelo", 2, true),
            this.createHeader("Cor", 3, true),
            this.createHeader("Cidade Registro", 4, true),
            this.createHeader("Tipo Veiculo", 5, true)
        ]
    ];

    this.dataFields = [
        this.createField("placa", "string"),
        this.createField("modelo.fabricante", "string"),
        this.createField("modelo.marca", "string"),
        this.createField("cor", "string"),
        this.createField("cidade", "string"),
        this.createField("tiposDeVeiculo.descricao", "string"),
    ];

    this.veiculoService.getLista().then(response => {
        this.data = response.listaDeVeiculos;
        this.loading = false;
    });
  }

}
