import { OnInit, Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthGuard } from "../../guards/auth.guard";
import { User } from "../../classes/user";
import { UtilService } from "../../services/utils.service";

@Component({
    selector: 'version-panel',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.css']
})
export class VersionPanelComponent implements OnInit {
    ngOnInit(): void {
    }

    constructor(private authGuard: AuthGuard,
        private router: Router,
        private utilService: UtilService) {
    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
    }

}