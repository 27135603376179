import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DatePipe } from '@angular/common';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';

import { Address } from '../../../../classes/address';
import { Company } from '../../../../classes/company';
import { City } from '../../../../classes/city';
import { WaitingPoint } from "../../../../classes/waiting-point";
import { CityService } from '../../../../services/crud/city.service';
import { CompanyService } from '../../../../services/crud/company.service';

import { BaseFormComponent } from '../../../base/base-form/base-form.component';
import { RESTResponseVO } from '../../../../classes/restresponsevo';
import { LoadingUnloadingPoints } from '../../../../classes/loading-unloading-points';
import { LoadingUnloadingPointsService } from '../../../../services/crud/loading-unloading-points.service';
import { AuthGuard } from '../../../../guards/auth.guard';

@Component({
    selector: 'app-loading-unloading-point-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css'],
    providers: [DatePipe]
})
export class LoadingUnloadingPointsFormComponent extends BaseFormComponent<LoadingUnloadingPoints> {
    constructor(private loadingUnloadingPointsService: LoadingUnloadingPointsService,
        private datePipe: DatePipe,
        private authGuard: AuthGuard,
        route: ActivatedRoute,
        router: Router) { super(route, router, 'adm/loadingunloadingpoints/list'); }

    assembleObjectFromForm(): void {
        if (this.activeObject.latitude) {
            this.activeObject.latitude = (this.activeObject.latitude + '').replace('.', '').replace(',', '.');
        }
        if (this.activeObject.longitude) {
            this.activeObject.longitude = (this.activeObject.longitude + '').replace('.', '').replace(',', '.');
        }
    }

    loadAuxiliaryLists(): void {
    }

    loadObject(): void {
        if (this.activeID && (this.activeID !== 0)) {
            this.loadingUnloadingPointsService.getById(this.activeID).then(response => {
                var resultItem: LoadingUnloadingPoints = response.response;
                this.activeObject = resultItem;
            });
        }
        this.loading = false;
    }

    initObject(): void {
        this.activeObject = new LoadingUnloadingPoints();
        this.activeObject.empresaId = new Company();
        this.activeObject.empresaId.id = this.authGuard.loggedUser().selectedCompany.id;
        this.activeObject.pontosDeEsperaId = new WaitingPoint();
        this.activeObject.pontosDeEsperaId.endereco = new Address();
        this.loading = false;
    }

    doCreate(t: LoadingUnloadingPoints): Promise<RESTResponseVO<LoadingUnloadingPoints>> {
        return this.loadingUnloadingPointsService.create(t);
    }

    doEdit(t: LoadingUnloadingPoints): Promise<RESTResponseVO<LoadingUnloadingPoints>> {
        return this.loadingUnloadingPointsService.update(t);
    }

    doDelete(t: LoadingUnloadingPoints): Promise<RESTResponseVO<string>> {
        return this.loadingUnloadingPointsService.delete(t.id);
    }

    setActiveID(): void {
        this.activeID = this.activeObject.id;
    }
}