import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSort, MatTableDataSource, MatDatepicker, MatSelect, MatOption } from '@angular/material';
import { Filtro } from 'app/classes/filtro';
import { AuthGuard } from 'app/guards/auth.guard';
import { RankingMotoristaService } from 'app/services/crud/ranking-motorista.service';
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { ReportService } from 'app/services/report/report.service';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { utils, writeFile } from 'xlsx';
import { PDFService } from 'app/services/pdf.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { RotaService } from 'app/services/rota.service';
import { ModalRegistroAlteracoes } from './modal-registro-alteracoes.component';

@Component({
  selector: 'app-registro-alteracoes',
  templateUrl: './registro-alteracoes.component.html',
  styleUrls: ['./registro-alteracoes.component.css']
})
export class RegistroAlteracoesComponent implements OnInit {

  isLoading: boolean = false;
  formGroup: FormGroup;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('selectVeiculos') selectVeiculos: MatSelect;
  @ViewChild('selectRotas') selectRotas: MatSelect;
  veiculos: any[] = [];
  rotas: any[] = [];
  xpandStatus = false;
  public veiculos2: any;
  public filteredList5: any;
  public rotas2: any;
  public filteredList7: any;
  maxDate: any;
  minDate: any;
  disabled: any;
  showSpinners: any;
  showSeconds: any;
  stepHour: any;
  stepMinute: any;
  stepSecond: any;
  idEmpresa: number;

  displayedColumns: string[] = [
    'tipoEvento',
    'descricao'
  ];
  dataSource: MatTableDataSource<any>;
  public filtro: Filtro;
  contentToExport: any;
  pageOrientation: string = 'portrait';

  constructor(
    private reportService: ReportService,
    private formBuilder: FormBuilder,
    private veiculoService: VeiculoService,
    private rotaService: RotaService,
    private modalService: NgbModal,
    private pdfService: PDFService,
    private authGuard: AuthGuard,
  ) { 
    const loggedUser = this.authGuard.loggedUser();
    this.idEmpresa = loggedUser.selectedCompany.id
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      dataHora: [null, [Validators.required]],
      veiculos: [[]],
      rotas: [[]]
    });
    this.listarVeiculos();
    this.listarRotas();
  }

  filterVeiculos(filtro: any) {
    return filtro;
  }

  exportCSV() {
    if (this.contentToExport) {
      const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Registro de alterações',
        useBom: true,
        noDownload: false,
        headers: ["Tipo de evento", "Descrição"]
      };

      new AngularCsv(this.contentToExport, "RegistroAlteracoes", csvOptions);
    }
  }

  exportExcel() {
    if (this.contentToExport) {
      const ws = utils.json_to_sheet(this.contentToExport);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'Tabela de Retorno');
      return writeFile(wb, 'RegistroAlteracoes.xlsx');
    }
  }

  createHeader(
    name: string,
    dataIndex?: number,
    canSort?: boolean,
    colspan?: number,
    css?: string,
    tooltip?: string
  ) {
    if (canSort == null) canSort = false;
    if (colspan == null) colspan = 1;
    if (dataIndex == null) dataIndex = -1;
    if (css == null) css = 'title-left';
    if (tooltip == null) tooltip = '';

    return {
      name: [name],
      canSort: canSort,
      colspan: colspan,
      dataIndex: dataIndex,
      css: css,
      tooltip: tooltip
    };
  }

  exportPrint() {
    if (this.contentToExport) {
      const data = this.contentToExport;
      const dataFields = Object.keys(this.contentToExport[0]).map((field) => ({
        name: field,
        prefix: '',
        separator: "",
        sufix: "",
        type: "string",
      }));
      const headers = [
        [
          this.createHeader("Tipo de Evento", 0, true),
          this.createHeader("Descrição", 1, true)
        ]
      ];
      const pageOrientation = 'portrait';
      this.pdfService.printList(
        data,
        dataFields,
        headers,
        pageOrientation
      );
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  listarVeiculos() {
    this.veiculoService.getLista().then(response => {
      this.veiculos = response.listaDeVeiculos;
      this.veiculos2 = response.listaDeVeiculos;
      this.filteredList5 = this.veiculos2.slice()
    });
  }

  listarRotas() {
    this.rotaService.getLista().then(response => {
      this.rotas = response.listaDeRota;
      this.rotas2 = response.listaDeRota;
      this.filteredList7 = this.rotas2.slice();
    })
  }

  abrirModal(row: any) {
    const modalRef = this.modalService.open(ModalRegistroAlteracoes, { size: <any>'xl', backdrop: 'static', keyboard: false, windowClass: 'vehicle-modal' });
    modalRef.componentInstance.dados = row;
    modalRef.result.then((result) => {
      if (result) {
      }
    }).catch((res) => { });
  }

  selecionarTodos(matSelect: MatSelect) {
    const isSelected: boolean = matSelect.options
      .filter((item: MatOption) => item.value === 0)
      .map((item: MatOption) => item.selected)
    [0];

    if (isSelected) {
      matSelect.options.forEach((item: MatOption) => item.select());
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  doSearch() {
    if (this.formGroup.value.dataHora === null || !this.formGroup.value.dataHora) {
      return;
    }
    const dataHora = moment(this.formGroup.value.dataHora).format('YYYY-MM-DD HH:mm:ss');
    try {
      const veiculosData = this.formGroup.value.veiculos.filter(item => item !== 0);
      const rotasData = this.formGroup.value.rotas.filter(item => item !== 0);
      this.reportService.getHistoricoAlteracoes(this.idEmpresa, dataHora, veiculosData, rotasData).then(response => {
        const result = response;
        console.log(result);
      })
      // const tempoPeriodoParado = {
      //   dataInicial: moment(this.formGroup.value.dataInicial).valueOf(),
      //   dataFinal: moment(this.formGroup.value.dataFinal).valueOf(),
      //   listaDeVeiculos: this.formGroup.value.veiculos[0] ? this.formGroup.value.veiculos.map((veiculo) => ({ id: veiculo })) : this.veiculos.map((veiculo) => ({ id: veiculo.id })),
      //   dataInicialString: dataInicial,
      //   dataFinalString: dataFinal,
      //   idUnidade: this.authGuard.loggedUser().selectedBranch.id,
      // };
      // this.isLoading = true;
      // this.reportService.getReportPeriodoParado(tempoPeriodoParado).then(response => {
      //   const result = response.map((ocorrencia) => {
      //     if (ocorrencia.quantidadeOcorrencia > 0) {
      //       if (ocorrencia.unidade === null) {
      //         ocorrencia.unidade = 0;
      //       }
      //       return ocorrencia;
      //     }
      //     return null;
      //   }).filter((ocorrencia) => ocorrencia !== null);
      //   this.isLoading = false;
      //   this.dataSource = new MatTableDataSource(result);
      //   this.dataSource.paginator = this.paginator;
      //   this.contentToExport = result.map((ocorrencia) => {
      //     return {
      //       unidade: ocorrencia.unidade,
      //       placa: ocorrencia.placa,
      //       quantidadeOcorrencia: ocorrencia.quantidadeOcorrencia,
      //       tempoTotal: ocorrencia.tempoTotal,
      //     }
      //   });
      //   this.xpandStatus = true;
      // });
    } catch (error) {
      console.log(error);
    }
  }
}