import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { User } from '../../../../classes/user';
import { UserService } from '../../../../services/crud/user.service';
import { BaseListComponent } from '../../../base/base-list/base-list.component';
import { UtilService } from '../../../../services/utils.service';
import { PDFService } from '../../../../services/pdf.service';
import { AuthGuard } from '../../../../guards/auth.guard';

@Component({
    templateUrl: '../../../base/base-list/base-list.component.html',
    styleUrls: [ '../../../base/base-list/base-list.component.css' ],
    encapsulation: ViewEncapsulation.None
})
export class UserListComponent extends BaseListComponent {

    public loggedUser: User;
    constructor(private userService: UserService,
        private utilService: UtilService,
        private authGuard: AuthGuard,
        pdfService: PDFService) {
        super(utilService, pdfService, authGuard);
        this.loggedUser  = authGuard.loggedUser();

        this.searchField = "userName";
    }

    ngOnInit() {
        this.searchPlaceholder = "Pesquisar Usuários";
        this.pageTitle = "Cadastros | Usuários";
        this.formPath = "/adm/user/form";
        this.allowDelete = false;

        this.tableHeaders = [
            [
                this.createHeader("Nome", 0, true),
                this.createHeader("E-mail", 1, true),
                this.createHeader("Situação", 2, true)
            ]
        ];

        this.dataFields = [
            this.createField("userName", "string"),
            this.createField("email", "string"),
            this.createField("situacao", "boolean"),
        ];

        this.userService.getList().then(response => {
            this.errorMessage = response.error;
            if (!this.errorMessage) {
                this.data = response.response;
                if(this.loggedUser.claimList.filter(e=> e.name === 'admin').length == 0){
                    var newData: any[] = [];
                    for(var i = 0; i < this.data.length; i++){
                        var userT = this.data[i];
                        if(userT.claimList.filter(e=> e.name === 'admin').length == 0){
                            newData.push(userT);
                        }
                    }
                    this.data =  newData;
                }
            }
            this.loading = false;
        });
    }
}
