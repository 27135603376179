import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ViamInputComponent } from "./components/viam-input/viam-input.component";
import { ViamSelectComponent } from "./components/viam-select/viam-select.component";

@NgModule({
  imports: [CommonModule, NgbModule, FormsModule, ReactiveFormsModule],
  declarations: [ViamInputComponent, ViamSelectComponent],
  exports: [ViamInputComponent, ViamSelectComponent],
  entryComponents: []
})
export class SharedModule { }
