import { Injectable } from '@angular/core';
import { CRUDService } from '../base/crud.service';
import { Http } from '@angular/http';
import { Tolerance } from '../../classes/tolerance';
import { environment } from '../../../environments/environment';
import { RESTResponseVO } from "../../classes/restresponsevo";
import { User } from "../../classes/user";
import { AuthGuard } from "../../guards/auth.guard";

@Injectable()
export class ToleranceService extends CRUDService<Tolerance> {
    constructor(http: Http, public authGuard: AuthGuard) {
        super(http, environment.baseURL + 'App/v2/web/tolerancias', true);

        var loggedUser: User = authGuard.loggedUser();
        var company: number = loggedUser.selectedCompany.id;
        this.listURL = environment.baseURL + "App/v2/web/tolerancias/lista/" + company;
    }
}