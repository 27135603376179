import { Pipe, PipeTransform } from "@angular/core";
import { UtilService } from "../../services/utils.service";

@Pipe({
    name: "addSecondsToDate"
})
export class AddSecondsToDatePipe implements PipeTransform {
    constructor(private utils: UtilService) {}

    transform(seconds: number, dateOrigin: string) {
        return this.utils.addSeconds(dateOrigin, seconds);
    }
}