import { Injectable } from '@angular/core';
import { CRUDService } from '../base/crud.service';
import { Http } from '@angular/http';
import { environment } from '../../../environments/environment';
import { Shift } from '../../classes/shift';
import { AuthGuard } from '../../guards/auth.guard';
import { User } from '../../classes/user';
import { Turno } from 'app/classes/turno';
import { RESTResponseVO } from 'app/classes/restresponsevo';

@Injectable()
export class ShiftService extends CRUDService<Shift> {
    localAuthGuard: AuthGuard;

  constructor(http: Http, authGuard: AuthGuard) {
    super(http, environment.baseURL + 'App/v2/web/turnos', true);

    this.localAuthGuard = authGuard;
    this.setListUrl();
  }

  setListUrl() {
    const loggedUser = this.localAuthGuard.loggedUser();
    const company: number = loggedUser.selectedCompany.id;

    this.listURL = environment.baseURL + 'App/v2/web/turnos/lista/' + company;
  }

  getList(): Promise<RESTResponseVO<Shift[]>> {
    this.setListUrl();
    return super.getList();
  }
    /*
    constructor(http: Http,
        private authGuard: AuthGuard) {
        super(http, environment.baseURL + 'App/v2/web/turnos', true);

        var loggedUser: User = authGuard.loggedUser();
        var company: number = loggedUser.selectedCompany.id;

        this.listURL = environment.baseURL + 'App/v2/web/turnos/lista/' + company;
    }
    */
}