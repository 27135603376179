import { Injectable } from '@angular/core';
import { CRUDService } from '../base/crud.service';
import { Http } from '@angular/http';
import { Holiday } from '../../classes/holiday';
import { environment } from '../../../environments/environment';
import { RESTResponseVO } from "../../classes/restresponsevo";
import { AuthGuard } from 'app/guards/auth.guard';
import { User } from 'app/classes/user';
import { RetornoRota } from 'app/classes/retorno-rota';

@Injectable()
export class HolidayService extends CRUDService<Holiday> {
    public company: number;
    public loggedUser: User;
    public authGuardM: AuthGuard;

    constructor(http: Http, public authGuard: AuthGuard) {
        super(http, environment.baseURL + 'App/v2/web/feriados', true);
        this.authGuardM = authGuard;
        this.loggedUser = authGuard.loggedUser();
        this.company = this.loggedUser.selectedCompany.id;
        var branch: string = this.loggedUser.selectedBranch.id === 0 && '0' || this.loggedUser.selectedBranch.id.toString();
    }

    getLista(): Promise<RESTResponseVO<any>> {
        this.loggedUser = this.authGuardM.loggedUser();
        this.company = this.loggedUser.selectedCompany.id;
        var branch: string = this.loggedUser.selectedBranch.id === 0 && '0' || this.loggedUser.selectedBranch.id.toString();
        let url: string = environment.baseURL + 'App/v2/web/feriados/buscarPorEmpresa/' + this.company + '/' + branch;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<any>;
            })
            .catch(this.handleError);
    }
}
