import { BaseListComponent } from "../../../base/base-list/base-list.component";
import { Component, ViewEncapsulation } from "@angular/core";
import { UtilService } from "../../../../services/utils.service";
import { PDFService } from "../../../../services/pdf.service";
import { AuthGuard } from "../../../../guards/auth.guard";
import { GrupoLocaisService } from "app/services/crud/grupo-locais.service";


@Component({
  templateUrl: '../../../base/base-list/base-list.component.html',
  styleUrls: [ '../../../base/base-list/base-list.component.css' ],
  encapsulation: ViewEncapsulation.None
})

export class GrupoLocaisListComponent extends BaseListComponent {
  constructor(private grupoLocaisService: GrupoLocaisService,
      private utilService: UtilService,
      pdfService: PDFService,
      authGuard: AuthGuard) {
      super(utilService, pdfService, authGuard);
      this.searchField = "nome";
  }

  ngOnInit() {
    this.pageTitle = "Cadastros | Grupo Locais";
      this.formPath = "/adm/grupoLocais/form";

      this.tableHeaders = [
          [
              this.createHeader("ID", 0, true),
              this.createHeader("Identificação", 1, true),
              this.createHeader("Tempo limite para abertura do baú", 2, true),
              this.createHeader("Tempo limite para fechamento do baú", 3, true),
              this.createHeader("Tempo limite para saída do local", 4, true),
          ]
      ];

      this.dataFields = [
          this.createField("id", "string"),
          this.createField("nome", "string"),
          this.createField("tempoMaxLojaBauFechado", "string"),
          this.createField("tempoMaxBauAberto", "string"),
          this.createField("tempoMaxSemAbrirBau", "string"),
      ];

      this.grupoLocaisService.getLista().then(response => {
        console.log(response);  
        this.data = response.listaDeGrupoLocais;
          this.loading = false;
      });
  }

}
