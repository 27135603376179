import { CRUDService } from "../base/crud.service";
import { Modelo } from "../../classes/modelo";
import { Http } from "@angular/http";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { AuthGuard } from "../../guards/auth.guard";
import { RetornoModelo } from "../../classes/retorno-modelo";
import { RESTResponseVO } from "app/classes/restresponsevo";


@Injectable({
  providedIn: 'root'
})
export class ModeloEquipamentoService extends CRUDService<Modelo> {
  constructor(http: Http, public authGuard: AuthGuard) {
    super(http, environment.baseURL + 'App/v2/web/modelo', true);
    this.listURL = environment.baseURL + "App/v2/web/modelo/buscarListaModeloEquipamento";
  }

 

  getLista() {
    const url: string =
      (this.listURL === undefined && `${this.defaultUrl}`) || `${this.listURL}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoModelo;
        }
      })
      .catch(this.handleError);
  }

  getListaPortatil() {
    const url = `${this.defaultUrl}/buscarModeloPortatil`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoModelo;
        }
      })
      .catch(this.handleError);
  }

  getById(id: number) {
    const url = `${this.defaultUrl}/buscarModelo/${id}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoModelo;
        }
      })
      .catch(this.handleError);
  }

  cadastrarModeloEquipamento(modelo: Modelo): Promise<RESTResponseVO<Modelo>> {
    const url = `${this.defaultUrl}/cadastrarModeloEquipamento`;
    modelo.tipo = 'E';
    return this.enviarRequisicao(modelo, url);
  }

  editarModeloEquipamento(modelo: Modelo): Promise<RESTResponseVO<Modelo>> {
    const url = `${this.defaultUrl}/editarModelo`;
    return this.enviarRequisicao(modelo, url);
  }

  enviarRequisicao(modelo: Modelo, url: string): Promise<RESTResponseVO<Modelo>> {
    try {
      return this.http
        .post(url, JSON.stringify(modelo), { headers: this.headers })
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            var retorno: RetornoModelo = response.json() as RetornoModelo;
            var modelo: Modelo = retorno.modelo;
            if (modelo === null) {
              modelo = new Modelo();
            }
            modelo.id = 0;
            return modelo;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }


}
