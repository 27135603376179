import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { UtilService } from "./utils.service";

@Injectable()
export class PDFService {

    constructor(private utils: UtilService) {}

    private buildTableBody(data, columns, tableHeader) {
        var body = [];
        if (tableHeader !== undefined) {
            tableHeader.forEach(headerLines => {
                let line = new Array();
                headerLines.forEach(header => {
                    line.push(header.name);
                });
                body.push(line);
            });
        } else {
            body.push(columns.map(c => c.name));
        }
        data.forEach((row) => {
            var dataRow = [];
            columns.forEach((column) => {
                var value = this.getValueByName(row, column);
                dataRow.push(value);
            })
            body.push(dataRow);
        });
        return body;
    }

    private renderTable(data, columns, tableHeader) {
        return {
            table: {
                headerRows: 1,
                body: this.buildTableBody(data, columns, tableHeader)
            }
        };
    }

    public print(dd) {
        (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
        pdfMake.createPdf(dd).open();
    }

    public printList(lista, colunas, tableHeader, orientation?: string) {
        if (!orientation) {
            orientation = 'portrait';
        }

        var dd = {
            content: [
                this.renderTable(lista, colunas, tableHeader)
            ],
            pageOrientation: orientation
        };

        this.print(dd); 
    }

    getValueByName(row: any, field: any): any {
        if (!row) return "";

        if (field.type === "date") {
            let value = this.utils.getValue(row, field.name, "");
            return this.utils.formatDate(value, "DD/MM/YYYY");
        } else if (field.type === "boolean") {
            let value = this.utils.getValue(row, field.name, false);
            if (value) {
                return "Sim";
            } else {
                return "Não";
            }
        } else if (field.type === "datetime") {
            let value = this.utils.getValue(row, field.name, "");
            return this.utils.formatDate(value);
        } else if (field.type === "month") {
            let value = this.utils.getValue(row, field.name, "");
            return this.utils.getMonth(value);
        } else if (field.type === "time") {
            let value = this.utils.getValue(row, field.name, "");
            return this.utils.miliToHHmm(value);
        } else if (field.type === "treatable") {
            let value = this.utils.getValue(row, field.name, "");
            if (!value) {
                return "Não tratável";
            } else {
                return "Tratável";
            }
        } else if (field.type === "location") {
            let value = this.utils.getValue(row, field.name, "");
            if (!value) {
                return "Não cadastrado";
            }
            return value;
        } else {
            let final: string;
            final = this.utils.getValue(row, field.name, "");
            if (field.prefix) {
                let prefix = this.utils.getValue(row, field.prefix, "");
                if (prefix) {
                    final = prefix + field.separator + final;
                }
            }
            if (field.sufix) {
                let sufix = this.utils.getValue(row, field.sufix, "");
                if (sufix) {
                    final += field.separator + sufix;
                }
            }
            return final;
        }
    }
}