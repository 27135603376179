import { sinOut } from '@amcharts/amcharts4/.internal/core/utils/Ease';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption, MatSelect } from '@angular/material';
import { JourneyCalculationReport } from 'app/classes/journey-calculation-report';
import { User } from 'app/classes/user';
import { AuthGuard } from 'app/guards/auth.guard';
import { DriverService } from 'app/services/crud/driver.service';
import { JourneyService } from 'app/services/crud/journey.service';
import { PDFService } from 'app/services/pdf.service';
import { ReportService } from 'app/services/report/report.service';
import { ProcessoArquivoService } from "app/services/processo-arquivo.service";


import * as moment from 'moment';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { WorkBook, WorkSheet, utils } from 'xlsx';


@Component({
  selector: 'app-apuracao',
  templateUrl: './apuracao.component.html',
  styleUrls: ['./apuracao.component.css']
})
export class ApuracaoComponent implements OnInit {

  isLoading: boolean = false;
  formGroup: FormGroup;
  @ViewChild('selectMotoristas') selectVeiculos: MatSelect;
  @Input()
  motoristas: any = [];
  public motoristas2: any;
  public filteredList5: any;
  filtro: any = {};
  allSelected: boolean = false;
  errorMessage: any;
  RMLabore = false;
  RelExcel = false;
  listRm: string[] = [];
  showTableInfoRelatorio: boolean = false;
  informacaoRelatorioGerado: any;
  showButtonDownload: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private journeyService: JourneyService,
    private driverService: DriverService,
    private authGuard: AuthGuard,
    private reportService: ReportService,
    private processoArquivoService:ProcessoArquivoService,
    private pdfService: PDFService) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      dataInicial: [null, [Validators.required]],
      dataFinal: [null, [Validators.required]],
      // motoristas: [null, [Validators.required]],
      relatorio: [null, [Validators.required]],
      dataFinalRmLabore: [null],
      situacao: [null, [Validators.required]]
    });
    //this.listarMotoristas();
    let loggedUser: User = this.authGuard.loggedUser();
  }

  filterMotoristas(filtro: any) {
    return filtro;
  }

  listarMotoristas() {
    this.isLoading = true;
    this.driverService.getMotoristas().then(response => {
      const refactoredResult = response.response.map(item => {
        item.nome = `${item.nome} ${item.sobrenome}`
        delete item.sobrenome;
        return item;
      })
      this.isLoading = false;
      //this.motoristas = refactoredResult;
    });
  }

  onStatusChange(event, matSelect: MatSelect) {
    /*
    if (event === "ATIVO") {
      this.motoristas2 = this.motoristas.filter(d => d.situacao);
    } else {
      this.motoristas2 = this.motoristas.filter(d => !d.situacao);

    }
      const filteredList = this.motoristas2.slice()
      this.filteredList5 = [...filteredList].sort((a, b): any => {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      });

      this.allSelected = false;
      matSelect.options.forEach((item: MatOption) => item.deselect());
      */
  }

  selecionarTodos(matSelect: MatSelect) {
    const isSelected: boolean = matSelect.options
      .filter((item: MatOption) => item.value === 0)
      .map((item: MatOption) => item.selected)
    [0];

    if (isSelected) {
      this.allSelected = true;
      matSelect.options.forEach((item: MatOption) => item.select());
    } else {
      this.allSelected = false;
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  changeTipoRelatorio(bool: number) {
    if (bool === 0) {
      this.RMLabore = false;
      this.RelExcel = false;
      this.formGroup.controls['dataFinalRmLabore'].clearValidators();
    } else if (bool === 1) {
      this.RMLabore = true;
      this.RelExcel = false;
      this.formGroup.controls['dataFinalRmLabore'].setValidators([Validators.required]);
    } else if (bool === 2) {
      this.RMLabore = false;
      this.RelExcel = true;
      this.formGroup.controls['dataFinalRmLabore'].clearValidators();
    }
  }

  createObjToPrint(array) {
    var obj: any = {};
    array.map((result, index, arr) => {
      if (index === 0) {
        obj.content = result.content;
        obj.footer = result.footer;
        obj.header = result.header;
        obj.pageMargins = result.pageMargins;
        obj.pageOrientation = result.pageOrientation;
        return obj.styles = result.styles;
      }
      obj.content[0] = [...obj.content[0], ...result.content[0]];
      // this.printPdf(result);
    });
    this.printPdf(obj);
    this.isLoading = false;
  }

  base64toPDF(data) {
    var bufferArray = this.base64ToArrayBuffer(data);
    var blobStore = new Blob([bufferArray], { type: "application/pdf" });
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(blobStore);
      return;
    }
    var data: any = window.URL.createObjectURL(blobStore);
    var link = document.createElement('a');
    document.body.appendChild(link);
    link.href = data;
    link.download = "file.pdf";
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
  }

  base64ToArrayBuffer(data) {
    var bString = window.atob(data);
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
      var ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  printPdf(dd) {
    (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(dd).getBase64((result) => {
      this.base64toPDF(result);
    });
  }

  private setting = {
    element: {
      dynamicDownload: null as HTMLElement
    }
  }

  private generateTextRmLabore() {
    const text = this.listRm.join('\n');
    return text;
  }

  private async dyanmicDownloadByHtmlTag(arg: {
    fileName: string,
    text: string
  }, size) {
    this.listRm = [...this.listRm, arg.text];
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement('a');
    }
    const element = this.setting.element.dynamicDownload;
    const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
    await element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(this.generateTextRmLabore())}`);
    element.setAttribute('download', arg.fileName);

    var event = new MouseEvent("click");
    element.dispatchEvent(event);
  }

  pad(num: string, size: number): string {
    while (num.length < size) num = "0" + num;
    return num;
  }

  downloadRMLabore(lista, date, size) {
    let chapa = "";
    let texto = "";
    var dd = this.pad(date.getDate().toString(), 2);
    var mm = this.pad((date.getMonth() + 1).toString(), 2); //because January is 0!
    var yyyy = date.getFullYear();
    var dataFinal = dd + "" + mm + "" + yyyy;
    lista.forEach((e, index) => {
      chapa = e.chapa;
      texto = texto + this.pad(e.chapa, 6);
      texto = texto + "          "
      texto = texto + dataFinal;
      texto = texto + this.pad(e.evento, 4);
      texto = texto + this.pad(e.hora, 6);
      texto = texto + '           0.00';
      texto = texto + '           0.00';
      texto = texto + '           0.00';
      texto = texto + "N";
      texto = texto + "N";
      texto = texto + "\n";
    });
    this.dyanmicDownloadByHtmlTag({
      fileName: "arquivo_rm_labore_" + String(Math.floor(Math.random() * 789)),
      text: texto
    }, size);
  }

 


  doSearch() {

    if (this.informacaoRelatorioGerado !== null) {
      this.informacaoRelatorioGerado = null;
      this.showButtonDownload = false;
    }

    if (this.allSelected) {
      const oldValue = this.formGroup.value.motoristas;
      this.formGroup.value.motoristas = oldValue.filter((item) => {
        if (item !== 0) {
          return item;
        }
      })
    }

    // if (!this.formGroup.value.motoristas) {
    // return;
    //}

    if (!this.formGroup.value.situacao) {
      return;
    }

    if (this.RMLabore) {
      if (!this.formGroup.value.dataFinalRmLabore) {
        return
      }
    }

    if (this.formGroup.value.dataInicial || this.formGroup.value.dataFinal) {
      this.isLoading = true;
    }
    try {
      const dataInicial = moment(this.formGroup.value.dataInicial).format('DDMMYYYYHHmm');
      const dataFinal = moment(this.formGroup.value.dataFinal).format('DDMMYYYYHHmm');
      this.filtro.dataInicialString = dataInicial;
      this.filtro.dataFinalString = dataFinal;
      this.filtro.statusFiltro = this.formGroup.value.situacao;
      this.filtro.idEmpresa = this.authGuard.loggedUser().selectedCompany.id;
      /*
      this.filtro.listaDeMotoristas = this.formGroup.value.motoristas.map((motoristaId) => {
        const motorista = this.motoristas.find(m => m.id === motoristaId);
        const motoristaToService = {
          id: motoristaId,
          nome: `${motorista.nome}`,
        }
        return motoristaToService;
      });*/
      this.journeyService.getApuracaoJornadaRelatorio(this.filtro).then(async (response) => {
        const informacaoRelatorio = response as any;
        if (!informacaoRelatorio) {
          this.isLoading = false;
          return;
        }

        if (this.RMLabore) {
          var teste = [];
          const size = informacaoRelatorio.length;
          informacaoRelatorio.forEach((relatorio) => {
            try {
              relatorio.listaDetalheDaJornada.forEach((r) => {
                teste.push(r);
              });
            } catch (error) {
              console.error(error);
              this.isLoading = false;
              return;
            }

          });
          this.downloadRMLabore(teste, this.formGroup.value.dataFinalRmLabore, size);
          teste = [];
          this.isLoading = false;
          return;
        }

        if (this.RelExcel) {
          var listaRelExcel = [];
          informacaoRelatorio.forEach((ir) => {
            try {
              var relExcel = {
                "nomeMotorista": ir.motorista.nome + " " + ir.motorista.sobrenome,
                "chapa": ir.motorista.numeroChapa,
                "listaDetalheDaJornada": ir.listaDetalheDaJornada
              }
              listaRelExcel.push(relExcel);
            } catch (error) {
              console.error(error);
              this.isLoading = false;
              return;
            }

          });

          this.downloadExcel(listaRelExcel);
          this.isLoading = false;
          return;
        }

        const results = await Promise.all(informacaoRelatorio.map(async (relatorio) => {
          const call = await this.journeyService.getJourneyCalculation(relatorio.motorista.id, moment(this.formGroup.value.dataInicial).valueOf(), moment(this.formGroup.value.dataFinal).valueOf(), true);
          if (call.error) {
            this.errorMessage = call.error;
          } else {
            let report: JourneyCalculationReport = call.response;
            let loggedUser = this.authGuard.loggedUser();
            let dd = this.reportService.printJourneyCalculationReport(loggedUser.selectedCompany.name, report, undefined, true, undefined);
            return dd;
          }
        }));
        this.createObjToPrint(results);
      });
    } catch (error) {
      console.error(error);
    }
  }

  getApuracaoJornadaRelatoriogerado(codigoDoRelatorio: string): any {
    const intervalId = setInterval(() => {
      try {
        this.journeyService.getApuracaoJornadaRelatoriogerado(codigoDoRelatorio)
          .then(async (response) => {
            const informacaoRelatorio = response as any;
            if (informacaoRelatorio) {
              this.isLoading = false;
              if (informacaoRelatorio.listaRelatorioApuracaoDeJornada === null) {
                this.informacaoRelatorioGerado = informacaoRelatorio;
                return false;
              }
              if (informacaoRelatorio.listaRelatorioApuracaoDeJornada !== null && informacaoRelatorio.listaRelatorioApuracaoDeJornada.length > 0) {
                this.showButtonDownload = true;
                this.informacaoRelatorioGerado = informacaoRelatorio;
                clearInterval(intervalId);
              }
              this.informacaoRelatorioGerado = informacaoRelatorio;
              return true;
            }
            return false;
          },
            (error) => {
              console.error("Error retrieving report data:", error);
              this.informacaoRelatorioGerado = {
                mensagem: 'Falha no processamento, por favor, tente novamente.'
              }
              this.isLoading = false;
              this.showTableInfoRelatorio = true;
              clearInterval(intervalId);
              throw error;
            });

      } catch (error) {
        console.error("Erro na função:", error);
        clearInterval(intervalId);
      }
    }, 20000);

  }

  async downloadRelatorioGerado() {
    if (this.RMLabore) {
      var teste = [];
      const size = this.informacaoRelatorioGerado.listaRelatorioApuracaoDeJornada.length;
      this.informacaoRelatorioGerado.listaRelatorioApuracaoDeJornada.forEach((relatorio) => {
        if (relatorio.motorista) {

          relatorio.listaDetalheDaJornada.forEach((r) => {
            if (r) {
              teste.push(r);
            }
          });
        }

      });
      this.downloadRMLabore(teste, this.formGroup.value.dataFinalRmLabore, size);
      teste = [];
      this.isLoading = false;
      return;
    }
    const results = await Promise.all(this.informacaoRelatorioGerado.listaRelatorioApuracaoDeJornada.map(async (relatorio) => {
      const call = await this.journeyService.getJourneyCalculation(relatorio.motorista.id, moment(this.formGroup.value.dataInicial).valueOf(), moment(this.formGroup.value.dataFinal).valueOf(), true);
      if (call.error) {
        this.errorMessage = call.error;
      } else {
        let report: JourneyCalculationReport = call.response;
        let loggedUser = this.authGuard.loggedUser();
        let dd = this.reportService.printJourneyCalculationReport(loggedUser.selectedCompany.name, report, undefined, true, undefined);
        return dd;
      }
    }));
    this.createObjToPrint(results);
  }


  async downloadExcel(listaRelExcel) {
    try {
      this.processoArquivoService.getArquivoExcelApuracao(listaRelExcel)
      .then((response: any) => {
        console.log(response);
        this.handleDownload(response)
      });
    } catch (error) {
      console.error;
    }

  }

 handleDownload(response: any): void {
    const blob = new Blob([response], {
      type: 'application/vnd.ms-excel'
    });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

}
