import { TipoFuncionarioService } from './../../../../services/crud/tipo-funcionario.service';
import { TipoFuncionario } from './../../../../classes/tipo-funcionario';
import { Component } from "@angular/core";
import { BaseFormComponent } from "../../../base/base-form/base-form.component";
import { ActivatedRoute, Router } from "@angular/router";
import { RESTResponseVO } from "../../../../classes/restresponsevo";
import { AuthGuard } from 'app/guards/auth.guard';

@Component({
  templateUrl: './form.component.html',
})
export class TipoFuncionarioFormComponent extends BaseFormComponent<TipoFuncionario> {

  showWait: boolean = false;

  constructor(
    private service: TipoFuncionarioService,
    route: ActivatedRoute,
    public router: Router,
    private authGuard: AuthGuard,
    ) {
    super(route, router, 'adm/tipofuncionario/list');
  }

  async doCreate(t: TipoFuncionario): Promise<RESTResponseVO<TipoFuncionario>> {
    const service = await this.service.create(t);
    if (service) {
      this.router.navigate(['adm/tipofuncionario/list']);
    }
    return service;
  }

  async doEdit(t: TipoFuncionario): Promise<RESTResponseVO<TipoFuncionario>> {
    t.id = this.activeID;
    const service = await this.service.updateTipo(this.activeID, t);
    if (service) {
      this.router.navigate(['adm/tipofuncionario/list']);
    }
    return service;
  }

  doDelete(t: TipoFuncionario): Promise<RESTResponseVO<string>> {
    return this.service.delete(this.activeID);
  }

  assembleObjectFromForm(): void { }

  loadAuxiliaryLists(): void { }

  loadObject(): void {
    if (this.activeID && (this.activeID !== 0)) {
      this.service.getById(this.activeID).then(response => {
        var resultItem = response as unknown as TipoFuncionario;
        this.activeObject = resultItem;
      });
    }
    this.loading = false;
  }

  initObject(): void {
    console.log(this.authGuard.loggedUser().selectedBranch.id);
    this.activeObject = new TipoFuncionario();
    this.loading = false;
  }

  setActiveID(): void {
    if (this.activeID) {
      this.activeID = this.activeObject.id;
    }
  }
}
