import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { catchError, debounceTime, distinctUntilChanged, finalize, switchMap, tap, map } from 'rxjs/operators';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs';
import { DashboardHomeService } from 'app/services/dashboard/dashboard.service';
import { AuthGuard } from 'app/guards/auth.guard';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-configurar-painel-dashboard',
  templateUrl: './modal-configurar-painel-dashboard.component.html',
  styleUrls: ['./modal-configurar-painel-dashboard.component.scss']
})
export class ModalConfigurarPainelDashboardComponent implements OnInit {

  @Input() public dados: any;
  titulo = 'Configurações do Dashboard';
  erromessage = '';
  formGroup: FormGroup;
  isLoading = false;
  mensagem = '';
  loggedUser: any;

  constructor(
    public activeModal: NgbActiveModal,
    private authGuard: AuthGuard,
    private formBuilder: FormBuilder,
    private dashboardHomeService: DashboardHomeService
  ) {
    this.formGroup = this.formBuilder.group({
      configuracaoViam: [null],
      configuracaoViaSat: [null]
    });
    this.loggedUser = this.authGuard.loggedUser();
  }

  ngOnInit(): void {
    if (!!this.dados) {

    }
    const loggedUser = this.authGuard.loggedUser();
    try {
      this.isLoading = true;
      this.dashboardHomeService.obterConfiguracoesInconformidades(loggedUser.selectedBranch.id).then(result => {
        this.isLoading = false;
        this.formGroup.setValue({
          configuracaoViam: this.ajustaTimeComponent(result.configuracaoViam),
          configuracaoViaSat: this.ajustaTimeComponent(result.configuracaoViaSat)
        });
      });
    } catch (error) {
      this.isLoading = false;
      console.error(error);
    }

  }

  ajustaTimeComponent(tempo) {
    if (tempo === undefined) { return null; }
    if (tempo === null) { return null; }
    if (tempo.length !== 4) { return null; }
    const hora = tempo.substring(0, 2);
    const minutos = tempo.substring(2, 4);
    // tslint:disable-next-line:max-line-length
    const isoStr1 = `2022-07-21T${hora}:${minutos}:00.820Z`; // cria uma data porque o retorno é apenas uma string e a diretiva espera uma data
    const date2 = new Date(isoStr1.slice(0, -1));
    return date2; // faz o set no campo input mask
  }

  salvarConfiguracoes() {
    const loggedUser = this.authGuard.loggedUser();
    try {
      const model = {
        viaM: this.formGroup.value.configuracaoViam !== null ? moment(this.formGroup.value.configuracaoViam).format('HHmm') : null,
        viaSat: this.formGroup.value.configuracaoViaSat !== null ? moment(this.formGroup.value.configuracaoViaSat).format('HHmm') : null,
      };
      this.isLoading = true;
      this.dashboardHomeService.salvarConfiguracoesInconformidades(loggedUser.selectedBranch.id, model).then(result => {
        this.isLoading = false;

        if (!result) {
          Swal.fire({
            type: 'error',
            title: 'Ops...',
            text: 'Ocorreu um erro ao atualizar as informações!',
            showConfirmButton: true,
            confirmButtonText: 'Ok',
          });
        } else {
          this.activeModal.close();
          Swal.fire({
            position: 'top-end',
            type: 'success',
            title: 'Sucesso.',
            text: 'Configuração alterada com sucesso!',
            showConfirmButton: false,
            timer: 3000
          });
        }
      });
    } catch (error) {
      this.isLoading = false;
      console.error(error);
    }
  }

  close() {
    this.activeModal.close();
  }
}
