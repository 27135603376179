import { Compiler, Component, OnInit, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material';
import { RetornoViagem } from 'app/classes/retorno-viagem';
import { Viagem } from 'app/classes/viagem';
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { ViagemService } from 'app/services/crud/viagem.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class ViagemFormComponent implements OnInit {

  public viagem: Viagem;
  dropdownList = [];
  public selectedItems = [];
  dropdownSettings: {};
  showVeiculos: boolean = false;
  showTabela: boolean = true;
  public listaViagem: any[];
  private listaParaExcluir: any[];
  private veiculo: String;


  constructor( private veiculoService: VeiculoService,
    private viagemService: ViagemService,
    private _compiler: Compiler) {
    this._compiler.clearCache();
   }


   @ViewChild('newViagemInicioDaViagem') newViagemInicioDaViagem;
  

   ngOnInit() {
    try {
      this.viagem = new Viagem();
      this.veiculoService.getLista().then(response => {
        var data = response.listaDeVeiculos;
        this.dropdownList = [];
        for (var i = 0; i < data.length; i++) {
          var veiculo = data[i];
          this.dropdownList.push({ item_id: veiculo.id, item_text: veiculo.placa });

        }
        this.dropdownSettings = {
          singleSelection: true,
          idField: 'item_id',
          textField: 'item_text',
          selectAllText: 'Todos',
          unSelectAllText: 'Desmarcar todos',
          itemsShowLimit: 3,
          allowSearchFilter: true,
          searchPlaceholderText: 'Pesquisar'
        };
        this.showVeiculos = true;
      });

    } catch (error) {
      console.error(error);
    }

  }

  onDeSelect(event) {
  }

  onItemSelect(event) {
    try {
      this.veiculo = event.item_text;
      this.showVeiculos = false;
      this.showTabela = true;
      this.buscarListaDeViagem(this.veiculo);
    } catch (error) {
      console.error(error);
    }
  }

  deleteViagemValue(index) {
    var cc = this.listaViagem[index];
    this.listaParaExcluir.push(cc);
    this.listaViagem.splice(index, 1);
  }

  adicionarViagem() {
    this.viagem.isNovo = true;
    this.viagem.placa = this.veiculo;
    this.listaViagem.push(this.viagem);
    this.viagem = new Viagem();
    this.newViagemInicioDaViagem.nativeElement.value = ' ';
  }

  gravarViagem() {
    this.showVeiculos = false;
    let ccInserir = this.listaViagem.filter(x => x.isNovo == true);
    let ccExcluir: number[] = [];
    this.listaParaExcluir.forEach(x => {
      if (x.id) {
        ccExcluir.push(x.id);
      }
    });

    this.viagemService.gravar(ccInserir, ccExcluir).then(response => {
      this.listaViagem.forEach(x => {
        x.isNovo = false;
      });
      this.showVeiculos = true;
      this.listaParaExcluir = [];
      this.viagem = new Viagem();
      this.buscarListaDeViagem(this.veiculo);
    });
  }

  buscarListaDeViagem(placaVeiculo) {
    try {
      this.viagemService.getLista(placaVeiculo).then(response => {
        var resultItem: RetornoViagem = response;
        this.listaViagem = resultItem.listaViagens;
        this.showVeiculos = true;
        this.showTabela = false;
        this.listaParaExcluir = [];
        this.viagem = new Viagem();
      });
    } catch (error) {
      console.error(error);
    }
  }

}
