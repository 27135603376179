import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-map-motorista-details',
  templateUrl: './modal-detalhes-local-component.html',
  styleUrls: ['./modal-detalhes-local-component.css']
})
export class ModalDetalhesLocalComponent implements OnInit {

  @Input() public dados: any;
  @ViewChild(MatSort) sort: MatSort;
  titulo: string;

  displayedColumns: string[] = [
    'inicioOcorrencia',
    'placa',
    'motorista',
    'atrasoInicioDescargaTempo',
    'atrasoFimDescargaTempo',
    'atrasoSaidaLojaTempo'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private paginator: MatPaginatorIntl
  ) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.dados.map((o) => ({
      inicioOcorrencia: o.dataRegistro !== null ? o.dataRegistro : 'Dado não encontrado',
      placa: o.placa !== null ? o.placa : 'Dado não encontrado',
      motorista: o.nomeMotorista !== null ? o.nomeMotorista : 'Dado não encontrado',
      atrasoInicioDescargaTempo: o.segundosAtrasoInicioDescarga !== null ? this.getHours(o.segundosAtrasoInicioDescarga) : 'Dado não encontrado',
      atrasoFimDescargaTempo: o.segundosAtrasoFimDescarga !== null ? this.getHours(o.segundosAtrasoFimDescarga) : 'Dado não encontrado',
      atrasoSaidaLojaTempo: o.segundosAtrasoSaidaLoja !== null ? this.getHours(o.segundosAtrasoSaidaLoja) : 'Dado não encontrado'
    })));
    this.dataSource.sort = this.sort;
    this.titulo = `Ocorrências de atrasos(${this.dados[0].nomeLocal})`;
  }

  getHours(seconds) {
    var date = new Date(null);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 8);
  }

  close() {
    this.activeModal.close();
  }
}
