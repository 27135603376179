
export class ResumoContadoresTelemetria{
    
    veiculos: number = 0;
    motoristas: number = 0;
    kmRodado: number = 0;
    litrosConsumidos: number = 0;
    kmLitro: number = 0;
    horasOciosas: string = "00:00";
    horasLigado: string = "00:00"
}
