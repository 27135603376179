import { BaseListComponent } from "../../../base/base-list/base-list.component";
import { Component, ViewEncapsulation } from "@angular/core";
import { EquipamentoService } from "../../../../services/crud/equipamento.service";
import { UtilService } from "../../../../services/utils.service";
import { PDFService } from "../../../../services/pdf.service";
import { AuthGuard } from "../../../../guards/auth.guard";

@Component({
  templateUrl: '../../../base/base-list/base-list.component.html',
  styleUrls: [ '../../../base/base-list/base-list.component.css' ],
  encapsulation: ViewEncapsulation.None
})
export class EquipamentoListComponent extends BaseListComponent {

  constructor(private  equipamentoService:  EquipamentoService,
    private utilService: UtilService,
    pdfService: PDFService,
    authGuard: AuthGuard) {
    super(utilService, pdfService, authGuard);
    this.searchField = "imei";
}

  ngOnInit() {
    this.pageTitle = "Cadastros | Equipamento";
      this.formPath = "/adm/equipamento/form";

      this.tableHeaders = [
          [
              this.createHeader("ID", 0, true),
              this.createHeader("IMEI", 1, true),
              this.createHeader("Modelo", 2, true) ,
              this.createHeader("Status", 2, true)         ]
      ];

      this.dataFields = [
          this.createField("id", "string"),
          this.createField("imei", "string"),
          this.createField("modelo.fabricante", "string"),
          this.createField("status", "boolean")
      ];

      this.equipamentoService.getLista().then(response => {
          this.data = response.listaEquipamento;
          this.loading = false;
      });
  }

}
