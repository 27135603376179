import { Component, OnInit } from '@angular/core';
import { AuthGuard } from '../../../guards/auth.guard';
import { User } from '../../../classes/user';
import { environment } from '../../../../environments/environment';
import { ChatService } from '../../../services/crud/chat.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertaService } from '../../../services/crud/alerta.service';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AlertaDialogComponent } from 'app/components/crud/alertas/alerta-dialog/alerta-dialog.component';


@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.css']
})
export class HeaderComponent implements OnInit {
    time: Date;
    loggedUser: User;
    notificationsCount: Observable<number>;
    showCountAlertas: boolean = false;

    constructor(private authGuard: AuthGuard,
        private chatService: ChatService,
        private alertaService:AlertaService,
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog,) {
        window.setInterval(() => this.time = new Date(), 1000);
        this.loggedUser = this.authGuard.loggedUser();
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            console.log('ngOnInit chamado!', params);
        });

        let bsurl = "";
        let loggedUser = this.authGuard.loggedUser();

        if (!loggedUser.selectedCompany || !loggedUser.selectedBranch) {
            this.authGuard.logout();
            return;
        }

        this.chatService.getBoshServiceUrl(loggedUser.selectedCompany.id).then(response => {
            /**Essa bosta chamava o chat do openfire */
            if (response.error) {
                console.error(response.error);
            } else {
                bsurl = response.response;
            }
        });

        this.alertaService.getTotalAlertaNovos(loggedUser.id).then(response =>{
            if (response.error) {
                console.error(response.error);
            } else {
                const ultimoAcesso = localStorage.getItem('ultimoDialog');
                const agora = new Date().getTime();
                //7200000
                let abrir = (!ultimoAcesso || agora - Number(ultimoAcesso) > 7200000);

                var count = response.response;
                if(count > 0){
                    this.showCountAlertas = true;
                }
                this.alertaService.updateNotificationsCount(count);
                this.notificationsCount = this.alertaService.getNotificationsCount();
                if (this.showCountAlertas && abrir) {
                    const dialogRef = this.dialog.open(AlertaDialogComponent, {
                        data: {
                            message: ''
                        }
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        localStorage.setItem('ultimoDialog', new Date().getTime().toString());
                        if (result) {
                            
                        }
                    });
                }
            }
        })
    }

    public logout(): void {
        this.authGuard.logout();
    }

    public irParaTelaQuadroAviso(){
       //this.router.navigateByUrl(`adm/quadro-alertas`);
       const dialogRef = this.dialog.open(AlertaDialogComponent, {
            data: {
                message: ''
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('dialogRef.afterClosed', result);
            if (result) {
                
            }
        });
    }

}
