import { BaseListComponent } from "../../../base/base-list/base-list.component";
import { Component, ViewEncapsulation } from "@angular/core";
import { VehicleTypeService } from "../../../../services/crud/vehicle-type.service";
import { UtilService } from "../../../../services/utils.service";
import { PDFService } from "../../../../services/pdf.service";
import { AuthGuard } from "../../../../guards/auth.guard";

@Component({
    templateUrl: '../../../base/base-list/base-list.component.html',
    styleUrls: [ '../../../base/base-list/base-list.component.css' ],
    encapsulation: ViewEncapsulation.None
})
export class VehicleTypeListComponent extends BaseListComponent {
    constructor(private vehicleTypeService: VehicleTypeService,
        private utilService: UtilService,
        pdfService: PDFService,
        authGuard: AuthGuard) {
        super(utilService, pdfService, authGuard);
        this.searchField = "descricao";
    }

    ngOnInit() {
        this.pageTitle = "Cadastros | Tipo de Veículos";
        this.formPath = "/adm/vehicletype/form";

        this.tableHeaders = [
            [
                this.createHeader("Descrição", 0, true),
                this.createHeader("Permite Acoplamento", 1, true),
                this.createHeader("Tração Própria", 2, true)
            ]
        ];

        this.dataFields = [
            this.createField("descricao", "string"),
            this.createField("permiteAcoplamento", "boolean"),
            this.createField("tracaoPropria", "boolean")
        ];

        this.vehicleTypeService.getListByCompany().then(response => {
            this.data = response.response;
            this.loading = false;
        });
    }
}