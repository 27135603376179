import { Component, NgZone, ElementRef, ViewChild } from "@angular/core";
import { BaseFormComponent } from "../../../base/base-form/base-form.component";
import { ActivatedRoute, Router } from "@angular/router";
import { IdentificacaoOperadorService } from "../../../../services/crud/identificacaooperador.service";
import { RESTResponseVO } from "../../../../classes/restresponsevo";
import { AuthGuard } from "../../../../guards/auth.guard";
import { IdentificacaoOperador } from "app/classes/identificacaooperador";
import { RetornoIdentificacaoOperador } from "app/classes/retorno-identificacao-operador";
import { MotoristaOperador } from "app/classes/motorista-operador";
import { DualListComponent } from 'angular-dual-listbox';
import { VeiculoAutorizacao } from "app/classes/veiculo-autorizacao";

/**
 * COMPONENTE DO DUAL LIST
 * https://www.npmjs.com/package/angular-dual-listbox
 */

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class IdentificacaoOperadorFormComponent extends BaseFormComponent<IdentificacaoOperador>  {

  listaMotoristaOperador: MotoristaOperador[];
  listaVeiculoAutorizacao: VeiculoAutorizacao[];

  keepSorted = true;
  listMotOper: Array<MotoristaOperador>;
  keyMotOper: string;
  displayMotOper: any;
  filter = true;
  confirmedMotOper: Array<MotoristaOperador>;
  listVeiculo: Array<VeiculoAutorizacao>;
  keyVeiculo: string;
  confirmedVeiculo: Array<VeiculoAutorizacao>;
  displayVeiculo: any;

  format: any = {
    add: 'Adicionar', remove: 'Remover', all: 'Selecionar Todos', none: 'Desmarcar',
    direction: DualListComponent.LTR, draggable: true, locale: 'pt_BR'
  };
  disabled = false;


  constructor(private identificacaoOperadorService: IdentificacaoOperadorService,
    private authGuard: AuthGuard,
    route: ActivatedRoute,
    private ngZone: NgZone,
    private router: Router) {
    super(route, router, 'adm/identificacaooperador/list');

  }


  loadObject(): void {
    if (this.activeID && (this.activeID !== 0)) {
      this.identificacaoOperadorService.getById(this.activeID).then(response => {
        var resultItem: RetornoIdentificacaoOperador = response;
        this.activeObject = resultItem.identificacaoOperador;
        this.buscarMotoristaOperador(true);
        this.buscarVeiculoAutorizacao(true);
      });
    } else {
      this.router.navigate(['adm/identificacaooperador/list']);
    }
    this.loading = false;
  }


  initObject(): void {
    this.activeObject = new IdentificacaoOperador();
    this.loading = false;
    this.buscarMotoristaOperador(false);
    this.buscarVeiculoAutorizacao(false);
  }


  buscarMotoristaOperador(validarCadastrado: boolean) {
    try {
      this.identificacaoOperadorService.buscarMotoristaOperador().then(response => {
        var resultItem: RetornoIdentificacaoOperador = response;
        this.listaMotoristaOperador = resultItem.listaMotoristaOperador;
        this.listMotOper = this.listaMotoristaOperador;
        this.keyMotOper = 'id';
        this.displayMotOper = this.motoristaOperadorLabel;
        this.confirmedMotOper = new Array<MotoristaOperador>();
        if (validarCadastrado) {
          this.ajustarconfirmedMotOper();
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  buscarVeiculoAutorizacao(validarCadastrado: boolean) {
    try {
      this.identificacaoOperadorService.buscarVeiculoAutorizacao().then(response => {
        var resultItem: RetornoIdentificacaoOperador = response;
        this.listaVeiculoAutorizacao = resultItem.listaVeiculoAutorizacao;
        this.listVeiculo = this.listaVeiculoAutorizacao;
        this.keyVeiculo = 'id';
        this.confirmedVeiculo = new Array<VeiculoAutorizacao>();
        this.displayVeiculo = this.veiculoLabel;
        if (validarCadastrado) {
          this.ajustarConfirmedVeiculo();
        }

      });
    } catch (error) {
      console.error(error);
    }
  }

  assembleObjectFromForm(): void {

  }
  loadAuxiliaryLists(): void {

  }


  doCreate(t: IdentificacaoOperador): Promise<RESTResponseVO<IdentificacaoOperador>> {
    try {
      var listaDeMot = "";
      var listDeOper = ""
      this.confirmedMotOper.forEach(e => {
        if ("O" == e.tipo) {
          listDeOper = listDeOper + e.id + ";";
        } else {
          listaDeMot = listaDeMot + e.id + ";";
        }
      });
      t.listaMotoristas = listaDeMot;
      t.listaOperadores = listDeOper;

      var listVei = "";
      this.confirmedVeiculo.forEach(e => {
        listVei = listVei + e.id + ";";
      });

      t.listaVeiculos = listVei;
      return this.identificacaoOperadorService.cadastrarIdentificacaoOperador(t);
    } catch (error) {
      console.error(error);
    }

  }


  doEdit(t: IdentificacaoOperador): Promise<RESTResponseVO<IdentificacaoOperador>> {
    try {
      var listaDeMot = "";
      var listDeOper = ""
      this.confirmedMotOper.forEach(e => {
        if ("O" == e.tipo) {
          listDeOper = listDeOper + e.id + ";";
        } else {
          listaDeMot = listaDeMot + e.id + ";";
        }
      });
      t.listaMotoristas = listaDeMot;
      t.listaOperadores = listDeOper;

      var listVei = "";
      this.confirmedVeiculo.forEach(e => {
        listVei = listVei + e.id + ";";
      });

      t.listaVeiculos = listVei;
      return this.identificacaoOperadorService.editarIdentificacaoOperador(t);
    } catch (error) {
      console.error(error);
    }
  }
  doDelete(t: IdentificacaoOperador): Promise<RESTResponseVO<string>> {
    throw new Error("Method not implemented.");
  }
  setActiveID(): void {
    try {
      if (this.activeObject == undefined) {
        this.activeID = 0;
      } else {
        this.activeID = this.activeObject.id;
      }
    } catch (e) {
      console.log(e);
    }

  }

  private motoristaOperadorLabel(motoristaOperador: MotoristaOperador) {
    var valor = motoristaOperador.nome + ', ' + motoristaOperador.funcao;
    if (motoristaOperador.tipo == 'M') {
      valor = valor + ', CNH: ' + motoristaOperador.categoriaCnh;
    }
    return valor;
  }

  private veiculoLabel(veiculoAutorizacao: VeiculoAutorizacao) {
    return veiculoAutorizacao.placa + ', ' + veiculoAutorizacao.fabricanteVeiculo + '-' + veiculoAutorizacao.modeloVeiculo;
  }

  private ajustarConfirmedVeiculo() {
    try {
      if (this.activeObject && this.activeObject.id && this.activeObject.id > 0) {
        var idsVeiculos = this.activeObject.listaVeiculos.split(";");
        idsVeiculos.forEach(e => {
          var v = this.listaVeiculoAutorizacao.find(f => f.id == Number(e));
          if (v) {
            this.confirmedVeiculo.push(v);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  private ajustarconfirmedMotOper() {
    try {
      if (this.activeObject && this.activeObject.id && this.activeObject.id > 0) {
        var idsMotOper = this.activeObject.listaMotoristas.split(";");
        idsMotOper.forEach(e => {
          var v = this.listaMotoristaOperador.find(f => f.id == Number(e));
          if (v) {
            this.confirmedMotOper.push(v);
          }
        });
        idsMotOper = this.activeObject.listaOperadores.split(";");
        idsMotOper.forEach(e => {
          var v = this.listaMotoristaOperador.find(f => f.id == Number(e));
          if (v) {
            this.confirmedMotOper.push(v);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

}
