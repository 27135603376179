import { IDEntity } from './IDEntity';
import { IDNameTuple } from './id-name-tuple';

export class User extends IDEntity {
    userName: string;
    password: string;
    criptoPassword: string;
    confirmPassword: string;
    email: string;
    situacao: boolean;
    habilitarAtualizacaoDashboard: boolean;
    selectedCompany: IDNameTuple;
    selectedBranch: IDNameTuple;
    companyList: IDNameTuple[];
    branchList: IDNameTuple[];
    branchMap: {};
    claimList: IDNameTuple[];
    receberAlertasEventos: boolean;
    mandarEmail: boolean;
    mandarSms: boolean;
    mandarSmsExcessoVelocidade: boolean;
    numeroCelularEnvioSms: string;
    tempoAtualizacaoDashboard: string;
    consultaJornada: boolean;
    consulataGradeJornada: boolean;
    relatorioViamMarcacao: boolean;
    relatorioViamChat: boolean;
    relatorioViamEvento: boolean;
    relatorioViasarCamaraFria: boolean;
    relatorioViasatDisponibilidade: boolean;
    relatorioViaSarDispositivoPortatil: boolean;
    relatorioViaSatEvento: boolean;
    relatorioViaSatHorimetro: boolean;
    relatorioViaSatHodometro: boolean;
    ralatorioViaSatPercurso: boolean;
    relatorioViaSatVelocidade: boolean;
    relatorioViaSatTomadaForca: boolean;
    cadastroUnidade: boolean;
    cadastroMotorista: boolean;
    cadastroVeiculo: boolean;
    cadastroLocal: boolean;
    cadastroGrupoLocais: boolean;
    cadastroRota:boolean;
    cadastroFeriados: boolean;
    cadastroUsuario: boolean;
    cadastroCor: boolean;
    cadastroTolerancia: boolean;
    cadastroTurno: boolean;
    cadastroMotivo: boolean;
    cadastroEquipamentoPortatil: boolean;
    cadastroTipoVeiculo: boolean;
    cadastroModeloVeiculo: boolean;
    cadastroTipoEquipamento: boolean;
    acessarIntegracao: boolean;
    acessarViam: boolean;
    acessarViaSat: boolean;
    acessarRelatorioDiariaMotorista: boolean;
    acessarCadastroDiariaMotorista: boolean;
    acessarMacro: boolean;
    acessarOperador: boolean;
    acessarHodometro: boolean;
    acessarRelatorioPresencaEquipamento: boolean;
    acessarRelatorioTransporteEquipamento: boolean;
    acessarRelatorioSensorBetoneira: boolean;
    acessarControleCombustivel: boolean;
    acessarGestao: boolean;
    cadastrarTipoRanking: boolean;
    acessoRankingMotorista: boolean;
    acessoSbh: boolean;
    relatorioViamEventoMotorista: boolean;
    habilitarAutenticacao: boolean;
    relatorioPontosPercorridos: boolean;
    relatorioProdutividadeVeiculo: boolean;
    acessarDesempenhoVeiculo: boolean;
    acessarDetalhesMonitoramento: boolean;
    acessarMotoristaTelaMonitoramento: boolean;
    agruparVeiculosMapaMonitoramento:boolean;
    acessarMonitoramentoJornada:boolean;
    acessarTelemetria:boolean;
    acessarIndicadorJornadaMotorista:boolean;
    acessarAnaliseJornadaAberto:boolean;
    verFormularioHodometro:boolean;
    listaMacros:boolean;
    listaMetas:boolean;
    listaOperador:boolean;
    canViewExportSchedule:boolean;
    canViewDriverProductivity:boolean;
    canViewExportAudit:boolean;
    canViewNonconformityDetails:boolean;
    relatorioViasatDetalhesPercursoVelocidade:boolean;
    relatorioViasatDetalhesRastreadorPortatil:boolean;
    relatorioViasatEstimativaProducao:boolean;
    verGraficoProducao:boolean;
    verRelatoriotempocarregamentografico:boolean;
    Verrelatoriotempotransportegrafico:boolean;
    verRelatoriograficovelocidade:boolean;
    verRelatorionumeroviagem:boolean;
    periodoParado:boolean;
    verTempoDirecao:boolean;
    cpf:string;
}
