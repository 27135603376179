import { Injectable } from '@angular/core';
import { CRUDService } from '../base/crud.service';
import { Http } from '@angular/http';
import { environment } from '../../../environments/environment';
import { RESTResponseVO } from "../../classes/restresponsevo";
import { User } from "../../classes/user";
import { AuthGuard } from "../../guards/auth.guard";
import { TipoRanking } from 'app/classes/tipo-ranking';
import { RetornoTipoRanking } from 'app/classes/retorno-tipo-ranking';
import { TipoRankingSelect } from 'app/classes/tipo-ranking-select';

@Injectable({
  providedIn: 'root'
})
export class TipoRankingService extends CRUDService<TipoRanking> {

  constructor(http: Http, public authGuard: AuthGuard) {
    super(http, environment.baseURL + 'App/v2/web/tiposderanking', true);
    var loggedUser: User = authGuard.loggedUser();
    var branch: string = loggedUser.selectedBranch.id === 0 && '0' || loggedUser.selectedBranch.id.toString();
    this.listURL = environment.baseURL +
      "App/v2/web/tiposderanking/lista/" + loggedUser.selectedCompany.id + "/" + branch;
  }


  getLista() {
    const url: string =
      (this.listURL === undefined && `${this.defaultUrl}`) || `${this.listURL}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoTipoRanking;
        }
      })
      .catch(this.handleError);
  }

  getById(id: number) {
    const url = `${this.defaultUrl}/buscarPeloId/${id}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoTipoRanking;
        }
      })
      .catch(this.handleError);
  }

  cadastrarTipoRanking(tipoRanking: TipoRanking): Promise<RESTResponseVO<TipoRanking>> {
    const url = `${this.defaultUrl}/cadastrar`;
    return this.enviarRequisicao(tipoRanking, url);
  }

  editarTipoRanking(tipoRanking: TipoRanking): Promise<RESTResponseVO<TipoRanking>> {
    const url = `${this.defaultUrl}/editar`;
    return this.enviarRequisicao(tipoRanking, url);
  }

  enviarRequisicao(tipoRanking: TipoRanking, url: string): Promise<RESTResponseVO<TipoRanking>> {
    try {
      let json = JSON.stringify(tipoRanking);
      return this.http
        .post(url, json , { headers: this.headers })
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            var retorno: RetornoTipoRanking = response.json() as RetornoTipoRanking;
            var tipoRanking: TipoRanking = retorno.tipoRanking;
            if (tipoRanking === null) {
              tipoRanking = new TipoRanking();
            }
            tipoRanking.id = 0;
            return tipoRanking;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }


  getTipoRanking(): TipoRankingSelect[] {
    let listaSelect = [
      new TipoRankingSelect(1, "Acelaração Brusca"),
      new TipoRankingSelect(2, "Curva Brusca"),
    //  new TipoRankingSelect(3, "Curva Brusca Esquerda"),
      new TipoRankingSelect(4, "Freada Brusca"),
      new TipoRankingSelect(5, "Excesso Velocidade - 80km/H"),
      new TipoRankingSelect(6, "Excesso Velocidade Chuva - 60km/H"),
     // new TipoRankingSelect(7, "Marcha Lenta"),
      new TipoRankingSelect(8, "Direção acima da faixa verde"),
      new TipoRankingSelect(9, "Direção acima da faixa vermelha")
    ];
    return listaSelect;
  }

}
