import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthGuard } from 'app/guards/auth.guard';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  painelV1 = true

  constructor(
    private authGuard: AuthGuard,
  ) {
    const loggedUser = this.authGuard.loggedUser();
    this.painelV1 = loggedUser.selectedCompany.id !== 59

  }

  ngOnInit() {
  }

}
