import { Injectable } from '@angular/core';
import { CRUDService } from '../base/crud.service';
import { Http } from '@angular/http';
import { Vacation } from '../../classes/vacation';
import { environment } from '../../../environments/environment';

@Injectable()
export class VacationService extends CRUDService<Vacation> {
    constructor(http: Http) {
        super(http, environment.baseURL + 'App/v2/web/ferias', true);
    }
}