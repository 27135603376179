import { IDEntity } from './IDEntity';

export class IDNameTuple extends IDEntity {
    name: string;
    ternaryID: number;
    habilitarControleDeDiarias:boolean;
    habilitarRmLobore:boolean;
    habilitarMatriculaChapa:boolean;
    /* UI */
    css: string;

    constructor(id: number, name: string) {
        super();
        this.id = id;
        this.name = name;
    }
}
