import { Driver } from "./driver";
import { IDEntity } from "./IDEntity";
import { TurnoEscala } from "./turno-escala";

export class TurnoEscalaMotorista extends IDEntity{
    turnoEscalaId:TurnoEscala;
    motoristasId:Driver;
    dataCadastro:Date;
    dataRemocao:Date;
    ativo:boolean;
}