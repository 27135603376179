export class TimelineFaturamento {
    category:String;
    start:number;
    inicio:String;
    end:number;
    fim:String;
    color:String;
    task:String;
    nomeEvento:String
    orderm:number;
    tempoTotal:String;
    latitudeInicial:number;
    longitudeInicial:number;
    enderecoInicial:String;
    latitudeFinal:number;
    longitudeFinal:number;
    enderecoFinal:String;
    status:String;
    faturamento:String;
    hodometroInicial:String;
    hodometroFinal:String;
}
