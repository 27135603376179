import { IDEntity } from "./IDEntity";
import { HolidayScope } from "./holiday-scope";
import { City } from "./city";
import { FederativeUnit } from "./federative-unit";
import { Company } from "./company";

export class Holiday extends IDEntity {
    dataOcorrencia: Date;
    descricao: string;
    ambito: HolidayScope;
    cidade: City;
    uf: FederativeUnit;
    inicioFeriado: Date;
    fimFeriado: Date;
    empresaId: Company;
    idUnidade:number;
}