import { Component, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import { BaseFormComponent } from "../../../base/base-form/base-form.component";
import { ActivatedRoute, Router } from "@angular/router";
import { RESTResponseVO } from "../../../../classes/restresponsevo";
import { AuthGuard } from "../../../../guards/auth.guard";
import { GrupoLocais } from "app/classes/grupoLocais";
import { Subject } from "rxjs";
import { GrupoLocaisService } from "app/services/crud/grupo-locais.service";

declare const google: any;

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})

export class GrupoLocaisFormComponent extends BaseFormComponent<GrupoLocais> implements OnDestroy {
  
  protected _onDestroy = new Subject<void>();
  @ViewChild('search')
  public searchElementRef: ElementRef;
  public tcode: string;
  tempoMaxPermanenciaLocal: string;

  constructor( private authGuard: AuthGuard,
    private grupoLocaisService: GrupoLocaisService,
    route: ActivatedRoute,
    private router: Router) { super(route, router, 'adm/grupoLocais/list'); }


  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  
  assembleObjectFromForm(): void {   
  }

  loadAuxiliaryLists(): void {
  }
  
  loadObject(): void {
    if (this.activeID && (this.activeID !== 0)) {
      this.grupoLocaisService.getById(this.activeID).then(response => {
        var resultItem: GrupoLocais = response.grupoLocais;
        this.activeObject = resultItem;
        if(resultItem && resultItem.tempoMaxPermanenciaLocal) {
          this.tempoMaxPermanenciaLocal = this.converteParaTime(resultItem.tempoMaxPermanenciaLocal)
        }
      });
    } else {
      this.router.navigate(['adm/grupoLocais/list']);
    }
    this.loading = false;
  }
  initObject(): void {
    this.activeObject = new GrupoLocais();
    this.loading = false; 
  }

  doCreate(): Promise<RESTResponseVO<GrupoLocais>> {
    try{
      this.activeObject.tempoMaxPermanenciaLocal = this.converterParaMinutos(
        this.tempoMaxPermanenciaLocal)
      return this.grupoLocaisService.cadastrarGrupoLocais(this.activeObject);
    }
    catch(error){
      console.error(error);
      
    }    
  }

  doEdit(): Promise<RESTResponseVO<GrupoLocais>> {
    
    try {
      this.activeObject.tempoMaxPermanenciaLocal = this.converterParaMinutos(
        this.tempoMaxPermanenciaLocal)

      return this.grupoLocaisService.editarGrupoLocais(this.activeObject);
    } catch (e) {
      console.error(e);
    }
    
  }
  doDelete(t: GrupoLocais): Promise<RESTResponseVO<string>> {
    return null;
  }
  setActiveID(): void {
    try {
      if (this.activeObject == undefined) {
        this.activeID = 0;
      } else {
        this.activeID = this.activeObject.id;
      }
    } catch (e) {
      console.log(e);
    }
  }

  converteParaTime(tempoMaxPermanenciaLocal: number) : string {
    const minutes = tempoMaxPermanenciaLocal % 60;
    const hours = Math.floor(tempoMaxPermanenciaLocal / 60);
    return `${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes)}`;
  }

  converterParaMinutos(horaFormatoHHMM: string) {
    var partes = horaFormatoHHMM.split(':');
    if (partes.length === 2) {
        var horas = parseInt(partes[0], 10);
        var minutos = parseInt(partes[1], 10);

        if (!isNaN(horas) && !isNaN(minutos)) {
            return horas * 60 + minutos;
        }
    }
    
    return null;
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

}
