import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { Filtro } from 'app/classes/filtro';
import { ReportService } from 'app/services/report/report.service';
import { Veiculo } from 'app/classes/veiculo';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

@Component({
  selector: 'app-tempo-carregamento-grafico',
  templateUrl: './tempo-carregamento-grafico.component.html',
  styleUrls: ['./tempo-carregamento-grafico.component.css']
})
export class TempoCarregamentoGraficoComponent implements OnInit {

  @ViewChild('iframe') iframe: ElementRef;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: {};
  public filtro: Filtro;
  public mostrarPdf: boolean = false;
  public pdfSrc: any;
  listaDeVeiculos: Veiculo[] = [];
  veiculo: Veiculo;
  responseBase64: String;
  funcao: number = 2;
  showGrafico: boolean = false;
  totalDeViagens: number = 0;
  pesoMedio: number = 45;
  public peso: String;
  totalProducao: number = 0;
  erro: String;
  ob: any;

  constructor(private veiculoService: VeiculoService, private reportService: ReportService) { }

  ngOnInit() {
    try {
      this.filtro = new Filtro();
      this.veiculoService.getLista().then(response => {
        var data = response.listaDeVeiculos;
        this.dropdownList = [];
        for (var i = 0; i < data.length; i++) {
          var veiculo = data[i];
          this.dropdownList.push({ item_id: veiculo.id, item_text: veiculo.placa });
        }

        this.dropdownSettings = {
          singleSelection: false,
          idField: 'item_id',
          textField: 'item_text',
          selectAllText: 'Todos',
          unSelectAllText: 'Desmarcar todos',
          itemsShowLimit: 3,
          limitSelection: 10,
          allowSearchFilter: true,
          searchPlaceholderText: 'Pesquisar'
        };
      });

    } catch (error) {
      console.error(error);
    }
  }

  onItemSelect(item: any) {
    this.inserirVeiculoSelecionado(item);
  }
  onSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirVeiculoSelecionado(items[i]);
    }
  }
  onDeSelect(item: any) {
    this.removerVeiculoSelecionado(item);
  }

  inserirVeiculoSelecionado(item: any): void {
    try {
      var veiculo = this.listaDeVeiculos.find(v => v.id == item.item_id);
      if (!veiculo) {
        this.veiculo = new Veiculo();
        this.veiculo.id = item.item_id;
        this.veiculo.placa = item.item_text;
        this.listaDeVeiculos.push(this.veiculo);
      }
    } catch (error) {
      console.log(error);
    }
  }

  removerVeiculoSelecionado(item: any): void {
    try {
      let index = this.listaDeVeiculos.findIndex(v => v.id == item.item_id);
      this.listaDeVeiculos.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }


  doSearch() {
    try {
      this.filtro.listaDeVeiculos = this.listaDeVeiculos;
      this.reportService.getReportTempoCarregamentoGrafico(this.filtro).then(response => {
        let retorno = response.relatorioGrafico;
        let lista = retorno.listaTempoCarregamentoGrafico;
        this.carregarGrafico(lista);
        this.showGrafico = true;
      });
    } catch (error) {
      console.error(error);
    }
  }


  carregarGrafico(lista) {
    try {
      am4core.useTheme(am4themes_animated);
      let chart = am4core.create('chartdivgrafico', am4charts.XYChart)
      chart.colors.step = 2;

      chart.legend = new am4charts.Legend()
      chart.legend.position = 'top'
      chart.legend.paddingBottom = 20
      chart.legend.labels.template.maxWidth = 95

      let xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      xAxis.dataFields.category = 'category'
      xAxis.renderer.cellStartLocation = 0.1
      xAxis.renderer.cellEndLocation = 0.9
      xAxis.renderer.grid.template.location = 0;

      let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
      yAxis.min = 0;

      let placaLista = [];
      let data = [];

      lista.forEach(e => {
        this.ob = {};
        let local = e.local;
        this.ob.category = local;
        let l2 = e.listaTempoCarregamento;
        l2.forEach(e2 => {
          let placa = e2.placa;
          if (!placaLista.includes(placa)) {
            placaLista.push(placa);
          }
          let horas = e2.horas;
          Object.defineProperty(this.ob, placa, {
            value: horas,
            writable: true,
            enumerable: true,
            configurable: true
          });
        });
        data.push(this.ob);
      });


      chart.data = data;

      placaLista.forEach(p => {
        this.createSeries(p, p, chart, xAxis);
      });




    } catch (error) {
      console.error(error);
    }
  }


  createSeries(value, name, chart, xAxis) {
    let series = chart.series.push(new am4charts.ColumnSeries())
    series.dataFields.valueY = value
    series.dataFields.categoryX = 'category'
    series.name = name

    series.events.on("hidden", this.arrangeColumns(chart, xAxis));
    series.events.on("shown", this.arrangeColumns(chart, xAxis));

    let bullet = series.bullets.push(new am4charts.LabelBullet())
    bullet.interactionsEnabled = false
    bullet.dy = 30;
    bullet.label.text = '{valueY} horas'
    bullet.label.fill = am4core.color('#ffffff')

    return series;
  }


  arrangeColumns(chart, xAxis) {

    let series = chart.series.getIndex(0);

    let w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
    if (series.dataItems.length > 1) {
      let x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
      let x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
      let delta = ((x1 - x0) / chart.series.length) * w;
      if (am4core.isNumber(delta)) {
        let middle = chart.series.length / 2;

        let newIndex = 0;
        chart.series.each(function (series) {
          if (!series.isHidden && !series.isHiding) {
            series.dummyData = newIndex;
            newIndex++;
          }
          else {
            series.dummyData = chart.series.indexOf(series);
          }
        })
        let visibleCount = newIndex;
        let newMiddle = visibleCount / 2;

        chart.series.each(function (series) {
          let trueIndex = chart.series.indexOf(series);
          let newIndex = series.dummyData;

          let dx = (newIndex - trueIndex + middle - newMiddle) * delta

          series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
          series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
        })
      }
    }
  }

}
