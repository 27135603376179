import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { catchError, debounceTime, distinctUntilChanged, finalize, switchMap, tap, map } from 'rxjs/operators';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs';
import { DashboardHomeService } from 'app/services/dashboard/dashboard.service';
import { AuthGuard } from 'app/guards/auth.guard';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-map-equipamento-details',
  templateUrl: './map-equipamento-details.component.html',
  styleUrls: ['./map-equipamento-details.component.scss']
})
export class MapEquipamentoDetailsComponent implements OnInit {

  @Input() public dados: any;
  titulo: string;
  erromessage = '';
  formGroup: FormGroup;
  formGroupTrajeto: FormGroup;
  formGroupObservacoes: FormGroup;
  inSpinner = false;
  mensagem = '';
  fotosCarregadas: any[] = [];
  errorMsg: string;
  isLoadingResult: boolean;
  active = 1;
  detalhes: any = {};
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  mapMarkers: any[] = [];
  listaDeInconformidades: any[] = [];
  lng: any;
  lat: any;
  observacoes: any[] = [];
  alertas: any[] = [];


  constructor(
    public activeModal: NgbActiveModal,
    private authGuard: AuthGuard,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private dashboardHomeService: DashboardHomeService
  ) {
    this.formGroup = this.formBuilder.group({
      motorista: false,
      frota: false,
      locais: false,
      localModel: [null],
      motoristaModel: [null],
      frotaModel: [null]
    });

    this.formGroupTrajeto = this.formBuilder.group({
      dataInicial: null,
      dataFinal: null
    });

    this.formGroupObservacoes = this.formBuilder.group({
      observacao: [null, Validators.required],
      id: null
    });
  }

  ngOnInit(): void {
    if (!!this.dados) {
      this.titulo = this.dados.placa;
      this.obterDadosDetalhes(this.dados.idObjeto);
    }
  }

  salvarObservacao() {

  }

  obterDadosDetalhes(id) {
    const loggedUser = this.authGuard.loggedUser();
    try {
      this.dashboardHomeService.getDadosDetalhesEquipamento(id).then(response => {
        this.detalhes = response;
        const split = response.detalheDashboard.latLong.split('/');
        this.lng = split[0];
        this.lat = split[1];
      });
    } catch (error) {
      console.error(error);
    }
  }

  markerDragEnd(mapMarker, event) {
    console.log('mapMarker >>>>>>>>>>>>>>>>> ', mapMarker);
    console.log('event >>>>>>>>>>>>>>>>> ', event);
  }

  onMarkerClickEvent(event, i) {
    console.log('i >>>>>>>>>>>>>>>>> ', i);
    console.log('event >>>>>>>>>>>>>>>>> ', event);
  }

  marcarComoLido(id) {
    const loggedUser = this.authGuard.loggedUser();
    try {
      this.dashboardHomeService.marcarComoLido(id, loggedUser.id).then(response => {

      });
    } catch (error) {
      console.error(error);
    }
  }

  filtrar() {
    console.log('===================>>>>', this.formGroupTrajeto.value);
  }

  trajeto() {
    console.log('===========trajeto========>>>>');
    try {
      this.dashboardHomeService.buscarTrajettoUltimas24horas(this.dados.id).then(response => {

      });
    } catch (error) {
      console.error(error);
    }
  }

  ocultar() {
    console.log('===========ocultar========>>>>');
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.formGroupTrajeto.patchValue({
        dataInicial: date
      });
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      this.formGroupTrajeto.patchValue({
        dataFinal: date
      });
    } else {
      this.formGroupTrajeto.patchValue({
        dataInicial: null,
        dataFinal: date
      });
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) &&
      date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) { return this.toDate && date.after(this.fromDate) && date.before(this.toDate); }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) ||
      this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }


  carregarFotos() {
    try {
      this.dashboardHomeService.carregarFotos(this.dados.id).then(response => {
        this.fotosCarregadas = response;
      });
    } catch (error) {
      console.error(error);
    }
  }

  detalhar() {

  }

  marcarImagemComoVista(id) {
    const loggedUser = this.authGuard.loggedUser();
    try {
      this.dashboardHomeService.marcarImagemComoVista(id).then(response => {

      });
    } catch (error) {
      console.error(error);
    }
  }

  downloadImagem(idFoto) {
    try {
      const foto = this.fotosCarregadas.find(x => x.id === idFoto);
      const a = document.createElement('a'); // Create <a>
      a.href = `data:image/png;base64,${foto.foto}`; // Image Base64 Goes here
      a.download = foto.dataRegistro + '.png'; // File name Here
      a.click(); // Downloaded file
    } catch (error) {
      console.error(error);
    }
  }

  getFotoBase64(array) {
    return `data:image/png;base64,${array}`;
  }

  close() {
    this.activeModal.close();
  }
}
