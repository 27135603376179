import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  appVersion = '00.07.11';
  title = 'Rodovia WebApp - ' + this.appVersion;
}
