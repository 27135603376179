/// <reference types="@types/googlemaps" />
import { Component, OnInit, Compiler, ViewChild, AfterViewInit } from '@angular/core';
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { FaturamentoService } from 'app/services/faturamento.service';
import { Veiculo } from 'app/classes/veiculo';
import { Filtro } from 'app/classes/filtro';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import am4lang_pt_BR from "@amcharts/amcharts4/lang/pt_BR";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";
import { DadosFaturamento } from 'app/classes/dados-faturamento';
import { DetalheGrafico } from 'app/classes/detalhe-grafico';
import { Faturamento } from 'app/classes/faturamento';
import { TimelineFaturamento } from 'app/classes/timeline-faturamento';
declare const google: any;

@Component({
  selector: 'app-faturamento',
  templateUrl: './faturamento.component.html',
  styleUrls: ['./faturamento.component.css']
})
export class FaturamentoComponent implements OnInit {

  public showGraphic: boolean;
  public showTimeLine: boolean = false;
  public showPesquisa: boolean = true;
  public showCarregouDados: boolean = false;
  public showAlerta: boolean = false;
  public showTituloModal: boolean = false;
  public showDadosOcioso: boolean = false;
  public showDadosEventoMotorista: boolean = false;
  public showCliqueTimeLine: boolean = false;
  public showTituloModalTimeline: boolean = false;
  public quantidadeDeDias: number;
  dropdownList = [];
  public selectedItems = [];
  private chart: any;
  dropdownSettings: {};
  public filtro: Filtro;
  public pdfSrc: any;
  listaDeVeiculos: Veiculo[] = [];
  veiculo: Veiculo;
  showVeiculos: boolean = false;
  showBotaoPesquisa: boolean = false;
  showCarregarDadosModal: boolean = false;
  showGrafico: boolean = false;
  public dataProvider: any[];
  public dataProviderTimeline: any[];
  public dataProviderGraficoProducao: any[];
  public placaSelecionada: String;
  public dadosFaturamento: DadosFaturamento = new DadosFaturamento();
  public corFaturamento: string;
  public tituloDaColuna: string;
  public detalheGrafico: DetalheGrafico;
  public contlinhas: number = 1;
  public motorista: String = "";
  public faturamento: Faturamento;
  listaTimelineFaturamento: TimelineFaturamento[];
  public timelineFaturamento: TimelineFaturamento;

  mapa: any;

  /*
  * Dados do detalhes do mapa
  */

  lat: number = 0;
  lng: number = 0;
  tempoTotal: String = "";
  mostrarFinal: boolean = false;
  mostrarMapa: boolean = false;
  mostrarDadosOcioso: boolean = false;
  ocultarInformcaoEndereco: boolean = false;


  @ViewChild('content') content;

  constructor(
    private veiculoService: VeiculoService,
    private faturamentoService: FaturamentoService,
    private router: Router,
    private _compiler: Compiler, private modalService: NgbModal,
  ) {
    this._compiler.clearCache();
  }


  ngOnInit() {
    try {
      //this.buildGraphic();
      this.filtro = new Filtro();
      this.detalheGrafico = new DetalheGrafico();
      this.timelineFaturamento = new TimelineFaturamento();
      this.veiculoService.getLista().then(response => {
        var data = response.listaDeVeiculos;
        this.dropdownList = [];
        for (var i = 0; i < data.length; i++) {
          var veiculo = data[i];
          this.dropdownList.push({ item_id: veiculo.id, item_text: veiculo.placa });

        }
        this.dropdownSettings = {
          singleSelection: false,
          idField: 'item_id',
          textField: 'item_text',
          selectAllText: 'Todos',
          unSelectAllText: 'Desmarcar todos',
          itemsShowLimit: 3,
          allowSearchFilter: true,
          limitSelection: 10,
          searchPlaceholderText: 'Pesquisar'
        };
        this.showVeiculos = true;
      });

    } catch (error) {
      console.error(error);
    }

  }

  onItemSelect(item: any) {
    this.inserirVeiculoSelecionado(item);
  }

  onSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirVeiculoSelecionado(items[i]);
    }
  }

  onDeSelect(item: any) {
    this.removerVeiculoSelecionado(item);
  }

  inserirVeiculoSelecionado(item: any): void {
    try {
      var veiculo = this.listaDeVeiculos.find(v => v.id == item.item_id);
      if (!veiculo) {
        this.veiculo = new Veiculo();
        this.veiculo.id = item.item_id;
        this.veiculo.placa = item.item_text;
        this.listaDeVeiculos.push(this.veiculo);
      }
    } catch (error) {
      console.log(error);
    }
  }

  removerVeiculoSelecionado(item: any): void {
    try {
      let index = this.listaDeVeiculos.findIndex(v => v.id == item.item_id);
      this.listaDeVeiculos.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }

  doSearch() {
    this.showBotaoPesquisa = true;
    try {
      this.filtro.listaDeVeiculos = this.listaDeVeiculos;
      this.faturamentoService.getResumoFaturamento(this.filtro).then(response => {
        this.quantidadeDeDias = response.response.quantidadeDeDias;
        let resumoFaturamento = response.response.listaResumoFaturamento;
        this.dataProvider = new Array();
        resumoFaturamento.forEach(rf => {
          this.dataProvider.push({
            placa: rf.placa,
            alto: rf.faturando,
            medio: rf.ocioso,
            baixo: rf.indisponivel,
            horasIndisponivel: rf.horasIndisponivel,
            horasOcioso: rf.horasOcioso,
            horasFaturando: rf.horasFaturando
          });
        });

        this.buildGraphic();
        this.showBotaoPesquisa = false;
        this.showGrafico = true;
      });
    } catch (error) {
      console.error(error);
    }
  }

  buildGraphic() {
    try {
      am4core.useTheme(am4themes_animated);
      this.chart = am4core.create("chartdiv", am4charts.XYChart);
      this.chart.data = this.dataProvider;
      let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "placa";
      categoryAxis.renderer.grid.template.location = 0;
      /*
      COLOCAR O LABEL VIRADO
      categoryAxis.renderer.labels.template.rotation = -45;
      categoryAxis.renderer.labels.template.horizontalCenter = "right";
      categoryAxis.renderer.labels.template.verticalCenter = "middle";
      categoryAxis.renderer.labels.template.maxWidth = this.dataProvider.length;
      */

      let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.inside = true;
      valueAxis.renderer.labels.template.disabled = true;
      valueAxis.min = 0;



      this.createSeries("baixo", "Improdutivo", "#FF6600");
      this.createSeries("medio", "Ocioso", "#FCD202");
      this.createSeries("alto", "Em produção", "#B0DE09");



      this.chart.exporting.menu = new am4core.ExportMenu();

      this.chart.legend = new am4charts.Legend();
      this.showGrafico = true;

    } catch (error) {
      console.log(error);
    }
  }

  createSeries(field, name, cor) {
    // Set up series
    let series = this.chart.series.push(new am4charts.ColumnSeries());
    series.name = name;
    series.dataFields.valueY = field;
    series.dataFields.categoryX = "placa";
    series.sequencedInterpolation = true;

    series.stroke = am4core.color(cor);
    series.columns.template.stroke = am4core.color(cor);
    series.columns.template.fill = am4core.color(cor);
    // Make it stacked
    series.stacked = true;

    // Configure columns
    series.columns.template.width = am4core.percent(60);
    series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}%";


    // Add label
    let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    if (field === "baixo") {
      labelBullet.label.text = "{horasIndisponivel} h - {valueY}%";
    }
    if (field === "medio") {
      labelBullet.label.text = "{horasOcioso} h - {valueY}%";
    }
    if (field === "alto") {
      labelBullet.label.text = "{horasFaturando} h - {valueY}%";
    }

    labelBullet.locationY = 0.5;
    labelBullet.label.hideOversized = true;

    series.columns.template.events.on("hit", function (ev) {
      const val = ev.target.dataItem.categoryX;
      this.criarTimeLine(val);
    }, this);



    return series;
  }

  criarTimeLine(placa: string) {
    try {
      this.placaSelecionada = placa;
      this.showBotaoPesquisa = true;
      this.filtro.listaDeVeiculos = this.listaDeVeiculos;
      let veiculoSele: Veiculo = this.filtro.listaDeVeiculos.find(x => x.placa == placa);
      let filtroNovo: Filtro = new Filtro;
      filtroNovo.dataFinal = this.filtro.dataFinal;
      filtroNovo.dataInicial = this.filtro.dataInicial;
      filtroNovo.veiculo = veiculoSele;
      this.faturamentoService.getTimelineFaturamento(filtroNovo).then(response => {
        this.faturamento = response.response;
        this.quantidadeDeDias = response.response.quantidadeDeDias;
        this.motorista = response.response.nomeDoMotorista;
        this.listaTimelineFaturamento = response.response.listaTimelineFaturamento;
        this.dataProviderTimeline = new Array();

        this.listaTimelineFaturamento.forEach(rf => {
          this.dataProviderTimeline.push({
            category: rf.category,
            start: rf.start,
            end: rf.end,
            color: rf.color,
            task: rf.task
          });
        });


        let listaGraficoResumo = response.response.listaGraficoResumo;
        this.dataProviderGraficoProducao = new Array();
        listaGraficoResumo.forEach(rf => {
          this.dataProviderGraficoProducao.push({
            evento: rf.evento,
            horas: rf.horas,
            cor: am4core.color(rf.cor)
          });
        });

        this.montarTimeLine();
        this.montarGraficoProducao();
        this.montarTotalizador();
        this.montarGraficoFaturamento(response.response.dadosFaturamento);
      });
    } catch (error) {
      console.error(error);
    }
  }

  voltarGraficos() {
    try {
      this.showTimeLine = false;
      this.showGrafico = true;
      this.showPesquisa = true;
    } catch (error) {
      console.error(error);
    }
  }

  montarTimeLine() {
    try {
      am4core.useTheme(am4themes_animated);
      // Themes endam4charts.XYChart am4charts.XYChart

      let timeline = am4core.create("chartdivtimeline", am4plugins_timeline.SerpentineChart);
      timeline.language.locale = am4lang_pt_BR;
      timeline.curveContainer.padding(50, 20, 50, 20);
      timeline.levelCount = 3;
      timeline.yAxisRadius = am4core.percent(25);
      timeline.yAxisInnerRadius = am4core.percent(-25);
      timeline.maskBullets = false;

      let colorSet = new am4core.ColorSet();
      colorSet.saturation = 0.5;

      timeline.data = this.dataProviderTimeline;

      timeline.dateFormatter.dateFormat = "dd/MM/yyyy HH:mm";
      timeline.dateFormatter.inputDateFormat = "dd/MM/yyyy HH:mm";
      timeline.fontSize = 11;

      let categoryAxis = timeline.yAxes.push(new am4charts.CategoryAxis() as any);
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.grid.template.disabled = true;
      categoryAxis.renderer.labels.template.paddingRight = 25;
      categoryAxis.renderer.minGridDistance = 10;
      categoryAxis.renderer.innerRadius = -60;
      categoryAxis.renderer.radius = 60;

      let dateAxis = timeline.xAxes.push(new am4charts.DateAxis() as any);
      dateAxis.renderer.minGridDistance = 70;
      dateAxis.baseInterval = { count: 1, timeUnit: "minute" };
      //dateAxis.baseInterval = { count: 1, timeUnit: "day" };
      dateAxis.renderer.tooltipLocation = 0;
      dateAxis.startLocation = -0.5;
      dateAxis.renderer.line.strokeDasharray = "1,4";
      dateAxis.renderer.line.strokeOpacity = 0.6;
      dateAxis.tooltip.background.fillOpacity = 0.2;
      dateAxis.tooltip.background.cornerRadius = 5;
      dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
      dateAxis.tooltip.label.paddingTop = 7;

      let labelTemplate = dateAxis.renderer.labels.template;
      labelTemplate.verticalCenter = "middle";
      labelTemplate.fillOpacity = 0.7;
      labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor("background");
      labelTemplate.background.fillOpacity = 1;
      labelTemplate.padding(7, 7, 7, 7);

      let series = timeline.series.push(new am4plugins_timeline.CurveColumnSeries());
      series.columns.template.height = am4core.percent(20);
      series.columns.template.tooltipText = "{task}: [bold]{openDateX}[/] - [bold]{dateX}[/]";

      series.dataFields.openDateX = "start";
      series.dataFields.dateX = "end";
      series.dataFields.categoryY = "category";
      series.dataFields.value = "inicio"
      series.columns.template.propertyFields.fill = "color"; // get color from data
      series.columns.template.propertyFields.stroke = "color";
      series.columns.template.strokeOpacity = 0;
      series.columns.template.events.on("hit", function (ev) {
        var categoria = ev.target.dataItem.categories.categoryY;
        var dates = ev.target.dataItem.dates;
        var di = dates.openDateX.getTime();
        var df = dates.dateX.getTime();
        this.timelineFaturamento = this.listaTimelineFaturamento.find(tf => tf.start == di && tf.end == df && tf.category == categoria);
        this.gerarInformacaoTimeLine();
      }, this);

      let bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.radius = 3;
      bullet.circle.strokeOpacity = 0;
      bullet.propertyFields.fill = "color";
      bullet.locationX = 0;


      let bullet2 = series.bullets.push(new am4charts.CircleBullet());
      bullet2.circle.radius = 3;
      bullet2.circle.strokeOpacity = 0;
      bullet2.propertyFields.fill = "color";
      bullet2.locationX = 1;

      timeline.scrollbarX = new am4core.Scrollbar();
      timeline.scrollbarX.align = "center"
      timeline.scrollbarX.width = am4core.percent(85);

      let cursor = new am4plugins_timeline.CurveCursor();
      timeline.cursor = cursor;
      cursor.xAxis = dateAxis;
      cursor.yAxis = categoryAxis;
      cursor.lineY.disabled = true;
      cursor.lineX.strokeDasharray = "1,4";
      cursor.lineX.strokeOpacity = 1;

      dateAxis.renderer.tooltipLocation2 = 0;
      categoryAxis.cursorTooltipEnabled = false;


      this.showGrafico = false;
      this.showPesquisa = false;
      this.showBotaoPesquisa = false;
      this.showTimeLine = true;
    } catch (error) {
      console.error(error);
    }
  }

  montarGraficoProducao() {
    try {
      this.dataProviderGraficoProducao.sort((a, b) => b.horas - a.horas);
      am4core.useTheme(am4themes_animated);
      var chart = am4core.create("chartdivgraficoimprodutividade", am4charts.XYChart);
      chart.data = this.dataProviderGraficoProducao;
      let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "evento";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.labels.template.fontSize = 10;
      categoryAxis.renderer.minGridDistance = 10;

      let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.opposite = true;

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = "horas";
      series.dataFields.categoryY = "evento";
      series.columns.template.tooltipText = "{categoryY}: [bold]{valueX}[/] horas";
      series.columns.template.strokeWidth = 0;
      series.columns.template.propertyFields.fill = "cor"; // get color from data
      series.columns.template.propertyFields.stroke = "cor";
      series.columns.template.strokeOpacity = 0;
      series.columns.template.interactionsEnabled = true;
      series.columns.template.events.on("hit", function (ev) {
        var item = ev.target.dataItem.categories;
        this.prepararDadosModal(item);
      }, this);

    } catch (error) {
      console.error(error);
    }
  }

  montarTotalizador() {
    try {

      let dataProviderTotalizador: any[] = new Array();
      let totalProduzido: number = 0;
      let totalImprodutivo: number = 0;
      let totalOsioso: number = 0;
      let totalEspera: number = 0;
      let totalVazio: number = 0;
      let totalCarregado: number = 0;
      this.dataProviderGraficoProducao.forEach(re => {
        if (re.evento === 'Ocioso') {
          totalOsioso = totalOsioso + re.horas;
        } else if (re.evento === 'Direção') {
          totalProduzido = totalProduzido + re.horas;
        } else if (re.evento === 'Carga' || re.evento === 'Descarga' || re.evento === 'Aguardando Descarga' || re.evento === 'Aguardando Carga') {
          totalEspera = totalEspera + re.horas;
        } else if (re.evento === 'Carregado') {
          totalCarregado = totalCarregado + re.horas;
        } else if (re.evento === 'Vazio') {
          totalVazio = totalVazio + re.horas;
        } else {
          if (re.evento != 'Faturando') {
            totalImprodutivo = totalImprodutivo + re.horas;
          }

        }
      });


      dataProviderTotalizador.push({
        evento: "Produzido",
        horas: totalProduzido,
        cor: am4core.color("#FFA500")
      });
      dataProviderTotalizador.push({
        evento: "Improdutivo",
        horas: totalImprodutivo,
        cor: am4core.color("#CCCCCC")
      });
      dataProviderTotalizador.push({
        evento: "Ocioso",
        horas: totalOsioso,
        cor: am4core.color("#FF0000")
      });
      dataProviderTotalizador.push({
        evento: "Espera",
        horas: totalEspera,
        cor: am4core.color("#54FF9F")
      });
      dataProviderTotalizador.push({
        evento: "Carregado",
        horas: totalCarregado,
        cor: am4core.color("#2E8B57")
      });
      dataProviderTotalizador.push({
        evento: "Vazio",
        horas: totalVazio,
        cor: am4core.color("#FF4500")
      });



      am4core.useTheme(am4themes_animated);
      var chart = am4core.create("chartdivgraficototalizador", am4charts.XYChart);
      chart.scrollbarX = new am4core.Scrollbar()
      chart.data = dataProviderTotalizador
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "evento";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.renderer.labels.template.horizontalCenter = "right";
      categoryAxis.renderer.labels.template.verticalCenter = "middle";
      categoryAxis.renderer.labels.template.rotation = 270;
      categoryAxis.tooltip.disabled = true;
      categoryAxis.renderer.minHeight = 110;

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.minWidth = 50;

      // Create series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.sequencedInterpolation = true;
      series.dataFields.valueY = "horas";
      series.dataFields.categoryX = "evento";
      series.tooltipText = "[{categoryX}: bold]{valueY}[/] horas";
      series.columns.template.strokeWidth = 0;
      series.columns.template.propertyFields.fill = "cor"; // get color from data
      series.columns.template.propertyFields.stroke = "cor";

      series.tooltip.pointerOrientation = "vertical";

      series.columns.template.column.cornerRadiusTopLeft = 10;
      series.columns.template.column.cornerRadiusTopRight = 10;
      series.columns.template.column.fillOpacity = 0.8;
      // on hover, make corner radiuses bigger
      var hoverState = series.columns.template.column.states.create("hover");
      hoverState.properties.cornerRadiusTopLeft = 0;
      hoverState.properties.cornerRadiusTopRight = 0;
      hoverState.properties.fillOpacity = 1;
      // Cursor
      chart.cursor = new am4charts.XYCursor();

    } catch (error) {
      console.error(error);
    }
  }

  createSeriesDois(valorHoras, name, chart) {
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.value = valorHoras;
    series.dataFields.valueY = 1000;
    series.name = name;
    series.columns.template.tooltipText = "{name}: [bold]{valueX}[/]";
    series.columns.template.height = am4core.percent(100);
    series.sequencedInterpolation = true;

    let valueLabel = series.bullets.push(new am4charts.LabelBullet());
    valueLabel.label.text = "{valueX}";
    valueLabel.label.horizontalCenter = "left";
    valueLabel.label.dx = 10;
    valueLabel.label.hideOversized = false;
    valueLabel.label.truncate = false;

    let categoryLabel = series.bullets.push(new am4charts.LabelBullet());
    categoryLabel.label.text = "{name}";
    categoryLabel.label.horizontalCenter = "right";
    categoryLabel.label.dx = -10;
    categoryLabel.label.fill = am4core.color("#fff");
    categoryLabel.label.hideOversized = false;
    categoryLabel.label.truncate = false;
  }

  montarGraficoFaturamento(dadosFaturamento: DadosFaturamento) {
    try {
      this.dadosFaturamento = dadosFaturamento;
      am4core.useTheme(am4themes_animated);
      // Themes end
      // create chart
      var chart = am4core.create("chartdivgraficofaturamento", am4charts.GaugeChart);
      chart.innerRadius = -15;

      var axis = chart.xAxes.push(new am4charts.ValueAxis() as any);
      axis.min = 0;
      axis.max = 100;
      axis.strictMinMax = true;

      var colorSet = new am4core.ColorSet();

      var gradient = new am4core.LinearGradient();
      gradient.stops.push({ color: am4core.color("red") })
      gradient.stops.push({ color: am4core.color("yellow") })
      gradient.stops.push({ color: am4core.color("green") })

      axis.renderer.line.stroke = gradient;
      axis.renderer.line.strokeWidth = 15;
      axis.renderer.line.strokeOpacity = 1;

      axis.renderer.grid.template.disabled = true;

      if (dadosFaturamento.porcetagemFaturamento < 33) {
        this.corFaturamento = "#FF0000";
      }

      if (dadosFaturamento.porcetagemFaturamento > 33 && dadosFaturamento.porcetagemFaturamento < 59) {
        this.corFaturamento = "#FFD700";
      }

      if (dadosFaturamento.porcetagemFaturamento > 59) {
        this.corFaturamento = "green";
      }

      var hand = chart.hands.push(new am4charts.ClockHand());
      hand.radius = am4core.percent(dadosFaturamento.porcetagemFaturamento);
      hand.showValue(dadosFaturamento.porcetagemFaturamento, 100, am4core.ease.cubicOut);
    } catch (error) {
      console.error(error);
    }
  }

  getCorFaturamento() {
    return this.corFaturamento
  }


  prepararDadosModal(nomeDaColuna) {
    try {
      let nomeColuna: string = nomeDaColuna.categoryY;
      if (nomeColuna.includes('DSR') || nomeColuna.includes('Interjornada') || nomeColuna.includes('Faturando')) {
        this.modalService.open(this.content, { centered: true, size: 'sm' });
        this.tituloDaColuna = nomeColuna;
        this.showCarregouDados = false;
        this.showTituloModal = false;
        this.showAlerta = true;
      } else {
        this.showAlerta = false;
        this.showCarregarDadosModal = true;
        this.modalService.open(this.content, { centered: true, size: 'lg' });
        this.filtro.nomeColuna = nomeColuna
        this.faturamentoService.getDetelheColuna(this.filtro).then(response => {
          this.detalheGrafico = response.response;
          this.showCarregarDadosModal = false;
          this.tituloDaColuna = nomeColuna;
          if (nomeColuna.includes('Ocioso')) {
            this.showDadosOcioso = true;
            this.showCarregouDados = false;
            this.showDadosEventoMotorista = false;
            this.showCliqueTimeLine = false;
          } else {
            if (nomeColuna.includes('Carregado') || nomeColuna.includes('Vazio')) {
              this.showDadosEventoMotorista = true;
              this.showDadosOcioso = false;
              this.showCarregouDados = false;
              this.showCliqueTimeLine = false;
            } else {
              this.showDadosEventoMotorista = false;
              this.showDadosOcioso = false;
              this.showCliqueTimeLine = false;
              this.showCarregouDados = true;
            }
          }
          this.showTituloModal = true;
          this.showTituloModalTimeline = false;
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  buscarCorTabela(status: String) {
    try {
      if (status === "Iniciou") {
        return "#FFFFFF";
      }
      if (status === "Finalizou") {
        return "#C6E2FF";
      }
      if (status.includes("Manual")) {
        return "#FFC1C1";
      }
    } catch (error) {
      console.error(error);
    }
    return "white";
  }

  zebrarTabela() {
    try {
      if (this.contlinhas % 2 == 0) {
        return "#C6E2FF";
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.contlinhas++;
    }
    return "white";
  }

  gerarExcel() {
    try {
      this.faturamento.placaDoVeiculo = this.placaSelecionada;
      this.faturamentoService.getExcelTimeline(this.faturamento).then(response => {
        let url = response.response;
        window.open(url, "_blank");
      });
    } catch (error) {
      console.log(error);
    }
  }

  gerarInformacaoTimeLine() {
    try {
      this.showDadosEventoMotorista = false;
      this.showDadosOcioso = false;
      this.showAlerta = false;
      this.showDadosEventoMotorista = false;
      this.showAlerta = false;
      this.showTituloModal = false;
      this.showCliqueTimeLine = true;
      this.showTituloModal = false;
      this.showTituloModalTimeline = true;

      if (this.timelineFaturamento.nomeEvento == "Vazio" || this.timelineFaturamento.nomeEvento == "Carregado") {
        return;
      }

      if (this.timelineFaturamento.nomeEvento == "Direção") {
        this.mostrarFinal = true;
        this.ocultarInformcaoEndereco = true;
        this.mostrarDadosOcioso = false;
      } else {
        if (this.timelineFaturamento.nomeEvento == "Ocioso") {
            this.ocultarInformcaoEndereco = false;
            this.mostrarDadosOcioso = true;

        } else {
          this.mostrarFinal = false;
          this.ocultarInformcaoEndereco = true;
          this.mostrarDadosOcioso = false;
        }
      }
      this.tempoTotal = this.timelineFaturamento.tempoTotal;
      this.modalService.open(this.content, { centered: true, size: 'lg' });
    } catch (error) {
      console.log(error);
    }
  }

  onMapReady(map) {
    try {
      this.initDrawingManager(map);
      this.mapa = map;
      if (this.mostrarFinal) {
        map.zoom = 10;
      } else {
        map.zoom = 18;
      }

      if ((this.timelineFaturamento.latitudeInicial && this.timelineFaturamento.longitudeInicial)
        && (this.timelineFaturamento.latitudeInicial != 0 && this.timelineFaturamento.longitudeInicial != 0)) {
        this.lat = this.timelineFaturamento.latitudeInicial;
        this.lng = this.timelineFaturamento.longitudeInicial;
      }

      if ((this.timelineFaturamento.latitudeFinal && this.timelineFaturamento.longitudeFinal)
        && (this.timelineFaturamento.latitudeFinal != 0 && this.timelineFaturamento.longitudeFinal != 0)) {
        this.lat = this.timelineFaturamento.latitudeFinal;
        this.lng = this.timelineFaturamento.longitudeFinal;
      }
 
      if ((this.timelineFaturamento.latitudeInicial && this.timelineFaturamento.longitudeInicial)
        && (this.timelineFaturamento.latitudeInicial != 0 && this.timelineFaturamento.longitudeInicial != 0)) {
        var myLatLng = { lat: this.timelineFaturamento.latitudeInicial, lng: this.timelineFaturamento.longitudeInicial };
        new google.maps.Marker({
          position: myLatLng,
          map,
          title: "Inicio " + this.timelineFaturamento.nomeEvento,
        });
      }

      if ((this.timelineFaturamento.latitudeFinal && this.timelineFaturamento.longitudeFinal)
        && (this.timelineFaturamento.latitudeFinal != 0 && this.timelineFaturamento.longitudeFinal != 0)) {
        var myLatLng = { lat: this.timelineFaturamento.latitudeFinal, lng: this.timelineFaturamento.longitudeFinal };
        new google.maps.Marker({
          position: myLatLng,
          map,
          title: "Final " + this.timelineFaturamento.nomeEvento,
        });
      }

      map.setMapTypeId('hybrid');

      if (this.mostrarFinal) {
        var directionsService = new google.maps.DirectionsService();
        var directionsRenderer = new google.maps.DirectionsRenderer();
        directionsRenderer.setMap(map);
        var inicio = '"' + this.timelineFaturamento.latitudeInicial + ',' + this.timelineFaturamento.longitudeInicial + '"';
        var fim = '"' + this.timelineFaturamento.latitudeFinal + ',' + this.timelineFaturamento.longitudeFinal + '"';
        var request = {
          origin: inicio,
          destination: fim,
          travelMode: google.maps.TravelMode.DRIVING
        };
        directionsService.route(request, function (result, status) {
          if (status == 'OK') {
            this.directionsRenderer.setDirections(result);
          }
        });
      }

    } catch (error) {
      console.log(error);
    }



  }



  initDrawingManager(map: any) {

  }

  center: any = {
    lat: -19.931281,
    lng: -43.939283,
    mapType: 'satellite'
  };

}
