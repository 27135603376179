import { Headers, Http } from '@angular/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { TipoFuncionario } from 'app/classes/tipo-funcionario';
import { RESTResponseVO } from 'app/classes/restresponsevo';
import { CRUDService } from '../base/crud.service';
import { AuthGuard } from 'app/guards/auth.guard';

@Injectable({
  providedIn: 'root'
})
export class TipoFuncionarioService extends CRUDService<TipoFuncionario> {

  private authGuard: any;
  defaultUrlAspCore: any;

  constructor(public http: Http, authGuard: AuthGuard) {
    super(http, environment.baseURL + 'App/v2/web', true)
    this.defaultUrlAspCore = environment.baseAspCoreURL + '/Funcionario';
    this.authGuard = authGuard;
  }

  getAll(companyId: any, branchId: any) {
    const url = `${this.defaultUrlAspCore}?unidadeId=${branchId}&empresaId=${companyId}`;
    return this.http.get(url, {headers: this.headers}).toPromise().then(response => {
      if (response) {
        return response.json();
      } else {
        return [];
      }
    })
      .catch(this.handleError);
  }

  getById(id: number): Promise<RESTResponseVO<TipoFuncionario>> {
    const loggedUser = this.authGuard.loggedUser();
    const companyId = loggedUser.selectedCompany.id;
    const branchId = loggedUser.selectedBranch.id;
    const url = `${this.defaultUrlAspCore}/${id}/${branchId}?empresaId=${companyId}`;
    return this.http.get(url, { headers: this.headers }).toPromise().then(response => {
      if (response) {
        return response.json();
      } else {
        return [];
      }
    })
      .catch(this.handleError);
  }

  create(tipofuncionario: TipoFuncionario) {
    const loggedUser = this.authGuard.loggedUser();
    const companyId = loggedUser.selectedCompany.id;
    const branchId = loggedUser.selectedBranch.id;
    tipofuncionario.empresaId = companyId;
    tipofuncionario.unidadeId = branchId;
    const url = `${this.defaultUrlAspCore}`;
    return this.http.post(url, tipofuncionario, { headers: this.headers })
      .toPromise()
      .then(response => {
      if (response) {
        return response.json();
      } else {
        return [];
      }
    })
      .catch(this.handleError);
  }

  updateTipo(id: number, tipofuncionario: TipoFuncionario): Promise<RESTResponseVO<TipoFuncionario>> {
    const loggedUser = this.authGuard.loggedUser();
    const companyId = loggedUser.selectedCompany.id;
    const branchId = loggedUser.selectedBranch.id;
    tipofuncionario.empresaId = companyId;
    tipofuncionario.unidadeId = branchId;
    const url = `${this.defaultUrlAspCore}/${id}`;
    return this.http.put(url, tipofuncionario, { headers: this.headers })
      .toPromise()
      .then(response => {
      if (response) {
        return response.json();
      } else {
        return [];
      }
    })
      .catch(this.handleError);
  }

  delete(id: string) {
    const url = `${this.defaultUrlAspCore}/${id}`;
    return this.http.get(url, { headers: this.headers })
      .toPromise()
      .then(response => {
      if (response) {
        return response.json();
      } else {
        return [];
      }
    })
      .catch(this.handleError);
  }

  handleError(error: any): Promise<any> {
    console.error('Ocorreu um erro: ', error);
    return Promise.reject(error.message || error);
  }
}
