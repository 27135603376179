import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { DriverService } from '../../../services/crud/driver.service';
import { Filtro } from 'app/classes/filtro';
import { ReportService } from 'app/services/report/report.service';
import { Motorista } from 'app/classes/motorista';

@Component({ 
  selector: 'app-evento-motorista',
  templateUrl: './evento-motorista.component.html',
  styleUrls: ['./evento-motorista.component.css']
})
export class EventoMotoristaComponent implements OnInit {

  dropdownList = [];
  selectedItems = [];
  dropdownSettings: {};
  public filtro: Filtro;
  public mostrarPdf: boolean = false;
  public pdfSrc: any;
  listaDeMotoristas: Motorista[] = [];
  motorista: Motorista;
  responseBase64: String;
  tipoEvento: number = 0;
  tipoRelatorio: String = "PDF";

  @ViewChild('iframe') iframe: ElementRef;



  constructor(private driverService: DriverService, private reportService: ReportService) { }

  ngOnInit() {
    try {
      this.filtro = new Filtro();
      this.driverService.getListMinimo().then(response => {
        var data = response.response;
        this.dropdownList = [];
        for (var i = 0; i < data.length; i++) {
          var motorista = data[i];
          var nome = motorista.nome + " " + motorista.sobrenome;
          this.dropdownList.push({ item_id: motorista.id, item_text: nome });
        }


        this.dropdownSettings = {
          singleSelection: false,
          idField: 'item_id',
          textField: 'item_text',
          selectAllText: 'Todos',
          unSelectAllText: 'Desmarcar todos',
          itemsShowLimit: 3,
          allowSearchFilter: true,
          searchPlaceholderText: 'Pesquisar'
        };
      });

    } catch (error) {
      console.error(error);
    }
  }


  onItemSelect(item: any) {
    this.inserirMotoristaSelecionado(item);
  }
  onSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirMotoristaSelecionado(items[i]);
    }
  }
  onDeSelect(item: any) {
    this.removerMotoristaSelecionado(item);
  }

  inserirMotoristaSelecionado(item: any): void {
    try {
      var moto = this.listaDeMotoristas.find(m => m.id == item.item_id);
      if (!moto) {
        this.motorista = new Motorista();
        this.motorista.id = item.item_id;
        this.listaDeMotoristas.push(this.motorista);
      }
    } catch (error) {
      console.log(error);
    }
  }

  removerMotoristaSelecionado(item: any): void {
    try {
      let index = this.listaDeMotoristas.findIndex(m => m.id == item.item_id);
      this.listaDeMotoristas.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }


  doSearch() {
    try {
      this.filtro.listaDeMotoristas = this.listaDeMotoristas;
      this.filtro.tipoArquivo = this.tipoRelatorio;
      if (this.tipoEvento && this.tipoEvento > 0) {
        this.filtro.tipoEventoMotorista = this.tipoEvento;
      }
      this.reportService.getReportRelatorioProdutividadeMotorista(this.filtro).then(response => {
        this.iframe.nativeElement.setAttribute('src', response);
      });

    } catch (error) {
      console.error(error);
    }
  }

  trocarRadioButton(value) {
    try {
      this.tipoRelatorio = value;
    } catch (error) {
      console.log(error);
    }
  }

}
