import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, MatTableDataSource } from '@angular/material';
import { ModalDetalhesLocalComponent } from './modal-detalhes-local-component';

@Component({
  selector: 'app-map-motorista-details',
  templateUrl: './modal-todos-locais-periodo-component.html',
  styleUrls: ['./modal-todos-locais-periodo-component.css']
})
export class ModalTodosLocaisPeriodoComponent implements OnInit {

  @Input() public dados: any;
  @ViewChild(MatSort) sort: MatSort;
  titulo: string;
  displayedColumns: string[] = [
    'local',
    'totalOcorrencias',
    'totalGeralHoras',
    'atrasoInicioDescarga',
    'atrasoInicioDescargaTempo',
    'atrasoFimDescarga',
    'atrasoFimDescargaTempo',
    'atrasoSaidaLoja',
    'atrasoSaidaLojaTempo'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private paginator: MatPaginatorIntl
  ) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.dados[0].map((o) => ({
      idLocal: o.idLocal,
      local: o.nomeLocal !== null ? o.nomeLocal : 'Dado não encontrado',
      totalOcorrencias: o.totalOcorrencias !== null ? o.totalOcorrencias : 'Dado não encontrado',
      totalGeralHoras: o.totalAtrasoSegundos !== null ? this.getHours(o.totalAtrasoSegundos) : 'Dado não encontrado',
      atrasoInicioDescarga: o.ocorrenciasAtrasoInicioDescarga !== null ? o.ocorrenciasAtrasoInicioDescarga : 'Dado não encontrado',
      atrasoInicioDescargaTempo: o.segundosAtrasoInicioDescarga !== null ? this.getHours(o.segundosAtrasoInicioDescarga) : 'Dado não encontrado',
      atrasoFimDescarga: o.ocorrenciasAtrasoFimDescarga !== null ? o.ocorrenciasAtrasoFimDescarga : 'Dado não encontrado',
      atrasoFimDescargaTempo: o.segundosAtrasoFimDescarga !== null ? this.getHours(o.segundosAtrasoFimDescarga) : 'Dado não encontrado',
      atrasoSaidaLoja: o.ocorrenciasAtrasoSaidaLoja !== null ? o.ocorrenciasAtrasoSaidaLoja : 'Dado não encontrado',
      atrasoSaidaLojaTempo: o.segundosAtrasoSaidaLoja !== null ? this.getHours(o.segundosAtrasoSaidaLoja) : 'Dado não encontrado'
    })));
    this.dataSource.sort = this.sort;
    this.titulo = `Ocorrências de atraso por local`;
  }

  abrirModal(row) {
    const modalRef = this.modalService.open(ModalDetalhesLocalComponent, { size: <any>'mysize', backdrop: 'static', keyboard: false, windowClass: 'vehicle-modal' });
    modalRef.componentInstance.dados = this.dados[1].filter((ocorrencia) => ocorrencia.idLocal === row.idLocal);
    modalRef.result.then((result) => {
      if (result) {
      }
    }).catch((res) => { });
  }

  getHours(seconds) {
    var date = new Date(null);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 8);
  }

  close() {
    this.activeModal.close();
  }
}
