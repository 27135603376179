import { Component } from "@angular/core";
import { EquipamentoPortatil } from "../../../../classes/equipamento-portatil";
import { BaseFormComponent } from "../../../base/base-form/base-form.component";
import { ActivatedRoute, Router } from "@angular/router";
import { EquipamentoPortatilService } from "../../../../services/crud/equipamento-portatil.service";
import { ModeloEquipamentoService } from "../../../../services/crud/modelo-equipamento.service";
import { RESTResponseVO } from "../../../../classes/restresponsevo";
import { AuthGuard } from "../../../../guards/auth.guard";
import { RetornoEquipamento } from "../../../../classes/retorno-equipamento";
import { RetornoModelo } from "../../../../classes/retorno-modelo";
import { Modelo } from "app/classes/modelo";
import { RetornoEquipamentoPortatil } from "app/classes/retorno-equipamento-portatil";
import { User } from "app/classes/user";

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
}) 

export class EquipamentoPortatilFormComponent extends BaseFormComponent<EquipamentoPortatil>  {

  retornoModelo: RetornoModelo;
  isAdmin:boolean;
  _listaDeModelo: Modelo[];
  constructor(private equipamentoService: EquipamentoPortatilService,
    private modeloEquipamentoService: ModeloEquipamentoService,
    private authGuard: AuthGuard, 
    route: ActivatedRoute,
    private router: Router) {
    super(route, router, 'adm/equipamentoportatil/list');
    var loggedUser: User = authGuard.loggedUser();
    this.isAdmin = loggedUser.claimList.some(x => x.name === "admin");
    this.buscarModeloEquipamento();
  }

  assembleObjectFromForm(): void {
  }

  loadAuxiliaryLists(): void {
  }

  loadObject(): void {
    if (this.activeID && (this.activeID !== 0)) {
      this.equipamentoService.getById(this.activeID).then(response => {
        var resultItem: RetornoEquipamentoPortatil = response;
        this.activeObject = resultItem.equipamentoPortatil;
      });
    } else {
      this.router.navigate(['adm/equipamentoportatil/list']);
    }
    this.loading = false;
  }


  initObject(): void {
    this.activeObject = new EquipamentoPortatil();
    this.loading = false;
    this.activeObject.modelo = new Modelo();
    this.buscarModeloEquipamento();
  }

  buscarModeloEquipamento() {
    try {
      var ret: Promise<any> = this.modeloEquipamentoService.getListaPortatil();
      ret.then(res => {
        this._listaDeModelo = res.listaDeModelo;
      });
    } catch (e) {
      console.error(e);
    }
  }


  doCreate(t: EquipamentoPortatil): Promise<RESTResponseVO<EquipamentoPortatil>> {
    return this.equipamentoService.cadastrarEquipamentoPortatil(t);
  }

  doEdit(t: EquipamentoPortatil): Promise<RESTResponseVO<EquipamentoPortatil>> {
    try {
      return this.equipamentoService.editarEquipamento(t);
    } catch (e) {
      console.error(e);
    }

  }

  doDelete(t: EquipamentoPortatil): Promise<RESTResponseVO<string>> {
    return null;
  }

  setActiveID(): void {
    try {
      if (this.activeObject == undefined) {
        this.activeID = 0;
      } else {
        this.activeID = this.activeObject.id;
      }
    } catch (e) {
      console.log(e);
    }

  }

  get listaDeModeloEquipamento() {
    return this._listaDeModelo;
  }


}
