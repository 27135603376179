import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-event-details',
    templateUrl: './event-details.component.html',
    styleUrls: ['./event-details.component.scss']
  })
  export class EventDetailsComponent implements OnInit {

    constructor(public activeModal: NgbActiveModal){

    }

    @Input() public dados: any;

    ngOnInit(): void {
        
    }
    
    close() {
      this.activeModal.close();
    }
  }