import { Injectable } from "@angular/core";
import { CRUDService } from "../base/crud.service";
import { Http } from "@angular/http";
import { User } from "../../classes/user";
import { AuthGuard } from "../../guards/auth.guard";
import { LoadingUnloadingPoints } from "../../classes/loading-unloading-points";
import { environment } from "../../../environments/environment";
import { RESTResponseVO } from "../../classes/restresponsevo";

@Injectable()
export class LoadingUnloadingPointsService extends CRUDService<LoadingUnloadingPoints> {
  constructor(http: Http, public authGuard: AuthGuard) {
    super(http, environment.baseURL + "App/v2/web/pontoscargadescarga", true);

    var loggedUser: User = authGuard.loggedUser();
    this.listURL = environment.baseURL + "App/v2/web/pontoscargadescarga/lista/" + loggedUser.selectedCompany.id;
  }

  public getListByCompany() {
    var url: string = this.listURL;

    return this.http.get(url).toPromise()
      .then(response => {
        return response.json() as RESTResponseVO<string>;
      })
      .catch(this.handleError);
  }
}
