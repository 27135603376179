import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseListComponent } from '../../../base/base-list/base-list.component';
import { HolidayService } from '../../../../services/crud/holiday.service';
import { UtilService } from '../../../../services/utils.service';
import { PDFService } from '../../../../services/pdf.service';
import { AuthGuard } from '../../../../guards/auth.guard';

@Component({
    templateUrl: '../../../base/base-list/base-list.component.html',
    styleUrls: [ '../../../base/base-list/base-list.component.css' ],
    encapsulation: ViewEncapsulation.None
})
export class HolidayListComponent extends BaseListComponent {

    constructor(private service: HolidayService,
        private utilService: UtilService,
        pdfService: PDFService,
        authGuard: AuthGuard) {
        super(utilService, pdfService, authGuard);
        this.searchField = "descricao";
    }

    ngOnInit() {
        this.pageTitle = "Cadastros | Feriados";
        this.formPath = "/adm/holiday/form";

        this.tableHeaders = [
            [
                this.createHeader("Descrição", 0, true),
                this.createHeader("Data Ocorrência", 1, true),
                this.createHeader("Cidade", 2, true),
                this.createHeader("UF", 3, true),
                this.createHeader("Ambito", 4, true)
            ]
        ];

        this.dataFields = [
            this.createField("descricao", "string"),
            this.createField("dataOcorrencia", "date"),
            this.createField("cidade.nome", "string"),
            this.createField("uf.sigla", "string"),
            this.createField("ambito.descricao", "string")
        ];

        this.service.getLista().then(response => {
            this.data = response.response; this.loading = false;
        });
    }
}
