import { Component, forwardRef, Output, EventEmitter, Input, ViewChild } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";

@Component({
    selector: 'autocomplete-input',
    template: `
        <div class="input-group input-group-sm">
            <ng-template #autoCompleteTemplate let-r="result" let-t="term">
                {{ getDisplayText(r) }}
            </ng-template>
            <input class="form-control form-control-sm" type="text" id="autoComplete" name="autoComplete"
                [ngbTypeahead]="typeahead" [resultTemplate]="autoCompleteTemplate" [inputFormatter]="inputFormatter" [(ngModel)]="obj" />
            <ng-content></ng-content>
        </div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutoCompleteInputComponent),
            multi: true
        }
    ]
})
export class AutoCompleteInputComponent implements ControlValueAccessor {

    @Input() getDisplayText: Function;
    @Input() typeahead: any;
    @Input() inputFormatter: any;
    @Input('obj') _obj: any;

    get obj() {
        return this._obj;
    }

    set obj(val) {
        this._obj = val;
        this.propagateChange(this._obj);
    }

    propagateChange = (_: any) => { };

    writeValue(obj: any): void {
        this._obj = obj;
     }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void { }

    setDisabledState?(isDisabled: boolean): void { }

    runBlur(event) {
        if ((typeof this.obj === "string") && (this.obj !== '')) {
            this.obj = "";
        }
    }
}
