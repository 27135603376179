import {
  Component,
  Input,
  forwardRef,
  OnInit,
  EventEmitter,
  Output,
  OnChanges
} from '@angular/core';

import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';

import {
  NgbDateAdapter,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';

import { DateTimePickerAdapter } from './datetimepicker-adapter.component';
import { DateTimePickerFormatter } from './datetimepicker-formatter.component';
import { isNullOrUndefined } from 'util';
import { Validation } from '../../../shared/models/validation';

@Component({
  selector: 'datetimepicker-input',
  styleUrls: ['./datetimepicker-input.component.css'],
  templateUrl: './datetimepicker-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerInputComponent),
      multi: true
    },
    { provide: NgbDateAdapter, useClass: DateTimePickerAdapter },
    { provide: NgbDateParserFormatter, useClass: DateTimePickerFormatter }
  ]
})
export class DateTimePickerInputComponent
  implements ControlValueAccessor, OnInit, OnChanges {
  public datepicker: FormControl = new FormControl();

  @Input() // Must receive a parent FormGroup to use form validations
  public form: FormGroup;

  @Input() // Required validation
  public isRequired: boolean;

  @Input()
  public placeholder: string;

  @Input() // Used by the "id" and "name" HTML attributes, used for Reactive Form Control too.
  public name: string;

  @Input() // A list of validation types and related message errors
  public validations: Validation[];

  @Input() // The effective value that will be send to the parent model
  public model: any;

  @Input() // Title / Description the will be showed to the user
  public title: string;

  @Output() // Reflects changes to Parent model
  public modelChange = new EventEmitter<any>();

  ngOnInit() {
    if (isNullOrUndefined(this.placeholder)) {
      this.placeholder = '';
    }

    this.setValidations();

    this.configureControl();
  }

  ngOnChanges(changes: any) {
    if (this.datepicker === undefined) {
      return;
    }

    this.datepicker.setValue(this.model, {
      emitEvent: false,
      emitModelToViewChange: false,
      emitViewToModelChange: false
    });
  }

  onChange(value: any) {
    this.model = value;

    this.modelChange.emit(this.model);

    if(this.propagateChange) this.propagateChange(value);
  }

  configureControl() {
    this.datepicker.valueChanges.subscribe(value => this.onChange(value));

    if (!isNullOrUndefined(this.form)) {
      this.form.addControl(this.name, this.datepicker);
    } // This line must be at the end
  }

  propagateChange = (_: any) => {};

  writeValue(value: any) {
    this.model = value;

    this.modelChange.emit(this.model);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {}

  validationExists(type) {
    if (isNullOrUndefined(this.validations)) {
      this.validations = [];
      return false;
    }

    return (
      this.validations.filter(validation => validation.type === type).length > 0
    );
  }

  setValidations() {
    const validators: ValidatorFn[] = [];

    if (this.isRequired) {
      validators.push(Validators.required);
      if (!this.validationExists('required')) {
        this.validations.push({
          type: 'required',
          message: 'Este campo é obrigatório.'
        });
      }
    }

    this.datepicker.setValidators(validators);
  }

  errorNames() {
    return Object.keys(this.datepicker.errors);
  }

  getMessageValidation(type): string {
    if (isNullOrUndefined(this.validations)) {
      this.validations = [];
      return '';
    }

    const obj: Validation = this.validations.filter(
      validation => validation.type === type
    )[0];

    return !isNullOrUndefined(obj) ? obj.message : '';
  }
}
