import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { catchError, debounceTime, distinctUntilChanged, finalize, switchMap, tap, map } from 'rxjs/operators';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs';

@Component({
  selector: 'app-dashboard-map-filter',
  templateUrl: './dashboard-map-filter.component.html',
  styleUrls: ['./dashboard-map-filter.component.scss']
})
export class DashboardMapFilterComponent implements OnInit {

  @Input() public dados: any;
  titulo: string;
  erromessage = '';
  formGroup: FormGroup;
  inSpinner = false;
  mensagem = '';
  cidades: any[] = [];
  errorMsg: string;
  isLoadingResult: boolean;
  keyword = 'nome';
  texto = 'digite o nome da cidade para buscar';
  searchFailedLocal = false;
  searchingLocal = false;

  searchFailedFrota = false;
  searchingFrota = false;

  searchFailedMotorista = false;
  searchingMotorista = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) {
    this.titulo = 'Filtrar no mapa';
    this.formGroup = this.formBuilder.group({
      motorista: false,
      frota: false,
      locais: false,
      cercas: false,
      localModel: [null],
      motoristaModel: [null],
      frotaModel: [null]
    });
  }

  ngOnInit(): void {
    console.log(' =======> ', this.dados);
  }

  onSelectItemLocal(event) {
    console.log('onSelectItemLocal   ====> ', event);
  }

  onSelectItemFrota(event) {
    console.log('onSelectItemFrota   ====> ', event);
  }

  onSelectItemMotorista(event) {
    console.log('onSelectItemMotorista   ====> ', event);
  }

  changeMapa(event, opcao) {
    console.log('changeMapa   ====> ', event);
    console.log('changeMapa opcao  ====> ', opcao);
  }

  searchLocal = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searchingLocal = true),
      map(term => term.length < 2 ? []
        : this.dados.listaVeiculoMonitoramento.filter(v => v.evento.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)),
      tap(() => this.searchingLocal = false),
    )

  formatterLocal = (x: { evento: string }) => x.evento;



  searchFrota = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searchingFrota = true),
      map(term => term.length < 2 ? []
        : this.dados.listaVeiculoMonitoramento.filter(v => v.evento.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)),
      tap(() => this.searchingFrota = false),
    )

  formatterFrota = (x: { evento: string }) => x.evento;


  searchMotorista = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searchingMotorista = true),
      map(term => term.length < 2 ? []
        : this.dados.listaVeiculoMonitoramento.filter(v => v.evento.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)),
      tap(() => this.searchingMotorista = false),
    )

  formatterMotorista = (x: { evento: string }) => x.evento;

  close() {
    this.activeModal.close(this.formGroup.value);
  }
}
