import { CRUDService } from "../base/crud.service";
import { Local } from "../../classes/local";
import { Http } from "@angular/http";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { AuthGuard } from "../../guards/auth.guard";
import { User } from '../../classes/user';
import { Veiculo } from "../../classes/veiculo";
import { Company } from "../../classes/company";
import { RetornoVeiculo } from "../../classes/retorno-veiculo";
import { RESTResponseVO } from "app/classes/restresponsevo";
import { Select } from "../../classes/select";
import { map } from "jquery";


@Injectable({
  providedIn: 'root'
})
export class VeiculoService extends CRUDService<Veiculo>{
  public company: number;
  public loggedUser: User;
  public authGuardM: AuthGuard;
  constructor(http: Http, public authGuard: AuthGuard) {
    super(http, environment.baseURL + 'App/v2/web/veiculos', true);
    this.authGuardM = authGuard;
    this.loggedUser = authGuard.loggedUser();
    this.company = this.loggedUser.selectedCompany.id;
    var branch: string = this.loggedUser.selectedBranch.id === 0 && '0' || this.loggedUser.selectedBranch.id.toString();
    this.listURL = environment.baseURL + "App/v2/web/veiculos/listarVeiculo/"+ this.company+"/"+branch;
  }

  getLista() {
    this.loggedUser = this.authGuardM.loggedUser();
    this.company = this.loggedUser.selectedCompany.id;
    var branch: string = this.loggedUser.selectedBranch.id === 0 && '0' || this.loggedUser.selectedBranch.id.toString();
    const url = environment.baseURL + "App/v2/web/veiculos/listarVeiculo/"+ this.company+"/"+branch;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoVeiculo;
        }
      })
      .catch(this.handleError);
  }

  getAllWithTelemetria() {
    this.loggedUser = this.authGuardM.loggedUser();
    this.company = this.loggedUser.selectedCompany.id;
    const url = environment.baseURL + "App/v2/web/veiculos/telemetria-dados?filtro="
     + encodeURI(JSON.stringify({"idEmpresa":this.company, "idUnidade":0}));
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoVeiculo;
        }
      })
      .catch(this.handleError);
  }

  getPlacas() {
    this.loggedUser = this.authGuardM.loggedUser();
    this.company = this.loggedUser.selectedCompany.id;

    
    var placas1 : Map<number, string>;
    placas1.set(1, "PLA5198");
    placas1.set(2, "UAJ2154");
    placas1.set(3, "YTA2165");

    return placas1;
    // var branch: string = this.loggedUser.selectedBranch.id === 0 && '0' || this.loggedUser.selectedBranch.id.toString();
    // const url: string  = environment.baseURL + "App/v2/web/veiculos/listarVeiculo/"+ this.company+"/"+branch;
    // return this.http
    //   .get(url)
    //   .toPromise()
    //   .then(response => {
    //     if (this.restResponseWrapper) {
    //       return response.json() as RetornoVeiculo;
    //     }
    //   })
    //   .catch(this.handleError);
  }


  getById(id: number) {
    const url = `${this.defaultUrl}/buscarVeiculoPeloId/${this.company}/${id}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoVeiculo;
        }
      })
      .catch(this.handleError);
  }


  cadastrarVeiculo(veiculo: Veiculo): Promise<RESTResponseVO<Veiculo>> {
    const url = `${this.defaultUrl}/cadastrarVeiculo`;
    var empresa: Company = new Company();
    empresa.id = this.company;
    veiculo.empresaVO = empresa;
    veiculo.unidadeId = veiculo.unidade.id;
    return this.enviarRequisicao(veiculo, url);
  }

  editarVeiculo(veiculo: Veiculo): Promise<RESTResponseVO<Veiculo>> {
    const url = `${this.defaultUrl}/editarVeiculo`;
    var empresa: Company = new Company();
    empresa.id = this.company;
    veiculo.empresaVO = empresa;
    veiculo.unidadeId = veiculo.unidade.id;
    return this.enviarRequisicao(veiculo, url);
  }

  enviarRequisicao(veiculo: Veiculo, url: string): Promise<RESTResponseVO<Veiculo>> {
    try {
      return this.http
        .post(url, JSON.stringify(veiculo), { headers: this.headers })
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            var retorno: RetornoVeiculo = response.json() as RetornoVeiculo;
            var veiculo: Veiculo = retorno.veiculo;
            if (veiculo === null) {
              veiculo = new Veiculo();
            }
            veiculo.id = 0;
            return veiculo;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }

  getTipoVeiculo(): Select[]{
    let listaSelect =[
      new Select("CAMINHAO", "Caminhão"),
      new Select("CARRETA", "Carreta"),
      new Select("CARRO", "Carro"),
      new Select("MOTO", "Moto"),
      new Select("ONIBUS", "Onibus"),
      new Select("PICK-UP", "Pick-Up"),
      new Select("TRATOR", "Trator")
    ];
    return listaSelect;
  }

  getTipoEntradaDigital(): Select[]{
    let listaSelect =[
      new Select("NO", "Normal"),
      new Select("CF", "Compressor Câmara Fria"),
      new Select("EC", "Engate Carreta"),
      new Select("PR", "Tomada de Força"),
      new Select("PM", "Porta Motorista"),
      new Select("PB", "Porta Baú"),
    ];
    return listaSelect;
  }

  getTipoCombustivel(): Select[]{
    let listaSelect =[
      new Select("A", "Álcool"),
      new Select("D", "Diesel"),
      new Select("F", "Flex"),
      new Select("G", "Gasolina"),
      new Select("GS", "Gás")
    ];
    return listaSelect;
  }

  getListaVeiculoPorUnidade(unidadesSelecionadasList: any[], situacaoSelecionada: number){
    try {
      let body = {
        unidadesSelecionadas: unidadesSelecionadasList,
        situacaoSelecionada: situacaoSelecionada
      };
      var bb = JSON.stringify(body);
      const url = `${this.defaultUrl}/burcarPorUnidades`;
      return this.http
        .post(url,bb , { headers: this.headers })
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            return response.json() as RetornoVeiculo;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }

}
