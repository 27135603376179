import { CRUDService } from "../base/crud.service";
import { Http } from "@angular/http";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { AuthGuard } from "../../guards/auth.guard";
import { User } from '../../classes/user';
import { RESTResponseVO } from "app/classes/restresponsevo";
import { ControleCombustivel } from "app/classes/controle-combustivel";
import { RetornoControleCombustivel } from "app/classes/retorno-controle-combustivel";
import { EnvioControleCombustivel } from "app/classes/envio-controle-combustivel";

@Injectable({
  providedIn: 'root'
})
export class ControleCombustivelService extends CRUDService<ControleCombustivel>{

  public company: number;
  public unidade:number;

  constructor(http: Http, public authGuard: AuthGuard) {
    super(http, environment.baseURL + 'App/v2/web/controlecombustivel', true);
    var loggedUser: User = authGuard.loggedUser();
    this.company = loggedUser.selectedCompany.id;
    this.unidade = loggedUser.selectedBranch.id;
  }

  ajustar(listaControleCombustivel: ControleCombustivel[], listaIdExcluir:number[]): Promise<RESTResponseVO<ControleCombustivel>> {
    const url = `${this.defaultUrl}/ajustar`;

    let envioControleCombustivel = new EnvioControleCombustivel();
    envioControleCombustivel.listaControleCombustivel = listaControleCombustivel;
    envioControleCombustivel.listaIdExcluir = listaIdExcluir;

    return this.enviarRequisicao(envioControleCombustivel, url);
  }


  enviarRequisicao(controleCombustivel: EnvioControleCombustivel, url: string): Promise<RESTResponseVO<ControleCombustivel>> {
    try {
      return this.http
        .post(url, JSON.stringify(controleCombustivel), { headers: this.headers })
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            var retorno: RetornoControleCombustivel = response.json() as RetornoControleCombustivel;
            var ret: ControleCombustivel = retorno.controleCombustivel;
            if (ret === null) {
              ret = new ControleCombustivel();
            }
            ret.id = 0;
            return ret;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }


  getLista(idVeiculo:number) {
    const url = `${this.defaultUrl}/buscarPorVeiculo/`+idVeiculo;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoControleCombustivel;
        }
      })
      .catch(this.handleError);
  }

  getExcluir(idControleCombustivel:number) {
    const url = `${this.defaultUrl}/excluir/`+idControleCombustivel;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoControleCombustivel;
        }
      })
      .catch(this.handleError);
  }

  protected handleError(error: any): Promise<any> {
    console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
    return Promise.reject(error.message || error);
  }
}
