import { Meta } from './../../../../classes/meta';
import { Component } from "@angular/core";
import { BaseFormComponent } from "../../../base/base-form/base-form.component";
import { ActivatedRoute, Router } from "@angular/router";
import { RESTResponseVO } from "../../../../classes/restresponsevo";
import { AuthGuard } from "../../../../guards/auth.guard";
import { ModeloVeiculoService } from "../../../../services/crud/modelo-veiculo.service";
import { BranchService } from "../../../../services/crud/branch.service";
import { MetasService } from 'app/services/crud/meta.service';
import { MetaOptions } from 'app/classes/meta-options';
@Component({
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})

export class MetasFormComponent extends BaseFormComponent<Meta> {

  showWait: boolean = false;
  modelosVeiculos = [];
  unidades: any;
  valid: boolean = false;
  listaDePergunta: any;
  marchaLentaParado: any;
  marchaLentaEmMovimento: any;
  faixaBatendoTransmissaoComAceleracao: any;
  faixaBatendoTransmissaoSemAceleracao: any;
  faixaEconomicaComAceleracao: any;
  faixaEconomicaSemAceleracao: any;
  faixaSuperEconomicaComAceleracao: any;
  faixaSuperEconomicaSemAceleracao: any;
  faixaDeSobreposicaoComAceleracao: any;
  faixaDeSobreposicaoSemAceleracao: any;
  faixaAmarelaComAceleracao: any;
  faixaAmarelaSemAceleracao: any;
  rpmMaximo: any;
  consumoKmPorLitro: any;
  limiteDeVelocidade: any;

  constructor(
    private service: MetasService,
    route: ActivatedRoute,
    private authGuard: AuthGuard,
    private modeloVeiculoService: ModeloVeiculoService,
    private branchService: BranchService,
    private router: Router
  ) {
    super(route, router, 'adm/metas/list');
    this.obterModelosVeiculos();
    this.obterUnidades();
  }

  obterModelosVeiculos() {
    this.modeloVeiculoService.getLista().then(response => {
      this.modelosVeiculos = response.listaDeModelo;
    });
  }

  obterUnidades() {
    this.branchService.getList().then(response => {
      this.unidades = response.response;
    });
  }

  doCreate(t: Meta): Promise<RESTResponseVO<Meta>> {
    const listaDePergunta = [
      this.marchaLentaParado,
      this.marchaLentaEmMovimento,
      this.faixaBatendoTransmissaoComAceleracao,
      this.faixaBatendoTransmissaoSemAceleracao,
      this.faixaEconomicaComAceleracao,
      this.faixaEconomicaSemAceleracao,
      this.faixaSuperEconomicaComAceleracao,
      this.faixaSuperEconomicaSemAceleracao,
      this.faixaDeSobreposicaoComAceleracao,
      this.faixaDeSobreposicaoSemAceleracao,
      this.faixaAmarelaComAceleracao,
      this.faixaAmarelaSemAceleracao,
      this.rpmMaximo,
      this.consumoKmPorLitro,
      this.limiteDeVelocidade,
    ];
    const listaRefatorada = listaDePergunta.map((p) => {
      if (p.meta && p.peso) {
        const metaNumber = Number(p.meta);
        const pesoNumber = Number(p.peso);
        p.meta = metaNumber;
        p.peso = pesoNumber;
      }
      return p;
    });
    const payload = {
      descricao: t.descricao,
      idModelo: Number(t.idModelo),
      idEmpresa: Number(this.authGuard.loggedUser().selectedCompany.id),
      idUnidade: Number(t.idUnidade),
      listaDePergunta: listaRefatorada,
    }
    const payloadAsMeta = payload as Meta;
    return this.service.create(payloadAsMeta);
  }

  doEdit(t: Meta): Promise<RESTResponseVO<Meta>> {
    const listaDePergunta = [
      this.marchaLentaParado,
      this.marchaLentaEmMovimento,
      this.faixaBatendoTransmissaoComAceleracao,
      this.faixaBatendoTransmissaoSemAceleracao,
      this.faixaEconomicaComAceleracao,
      this.faixaEconomicaSemAceleracao,
      this.faixaSuperEconomicaComAceleracao,
      this.faixaSuperEconomicaSemAceleracao,
      this.faixaDeSobreposicaoComAceleracao,
      this.faixaDeSobreposicaoSemAceleracao,
      this.faixaAmarelaComAceleracao,
      this.faixaAmarelaSemAceleracao,
      this.rpmMaximo,
      this.consumoKmPorLitro,
      this.limiteDeVelocidade,
    ];
    const listaRefatorada = listaDePergunta.map((p) => {
      if (p.meta && p.peso) {
        const metaNumber = Number(p.meta);
        const pesoNumber = Number(p.peso);
        p.meta = metaNumber;
        p.peso = pesoNumber;
      }
      return p;
    });
    const payload = {
      id: Number(this.activeID),
      descricao: t.descricao,
      idModelo: Number(t.idModelo),
      idEmpresa: Number(this.authGuard.loggedUser().selectedCompany.id),
      idUnidade: Number(t.idUnidade),
      listaDePergunta: listaRefatorada,
    }
    const payloadAsMeta = payload as Meta;
    return this.service.editarMeta(this.activeID, payloadAsMeta);
  }

  doDelete(t: Meta): Promise<RESTResponseVO<string>> {
    return null;
  }

  assembleObjectFromForm(): void { }

  loadAuxiliaryLists(): void { }

  loadObject(): void {
    if (this.activeID && (this.activeID !== 0)) {
      this.service.getById(this.activeID).then(response => {
        var resultItem: Meta = response.response;
        this.activeObject = resultItem;
        this.marchaLentaParado = resultItem.listaDePergunta.find(p => p.label === 'Marcha lenta parado:');
        this.marchaLentaEmMovimento = resultItem.listaDePergunta.find(p => p.label === 'Marcha lenta em movimento:');
        this.faixaBatendoTransmissaoComAceleracao = resultItem.listaDePergunta.find(p => p.label === 'Faixa batendo transmissão com aceleração:');
        this.faixaBatendoTransmissaoSemAceleracao = resultItem.listaDePergunta.find(p => p.label === 'Faixa batendo transmissão sem aceleração:');
        this.faixaEconomicaComAceleracao = resultItem.listaDePergunta.find(p => p.label === 'Faixa econômica com aceleração:');
        this.faixaEconomicaSemAceleracao = resultItem.listaDePergunta.find(p => p.label === 'Faixa econômica sem aceleração:');
        this.faixaSuperEconomicaComAceleracao = resultItem.listaDePergunta.find(p => p.label === 'Faixa super econômica com aceleração:');
        this.faixaSuperEconomicaSemAceleracao = resultItem.listaDePergunta.find(p => p.label === 'Faixa super econômica sem aceleração:');
        this.faixaDeSobreposicaoComAceleracao = resultItem.listaDePergunta.find(p => p.label === 'Faixa de sobreposição com aceleração:');
        this.faixaDeSobreposicaoSemAceleracao = resultItem.listaDePergunta.find(p => p.label === 'Faixa de sobreposição sem aceleração:');
        this.faixaAmarelaComAceleracao = resultItem.listaDePergunta.find(p => p.label === 'Faixa amarela com aceleração:');
        this.faixaAmarelaSemAceleracao = resultItem.listaDePergunta.find(p => p.label === 'Faixa amarela sem aceleração:');
        this.rpmMaximo = resultItem.listaDePergunta.find(p => p.label === 'RPM Máximo:');
        this.consumoKmPorLitro = resultItem.listaDePergunta.find(p => p.label === 'Consumo Km/Litro:');
        this.limiteDeVelocidade = resultItem.listaDePergunta.find(p => p.label === 'Limite de velocidade:');
      });
    } else {
      this.router.navigate(['adm/metas/list']);
    }
    this.loading = false;
  }

  initObject(): void {
    this.activeObject = new Meta();
    this.listaDePergunta = [
      { label: 'Marcha lenta parado:', meta: null, peso: null, id: null },
      { label: 'Marcha lenta em movimento:', meta: null, peso: null, id: null },
      { label: 'Faixa batendo transmissão com aceleração:', meta: null, peso: null, id: null },
      { label: 'Faixa batendo transmissão sem aceleração:', meta: null, peso: null, id: null },
      { label: 'Faixa econômica com aceleração:', meta: null, peso: null, id: null },
      { label: 'Faixa econômica sem aceleração:', meta: null, peso: null, id: null },
      { label: 'Faixa super econômica com aceleração:', meta: null, peso: null, id: null },
      { label: 'Faixa super econômica sem aceleração:', meta: null, peso: null, id: null },
      { label: 'Faixa de sobreposição com aceleração:', meta: null, peso: null, id: null },
      { label: 'Faixa de sobreposição sem aceleração:', meta: null, peso: null, id: null },
      { label: 'Faixa amarela com aceleração:', meta: null, peso: null, id: null },
      { label: 'Faixa amarela sem aceleração:', meta: null, peso: null, id: null },
      { label: 'RPM Máximo:', meta: null, peso: null, id: null },
      { label: 'Consumo Km/Litro:', meta: null, peso: null, id: null },
      { label: 'Limite de velocidade:', meta: null, peso: null, id: null },
    ];
    this.marchaLentaParado = this.listaDePergunta.find(p => p.label === 'Marcha lenta parado:');
    this.marchaLentaEmMovimento = this.listaDePergunta.find(p => p.label === 'Marcha lenta em movimento:');
    this.faixaBatendoTransmissaoComAceleracao = this.listaDePergunta.find(p => p.label === 'Faixa batendo transmissão com aceleração:');
    this.faixaBatendoTransmissaoSemAceleracao = this.listaDePergunta.find(p => p.label === 'Faixa batendo transmissão sem aceleração:');
    this.faixaEconomicaComAceleracao = this.listaDePergunta.find(p => p.label === 'Faixa econômica com aceleração:');
    this.faixaEconomicaSemAceleracao = this.listaDePergunta.find(p => p.label === 'Faixa econômica sem aceleração:');
    this.faixaSuperEconomicaComAceleracao = this.listaDePergunta.find(p => p.label === 'Faixa super econômica com aceleração:');
    this.faixaSuperEconomicaSemAceleracao = this.listaDePergunta.find(p => p.label === 'Faixa super econômica sem aceleração:');
    this.faixaDeSobreposicaoComAceleracao = this.listaDePergunta.find(p => p.label === 'Faixa de sobreposição com aceleração:');
    this.faixaDeSobreposicaoSemAceleracao = this.listaDePergunta.find(p => p.label === 'Faixa de sobreposição sem aceleração:');
    this.faixaAmarelaComAceleracao = this.listaDePergunta.find(p => p.label === 'Faixa amarela com aceleração:');
    this.faixaAmarelaSemAceleracao = this.listaDePergunta.find(p => p.label === 'Faixa amarela sem aceleração:');
    this.rpmMaximo = this.listaDePergunta.find(p => p.label === 'RPM Máximo:');
    this.consumoKmPorLitro = this.listaDePergunta.find(p => p.label === 'Consumo Km/Litro:');
    this.limiteDeVelocidade = this.listaDePergunta.find(p => p.label === 'Limite de velocidade:');
    this.loading = false;
  }

  setActiveID(): void {
    try {
      if (this.activeObject == undefined) {
        this.activeID = 0;
      } else {
        this.activeID = this.activeObject.id;
      }
    } catch (e) {
      console.log(e);
    }
  }
}
