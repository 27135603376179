import { Http } from "@angular/http";
import { RESTResponseVO } from "../../classes/restresponsevo";
import { AllowanceReason } from "../../classes/allowance-reason";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { JourneyEvent } from "../../classes/journey-event";

@Injectable()
export class JourneyEventsService {
    constructor(private http: Http) {
    }

    getAllowances(): Promise<RESTResponseVO<AllowanceReason[]>> {
        var url: string = environment.baseURL + 'App/v2/web/eventosJornada/allowances';
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<AllowanceReason[]>;
            })
            .catch(this.handleError);
    }

    findByDateJourney(journey: number, date: number): Promise<RESTResponseVO<JourneyEvent[]>> {
        var url: string = environment.baseURL + 'App/v2/web/eventosJornada/' + journey + '/' + date;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<JourneyEvent[]>;
            })
            .catch(this.handleError);
    }

    findAllMoverEventos(): Promise<RESTResponseVO<JourneyEvent[]>> {
        var url: string = environment.baseURL + 'App/v2/web/eventosJornada/moverEventos';
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<JourneyEvent[]>;
            })
            .catch(this.handleError);
    }

    protected handleError(error: any): Promise<any> {
        console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
        return Promise.reject(error.message || error);
    } 
}