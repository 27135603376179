import { array } from "@amcharts/amcharts4/core";
import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ObservacaoInconformidade } from "app/classes/observacao-inconformidade";
import { InconformidadeService } from "app/services/crud/inconformidade.service";


@Component({
    selector: 'app-observacoes-inconformidades',
    templateUrl: './observacoes-inconformidades.component.html',
    styleUrls: ['./observacoes-inconformidades.component.scss']
})

export class ObservacoesIncoformidadesComponent implements OnInit {

    @Input() public dados: Array<any> = [];
    formGroup: FormGroup;
    titulo = 'Anotações';
    isLoading = false;
    progress = 0;
    enviados: Array<string> = []
    reenviar = false;
    mode ='determinate';
    itensValidar = 0;
    exibirAcao = true;
    
    constructor(private formBuilder: FormBuilder,
        private activeModal: NgbActiveModal,
        private inconformidadeService: InconformidadeService) {
        this.formGroup = this.formBuilder.group({
            observacao: ['', [Validators.required]]
          });
        
    }

    ngOnInit(): void {
    }

    close() {
        this.activeModal.close();
    }

    cancelar() {
        this.activeModal.close();
    }

     async salvar() {
        
        this.reenviar = false
        
        if(this.enviados.length === this.dados.length) {
            this.enviados = [];
        }
        
        if(this.formGroup.valid && this.dados.length > 0) {
            const itens = this.dados.filter(a=> !this.enviados.includes(a.id));
            if(itens && itens.length) {
                this.itensValidar = itens.length;
                this.mode ='indeterminate'
                for(let index =0; index < itens.length; index++) {
                    try {

                        const item = itens[index];

                        const inconformidade = await  this.inconformidadeService.atualizarObservacaoInfoformidade(<ObservacaoInconformidade> {
                            dataDoOcorrido: item.data,
                            evento: item.tipo,
                            id: item.id,
                            observacao: this.formGroup.controls.observacao.value
                        });

                        if(inconformidade.codigoRetorno ===1 ) {
                            this.enviados.push(item.id)
                        }
                        this.validaUltimoItem(index)

                    } catch (error) {
                        this.validaUltimoItem(index)
                    }
                }
            } else {
                this.progress = (this.enviados.length * 100) / this.dados.length
                this.mode ='determinate'
            }           
        }
    }

    validaUltimoItem(index) {
        if(index === this.itensValidar -1) {
            this.mode ='determinate'
            this.progress = (this.enviados.length * 100) / this.dados.length
            if(this.enviados.length < this.dados.length) {
                this.reenviar = true;
            } else {
                this.reenviar = false;
            }


            if(this.enviados.length === this.dados.length) {
                this.exibirAcao = false
            }
        }
    }

}