import { Component } from "@angular/core";
import { Modelo } from "../../../../classes/modelo";
import { BaseFormComponent } from "../../../base/base-form/base-form.component";
import { ActivatedRoute, Router } from "@angular/router";
import { ModeloEquipamentoService } from "../../../../services/crud/modelo-equipamento.service";
import { RESTResponseVO } from "../../../../classes/restresponsevo";
import { AuthGuard } from "../../../../guards/auth.guard";
import { RetornoModelo } from "app/classes/retorno-modelo";

@Component({
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class ModeloEquipamentoFormComponent extends BaseFormComponent<Modelo> {
  constructor(private modeloEquipamentoService: ModeloEquipamentoService,
    private authGuard: AuthGuard,
    route: ActivatedRoute,
    private router: Router) { super(route, router, 'adm/modelo-equipamento/list'); }

  assembleObjectFromForm(): void {
  }

  loadAuxiliaryLists(): void {
  }

  loadObject(): void {

    if (this.activeID && (this.activeID !== 0)) {
      this.modeloEquipamentoService.getById(this.activeID).then(response => {
        var resultItem: RetornoModelo = response;
        this.activeObject = resultItem.modelo;
      });
    }else{
      this.router.navigate(['adm/modelo-equipamento/list']);
    }
    this.loading = false;
  }

  initObject(): void {
    this.activeObject = new Modelo();
    this.loading = false;
  }

  doCreate(t: Modelo): Promise<RESTResponseVO<Modelo>>  {
     return this.modeloEquipamentoService.cadastrarModeloEquipamento(t);
  }

  doEdit(t: Modelo): Promise<RESTResponseVO<Modelo>> {
    try {
      return this.modeloEquipamentoService.editarModeloEquipamento(t);
    } catch (e) {
      console.error(e);
    }

  }

  doDelete(t: Modelo): Promise<RESTResponseVO<string>> {
    return null;
  }

  setActiveID(): void {
    try {
      if(this.activeObject == undefined){
        this.activeID = 0;
      }else{
        this.activeID = this.activeObject.id;
      }
    } catch (e) {
      console.log(e);
    }

  }

}
