import { CRUDService } from "../base/crud.service";
import { Http } from "@angular/http";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { AuthGuard } from "../../guards/auth.guard";
import { RESTResponseVO } from "app/classes/restresponsevo";
import { User } from '../../classes/user';
import { Company } from "app/classes/company";
import { GrupoLocais } from "app/classes/grupoLocais";
import { RetornoGrupoLocais } from "app/classes/retorno-grupo-locais";

@Injectable({
  providedIn: 'root'
})
export class GrupoLocaisService extends CRUDService<GrupoLocais> {
  public company: number;
  constructor(http: Http, public authGuard: AuthGuard) {
    super(http, environment.baseURL + 'App/v2/web/grupoLocais', true);
    var loggedUser: User = authGuard.loggedUser();
    this.company = loggedUser.selectedCompany.id;
    this.listURL = environment.baseURL + "App/v2/web/grupoLocais/buscarListaGrupoLocais/"+ this.company;
  }

  getLista() {
    const url: string =
      (this.listURL === undefined && `${this.defaultUrl}`) || `${this.listURL}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoGrupoLocais;
        }
      })
      .catch(this.handleError);
  }

  getById(id: number) {
    const url = `${this.defaultUrl}/buscarGrupoLocal/${id}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoGrupoLocais;
        }
      })
      .catch(this.handleError);
  }

  cadastrarGrupoLocais(grupoLocais: GrupoLocais): Promise<RESTResponseVO<GrupoLocais>> {  
    const url = `${this.defaultUrl}/cadastrarGrupoLocais`;
    var empresa: Company = new Company();
    empresa.id = this.company;
    grupoLocais.empresaId = empresa.id;
    return this.enviarRequisicao(grupoLocais, url);
  }

  editarGrupoLocais(grupoLocais: GrupoLocais): Promise<RESTResponseVO<GrupoLocais>> {
    const url = `${this.defaultUrl}/editarGrupoLocais`;
    var empresa: Company = new Company();
    empresa.id = this.company;
    grupoLocais.empresaId = empresa.id;
    return this.enviarRequisicao(grupoLocais, url);
  }

  enviarRequisicao(grupoLocais: GrupoLocais, url: string): Promise<RESTResponseVO<GrupoLocais>> {
    try {
      return this.http
        .post(url, JSON.stringify(grupoLocais), { headers: this.headers })
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            var retorno: RetornoGrupoLocais = response.json() as RetornoGrupoLocais;
            var grupoLocais: GrupoLocais = retorno.grupoLocais;
            if (grupoLocais === null) {
              grupoLocais = new GrupoLocais();
            }
            grupoLocais.id = 0;
            return grupoLocais;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }
}
