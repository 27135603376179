import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

declare const google: any;

@Component({
  selector: "mapa-localizacao-veiculo",
  templateUrl: "./mapa-localizacao-veiculo.html",
  styleUrls: ["./mapa-localizacao-veiculo.scss"],
})
export class MapaLocalizacaoVeiculoComponent implements OnInit {
  latitude: number = 0;
  longitude: number = 0;
  mapa: any;
  url = null;
  constructor(
    public dialogRef: MatDialogRef<MapaLocalizacaoVeiculoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {
    this.latitude = data.latitude;
    this.longitude = data.longitude;
  }

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }
}
