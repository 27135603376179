import { BaseListComponent } from "../../base/base-list/base-list.component";
import { OnInit, Component, ElementRef, ViewChild } from "@angular/core";
import { AuthGuard } from "../../../guards/auth.guard";
import { Router } from "@angular/router";
import { DriverService } from "../../../services/crud/driver.service";
import { JourneyService } from "../../../services/crud/journey.service";
import { UtilService } from "../../../services/utils.service";
import { PDFService } from "../../../services/pdf.service";
import { Driver } from "../../../classes/driver";
import { Observable } from "rxjs/Observable";
import { ReportService } from "../../../services/report/report.service";
import { Filtro } from "app/classes/filtro";
import { Motorista } from "app/classes/motorista";

@Component({
  selector: 'app-diaria-motorista',
  templateUrl: './diaria-motorista.component.html',
  styleUrls: ['./diaria-motorista.component.css']
})
export class RelatorioDiariaMotoristaComponent extends BaseListComponent implements OnInit {

  constructor(private authGuard: AuthGuard,
    private router: Router,
    private driverService: DriverService,
    private journeyService: JourneyService,
    private utilService: UtilService,
    private reportService: ReportService,
    protected pdfService: PDFService) {
    super(utilService, pdfService, authGuard);
    this.searchField = "name";
  }

  @ViewChild('iframe') iframe: ElementRef;
  public driverList: Driver[];
  public searchObject: { driver: Driver, start: Date, end: Date } = { driver: undefined, start: undefined, end: undefined };
  public filtro: Filtro;
  
  ngOnInit() {
    this.loading = true;
    this.driverService.getListMinimo().then(response => {
      this.driverList = response.response;
      this.driverList.sort((d1, d2) => d1.nome > d2.nome ? 1 : -1);
      this.loading = false;
    });
  }



  doSearch() {
    try {

      this.errorMessage = undefined;
      this.data = undefined;

      if (!this.searchObject) {
        this.errorMessage = "Favor preencher os campos do formulário.";
      } else if (!this.searchObject.driver) {
        this.errorMessage = "Favor selecionar um motorista.";
      } else if (!this.searchObject.start || !this.searchObject.end) {
        this.errorMessage = "Data inicial ou final inválidas ou não preenchidas!";
      } else {

        this.filtro = new Filtro();
        var motorista = new Motorista();
        motorista.id = this.searchObject.driver.id; 
        this.filtro.motorista = motorista;
        this.filtro.dataInicial = this.searchObject.start;
        this.filtro.dataFinal = this.searchObject.end;
        this.reportService.getReportDiariaMotorista(this.filtro).then(response => {
          this.iframe.nativeElement.setAttribute('src', response);
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  driverFormatter = (x: Driver) => x.nome + ' ' + x.sobrenome;

  searchDriver = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map(term => term.length < 2 ? [] : this.driverList.filter(v => v.nome.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10));

  driverTemplate(r) {
    return r.nome + " " + r.sobrenome;
  }

}
