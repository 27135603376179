import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';

import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Validation } from '../../models/validation';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'viam-select',
  templateUrl: './viam-select.component.html',
  styleUrls: ['./viam-select.component.scss']
})
export class ViamSelectComponent implements OnInit, OnChanges {
  public select: FormControl = new FormControl();

  @Input()
  public form: FormGroup;

  @Input()
  public title: string;

  @Input()
  public name: string;

  @Input()
  public selected: any;

  @Output()
  public selectedChange = new EventEmitter<any>();

  @Input()
  public list: any[];

  @Input()
  public selectBy: string;

  @Input()
  public descProp: string;

  @Input()
  public placeholder: string;

  @Input()
  public isRequired: boolean;

  @Input() // Apply disabled property to the select
  public isDisabled: boolean;

  @Input()
  public validations: Validation[];

  constructor() {}

  ngOnInit() {
    if (isNullOrUndefined(this.selectBy)) {
      this.selectBy = '';
    }

    if (isNullOrUndefined(this.descProp)) {
      this.descProp = 'description';
    }

    if (isNullOrUndefined(this.placeholder)) {
      this.placeholder = '-- No data selected --';
    }

    if (isNullOrUndefined(this.isDisabled)) {
      this.isDisabled = false;
    }

    if (this.isDisabled) {
      this.select.disable();
    }

    if (isNullOrUndefined(this.title)) {
      this.title = this.placeholder;
    }
    this.setValidations();
    this.configureControl();
  }

  ngOnChanges() {
    if (isNullOrUndefined(this.selected)) {
      this.selected = null;
    }

    if (this.isDisabled) {
      this.select.disable();
    } else {
      this.select.enable();
    }

    this.select.setValue(this.selected, { emitEvent: false });
  }

  compare(val1: any, val2: any) {
    if (!isNullOrUndefined(val2) && !isNullOrUndefined(this.selectBy)) {
      if (this.selectBy.length > 0) {
        return (
          JSON.stringify(val1[this.selectBy]) ===
          JSON.stringify(val2[this.selectBy])
        );
      }
    }
    return JSON.stringify(val1) === JSON.stringify(val2);
  }

  setValidations() {
    // Validators
    if (this.isRequired) {
      this.select.setValidators(Validators.required);
      if (!this.validationExists('required')) {
        this.validations.push({
          type: 'required',
          message: 'Este campo é obrigatório.'
        });
      }
    }
  }

  configureControl() {
    this.select.valueChanges.subscribe(value => this.onSelect(value));

    if (this.form !== undefined) {
      this.form.addControl(this.name, this.select);
    } // This line must be at the end
  }

  onSelect(value: any) {
    this.selected = value;
    this.selectedChange.emit(this.selected);
  }

  errorNames() {
    return Object.keys(this.select.errors);
  }

  getMessageValidation(type): string {
    const obj: Validation = this.validations.filter(
      validation => validation.type === type
    )[0];

    return obj !== undefined ? obj.message : '';
  }

  validationExists(type) {
    if (this.validations === undefined) {
      this.validations = [];
      return false;
    }

    return (
      this.validations.filter(validation => validation.type === type).length > 0
    );
  }
}
