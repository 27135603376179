import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Company } from '../../../../classes/company';
import { CompanyService } from '../../../../services/crud/company.service';
import { BaseListComponent } from '../../../base/base-list/base-list.component';
import { UtilService } from '../../../../services/utils.service';
import { PDFService } from '../../../../services/pdf.service';
import { AuthGuard } from '../../../../guards/auth.guard';

@Component({
    templateUrl: '../../../base/base-list/base-list.component.html',
    styleUrls: [ '../../../base/base-list/base-list.component.css' ],
    encapsulation: ViewEncapsulation.None
})
export class CompanyListComponent extends BaseListComponent {

    constructor(private companyService: CompanyService,
        private utilService: UtilService,
        pdfService: PDFService,
        authGuard: AuthGuard) {
        super(utilService, pdfService, authGuard);
        this.searchField = "nome";
    }

    ngOnInit() {
        this.pageTitle = "Cadastros | Empresas";
        this.formPath = "/adm/company/form";

        this.tableHeaders = [
            [
                this.createHeader("Nome", 0, true),
                this.createHeader("Apelido", 1, true),
                this.createHeader("CNPJ", 2, true),
                this.createHeader("Responsável", 3, true),
                this.createHeader("Telefone", 4, true),
                this.createHeader("E-mail", 5, true),
                this.createHeader("Validade", 6, true),
                this.createHeader("QTD Usuários", 7, true),
                this.createHeader("Tempo webserver", 8, true),
                this.createHeader("Ativo", 9, true) 
            ]
        ];

        this.dataFields = [
            this.createField("nome", "string"),
            this.createField("displayName", "string"),
            this.createField("cnpj", "string"),
            this.createField("responsavel", "string"),
            this.createField("telefone", "string"),
            this.createField("email", "string"),
            this.createField("dataValidade", "date"),
            this.createField("quantidadeUsuarios", "string"),
            this.createField("frequencyreading", "string"),
            this.createField("status", "boolean")
        ];

        this.companyService.getList()
            .then(response => {
                this.data = response.response; this.loading = false;
            });

        this.pageOrientation = 'landscape';
    }
}
