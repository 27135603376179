import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DatePipe } from '@angular/common';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import { BaseFormComponent } from '../../../base/base-form/base-form.component';
import { RESTResponseVO } from '../../../../classes/restresponsevo';
import { Tolerance } from '../../../../classes/tolerance';
import { ToleranceService } from '../../../../services/crud/tolerance.service';
import { AuthGuard } from '../../../../guards/auth.guard';

@Component({
  selector: 'app-tolerance-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css'],
  providers: [DatePipe]
})
export class ToleranceFormComponent extends BaseFormComponent<Tolerance> {
  constructor(private toleranceService: ToleranceService,
    private datePipe: DatePipe,
    route: ActivatedRoute,
    private authGuard: AuthGuard,
    router: Router) { super(route, router, 'adm/tolerance/list'); }

  assembleObjectFromForm(): void {
  }

  loadAuxiliaryLists(): void {
  }

  loadObject(): void {
    if (this.activeID && (this.activeID !== 0)) {
      this.toleranceService.getById(this.activeID).then(response => {
        var resultItem: Tolerance = response.response;
        this.activeObject = resultItem;
      });
    }
    this.loading = false;
  }

  initObject(): void {
    this.activeObject = new Tolerance();
    this.activeObject.empresaId = this.authGuard.loggedUser().selectedCompany.id;
    this.loading = false;
  }

  doCreate(t: Tolerance): Promise<RESTResponseVO<Tolerance>> {
    return this.toleranceService.create(t);
  }

  doEdit(t: Tolerance): Promise<RESTResponseVO<Tolerance>> {
    return this.toleranceService.update(t);
  }

  doDelete(t: Tolerance): Promise<RESTResponseVO<string>> {
    return this.toleranceService.delete(t.id);
  }

  setActiveID(): void {
    this.activeID = this.activeObject.id;
  }
}
