import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DatePipe } from '@angular/common';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';

import { Address } from '../../../../classes/address';
import { Company } from '../../../../classes/company';
import { City } from '../../../../classes/city';
import { WaitingPoint } from "../../../../classes/waiting-point";
import { CityService } from '../../../../services/crud/city.service';
import { CompanyService } from '../../../../services/crud/company.service';

import { BaseFormComponent } from '../../../base/base-form/base-form.component';
import { RESTResponseVO } from '../../../../classes/restresponsevo';
import { TaxAndInspectBarrier } from '../../../../classes/tax-and-inspect-barrier';
import { TaxAndInspectBarrierService } from '../../../../services/crud/tax-and-inspect-barrier.service';

@Component({
    selector: 'app-tax-and-inspect-barrier-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css'],
    providers: [DatePipe]
})
export class TaxAndInspectBarrierFormComponent extends BaseFormComponent<TaxAndInspectBarrier> {
    constructor(private taxAndInspectBarrierService: TaxAndInspectBarrierService,
        private datePipe: DatePipe,
        route: ActivatedRoute,
        router: Router) { super(route, router, 'adm/taxandinspectbarrier/list'); }

    assembleObjectFromForm(): void {
    }

    loadAuxiliaryLists(): void {
    }

    loadObject(): void {
        if (this.activeID && (this.activeID !== 0)) {
            this.taxAndInspectBarrierService.getById(this.activeID).then(response => {
                var resultItem: TaxAndInspectBarrier = response.response;
                this.activeObject = resultItem;
            });
        }
        this.loading = false;
    }

    initObject(): void {
        this.activeObject = new TaxAndInspectBarrier();
        this.activeObject.pontosDeEsperaId = new WaitingPoint();
        this.activeObject.pontosDeEsperaId.endereco = new Address();
        this.loading = false;
    }

    doCreate(t: TaxAndInspectBarrier): Promise<RESTResponseVO<TaxAndInspectBarrier>> {
        return this.taxAndInspectBarrierService.create(t);
    }

    doEdit(t: TaxAndInspectBarrier): Promise<RESTResponseVO<TaxAndInspectBarrier>> {
        return this.taxAndInspectBarrierService.update(t);
    }

    doDelete(t: TaxAndInspectBarrier): Promise<RESTResponseVO<string>> {
        return this.taxAndInspectBarrierService.delete(t.id);
    }

    setActiveID(): void {
        this.activeID = this.activeObject.id;
    }
}