import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  ViewChild,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MatPaginator,
  MatSort,
  MatTableDataSource,
  MAT_DIALOG_DATA,
} from "@angular/material";
import * as XLSX from "xlsx";
import { DetalheEficienciaVeiculo } from "app/classes/detalhe-eficiencia-veiculo";
import { MapaEficienciaVeiculoComponent } from "../mapa-eficiencia-veiculos/mapa-eficiencia-veiculos";
import { FiltroOpcoesTelemetria } from "app/classes/filtro-opcoes-telemetria";
import { Vehicle } from "app/classes/vehicle";
import { number } from "@amcharts/amcharts4/core";

@Component({
  selector: "detalhes-eficiencia-veiculos",
  templateUrl: "./detalhes-eficiencia-veiculos.html",
  styleUrls: ["./detalhes-eficiencia-veiculos.scss"],
})
export class DetalhesEficienciaVeiculosComponent implements AfterViewInit {
  private hoje = Date.now();
  private dateHoje = new Date(this.hoje);
  private hojePrimeiraHora = new Date(
    this.dateHoje.getFullYear(),
    this.dateHoje.getMonth(),
    this.dateHoje.getDate(),
    0,
    0,
    1
  ).getTime();
  private hojeUltimaHora = new Date(
    this.dateHoje.getFullYear(),
    this.dateHoje.getMonth(),
    this.dateHoje.getDate(),
    23,
    59,
    59
  ).getTime();

  @Input()
  detalhesEficienciaVeiculo: Array<DetalheEficienciaVeiculo> = [];

  @Input() filtroFront = new FiltroOpcoesTelemetria();

  @ViewChild("TABLE") table: ElementRef;

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginador: MatPaginator;

  dataSource = new MatTableDataSource(this.detalhesEficienciaVeiculo);

  displayedColumns = [
    "placa",
    "modelo",
    "kmRodado",
    "consumoEmLitros",
    "kmLitros",
    "horasLigado",
    "marchaLentaParadoPorcentagem",
    "marchaLentaDeslocandoPorcentagem",
    "batendoTransmissaoSemAcelaracaoPorcentagem",
    "batendoTransmissaoComAcelaracaoPorcentagem",
    "superEconomiaSemAcelaracaoPorcentagem",
    "superEconomiaComAcelaracaoPorcentagem",
    "sobrerotacaoSemAcelaracaoPorcentagem",
    "sobrerotacaoComAcelaracaoPorcentagem",
    "amarelaSemAcelaracaoPorcentagem",
    "amarelaComAcelaracaoPorcentagem",
    "rpmMaximoPorcentagem",
    "limiteDeVelocidade",
  ];

  constructor(
    public dialogRef: MatDialogRef<DetalhesEficienciaVeiculosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {
    this.detalhesEficienciaVeiculo = data.detalhesEficienciaVeiculo;
    this.filtroFront = data.filtro;
  }

  exibirTabela: Boolean = true;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginador;
    this.dataSource.sort = this.sort;
    this.dataSource.data = this.detalhesEficienciaVeiculo;

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'marchaLentaParadoPorcentagem' :
        case 'marchaLentaDeslocandoPorcentagem' :
        case 'batendoTransmissaoSemAcelaracaoPorcentagem' :
        case 'batendoTransmissaoComAcelaracaoPorcentagem' :
        case 'superEconomiaSemAcelaracaoPorcentagem' :
        case 'superEconomiaComAcelaracaoPorcentagem' :
        case 'sobrerotacaoSemAcelaracaoPorcentagem' :
        case 'sobrerotacaoComAcelaracaoPorcentagem' :
        case 'amarelaSemAcelaracaoPorcentagem' :
        case 'amarelaComAcelaracaoPorcentagem' :
        case 'rpmMaximoPorcentagem' : {
            if(item[property] && item[property].indexOf('%') > 0) {
              let value = item[property].replace('%', '');
              return Number.parseFloat(value)
            }
            return item[property]
        }
        default: return item[property];
      }
    };
  }

  exportAsExcel() {

    const fmt = "0.00";

    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );

    if(this.detalhesEficienciaVeiculo && this.detalhesEficienciaVeiculo.length
      && this.detalhesEficienciaVeiculo.length > 0) {
      
      const range = { s: {r:1, c:4}, e: {r:this.detalhesEficienciaVeiculo.length, c:4} };
      
      for(var R = range.s.r; R <= range.e.r; ++R) {
        for(var C = range.s.c; C <= range.e.c; ++C) {
          var cellPlaca = ws[XLSX.utils.encode_cell({r:R,c:0})];
          if(cellPlaca.v) {
            var cell = ws[XLSX.utils.encode_cell({r:R,c:C})];
          if(!cell || cell.t != 'n') continue; 
          const item = this.detalhesEficienciaVeiculo.find(p=> p.placa == cellPlaca.v)
          if(item) {
            cell.v = item.kmLitros;
            cell.z = fmt;
          }
          }
        }
      }
    }

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Tabela Eficiência");

    XLSX.writeFile(wb, "Tabela_Eficiencia.xlsx");
  }

  exibirMapaEficienciaVeiculos(placa: string) {
    if (this.filtroFront != null && this.filtroFront.dataFinal > Date.now()) {
      this.filtroFront.dataInicial = this.hojePrimeiraHora;
      this.filtroFront.dataFinal = this.hojeUltimaHora;
    }
    if (this.filtroFront == null || this.filtroFront.dataFinal == null) {
      this.filtroFront = new FiltroOpcoesTelemetria();
      this.filtroFront.dataInicial = this.hojePrimeiraHora;
      this.filtroFront.dataFinal = this.hojeUltimaHora;
    }
    this.filtroFront.veiculo = new Vehicle();
    this.filtroFront.veiculo.placa = placa;
    this.dialog.open(MapaEficienciaVeiculoComponent, {
      data: {
        detalhesEficienciaVeiculo: this.detalhesEficienciaVeiculo,
        filtro: this.filtroFront,
      },
      panelClass: "indicadores-dialog",
      width: "100vw",
    });
  }

  close() {
    this.dialogRef.close();
  }
}
