import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseFormComponent } from "../../../base/base-form/base-form.component";
import { ActivatedRoute, Router } from "@angular/router";
import { RESTResponseVO } from "../../../../classes/restresponsevo";
import { Macros } from "../../../../classes/macros";
import { MacrosService } from "../../../../services/crud/macros.service";
import { AuthGuard } from "../../../../guards/auth.guard";
import { RetornoMacros } from "app/classes/retorno-macros";
import { EventoMacros } from "app/classes/evento-macros";
import { Hodometro } from 'app/classes/hodometro';
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { RetornoVeiculo } from 'app/classes/retorno-veiculo';
import { Veiculo } from 'app/classes/veiculo';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { ReplaySubject, Subject } from "rxjs";
import { MatSelect } from "@angular/material";
import { take, takeUntil } from 'rxjs/operators';
import { HodometroService } from 'app/services/crud/hodometro.service';
import { RetornoHodometro } from 'app/classes/retorno-hodometro';


@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class HodometroFormComponent implements OnInit {

  public loading: boolean;
  listaDeHodometro: Hodometro[];
  //INICIO VEICULO
  private listaDeVeiculos: Veiculo[];
  public veiculoCtrl: FormControl = new FormControl();
  public veiculoFilterCtrl: FormControl = new FormControl();
  public filteredVeiculos: ReplaySubject<Veiculo[]> = new ReplaySubject<Veiculo[]>(1);
  @ViewChild('singleSelect') singleSelect: MatSelect;
  protected _onDestroy = new Subject<void>();
  //FIM VEICULO
  public idVeiculo: Veiculo;

  constructor(private hodometroService: HodometroService,
    private veiculoService: VeiculoService,
    route: ActivatedRoute,
    private authGuard: AuthGuard,
    private router: Router) { }

  ngOnInit() {
    this.loading = true;
    this.loadObject();
  }


  loadObject(): void {
    this.buscarListaVeiculo();
    this.loading = false;
  }

  initObject(): void {
    this.buscarListaVeiculo();
    this.loading = false;
  }

  buscarListaVeiculo() {
    try {
      this.veiculoService.getLista().then(response => {
        var retorno: RetornoVeiculo = response;
        this.listaDeVeiculos = retorno.listaDeVeiculos;
        this.filteredVeiculos.next(this.listaDeVeiculos.slice());
        this.veiculoFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterVeiculos();
          });
      });
    } catch (error) {
      console.error(error);
    }
  }

  protected filterVeiculos() {
    if (!this.listaDeVeiculos) {
      return;
    }
    let search = this.veiculoFilterCtrl.value;
    if (!search) {
      this.filteredVeiculos.next(this.listaDeVeiculos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredVeiculos.next(
      this.listaDeVeiculos.filter(veiculo => veiculo.placa.toLowerCase().indexOf(search) > -1)
    );
  }

  buscarHodometroPeloVeiculo(){
     this.hodometroService.getLista(388).then(response =>{
       this.listaDeHodometro = response;
     });
  }

}
