import { Component, OnInit, Compiler, ElementRef, ViewChild } from '@angular/core';
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { ReportService } from 'app/services/report/report.service';
import { Veiculo } from 'app/classes/veiculo';
import { Filtro } from 'app/classes/filtro';
import { InformacaoRelatorio } from 'app/classes/informacao-relatorio';

@Component({
  selector: 'app-percurso-detalhado',
  templateUrl: './percurso-detalhado.component.html',
  styleUrls: ['./percurso-detalhado.component.css']
})
export class PercursoDetalhadoComponent implements OnInit {

  @ViewChild('iframe') iframe: ElementRef;
  dropdownList = [];
  dropdownListLocal = [];
  public selectedItems = [];
  public selectedItemsLocal = [];
  dropdownSettings: {};
  public filtro: Filtro;
  public pdfSrc: any;
  listaDeVeiculos: Veiculo[] = [];
  veiculo: Veiculo;
  listaInformacaoRelatorio: any[];
  showVeiculos: boolean = false;
  showBotaoPesquisa: boolean = false;
  public dataInicial: String;
  public dataFinal: String;
  tipoDeIgnicao:number = 0;

  @ViewChild('dataTable') table;
  dataTable: any;
  dtOption: any = {};

  constructor(
    private veiculoService: VeiculoService,
    private reportService: ReportService,
    private _compiler: Compiler) {
    this._compiler.clearCache();
  }


  ngOnInit() {
    try {
      this.filtro = new Filtro();
      this.veiculoService.getLista().then(response => {
          var data = response.listaDeVeiculos;
          this.dropdownList = [];
          this.dropdownListLocal = [];
          for (var i = 0; i < data.length; i++) {
            var veiculo = data[i];
            this.dropdownList.push({ item_id: veiculo.id, item_text: veiculo.placa });
          }

          this.dropdownSettings = {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Todos',
            unSelectAllText: 'Desmarcar todos',
            itemsShowLimit: 3,
            allowSearchFilter: true,
            searchPlaceholderText: 'Pesquisar'
          };
          this.showVeiculos = true;
      });

      this.dtOption = {
        "iDisplayLength": 17,
        "bPaginate": false,
        "fixedHeader": true,
        "bLengthChange": false,
        "bFilter": true,
        "bInfo": false,
        "bAutoWidth": false,
        "scrollY": 400,
        "deferRender": true,
        "scroller": true,
        "language": {
          "lengthMenu": "Mostrando _MENU_ registros por pagina",
          "zeroRecords": "Nenhum registro encontrado",
          "search": "Pesquisar",
          "oPaginate": {
            "sNext": "Próximo",
            "sPrevious": "Anterior",
            "sFirst": "Primeiro",
            "sLast": "Último"
          }
        },
        'dom': 'Bfrtip',
        'buttons': [
          'csv', 'excel'
        ]
      };
      this.dataTable = $(this.table.nativeElement);
      this.dataTable.DataTable(this.dtOption);

    } catch (error) {
      console.error(error);
    }

  }


  doSearch() {
    this.showBotaoPesquisa = true;
    try {
      if(this.tipoDeIgnicao){
        if(this.tipoDeIgnicao == 1){
          this.filtro.ignicaoAtiva = true;
        }
        if(this.tipoDeIgnicao == 2){
          this.filtro.ignicaoAtiva = false;
        }
      }
      this.filtro.dataInicialString = this.dataInicial;
      this.filtro.dataFinalString = this.dataFinal;
      this.filtro.listaDeVeiculos = this.listaDeVeiculos;
      this.reportService.getReportRelatorioPercursoDetalhado(this.filtro).then(response => {
        let informacaoRelatorio = response.listaInformacaoRelatorio;
        this.ajustarTabelaDeDetalhes(informacaoRelatorio);
        this.showBotaoPesquisa = false;
      });
    } catch (error) {
      console.error(error);
    }
  }

  onItemSelect(item: any) {
    this.inserirVeiculoSelecionado(item);
  }

  onSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirVeiculoSelecionado(items[i]);
    }
  }

  onDeSelect(item: any) {
    this.removerVeiculoSelecionado(item);
  }

  inserirVeiculoSelecionado(item: any): void {
    try {
      var veiculo = this.listaDeVeiculos.find(v => v.id == item.item_id);
      if (!veiculo) {
        this.veiculo = new Veiculo();
        this.veiculo.id = item.item_id;
        this.veiculo.placa = item.item_text;
        this.listaDeVeiculos.push(this.veiculo);
      }
    } catch (error) {
      console.log(error);
    }
  }

  
  removerVeiculoSelecionado(item: any): void {
    try {
      let index = this.listaDeVeiculos.findIndex(v => v.id == item.item_id);
      this.listaDeVeiculos.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }


  ajustarTabelaDeDetalhes(informacaoRelatorio: InformacaoRelatorio[]) {
    try {
      this.dataTable.DataTable().clear().draw();
      informacaoRelatorio.forEach(i1 => {
        let veiculo = i1.campo01;
        let ing = i1.campo02;
        let gps = i1.campo03;
        let data = i1.campo04;
        let vel = i1.campo05;
        let hor = i1.campo06;
        let hod = i1.campo07;
        let tensao = i1.campo08;
        let evento = i1.campo09;
        let end = i1.campo10;
        this.dataTable.DataTable().row.add([veiculo, ing, gps, data, vel, hor, hod,tensao, evento, end]);
      });
      this.dataTable.DataTable().draw();
    } catch (error) {
      console.log(error);
    }
  }

}
