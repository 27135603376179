import {
  Compiler,
  Component,
  OnInit,
  ViewChild
} from "@angular/core";
import { ReportService } from "app/services/report/report.service";
import Swal from "sweetalert2";
import { Filtro } from "app/classes/filtro";
import { RelatorioInconformidades } from "app/classes/relatorio-inconformidades";
import { Veiculo } from "app/classes/veiculo";
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { RelatorioConsultaLocaisPercorridos } from "app/classes/relatorio-consulta-locais-percorridos";
import { AuthGuard } from "app/guards/auth.guard";
import { User } from "app/classes/user";
import { LocalService } from "app/services/crud/local.service";
import { Local } from "app/classes/local";

@Component({
  selector: "app-consulta-locais-percorridos",
  templateUrl: "./consulta-locais-percorridos.component.html",
  styleUrls: ['./consulta-locais-percorridos.component.css']
})
export class ConsultaLocaisPercorridosComponent implements OnInit {
  dropdownList = [];
  dropdownListLocalOrigem = [];
  dropdownListLocalDestino = [];
  public selectedItems = [];
  public selectedItemsTiposInconformidades = [];
  public selectedItemsLocalOrigem = [];
  public selectedItemsLocalDestino = [];
  dropdownSettings: {};
  dropdownSettingsLocal: {};
  public filtro: Filtro;
  public pdfSrc: any;
  listaDeVeiculos: Veiculo[] = [];
  listaDeLocais: Local[] = [];
  veiculo: Veiculo;
  localOrigem: Local;
  localDestino: Local;
  listaInformacaoRelatorio: any[];
  showVeiculos: boolean = false;
  showLocalOrigem: boolean = false;
  showBotaoPesquisa: boolean = true;
  isLoading = false;
  public dataInicial: string;
  public dataFinal: string;
  eventoSensores: number = 0;
  public company: number;
  public loggedUser: User;
  public authGuardM: AuthGuard;

  relatorioConsultaLocaisPercorridos: RelatorioConsultaLocaisPercorridos[] = [];
  dtOption: any = {};
  @ViewChild("dataTable") table;
  dataTable: any;

  constructor(
    private localService: LocalService,
    private veiculoService: VeiculoService,
    private reportService: ReportService,
    private _compiler: Compiler,
    public authGuard: AuthGuard
  ) {
    this.loggedUser = authGuard.loggedUser();
    this.company = this.loggedUser.selectedCompany.id;
    this._compiler.clearCache();
  }

  ngOnInit() {
    this.filtro = new Filtro();
    this.getVeiculos();
    this.getLocais();
    this.prepareDataTable();
  }



  prepareDataTable() {
    try {
      this.dtOption = {
        iDisplayLength: 17,
        bPaginate: false,
        fixedHeader: true,
        bLengthChange: false,
        bFilter: true,
        bInfo: false,
        bAutoWidth: false,
        scrollY: 400,
        deferRender: true,
        scroller: true,
        language: {
          lengthMenu: "Mostrando _MENU_ registros por pagina",
          zeroRecords: "Nenhum registro encontrado",
          search: "Pesquisar",
          oPaginate: {
            sNext: "Próximo",
            sPrevious: "Anterior",
            sFirst: "Primeiro",
            sLast: "Último",
          },
        },
        dom: "Bfrtip",
        buttons: ["csv", "excel", "print"],
      };
      this.dataTable = $(this.table.nativeElement);
      this.dataTable.DataTable(this.dtOption);
    } catch (error) {
      console.error(error);
    }
  }

  getVeiculos() {
    this.veiculoService.getLista().then(response => {
      var data = response.listaDeVeiculos;
      this.dropdownList = [];
      for (var i = 0; i < data.length; i++) {
        var veiculo = data[i];
        this.dropdownList.push({ item_id: veiculo.id, item_text: veiculo.placa });
      }

      this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Todos',
        unSelectAllText: 'Desmarcar todos',
        itemsShowLimit: 3,
        allowSearchFilter: true,
        searchPlaceholderText: 'Pesquisar'
      };
      this.showVeiculos = true;
    }).catch((error) => console.error(error));
  }

  getLocais(){
    try {
      this.localService.getListaLocaisSemCoordenadas().then(response => {
        this.listaDeLocais = [];
        this.listaDeLocais = response.listaDeLocal;
        this.dropdownListLocalOrigem = [];
        this.dropdownListLocalDestino = [];
        for (var i = 0; i < this.listaDeLocais.length; i++) {
          var local = this.listaDeLocais[i];
          this.dropdownListLocalOrigem.push({ item_id: local.id, item_text: local.nome });
          this.dropdownListLocalDestino.push({ item_id: local.id, item_text: local.nome });
        }
        this.dropdownSettingsLocal = {
          singleSelection: true,
          idField: 'item_id',
          textField: 'item_text',
          selectAllText: 'Todos',
          unSelectAllText: 'Desmarcar todos',
          itemsShowLimit: 3,
          allowSearchFilter: true,
          searchPlaceholderText: 'Pesquisar'
        };

        this.showLocalOrigem = true;
    });
    } catch (error) {
      
    }
  }

  async doSearch() {
    if (!this.dataInicial || !this.dataFinal) {
      return Swal.fire({
        position: 'top-end',
        type: 'error',
        title: `Erro`,
        text: 'Os campos "Data inicial" e "Data final" são obrigatórios',
        showConfirmButton: false,
        timer: 4000
      });
    }
    try {
      this.showBotaoPesquisa = false;
      this.isLoading = true;
      this.filtro = new Filtro();
      this.filtro.dataInicialString = this.dataInicial;
      this.filtro.dataFinalString = this.dataFinal;
      this.filtro.listaDeVeiculos = this.listaDeVeiculos;
      this.filtro.localOrigem = this.localOrigem;
      this.filtro.localDestino = this.localDestino;
      this.filtro.idCliente = this.company;
      const response = await this.reportService
        .getReportRelatorioConsultaLocaisPercorridos(this.filtro)
      this.ajustarTabelaDeDetalhes(response.listaRelatorioConsultaLocaisPercorridos);
    } catch (error) {
      console.error(error);
    } finally {
      this.showBotaoPesquisa = true;
      this.isLoading = false;
    }
  }

  onDeSelect(item: any) {
    this.removerVeiculoSelecionado(item);
  }

  onItemSelect(item: any) {
    this.inserirVeiculoSelecionado(item);
  }

  onSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirVeiculoSelecionado(items[i]);
    }
  }


  inserirVeiculoSelecionado(item: any): void {
    try {
      var moto = this.listaDeVeiculos.find(m => m.id == item.item_id);
      if (!moto) {
        this.veiculo = new Veiculo();
        this.veiculo.id = item.item_id;
        this.listaDeVeiculos.push(this.veiculo);
      }
    } catch (error) {
      console.log(error);
    }
  }

  removerVeiculoSelecionado(item: any): void {
    try {
      let index = this.listaDeVeiculos.findIndex(m => m.id == item.item_id);
      this.listaDeVeiculos.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }


  ajustarTabelaDeDetalhes(relatorioConsultaLocaisPercorridos: RelatorioConsultaLocaisPercorridos[]) {
    try {
      this.relatorioConsultaLocaisPercorridos = relatorioConsultaLocaisPercorridos;
      this.dataTable.DataTable().clear().draw();
      relatorioConsultaLocaisPercorridos.forEach((item) => {

        const placa = item.placa || '-';
        const localOrigem = item.localOrigem || '-';
        const dataHoraSaida = item.dataHoraSaida || '-';
        const localChegada = item.localChegada || '-';
        const dataHoraChegada = item.dataHoraChegada || '-';
        const tempoTotalPercurso = item.tempoTotalPercurso || '-';
        const velocidadeMedia = item.velocidadeMedia || '-';
        const velocidadeMaxima = item.velocidadeMaxima || '-';
        const maximoRpm = item.maximoRpm || '-';


        this.dataTable
          .DataTable()
          .row.add([
            placa,
            localOrigem,
            dataHoraSaida,
            localChegada,
            dataHoraChegada,
            tempoTotalPercurso,
            velocidadeMedia,
            velocidadeMaxima,
            maximoRpm
          ]);
      });
      this.dataTable.DataTable().draw();
    } catch (error) {
      console.log(error);
    }
  }


  onItemSelectLocalOrigem(item: any) {
    this.inserirLocalOrigemSelecionado(item);
  }

  inserirLocalOrigemSelecionado(item: any): void {
    try {
      this.localOrigem = this.listaDeLocais.find(m => m.id == item.item_id);
    } catch (error) {
      console.log(error);
    }
  }


  onDeSelectLocalOrigem(item: any) {
    this.localOrigem = null;
  }

  onItemSelectLocalDestino(item: any) {
    this.inserirLocalDestinoSelecionado(item);
  }

  inserirLocalDestinoSelecionado(item: any): void {
    try {
      this.localDestino = this.listaDeLocais.find(m => m.id == item.item_id);
    } catch (error) {
      console.log(error);
    }
  }

  onDeSelectLocalDestino(item: any) {
    this.localDestino = null;
  }
}
