import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { User } from '../../../../classes/user';
import { UserService } from '../../../../services/crud/user.service';
import { CompanyService } from '../../../../services/crud/company.service';
import { BaseFormComponent } from '../../../base/base-form/base-form.component';
import { UserClaim } from '../../../../classes/user-claim';
import { AuthGuard } from '../../../../guards/auth.guard';
import { IDNameTuple } from '../../../../classes/id-name-tuple';
import { BranchService } from '../../../../services/crud/branch.service';
import { RESTResponseVO } from '../../../../classes/restresponsevo';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-form-user',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css']
})
export class UserFormComponent extends BaseFormComponent<User> {

    public branchSelect: any;
    public claimSelect: any;
    public companySelect: any;

    public showBranchInsert: boolean;
    public showClaimInsert: boolean;
    public showCompanyInsert: boolean;
    public doingDisable: boolean;
    public branches: any[];
    public claims: any[];
    public companies: any[];
    public newPassword: string;
    private loggedUser: User;
    private newClaims: any;
    private menus: any[];

    constructor(private userService: UserService,
        private companyService: CompanyService,
        private branchService: BranchService,
        private authGuard: AuthGuard,
        route: ActivatedRoute,
        private router: Router) {
            super(route, router, 'adm/user/list');
            this.loggedUser = this.authGuard.loggedUser();
    }

    assembleObjectFromForm(): void {
    }

    addBranch(): void {
        this.errorMessage = undefined;

        if (!this.branchSelect) {
            this.errorMessage = "Nenhuma unidade foi selecionada!";
            return;
        }

        this.showBranchInsert = false;

        for (var index in this.activeObject.branchList) {
            if (this.activeObject.branchList[index].id === this.branchSelect.id) {
                this.errorMessage = "Unidade já existe na lista!";
                return;
            }
        }

        this.activeObject.branchList.push(new IDNameTuple(this.branchSelect.id, this.branchSelect.apelido));
        this.branchSelect = undefined;
    }

    addClaim(): void {
        this.errorMessage = undefined;

        if (!this.claimSelect) {
            this.errorMessage = "Nenhuma permissão foi selecionada!";
            return;
        }

        this.showClaimInsert = false;

        for (var index in this.activeObject.claimList) {
            if (this.activeObject.claimList[index].name === this.claimSelect) {
                this.errorMessage = "Permissão já existe na lista!";
                return;
            }
        }

        this.activeObject.claimList.push(new IDNameTuple(0, this.claimSelect));
        this.claimSelect = undefined;
    }

    loadNewClaims() : void {

        if (this.activeID && (this.activeID !== 0)) {
            let initial = []
                this.userService.getPermissionsById(this.loggedUser.id)
                .then(response => {
                    this.newClaims = response.response;
                    if(this.newClaims && this.newClaims.length){
                        this.newClaims.reduce((previous, current) => {
                            if(initial.length) {
                                const index = initial.findIndex(c=> c.parentMenuId == current.parentMenuId);
                                if(index >= 0) {
                                    initial[index].itens.push({
                                        situation: current.situation,
                                        description: current.description,
                                        value: current.value
                                    })
                                    return;
                                }
                            }

                            initial.push({
                                parentMenuId: current.parentMenuId,
                                parentMenuDescription: current.parentMenuDescription,
                                itens: [{
                                    situation: current.situation,
                                    description: current.description,
                                    value: current.value
                                }]
                            });

                        }, initial)
                    }
                    this.menus = initial;
                });
        }
    }

    addCompany(): void {
        this.errorMessage = undefined;

        if (!this.companySelect) {
            this.errorMessage = "Nenhuma empresa foi selecionada!";
            return;
        }

        this.showCompanyInsert = false;

        this.activeObject.companyList.push(new IDNameTuple(this.companySelect.id, this.companySelect.name));
        this.companySelect = undefined;
    }

    loadAuxiliaryLists(): void {
        this.claims = [{ claim: "_rota" }, { claim: "dp" }, { claim: "frota" }, { claim: "apuracao" }, { claim: "mobile" }, { claim: "operacao" }];
        if (this.authGuard.isAdmin()) {
            this.claims.push({ claim: "admin" });
        }

        this.companies = this.authGuard.loggedUser().companyList;
        this.branchService.listURL = environment.baseURL + 'App/v2/web/unidades/lista/' + this.authGuard.loggedUser().selectedCompany.id;
        this.branchService.getList().then(response => { this.branches = response.response; });
    }

    loadObject(): void {
        if (this.activeID && (this.activeID !== 0)) {
            this.userService.getById(this.activeID).then(response => {
                this.activeObject = response.response;
                console.log('entrou aqui activeObject.cadastroMotorista: ',this.activeObject)
                this.activeObject.branchList = this.activeObject.branchList.filter(el => {
                    return el.id !== 0;
                });
            });

            this.loadNewClaims();
        }
        this.loading = false;
    }

    initObject(): void {
        this.activeObject = new User();
        this.activeObject.branchList = new Array<any>();
        this.activeObject.claimList = new Array<any>();
        this.activeObject.companyList = new Array<any>();
        this.loading = false;
    }

    doCreate(t: User): Promise<RESTResponseVO<User>> {
        t = this.clearUnusedFields(t);
        return this.userService.create(t);
    }

    async doEdit(t: User): Promise<RESTResponseVO<User>> {
        t = this.clearUnusedFields(t);
        const response = await this.userService.update(t);
        const currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
        if (response.response.id == currentUser.id) {
            currentUser.tempoAtualizacaoDashboard = response.response.tempoAtualizacaoDashboard;
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
        }
        return response;
    }

    doDelete(t: User): Promise<RESTResponseVO<string>> {
        return this.userService.delete(t.id);
    }

    doDisable() {
        if (this.activeObject.situacao) {
            this.successMessage = 'Usuário Inativado. Clique em Gravar!';
            this.activeObject.situacao = false;
        } else {
            this.successMessage = 'Usuário Ativado. Clique em Gravar';
            this.activeObject.situacao = true;
        }
    }

    removeCompany(company: any) {
        this.loading = true;
        this.errorMessage = undefined;

        this.userService.removeCompany(company).then(resultItem => {
            this.errorMessage = resultItem.error;
            if (!this.errorMessage) {
                this.activeObject.companyList = this.activeObject.companyList.filter(el => {
                    return el.id !== company.id;
                });
            }
            this.loading = false;
        });
    }

    removeBranch(branch: any) {
        this.loading = true;
        this.errorMessage = undefined;

        this.userService.removeBranch(branch).then(resultItem => {
            this.errorMessage = resultItem.error;
            if (!this.errorMessage) {
                this.activeObject.branchList = this.activeObject.branchList.filter(el => {
                    return el.id !== branch.id && el.id !== 0;
                });
            }
            this.loading = false;
        });
    }

    removeClaim(claim: any) {
        this.loading = true;
        this.errorMessage = undefined;

        this.userService.removeClaim(claim).then(resultItem => {
            this.errorMessage = resultItem.error;
            if (!this.errorMessage) {
                this.activeObject.claimList = this.activeObject.claimList.filter(el => {
                    return el.id !== claim.id;
                });
            }
            this.loading = false;
        });
    }

    toggleBranchInsert(): void {
        this.showBranchInsert = !this.showBranchInsert;
    }

    toggleClaimInsert(): void {
        this.showClaimInsert = !this.showClaimInsert;
    }

    toggleCompanyInsert(): void {
        this.showCompanyInsert = !this.showCompanyInsert;
    }

    changePassword() {
        this.loading = true;
        this.errorMessage = undefined;

        this.userService.resetPassword(this.activeID, this.activeObject.criptoPassword, this.newPassword).then(response => {
            this.errorMessage = response.error;
            if (!this.errorMessage) {
                this.router.navigate([this.listPath], {skipLocationChange: true});
            }
            this.loading = false;
        });
    }

    btnResetPassword(user: User) {
        this.loading = true;
        this.errorMessage = undefined;

        this.userService.resetPassword(this.activeID, this.activeObject.criptoPassword, "123456").then(response => {
            this.errorMessage = response.error;
            if (!this.errorMessage) {
                this.router.navigate([this.listPath], {skipLocationChange: true});
            }
            this.loading = false;
        });
    }

    clearUnusedFields(t: User): User{
        delete(t.confirmPassword);
        delete(t.selectedCompany);
        delete(t.selectedBranch);

        return t;
    }

    setActiveID(): void {
        this.activeID = this.activeObject.id;
    }

    usuarioLogado():User{
        return this.loggedUser;
    }
}
