import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, MatTableDataSource } from '@angular/material';
import { utils, writeFile } from 'xlsx';
import { PDFService } from 'app/services/pdf.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'

@Component({
  selector: 'app-map-motorista-details',
  templateUrl: './modal-tempo-periodo-parado.component.html',
  styleUrls: ['./modal-tempo-periodo-parado.component.css']
})
export class ModalTempoPeriodoParadoComponent implements OnInit {

  @Input() public dados: any;
  titulo: string;

  displayedColumns: string[] = [
    'local',
    'dataInicio',
    'dataFinal',
    'tempoTotal',
    'latitude',
    'longitude',
  ];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  contentToExport: any;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private pdfService: PDFService,
  ) { }

  ngOnInit(): void {
    const data = this.dados.listaDetalhePeriodoParado.map((dado) => {
      if (dado.local && dado.local !== '-') {
        return {
          unidade: this.dados.unidade,
          placa: this.dados.placa,
          local: dado.local,
          dataInicio: dado.dataInicio,
          dataFinal: dado.dataFinal,
          tempoTotal: dado.tempoTotal,
          latitude: dado.latitude,
          longitude: dado.longitude
        }
      } else if (dado.endereco) {
        return {
          unidade: this.dados.unidade,
          placa: this.dados.placa,
          local: dado.endereco,
          dataInicio: dado.dataInicio,
          dataFinal: dado.dataFinal,
          tempoTotal: dado.tempoTotal,
          latitude: dado.latitude,
          longitude: dado.longitude
        }
      } else {
        return {
          unidade: this.dados.unidade,
          placa: this.dados.placa,
          local: 'Sem registros',
          dataInicio: dado.dataInicio,
          dataFinal: dado.dataFinal,
          tempoTotal: dado.tempoTotal,
          latitude: dado.latitude,
          longitude: dado.longitude
        }
      }
    });
    this.contentToExport = data;
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.titulo = `Detalhes do veículo - ${this.dados.placa}`;
  }

  redirecionar(row) {
    if (row.latitude && row.longitude) {
      const latitude = row.latitude;
      const longitude = row.longitude;
      return window.open(
        'http://maps.google.com/maps?q=' + latitude + ',' + longitude,
        '_blank'
      );
    }
  }

  exportExcel() {
    if (this.contentToExport) {
      const ws = utils.json_to_sheet(this.contentToExport);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'Tabela de Retorno');
      return writeFile(wb, 'TempoPeriodoParado.xlsx');
    }
  }

  exportCSV() {
    if (this.contentToExport) {
      const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Tempo periodo parado',
        useBom: true,
        noDownload: false,
        headers: ["Unidade", "Placa", "Quantidade de ocorrências", "Tempo total"]
      };

      new AngularCsv(this.contentToExport, "TempoPeriodoParado", csvOptions);
    }
  }

  createHeader(
    name: string,
    dataIndex?: number,
    canSort?: boolean,
    colspan?: number,
    css?: string,
    tooltip?: string
  ) {
    if (canSort == null) canSort = false;
    if (colspan == null) colspan = 1;
    if (dataIndex == null) dataIndex = -1;
    if (css == null) css = 'title-left';
    if (tooltip == null) tooltip = '';

    return {
      name: [name],
      canSort: canSort,
      colspan: colspan,
      dataIndex: dataIndex,
      css: css,
      tooltip: tooltip
    };
  }

  exportPrint() {
    if (this.contentToExport) {
      const data = this.contentToExport;
      const dataFields = Object.keys(this.contentToExport[0]).filter(i => i !== 'hovered').map((field) => ({
        name: field,
        prefix: '',
        separator: "",
        sufix: "",
        type: "string",
      }));
      const headers = [
        [
          this.createHeader("unidade", 0, true),
          this.createHeader("placa", 1, true),
          this.createHeader("Local", 2, true),
          this.createHeader("Data Início", 3, true),
          this.createHeader("Data Final", 4, true),
          this.createHeader("Tempo total", 6, true),
          this.createHeader("Latitude", 6, true),
          this.createHeader("Logitude", 7, true),
        ]
      ];
      const pageOrientation = 'portrait';
      this.pdfService.printList(
        data,
        dataFields,
        headers,
        pageOrientation
      );
    }
  }

  close() {
    this.activeModal.close();
  }
}
