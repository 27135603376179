import { IDEntity } from "./IDEntity";
import { Driver } from "./driver";
import { Event } from "./event";
import { Summary } from "./summary";

export class Journey extends IDEntity {
    motoristasId: Driver;
    eventos: Event[];
    jornadaInicio: Date;
    jornadaFim: Date;
    horasExtras: Number;
    trabalhado: Number;
    hedFeriado: Number;
    henFeriado: Number;
    hedDSR: Number;
    henDSR: Number;
    horasFaltosas: Number;
    resumoEventos: Map<string, Summary>;
    resumoTiposEstados: Map<string, Summary>;
    resumoEstados: Map<string, Summary>;
    locked: boolean;
    horasExtrasAbonada:boolean;
    horasExtrasCinquentaPorcento:boolean;
    textoLivre:String;
}