import { Component, Input} from '@angular/core';

@Component({
  selector: 'quadro-combustivel-component',
  templateUrl: './quadro-combustivel-component.html',
  styleUrls: ['./quadro-combustivel-component.scss']
})
export class QuadroCombustivelComponent {
  
  @Input()
  consumoCombustivel:number;

  constructor() {}

}