import { BaseListComponent } from "../../base/base-list/base-list.component";
import { OnInit, Component } from "@angular/core";
import { AuthGuard } from "../../../guards/auth.guard";
import { Router } from "@angular/router";
import { DriverService } from "../../../services/crud/driver.service";
import { JourneyService } from "../../../services/crud/journey.service";
import { UtilService } from "../../../services/utils.service";
import { PDFService } from "../../../services/pdf.service";
import { Driver } from "../../../classes/driver";
import { Observable } from "rxjs/Observable";
import { JourneyLogReport } from "../../../classes/journey-log-report";
import { Instant } from "../../../classes/instant";
import { ReportService } from "../../../services/report/report.service";

@Component({
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.css']
})
export class JourneyCalculationListComponent extends BaseListComponent implements OnInit {
    constructor(private authGuard: AuthGuard,
        private router: Router,
        private driverService: DriverService,
        private journeyService: JourneyService,
        private utilService: UtilService,
        private reportService: ReportService,
        pdfService: PDFService) {
        super(utilService, pdfService, authGuard);
        this.searchField = "name";
    }

    public driverList: Driver[];
    public searchObject: { driver: Driver, start: Date, end: Date, type: Number, nonconformity: boolean } = { driver: undefined, start: undefined, end: undefined, type: undefined, nonconformity: false };
    public journeyLog: JourneyLogReport;

    ngOnInit() {
        this.loading = true;
        this.driverService.getListMinimo().then(response => {
            this.driverList = response.response;
            this.driverList.sort((d1, d2) => d1.nome > d2.nome ? 1 : -1);
            
            this.loading = false;
        });
    }

    doSearch() {
        this.errorMessage = undefined;

        if (!this.searchObject) {
            this.errorMessage = "Favor preencher os campos do formulário.";
        } else if (!this.searchObject.driver) {
            this.errorMessage = "Favor selecionar um motorista.";
        } else if (!this.searchObject.start || !this.searchObject.end) {
            this.errorMessage = "Data inicial ou final inválidas ou não preenchidas!";
        } else if (!this.searchObject.type) {
            this.errorMessage = "Favor selecionar um tipo de relatório.";
        } else {
            this.loading = true;

            let start = this.searchObject.start.getTime();
            let end = this.searchObject.end.getTime();

            this.searchObject.nonconformity = true;

            this.journeyService.getJourneyCalculation(this.searchObject.driver.id, start, end, this.searchObject.nonconformity).then(response => {
                if (response.error) {
                    this.errorMessage = response.error;
                } else {
                    this.tableHeaders = [
                        [
                            this.createHeader("Motorista", 0, true),
                            this.createHeader("Mês Referência", 1, true),
                            this.createHeader("Horário", 2, true)
                        ]
                    ];

                    this.dataFields = [
                        this.createField("motorista.nome", "string", undefined, "motorista.sobrenome", " "),
                        this.createField("mesReferencia", "month"),
                        this.createField("identificacaoTurno", "string")
                    ];

                    if (response.response) {
                        this.data = [response.response];
                        if (this.searchObject.type > 0) {
                            this.journeyService.getJourneyLog(this.searchObject.driver.id, start, end).then(response => {
                                if (response.error) {
                                    this.errorMessage = response.error;
                                } else {
                                    this.journeyLog = response.response;
                                }
                            });
                        }
                        this.utilService.fixIcons();
                    }
                }

                this.loading = false;
            });
        }
    }

    print(row) {
        let loggedUser = this.authGuard.loggedUser();
        let dd = this.reportService.printJourneyCalculationReport(loggedUser.selectedCompany.name , row, this.journeyLog, this.searchObject.nonconformity);
        this.pdfService.print(dd);
    }

    driverFormatter = (x: Driver) => x.nome + ' ' + x.sobrenome;

    searchDriver = (text$: Observable<string>) =>
        text$
            .debounceTime(200)
            .distinctUntilChanged()
            .map(term => term.length < 2 ? [] : this.driverList.filter(v => v.nome.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10));

    driverTemplate(r) {
        return r.nome + " " + r.sobrenome;
    }
}