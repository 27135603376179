import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { DashboardHomeService } from 'app/services/dashboard/dashboard.service';
import { AuthGuard } from 'app/guards/auth.guard';
import { ValidadorService } from 'app/services/validador.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-map-details-inconformidades',
  templateUrl: './map-details-inconformidades.component.html',
  styleUrls: ['./map-details-inconformidades.component.scss']
})
export class MapDetailsInconformidadesComponent implements OnInit {

  @Output() onload: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public dadosVeiculo: any;
  formGroupFiltroInconformidades: FormGroup;
  listaDeInconformidades: any[] = [];
  displayedColumnsInconformidades: string[] = ['data_inconformidade', 'nome', 'lido', 'data_leitura', 'usuario', 'acoes'];
  dataSourceInconformidades: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginatorInconformidades: MatPaginator;
  @ViewChild(MatSort) sortInconformidades: MatSort;
  isHidden = false;
  noDataInconformidades = false;

  constructor(
    public activeModal: NgbActiveModal,
    private authGuard: AuthGuard,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    public validadorService: ValidadorService,
    private spinner: NgxSpinnerService,
    public formatter: NgbDateParserFormatter,
    private dashboardHomeService: DashboardHomeService
  ) {
    this.formGroupFiltroInconformidades = this.formBuilder.group({
      inicio: [null, Validators.required],
      fim: [null, Validators.required]
    });
  }

  ngOnInit(): void {

  }

  filtrarInconformidades() {
    const model = {
      idVeiculo: this.dadosVeiculo.idObjeto,
      inicio: this.formGroupFiltroInconformidades.value.inicio,
      fim: this.formGroupFiltroInconformidades.value.fim
    };
    if (this.formGroupFiltroInconformidades.invalid) {
      Object.keys(this.formGroupFiltroInconformidades.controls).forEach(key => {
        this.formGroupFiltroInconformidades.get(key).markAsTouched();
      });
    } else {
      this.onload.emit(true);
      this.dashboardHomeService.inconformidadesVeiculo(model).then(response => {
        this.isHidden = response.length === 0;
        this.noDataInconformidades = response.length === 0;
        this.listaDeInconformidades = response;
        this.dataSourceInconformidades = new MatTableDataSource(response);
        this.dataSourceInconformidades.paginator = this.paginatorInconformidades;
        this.dataSourceInconformidades.sort = this.sortInconformidades;
        this.onload.emit(false);
      }).catch((res) => {
        this.onload.emit(false);
        console.log('erro', res);
      });
    }
  }

  marcarInconformidadeComoLida(row) {
    const loggedUser = this.authGuard.loggedUser();
    try {
      this.onload.emit(true);
      this.dashboardHomeService.marcarComoLido(row.id, loggedUser.id).then(response => {
        this.onload.emit(false);
        if (response.error === '') {
          row.lido = true;
          row.usuario = loggedUser.userName;
          Swal.fire({
            position: 'top-end',
            type: 'success',
            title: `Sucesso`,
            text: 'Inconformidade marcada como lida com sucesso!',
            showConfirmButton: false,
            timer: 4000
          });
        }
      }).catch((res) => {
        this.onload.emit(false);
        console.log('erro', res);
      });
    } catch (error) {
      this.onload.emit(false);
      console.error(error);
    }
  }

}
