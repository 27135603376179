import { BaseListComponent } from "../../../base/base-list/base-list.component";
import { Component, ViewEncapsulation } from "@angular/core";
import { OperadorService } from "../../../../services/crud/operador.service";
import { UtilService } from "../../../../services/utils.service";
import { PDFService } from "../../../../services/pdf.service";
import { AuthGuard } from "../../../../guards/auth.guard";
@Component({
  templateUrl: '../../../base/base-list/base-list.component.html',
  styleUrls: [ '../../../base/base-list/base-list.component.css' ],
  encapsulation: ViewEncapsulation.None
})

export class OperadorListComponent extends BaseListComponent {

  constructor(private operadorService: OperadorService,
      private utilService: UtilService,
      pdfService: PDFService,
      authGuard: AuthGuard) {
      super(utilService, pdfService, authGuard);
      this.searchField = "nome";
  }

 

 ngOnInit() {
    this.pageTitle = "Cadastros | Operador";
      this.formPath = "/adm/operador/form";

      this.tableHeaders = [
          [
              this.createHeader("ID", 0, true),
              this.createHeader("Nome", 1, true),
              this.createHeader("Telefone", 2, true),
              this.createHeader("Número Matricula", 3, true),
              this.createHeader("iButton", 4, true),
              this.createHeader("Ativo", 5, true)
          ]
      ];

      this.dataFields = [
          this.createField("id", "string"),
          this.createField("nome", "string"),
          this.createField("telefone", "string"),
          this.createField("numeroMatricula", "string"),
          this.createField("ibutton", "string"),
          this.createField("status", "boolean")
      ];

      this.operadorService.getLista().then(response => {
          this.data = response.listaOperador;
          this.loading = false;
      });
  }
}
