import { IDEntity } from './IDEntity';
import { Company } from './company';
import { OperadorVeiculo } from './operador-veiculo';

export class Operador extends IDEntity{
    nome: string;
    telefone: string;
    unidadeId: number;
    empresa:  Company;
    dataCriacao: number;
    status: boolean;
    numeroMatricula: string;
    macroId: number;
    ibutton:String;
    funcao:String;
    listaOperadorVeiculo: OperadorVeiculo[];
    quantidadeOperadorMotorista:number;
    quantidadDeVeiculos:number;
}
 