import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { RESTResponseVO } from "../../classes/restresponsevo";
import { environment } from "../../../environments/environment";
import { MessageArchiveVO } from "../../classes/message-archive-vo";

@Injectable()
export class ChatService {
    constructor(private http: Http) { }

    getReport(driver: Number, start: Number, end: Number): Promise<RESTResponseVO<MessageArchiveVO[]>> {
        var url: string = environment.baseURLChat + 'App/v2/web/chat/relatorio/' + driver + '/' + start + '/' + end;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<MessageArchiveVO[]>;
            })
            .catch(this.handleError);
    }

    // getBoshServiceUrl(company: Number): Promise<RESTResponseVO<string>> {
    //     var url: string = environment.baseURL + 'App/v2/web/chat/boshserviceurl/' + company;
    //     return this.http.get(url)
    //         .toPromise()
    //         .then(response => {
    //             return response.json() as RESTResponseVO<string>;
    //         })
    //         .catch(this.handleError);
    // }

    getBoshServiceUrl(company: Number): Promise<RESTResponseVO<string>> {
        var url: string = environment.baseURLChat + 'App/v2/web/chat/boshserviceurl/' + company;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<string>;
            })
            .catch(this.handleError);
    }

    protected handleError(error: any): Promise<any> {
        console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
        return Promise.reject(error.message || error);
    }
}