import {Component, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BranchService } from 'app/services/crud/branch.service';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import am4lang_pt_BR from "@amcharts/amcharts4/lang/pt_BR";
import { PainelIndicadoresService } from 'app/services/administrador/painel-indicadores.service';
import { AuthGuard } from 'app/guards/auth.guard';
import { User } from 'app/classes/user';
import { DetalheIndicador, Grafico, Indicador, ListaDetalheGrafico, PainelIndicadores } from 'app/classes/painel-indicadores';
import { PainelIndicadoresTimeline, Evento } from 'app/classes/painel-indicadores-timeline';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { ShiftService } from 'app/services/crud/shift.service';
import { WorkSheet, utils, writeFile } from 'xlsx';
import * as FileSaver from 'file-saver';
import { interval, Subscription } from 'rxjs';

@Component({
    selector: 'app-painel-indicadores',
    templateUrl: './painel-indicadores.component.html',
    styleUrls: ['./painel-indicadores.component.css']
})
export class PainelIndicadoresComponent implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    formGroup: FormGroup;
    flag = '';
    unidades: any;
    turnos: any;
    opcaoSelecionada: number;
    opcaoSelecionadaTurno: number;
    cards: any[];
    loggedUser: User;
    displayedColumns = [];
    dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
    painelIndicadores: PainelIndicadores;
    painelIndicadoresTimeline: PainelIndicadoresTimeline;
    xpandStatus = true;
    mostrarTabela = false;
    mostrarGrafico = false;
    dadosGraficos: Grafico[] = [];
    dadosGraficosFiltrados: ListaDetalheGrafico[] = [];
    eventosTimeline: Evento[] = [];
    tituloGrid: string;
    filtroGrid: string;
    private chart: am4charts.XYChart;
    schemaColor = [
      { flag:'ATIVOS', color: '#063874', columns: ['nomeMotorista', 'matriculaMotorista', 'unidadeMotorista', 'turno']},
      { flag:'JORNADA', color: '#468fe7', columns: ['nomeMotorista', 'matriculaMotorista', 'unidadeMotorista', 'turno', 'dataInicioJornada']},
      { flag:'FERIAS', color: '#ae91e1', columns: ['nomeMotorista', 'matriculaMotorista', 'unidadeMotorista', 'turno', 'dataInicioFerias', 'dataFimFerias']},
      { flag:'INTERJORNADA', color: '#b15959', columns: ['nomeMotorista', 'matriculaMotorista', 'unidadeMotorista', 'turno', 'dataFimUltimaJornada']},
      { flag:'OUTROS', color: '#b15959', columns: ['nomeMotorista', 'matriculaMotorista', 'unidadeMotorista', 'turno', 'ultimoEvento', 'dataUltimoEvento', 'eventoAtual']},
      { flag:'SEMEVENTOS', color: '#b15959', columns: ['nomeMotorista', 'matriculaMotorista', 'unidadeMotorista', 'turno', 'ultimoEvento', 'dataUltimoEvento']},
    ];
    defaultColor: '#b15959';
    defaultColumns: ['nomeMotorista', 'matriculaMotorista', 'unidadeMotorista', 'turno'];
    public loading: boolean = false;
    private subscription: Subscription;
    
    constructor(
        private branchService : BranchService,
        private painelIndicadoresService: PainelIndicadoresService,
        private shiftService: ShiftService,
        private authGuard: AuthGuard,
        private fb: FormBuilder) 
    {}

    ngOnInit(): void {
        
        this.formGroup = this.fb.group({
            idUnidade: [null, [Validators.required]],
            turno: [null, [Validators.required]]

          });
          this.loggedUser =  this.authGuard.loggedUser();
          this.obterUnidades();
          this.obterTurnos();
          this.montarTela(this.loggedUser.selectedBranch.id, 0);
    }

    ngOnDestroy() {
      this.destroySubscription();
    }

    onChange(event) {
      if(this.formGroup.valid) {
        this.montarTela(this.opcaoSelecionada, this.opcaoSelecionadaTurno);
      }
    }

    atualizar() {
      if(this.formGroup.valid) {
        this.montarTela(this.opcaoSelecionada, this.opcaoSelecionadaTurno);
      }
    }

    changeAtualizacao(event) {
      if (event.checked) {
        this.createSubscription();
      } else {
        this.destroySubscription();
      }
    }

    createSubscription() {
      console.log('Atualização automática ligada.');
      // atualiza a tela a cada 10 minutos (600.000 milissegundos)
      this.subscription = interval(600000).subscribe(() => {
        console.log('atualizando...');
        this.atualizar();
      });
    }

    destroySubscription() {
      if (this.subscription) {
        console.log('Atualização automática desligada.');
        this.subscription.unsubscribe();
      }
    }

    montarTela(unidade : number, turno : number) {
      this.loading = true;
      this.cards = [];
      this.eventosTimeline = [];
      this.mostrarGrafico = false;
      this.mostrarTabela = false;
      if(this.chart) {
        this.chart.dispose();
      }
      this.painelIndicadoresService.getPainel(this.loggedUser.selectedCompany.id, unidade, turno)
        .then(response=> {
          this.painelIndicadores = response;
          if(this.painelIndicadores) {
            if(this.painelIndicadores.listaIndicadores) {
              this.preencherCards(this.painelIndicadores.listaIndicadores);
              this.mostrarTabela = true;
              this.mostrarGrafico = true;
              this.displayedColumns = this.cards[0].columns;
              this.dataSource = new MatTableDataSource(this.cards[0].listaDetalheIndicador);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
              this.flag = this.cards[0].flag;
              this.tituloGrid = this.cards[0].titulo;
              this.filtroGrid = '';
              if(this.cards[0].listaGrafico && this.cards[0].listaGrafico.length > 0) {
                this.preencherGrafico(this.cards[0].listaGrafico, this.cards[0].totalItens);
              }
            }
          }
          this.loading = false;
        });
    }

    obterUnidades() {
        this.branchService.getList().then(response => {
          this.unidades = response.response;
          this.unidades.sort((a, b) => {
            if (a.apelido < b.apelido) {
                return -1;
            }
            if (a.apelido > b.apelido) {
                return 1;
            }
            return 0;
          });

          this.opcaoSelecionada = this.loggedUser.selectedBranch.id;
        });
    }

    obterTurnos() {
      this.shiftService.getList().then(response => {
        this.turnos = response.response;
        this.turnos.sort((a, b) => {
          if (a.identificacao < b.identificacao) {
              return -1;
          }
          if (a.identificacao > b.identificacao) {
              return 1;
          }
          return 0;
        });
        if(this.turnos && this.turnos.length && this.turnos.length == 1) {
          this.opcaoSelecionadaTurno = this.turnos[0].id;
        } else {
          this.opcaoSelecionadaTurno = 0;
        }
      })
    }

    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
  
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }

    preencherCards(listaIndicadores: Indicador[] ) {
      
      listaIndicadores.forEach(c=> {
        const indexColor = this.schemaColor.findIndex(d=> d.flag == c.flag)

        let icon = '';
        if (c.flag == 'JORNADA') {
          icon = 'cached';
        } else if (c.flag == 'ATIVOS') {
          icon = 'groups';
        } else if (c.flag == 'FERIAS') {
          icon = 'real_estate_agent';
        } else {
          icon = 'logout';
        }
        
        this.cards.push({
          color: indexColor >=0 ? this.schemaColor[indexColor].color : this.defaultColor, 
          titulo: c.titulo, 
          flag: c.flag,
          totalItens: c.contador, 
          icon: icon,
          listaDetalheIndicador: c.listaDetalheIndicador,
          listaGrafico: c.listaGrafico,
          columns: indexColor >=0 ? this.schemaColor[indexColor].columns : this.defaultColumns, })
      });
    }

    preencherGrafico(listaGrafico: Grafico[], contador: number) {
      this.mostrarGrafico = true;
      this.dadosGraficosFiltrados = [];
      let chart = am4core.create("chartdiv", am4charts.XYChart);
      chart.numberFormatter.numberFormat = "#";
      const data = [];
      listaGrafico.sort((a,b)=> a.horas - b.horas)
      .forEach(c=> {
        const medidaTempo = c.horas == 1 ? 'hora' : 'horas'
        const horas = c.horas == 99? '13+' : c.horas.toString();
        data.push({
          "horasJornada": `${horas}`,
          "quatidadeMotoristas": c.quatidadeMotoristas,
          'horas': c.horas
        })
        this.dadosGraficosFiltrados.push(...c.listaDetalheGrafico);
      })

      this.dadosGraficos = listaGrafico;
      
      chart.data = data;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "horasJornada";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.title.text = 'Horas';

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = 'Motoristas';
      valueAxis.numberFormatter = new am4core.NumberFormatter();
      valueAxis.numberFormatter.numberFormat = "#";
      valueAxis.min = 0;
      if (contador < 10) {
        valueAxis.adapter.add("min", function(min, target) {
          return Math.floor(min); // Arredonda o valor mínimo para baixo
        });

        valueAxis.adapter.add("max", function(max, target) {
          return Math.ceil(max); // Arredonda o valor máximo para cima
        });
      } else {
        valueAxis.renderer.minGridDistance = 30;
      }

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = "horasJornada";
      series.dataFields.valueY = "quatidadeMotoristas";
      series.tooltipText = "{valueY.value}";
      series.showTooltipOn = "hit";
      series.columns.template.strokeOpacity = 0;

      series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      series.columns.template.tooltipText = "{valueY}";
      series.columns.template.showTooltipOn = "hover";
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color("#ffffff");
      series.tooltip.autoTextColor = false;
      series.tooltip.label.fill = am4core.color("#000000");

      series.columns.template.events.on("hit", (ev: any) => {
        if(ev.target.dataItem && ev.target.dataItem._dataContext && ev.target.dataItem._dataContext.horas) {
          const filter = this.dadosGraficos.find(j => j.horas == ev.target.dataItem._dataContext.horas)
          if(filter && filter.listaDetalheGrafico) {
            this.eventosTimeline = [];
            this.dadosGraficosFiltrados = filter.listaDetalheGrafico
            this.dataSource = new MatTableDataSource(filter.listaDetalheGrafico);
            
            if (ev.target.dataItem._dataContext.horas == 1) {
              this.filtroGrid = `(${ev.target.dataItem._dataContext.horas} hora de jornada)`;
            } else if (ev.target.dataItem._dataContext.horas == 99) {
              this.filtroGrid = "(Mais de 13 horas de jornada)";
            } else {
              this.filtroGrid = `(${ev.target.dataItem._dataContext.horas} horas de jornada)`;
            }
          } else {
            this.dadosGraficosFiltrados = []
          }
        }
      });

      this.chart = chart;
    }

    

    onCardClick(listaDetalheDaJornada: DetalheIndicador[], listaGrafico: Grafico[], columns: string[], flag: string, titulo: string, contador: number) {
      this.mostrarTabela = true;
      this.mostrarGrafico = flag == 'JORNADA'? true : false;
      this.displayedColumns = columns;
      this.dataSource = new MatTableDataSource(listaDetalheDaJornada);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.flag = flag;
      this.tituloGrid = titulo;
      this.filtroGrid = '';

      if(listaGrafico && listaGrafico.length > 0) {
        this.preencherGrafico(listaGrafico, contador);
      }
    }

    exportCSV() {
      const CSV_TYPE ='data:text/csv;charset=utf-8';
      const CSV_EXTENSION = '.csv';
      const BOM = '\uFEFF';
      const json  = this.formatarJsonExcelCsv();
  
      const ws: WorkSheet = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, this.getHeaderByFlag());
  
      utils.sheet_add_json(ws, json, { origin: 'A2', skipHeader: true });
      const csvOutput: string = utils.sheet_to_csv(ws);
  
      FileSaver.saveAs(new Blob([BOM + csvOutput], { type: CSV_TYPE }), 
      `painel_indicadores_export.${CSV_EXTENSION}`);
      
    }

    exportExcel() {
      if (this.dataSource && this.dataSource.data && this.flag) {
        const json  = this.formatarJsonExcelCsv();
        const wb = utils.book_new();
        const ws: WorkSheet = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, this.getHeaderByFlag());

        utils.sheet_add_json(ws, json, { origin: 'A2', skipHeader: true });

        utils.book_append_sheet(wb, ws, 'Sheet1');

        writeFile(wb, 'painel_indicadores.xlsx');
      }
    }

    formatarJsonExcelCsv() {
      const itens = this.dataSource.filteredData.map(e =>{
        switch (this.flag) {
          case 'ATIVOS':
            return {
                    'nomeMotorista': e.nomeMotorista,
                    'matriculaMotorista': e.matriculaMotorista,
                    'unidadeMotorista': e.unidadeMotorista,
                    'turno': e.turno
                  }
          case 'JORNADA':
            return {
              'nomeMotorista': e.nomeMotorista,
              'matriculaMotorista': e.matriculaMotorista,
              'unidadeMotorista': e.unidadeMotorista,
              'turno': e.turno,
              'dataInicioJornada': e.dataInicioJornada
            }
          case 'FERIAS':
          return {
            'nomeMotorista': e.nomeMotorista,
            'matriculaMotorista': e.matriculaMotorista,
            'unidadeMotorista': e.unidadeMotorista,
            'turno': e.turno,
            'dataInicioFerias': e.dataInicioFerias,
            'dataFimFerias': e.dataFimFerias,
          }
          case 'INTERJORNADA':
            return {
              'nomeMotorista': e.nomeMotorista,
              'matriculaMotorista': e.matriculaMotorista,
              'unidadeMotorista': e.unidadeMotorista,
              'turno': e.turno,
              'dataFimUltimaJornada': e.dataFimUltimaJornada
            }
          case 'OUTROS':
            return {
              'nomeMotorista': e.nomeMotorista,
              'matriculaMotorista': e.matriculaMotorista,
              'unidadeMotorista': e.unidadeMotorista,
              'turno': e.turno,
              'ultimoEvento': e.ultimoEvento,
              'dataUltimoEvento': e.dataUltimoEvento,
              'eventoAtual': e.eventoAtual
            }
          case 'SEMEVENTOS':
            return {
              'nomeMotorista': e.nomeMotorista,
              'matriculaMotorista': e.matriculaMotorista,
              'unidadeMotorista': e.unidadeMotorista,
              'turno': e.turno,
              'ultimoEvento': e.ultimoEvento,
              'dataUltimoEvento': e.dataUltimoEvento
            }
          default:
            return {
              'nomeMotorista': e.nomeMotorista,
              'matriculaMotorista': e.matriculaMotorista,
              'unidadeMotorista': e.unidadeMotorista,
              'turno': e.turno
            }
        }
      });
      return itens;
    }

    getHeaderByFlag(): string [][] {
      const header = []
      const indexColor = this.schemaColor.findIndex(d=> d.flag == this.flag);
      header.push(indexColor >=0 ? this.schemaColor[indexColor].columns : this.defaultColumns);
      return header
    }

    onRowClick(row: any): void {
      if (this.mostrarGrafico) {
        this.eventosTimeline = [];
        this.painelIndicadoresService.getTimeline(row.idJornadaInicial)
            .then(response=> {
              this.painelIndicadoresTimeline = response;
              if(this.painelIndicadoresTimeline) {
                if(this.painelIndicadoresTimeline.listaEventos) {
                  this.eventosTimeline.push(...this.painelIndicadoresTimeline.listaEventos);
                } 
              }
            });
      }
    }

}