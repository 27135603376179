import { Injectable } from '@angular/core';
import { Hodometro } from 'app/classes/hodometro';
import { CRUDService } from "../base/crud.service";
import { Http } from "@angular/http";
import { environment } from "../../../environments/environment";
import { AuthGuard } from "../../guards/auth.guard";
import { User } from '../../classes/user';
import { RESTResponseVO } from "app/classes/restresponsevo";
import { Macros } from "app/classes/macros";
import { RetornoMacros } from "app/classes/retorno-macros";
import { VeiculoMinimo } from 'app/classes/veiculo-minimo';
import { RetornoHodometro } from 'app/classes/retorno-hodometro';
@Injectable({
  providedIn: 'root'
})
export class HodometroService extends CRUDService<Hodometro>{

  public idUser: number;
  constructor(http: Http, public authGuard: AuthGuard) {
    super(http, environment.baseURL + 'App/v2/web/hodometro', true);
    var loggedUser: User = authGuard.loggedUser();
    this.idUser = loggedUser.id;
  }


  getLista(idVeiculo: number): Promise<Hodometro[]> {
    try {
      let veiculo: VeiculoMinimo = new VeiculoMinimo();
      veiculo.id = idVeiculo;
      const url = `${this.defaultUrl}/buscarListaDeHodometro`;
      return this.http
        .post(url, JSON.stringify(veiculo), { headers: this.headers })
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            var retorno: RetornoHodometro = response.json() as RetornoHodometro;
            var ret: Hodometro[] = retorno.listaDeHodometro;
            return ret;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }


  enviarRequisicao(macros: Macros, url: string): Promise<RESTResponseVO<Macros>> {
    try {
      return this.http
        .post(url, JSON.stringify(macros), { headers: this.headers })
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            var retorno: RetornoMacros = response.json() as RetornoMacros;
            var ret: Macros = retorno.macros;
            if (ret === null) {
              ret = new Macros();
            }
            ret.id = 0;
            return ret;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }


}
