import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { User } from '../classes/user';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if (next.url[0].path === 'context-picker') {
            return true;
        }

        if (localStorage.getItem('currentUser')) {
            var user = JSON.parse(localStorage.getItem('currentUser')) as User;
            return (user.selectedBranch !== undefined && user.selectedCompany !== undefined);
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { skipLocationChange: true } /*, { queryParams: { returnUrl: state.url }}*/); // usar para manter a página que tentou chamar, e redirecionar após o login
        return false;
    }

    public loggedUser(): User {
        if (localStorage.getItem('currentUser')) {
            return JSON.parse(localStorage.getItem('currentUser')) as User;
        } else {
            return null;
        }
    }

    public logout(): void {
        localStorage.removeItem("currentUser");
        this.router.navigate(['/login'], { skipLocationChange: true });
    }

    public isApuracao(): boolean {
        let loggedUser = this.loggedUser();
        let isApuracao = false;
        if (loggedUser && loggedUser.claimList) {
            loggedUser.claimList.forEach((claim) => {
                if (claim.name === "apuracao") {
                    isApuracao = true;
                }
            });
        }
        return isApuracao;
    }

    public isAdmin(): boolean {
        let loggedUser = this.loggedUser();
        let isAdmin = false;
        if (loggedUser && loggedUser.claimList) {
            loggedUser.claimList.forEach((claim) => {
                if (claim.name === "admin") {
                    isAdmin = true;
                }
            });
        }
        return isAdmin;
    }
}
