import { IDEntity } from "./IDEntity";
import { WaitingPoint } from "./waiting-point";
import { Company } from "./company";

export class LoadingUnloadingPoints extends IDEntity {
    telefone: string;
    pessoaResponsavel: string;
    empresaId: Company;
    pontosDeEsperaId: WaitingPoint;
    longitude: any;
    latitude: any;
    raioLocalidade: Number;
    status: string;
}