import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { FiltrosMonitoramentoJornada } from 'app/classes/filtro-monitoramento-jornada';
import { RESTResponseVO } from 'app/classes/restresponsevo';
import { RetornoContadorMonitoramentoJornada } from 'app/classes/retorno-contador-monitoramento-jornada';
import { RetornoMonitoramentoJornada } from 'app/classes/retorno-jornada-motorista';
import { RetornoMotorista } from 'app/classes/retorno-motorista';
import { RetornoSituacaoMotorista } from 'app/classes/retorno-situacao-motorista';
import { RetornoTurnoMotorista } from 'app/classes/retorno-turno-motorista';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MotoristaService {
  protected headers = new Headers({ 'Content-Type': 'application/json' });
  listURL: any;
  loggedUser: any;

  constructor(public http: Http,) {
    this.listURL = environment.baseURL + '/App/v2/web/jornadas';
  }

  getListaSituacoes() {
    const url = `${this.listURL}/listarSituacoesMotorista`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        return response.json() as RetornoSituacaoMotorista;
      })
      .catch(this.handleError);
  }

  getListaTurnosMotorista() {
    const url = `${this.listURL}/listarTurnosMotorista`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        return response.json() as RetornoTurnoMotorista;
      })
      .catch(this.handleError);
  }

  getLista() {
    const url = `${this.listURL}/listarMotoristas`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        return response.json() as RetornoMotorista;
      })
      .catch(this.handleError);
  }

  getListaAusentes() {
    const url = `${this.listURL}/listarMotoristasPendentes`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        return response.json() as RetornoMotorista;
      })
      .catch(this.handleError);
  }

  getListaDentroDaJornada() {
    const url = `${this.listURL}/listarMotoristasDentroJornada`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        return response.json() as RetornoMotorista;
      })
      .catch(this.handleError);
  }

  getListaJornadaExtrapolada() {
    const url = `${this.listURL}/listarMotoristasJornadaExtrapolada`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        return response.json() as RetornoMotorista;
      })
      .catch(this.handleError);
  }

  getListaParadas() {
    const url = `${this.listURL}/listarMotoristasParadas`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        return response.json() as RetornoMotorista;
      })
      .catch(this.handleError);
  }

  public getContadoresMonitoramento() {
    const url = `${this.listURL}/listarContadoresMonitoramento`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        return response.json() as RetornoContadorMonitoramentoJornada;
      })
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('Ocorreu um erro: ', error);
    return Promise.reject(error.message || error);
  }
}
