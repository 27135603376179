import { CRUDService } from "../base/crud.service";
import { Http } from "@angular/http";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { AuthGuard } from "../../guards/auth.guard";
import { User } from '../../classes/user';
import { Company } from "../../classes/company";
import { RESTResponseVO } from "app/classes/restresponsevo";
import { Operador } from "app/classes/operador";
import { RetornoOperador } from "app/classes/retorno-operador";

@Injectable({
  providedIn: 'root'
})
export class OperadorService extends CRUDService<Operador>{

  public company: number;
  public unidade:number;
  constructor(http: Http, public authGuard: AuthGuard) {
    super(http, environment.baseURL + 'App/v2/web/operador', true);
    var loggedUser: User = authGuard.loggedUser();
    this.company = loggedUser.selectedCompany.id;
    this.unidade = loggedUser.selectedBranch.id;
    var branch: string = loggedUser.selectedBranch.id === 0 && '0' || loggedUser.selectedBranch.id.toString();
    this.listURL = environment.baseURL + "App/v2/web/operador/buscarPelaEmpresa/"+ this.company+"/"+branch;
  }

  getLista() {
    const url: string =
      (this.listURL === undefined && `${this.defaultUrl}`) || `${this.listURL}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoOperador;
        }
      })
      .catch(this.handleError);
  }

  getById(id: number) {
    const url = `${this.defaultUrl}/buscarPeloId/${id}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoOperador;
        }
      })
      .catch(this.handleError);
  }


  cadastrarOperador(operador: Operador): Promise<RESTResponseVO<Operador>> {
    const url = `${this.defaultUrl}/cadastrar`;
    var empresa: Company = new Company();
    empresa.id = this.company;
    operador.empresa = empresa;
    operador.unidadeId = this.unidade;
    return this.enviarRequisicao(operador, url);
  }


  editarOperador(operador: Operador): Promise<RESTResponseVO<Operador>> {
    const url = `${this.defaultUrl}/editar`;
    var empresa: Company = new Company();
    empresa.id = this.company;
    operador.empresa = empresa;
    return this.enviarRequisicao(operador, url);
  }

  enviarRequisicao(operador: Operador, url: string): Promise<RESTResponseVO<Operador>> {
    try {
      return this.http
        .post(url, JSON.stringify(operador), { headers: this.headers })
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            var retorno: RetornoOperador = response.json() as RetornoOperador;
            var ret: Operador = retorno.operador;
            if (ret === null) {
              ret = new Operador();
            }
            ret.id = 0;
            return ret;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }

  protected handleError(error: any): Promise<any> {
    console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
    return Promise.reject(error.message || error);
  }
}
