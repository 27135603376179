import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-horas-operacao-motoristas',
  templateUrl: './horas-operacao-motoristas.component.html',
  styleUrls: ['./horas-operacao-motoristas.component.scss']
})
export class HorasOperacaoMotoristasComponent implements OnInit {

  @Input() modelRetorno: any;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      if (this.modelRetorno) {
        this.criarGrafico();
      }
    }, 100);
  }

  criarGrafico(){
    const labels = [
      'Direção',
      'A disposição',
      'Em espera',
      'Paradas',
      'Refeição',
    ];

    const data = {
      labels: labels,
      datasets: [{
        labels: labels,
        backgroundColor: ['#c08300',
                          '#ffae00',
                          '#00b052',
                          '#99009a',
                          '#0071c4',],
        data: [this.modelRetorno.porcentagemDirecao, this.modelRetorno.porcentagemDisposicao, this.modelRetorno.porcentagemEmEspera, this.modelRetorno.porcentagemParadas, this.modelRetorno.porcentagemRefeicao],
      }]
    };

    const config = {
      type: 'pie',
      data: data,
      options: {
        responsive: false,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function(tooltipItem, data) {
                 var indice = tooltipItem.index;
                 return data.datasets[0].labels[indice] + ": " + data.datasets[0].data[indice] + "%";
             }
          }
      },
      }
    };

    var element = document.getElementById('myChart') as HTMLCanvasElement;
    const myChart = new Chart(element, config);
  }
}
