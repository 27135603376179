import { Component } from "@angular/core";
import { BaseFormComponent } from "../../../base/base-form/base-form.component";
import { ActivatedRoute, Router } from "@angular/router";
import { RESTResponseVO } from "../../../../classes/restresponsevo";
import { AuthGuard } from "../../../../guards/auth.guard";
import { SascarVeiculo } from "app/classes/sascar-veiculo";
import { RetornoSascarVeiculo } from "app/classes/retorno-sascar-veiculo";
import { SascarVeiculoService } from "app/services/crud/sascar-veiculo.service";


@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class SascarVeiculoFormComponent extends BaseFormComponent<SascarVeiculo> {
  constructor(private sascarVeiculoService: SascarVeiculoService,
    private authGuard: AuthGuard,
    route: ActivatedRoute,
    private router: Router) { super(route, router, 'adm/sascarveiculo/list'); }

  assembleObjectFromForm(): void {
  }

  loadAuxiliaryLists(): void {
  }

  loadObject(): void {

    if (this.activeID && (this.activeID !== 0)) {
      this.sascarVeiculoService.getById(this.activeID).then(response => {
        var resultItem: RetornoSascarVeiculo = response;
        this.activeObject = resultItem.sascarVeiculo;
      });
    }else{
      this.router.navigate(['adm/sascarveiculo/list']);
    }
    this.loading = false;
  }

  initObject(): void {
    this.activeObject = new SascarVeiculo();
    this.loading = false;
  }

  doCreate(t: SascarVeiculo): Promise<RESTResponseVO<SascarVeiculo>>  {
     return this.sascarVeiculoService.cadastrarSascarVeiculo(t);
  }

  doEdit(t: SascarVeiculo): Promise<RESTResponseVO<SascarVeiculo>> {
    try {
      return this.sascarVeiculoService.editarSascarVeiculo(t);
    } catch (e) {
      console.error(e);
    }

  }

  doDelete(t: SascarVeiculo): Promise<RESTResponseVO<string>> {
    return null;
  }

  setActiveID(): void {
    try {
      if(this.activeObject == undefined){
        this.activeID = 0;
      }else{
        this.activeID = this.activeObject.id;
      }
    } catch (e) {
      console.log(e);
    }

  }
}
