import { Component, Inject, Input} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DetalheAcionamentoRegra } from 'app/classes/detalhe-acionamento-regra';
import { ResumoDetalheAcionamentoRegra } from 'app/classes/resumo-detalhe-acionamento-regra';
import { DetalhesAcionamentosLocaisComponent } from '../detalhes-acionamentos-locais/detalhes-acionamentos-locais';
import * as XLSX from 'xlsx';

@Component({
  selector: 'detalhes-indicadores-locais',
  templateUrl: './detalhes-indicadores-locais.html',
  styleUrls: ['./detalhes-indicadores-locais.scss']
})
export class DetalhesIndicadoresLocaisComponent {

  @Input()
  resumoPorLocal: ResumoDetalheAcionamentoRegra[] = [];

  @Input()
  detalhesPorLocal: DetalheAcionamentoRegra[] = [];

  resumoPorLocalPaginado: ResumoDetalheAcionamentoRegra[] = [];

  length: number = this.resumoPorLocal.length;
  pageSize: number = 10;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  nomeTabela = "DetalhesIndicadoresLocais.xlsx";

  constructor(public dialogRef: MatDialogRef<DetalhesIndicadoresLocaisComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) {
    this.resumoPorLocal = data.resumoPorLocal;
    this.detalhesPorLocal = data.detalhesPorLocal;

    this.length = this.resumoPorLocal.length;

    this.paginarResultados(0);
  }

  exibirTabela: Boolean = true;

  exportexcel() {
    let element = document.getElementById('detalhes_Indicadores_Locais_Completo');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
 
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Tabela 1');
   
    XLSX.writeFile(wb, this.nomeTabela);
 
  }
  
  exibirDetalheLocal(idLocal: number) {
    var itensFiltrados = this.detalhesPorLocal.filter(x => x.idLocal == idLocal);
    this.dialog.open(DetalhesAcionamentosLocaisComponent, { data: { detalhesPorLocal: itensFiltrados }, panelClass: 'indicadores-dialog' });
  }

  page(event) {
    this.pageSize = event.pageSize;
    this.paginarResultados(event.pageIndex);
  }

  paginarResultados(pageIndex: number) {
    this.resumoPorLocalPaginado = this.resumoPorLocal.slice(pageIndex * this.pageSize, (pageIndex + 1) * this.pageSize);
  }

  close() {
    this.dialogRef.close();
  }
}