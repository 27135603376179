import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DriverService } from 'app/services/crud/driver.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption, MatSelect } from '@angular/material';
import { JourneyService } from 'app/services/crud/journey.service';
import { ReportService } from 'app/services/report/report.service';
import * as XLSX from "xlsx";
import * as FileSaver from 'file-saver';


@Component({
  selector: 'app-hours-totalizer',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class HoursTotalizerComponent implements OnInit {

  isLoading: boolean = false;
  formGroup: FormGroup;
  @ViewChild('dataTable') table;
  dataTable: any;
  dtOption: any = {};
  @ViewChild('selectMotoristas') selectVeiculos: MatSelect;
  motoristas: any = [];
  listaDeJornadas:any;
  public motoristas2: any;
  public filteredList5: any;
  filtro: any = {};
  allSelected: boolean = false;
  errorMessage: any;
  RMLabore = false;
  listRm: string[] = [];
  exibirFooter = false;
  horasJornada: any = {};
  horasContabilizar = ['disposicao', 'direcao', 'cargaDescarga', 'paradas', 'refeicao',
   'totalJornadaMenosRefeicao', 'jornadaPrevista', 'jornadaRealizada'];
   payloadRelatorioPdf = { filtroVO: null, listaRelatorioResumoJornadas: null};
  Heading = [[ '','Motorista', 'Data início jornada', 'À disposição', 'Direção', 
              'Carga/Descarga', 'Paradas', 'Refeição', 'Total jornada deduzindo refeição', 
              'Jornada prevista', 'Realizada - prevista']];
  constructor(
    private formBuilder: FormBuilder,
    private elementRef:ElementRef,
    private driverService: DriverService,
    private journeyService: JourneyService,
    private reportService: ReportService) { }

  ngOnInit() {

    this.resetHours();
    
    this.formGroup = this.formBuilder.group({
      dataInicial: [null, [Validators.required]],
      dataFinal: [null, [Validators.required]],
      motoristas: [null, [Validators.required]],
      situacao: [null, [Validators.required]]
    });
    this.listarMotoristas();

    this.dtOption = {
      "iDisplayLength": 40,
      "bPaginate": false,
      "fixedHeader": { header: true, footer: true},
      "bLengthChange": false,
      "bFilter": false,
      "bInfo": false,
      "bAutoWidth": false,
      "scrollY": 400,
      "deferRender": true,
      "scroller": true,
      "order": [],
      "language": {
        "lengthMenu": "Mostrando _MENU_ registros por pagina",
        "zeroRecords": "Nenhum registro encontrado",
        "search": "Pesquisar",
        "oPaginate": {
          "sNext": "Próximo",
          "sPrevious": "Anterior",
          "sFirst": "Primeiro",
          "sLast": "Último"
        }
      },
      'dom': 'Bfrtip',
      'buttons': [
        {
          text: 'Csv',
          action: () => {
            this.exportCSV();
          }
        },
        {
          text: 'Excel',
          action: () => {
            this.exportExcel();
          }
        },
       {
          text: 'Pdf',
          action: () => {
            this.imprimirPdf();
          }
        }
      ]
    };
    this.dataTable = $(this.table.nativeElement);
    this.dataTable.DataTable(this.dtOption);
  }

  filterMotoristas(filtro: any) {
    return filtro;
  }

  imprimirPdf() {
    this.isLoading = true;
    try {
      if(this.payloadRelatorioPdf && this.payloadRelatorioPdf.filtroVO && 
         this.payloadRelatorioPdf.listaRelatorioResumoJornadas) {

          this.reportService.getReportTotalizadorHoras(this.payloadRelatorioPdf)
          .then(response => {
          this.isLoading = false;
          if(response && response.url) {
            window.open(response.url, "_blank");
          }
          });
      } else {
        this.isLoading = false;
      }
    } catch (error) {
      this.isLoading = false;
    }
  }

  exportCSV() {
    const CSV_TYPE ='data:text/csv;charset=utf-8';
    const CSV_EXTENSION = '.csv';
    const BOM = '\uFEFF';
    const json  = this.formataJsonListaMotorista();

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, this.Heading);

    XLSX.utils.sheet_add_json(ws, json, { origin: 'A2', skipHeader: true });
    const csvOutput: string = XLSX.utils.sheet_to_csv(ws);

    FileSaver.saveAs(new Blob([BOM + csvOutput], { type: CSV_TYPE }), 
    `totalizadorHoras_export.${CSV_EXTENSION}`);
    
  }

  exportExcel() {
    const json  = this.formataJsonListaMotorista();
    const wb = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, this.Heading);

    XLSX.utils.sheet_add_json(ws, json, { origin: 'A2', skipHeader: true });

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'totalizadorHoras.xlsx');
  }

  formataJsonListaMotorista() {
    const itens = [];
    if(this.listaDeJornadas && this.listaDeJornadas.length) {
      this.listaDeJornadas.reduce((prev, curr) => {
          if(curr && curr.listaResumoJornada && curr.listaResumoJornada.length) {
            const resumos = curr.listaResumoJornada.map(c=> {return {
              intro: '',
              motorista: c.motorista,
              inicioDaJornada: c.inicioDaJornada,
              disposicao: c.disposicao,
              direcao: c.direcao,
              cargaDescarga: c.cargaDescarga,
              paradas: c.paradas,
              refeicao: c.refeicao,
              totalJornadaMenosRefeicao: c.totalJornadaMenosRefeicao,
              jornadaPrevista: c.jornadaPrevista,
              jornadaRealizada: c.jornadaRealizada
            }})

            itens.push(...resumos);
          }

          itens.push({
              intro: 'Total',
              motorista: curr.motorista,
              inicioDaJornada: curr.inicioDaJornada,
              disposicao: curr.disposicao,
              direcao: curr.direcao,
              cargaDescarga: curr.cargaDescarga,
              paradas: curr.paradas,
              refeicao: curr.refeicao,
              totalJornadaMenosRefeicao: curr.totalJornadaMenosRefeicao,
              jornadaPrevista: curr.jornadaPrevista,
              jornadaRealizada: curr.jornadaRealizada
          })
          
      },itens)
    }

    this.inserirDadosConsolidados(itens);
    
    return itens;
  }

  listarMotoristas() {
    this.isLoading = true;
    this.driverService.getListMinimo().then(response => {
      const refactoredResult = response.response.map(item => {
        item.nome = `${item.nome} ${item.sobrenome}`
        delete item.sobrenome;
        return item;
      })
      this.isLoading = false;
      this.motoristas = refactoredResult;
    });
  }

  onStatusChange(event, matSelect: MatSelect) {
    
    if (event === "ATIVO") {
      this.motoristas2 = this.motoristas.filter(d => d.situacao);
    } else if(event === "TODOS") {
      this.motoristas2 = this.motoristas;
    } else{
      this.motoristas2 = this.motoristas.filter(d => !d.situacao);

    }
      const filteredList = this.motoristas2.slice()
      this.filteredList5 = [...filteredList].sort((a, b): any => {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      });

      this.allSelected = false;
      matSelect.options.forEach((item: MatOption) => item.deselect());
  }

  selecionarTodos(matSelect: MatSelect) {
    const isSelected: boolean = matSelect.options
      .filter((item: MatOption) => item.value === 0)
      .map((item: MatOption) => item.selected)
    [0];

    if (isSelected) {
      this.allSelected = true;
      matSelect.options.forEach((item: MatOption) => item.select());
    } else {
      this.allSelected = false;
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  doSearch() {
    
    this.exibirFooter = false;
    this.resetHours();

    if (this.allSelected) {
      const oldValue = this.formGroup.value.motoristas;
      this.formGroup.value.motoristas = oldValue.filter((item) => {
        if (item !== 0) {
          return item;
        }
      })
    }
    this.formGroup.get('situacao').markAsTouched();
    this.formGroup.get('motoristas').markAsTouched();
    this.formGroup.get('dataInicial').markAsTouched();
    this.formGroup.get('dataFinal').markAsTouched();

    if (!this.formGroup.value.motoristas) {
      return;
    }

    if (!this.formGroup.value.situacao) {
      return;
    }

    if (!this.formGroup.value.dataInicial || !this.formGroup.value.dataFinal) {
      return;
    }

    this.isLoading = true;

    try {
      const dataInicial = new Date(this.formGroup.value.dataInicial);
      const dataFinal = new Date(this.formGroup.value.dataFinal);

      dataInicial.setHours(0,0,0,0);
      dataFinal.setHours(23,59,59,999);


      this.filtro.dataInicial = dataInicial.toISOString();
      this.filtro.dataFinal = dataFinal.toISOString();

      this.filtro.listaDeMotoristas = this.formGroup.value.motoristas.map((motoristaId) => {
        return {id: motoristaId}
      });

      this.payloadRelatorioPdf = {
        filtroVO: {
          dataInicial: this.formGroup.value.dataInicial,
          dataFinal: this.formGroup.value.dataFinal
        },
        listaRelatorioResumoJornadas: {}
      }

      this.journeyService
        .getTotalizerReport(this.filtro)
        .then(response => {
          if (response.error) {
            this.errorMessage = response.error;
          } else if(response && response.listaRelatorioResumoJornadas && response.listaRelatorioResumoJornadas.length){
            this.listaDeJornadas = response.listaRelatorioResumoJornadas.filter(c=> c.motorista);
            this.payloadRelatorioPdf.listaRelatorioResumoJornadas = this.listaDeJornadas;
            this.addGroupRown(this.listaDeJornadas);
          }
          this.isLoading = false;
        });

    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }
  }

  addGroupRown(informacaoJornada) {
    try {
      this.dataTable.DataTable().clear().draw();
      informacaoJornada.forEach((i1, index) => {
        this.dataTable.DataTable().row.add(
          this.addRow(i1, index)
        ).draw().node();

        if(i1.listaResumoJornada && i1.listaResumoJornada.length) {
          this.addChildrenRows(i1.listaResumoJornada, `totalizer-${index}`)
        }
          
        this.somarHoras(i1)

      });
      this.dataTable.DataTable().draw();
      let elements = this.elementRef.nativeElement.querySelectorAll('.expand-action');
      elements.forEach(elClick => {
        elClick.addEventListener('click', this.expand.bind(this));
      });
      this.exibirFooter = true;
                                
    } catch (error) {
      console.log(error);
    }
  }

  addChildrenRows(elements, cssClass) {
    elements.forEach((i1) => {
      let tr = this.dataTable.DataTable().row.add(
        this.addRow(i1, null)
      ).draw().node();
        tr.classList.add(cssClass)
        tr.style.display = 'none';
    });
  }

addRow(i1, index) {
  return  [
    Number.isInteger(index) ? 
      `<div class="expand-action"><i class="material-icons totalizer-${index}">add</i></div>` : '',
    i1.motorista,
    i1.inicioDaJornada,
    i1.disposicao,
    i1.direcao,
    i1.cargaDescarga,
    i1.paradas,
    i1.refeicao,
    i1.totalJornadaMenosRefeicao,
    i1.jornadaPrevista,
    i1.jornadaRealizada
  ]
}

resetHours() {
  this.horasJornada = {
    disposicao: '00:00',
    direcao: '00:00',
    cargaDescarga: '00:00',
    paradas: '00:00',
    refeicao: '00:00',
    totalJornadaMenosRefeicao: '00:00',
    jornadaPrevista: '00:00',
    jornadaRealizada: '00:00'
  }
}

somarHoras(i1) {
 try {
  this.horasContabilizar.forEach(item => {
    var totalH;
    var totalM;
  
    if(this.horasJornada[item]) {
      
      const horasAtuais = this.horasJornada[item].split(':');
      if(horasAtuais.length > 1) {
        
        totalH = parseInt(horasAtuais[0], 10);
        totalM = parseInt(horasAtuais[1], 10);;
  
        if(i1[item] && i1[item].trim() !='-') {
            const hm = i1[item].split(':');
  
            if(hm.length > 1){
              totalH += parseInt(hm[0], 10);
              totalM += parseInt(hm[1], 10);
              if (totalM >= 60) {
                totalH += Math.floor(totalM / 60);
                totalM = totalM % 60;
              }
              this.horasJornada[item] = `${totalH.toString().padStart(2, '0')}:${totalM.toString().padStart(2, '0')}`;         
            }
        }
      }  
    }else {
      if(i1[item] && i1[item].trim() !='-') {
        this.horasJornada[item] = i1[item]
      }
    }
   });
 } catch (error) {
    return;
 }
}

inserirDadosConsolidados(itens) {
  itens.push({
    intro: 'Total geral',
    motorista: '',
    inicioDaJornada: '',
    disposicao: this.horasJornada.disposicao,
    direcao: this.horasJornada.direcao,
    cargaDescarga: this.horasJornada.cargaDescarga,
    paradas: this.horasJornada.paradas,
    refeicao: this.horasJornada.refeicao,
    totalJornadaMenosRefeicao: this.horasJornada.totalJornadaMenosRefeicao,
    jornadaPrevista: this.horasJornada.jornadaPrevista,
    jornadaRealizada: this.horasJornada.jornadaRealizada
})
}
  
expand(event) {
    let elements = [];
    let display = 'table-row'

    if(event && event.target && event.target.textContent)  {
      const totalizerClass = event.target.classList.value.split(' ')
        .filter(c=> c.indexOf('totalizer') > -1);

      if(totalizerClass && totalizerClass.length) {
        elements = this.elementRef.nativeElement.querySelectorAll(`tr.${totalizerClass[0]}`);
      }

      if(event.target.textContent == "add") {
        event.target.textContent = "minimize";
      } else {
        event.target.textContent = "add"
        display = 'none';
      }

      elements.forEach(elClick => {
        elClick.style.display = display;
      });
    }
  }
}