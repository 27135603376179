import { BaseFormComponent } from '../../../base/base-form/base-form.component';
import { Driver } from '../../../../classes/driver';
import { Component, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { DriverService } from '../../../../services/crud/driver.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RESTResponseVO } from '../../../../classes/restresponsevo';
import { Address } from '../../../../classes/address';
import { Branch } from '../../../../classes/branch';
import { Observable } from 'rxjs/Observable';
import { BranchService } from '../../../../services/crud/branch.service';
import { UserService } from '../../../../services/crud/user.service';
import { User } from '../../../../classes/user';
import { ShiftService } from '../../../../services/crud/shift.service';
import { Shift } from '../../../../classes/shift';
import { VehicleService } from '../../../../services/crud/vehicle.service';
import { Veiculo } from '../../../../classes/veiculo';
import { MotoristasVeiculos } from '../../../../classes/motoristas-veiculos';
import { Vacation } from '../../../../classes/vacation';
import { VacationService } from '../../../../services/crud/vacation.service';
import { AuthGuard } from '../../../../guards/auth.guard';
import { DriverStatus } from '../../../../classes/driver-status';
import { Motive } from '../../../../classes/motive';
import { MotiveService } from '../../../../services/crud/motive.service';
import { FormGroup } from '@angular/forms';
import { Macros } from "app/classes/macros";
import { RetornoMacros } from "app/classes/retorno-macros";
import { MacrosService } from "../../../../services/crud/macros.service";
import { TipoFuncionario } from 'app/classes/tipo-funcionario';
import { TipoFuncionarioService } from 'app/services/crud/tipo-funcionario.service';
import { TurnoEscalaService } from 'app/services/crud/turno-escala.service';
import { TurnoEscala } from 'app/classes/turno-escala';
import { TurnoEscalaMotorista } from 'app/classes/turno-escala-motorista';


@Component({
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class DriverFormComponent extends BaseFormComponent<Driver>
  implements AfterViewChecked {
  private listaDeMacros: Macros[];
  private addDriverForm: FormGroup = new FormGroup({});
  podeInserirNumeroChapa: boolean = false;
  canloadObject: boolean;
  editingVehicle: boolean[] = new Array();
  editingTurnoEscala: boolean[] = new Array();
  editingData: boolean[] = new Array();
  newVehicle: Object;
  newTurnoEscala: Object;
  novaFerias: Object;
  doingDisable: boolean;
  driverStatus: DriverStatus;
  retornoVeiculos: any;
  habilitarBotaoGerarMatricula: boolean = true;
  listaTurnoEscalaDoMotorista = [];
  selectedTipo: any;
  // Branch
  branchList: Branch[];
  // Shift
  shiftList: Shift[];
  // Tipo
  tipoFuncionarioList: TipoFuncionario[];
  // Vehicle
  listaVeiculos: Veiculo[];
  // Turno Escala
  listaDeTurnoEscala: TurnoEscala[];
  motiveList: Motive[];
  showTurno: boolean = true;

  constructor(
    private service: DriverService,
    private macrosService: MacrosService,
    private branchService: BranchService,
    private userService: UserService,
    private shiftService: ShiftService,
    private tipoFuncionarioService: TipoFuncionarioService,
    private vehicleService: VehicleService,
    private vacationService: VacationService,
    private authGuard: AuthGuard,
    private motiveService: MotiveService,
    private cdRef: ChangeDetectorRef,
    private turnoEscalaService: TurnoEscalaService,
    route: ActivatedRoute,
    router: Router
  ) {
    super(route, router, 'adm/driver/list');
    this.canloadObject = false;
    this.editingVehicle[-1] = false;
    this.editingTurnoEscala[-1] = false;
    this.buscarListaMacros();
    this.podeInserirNumeroChapa = authGuard.loggedUser().selectedCompany.habilitarMatriculaChapa;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  assembleObjectFromForm(): void {
    if (this.activeObject.unidade) {
      this.activeObject.unidadesId = this.activeObject.unidade.id;
      delete this.activeObject.unidade;
    }
    if (this.activeObject.turno) {
      this.activeObject.turnosId = this.activeObject.turno;
      delete this.activeObject.turno;
    }
    if (this.activeObject.user) {
      this.activeObject.userId = this.activeObject.user.id;
    }
  }

  loadObject(): void {
    if (this.canloadObject) {
      if (this.activeID && this.activeID !== 0) {
        this.loadVehiclesList();
        this.loadTurnoEscalaMotorista();
      } else {
        this.loading = false;
      }
      this.buscarListaMacros();
    }
  }

  loadAuxiliaryLists(): void {
    this.branchService.getList().then(r1 => {
      this.branchList = r1.response;
      this.shiftService.getList().then(r3 => {
        this.shiftList = r3.response;
        this.motiveService.getList().then(r4 => {
          this.motiveList = r4.response;
          this.turnoEscalaService.getBuscarPorParametros().then(response => {
            let retornoTurnoEscala = response;
            if (retornoTurnoEscala.codigoRetorno == 1) {
              this.listaDeTurnoEscala = retornoTurnoEscala.listaTurnoEscalas;
              this.canloadObject = true;
              this.loadObject();
            }
            this.tipoFuncionarioService.getAll(this.authGuard.loggedUser().selectedCompany.id, this.authGuard.loggedUser().selectedBranch.id).then(response2 => {
              this.tipoFuncionarioList = response2;
            });
          });
        });
      });
    });
  }

  initObject(): void {
    this.activeObject = new Driver();
    this.driverStatus = new DriverStatus();
    this.driverStatus.motive = new Motive();
    this.activeObject.enderecosId = new Address();
    this.loading = false;
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = e => {
        this.activeObject.imagemId = {
          id: undefined,
          dados: reader.result
        };
      };
    }
  }

  clearTmpFilds(t: Driver) {
    delete t.unidade;
    delete t.tipoFuncionarioId;
    delete t.tipo;
    delete t.turno;
    delete t.user;
  }

  doCreate(t: Driver): Promise<RESTResponseVO<Driver>> {
    const loggedUser = this.authGuard.loggedUser();

    // this.clearTmpFilds(t);
    const sascarString = t.sascarId;
    t.sascarId = Number(sascarString)
    t.situacao = 1;
    t.empresaId = loggedUser.selectedCompany.id;
    t.user = null;
    return this.service.create(t);
  }

  doEdit(t: Driver): Promise<RESTResponseVO<Driver>> {
    const loggedUser = this.authGuard.loggedUser();

    this.clearTmpFilds(t);
    const sascarString = t.sascarId;
    t.sascarId = Number(sascarString)
    t.empresaId = loggedUser.selectedCompany.id;
    console.log('t:  ' + t)
    return this.service.update(t);
  }

  doDelete(t: Driver): Promise<RESTResponseVO<string>> {
    return this.service.delete(t.id);
  }

  loadVehiclesList() {
    this.editingVehicle[-1] = false;
    this.vehicleService.getList().then(response => {
      this.retornoVeiculos = response;
      if (this.retornoVeiculos.codigoRetorno == 61) {
        this.listaVeiculos = this.retornoVeiculos.listaDeVeiculos;
      }
      this.carregarListaDeVeiculos();
    });
  }

  loadTurnoEscalaMotorista(){
    this.editingTurnoEscala[-1] = false;
    this.turnoEscalaService.buscarTurnoEscalaMotorista(this.activeID).then(response => {
      let retornoTurnoEscalaMotorista = response;
      if (retornoTurnoEscalaMotorista.codigoRetorno == 1) {
        this.listaTurnoEscalaDoMotorista = retornoTurnoEscalaMotorista.listaTurnoEscalaMotorista;
      }
    });
  }

  onChange() {
    if (!this.activeObject.unidade.usarTurnoQuatroDois || this.activeObject.unidade.usarTurnoQuatroDois === null || this.activeObject.unidade.usarTurnoQuatroDois === undefined) {
      this.showTurno = true;
    } else if (this.activeObject.unidade.usarTurnoQuatroDois) {
      this.showTurno = false;
      this.activeObject.turno = null;
    }
  }

  carregarListaDeVeiculos() {
    try {
      this.service.getById(this.activeID).then(async (rf) => {

        const resultItem: Driver = rf.response;
        if (rf.response.tipoFuncionario) {
          const tipoFuncionario = await this.tipoFuncionarioService.getById(rf.response.tipoFuncionario.id);
          resultItem.tipoFuncionario = tipoFuncionario;
        }
        resultItem.veiculos;

        if(resultItem.veiculos !== null && resultItem.veiculos !== undefined){
          for (let ii = 0; ii < resultItem.veiculos.length; ii++) {
            let vt = this.listaVeiculos.find(i => i.id === resultItem.veiculos[ii].veiculoOrion);
            if (vt) {
              resultItem.veiculos[ii].veiculo = vt;
            } else {
              let veiculoTemp = new Veiculo();
              veiculoTemp.placa = "NAO ENCONTRADO:" + resultItem.veiculos[ii].veiculoOrion;

              resultItem.veiculos[ii].veiculo = veiculoTemp;
            }
          }
        }


        this.driverStatus = new DriverStatus();

        resultItem.turno = resultItem.turnosId
          ? this.shiftList.filter(s => s.id === resultItem.turnosId)[0]
          : undefined;
        resultItem.unidade = resultItem.unidadesId
          ? this.branchList.filter(b => b.id === resultItem.unidadesId)[0]
          : undefined;

        this.activeObject = resultItem;
        this.loading = false;

        for (
          let i = 0, length = this.listaVeiculos.length;
          i < length;
          i++
        ) {
          if (this.activeObject.veiculos[i]) {
            this.editingVehicle[this.activeObject.veiculos[i].id] = false;
            this.activeObject.veiculos[i].dataInicio = this.addDateOffset(
              this.activeObject.veiculos[i].dataInicio
            );
          }
        }

        this.editingData[-1] = false;
        let total = (this.activeObject.ferias.length - 1);
        for (
          let i = 0, length = this.activeObject.ferias.length;
          i < length;
          i++
        ) {
          if (i >= total) {
            this.editingData[this.activeObject.ferias[i].id] = true;
          } else {
            this.editingData[this.activeObject.ferias[i].id] = false;
          }
          this.activeObject.ferias[i].dataInicio = this.addDateOffset(
            this.activeObject.ferias[i].dataInicio
          );
          this.activeObject.ferias[i].dataFinal = this.addDateOffset(
            this.activeObject.ferias[i].dataFinal
          );
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  branchFormatter = (x: Branch) => x.apelido;

  searchBranch = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map(term =>
        term.length < 2
          ? []
          : this.branchList
            .filter(
              v => v.apelido.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
            .slice(0, 10)
      );

  branchTemplate(r) {
    return r.apelido;
  }

  shiftFormatter = (x: Shift) => x.identificacao;

  searchShift = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map(term =>
        term.length < 2
          ? []
          : this.shiftList
            .filter(
              v =>
                v.identificacao.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
            .slice(0, 10)
      );

  shiftTemplate(r) {
    return r.identificacao;
  }

  toggleVehicleInsert() {
    this.editingVehicle[-1] = !this.editingVehicle[-1];
    this.newVehicle = new Object();
  }

  toggleTurnoEscalaInsert() {
    this.editingTurnoEscala[-1] = !this.editingTurnoEscala[-1];
    this.newTurnoEscala = new Object();
  }

  toggleVehicleUpdate(t: MotoristasVeiculos) {
    if (t) {
      this.editingVehicle[t.id] = !this.editingVehicle[t.id];
    }
  }

  saveVehicle(t: MotoristasVeiculos) {
    this.loading = true;
    t.motoristasId = this.activeObject.id;

    this.service.addVehicle(t).then(response => {
      this.loading = false;
      this.errorMessage = response.error;

      if (!this.errorMessage) {
        this.loadObject();
      } else {
        this.errorMessage = response.error;
      }
    });
  }

  saveTurnoEscala(t: TurnoEscala) {
    this.loading = true;
    var turnoEscalaMotorista = new TurnoEscalaMotorista();
    turnoEscalaMotorista.ativo = true;
    var motoraistaTemp = new Driver();
    motoraistaTemp.id = this.activeObject.id;
    turnoEscalaMotorista.motoristasId = motoraistaTemp;
    var turnoEscalaTemp = new TurnoEscala();
    turnoEscalaTemp.id = Number(t.id);
    turnoEscalaMotorista.turnoEscalaId = turnoEscalaTemp;

    this.turnoEscalaService.salvarTurnoEscalaMotorista(turnoEscalaMotorista).then(response => {
      this.loading = false;
      this.errorMessage = response.error;
      if (!this.errorMessage) {
        this.loadObject();
      } else {
        this.errorMessage = response.error;
      }
    });
  }


  updateVehicle(t: MotoristasVeiculos) {
    this.loading = true;
    t.motoristasId = this.activeObject.id;

    this.service.editVehicle(t).then(response => {
      this.loading = false;
      this.errorMessage = response.error;

      if (!this.errorMessage) {
        this.loadObject();
      } else {
        this.errorMessage = response.error;
      }
    });
  }

  removeVehicle(t: MotoristasVeiculos) {
    this.loading = true;
    t.motoristasId = this.activeObject.id;
    this.service.removeVehicle(t).then(response => {
      this.loading = false;
      this.errorMessage = response.error;

      if (!this.errorMessage) {
        this.loadObject();
      } else {
        this.errorMessage = response.error;
      }
    });
  }

  // Vacation

  toggleInsert() {
    this.editingData[-1] = !this.editingData[-1];
    this.novaFerias = new Object();
  }

  toggleUpdate(t: Vacation) {
    if (t) {
      this.editingData[t.id] = !this.editingData[t.id];
    }
  }

  saveVacation(t: Vacation) {
    this.loading = true;
    t.motoristaId = this.activeObject.id;

    t.dataInicio = this.addDateOffset(t.dataInicio);
    t.dataFinal = this.addDateOffset(t.dataFinal);

    this.vacationService.create(t).then(response => {
      this.loading = false;
      this.errorMessage = response.error;

      if (!this.errorMessage) {
        this.loadObject();
      } else {
        this.errorMessage = response.error;
      }
    });
  }

  updateVacation(t: Vacation) {
    this.loading = true;
    t.motoristaId = this.activeObject.id;

    t.dataInicio = this.addDateOffset(t.dataInicio);
    t.dataFinal = this.addDateOffset(t.dataFinal);

    this.vacationService.update(t).then(response => {
      this.loading = false;
      this.errorMessage = response.error;

      if (!this.errorMessage) {
        this.loadObject();
      } else {
        this.errorMessage = response.error;
      }
    });
  }

  removeVacation(t: Vacation) {
    this.loading = true;
    t.motoristaId = this.activeObject.id;
    this.vacationService.delete(t.id).then(response => {
      this.loading = false;
      this.errorMessage = response.error;

      if (!this.errorMessage) {
        this.loadObject();
      } else {
        this.errorMessage = response.error;
      }
    });
  }

  addDateOffset(dbDate) {
    if (!dbDate) {
      return dbDate;
    }

    const date = new Date(dbDate);
    const offset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + offset);
  }

  setActiveID(): void {
    this.activeID = this.activeObject.id;
  }

  doDisable() {
    this.doingDisable = !this.doingDisable;
  }

  saveMotive(t: Motive) {
    this.loading = true;
    this.errorMessage = undefined;

    if (this.driverStatus.motive.id) {
      const loggedUser: User = this.authGuard.loggedUser();
      this.driverStatus.empresaId = loggedUser.selectedCompany.id;
      this.driverStatus.instanteLancamento = new Date();
      this.driverStatus.motivo = this.driverStatus.motive.descricao;
      this.driverStatus.motorista = this.activeID;
      this.driverStatus.operadorLancamento = loggedUser.id;

      this.driverStatus.situacaoAnterior = this.activeObject.situacao;

      delete this.driverStatus.motive;

      if (this.activeObject.situacao === 0) {
        this.activeObject.situacao = 1;
      } else {
        this.activeObject.situacao = 0;
      }

      this.driverStatus.situacaoInserida = this.activeObject.situacao;

      this.service.createMotive(this.driverStatus).then(response => {
        this.errorMessage = response.error;
        if (!this.errorMessage) {
          this.loadObject();
          this.doDisable();
        }
        this.loading = false;
      });
    } else {
      this.errorMessage = 'Motivo não selecionado !';
    }
  }

  buscarListaMacros() {
    try {
      var ret: Promise<any> = this.macrosService.getLista();
      ret.then(res => {
        let retornoMacros: RetornoMacros = res;
        this.listaDeMacros = retornoMacros.listaDeMacros;
      });
    } catch (e) {
      console.error(e);
    }
  }

  parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    }
    return null;
  }
}
