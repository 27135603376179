import { CRUDService } from "../base/crud.service";
import { Http } from "@angular/http";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { AuthGuard } from "../../guards/auth.guard";
import { RESTResponseVO } from "app/classes/restresponsevo";
import { User } from '../../classes/user';
import { EquipamentoPortatil } from "app/classes/equipamento-portatil";
import { RetornoEquipamentoPortatil } from "app/classes/retorno-equipamento-portatil";


@Injectable({
  providedIn: 'root'
})
export class EquipamentoPortatilService extends CRUDService<EquipamentoPortatil> {

  public company: number;
  public unidade:number;

  constructor(http: Http, public authGuard: AuthGuard) {
    super(http, environment.baseURL + 'App/v2/web/equipamentoportatil', true);
   
    var loggedUser: User = authGuard.loggedUser();
    this.company = loggedUser.selectedCompany.id;
    this.unidade = loggedUser.selectedBranch.id;
    this.listURL = environment.baseURL + "App/v2/web/equipamentoportatil/listar/"+this.company+"/"+this.unidade;
  } 


  getLista() {
    const url: string =
      (this.listURL === undefined && `${this.defaultUrl}`) || `${this.listURL}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoEquipamentoPortatil;
        }
      })
      .catch(this.handleError);
  }

  getById(id: number) {
    const url = `${this.defaultUrl}/buscar/${id}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoEquipamentoPortatil;
        }
      })
      .catch(this.handleError);
  }

  cadastrarEquipamentoPortatil(equipamento: EquipamentoPortatil): Promise<RESTResponseVO<EquipamentoPortatil>> {
    const url = `${this.defaultUrl}/cadastrar`;
    equipamento.cliente = this.company;
    equipamento.unidade = this.unidade;
    return this.enviarRequisicao(equipamento, url);
  }

  editarEquipamento(equipamento: EquipamentoPortatil): Promise<RESTResponseVO<EquipamentoPortatil>> {
    const url = `${this.defaultUrl}/editar`;
    equipamento.cliente = this.company;
    equipamento.unidade = this.unidade;
    return this.enviarRequisicao(equipamento, url);
  }

  enviarRequisicao(equipamento: EquipamentoPortatil, url: string): Promise<RESTResponseVO<EquipamentoPortatil>> {
    try {
      return this.http
        .post(url, JSON.stringify(equipamento), { headers: this.headers })
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            var retorno: RetornoEquipamentoPortatil = response.json() as RetornoEquipamentoPortatil;
            var equipamento: EquipamentoPortatil = retorno.equipamentoPortatil;
            if (equipamento === null) {
              equipamento = new EquipamentoPortatil();
            }
            equipamento.id = 0;
            return equipamento;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }

}
