import { BaseFormComponent } from "../../../base/base-form/base-form.component";
import { JourneySummary } from "../../../../classes/journey-summary";
import { RESTResponseVO } from "../../../../classes/restresponsevo";
import { OnInit, Component, ViewChild } from "@angular/core";
import { JourneyService } from "../../../../services/crud/journey.service";
import { Journey } from "../../../../classes/journey";
import { Event } from "../../../../classes/event";
import { AuthGuard } from "../../../../guards/auth.guard";
import { Router } from "@angular/router";
import { Driver } from "../../../../classes/driver";
import { BaseListComponent } from "../../../base/base-list/base-list.component";
import { PDFService } from "../../../../services/pdf.service";
import { UtilService } from "../../../../services/utils.service";
import { JourneyEventsService } from "../../../../services/crud/journey-events.service";
import { JourneyEvent } from "../../../../classes/journey-event";
import { EventsService } from "../../../../services/crud/events.service";
import { AllowanceReason } from "../../../../classes/allowance-reason";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { HoldingPoint } from "../../../../classes/holding-point";

@Component({
    templateUrl: "./form.component.html",
    styleUrls: ["./form.component.css"]
})
export class JourneyFormComponent extends BaseListComponent implements OnInit {
    public activeObject: { summary: JourneySummary, period: string, data: JourneySummary[], searchObject: any, journey?: Journey, returnURL: string };

    public confirmFillJourney: boolean;
    public confirmFillJourneyLunch: boolean;

    public showInsert: boolean;
    public eventTypeList: JourneyEvent[];
    public tiposEventos: JourneyEvent[];
    public newEvent: any;

    public showAllowance: boolean;
    public allowanceList: AllowanceReason[];
    public allowanceEvent: any;

    public selectedEvent: any;
    public pairedEvent: any;

    public showEdit: boolean;

    private temInicioDeJornada:boolean = false;

    public resumoTipo: { type: string, total: number, color: string }[];

    public tooltips = {
        'lunchAllowance': 'Abonar almoço',
        'missingAllowance': 'Abonar horas faltantes',
        'addAllowance': 'Adicionar abono',
        'location': 'Exibir no mapa',
        'edit': 'Editar evento',
        'remove': 'Remover evento',
        'newEvent': 'Adicionar evento'
    };

    private eventosSelecionados: any[] = [];
    private erroMover: boolean = false;
    private mover: boolean = false;
    private msgErrosMover: string[] = [];
    modalReference: NgbModalRef;

    @ViewChild('dialogContent') dialogContent;
    @ViewChild('dialogContentMoverEventos') dialogContentMoverEventos;

    constructor(private journeyService: JourneyService,
        private journeyEventService: JourneyEventsService,
        private eventService: EventsService,
        private modalService: NgbModal,
        private authGuard: AuthGuard,
        private router: Router,
        pdfService: PDFService,
        private utils: UtilService) {
        super(utils, pdfService, authGuard);
        this.searchField = "";
    }

    ngOnInit(): void {
        this.loading = true;
        this.confirmFillJourney = false;
        this.confirmFillJourneyLunch = false;
        this.showInsert = false;
        this.showAllowance = false;
        this.showEdit = false;

        this.loadAuxiliaryLists();
        this.loadObject();
    }

    assembleObjectFromForm(): void {
    }

    loadAuxiliaryLists(): void {
        this.carregarTiposEventosJornada();
        this.journeyEventService.getAllowances().then(response => {
            this.allowanceList = response.response;
        });
    }

    loadObject(): void {
        this.showEdit = false;
        this.selectedEvent = undefined;
        
        if (localStorage.getItem('journey')) {
            this.activeObject = JSON.parse(localStorage.getItem('journey')) as { summary: JourneySummary, period: string, data: JourneySummary[], searchObject: any, returnURL: string };
            this.journeyService.getById(this.activeObject.summary.id).then(response => {
                this.errorMessage = response.error;
                if (!this.errorMessage) {
                    this.activeObject.journey = response.response;
                    
                    if (!this.activeObject.journey.locked) {
                        this.activeObject.journey.locked = false;
                    }

                    this.activeObject.journey.eventos.forEach(ev => {
                        if (!ev.localEventoEspera) {
                            ev.localEventoEspera = new HoldingPoint();
                            if (ev.longitude && ev.latitude) {
                                ev.localEventoEspera.identificacao = "Não cadastrado (" + ev.latitude + ", " + ev.longitude + ")";
                            } else {
                                ev.localEventoEspera.identificacao = "Não informado";
                            }
                        }
                    });

                    this.resumoTipo = new Array();

                    for (var estado in this.activeObject.journey.resumoTiposEstados) {
                        this.resumoTipo.push({
                            type: estado,
                            total: this.activeObject.journey.resumoTiposEstados[estado].somatorio,
                            color: this.activeObject.journey.resumoTiposEstados[estado].color
                        });
                    }

                    this.tableHeaders = [
                        [
                            this.createHeader("Data", 0, true),
                            this.createHeader("Evento", 1, true),
                            this.createHeader("Veículo", 2, true),
                            this.createHeader("Local", 3, true),
                            this.createHeader("Data GPS", 4, true),
                            this.createHeader("Metragem aproximada", 5, true)
                        ]
                    ];

                    this.dataFields = [
                        this.createField("instanteEvento", "datetime"),
                        this.createField("tipoEvento.descricao", "string", undefined, "tipoEvento.motivoAbono.descricao", " - "),
                        this.createField("veiculoMotor.placa", "string"),
                        this.createField("localEventoEspera.identificacao", "location"),
                        this.createField("gpsDataHora", "dateFromTime"),
                        this.createField("gpsAccuracy", "distance"),
                    ];
                }
                this.sortType = "instanteEvento";
                this.loading = false;
            });
        } else {
            this.activeObject = undefined;
            this.errorMessage = "Nenhuma jornada encontrada!";
        }
        this.loading = false;
    }

    returnToSearch() {
        if (this.activeObject.searchObject) {
            this.router.navigate(['adm/journey/list'], { skipLocationChange: true });
        } else if (this.activeObject.returnURL) {
            this.router.navigate([this.activeObject.returnURL], { skipLocationChange: true });
        } else {
            this.router.navigate(['adm/main'], { skipLocationChange: true });
        }
    }

    toggleFillJourney() {
        this.confirmFillJourney = !this.confirmFillJourney;
        this.confirmFillJourneyLunch = false;
        this.showAllowance = false;
        this.showInsert = false;
        this.showEdit = false;
    }

    toggleFillJourneyLunch() {
        this.confirmFillJourneyLunch = !this.confirmFillJourneyLunch;
        this.confirmFillJourney = false;
        this.showAllowance = false;
        this.showInsert = false;
        this.showEdit = false;
    }

    fillMissingHours() {
        this.requestWithRefresh(this.journeyService.fillJourney(this.activeObject.journey.id, this.authGuard.loggedUser().id));
    }

    fillMissingHoursLunch() {
        this.requestWithRefresh(this.journeyService.lunchAllowance(this.activeObject.journey.id, this.authGuard.loggedUser().id));
    }

    requestWithRefresh(promise) {
        this.confirmFillJourney = false;
        this.confirmFillJourneyLunch = false;
        this.showAllowance = false;
        this.showInsert = false;
        this.showEdit = false;

        this.errorMessage = undefined;
        this.loading = true;

        promise.then(response => {
            this.errorMessage = response.error;
            if (!this.errorMessage) {
                this.loadObject();
            }
        }).catch(reason => {
            this.errorMessage = reason;
        });
    }

    dateChange(date: number) {
        this.errorMessage = undefined;
        this.loading = true;

        if (!date) {
            this.loading = false;
            this.errorMessage = "Data inválida!";
            return;
        }
        
        this.journeyEventService.findByDateJourney(this.activeObject.journey.id, date).then(response => {
            this.errorMessage = response.error;
            if (!this.errorMessage) {
                this.eventTypeList = response.response;
            }
            this.loading = false;
        }).catch(reason => {
            this.errorMessage = reason;
            this.loading = false;
        });
    }

    createMockAllowanceEvent(code: number) {
        var loggedUser = this.authGuard.loggedUser();
        return {
            tipoEvento: code,
            instanteEvento: new Date(code > 0 ? this.allowanceEvent.inicio : this.allowanceEvent.fim),
            justificativa: this.allowanceEvent.tipo.descricao,
            empresaId: loggedUser.selectedCompany.id,
            jornadaId: this.allowanceEvent.jornadaId,
            instanteLancamento: Date.now(),
            operadorLancamento: loggedUser.id,
            motivoAbono: this.allowanceEvent.tipo
        };
    }

    saveAllowance() {
        this.errorMessage = undefined;
        this.loading = true;

        this.allowanceEvent.jornadaId = this.activeObject.journey.id;

        let startCode = 17;
        let endCode = -17;

        if (this.allowanceEvent.tipo.id === 15) {
            startCode = 21;
            endCode = -21;
        }

        let startEvent = this.createMockAllowanceEvent(startCode);
        let endEvent = this.createMockAllowanceEvent(endCode);

        if (this.activeObject.journey.locked && !this.authGuard.isApuracao()) {
            this.errorMessage = "Jornada bloqueada e usuário não possui permissão para efetuar alteração!";
        } else {
            if (this.isValidEvent(startEvent) && this.isValidEvent(endEvent)) {
                var indata = { eventoInicio: startEvent, eventoFinal: endEvent };
                this.requestWithRefresh(this.eventService.createChained(indata, this.authGuard.loggedUser().id));
            } else {
                this.errorMessage = "Abono fora do período permitido [" + this.activeObject.period + "]";
                this.loading = false;
            }
        }
    }

    saveChained() {
        this.errorMessage = undefined;
        this.loading = true;

        let startCode = this.newEvent.tipoEvento.id;
        let endCode = this.newEvent.tipoEvento.id * -1;

        if (startCode === 1) {
            endCode = 0;
        }

        let startEvent = this.createMockEvent(startCode);
        let endEvent = this.createMockEvent(endCode);

        if (this.activeObject.journey.locked && !this.authGuard.isApuracao()) {
            this.errorMessage = "Jornada bloqueada e usuário não possui permissão para efetuar alteração!";
        } else {
            if (this.isValidEvent(startEvent) && this.isValidEvent(endEvent)) {
                var indata = { eventoInicio: startEvent, eventoFinal: endEvent };
                this.requestWithRefresh(this.eventService.createChained(indata, this.authGuard.loggedUser().id));
            } else {
                this.errorMessage = "Evento fora do período permitido [" + this.activeObject.period + "]";
                this.loading = false;
            }
        }
    }

    isValidEvent(event) {
        if (!this.activeObject.searchObject) {
            return true;
        }

        let start;
        let end;
        if (this.activeObject.searchObject.month) {
            start = new Date(this.activeObject.searchObject.month.start);
            end = new Date(this.activeObject.searchObject.month.end);
        } else {
            start = new Date(this.activeObject.searchObject.manualStart);
            end = new Date(this.activeObject.searchObject.manualEnd);
        }
        
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);

        let eventDate = event.instanteEvento;
        if (typeof eventDate !== "object") {
            eventDate = new Date(event.instanteEvento);
        }

        return eventDate >= start && eventDate <= end;
    }

    createMockEvent(code) {
        let loggedUser = this.authGuard.loggedUser();
        return {
            tipoEvento: code,
            instanteEvento: new Date(code > 0 ? this.newEvent.inicio : this.newEvent.fim),
            justificativa: this.newEvent.justificativa,
            empresaId: loggedUser.selectedCompany.id,
            jornadaId: this.activeObject.journey.id,
            instanteLancamento: Date.now(),
            operadorLancamento: loggedUser.id
        }
    }

    toggleAddAllowance() {
        this.showAllowance = !this.showAllowance;
        this.showInsert = false;
        this.confirmFillJourney = false;
        this.confirmFillJourneyLunch = false;
        this.showEdit = false;

        this.allowanceEvent = {};
    }

    toggleAddEvent() {
        this.showInsert = !this.showInsert;
        this.showAllowance = false;
        this.confirmFillJourney = false;
        this.confirmFillJourneyLunch = false;
        this.showEdit = false;
        this.newEvent = {};

        if (this.showInsert) {
            let start;
            if (typeof this.activeObject.journey.jornadaInicio === "number") {
                start = this.activeObject.journey.jornadaInicio;
            } else {
                start = this.activeObject.journey.jornadaInicio.getTime();
            }

            this.dateChange(start + 1);
        }
    }

    hidesEdit(row) {
        let lockedEvents = [-20, 20];

        let index = lockedEvents.indexOf(row.tipoEvento.id);
        return index >= 0 || !row.id;
    }

    hidesRemove(isFirst, row) {
        if(row.tipoEvento.id == 1){
            this.temInicioDeJornada = true; 
        }
        if (!row.id) {
            return true;
        } else if (this.activeObject.journey.eventos.length === 2) {
            return false;
        } else if (row.tipoEvento.id !== 0) {
            return isFirst;
        }else if (row.tipoEvento.id == 0 && !this.temInicioDeJornada) {
            return false;
        }

        return true;
    }

    findPaired(row) {
        let pairID;
        if (row.tipoEvento.id > 0) {
            pairID = row.fimEventoId;
        } else {
            pairID = row.aberturaId;
        }

        let results = this.activeObject.journey.eventos.filter(e => e.id === pairID);
        return results.length > 0 ? results[0] : undefined;;
    }

    saveEvent() {
        if (!this.selectedEvent.justificativa) {
            this.errorMessage = "Campo justificativa é obrigatório!";
        } else if (!this.isValidEvent(this.selectedEvent)) {
            this.errorMessage = "Evento fora do período permitido [" + this.activeObject.period + "]";
        } else {
            let loggedUser = this.authGuard.loggedUser();

            this.loading = true;
            this.errorMessage = undefined;

            this.selectedEvent.empresaId = loggedUser.selectedCompany.id;
            this.selectedEvent.jornadaId = this.activeObject.journey.id;
            this.selectedEvent.instanteLancamento = Date.now();
            this.selectedEvent.operadorLancamento = loggedUser.id;
            this.selectedEvent.instanteEvento = new Date(this.selectedEvent.instanteEvento);

            delete this.selectedEvent.jornada;
            delete this.selectedEvent.eventoAnteriorId;
            delete this.selectedEvent.eventoSeguinte;

            this.eventService.update(this.selectedEvent.id, this.selectedEvent, this.authGuard.loggedUser().id).then(response => {
                this.errorMessage = response.error;
                if (!this.errorMessage) {
                    this.loadObject();
                } else {
                    this.selectedEvent.instanteEvento = this.utils.formatDatetoDatetimeLocalInput(this.selectedEvent.instanteEvento);
                    this.loading = false;
                }
            })
        }

    }

    toggleEdit(row) {
        if (row) {
            this.selectedEvent = Object.assign({}, row);
            this.selectedEvent.instanteEvento = this.utils.formatDatetoDatetimeLocalInput(new Date(this.selectedEvent.instanteEvento));
        } else {
            this.selectedEvent = undefined;
        }

        this.showEdit = !this.showEdit;
        this.showAllowance = false;
        this.showInsert = false;
        this.confirmFillJourney = false;
        this.confirmFillJourneyLunch = false;
    }

    confirmEventRemoval(row) {
        this.selectedEvent = row;
        this.pairedEvent = this.findPaired(row);

        this.modalService.open(this.dialogContent, { centered: true }).result.then(result => {
            if (!this.selectedEvent.justificativa) {
                this.errorMessage = "Campo JUSTIFICATIVA é obrigatório!";
            } else {
                
                this.selectedEvent.removido = 1;
                this.selectedEvent.operadorAlteracao = this.authGuard.loggedUser().id;
                this.loading = true;

                this.eventService.markAsRemoved(this.selectedEvent.id, this.selectedEvent, this.authGuard.loggedUser().id).then(response => {
                    this.errorMessage = response.error;
                    if (!this.errorMessage) {
                        this.loadObject();
                    } else {
                        this.loading = false;
                    }
                }).catch((reason) => {
                    console.log(reason);
                    this.errorMessage = reason;
                });
                
            }
        }, (reason) => {
            this.selectedEvent = undefined;
            this.pairedEvent = undefined;
        });
    }

    userHasApuracao() {
        return this.authGuard.isApuracao();
    }

    carregarTiposEventosJornada() {
        this.journeyEventService.findAllMoverEventos().then(response => {
            this.errorMessage = response.error;
            if (!this.errorMessage) {
                this.tiposEventos = response.response;
            }
        }).catch(reason => {
            this.errorMessage = reason;
        });
    }

    toggleSelection(event: any, evento: Event) {
        var inserir = event.target.checked;
        if (inserir) {
            var novoEvento: any;
            novoEvento = {};
            novoEvento.id = evento.id !== undefined ? evento.id : 0;
            novoEvento.jornada = evento.jornada;
            novoEvento.instanteEvento = this.utils.formatDatetoDatetimeLocalInput(new Date(evento.instanteEvento));
            novoEvento.tipoEvento = evento.tipoEvento.id;
            novoEvento.justificativa = evento.justificativa;
            this.eventosSelecionados.push(novoEvento);
        } else {
            if (this.eventosSelecionados.length > 0) {
                var i = 0;
                this.eventosSelecionados.forEach(evt => {
                    if (evt.id === evento.id) {
                        this.removerItemMoverEvento(i);
                    }
                    i++;
                });
            }
        }
    }

    adicionarNovoEventoMover() {
        var loggedUser = this.authGuard.loggedUser();
        var novoEvento: any;
        novoEvento = {};
        novoEvento.id = 0;
        novoEvento.empresaId = loggedUser.selectedCompany.id,
        novoEvento.operadorLancamento = loggedUser.id
        this.eventosSelecionados.push(novoEvento);
    }

    abrirModalMoverEventos() {
        if (this.eventosSelecionados.length > 0) {   
            this.modalReference = this.modalService.open(this.dialogContentMoverEventos, { 
                centered: true, 
                size: 'lg',
                backdrop: 'static',
                keyboard: false});
            this.modalReference.result.then(result => {
                this.mover = false;
            }, (reason) => {
                this.mover = false;
                console.log('fechou modal');
            });
        }
    }

    moverEventos() {
        if (this.eventosSelecionados.length > 0) {
            
            this.erroMover = false;
            this.mover = true;
            this.msgErrosMover = [];
            let inicioJornada = false;
            let fimJornada = false;

            this.ordenarListaMoverEventos();
            for (let i = 0; i < this.eventosSelecionados.length; i++) {
                const atual = this.eventosSelecionados[i];
                const proximo = this.eventosSelecionados[i + 1];

                if ((!atual.justificativa || !atual.instanteEvento || !atual.tipoEvento === undefined) && !this.erroMover) {
                    this.erroMover = true;
                    this.msgErrosMover.push('* Todos os campos são obrigatórios.');
                }

                //se evento positivo, verifica se o próximo evento é seu par negativo
                if (atual.tipoEvento > 1 && proximo) {
                    if (atual.tipoEvento !== (proximo.tipoEvento*-1)) {
                        this.erroMover = true;
                        let journeyEvent = this.findTipoEventoById(atual.tipoEvento);
                        this.msgErrosMover.push(`* Evento "${journeyEvent.descricao}" sem finalização.`);
                    }
                }

                //se evento negativo, verifica se o próximo evento também é de finalização
                if (atual.tipoEvento < 0 && proximo) {
                    if (proximo.tipoEvento < 0) {
                        this.erroMover = true;
                        let journeyEvent = this.findTipoEventoById(proximo.tipoEvento);
                        this.msgErrosMover.push(`* Evento "${journeyEvent.descricao}" sem início.`);
                    }
                }

                if (atual.tipoEvento == 1) {
                    inicioJornada = true;
                    if (i != 0) {
                        this.msgErrosMover.push('* Início de jornada deve ser o primeiro evento.');
                        this.erroMover = true;
                    }
                }
                if (atual.tipoEvento == 0) {
                    fimJornada = true;
                    if (i < this.eventosSelecionados.length-1) {
                        this.msgErrosMover.push('* Fim de jornada deve ser o último evento.');
                        this.erroMover = true;
                    }
                }
            }
        
            if (!inicioJornada) {
                this.msgErrosMover.push('* Não foi informado início de jornada.');
                this.erroMover = true;
            }
            if (!fimJornada) {
                this.msgErrosMover.push('* Não foi informado fim de jornada.');
                this.erroMover = true;
            }

            if (!this.erroMover) {
                this.eventosSelecionados.forEach(evento => {
                    evento.instanteEvento = new Date(evento.instanteEvento);
                });

                this.modalReference.close();
                
                if (!this.erroMover) {
                    this.loading = true;
                    this.eventService.moverEventosNovaJornada(this.authGuard.loggedUser().id, this.activeObject.journey.id, this.eventosSelecionados).then(response => {
                        this.loading = false;
                        this.errorMessage = response.error;
                        if (!this.errorMessage) {
                            this.loadObject();
                        }
                    }).catch((reason) => {
                        this.loading = false;
                        this.errorMessage = reason;
                    });
                }
                
            }
        }
    }

    removerItemMoverEvento(index: number) {
        this.eventosSelecionados.splice(index, 1);
    }

    findTipoEventoById(id: number): JourneyEvent | undefined {
        return this.tiposEventos.find(t => t.id === id);
    }

    ordenarListaMoverEventos() {
        this.eventosSelecionados.sort((a, b) => {
            const dateA = a.instanteEvento ? new Date(a.instanteEvento).getTime() : Infinity;
            const dateB = b.instanteEvento ? new Date(b.instanteEvento).getTime() : Infinity;
            return dateA - dateB;
        });
    }
}