import { CRUDService } from "../base/crud.service";
import { Local } from "../../classes/local";
import { Http } from "@angular/http";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { AuthGuard } from "../../guards/auth.guard";
import { RetornoLocal } from "../../classes/retorno-local";
import { RESTResponseVO } from "app/classes/restresponsevo";
import { User } from '../../classes/user';
import { Company } from "app/classes/company";
import { Address } from "../../classes/address";
import { RetornoMapa } from "app/classes/retorno-mapa";
import { TipoLocal } from "../../classes/tipo-local";

 
@Injectable({
  providedIn: 'root'
})
export class LocalService extends CRUDService<Local> {
  public company: number;
  constructor(http: Http, public authGuard: AuthGuard) {
    super(http, environment.baseURL + 'App/v2/web/local', true);
    var loggedUser: User = authGuard.loggedUser();
    this.company = loggedUser.selectedCompany.id;
    this.listURL = environment.baseURL + "App/v2/web/local/buscarListaLocal/"+ this.company;
  }

  getLista() {
    const url: string =
      (this.listURL === undefined && `${this.defaultUrl}`) || `${this.listURL}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoLocal;
        }
      })
      .catch(this.handleError);
  }

  getById(id: number) {
    const url = `${this.defaultUrl}/buscarLocal/${id}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoLocal;
        }
      })
      .catch(this.handleError);
  }

  cadastrarLocal(local: Local): Promise<RESTResponseVO<Local>> {
    const url = `${this.defaultUrl}/cadastrarLocal`;
    var empresa: Company = new Company();
    empresa.id = this.company;
    local.cliente = empresa;
    return this.enviarRequisicao(local, url);
  }

  excluirLocal(local: Local): Promise<RESTResponseVO<Local>> {
    const url = `${this.defaultUrl}/excluirLocal`;
    var empresa: Company = new Company();
    empresa.id = this.company;
    local.cliente = empresa;
    return this.enviarRequisicao(local, url);
  }

  editarLocal(local: Local): Promise<RESTResponseVO<Local>> {
    const url = `${this.defaultUrl}/editarLocal`;
    var empresa: Company = new Company();
    empresa.id = this.company;
    local.cliente = empresa;
    return this.enviarRequisicao(local, url);
  }

  enviarRequisicao(local: Local, url: string): Promise<RESTResponseVO<Local>> {
    try {
      return this.http
        .post(url, JSON.stringify(local), { headers: this.headers })
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            var retorno: RetornoLocal = response.json() as RetornoLocal;
            var local: Local = retorno.local;
            if (local === null) {
              local = new Local();
            }
            local.id = 0;
            return local;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }

  buscarLatLngPeloEndereco(address:Address){
    try {
      const url = `${this.defaultUrl}/buscarLatLngPeloEndereco`;
      return this.http
        .post(url, JSON.stringify(address), { headers: this.headers })
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            var retorno: RetornoMapa = response.json() as RetornoMapa;
            return retorno;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }

  getTipoLocal(): TipoLocal[]{
    let listaTipoLocal =[
      new TipoLocal("E", "Empresa"),
      new TipoLocal("O", "Oficina"),
      new TipoLocal("N", "Outros"),
      new TipoLocal("P", "Posto Abastecimento"),
      new TipoLocal("PF", "Posto Fiscalização")
    ];
    return listaTipoLocal;
  }

  getListaLocaisSemCoordenadas() {
    const url = `${this.defaultUrl}/buscarLocaisSemCoordenadas/${this.company}`;
    console.log(url);
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoLocal;
        }
      })
      .catch(this.handleError);
  }

  getListaCoordenadasPorId(idOrigem: number, idDestino:number) {
    const url = `${this.defaultUrl}/buscarLocal/${idOrigem}/${idDestino}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json();
        }
      })
      .catch(this.handleError);
  }
}
