import { Injectable } from '@angular/core';
import { RESTResponseVO } from '../../classes/restresponsevo';
import { Http } from '@angular/http';
import { AuthGuard } from '../../guards/auth.guard';
import { Event } from '../../classes/event';
import { environment } from '../../../environments/environment';
import { EventReportVO } from '../../classes/event-report-vo';
import { JornadaMotorista } from '../../classes/jornadaMotorista';
import { User } from '../../classes/user';
import { PesquisaEvento } from '../../classes/pesquisa-evento';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { EventoCorrecao } from 'app/classes/evento-correcao';
 
@Injectable()
export class EventsService {
    constructor(private http: Http, public authGuard: AuthGuard) { }

    update(id: number, event: Event, user: number): Promise<RESTResponseVO<Event>> {
        const url: string = environment.baseURL + 'App/v2/web/eventos/' + id + '/' + user;
        return this.http
            .put(url, event)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<Event>;
            })
            .catch(this.handleError);
    }

    createChained(event: any, user: Number): Promise<RESTResponseVO<Event[]>> {
        const url: string = environment.baseURL + 'App/v2/web/eventos/encadeado/' + user;
        return this.http
            .post(url, event)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<Event[]>;
            })
            .catch(this.handleError);
    }

    findCurrentEventAll(company: Number, branch: Number): Promise<RESTResponseVO<Event[]>> {
        const url: string = environment.baseURL + 'App/v2/web/eventos/eventoAtual/' + company + '/' + branch;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<Event[]>;
            })
            .catch(this.handleError);
    }

    getClockingReport(driver: Number, start: Number, end: Number): Promise<RESTResponseVO<EventReportVO[]>> {
        const url: string = environment.baseURL + 'App/v2/web/eventos/relatorio/' + driver + '/' + start + '/' + end;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<EventReportVO[]>;
            })
            .catch(this.handleError);
    }

    createAllowancePeriod(driver: number, event): Promise<RESTResponseVO<Event>> {
        const url: string = environment.baseURL + 'App/v2/web/eventos/abono/periodo/' + driver;
        return this.http
            .post(url, event)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<Event>;
            })
            .catch(this.handleError);
    }

    markAsRemoved(eventId: number, event: any, user: number): Promise<RESTResponseVO<boolean>> {
        const url: string = environment.baseURL + 'App/v2/web/eventos/marcaremovido/' + eventId + '/' + user;
        return this.http
            .put(url, event)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<boolean>;
            })
            .catch(this.handleError);
    }

    /////// Calendar Methods ///////

    updateTime(event: any, user: Number): Promise<RESTResponseVO<boolean>> {
        const url: string = environment.baseURL + 'App/v2/web/journeygrid/updatetime/' + user;
        return this.http
            .put(url, event)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<boolean>;
            })
            .catch(this.handleError);
    }

    updateRemoved(event: any): Promise<RESTResponseVO<boolean>> {
        const url: string = environment.baseURL + 'App/v2/web/journeygrid/updateremoved';
        return this.http
            .put(url, event)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<boolean>;
            })
            .catch(this.handleError);
    }

    getList(dtInicio: string, dtFim: string, 
        motoristas: Array<number>, 
        user: number, type: string, 
        unidades: Array<number>): Promise<RESTResponseVO<Array<JornadaMotorista>>> {
        const url = `${environment.baseURL}App/v2/web/eventos/list/${dtInicio}/${dtFim}/${user}/${type}`;
        const pesquisaEvento = new PesquisaEvento();
        pesquisaEvento.motoristas = motoristas;
        pesquisaEvento.unidades = unidades;
        return this.http
            .post(url, pesquisaEvento).pipe(timeout(3000000))
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<Array<Event>>;
            })
            .catch(this.handleError);
    }

    protected handleError(error: any): Promise<any> {
        console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
        return Promise.reject(error.message || error);
    }

    reajustarZoomMapa(listaDePermissoes):boolean{
        try {
            for(var i = 0; i < listaDePermissoes.length; i++) {
                if (listaDePermissoes[i].name == 'operacao') {
                    return false;
                }
            }
        } catch (error) {
            console.error(error);
        }
        return true;
    }

    getUrlDasboard() {
        try { 
            var loggedUser: User = this.authGuard.loggedUser();
            let company = loggedUser.selectedCompany.id;
            let unidade = loggedUser.selectedBranch.id
            let idUser = loggedUser.id;
            let acessarViam = loggedUser.acessarViam;
            let acessarViaSat = loggedUser.acessarViaSat;
            let listaDePermissoes = loggedUser.claimList;
            let reajustarZoomMapa = this.reajustarZoomMapa(listaDePermissoes);
            var tempoDeshboard:number = 300000;
            if(loggedUser.tempoAtualizacaoDashboard){
                var a = loggedUser.tempoAtualizacaoDashboard.split(":", 2); 
                var horas:number = Number(a[0]);
                if(horas > 0){
                    horas = ((horas * 60) * 60) * 1000;
                }
                var minutos:number = Number(a[1]);
                if(minutos > 0){
                    minutos = (minutos * 60) * 1000;
                }

                tempoDeshboard = horas + minutos;
                console.log(a);
            }
            const urlDasboard = environment.baseURlDashboardRodovia+"?idEmpresa="+company+"&unidade="+unidade+"&tempoDeshboard="+tempoDeshboard+"&idUsuario="+idUser+"&acessarViam="+acessarViam+"&acessarViaSat="+acessarViaSat+"&reajustarZoomMapa="+reajustarZoomMapa;
            console.log(urlDasboard);
            return urlDasboard;
        } catch (error) {
            console.error(error);
        }
    }

    moverEventosNovaJornada(user: number, jornadaId: number, eventos: Event[]): Promise<RESTResponseVO<boolean>> {
        const url: string = environment.baseURL + 'App/v2/web/eventos/moverEventosNovaJornada/' + user + '/' + jornadaId;
        return this.http
            .post(url, eventos)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<boolean>;
            })
            .catch(this.handleError);
    }

    getEventosCorrecao(motoristaId: number, dataInicio: number, dataFim: number): Promise<RESTResponseVO<Array<EventoCorrecao>>> {
        const url: string = environment.baseURL + 'App/v2/web/eventos/getEventosCorrecao/' + motoristaId + '/' + dataInicio + '/' + dataFim;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<Array<EventoCorrecao>>;
            })
            .catch(this.handleError);
    }

    criarEventosCorrecao(eventos: any[]): Promise<RESTResponseVO<boolean>> {
        const url: string = environment.baseURL + 'App/v2/web/eventos/criarEventosCorrecao';
        return this.http
            .post(url, eventos)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<boolean>;
            })
            .catch(this.handleError);
    }
}
