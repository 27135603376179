import { BaseListComponent } from "../../../base/base-list/base-list.component";
import { Component, ViewEncapsulation } from "@angular/core";
import { IdentificacaoOperadorService } from "../../../../services/crud/identificacaooperador.service";
import { UtilService } from "../../../../services/utils.service";
import { PDFService } from "../../../../services/pdf.service";
import { AuthGuard } from "../../../../guards/auth.guard";

@Component({
  templateUrl: '../../../base/base-list/base-list.component.html',
  styleUrls: ['../../../base/base-list/base-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class IdentificacaoOperadorListComponent extends BaseListComponent {

  constructor(private identificacaoOperadorService: IdentificacaoOperadorService,
    private utilService: UtilService,
    pdfService: PDFService,
    authGuard: AuthGuard) {
    super(utilService, pdfService, authGuard);
    this.searchField = "descricao";
  }

  ngOnInit() {
    this.pageTitle = "Autenticação | iButton";
    this.formPath = "/adm/identificacaooperador/form";

    this.tableHeaders = [
      [
        this.createHeader("ID", 0, true),
        this.createHeader("Descrição", 1, true),
        this.createHeader("Total de Veiculos", 2, true),
        this.createHeader("Total de Motoristas/Operador", 3, true)
      ]
    ];

    this.dataFields = [
      this.createField("id", "string"),
      this.createField("descricao", "string"),
      this.createField("quantidadDeVeiculos", "string"),
      this.createField("quantidadeOperadorMotorista", "string"),
    ];

    this.identificacaoOperadorService.getLista().then(response => {
      this.data = response.listaIdentificacaoOperador;
      this.loading = false;
    });
  }

}
