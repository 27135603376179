import { Injectable } from '@angular/core';
import { CRUDService } from '../base/crud.service';
import { Http } from '@angular/http';
import { HolidayScope } from '../../classes/holiday-scope';
import { environment } from '../../../environments/environment';

@Injectable()
export class HolidayScopeService extends CRUDService<HolidayScope> {
  constructor(http: Http) {
    super(http, environment.baseURL + 'App/v2/web/ambitosferiados', true);
  }
}