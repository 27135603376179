import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Portaria671 } from "app/classes/portaria-671";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RESTResponseVO } from "app/classes/restresponsevo";
import { FiltroArquivoAEJ } from "app/classes/filtro-arquivo-aej";
import { ResponseContentType } from "@angular/http/src/enums";
import { FiltroArquivoAFD } from "app/classes/filtro-arquivo-afd";
import { timeout } from 'rxjs/operators';


@Injectable()
export class PortariaService {
    
    urlBase: string;
    headers = new HttpHeaders();

    constructor(private http: HttpClient) { 
        this.urlBase = environment.baseURL + 'App/v2/web/adm-portarias';
        this.headers = this.headers.set('Content-Type', 'application/json; charset=utf-8');
    }

    create(portaria: Portaria671): Promise<any> {
        
        return this.http
          .post(this.urlBase, JSON.stringify(portaria), { headers: this.headers })
          .toPromise()
          .then(response => {
            return response
          })
          .catch(this.handleError);
      }

      update(portaria: Portaria671): Promise<any> {
        return this.http
          .put(this.urlBase + `/${portaria.id}`, JSON.stringify(portaria), { headers: this.headers })
          .toPromise()
          .then(response => {
              return response;
          })
          .catch(this.handleError);
      }

      getList(): Promise<RESTResponseVO<Portaria671[]>> {
        return this.http
          .get(this.urlBase)
          .toPromise()
          .then(response => {
              return response as Portaria671[];
          })
          .catch(this.handleError);
      }

      getArquivoAEJ(filtro: FiltroArquivoAEJ): Promise<any> {
        
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json', 
            'Accept': '*/*',
            'observe': 'response'
          }),
        };
      
        return this.http.post(this.urlBase + '/aej-exportacoes', filtro, {
          ...httpOptions,
          responseType: 'blob'}).pipe(timeout(3000000))
          .toPromise()
          .then(response => {
            console.log(response);
            return response;
          })
          .catch(this.handleError);
      }

      getArquivoAFD(filtro: FiltroArquivoAFD): Promise<any> {
        
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'observe': 'response'
          }),
        };
      
        return this.http.post(this.urlBase + '/afd-exportacoes', filtro, {
          ...httpOptions,
          responseType: 'blob'}).pipe(timeout(3000000))
          .toPromise()
          .then(response => {
            console.log(response);
            return response;
          })
          .catch(this.handleError);
      }

      protected handleError(error: any): Promise<any> {
        console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
        return Promise.reject(error.message || error);
      }

   
}