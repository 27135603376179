import { BaseListComponent } from '../../base/base-list/base-list.component';
import { OnInit, Component, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { UtilService } from '../../../services/utils.service';
import { DriverService } from '../../../services/crud/driver.service';
import { PDFService } from '../../../services/pdf.service';
import { AuthGuard } from '../../../guards/auth.guard';
import { Driver } from '../../../classes/driver';
import { JourneyService } from '../../../services/crud/journey.service';
import { EventsService } from '../../../services/crud/events.service';
import { SascarEventsService } from '../../../services/crud/sascar-events.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs/Observable';

@Component({
    templateUrl: './sascar-events.component.html',
    styleUrls: ['./sascar-events.component.css']
})
export class SascarEventsComponent extends BaseListComponent
    implements OnInit, AfterViewChecked {
    public eventsForm: FormGroup = new FormGroup({});
    public driverList: Driver[];
    public searchObj: { driver?: Driver; };

    constructor(
        private utilService: UtilService,
        private driverService: DriverService,
        private journeyService: JourneyService,
        private eventsService: EventsService,
        private sascarEventsService: SascarEventsService,
        private authGuard: AuthGuard,
        private cdRef: ChangeDetectorRef,
        pdfService: PDFService,
        private fb: FormBuilder
    ) {
        super(utilService, pdfService, authGuard);

        this.searchObj = {};
    }
    public searchObject: {
      driver: Driver;
      driverStatus: string;
  } = { driver: undefined, driverStatus: undefined,};
    ngOnInit() {
        this.loading = true;
        this.driverService.getListMinimo().then(response => {
            this.originalDriverList = response.response;
            this.driverList = response.response;
            this.driverList.sort((d1, d2) => (d1.nome > d2.nome ? 1 : -1));

            this.driverList.forEach(driver => driver.nome = `${driver.nome} ${driver.sobrenome}`);

            this.loading = false;
            this.onStatusChange(this.searchObject.driverStatus);
        });
        this.eventsForm = this.fb.group({
          file: ['']
        });
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    driverTemplate(r) {
        return `${r.nome}  ${r.sobrenome}`;
    }

    driverstTitle() {
        return `Motoristas (${this.driverList ? this.driverList.length : 0})`;
    }

    onSelectedFile(event) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.eventsForm.get('file').setValue(file);
      }
    }

    saveEvents(){
        this.loading = true;
        const file = new FormData();
        file.append('file', this.eventsForm.get('file').value);
        try{
          return this.sascarEventsService.saveSascarEvent(file, this.searchObj.driver.id, this.authGuard.loggedUser().id).then(response => {
            this.errorMessage = response.error;
            if (!this.errorMessage) {
              this.errorMessage = "Upload concluído com sucesso";
              this.loading = false;
            } else {
              this.errorMessage = response.error;
              this.loading = false;
            }
          });
        }catch (e) {
          console.log(e);
          this.loading = false;
        }
    }
// Driver Search

originalDriverList: Driver[];

driverFormatter = (x: Driver) => x.nome + ' ' + x.sobrenome;

searchDriver = (text$: Observable<string>) =>
    text$
        .debounceTime(200)
        .map(term =>
            this.driverList
                .filter(v => v.nome.toLowerCase().indexOf(term.toLowerCase()) > -1)
                .slice(0, 10)
        );

    onStatusChange(value) {
      if (value !== '') {
          this.driverList = this.originalDriverList.filter(
              d => d.situacao === +value
          );
      } else {
          this.driverList = this.originalDriverList;
      }
  }
}
