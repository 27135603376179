import { BaseListComponent } from '../../base/base-list/base-list.component';
import { OnInit, Component } from '@angular/core';
import { UtilService } from '../../../services/utils.service';
import { DriverService } from '../../../services/crud/driver.service';
import { UserService } from '../../../services/crud/user.service';
import { PDFService } from '../../../services/pdf.service';
import { AuthGuard } from '../../../guards/auth.guard';
import { Driver } from '../../../classes/driver';
import { User } from '../../../classes/user';
import { Observable } from 'rxjs/Observable';
import { ChatService } from '../../../services/crud/chat.service';
import { environment } from 'environments/environment';
import * as firebaseApp from '../../../../assets/js/firebase/firebase-app.js'
import * as firebaseAnalytics from '../../../../assets/js/firebase/firebase-analytics.js'
import * as firebaseDataBase from '../../../../assets/js/firebase/firebase-database.js'
import { DomSharedStylesHost } from '@angular/platform-browser/src/dom/shared_styles_host';

interface Unidade {
  id: number;
  name: string;
}

@Component({
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ChatDriverListComponent extends BaseListComponent
  implements OnInit {

    app: any;
    analytics: any;
    dbRead:any;

  constructor(
    private utilService: UtilService,
    private driverService: DriverService,
    private userService: UserService,
    private chatService: ChatService,
    pdfService: PDFService,
    private authGuard: AuthGuard
  ) {
    super(utilService, pdfService, authGuard);
    this.searchField = '';
  }

  public driverList: Driver[];
  public userList: User[];
  public unidadeList: Unidade[];
  public company: number;
  public branch: string;
  public searchObject: { driver: Driver; start: Date; end: Date } = {
    driver: undefined,
    start: undefined,
    end: undefined
  };

  ngOnInit() {
    this.loading = true;
    this.driverService.getMotoristas().then(response => {
      this.driverList = response.response;
      this.loading = false;
    });

    this.userService.getList().then(response => {
      this.userList = response.response;
    });

    var loggedUser = this.authGuard.loggedUser();
    this.company = loggedUser.selectedCompany.id;
    this.branch = loggedUser.selectedBranch.id === 0 && '0' || loggedUser.selectedBranch.id.toString();

    this.app =  firebaseApp.initializeApp(environment.firebaseConfig);
    this.analytics = firebaseAnalytics.getAnalytics(this.app);
    this.dbRead = firebaseDataBase.getDatabase();

    this.unidadeList = loggedUser.branchList;

  }

  milissegundosParaDataHora(milissegundos) {
    // Cria um objeto Date usando os milissegundos fornecidos
    const data = new Date(milissegundos);

    // Obtém os componentes da data
    const dia = data.getDate();
    const mes = data.getMonth() + 1; // Meses são indexados de 0 a 11
    const ano = data.getFullYear();
    const horas = data.getHours();
    const minutos = data.getMinutes();
    const segundos = data.getSeconds();

    // Formata a data e hora
    const dataHoraFormatada = `${ano}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}T${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`;

    return dataHoraFormatada;
  }

  doSearch() {
    this.loading = true;
    this.errorMessage = undefined;

    if (!this.searchObject) {
      this.errorMessage = 'Favor preencher os campos do formulário.';
    } else if (!this.searchObject.driver) {
      this.errorMessage = 'Favor selecionar um motorista.';
    } else if (!this.searchObject.start || !this.searchObject.end) {
      this.errorMessage = 'Data inicial ou final inválidas ou não preenchidas!';
    } else {
      let start = this.searchObject.start.getTime();

      const data_final = new Date(this.searchObject.end.getTime());
      data_final.setDate(data_final.getDate() + 1);
      let end = data_final.getTime();

      this.tableHeaders = [
        [
          this.createHeader('Data/Hora', 0, true),
          this.createHeader('Remetente', 1, true),
          this.createHeader('Destinatário', 2, true),
          this.createHeader('Conversa', 3, true)
        ]
      ];

      this.dataFields = [
        this.createField('sentDate', 'datetime'),
        this.createField('fromJID', 'string'),
        this.createField('toJID', 'string'),
        this.createField('body', 'string')
      ];

      this.data = [];

      const caminho = 'empresas/'+this.company+'/unidades/'+this.searchObject.driver.unidadesId+'/mensagens/';
      const chatsRef = firebaseDataBase.ref(this.dbRead, caminho);
      firebaseDataBase.onValue(chatsRef, (snapshot) => {
          snapshot.forEach((childSnapshot) => {
              const childData = childSnapshot.val();

              if ((childData.sender == this.searchObject.driver.userId+"") || (childData.receiver == this.searchObject.driver.userId+"")) {
                if (childData.time >= start && childData.time < end) {
                  var dataHora = this.milissegundosParaDataHora(childData.time);
                  const dateTime = new Date(dataHora);
                  this.data.push({ sentDate: dateTime, fromJID: this.getUserNameById(childData.sender), toJID: this.getUserNameById(childData.receiver), body: childData.message });
                }
              }
          });
      }, {
          onlyOnce: true
      });

      this.loading = false;
    }
  }

  driverFormatter = (x: Driver) => x.nome + ' ' + x.sobrenome;

  searchDriver = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map(term =>
        term.length < 2
          ? []
          : this.driverList
              .filter(
                v => v.nome.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
              .slice(0, 10)
      );

  driverTemplate(r) {
    return r.nome + ' ' + r.sobrenome;
  }

  getUserNameById(userId: number): string {
    let userName = '';
    if (userId > 0) {
      if (userId == this.searchObject.driver.userId) {
        userName = this.searchObject.driver.nome + ' ' + this.searchObject.driver.sobrenome;
      } else {
        this.userList.forEach(user => {
          if (user.id == userId) {
            userName = user.userName;
          }
        });
      }
    } else {
      userName = this.getBranchNameById(this.searchObject.driver.unidadesId);
    }
    return userName;
  }

  getBranchNameById(id: number): string {
    let branchName = '';
    if (id > 0) {
      this.unidadeList.forEach(u => {
        if (u.id == id) {
          branchName = u.name;
        }
      });
    } else {
      branchName = '';
    }
    return branchName;
  }
}
