import { Component, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { UtilService } from "../../../services/utils.service";

@Component({
    selector: 'timepicker-input',
    styleUrls: ['./timepicker-input.component.css'],
    template: `
      <div class="input-group input-group-sm">
        <ngb-timepicker [(ngModel)]="obj"></ngb-timepicker>
      </div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimePickerInputComponent),
            multi: true
        }
    ]
})
export class TimePickerInputComponent implements ControlValueAccessor {
    @Input('obj') _obj: any;

    get obj() {
        return this._obj;
    }

    set obj(val) {
        this._obj = val;
        this.propagateChange(this._obj);
    }

    propagateChange = (_: any) => { };

    writeValue(obj: any): void {        
        this._obj = obj;
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void { }

    setDisabledState?(isDisabled: boolean): void { }
}