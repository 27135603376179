import { Injectable } from "@angular/core";
import { Driver } from "../../classes/driver";
import { UtilService } from "../utils.service";
import { Instant } from "../../classes/instant";
import { JourneyLogReport } from "../../classes/journey-log-report";
import { JourneyCalculationReport } from "../../classes/journey-calculation-report";
import { AuthGuard } from "app/guards/auth.guard";
import { environment } from "../../../environments/environment";
import { Filtro } from "app/classes/filtro";
import { Headers, Http } from '@angular/http';
import { RetornoRelatorio } from "../../classes/retorno-relatorio";
import { InconformidadesVeiculo } from "app/classes/inconformidades-veiculo";
import { FiltroEspelhoPonto } from "app/classes/filtro-espelho-ponto";
import { RetornoRelatorioDirecaoVersusPlaca } from "app/classes/retorno-relatorio-direcao-versus-placa";
import { User } from "app/classes/user";
import { Branch } from "app/classes/branch";


@Injectable()
export class ReportService {
    public company: number;
    protected headers = new Headers({ 'Content-Type': 'application/json' });
    protected http: Http;
    public loggedUser: User;
    constructor(private utils: UtilService, http: Http, public authGuard: AuthGuard) {
        this.http = http;
        this.loggedUser = authGuard.loggedUser();
        this.company = this.loggedUser.selectedCompany.id;
    }

    private checkInstant(instant: Instant, justificativas) {
        if (instant && instant.original) {
            justificativas.push(instant);
        }
    }

    getPrintableInstant(instant: Instant, mask: string) {
        let formatted;
        if (instant) {
            formatted = this.utils.formatDate(instant.data, mask);
            if (instant.original && instant.indicador) {
                formatted += '*';
            }
        }

        if (!formatted) formatted = " ";

        return {
            text: formatted,
            alignment: 'center',
            style: 'dados'
        }
    }

    sumarize(total: number, fim: number, inicio?: number) {
        if (!inicio) {
            if ((fim) && (fim >= 60000)) {
                return total += fim;
            } else {
                return total;
            }
        } else {
            if ((!fim) || (!inicio))
                return;

            return total += fim - inicio;
        }
    }

    printInstantsAndSummarize(instants: Instant[], total: number, starts: Instant[]) {
        let row = new Array();
        if (instants) {    // c09
            var tmpSze = instants.length;
            for (var j = 0; j < tmpSze; j++) {
                row.push(this.getPrintableInstant(instants[j], 'HH:mm:ss'));
                if (instants[j] && starts[j]) {
                    total = this.sumarize(total, new Date(instants[j].data).getTime(), new Date(starts[j].data).getTime());
                }
            }
        }
        return row;
    }

    getPrintableText(text, impar?: string) {
        if (!text) text = " ";
        if (!impar) impar = "#FFFFFF";

        return {
            text: text,
            alignment: 'center',
            fillColor: impar,
            style: 'dados'
        }
    }

    getPrintableColSpanText(colSpan: number, text, impar?: string) {
        if (!text) text = " ";
        if (!impar) impar = "#FFFFFF";

        return {
            colSpan: colSpan,
            text: text,
            alignment: 'center',
            fillColor: impar,
            style: 'dados'
        }
    }

    getPrintableTime(msec, impar?: string, colSpan = 1) {
        let formatted = this.utils.miliToHHmmss(msec);
        if (!impar) impar = "#FFFFFF";

        return {
            text: formatted,
            alignment: 'center',
            fillColor: impar,
            style: 'dados',
            colSpan: colSpan
        };
    };

    getPrintableColSpanTime(msec, colSpan: number, impar?: string) {
        let formatted = this.utils.miliToHHmmss(msec);
        if (!impar) impar = "#FFFFFF";

        return {
            colSpan: colSpan,
            text: formatted,
            alignment: 'center',
            fillColor: impar,
            style: 'dados'
        };
    };

    getPrintableDate(date: any, mask: string, impar?: string) {
        let formatted = this.utils.formatDate(date, mask);
        if (!formatted) formatted = " ";
        if (!impar) impar = "#FFFFFF";

        return {
            text: formatted,
            alignment: 'center',
            fillColor: impar,
            style: 'dados'
        }
    }

    getPrintableFictaHour(msec, impar?: string) {
        let formatted = this.utils.miliToHHmmss(msec);
        if (!impar) impar = "#FFFFFF";

        if (formatted.length > 6) {
            formatted += " (" + this.utils.miliToHHmmss(msec * 1.142857) + ")";
        }
        return {
            text: formatted,
            alignment: 'center',
            fillColor: impar,
            style: 'dados'
        };
    }

    printJourneyCalculationBody(row, nonconformity) {
        var body: any = [
            this.printDriverHeader(row, 18, 'Apuração de  Jornada', nonconformity),
            this.printJourneyCalculationHeader1(row),
            this.printJourneyCalculationHeader2(row),
            this.printJourneyCalculationHeader3(row)
        ];

        body = body.concat(this.printCalculationRows(row));

        return body;
    }

    printJourneyCalculationTable(row, nonconformity) {
        return {
            table: {
                headerRows: 4,
                dontBreakRows: true,
                widths: ['6.25%', '*', '5.5%', '6.25%', '5.5%', '5.5%', '5.5%', '6.25%', '5.5%', '5.5%', '6.25%', '5.5%', '5.5%', '5.5%', '5.5%', '6.25%', '5.5%', '5.5%'],
                body: this.printJourneyCalculationBody(row, nonconformity),
                pageBreak: 'after'
            },
            layout: {
                paddingLeft: function (i) {
                    return 0;
                },
                paddingRight: function (i, node) {
                    return 0;
                },
                paddingTop: function (i, node) {
                    return 0;
                },
                paddingBottom: function (i, node) {
                    return 0;
                }
            }
        }
    }

    printApuracaoJornadaTable(row, nonconformity) {
        return {
            table: {
                headerRows: 4,
                dontBreakRows: true,
                widths: ['6.25%', '*', '5.5%', '6.25%', '5.5%', '5.5%', '5.5%', '6.25%', '5.5%', '5.5%', '6.25%', '5.5%', '5.5%', '5.5%', '5.5%', '6.25%', '5.5%', '5.5%'],
                body: this.printJourneyCalculationBody(row, nonconformity),
                pageBreak: 'after'
            },
            layout: {
                paddingLeft: function (i) {
                    return 0;
                },
                paddingRight: function (i, node) {
                    return 0;
                },
                paddingTop: function (i, node) {
                    return 0;
                },
                paddingBottom: function (i, node) {
                    return 0;
                }
            }
        }
    }

    // milissegundosParaHoras(milissegundos) {
    //   // Convertendo milissegundos para horas
    //   const horas = milissegundos / (1000 * 60 * 60);
    //   return horas;
    // }

    // teste(valor: any){
    //   if(valor === "F.Comp"){
    //     const x = valor / (1000 * 60 * 60);
    //     return x;
    //   }
    // }


    printCalculationRows(row) {
        var lSze = row.linhas.length;

        var totalHeDiurna = 0;
        var totalHedIntra = 0;
        var totalHedDSR = 0;
        var totalHedFeriado = 0;
        var totalHeNoturna = 0;
        var totalHenIntra = 0;
        var totalHenDSR = 0;
        var totalHenFeriado = 0;
        var totalHeInter = 0;
        var totalTeDiurno = 0;
        var totalTeNoturno = 0;
        var totalAddNoturno = 0;
        var totalHfAbonadas = 0;
        var totalHfDescontadas = 0;
        let totalCompensado = 0;
        let valorFComp = '';

        var impar = '';
        var pulou = 0;
        let body = new Array();

        for (var i = 0; i < lSze; i++) {
            impar = '#CCCCCC';

            if (((i + pulou) % 2) !== 0) {
                impar = '#FFFFFF';
            }

            var dataRow = [];
            var linha = row.linhas[i];
            var proxima = null;
            var mesmaData = false;

            if (i + 1 < lSze) {
                proxima = row.linhas[i + 1];

                var dtAtual = this.utils.formatDate(linha.data, 'DD/MM/YYYY');
                var dtProxi = this.utils.formatDate(proxima.data, 'DD/MM/YYYY');
                mesmaData = (dtAtual === dtProxi);
            }

            var tipoJornada = linha.tipoJornada;
            var horasFolgaCompensada = linha.horasFolgaCompensada;
            // "Informações Gerais"
            dataRow.push(this.getPrintableDate(linha.data, 'DD/MM/YYYY', impar));    // c01
            dataRow.push(this.getPrintableDate(linha.data, 'ddd', impar));  // c02

            // Se for a mesma data, pula para não ficar duplicado
            if (tipoJornada) {
                if (mesmaData) {
                    pulou = (pulou === 1) ? 0 : 1;
                    continue;
                }



                dataRow.push(this.getPrintableText(tipoJornada, impar)); // c03
                dataRow.push(this.getPrintableText(tipoJornada, impar)); // c04
                dataRow.push(this.getPrintableText(tipoJornada, impar));   // c05
                dataRow.push(this.getPrintableText(tipoJornada, impar)); // c06
                dataRow.push(this.getPrintableText(tipoJornada, impar)); // c07
                dataRow.push(this.getPrintableText(tipoJornada, impar)); // c08
                dataRow.push(this.getPrintableText(tipoJornada, impar));   // c09
                dataRow.push(this.getPrintableText(tipoJornada, impar)); // c10
                dataRow.push(this.getPrintableText(tipoJornada, impar));    // c11
                dataRow.push(this.getPrintableText(tipoJornada, impar));    // c12
                dataRow.push(this.getPrintableText(tipoJornada, impar));    // c13
                dataRow.push(this.getPrintableText(tipoJornada, impar));    // c14
                dataRow.push(this.getPrintableText(tipoJornada, impar));    // c15
                dataRow.push(this.getPrintableText(tipoJornada, impar));    // c16
                if (tipoJornada === "F.Comp") {
                    //dataRow.push(this.getPrintableColSpanText(2, this.getPrintableTime(horasFolgaCompensada , impar) , impar)); //17..18


                    dataRow.push(this.getPrintableTime(horasFolgaCompensada, impar, 2)); //17..18
                    totalCompensado = this.sumarize(totalCompensado, linha.horasFolgaCompensada);
                } else {
                    dataRow.push(this.getPrintableColSpanText(2, tipoJornada, impar)); //17..18
                }


                // dataRow.push(this.getPrintableText(tipoJornada, impar));    // c17
                // dataRow.push(this.getPrintableText(tipoJornada, impar));    // c18

            } else {
                // "Horas Extras Diurnas"
                dataRow.push(this.getPrintableTime(linha.horasExtrasDiurnas, impar)); // c03
                totalHeDiurna = this.sumarize(totalHeDiurna, linha.horasExtrasDiurnas);

                dataRow.push(this.getPrintableTime(linha.hedIntraJornada, impar)); // c04
                totalHedIntra = this.sumarize(totalHedIntra, linha.hedIntraJornada);

                dataRow.push(this.getPrintableTime(linha.hedDSR, impar));   // c05
                totalHedDSR = this.sumarize(totalHedDSR, linha.hedDSR, 0);

                dataRow.push(this.getPrintableTime(linha.hedFeriado, impar)); // c06
                totalHedFeriado = this.sumarize(totalHedFeriado, linha.hedFeriado);

                // "Horas Extras Noturnas"
                dataRow.push(this.getPrintableFictaHour(linha.horasExtrasNoturnas, impar)); // c07
                totalHeNoturna = this.sumarize(totalHeNoturna, linha.horasExtrasNoturnas);

                dataRow.push(this.getPrintableTime(linha.henIntraJornada, impar)); // c08
                totalHenIntra = this.sumarize(totalHenIntra, linha.henIntraJornada);

                dataRow.push(this.getPrintableTime(linha.henDSR, impar));   // c09
                totalHenDSR = this.sumarize(totalHenDSR, linha.henDSR);

                dataRow.push(this.getPrintableTime(linha.henFeriado, impar)); // c10
                totalHenFeriado = this.sumarize(totalHenFeriado, linha.henFeriado);

                // "Horas Extras"
                dataRow.push(this.getPrintableTime(linha.horasExtrasInterJornadas, impar));    // c11
                totalHeInter = this.sumarize(totalHeInter, linha.horasExtrasInterJornadas);

                // "Tempo de Espera"
                dataRow.push(this.getPrintableTime(linha.tempoDeEsperaDiurno, impar));    // c12
                totalTeDiurno = this.sumarize(totalTeDiurno, linha.tempoDeEsperaDiurno);

                dataRow.push(this.getPrintableFictaHour(linha.tempoDeEsperaNoturno, impar));    // c13
                totalTeNoturno = this.sumarize(totalTeNoturno, linha.tempoDeEsperaNoturno);

                // "Adicional"
                dataRow.push(this.getPrintableFictaHour(linha.adicionalNoturno, impar));    // c14
                totalAddNoturno = this.sumarize(totalAddNoturno, linha.adicionalNoturno);

                // "Horas Faltosas"
                dataRow.push(this.getPrintableTime(linha.horasFaltosasAbonadas, impar));    // c15
                totalHfAbonadas = this.sumarize(totalHfAbonadas, linha.horasFaltosasAbonadas);

                dataRow.push(this.getPrintableTime(linha.horasFaltosasDescontadas, impar));    // c16
                totalHfDescontadas = this.sumarize(totalHfDescontadas, linha.horasFaltosasDescontadas);

                // "folgas compensadas"
                dataRow.push(this.getPrintableColSpanTime(linha.horasFolgaCompensada, 2, impar));    // c17..18
                totalCompensado = this.sumarize(totalCompensado, linha.horasFolgaCompensada);



            }
            body.push(dataRow);
        };

        if ((lSze % 2) !== 0) {
            impar = '#CCCCCC';
        } else {
            impar = '#FFFFFF';
        }

        body.push(
            [
                {
                    colSpan: 2,
                    text: 'Totais: ',
                    alignment: 'right',
                    fillColor: impar,
                    style: 'dadosHeader'
                }, '', // 1..2
                this.getPrintableTime(totalHeDiurna, impar), // 3
                this.getPrintableTime(totalHedIntra, impar), // 4
                this.getPrintableTime(totalHedDSR, impar), // 5
                this.getPrintableTime(totalHedFeriado, impar), // 6
                this.getPrintableTime(totalHeNoturna, impar), // 7
                this.getPrintableTime(totalHenIntra, impar), // 8
                this.getPrintableTime(totalHenDSR, impar), // 9
                this.getPrintableTime(totalHenFeriado, impar), // 10
                this.getPrintableTime(totalHeInter, impar), // 11
                this.getPrintableTime(totalTeDiurno, impar), // 12
                this.getPrintableTime(totalTeNoturno, impar), // 13
                this.getPrintableTime(totalAddNoturno, impar), // 14
                this.getPrintableTime(totalHfAbonadas, impar), // 15
                this.getPrintableTime(totalHfDescontadas, impar), // 16
                this.getPrintableTime(totalCompensado, impar, 2), // 17
                //this.getPrintableTime(undefined, impar) // 18

            ]
        );

        // impar = !impar;

        body.push(
            [
                {
                    colSpan: 2,
                    text: 'Hora Ficta Noturna: ',
                    alignment: 'right',
                    fillColor: impar,
                    style: 'dadosHeader'
                }, '', // 1..2
                '', // 3
                '', // 4
                '', // 5
                '', // 6
                this.getPrintableTime(totalHeNoturna * 1.142857, impar), // 7
                '', // miliSecsToHHmm(totalHenIntra * 1.1428, impar), // 8
                '', // miliSecsToHHmm(totalHenDSR * 1.1428, impar), // 9
                '', // miliSecsToHHmm(totalHenFeriado * 1.1428, impar), // 10
                '', // 11
                '', // 12
                this.getPrintableTime(totalTeNoturno * 1.142857, impar), // 13
                this.getPrintableTime(totalAddNoturno * 1.142857, impar), // 14
                '', // 15
                '', // 16
                '', // 17
                '' // 18

            ]
        );

        body.push(
            [
                {
                    colSpan: 18,
                    stack: [
                        {
                            text: 'Declaro, nesta oportunidade, que concordo integral e inequivocamente, com o conteúdo apresentado neste relatório, sendo certo ter sido garantido pela empresa empregadora o direito a conferência dos dados.',
                            alignment: 'justify',
                            style: 'dados'
                        },
                        ' ',
                        {
                            text: 'Assinatura:____________________________________________________________________________________________________',
                            alignment: 'center',
                            style: 'dados'
                        },
                        {
                            text: row.motorista.nome + ' ' + row.motorista.sobrenome,
                            alignment: 'center',
                        },
                    ]
                }, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''
            ]);

        return body;
    }

    printLogRows(row, selectedBranch): { dd: any, totalJornada: number, totalRefeicao: number, totalCarregamento: number, totalDescarregamento: number, totalDirecao: number, totalDescanso: number } {
        let lSze = row.linhas.length;
        let totalJornada: number = 0;
        let totalRefeicao: number = 0;
        let totalCarregamento: number = 0;
        let totalDescarregamento: number = 0;
        let totalDirecao: number = 0;
        let totalDescanso: number = 0;
        let body = new Array();

        for (var i = 0; i < lSze; i++) {
            let dataRow = [];
            let linha = row.linhas[i];

            var proxima = null;
            var mesmaData = false;

            if (i + 1 < lSze) {
                proxima = row.linhas[i + 1];

                var dtAtual = this.utils.formatDate(linha.data, 'DD/MM/YYYY');
                var dtProxi = this.utils.formatDate(proxima.data, 'DD/MM/YYYY');

                mesmaData = (dtAtual === dtProxi);
            }

            dataRow.push(this.getPrintableDate(linha.data, 'DD/MM/YYYY'));    // c01
            dataRow.push(this.getPrintableDate(linha.data, 'ddd'));  // c02

            var tipoJornada = linha.tipoJornada;

            if (tipoJornada) {
                if (mesmaData) {
                    continue;
                }

                dataRow.push(this.getPrintableText(tipoJornada));    // c03
                dataRow.push(this.getPrintableText(tipoJornada));    // c04
                dataRow.push(this.getPrintableText(tipoJornada));    // c05
                dataRow.push(this.getPrintableText(tipoJornada));    // c06
                dataRow.push(this.getPrintableText(tipoJornada));    // c07
                dataRow.push(this.getPrintableText(tipoJornada));    // c08
                dataRow.push(this.getPrintableText(tipoJornada));    // c09

                if (selectedBranch.habilitarColunaCarregamento) {
                    dataRow.push(this.getPrintableText(tipoJornada));    // c10
                    dataRow.push(this.getPrintableText(tipoJornada));    // c11
                }
        
                if (selectedBranch.habilitarColunaDescarregamento) {
                    dataRow.push(this.getPrintableText(tipoJornada));    // c12
                    dataRow.push(this.getPrintableText(tipoJornada));    // c13
                }
        
                if (selectedBranch.habilitarColunaFiscalizacao) {
                    dataRow.push(this.getPrintableText(tipoJornada));    // c14
                    dataRow.push(this.getPrintableText(tipoJornada));    // c15
                }

                dataRow.push(this.getPrintableText(tipoJornada));    // c16
            } else {
                dataRow.push(this.getPrintableInstant(linha.jornadaInicio, 'HH:mm:ss')); // c03

                totalJornada = this.sumarize(totalJornada, linha.jornadaFim, linha.jornadaInicio);

                dataRow.push(this.getPrintableInstant(linha.refeicaoInicio, 'HH:mm:ss')); // c04 // TODO: looping
                dataRow.push(this.getPrintableInstant(linha.refeicaoFim, 'HH:mm:ss'));   // c05 // TODO: looping
                totalRefeicao = this.sumarize(totalRefeicao, linha.refeicaoFim, linha.refeicaoInicio);

                dataRow.push(this.printInstants(linha.direcaoInicio)); // c08
                dataRow.push(this.printInstantsAndSummarize(linha.direcaoFim, totalDirecao, linha.direcaoInicio));

                dataRow.push(this.printInstants(linha.descansoInicio)); // c08
                dataRow.push(this.printInstantsAndSummarize(linha.descansoFim, totalDescanso, linha.descansoInicio));

                if (selectedBranch.habilitarColunaCarregamento) {
                    dataRow.push(this.printInstants(linha.carregamentoInicio)); // c10
                    dataRow.push(this.printInstantsAndSummarize(linha.carregamentoFim, totalCarregamento, linha.carregamentoInicio)); //c11
                }
        
                if (selectedBranch.habilitarColunaDescarregamento) {
                    dataRow.push(this.printInstants(linha.descarregamentoInicio)); // c12
                    dataRow.push(this.printInstantsAndSummarize(linha.descarregamentoFim, totalDescarregamento, linha.descarregamentoInicio)); //c13
                }
        
                if (selectedBranch.habilitarColunaFiscalizacao) {
                    dataRow.push(this.printInstants(linha.fiscalizacaoInicio)); //c14
                    dataRow.push(this.printInstants(linha.fiscalizacaoFim)); // c15
                }

                dataRow.push(this.getPrintableInstant(linha.jornadaFim, 'HH:mm:ss'));    // c16
            }
            body.push(dataRow);
        };

        return {
            dd: body,
            totalJornada: totalJornada,
            totalRefeicao: totalRefeicao,
            totalCarregamento: totalCarregamento,
            totalDescarregamento: totalDescarregamento,
            totalDescanso: totalDescanso,
            totalDirecao: totalDirecao
        }
    }

    printJustificativasTable(justificativas) {
        var body = [];
        var correcaoRegistro = 'Correção de Registro';
        if (justificativas.length > 1) {
            correcaoRegistro = 'Correções de Registros';
        }

        body.push([{
            colSpan: 4,
            text: correcaoRegistro,
            alignment: 'center',
            fontSize: 14,
            bold: true
        }, '', '', ''],
            [
                { text: 'Errada', alignment: 'center', style: 'dadosHeader' }, // 1
                { text: 'Corrigida', alignment: 'center', style: 'dadosHeader' }, // 2
                { text: 'Tipo', alignment: 'center', style: 'dadosHeader' }, // 3
                { text: 'Justificativa', alignment: 'center', style: 'dadosHeader' } // 4
            ]
        );

        justificativas.forEach(j => {
            var dataRow = [];

            dataRow.push(this.getPrintableDate(j.original, 'DD/MM/YYYY HH:mm:ss')); // c01
            dataRow.push(this.getPrintableDate(j.data, 'DD/MM/YYYY HH:mm:ss'));    // c02
            dataRow.push(this.getPrintableText(j.tipoOriginal)); // c03
            dataRow.push(this.getPrintableText(j.justificativa));  // c04

            body.push(dataRow);
        });

        return body;
    }

    printJourneyCorrectionReport(row: JourneyLogReport, nonconformity, nomeEmpresa: string) {


        let dd = {
            header: {
                columns: [
                    { text: `${nomeEmpresa}.`, alignment: 'left', margin: [12, 15, 0, 0], style: 'pageHeader' }, // TODO: variavel no futuro (empresa.nome)
                    { text: 'RodoVia - ViaM', alignment: 'center', margin: [0, 15, 0, 0], style: 'pageHeader' }, // TODO: nome do sistema
                    { text: this.utils.getMonth(row.mesReferencia), alignment: 'right', margin: [0, 15, 12, 0], style: 'pageHeader' } // variavel periodo.mesReferencia
                ]
            },
            content: [

                this.printTableBodyCorrection(row, nonconformity),

                this.printJustificativas(row, true)

            ],
            footer: (currentPage, pageCount) => {
                return {
                    columns: [
                        { text: this.utils.formatDate(this.utils.now(), "DD/MM/YYYY HH:mm:ss"), alignment: 'left', margin: [12, 0, 0, 0] }, // trocar pelo moment
                        { text: '', alignment: 'center' },
                        { text: currentPage.toString() + ' de ' + pageCount, alignment: 'right', margin: [0, 0, 12, 0] }
                    ]
                }
            },
            pageOrientation: 'landscape',
            pageMargins: [12, 35, 12, 35],
            styles: {
                dados: {
                    fontSize: 8
                },
                dadosHeader: {
                    fontSize: 9,
                    bold: true
                },
                pageHeader: {
                    fontSize: 9,
                    bold: true
                }
            }
        }

        return dd;
    }

    printTableCorrection(row, nonconformity) {
        let body = this.printTableBodyCorrection(row, nonconformity);
        let dd = {
            table: {
                headerRows: 3,
                dontBreakRows: true,
                widths: [65, 90, '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'], // 10
                body: body
            }
        };
        return dd;
    }

    printTableBodyCorrection(row, nonconformity) {
        var body = this.printJourneyCorrectionTableHeader(row, nonconformity);
        return body;

    }

    printJourneyCorrectionTableHeader(row, nonconformity) {
        return [
            this.printDriverHeader(row, 16, 'Correção de Registros', nonconformity),
        ]
    }

    printJourneyCalculationReport(nomeEmpresa: string, row: JourneyCalculationReport, journeyLog: JourneyLogReport, nonconformity: boolean, selectedBranch: Branch) {
        let dd = {
            header: {
                columns: [
                    { text: `${nomeEmpresa}.`, alignment: 'left', margin: [12, 15, 0, 0], style: 'pageHeader' }, // TODO: variavel no futuro (empresa.nome)
                    { text: 'RodoVia - ViaM', alignment: 'center', margin: [0, 15, 0, 0], style: 'pageHeader' }, // TODO: nome do sistema
                    { text: this.utils.getMonth(row.mesReferencia), alignment: 'right', margin: [0, 15, 12, 0], style: 'pageHeader' } // variavel periodo.mesReferencia
                ]
            },
            content: [
                this.getReportContent(row, journeyLog, nonconformity, selectedBranch)
            ],
            footer: (currentPage, pageCount) => {
                return {
                    columns: [
                        { text: this.utils.formatDate(this.utils.now(), "DD/MM/YYYY HH:mm:ss"), alignment: 'left', margin: [12, 0, 0, 0] }, // trocar pelo moment
                        { text: '', alignment: 'center' },
                        { text: currentPage.toString() + ' de ' + pageCount, alignment: 'right', margin: [0, 0, 12, 0] }
                    ]
                }
            },
            pageOrientation: 'landscape',
            // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
            pageMargins: [5, 35, 5, 35],
            styles: {
                dados: {
                    fontSize: 7
                },
                dadosHeader: {
                    fontSize: 7,
                    // noWrap: true,
                    bold: true
                },
                pageHeader: {
                    fontSize: 12,
                    bold: true
                },
                resumoHoras: {
                    margin: [0, 0, 0, 0],
                    padding: 0,
                }
            }
        }

        return dd;
    }

    printJourneyLogReport(row: JourneyLogReport, nonconformity, nomeEmpresa: string, correcoes: boolean, selectedBranch: Branch, reportDetalhes) {
        let table = this.printTableLog(row, nonconformity, selectedBranch);

        var dd = {
            header: {
                columns: [
                    { text: `${nomeEmpresa}.`, alignment: 'left', margin: [12, 15, 0, 0], style: 'pageHeader' }, // TODO: variavel no futuro (empresa.nome)
                    { text: 'RodoVia - ViaM', alignment: 'center', margin: [0, 15, 0, 0], style: 'pageHeader' }, // TODO: nome do sistema
                    { text: this.utils.getMonth(row.mesReferencia), alignment: 'right', margin: [0, 15, 12, 0], style: 'pageHeader' } // variavel periodo.mesReferencia
                ]
            },
            content: [
                table.dd,
                this.printSignature(row, reportDetalhes.listaDetalheDaJornadaDireto),
                this.printJustificativas(row, correcoes)
            ],
            footer: (currentPage, pageCount) => {
                return {
                    columns: [
                        { text: this.utils.formatDate(this.utils.now(), "DD/MM/YYYY HH:mm:ss"), alignment: 'left', margin: [12, 0, 0, 0] }, // trocar pelo moment
                        { text: '', alignment: 'center' },
                        { text: currentPage.toString() + ' of ' + pageCount, alignment: 'right', margin: [0, 0, 12, 0] }
                    ]
                }
            },
            pageOrientation: 'landscape',
            pageMargins: [12, 35, 12, 35],
            styles: {
                dados: {
                    fontSize: 8
                },
                dadosHeader: {
                    fontSize: 9,
                    bold: true
                },
                pageHeader: {
                    fontSize: 9,
                    bold: true
                },
                resumoHoras: {
                    margin: [1, 1, 1, 1]
                }
            }
        }

        return dd;
    }


    printEspelhoPontoReport(relatorioApuracaoJornada, logo) {

        var dd = {
            header: [
                {
                    columns: [
                        { image: `data:image/jpeg;base64,${logo}`, width: 80, height: 40, margin: [15, 15, 0, 0] },
                        { lineHeight: 2, text: 'ESPELHO DE PONTO ELETRÔNICO', alignment: 'center', margin: [0, 15, 0, 0], style: 'pageHeader' },
                        { text: '', width: '15%' }
                    ]
                },
                {
                    columns: [

                        { width: '70%', text: 'Período: ', bold: true, alignment: 'right' },
                        { width: '30%', text: relatorioApuracaoJornada.campo09 },
                    ],
                    columnGap: 6
                },
                {
                    columns: [
                        { width: '70%', text: 'Emitido em: ', bold: true, alignment: 'right' },
                        { width: '30%', text: relatorioApuracaoJornada.campo08 },

                    ],
                    columnGap: 6
                },
            ],
            content: [
                this.getReportContentEspelhoPonto(relatorioApuracaoJornada)
            ],
            footer: (currentPage, pageCount) => {
                return {
                    columns: [
                        { text: this.utils.formatDate(this.utils.now(), "DD/MM/YYYY HH:mm:ss"), alignment: 'left', margin: [12, 0, 0, 0] }, // trocar pelo moment
                        { text: '', alignment: 'center' },
                        { text: currentPage.toString() + ' of ' + pageCount, alignment: 'right', margin: [0, 0, 12, 0] }
                    ]
                }
            },
            pageOrientation: 'landscape',
            pageMargins: [40, 100, 40, 60],
            styles: {
                dados: {
                    fontSize: 8
                },
                dadosHeader: {
                    fontSize: 9,
                    bold: true
                },
                pageHeader: {
                    fontSize: 14,
                    bold: true
                },
                negrito: {
                    bold: true
                }
            }
        }

        return dd;
    }

    printJustificativas(row, correcoes) {
        if (correcoes) {
            let justificativas = this.retrieveJustificativas(row);

            var jSze = justificativas.length;
            if (jSze < 1) {
                return {};
            };
            var objs: any = [{
                table: {
                    headerRows: 2,
                    dontBreakRows: true,
                    widths: [90, 90, 180, '*'],
                    body: this.printJustificativasTable(justificativas)
                }
            }];
            objs.push(this.printJustificativaSignature(row));

            return objs;
        }
    }

    printJustificativaSignature(row) {
        return {
            table: {
                dontBreakRows: true,
                widths: ['*'],
                body: [[{
                    stack: [
                        {
                            text: 'Declaro que autorizei a empresa empregadora a proceder às alterações demonstradas nesse documento, vez que correspondem a minha real jornada de trabalho, razão pela qual estou de acordo e valido a correção registrada.',
                            alignment: 'justify',
                            style: 'dados'
                        },
                        ' ',
                        {
                            text: 'Assinatura:____________________________________________________________________________________________________',
                            alignment: 'center',
                            style: 'dados'
                        },
                        {
                            text: row.motorista.nome + ' ' + row.motorista.sobrenome,
                            alignment: 'center',
                        },
                    ]
                }
                ]]
            }
        };
    }

    printSignature(dadosAssinatura, listaDetalheDaJornada) {
        const name = typeof dadosAssinatura === "string" ?
            dadosAssinatura : dadosAssinatura.motorista.nome + ' ' + dadosAssinatura.motorista.sobrenome;
        
        var body_horas = [];

        if (listaDetalheDaJornada) {
            body_horas.push([{
                colSpan: 3,
                text: 'Resumo de Horas',
                alignment: 'center',
                fontSize: 10,
                bold: true,
            }, '', ''],
                [
                    { text: 'Descrição', alignment: 'center', style: 'dadosHeader' }, //2
                    { text: 'Hora', alignment: 'center', style: 'dadosHeader' }, //3
                    { text: 'Falta', alignment: 'center', style: 'dadosHeader' } //3
                ]
            );

            listaDetalheDaJornada.forEach(r => {
                var dataRow = [];
                dataRow.push(this.getPrintableText(r.descricao)); //c02
                dataRow.push(this.getPrintableText(r.hora));  //c03
                dataRow.push(this.getPrintableText(r.falta));  //c03
                body_horas.push(dataRow);
            });

            return {
                table: {
                    dontBreakRows: true,
                    widths: [600,'*'],
    
                    body: [
                        [
                            {
                                stack: [
                                    {
                                        text: 'Declaro, sob as penas da lei, para todos os fins de direito, nos termos do art. 2º, inciso V, alínea “b” e art. 235-C, § 14, ambos da Lei 13.103/2015, que os horários apontados neste relatório, cuja exatidão são de minha responsabilidade, representam minha real jornada de trabalho, estando, em sua integralidade, em conformidade com os horários registrados exclusivamente por mim, lançados pessoalmente no diário individual de bordo eletrônico, através de senha pessoal e intransferível, bem como nos documentos e informações sempre, por mim, fornecidos à empresa empregadora.',
                                        alignment: 'justify',
                                        style: 'dados'
                                    },
                                    ' ',
                                    {
                                        text: 'Declaro, ainda, que me foi dada ampla oportunidade de consulta e conferência de todos os horários por mim lançados, ficando constatado que não houve qualquer divergência de registro.  Desta forma, concordo com a apuração feita pela empregadora para proceder ao pagamento correspondente.',
                                        alignment: 'justify',
                                        style: 'dados'
                                    },
                                    ' ',
                                    {
                                        text: 'Confirmo, nesta oportunidade, ter recebido da empresa empregadora todos os treinamentos necessários para a correta utilização do diário individual de bordo eletrônico, além de treinamento específico relativo a lei que regulamenta o exercício da profissão de motorista, com destaque para o cumprimento dos intervalos obrigatórios de descanso, repouso e refeição.',
                                        alignment: 'justify',
                                        style: 'dados'
                                    },
                                    ' ',
                                    {
                                        text: 'Assinatura:____________________________________________________________________________________________________',
                                        alignment: 'center',
                                        style: 'dados'
                                    },
                                    {
                                        text: name,
                                        alignment: 'center',
                                    },
                                ]
                            },
                            {
                                style: 'resumoHoras',
                                table: {
                                    dontBreakRows: true,
                                    style: 'resumoHoras',
                                    body: body_horas,
                                }
                            }
                        ]
                    ]
                }
            }
        } else {
            return {
                table: {
                    dontBreakRows: true,
                    body: [
                        [
                            {
                                stack: [
                                    {
                                        text: 'Declaro, sob as penas da lei, para todos os fins de direito, nos termos do art. 2º, inciso V, alínea “b” e art. 235-C, § 14, ambos da Lei 13.103/2015, que os horários apontados neste relatório, cuja exatidão são de minha responsabilidade, representam minha real jornada de trabalho, estando, em sua integralidade, em conformidade com os horários registrados exclusivamente por mim, lançados pessoalmente no diário individual de bordo eletrônico, através de senha pessoal e intransferível, bem como nos documentos e informações sempre, por mim, fornecidos à empresa empregadora.',
                                        alignment: 'justify',
                                        style: 'dados'
                                    },
                                    ' ',
                                    {
                                        text: 'Declaro, ainda, que me foi dada ampla oportunidade de consulta e conferência de todos os horários por mim lançados, ficando constatado que não houve qualquer divergência de registro.  Desta forma, concordo com a apuração feita pela empregadora para proceder ao pagamento correspondente.',
                                        alignment: 'justify',
                                        style: 'dados'
                                    },
                                    ' ',
                                    {
                                        text: 'Confirmo, nesta oportunidade, ter recebido da empresa empregadora todos os treinamentos necessários para a correta utilização do diário individual de bordo eletrônico, além de treinamento específico relativo a lei que regulamenta o exercício da profissão de motorista, com destaque para o cumprimento dos intervalos obrigatórios de descanso, repouso e refeição.',
                                        alignment: 'justify',
                                        style: 'dados'
                                    },
                                    ' ',
                                    {
                                        text: 'Assinatura:____________________________________________________________________________________________________',
                                        alignment: 'center',
                                        style: 'dados'
                                    },
                                    {
                                        text: name,
                                        alignment: 'center',
                                    },
                                ]
                            }
                        ]
                    ]
                }
            }
        }
    }

    printTableLog(row, nonconformity, selectedBranch) {
        let body = this.printTableBodyLog(row, nonconformity, selectedBranch);

        let widths = ['7%', '9%', '6%', '6%', '6%', '6%', '6%', '6%', '6%']; // 9 colunas fixas
        let totalExtraWidth = 0;
        let colunasExtras = 0;

        if (selectedBranch.habilitarColunaCarregamento) {
            widths.push('6%');//10
            widths.push('6%');//11
        } else {
            totalExtraWidth += 12;
        }

        if (selectedBranch.habilitarColunaDescarregamento) {
            widths.push('6%');//12
            widths.push('6%');//13
        } else {
            totalExtraWidth += 12;
        }

        if (selectedBranch.habilitarColunaFiscalizacao) {
            widths.push('6%');//14
            widths.push('6%');//15
        } else {
            totalExtraWidth += 12;
        }

        widths.push('6%'); //16

        if (totalExtraWidth > 0) {
            let numColunasFixas = widths.length;
            let extraPorColuna = totalExtraWidth / numColunasFixas;

            widths = widths.map(width => {
                let valor = parseFloat(width) + extraPorColuna;
                return `${valor.toFixed(2)}%`;
            });
        }

        let dd = {
            table: {
                headerRows: 3,
                dontBreakRows: true,
                widths: widths,
                body: body.dd
            }
        };
        return {
            dd: dd,
            totalCarregamento: body.totalCarregamento,
            totalDescarregamento: body.totalDescarregamento,
            totalJornada: body.totalJornada,
            totalRefeicao: body.totalRefeicao,
            totalDescanso: body.totalDescanso,
            totalDirecao: body.totalDirecao
        }
    }

    printTableBodyLog(row, nonconformity, selectedBranch) {
        var body = this.printJourneyLogTableHeader(row, nonconformity, selectedBranch);
        var logRows = this.printLogRows(row, selectedBranch);

        body = body.concat(logRows.dd);

        return {
            dd: body,
            totalCarregamento: logRows.totalCarregamento,
            totalDescarregamento: logRows.totalDescarregamento,
            totalJornada: logRows.totalJornada,
            totalRefeicao: logRows.totalRefeicao,
            totalDescanso: logRows.totalDescanso,
            totalDirecao: logRows.totalDirecao
        }
    }

    printInstants(instants: Instant[]) {
        let row = new Array();
        if (instants) {
            instants.forEach(i => {
                row.push(this.getPrintableInstant(i, 'HH:mm:ss'));
            });
        }
        return row;
    }

    retrieveJustificativas(row) {
        let justificativas = new Array();
        row.linhas.forEach(l => {
            this.checkInstant(l.jornadaInicio, justificativas);
            this.checkInstant(l.refeicaoInicio, justificativas);
            this.checkInstant(l.refeicaoFim, justificativas);
            l.direcaoInicio.forEach(d => {
                this.checkInstant(d, justificativas);
            });
            l.direcaoFim.forEach(d => {
                this.checkInstant(d, justificativas);
            });
            l.descansoInicio.forEach(d => {
                this.checkInstant(d, justificativas);
            });
            l.descansoFim.forEach(d => {
                this.checkInstant(d, justificativas);
            });
            l.carregamentoInicio.forEach(d => {
                this.checkInstant(d, justificativas);
            });
            l.carregamentoFim.forEach(d => {
                this.checkInstant(d, justificativas);
            });
            l.descarregamentoInicio.forEach(d => {
                this.checkInstant(d, justificativas);
            });
            l.descarregamentoFim.forEach(d => {
                this.checkInstant(d, justificativas);
            })
            l.fiscalizacaoInicio.forEach(d => {
                this.checkInstant(d, justificativas);
            });
            l.fiscalizacaoFim.forEach(d => {
                this.checkInstant(d, justificativas);
            });
            l.compensacaoDeHoras.forEach(d => {
                this.checkInstant(d, justificativas);
            });
            this.checkInstant(l.jornadaFim, justificativas);
        });
        return justificativas;
    }

    retrieveDriverPhoto(driver: Driver) {
        var result = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAABQCAIAAADKqIEEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAVUlEQVRo3u3OAQ0AAAgDILV/55vjGyRgk0ybm0LS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tK96QfwzAOdhhiBNQAAAABJRU5ErkJggg==';

        if (driver.imagemId && driver.imagemId.dados) {
            result = driver.imagemId.dados;
        }

        return result;
    }

    printJourneyCalculationHeader1(row) {
        return [
            { colSpan: 2, rowSpan: 2, text: 'Informações Gerais', alignment: 'center', style: 'dadosHeader' }, '', // 1..2
            { colSpan: 9, text: 'Horas Extras', alignment: 'center', style: 'dadosHeader' }, '', '', '', '', '', '', '', '', // 3..11
            { colSpan: 2, rowSpan: 2, text: 'Tempo de Espera', alignment: 'center', style: 'dadosHeader' }, '', // 12..13
            { rowSpan: 3, text: 'Adicional Noturno', alignment: 'center', style: 'dadosHeader' }, // 14
            { colSpan: 2, text: 'Horas Faltosas', alignment: 'center', style: 'dadosHeader' }, '', // 15..16
            { colSpan: 2, rowSpan: 2, text: 'Folgas Compensadas', alignment: 'center', style: 'dadosHeader' }, '', // 17..18
        ]
    }

    printJourneyCalculationHeader2(row) {
        return [
            '', '', // 1..2
            { colSpan: 4, text: 'Diurnas', alignment: 'center', style: 'dadosHeader' }, '', '', '', // 3..6
            { colSpan: 4, text: 'Noturnas', alignment: 'center', style: 'dadosHeader' }, '', '', '', // 7..10
            { rowSpan: 2, text: 'Interjornada', alignment: 'center', style: 'dadosHeader' }, // 11
            '', '', // 12..13
            '', // 14
            { rowSpan: 2, text: 'Abonadas', alignment: 'center', style: 'dadosHeader' }, // 15
            { rowSpan: 2, text: 'Descontadas', alignment: 'center', style: 'dadosHeader' }, // 16
            '', '' //17..18
        ]
    }

    printJourneyCalculationHeader3(row) {
        return [
            { text: 'Data', alignment: 'center', style: 'dadosHeader' }, // 01
            { text: 'Dia', alignment: 'center', style: 'dadosHeader' }, // 02
            { text: 'Diurnas', alignment: 'center', style: 'dadosHeader' }, // 03
            { text: 'Intrajornada', alignment: 'center', style: 'dadosHeader' }, // 04
            { text: 'DSR', alignment: 'center', style: 'dadosHeader' }, // 05
            { text: 'Feriado', alignment: 'center', style: 'dadosHeader' }, // 06
            { text: 'Noturnas', alignment: 'center', style: 'dadosHeader' }, // 07
            { text: 'Intrajornada', alignment: 'center', style: 'dadosHeader' }, // 08
            { text: 'DSR', alignment: 'center', style: 'dadosHeader' }, // 09
            { text: 'Feriado', alignment: 'center', style: 'dadosHeader' }, // 10
            '', // 11
            { text: 'Diurno', alignment: 'center', style: 'dadosHeader' }, // 12
            { text: 'Noturno', alignment: 'center', style: 'dadosHeader' }, // 13
            '', // 14
            '', '', // 15..16
            { colSpan: 2, text: 'Horas compensadas', alignment: 'center', style: 'dadosHeader' } //17..18

        ]
    }


    printJourneyLogTableHeader(row, nonconformity, selectedBranch) {
        let numberOfCol = 10;
        if (selectedBranch.habilitarColunaCarregamento) {
            numberOfCol += 2;
        }

        if (selectedBranch.habilitarColunaDescarregamento) {
            numberOfCol += 2;
        }

        if (selectedBranch.habilitarColunaFiscalizacao) {
            numberOfCol += 2;
        }

        return [
            this.printDriverHeader(row, numberOfCol, 'Registro de  Jornada', nonconformity),
            this.printJourneyLogHeader(row, selectedBranch),
            this.printJourneyLogDayHeader(row, selectedBranch)
        ]
    }

    printDriverHeader(row, numberOfCol, relatoryName, nonconformity) {
        let result = new Array();

        result.push({
            colSpan: numberOfCol,
            stack: [
                {
                    table: {

                        widths: [60, '*'],
                        body: [
                            [
                                {
                                    rowSpan: 4,
                                    image: this.retrieveDriverPhoto(row.motorista),
                                    width: 60, height: 80, alignment: 'center'
                                },
                                { text: relatoryName, alignment: 'center', fontSize: 18, bold: true }
                            ],
                            ['', 'Motorista: ' + row.motorista.nome + ' ' + row.motorista.sobrenome], // variavel motorista.nome
                            ['', 'Período: ' + this.utils.formatDate(row.dataInicio, 'DD/MM/YYYY') + ' à ' + this.utils.formatDate(row.dataFim, 'DD/MM/YYYY')],
                            ['', 'Horário: ' + row.identificacaoTurno] // variavel motorista.turnosId.identificacao //  + '; Considerar Inconformidade: ' + (nonconformity ? 'Sim' : 'Não')
                        ]
                    }
                }
            ]
        })

        while (result.length < numberOfCol) {
            result.push('');
        }

        return result;
    }

    printJourneyLogHeader(row, selectedBranch) {
        let colunas = [
            { colSpan: 2, text: 'Informações Gerais', alignment: 'center', style: 'dadosHeader' }, '', // 1 e 2
            { text: 'Jornada', alignment: 'center', style: 'dadosHeader' }, // 3
            { colSpan: 2, text: 'Refeição', alignment: 'center', style: 'dadosHeader' }, '', // 4 e 5
            { colSpan: 2, text: 'Direção', alignment: 'center', style: 'dadosHeader' }, '', // 6 e 7
            { colSpan: 2, text: 'Descanso Obrigatório', alignment: 'center', style: 'dadosHeader' }, '', // 8  e 9
        ];

        if (selectedBranch.habilitarColunaCarregamento) {
            colunas.push({ colSpan: 2, text: 'Carregamento', alignment: 'center', style: 'dadosHeader' }, '',); // 10 e 11
        }

        if (selectedBranch.habilitarColunaDescarregamento) {
            colunas.push({ colSpan: 2, text: 'Descarregamento', alignment: 'center', style: 'dadosHeader' }, '',); // 12 e 13
        }

        if (selectedBranch.habilitarColunaFiscalizacao) {
            colunas.push({ colSpan: 2, text: 'Fiscalização', alignment: 'center', style: 'dadosHeader' }, '',); // 14 e 15
        }
        
        colunas.push({ text: 'Jornada', alignment: 'center', style: 'dadosHeader' }); // 16

        return colunas;
    }

    printJourneyLogDayHeader(row, selectedBranch) {
        let colunas = [
            { text: 'Data', alignment: 'center', style: 'dadosHeader' }, // 01
            { text: 'Dia da Semana', alignment: 'center', style: 'dadosHeader' }, // 02
            { text: 'Início', alignment: 'center', style: 'dadosHeader' }, // 03
            { text: 'Início', alignment: 'center', style: 'dadosHeader' }, // 04
            { text: 'Fim', alignment: 'center', style: 'dadosHeader' }, // 05
            { text: 'Início', alignment: 'center', style: 'dadosHeader' }, // 06
            { text: 'Fim', alignment: 'center', style: 'dadosHeader' }, // 07
            { text: 'Início', alignment: 'center', style: 'dadosHeader' }, // 08
            { text: 'Fim', alignment: 'center', style: 'dadosHeader' }, // 09
        ];

        if (selectedBranch.habilitarColunaCarregamento) {
            colunas.push({ text: 'Início', alignment: 'center', style: 'dadosHeader' },); // 10
            colunas.push({ text: 'Fim', alignment: 'center', style: 'dadosHeader' },); // 11
        }

        if (selectedBranch.habilitarColunaDescarregamento) {
            colunas.push({ text: 'Início', alignment: 'center', style: 'dadosHeader' },); // 12
            colunas.push({ text: 'Fim', alignment: 'center', style: 'dadosHeader' },); // 13
        }

        if (selectedBranch.habilitarColunaFiscalizacao) {
            colunas.push({ text: 'Início', alignment: 'center', style: 'dadosHeader' },); // 14
            colunas.push({ text: 'Fim', alignment: 'center', style: 'dadosHeader' },); // 15
        }
        
        colunas.push({ text: 'Fim', alignment: 'center', style: 'dadosHeader' },); // 16

        return colunas;
    }


    getReportTomadaDeForca(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarrelatoriotomadaforca";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno.url;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportEspelhoPonto(filtro: FiltroEspelhoPonto) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/apuracao-jornadas";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    const retorno = response.json()
                    return retorno;
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportCompreensorCamaraFria(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarrelatoriocompreensor";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno.url;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportDisponibilidade(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarrelatoriodisponibilidade";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno.url;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportDisponibilidadeGrafico(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarrelatoriodisponibilidadegrafico";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno.disponibilidadeFisica;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportEvento(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarrelatorioevento";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno.url;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportHorimetro(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarrelatoriohorimetro";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno.url;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportPercurso(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarrelatoriopercurso";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno.url;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }


    getReportEquipamentoPortatil(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarrelatorioequipamentoportatil";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno.url;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportVelocidade(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarrelatoriovelocidade";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno.url;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportDiariaMotorista(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarRelatorioDiariaMotorista";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno.url;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportTempoTransporteGrafico(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarTempoTransporteGrafico";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportVelocidadeParaRelatorio(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarVelocidadeParaRelatorio";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }


    getReportTempoCarregamentoGrafico(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarTempoCarregamentoGrafico";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportRelatorioPercursoDetalhado(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarRelatorioPercursoDetalhado";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportRelatorioEventoSensoresDetalhado(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarRelatorioEventoSensoresDetalhado";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportPresencaDeEquipamentos(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarrelatoriopresencadeequipamentos";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno.url;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportTransporteEquipamento(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarrelatoriotransportedeequipamentos";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno.url;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }


    getReportSensorBetoneira(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarsensorbetoneira";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno.url;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }


    getGraficoProducaoEquipamento(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscargraficoproducaoequipamento";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno.listaInformacaoRelatorio;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getNumeroDeViagem(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarNumeroDeViagem";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno.numeroDeViagemRelatorio;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportDetalhesTelemetria(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarDetalhesTelemetria";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    return retorno;
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportSensorPorta(filtro: Filtro) {
        try {
            filtro.idCliente = this.company;
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarSensorPorta";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportReferenciaLocal(filtro: Filtro) {
        try {
            filtro.idCliente = this.company;
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarRelatorioReferenciaLocal";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportRelatorioProdutividadeMotorista(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarRelatorioProdutividade";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno.url;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportRelatorioProdutividadeVeiculo(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarRelatorioProdutividadeVeiculo";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno.url;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportReferenciaLocalDetalhes(filtro: Filtro) {
        try {
            filtro.idCliente = this.company;
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarRelatorioReferenciaLocalDetalhes";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }


    getReportPontosPercorridos(filtro: Filtro) {
        try {
            filtro.idCliente = this.company;
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarPontosPercorridos";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }


    getReportRelatorioProdutividadeMotoristaAdm(filtro: Filtro) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarProdutividadeMotorista";
            var json = JSON.stringify(filtro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno.url;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportPeriodoParado(tempoPeriodoParado: any) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/buscarPeriodoParadoNoLocal";
            var json = JSON.stringify(tempoPeriodoParado);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno = response.json() as any;
                    return retorno;
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportPeriodoParadoDetalhe(tempoPeriodoParado: any) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/gerarRelatorioPeriodoParado";
            var json = JSON.stringify(tempoPeriodoParado);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno = response.json() as any;
                    if (retorno) {
                        return retorno;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    getReportTotalizadorHoras(totalizadorFiltro: any) {
        try {
            var ulrRelatorio = environment.baseURL + "App/v2/web/rel/gerarRelatorioResumoJornada";
            var json = JSON.stringify(totalizadorFiltro);
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno = response.json() as any;
                    if (retorno) {
                        return retorno;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    ExportacaoGradeJornada(idEmpresa: number, reqBody: any) {
        try {
            var url = environment.baseURL + `App/v2/web/jornadas/${idEmpresa}/exportacao`;
            return this.http
                .post(url, reqBody, { headers: this.headers })
                .toPromise()
                .then(response => {
                    if (response) {
                        return response;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    };

    RelatorioPercursoVelocidade(reqBody: any) {
        try {
            const url = environment.baseURL + `App/v2/web/rel/buscarPontosPercorridos`;
            return this.http
                .post(url, reqBody, { headers: this.headers })
                .toPromise()
                .then(response => {
                    if (response) {
                        return response;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    RelatorioPercursoVelocidadeRastreadorPortatil(reqFiltro: any) {
        try {
            const url = environment.baseURL + `App/v2/web/rel/percurso-rastreadores-portateis?filtro=${reqFiltro}`;
            return this.http
                .get(url, { headers: this.headers })
                .toPromise()
                .then(response => {
                    if (response) {
                        return response;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }


    async getReportRelatorioDetalhesIncoformidadeViam(filtro: Filtro) {
        try {
            var json = JSON.stringify(filtro);
            const bodyEncoded = encodeURIComponent(json);
            var ulrRelatorio = environment.baseURL + 'App/v2/web/rel/detalheIncormidadeViam';
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }


    async getReportRelatorioConsultaLocaisPercorridos(filtro: Filtro) {
        try {
            var json = JSON.stringify(filtro);
            const bodyEncoded = encodeURIComponent(json);
            var ulrRelatorio = environment.baseURL + 'App/v2/web/rel/consultarLocaisPercorridos';
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorio = response.json() as RetornoRelatorio;
                    if (retorno) {
                        return retorno;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }


    async getReportRelatorioDirecaoVersusPlaca(filtro: Filtro) {
        try {
            var json = JSON.stringify(filtro);
            const bodyEncoded = encodeURIComponent(json);
            var ulrRelatorio = environment.baseURL + 'App/v2/web/rel/buscarDirecaoVersusPlaca';
            return this.http
                .post(ulrRelatorio, json, { headers: this.headers })
                .toPromise()
                .then(response => {
                    var retorno: RetornoRelatorioDirecaoVersusPlaca = response.json() as RetornoRelatorioDirecaoVersusPlaca;
                    if (retorno) {
                        return retorno;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    ExportacaoTempoDirecao(encodedBody: any) {
        try {
            const url = environment.baseURL + `App/v2/web/rel/tempo-direcao-motoristas?filtro=${encodedBody}`;
            return this.http
                .get(url, { headers: this.headers })
                .toPromise()
                .then(response => {
                    if (response) {
                        return response;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

    async getIntegracaoSllic(encodedBody: string) {
        try {
            const url = environment.baseURL + `App/v2/web/rel/sliic-dados?filtro=${encodedBody}`;
            return await this.http
                .get(url, { headers: this.headers })
                .toPromise()
                .catch(this.handleError);
        } catch (error) {
            return null;
        }
    }

    getHistoricoAlteracoes(idEmpresa: number, dataHora: any, idVeiculos: number[], idRotas: number[]) {
        try {
            const json = {
                idCliente: idEmpresa,
                dataInicial: dataHora,
                listaRota: idRotas,
                listaVeiculo: idVeiculos,
            };
            const url = `${environment.baseURL}App/v2/web/rel/historico-veiculos-rotas?filtro=${new URLSearchParams(JSON.stringify(json)).toString()}`;
            return this.http
                .get(url, { headers: this.headers })
                .toPromise()
                .then(resp => {
                    var retorno = resp.json() as any;
                    return retorno.response;
                })
                .catch(this.handleError)
        } catch (error) {
            console.error(error);
        }
    }

    getHistoricoExportacaoGradeJornada(idEmpresa: number, layout: number, page: number, size: number) {
        try {
            var url = environment.baseURL +
                `App/v2/web/jornadas/${idEmpresa}/historico-exportacoes/${layout}?page=${page}&size=${size}`;
            return this.http
                .get(url, { headers: this.headers })
                .toPromise()
                .then(resp => {
                    var retorno = resp.json() as any;
                    return retorno.response;
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    };

    protected handleError(error: any): Promise<any> {
        console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
        return Promise.reject(error.message || error);
    }


    getReportContent(row, journeyLog, nonconformity, selectedBranch) {
        let result = new Array();

        result.push(this.printJourneyCalculationTable(row, nonconformity));
        if (journeyLog) {
            result.push({ text: '', margin: [0, 0, 0, 0], pageBreak: 'before' });
            result.push(this.printTableLog(journeyLog, nonconformity, selectedBranch).dd);
            result.push(this.printSignature(journeyLog, row.listaDetalheDaJornadaDireto));
            result.push(this.printJustificativas(journeyLog, true));


        }

        if (row.listaDeResumoDeJornada || row.listaDetalheDaJornada) {
            result.push({ text: '', margin: [0, 0, 0, 0], pageBreak: 'before' });
        }

        if (row.listaDeResumoDeJornada) {
            result.push(this.printResumoJornada(row));
        }

        if (row.listaDetalheDaJornada) {
            result.push(this.printDetalheDaJornada(row));
        }

        result.push({ text: '', margin: [0, 0, 0, 0], pageBreak: 'after' });

        return result;
    }


    getReportContentEspelhoPonto(rowRelatorioApuracaoJornada) {
        let result = new Array();

        result.push(this.printReportEspelhoPontoCabecalho(rowRelatorioApuracaoJornada));

        if (rowRelatorioApuracaoJornada.listaDadosRelatorio) {
            result.push(this.printlistaInformacaoRelatorio(rowRelatorioApuracaoJornada));
        }

        if (rowRelatorioApuracaoJornada.listaCorrecoesRelatorio) {
            result.push(this.printCorrecoesPonto(rowRelatorioApuracaoJornada));
        }

        result.push(this.printSignature(rowRelatorioApuracaoJornada.campo03, null));

        result.push({ text: '', margin: [0, 0, 0, 0], pageBreak: 'after' });

        return result;
    }

    printlistaInformacaoRelatorio(row) {
        let listaResumo = row.listaDadosRelatorio;

        var jSze = listaResumo.length;
        if (jSze < 1) {
            return {};
        };
        var objs: any = [{
            table: {
                headerRows: 2,
                dontBreakRows: false,
                widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
                body: this.printEspelhoPontoTable(listaResumo)
            }
        }];

        return objs;
    }

    printEspelhoPontoTable(lista) {
        var body = [];

        body.push(
            [
                { text: 'Dia', alignment: 'center', style: 'dadosHeader' }, //1
                { text: 'Dia da semana', alignment: 'center', style: 'dadosHeader' }, //2
                { text: 'Entrada', alignment: 'center', style: 'dadosHeader' }, //3
                { text: 'Direções', alignment: 'center', style: 'dadosHeader' }, //4
                { text: 'Paradas', alignment: 'center', style: 'dadosHeader' }, //5
                { text: 'Carga/Descarga', alignment: 'center', style: 'dadosHeader' }, //6
                { text: 'Refeição', alignment: 'center', style: 'dadosHeader' }, //7
                { text: 'Fiscalização', alignment: 'center', style: 'dadosHeader' },//8
                { text: 'Saída', alignment: 'center', style: 'dadosHeader' },//9
                { text: 'Duração jornada', alignment: 'center', style: 'dadosHeader' },//10
            ]
        );

        lista.forEach(r => {
            var dataRow = [];
            dataRow.push(this.getPrintableText(r.campo01)); //c01
            dataRow.push(this.getPrintableText(r.campo02)); //c02
            dataRow.push(this.getPrintableText(r.campo03));  //c03
            dataRow.push(this.getPrintableText(r.campo04));  //c04
            dataRow.push(this.getPrintableText(r.campo05)); //c05
            dataRow.push(this.getPrintableText(r.campo06)); //c06
            dataRow.push(this.getPrintableText(r.campo07)); //c07
            dataRow.push(this.getPrintableText(r.campo08)); //c08
            dataRow.push(this.getPrintableText(r.campo09)); //c09
            dataRow.push(this.getPrintableText(r.campo10)); //c10

            body.push(dataRow);
        });
        return body;
    }

    printCorrecoesPonto(row) {
        let listaCorrecaoFolhaPonto = row.listaCorrecoesRelatorio;

        var jSze = listaCorrecaoFolhaPonto.length;
        if (jSze < 1) {
            return {};
        };
        var objs: any = [{
            table: {
                headerRows: 2,
                dontBreakRows: false,
                widths: [60, 60, 150, 60, 60],
                body: this.printDetalheCorrecaoFolhaPontoTable(listaCorrecaoFolhaPonto)
            }
        }];


        return objs;
    }

    printDetalheCorrecaoFolhaPontoTable(listaCorrecao) {
        var body = [];
        var correcaoRegistro = 'Correções ocorridas';

        body.push([{
            colSpan: 5,
            text: correcaoRegistro,
            alignment: 'center',
            fontSize: 14,
            bold: true
        }, '', '', '', ''],
            [
                { text: 'Data hora original', alignment: 'center', style: 'dadosHeader' }, //1
                { text: 'Data hora corrigida', alignment: 'center', style: 'dadosHeader' }, //2
                { text: 'Justificativa', alignment: 'center', style: 'dadosHeader' }, //2
                { text: 'Usuário que fez a correção', alignment: 'center', style: 'dadosHeader' }, //3
                { text: 'Horário correção', alignment: 'center', style: 'dadosHeader' } //3
            ]
        );

        listaCorrecao.forEach(r => {
            var dataRow = [];
            dataRow.push(this.getPrintableText(r.campo01)); //c01
            dataRow.push(this.getPrintableText(r.campo02)); //c02
            dataRow.push(this.getPrintableText(r.campo03)); //c02
            dataRow.push(this.getPrintableText(r.campo04));  //c03
            dataRow.push(this.getPrintableText(r.campo05));  //c03
            body.push(dataRow);
        });
        return body;
    }

    printReportEspelhoPontoCabecalho(rowRelatorioApuracaoJornada) {
        let result = new Array();
        const columnsRowTwo = new Array();
        const columnsRowThree = new Array();

        result.push({
            columns: [
                { width: '10%', text: 'Empresa:', alignment: 'right' },
                { width: '25%', text: rowRelatorioApuracaoJornada.campo01, alignment: 'left' },
                { width: '10%', text: 'CNPJ:', alignment: 'right' },
                { width: '20%', text: this.formatarCnpj(rowRelatorioApuracaoJornada.campo02), alignment: 'left' },
                { width: '10%', text: 'Tipo:', alignment: 'right' },
                { width: '30%', text: rowRelatorioApuracaoJornada.campo11, alignment: 'left' },
            ]
        });

        columnsRowTwo.push(
            { width: '10%', text: 'Nome:', alignment: 'right' },
            { width: '25%', text: rowRelatorioApuracaoJornada.campo03, alignment: 'left' },
            { width: '10%', text: 'Admissão:', alignment: 'right' },
            { width: '20%', text: rowRelatorioApuracaoJornada.campo05, alignment: 'left' },
        )

        columnsRowThree.push(
            { width: '10%', text: 'CTPS:', alignment: 'right' },
            { width: '25%', text: rowRelatorioApuracaoJornada.campo06, alignment: 'left' },
            { width: '10%', text: 'Cargo:', alignment: 'right' },
            { width: '20%', text: rowRelatorioApuracaoJornada.campo07, alignment: 'left' },
        )


        if (rowRelatorioApuracaoJornada.campo11.indexOf('Flexível') >= 0) {
            columnsRowTwo.push(
                { width: '15%', text: 'Horas Semanais:', alignment: 'right' },
                { width: '20%', text: rowRelatorioApuracaoJornada.campo12, alignment: 'left' }
            )

            result.push({
                columns: columnsRowTwo
            });

            result.push({
                columns: [
                    { width: '80%', text: 'Horas Mensais:', alignment: 'right' },
                    { width: '20%', text: rowRelatorioApuracaoJornada.campo13, alignment: 'left' }
                ]
            });

            columnsRowThree.push(
                { width: '15%', text: 'CPF:', alignment: 'right' },
                { width: '20%', text: rowRelatorioApuracaoJornada.campo04, alignment: 'left' }
            )

        } else {

            columnsRowTwo.push(
                { width: '35%', text: rowRelatorioApuracaoJornada.campo12, alignment: 'left' }
            )

            result.push({
                columns: columnsRowTwo
            });

            for (let index = 13; index <= 18; index++) {
                result.push({
                    columns: [
                        { width: '65%', text: '', alignment: 'left' },
                        { width: '35%', text: rowRelatorioApuracaoJornada[`campo${index}`], alignment: 'left' }
                    ]
                })
            }

            columnsRowThree.push(
                { width: '35%', text: `CPF:${rowRelatorioApuracaoJornada.campo04}`, alignment: 'left' },
            )
        }



        result.push({
            columns: columnsRowThree
        });

        return result;
    }


    printDetalheDaJornada(row) {
        let listaDetalheDaJornada = row.listaDetalheDaJornada;

        var jSze = listaDetalheDaJornada.length;
        if (jSze < 1) {
            return {};
        };
        var objs: any = [{
            table: {
                headerRows: 2,
                dontBreakRows: false,
                widths: [50, 50, 120, 50, 50],
                body: this.printDetalheDaJornadaTable(listaDetalheDaJornada)
            }
        }];
        //objs.push(this.printJustificativaSignature(row));

        return objs;
    }

    printResumoJornada(row) {
        let listaResumo = row.listaDeResumoDeJornada;

        var jSze = listaResumo.length;
        if (jSze < 1) {
            return {};
        };
        var objs: any = [{
            table: {
                headerRows: 2,
                dontBreakRows: false,
                widths: [50, 50, 50, '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
                body: this.printResumoJornadaTable(listaResumo)
            }
        }];
        //objs.push(this.printJustificativaSignature(row));

        return objs;
    }

    printResumoJornadaTable(listaResumo) {
        /**
         * Nesse codigo esta o puro do supra sumo do ajuste tecnico
         */
        var body = [];
        var totalLinhasCabecaho = 11;
        var correcaoRegistro = 'Resumo Jornada';
        const resumo = listaResumo[0];
        const mostrarExtraDirigida = (resumo.extraDirigida != '-1');
        if (mostrarExtraDirigida) {
            totalLinhasCabecaho++;
        }
        const mostrarExtraEspera = (resumo.extraEspera != '-1');
        if (mostrarExtraEspera) {
            totalLinhasCabecaho++;
        }
        const mostrarHoraExcedentes = (resumo.horaExcedentes != '-1');
        if (mostrarHoraExcedentes) {
            totalLinhasCabecaho++;
        }
        const mostrarTempoDeEspera = (resumo.tempoDeEspera != '-1');
        if (mostrarTempoDeEspera) {
            totalLinhasCabecaho++;
        }


        const cabecalho = [{
            colSpan: totalLinhasCabecaho,
            text: correcaoRegistro,
            alignment: 'center',
            fontSize: 14,
            bold: true
        }, '', '', '', '', '', '', '', '', '', ''];
        const colunasCabecalho =
            [
                { text: 'Entrada', alignment: 'center', style: 'dadosHeader' }, //1
                { text: 'Previsão Término', alignment: 'center', style: 'dadosHeader' }, //2
                { text: 'Saída', alignment: 'center', style: 'dadosHeader' }, //3
                { text: 'Paradas', alignment: 'center', style: 'dadosHeader' }, //4
                { text: 'Refeição', alignment: 'center', style: 'dadosHeader' }, //5
                { text: 'Hr Dirigidas', alignment: 'center', style: 'dadosHeader' }, //6
                { text: 'Interjornada', alignment: 'center', style: 'dadosHeader' }, //7
                { text: 'Ocioso', alignment: 'center', style: 'dadosHeader' },//8
            ];
        if (mostrarExtraDirigida) {
            cabecalho.push('');
            colunasCabecalho.push({ text: 'Extra Trabalhada', alignment: 'center', style: 'dadosHeader' });
        }
        if (mostrarExtraEspera) {
            cabecalho.push('');
            colunasCabecalho.push({ text: 'Extra Espera', alignment: 'center', style: 'dadosHeader' });
        }
        colunasCabecalho.push({ text: 'Folga Compensada', alignment: 'center', style: 'dadosHeader' });
        if (mostrarHoraExcedentes) {
            cabecalho.push('');
            colunasCabecalho.push({ text: 'Total Hr Excedente', alignment: 'center', style: 'dadosHeader' });
        }
        if (mostrarTempoDeEspera) {
            cabecalho.push('');
            colunasCabecalho.push({ text: 'Tempo de Espera', alignment: 'center', style: 'dadosHeader' });
        }
        colunasCabecalho.push({ text: 'Hrs Trabalhadas', alignment: 'center', style: 'dadosHeader' });//14
        colunasCabecalho.push({ text: 'Total Jornada', alignment: 'center', style: 'dadosHeader' });//15


        body.push(
            cabecalho,
            colunasCabecalho
        );

        listaResumo.forEach(r => {
            var dataRow = [];
            dataRow.push(this.getPrintableText(r.entrada)); //c01
            dataRow.push(this.getPrintableText(r.previsaoTermino)); //c02
            dataRow.push(this.getPrintableText(r.saida));  //c03
            dataRow.push(this.getPrintableText(r.paradas));  //c04
            dataRow.push(this.getPrintableText(r.refeicao)); //c05
            dataRow.push(this.getPrintableText(r.horasDirigidas)); //c06
            dataRow.push(this.getPrintableText(r.interjornada)); //c07
            dataRow.push(this.getPrintableText(r.ocioso)); //c08
            if (mostrarExtraDirigida) {
                dataRow.push(this.getPrintableText(r.extraDirigida)); //c09
            }

            if (mostrarExtraEspera) {
                dataRow.push(this.getPrintableText(r.extraEspera)); //c10
            }
            dataRow.push(this.getPrintableText(r.folgaCompensada)); //c11

            if (mostrarHoraExcedentes) {
                dataRow.push(this.getPrintableText(r.horaExcedentes)); //c12
            }
            if (mostrarTempoDeEspera) {
                dataRow.push(this.getPrintableText(r.tempoDeEspera)); //c013
            }
            dataRow.push(this.getPrintableText(r.horasTrabalhada)); //c014
            dataRow.push(this.getPrintableText(r.totalJornada)); //c15
            body.push(dataRow);
        });
        return body;
    }

    printDetalheDaJornadaTable(listaDetalheDaJornada) {
        var body = [];
        var correcaoRegistro = 'Detalhe da Jornada';

        body.push([{
            colSpan: 5,
            text: correcaoRegistro,
            alignment: 'center',
            fontSize: 14,
            bold: true
        }, '', '', '', ''],
            [
                { text: 'Chapa', alignment: 'center', style: 'dadosHeader' }, //1
                { text: 'Evento', alignment: 'center', style: 'dadosHeader' }, //2
                { text: 'Descrição', alignment: 'center', style: 'dadosHeader' }, //2
                { text: 'Hora', alignment: 'center', style: 'dadosHeader' }, //3
                { text: 'Falta', alignment: 'center', style: 'dadosHeader' } //3
            ]
        );

        listaDetalheDaJornada.forEach(r => {
            var dataRow = [];
            dataRow.push(this.getPrintableText(r.chapa)); //c01
            dataRow.push(this.getPrintableText(r.evento)); //c02
            dataRow.push(this.getPrintableText(r.descricao)); //c02
            dataRow.push(this.getPrintableText(r.hora));  //c03
            dataRow.push(this.getPrintableText(r.falta));  //c03
            body.push(dataRow);
        });
        return body;
    }

    async inconformidadesVeiculo(model: any): Promise<InconformidadesVeiculo[]> {
        const url = `${environment.baseAspCoreURL}/Dashboard/inconformidades-veiculo`;
        return this.http
            .post(url, model)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    formatarCnpj(cnpj) {

        const cnpjNumerico = cnpj.replace(/\D/g, '').padStart(14, '0');

        // Formatação do CNPJ
        const cnpjFormatado = `${cnpjNumerico.slice(0, 2)}.${cnpjNumerico.slice(2, 5)}.${cnpjNumerico.slice(5, 8)}/${cnpjNumerico.slice(8, 12)}-${cnpjNumerico.slice(12)}`;

        return cnpjFormatado;
    }

    RelatorioSituacaoVeiculo(reqBody: any) {
        try {
            const url = environment.baseURL + `App/v2/web/rel/buscarSituacaoVeiculo`;
            return this.http
                .post(url, reqBody, { headers: this.headers })
                .toPromise()
                .then(response => {
                    if (response) {
                        return response;
                    }
                })
                .catch(this.handleError);
        } catch (error) {
            console.error(error);
        }
    }

}
