import { Component, OnInit, Compiler, ElementRef, ViewChild  } from '@angular/core';
import { Router } from '@angular/router';
import { Company } from 'app/classes/company';
import { CompanyService } from 'app/services/crud/company.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Contratos } from 'app/classes/contratos';
import { ContratosService } from 'app/services/administrador/contratos.service';
import { FormBuilder, FormGroup } from "@angular/forms";
import { Arquivo } from 'app/classes/arquivo';

@Component({
  selector: 'app-contratos',
  templateUrl: './contratos.component.html',
  styleUrls: ['./contratos.component.css']
})
export class ContratosComponent implements OnInit {
  public form: FormGroup;
  @ViewChild('fileInput', { read: ElementRef }) private fileInput: ElementRef;

  dropdownList = [];
  public selectedItems = [];
  dropdownSettings: {};
  VM: String = "Via-M";
  VS: String = "Via-Sat";
  VL: String = "Via-Log";
  Interno:String ="Interno";
  V4:String ="V4";
  Sartech:String = "Sartech";

  public showDetalheCliente: boolean = false;
  public showPesquisa: boolean = true;
  showEmpresas: boolean = false;
  showBotaoPesquisa: boolean = false;
  showDetalhesContratos: boolean = false;
  showTabelaResumo: boolean = false;
  showTabelaDetalhes: boolean = false;
  showTabelaPorStatus: boolean = false;
  ocultarEditar: boolean = true;
  private empresaSelecionada: Company;
  private listaEmpresas = [];
  private contratos: Contratos;
  public novoContrato: Contratos;
  public contadorContrato: Contratos;
  public listaContrato: Contratos[];
  public listaContratoDetalhe: Contratos[];
  fileData: File = null;

  constructor(private empresaService: CompanyService,
    private contratosService: ContratosService,
    private router: Router,
    private _compiler: Compiler,
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
    try {
      this.listaContrato = [];
      this.listaContratoDetalhe = [];
      this.novoContrato = new Contratos();
      this.contadorContrato = new Contratos();
      this.empresaSelecionada = new Company();
      this.empresaService.buscarTodosParaContratos().then(response => {
        this.contratosService.buscarContadores().then(r => {
          this.contadorContrato = r.contratos;
          var data = response.response;
          this.listaEmpresas = data;
          this.dropdownList = [];
          for (var i = 0; i < data.length; i++) {
            var empresa = data[i];
            this.dropdownList.push({ item_id: empresa.id, item_text: empresa.nome });

          }
          this.dropdownSettings = {
            singleSelection: true,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Todos',
            unSelectAllText: 'Desmarcar todos',
            itemsShowLimit: 3,
            allowSearchFilter: true,
            searchPlaceholderText: 'Pesquisar'
          };
          this.showEmpresas = true;
          this.showTabelaResumo = true;
          this.showTabelaPorStatus = false;
        });
      });

    } catch (error) {
      console.error(error);
    }

  }

  doSearch() {
    this.showBotaoPesquisa = true;
    try {
      var idEmpresa = this.empresaSelecionada.id;
      this.contratosService.getPorEmpresa(idEmpresa).then(response => {
        this.listaContrato = response.listaDeContratos;
        this.showBotaoPesquisa = false;
        this.showEmpresas = true;
        this.showTabelaResumo = false;
        this.showPesquisa = false;
        this.showDetalheCliente = true;
        this.showDetalhesContratos = true;
        this.showTabelaDetalhes = false;
        this.showTabelaPorStatus = false;
        //Caso queira um novo contrato
        this.novoContrato = new Contratos();
      });
    } catch (error) {
      console.error(error);
    }
  }

  detalheDoCliente(id: number) {
    this.showBotaoPesquisa = true;
    try {
      this.contratosService.getPorEmpresa(id).then(response => {
        this.listaContrato = response.listaDeContratos;
        this.showBotaoPesquisa = false;
        this.showEmpresas = true;
        this.showTabelaResumo = false;
        this.showPesquisa = false;
        this.showDetalheCliente = true;
        this.showDetalhesContratos = true;
        this.showTabelaDetalhes = false;
        this.showTabelaPorStatus = false;
        //Caso queira um novo contrato
        this.novoContrato = new Contratos();
      });
    } catch (error) {
      console.error(error);
    }
  }

  voltarPesquisa() {
    try {
      this.showPesquisa = true;
      this.showTabelaResumo = true;
      this.showDetalheCliente = false;
      this.showDetalhesContratos = false;
      this.showTabelaDetalhes = false;
      this.showTabelaPorStatus = false;
    } catch (error) {
      console.error(error);
    }
  }

  onItemSelect(item: any) {
    this.inserirEmpresaSelecionado(item);
  }

  onSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirEmpresaSelecionado(items[i]);
    }
  }

  onDeSelect(item: any) {
    this.removerEmpresaSelecionado(item);
  }


  inserirEmpresaSelecionado(item: any): void {
    try {
      var empresa = this.listaEmpresas.find(e => e.id == item.item_id);
      if (empresa) {
        this.empresaSelecionada = empresa;
      }
    } catch (error) {
      console.log(error);
    }
  }

  removerEmpresaSelecionado(item: any): void {
    try {
      this.empresaSelecionada = null;
    } catch (error) {
      console.log(error);
    }
  }

  salvarContrato() {
    try {
      this.novoContrato.empresa = this.empresaSelecionada.id;
      this.novoContrato.tipoSistema = this.empresaSelecionada.tipoSistema;
      this.contratosService.cadastrarContrato(this.novoContrato).then(response => {
        this.novoContrato = new Contratos();
        this.recarregartabela();
      });
    } catch (error) {
      console.log(error);
    }
  }

  converteStatus(status: number) {
    switch (status) {
      case 1: return "Faturando";
      case 2: return "Implantando";
      case 3: return "Inadimplente";
      case 4: return "Desenvolvendo";
      case 5: return "Nova licença";
      case 6: return "Treinamento";
      case 7: return "Cancelado";
      case 8: return "Teste";
    }
  }

  cancelar() {
    this.novoContrato = new Contratos();
  }


  editarContratos() {
    try {
      this.ocultarEditar = false;
      let contratoTemp =  this.listaContrato.filter(x => this.isEmpty(x.revenda) == false)[0];
      if(contratoTemp){
        this.listaContrato.forEach(x=>{
          if(this.isEmpty(x.revenda)){
            x.revenda = contratoTemp.revenda;
          }
        })
      }
     
      this.contratosService.editarContratos(this.listaContrato).then(response => {
        this.novoContrato = new Contratos();
        this.ocultarEditar = true;
      });
    } catch (error) {
      console.log(error);
    }
  }

  recarregartabela() {
    try {
      this.contratosService.getPorEmpresa(this.empresaSelecionada.id).then(response => {
        this.listaContrato = response.listaDeContratos;
        //Caso queira um novo contrato
        this.novoContrato = new Contratos();
      });
    } catch (error) {
      console.log(error);
    }
  }

  detalhesItem(status: number) {
    try {
      this.contratosService.getPorItem(status).then(response => {
        this.listaContratoDetalhe = response.listaDeContratos;
        this.showTabelaResumo = false;
        this.showTabelaPorStatus = true;
      });

    } catch (error) {
      console.log(error);
    }
  }

  voltarTabelaResumo() {
    try {
      this.showTabelaResumo = true;
      this.showTabelaPorStatus = false;
    } catch (error) {
      console.log(error);
    }
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  enviarArquivo() {
    const formData = new FormData();
    formData.append('file', this.fileData);
    console.log(formData);
  }

  onFileChange(event, id) {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let arrayBuffer = reader.result
        let name = file.name;
        let size = file.size;
        let type = file.type;
        let arquivo = new Arquivo();
        arquivo.name = name;
        arquivo.size = size;
        arquivo.type = type;
        arquivo.dados = arrayBuffer.toString();
        let contrato = this.listaContrato.find(e => e.id == id);
        if(contrato.listaDeArquivos){
          contrato.listaDeArquivos.push(arquivo);
        }else{
          let lista = [];
          lista.push(arquivo);
          contrato.listaDeArquivos = lista;

        }
      };
    }
  }

  excluir(contrato, i){
    try {
      if(contrato.listaDeArquivos){
        contrato.listaDeArquivos.splice(i,1);
      }
    } catch (error) {
      console.error(error);
    }
  }

  downloadFile(arquivo:Arquivo) {
    const blob = new Blob([arquivo.dados], { type: arquivo.type });
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  }

  mostrarBotaoExcluir(arquivo:Arquivo){
    try {
      if(arquivo && !arquivo.id){
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  isEmpty(str) {
    return (!str || str.length === 0 );
}
}
