import { Component, OnInit, Compiler, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { FaturamentoService } from 'app/services/faturamento.service';
import { Veiculo } from 'app/classes/veiculo';
import { Filtro } from 'app/classes/filtro';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import am4lang_pt_BR from "@amcharts/amcharts4/lang/pt_BR";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";
import { DadosFaturamento } from 'app/classes/dados-faturamento';
import { DetalheGrafico } from 'app/classes/detalhe-grafico';
import { Faturamento } from 'app/classes/faturamento';
import { TimelineFaturamento } from 'app/classes/timeline-faturamento';
import { RankingMotoristaService } from 'app/services/crud/ranking-motorista.service';
import { RankingMototrista } from 'app/classes/ranking-mototrista';
import { ResumoRankingMototrista } from 'app/classes/resumo-ranking-mototrista';
import { ItemSelected } from 'app/classes/item-selected';
import { NotasMotoristaGrafico } from 'app/classes/notas-motorista-grafico';
import { MotoristaRankingDados } from 'app/classes/motorista-ranking-dados';


@Component({
  selector: 'app-ranking-motorista',
  templateUrl: './ranking-motorista.component.html',
  styleUrls: ['./ranking-motorista.component.css']
})
export class RankingMotoristaComponent implements OnInit {

  @ViewChild('resumoMotoristasList') resumoMotoristasList: ElementRef;
  mostrarNomeDoMotorista: boolean = false;
  showBotaoPesquisa: boolean = false;
  showResumo: boolean = true;
  showDetalhesMotarista: boolean = true;
  showBotaoVoltar: boolean = true;
  showBotoesPrincipais: boolean = false;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: {};
  motoristasSelecionados = [];
  chart: any;
  nomeDoMotorista: String;
  listaDeDados: any[];

  @ViewChild('dataTable') table;
  dataTable: any;
  dtOption: any = {};


  public rankingMototrista: RankingMototrista;
  public listaResumoRankingMototrista: ResumoRankingMototrista[];


  constructor(
    private rankingMotoristaService: RankingMotoristaService,
    private router: Router,
    private _compiler: Compiler,
    private modalService: NgbModal,
  ) {
    this._compiler.clearCache();
  }


  ngOnInit() {
    try {
      this.dropdownList = [];
      this.listaResumoRankingMototrista = [];
      this.nomeDoMotorista = "";
      this.listaDeDados = [];
      this.dtOption = {
        "iDisplayLength": 7,
        "bPaginate": true,
        "bLengthChange": false,
        "bFilter": true,
        "bInfo": false,
        "bAutoWidth": false,
        "language": {
          "lengthMenu": "Mostrando _MENU_ registros por pagina",
          "zeroRecords": "Nenhum registro encontrado",
          "search": "Pesquisar",
          "oPaginate": {
            "sNext": "Próximo",
            "sPrevious": "Anterior",
            "sFirst": "Primeiro",
            "sLast": "Último"
          }
        }, "columnDefs": [
          //Largura das Colunas
          { "className": "text-center", targets: [0, 1] },
          { "width": "20%", targets: 0 },
          { "width": "80%", targets: 1 }
        ],
        'dom': 'Bfrtip',
        'buttons': [
          'csv', 'excel', 'print'
        ]
      };
      this.dataTable = $(this.table.nativeElement);
      this.dataTable.DataTable(this.dtOption);
    } catch (error) {
      console.log(error);
    }
  }

  carregarRanking() {
    try {
      this.showBotaoPesquisa = true;
      this.showResumo = true;
      this.rankingMotoristaService.getResumo().then(response => {
        this.showBotaoPesquisa = false;
        this.showResumo = false;
        this.rankingMototrista = response.rankingMototrista;
        this.listaResumoRankingMototrista = this.rankingMototrista.listaResumoRankingMototrista;
        this.dropdownList = [];
        this.motoristasSelecionados = [];
        for (var i = 0; i < this.listaResumoRankingMototrista.length; i++) {
          let l = this.listaResumoRankingMototrista[i];
          this.dropdownList.push({ item_id: l.idMotorista, item_text: l.nomeMotorista });
        }
        this.dropdownSettings = {
          singleSelection: false,
          idField: 'item_id',
          textField: 'item_text',
          selectAllText: 'Todos',
          unSelectAllText: 'Desmarcar todos',
          itemsShowLimit: 3,
          allowSearchFilter: true,
          searchPlaceholderText: 'Pesquisar'
        };

        this.montarGraficoMotoristas();
      });
    } catch (error) {
      console.log(error);
    }
  }


  detalharMotoristas(idMotorista: number) {
    try {
      console.log(idMotorista);
    } catch (error) {
      console.log(error);
    }
  }

  montarGraficoMotoristas() {
    try {
      am4core.useTheme(am4themes_animated);
      this.chart = am4core.create("chartdivresumomotorista", am4charts.XYChart);
      let data = [];

      for (var i = 0; i < this.listaResumoRankingMototrista.length; i++) {
        let resumo = this.listaResumoRankingMototrista[i];
        data.push({ idMotorista: resumo.idMotorista, category: resumo.nomeMotorista, value: resumo.nota, color: resumo.cor });
      }
      this.chart.data = data;
      let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.minGridDistance = 15;
      categoryAxis.renderer.grid.template.location = 0.5;
      categoryAxis.renderer.grid.template.strokeDasharray = "1,3";
      categoryAxis.renderer.labels.template.rotation = -90;
      categoryAxis.renderer.labels.template.horizontalCenter = "left";
      categoryAxis.renderer.labels.template.location = 0.5;
      categoryAxis.renderer.labels.template.propertyFields.fill = "color";
      categoryAxis.renderer.labels.template.propertyFields.stroke = "color";

      categoryAxis.renderer.labels.template.adapter.add("dx", function (dx, target) {
        return -target.maxRight / 2;
      })

      let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.ticks.template.disabled = true;
      valueAxis.renderer.axisFills.template.disabled = true;

      let series = this.chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = "category";
      series.dataFields.valueY = "value";
      series.tooltipText = "{valueY.value}";
      series.sequencedInterpolation = true;
      series.fillOpacity = 0;
      series.strokeOpacity = 1;
      series.strokeDasharray = "1,3";
      series.columns.template.width = 0.01;
      series.columns.template.propertyFields.fill = "color"; // get color from data
      series.columns.template.propertyFields.stroke = "color";
      series.tooltip.pointerOrientation = "horizontal";

      let bullet = series.bullets.create(am4charts.CircleBullet);
      bullet.propertyFields.fill = "color";
      bullet.events.on("hit", function (event) {
        const dados = event.target.dataItem.dataContext;
        this.ajustarDetalheDoMotorista(dados);
      }, this);


      this.chart.cursor = new am4charts.XYCursor();
      this.chart.scrollbarX = new am4core.Scrollbar();
      this.chart.scrollbarY = new am4core.Scrollbar();
    } catch (error) {
      console.log(error);
    }
  }

  ajustarDetalheDoMotorista(dados: Object) {
    try {
      this.showBotaoVoltar = false;
      this.showBotoesPrincipais = true;
      this.showResumo = true;
      this.showDetalhesMotarista = false;
      let itemSelected = dados as ItemSelected;
      this.nomeDoMotorista = " - " + itemSelected.category;
      this.rankingMotoristaService.getRankingMotorista(itemSelected.idMotorista).then(response => {
        let motoristaRankingDados = response.rankingMototrista.motoristaRankingDados;
        this.ajustarGraficoDeMedia(motoristaRankingDados.mediaNotas);
        this.ajustarGraficoDeNotas(motoristaRankingDados.listaDeNotas);
        this.ajustarTabelaDeDetalhes(motoristaRankingDados);
      });

    } catch (error) {
      console.log(error);
    }
  }

  ajustarGraficoDeMedia(mediaDeNotas: number) {
    try {
      am4core.useTheme(am4themes_animated);

      let chart = am4core.create("chartdivmediamotorista", am4charts.GaugeChart);
      chart.innerRadius = -15;

      let axis = chart.xAxes.push(new am4charts.ValueAxis() as any);
      axis.min = 0;
      axis.max = 10;
      axis.strictMinMax = true;

      let colorSet = new am4core.ColorSet();

      let gradient = new am4core.LinearGradient();
      gradient.stops.push({ color: am4core.color("green") })
      gradient.stops.push({ color: am4core.color("yellow") })
      gradient.stops.push({ color: am4core.color("red") })

      axis.renderer.line.stroke = gradient;
      axis.renderer.line.strokeWidth = 15;
      axis.renderer.line.strokeOpacity = 1;

      axis.renderer.grid.template.disabled = true;

      let hand = chart.hands.push(new am4charts.ClockHand());
      hand.radius = am4core.percent(97);
      hand.showValue(mediaDeNotas, 1, am4core.ease.cubicOut);

    } catch (error) {
      console.log(error);
    }
  }

  ajustarTabelaDeDetalhes(motoristaRankingDados: MotoristaRankingDados) {
    try {
      this.dataTable.DataTable().clear().draw();
      this.listaDeDados = motoristaRankingDados.listaDeDados;
      this.listaDeDados.forEach(e => {
        let data = e[0];
        let evento = e[1];
        this.dataTable.DataTable().row.add([data, evento]);
      });
      this.dataTable.DataTable().draw();
    } catch (error) {
      console.log(error);
    }
  }

  ajustarGraficoDeNotas(listaDeNotas: NotasMotoristaGrafico[]) {
    try {
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create("chartdivnotamotorista", am4charts.XYChart);

      // Add data
      chart.data = listaDeNotas;

      // Create axes
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());

      // Create value axis
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      // Create series
      let lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.valueY = "nota";
      lineSeries.dataFields.dateX = "dataDoEvento";
      lineSeries.name = "Notas";
      lineSeries.strokeWidth = 3;

      // Add simple bullet
      let bullet = lineSeries.bullets.push(new am4charts.Bullet());
      bullet.tooltipText = "[bold]{nota}";
      let image = bullet.createChild(am4core.Image);
      image.href = "https://www.amcharts.com/lib/images/star.svg";
      image.width = 30;
      image.height = 30;
      image.horizontalCenter = "middle";
      image.verticalCenter = "middle";
    } catch (error) {
      console.log(error);
    }
  }

  voltarGraficos() {
    this.showBotaoVoltar = true;
    this.showBotoesPrincipais = false;
    this.showResumo = false;
    this.showDetalhesMotarista = true;
    this.nomeDoMotorista = "";
  }

  onItemSelect(item: any) {
    this.motoristasSelecionados.push(item);
  }

  onSelectAll(items: any) {
    this.motoristasSelecionados = [];
    this.motoristasSelecionados = items;
  }

  onDeSelectAll(items: any) {
    this.motoristasSelecionados = [];
  }
  onDeSelect(item: any) {
    let index = this.motoristasSelecionados.findIndex(v => v.item_id == item.item_id);
    this.motoristasSelecionados.splice(index, 1);
  }


  mostrarSelecionados() {
    try {
      let data = [];
      for (var i = 0; i < this.motoristasSelecionados.length; i++) {
        let item = this.motoristasSelecionados[i];
        let m = this.listaResumoRankingMototrista.find(v => v.idMotorista == item.item_id);
        data.push({ idMotorista: m.idMotorista, category: m.nomeMotorista, value: m.nota, color: m.cor });
      }
      this.chart.data = data;
      this.chart.validateData();
    } catch (error) {
      console.log(error);
    }
  }


  pontuacaoMinima() {
    try {
      let data = [];
      for (var i = 0; i < this.listaResumoRankingMototrista.length; i++) {
        let m = this.listaResumoRankingMototrista[i];
        if (m.nota <= 4) {
          data.push({ idMotorista: m.idMotorista, category: m.nomeMotorista, value: m.nota, color: m.cor });
        }
      }
      this.chart.data = data;
      this.chart.validateData();
    } catch (error) {
      console.log(error);
    }
  }

  pontuacaoMedia() {
    try {
      let data = [];
      for (var i = 0; i < this.listaResumoRankingMototrista.length; i++) {
        let m = this.listaResumoRankingMototrista[i];
        if (m.nota > 4 && m.nota <= 6) {
          data.push({ idMotorista: m.idMotorista, category: m.nomeMotorista, value: m.nota, color: m.cor });
        }
      }
      this.chart.data = data;
      this.chart.validateData();
    } catch (error) {
      console.log(error);
    }
  }

  pontuacaoMaxima() {
    try {
      let data = [];
      for (var i = 0; i < this.listaResumoRankingMototrista.length; i++) {
        let m = this.listaResumoRankingMototrista[i];
        if (m.nota > 6) {
          data.push({ idMotorista: m.idMotorista, category: m.nomeMotorista, value: m.nota, color: m.cor });
        }
      }
      this.chart.data = data;
      this.chart.validateData();
    } catch (error) {
      console.log(error);
    }
  }

  pesquisarPorPeriodo() {
    try {

    } catch (error) {
      console.log(error);
    }
  }

}
