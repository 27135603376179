import { Component } from "@angular/core";
import { BaseFormComponent } from "../../../base/base-form/base-form.component";
import { ActivatedRoute, Router } from "@angular/router";
import { RESTResponseVO } from "../../../../classes/restresponsevo";
import { VehicleType } from "../../../../classes/vehicle-type";
import { VehicleTypeService } from "../../../../services/crud/vehicle-type.service";
import { AuthGuard } from "../../../../guards/auth.guard";

@Component({
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css']
})
export class VehicleTypeFormComponent extends BaseFormComponent<VehicleType> {
    constructor(private service: VehicleTypeService,
        route: ActivatedRoute,
        private authGuard: AuthGuard,
        router: Router) { super(route, router, 'adm/vehicletype/list'); }

    assembleObjectFromForm(): void {
    }

    loadAuxiliaryLists(): void {
    }

    loadObject(): void {
        if (this.activeID && (this.activeID !== 0)) {
            this.service.getById(this.activeID).then(response => {
                var resultItem: VehicleType = response.response;
                this.activeObject = resultItem;
            });
        }
        this.loading = false;
    }

    initObject(): void {
        this.activeObject = new VehicleType();
        this.activeObject.empresaId = this.authGuard.loggedUser().selectedCompany.id;
        this.loading = false;
    }

    doCreate(t: VehicleType): Promise<RESTResponseVO<VehicleType>> {
        return this.service.create(t);
    }

    doEdit(t: VehicleType): Promise<RESTResponseVO<VehicleType>> {
        return this.service.update(t);
    }

    doDelete(t: VehicleType): Promise<RESTResponseVO<string>> {
        return this.service.delete(t.id);
    }

    setActiveID(): void {
        this.activeID = this.activeObject.id;
    }
}