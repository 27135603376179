import { Injectable } from '@angular/core';
import { Inconformidade } from 'app/classes/inconformidade';
import { CRUDService } from '../base/crud.service';
import { Http } from "@angular/http";
import { AuthGuard } from "../../guards/auth.guard";
import { environment } from "../../../environments/environment";
import { User } from '../../classes/user';
import { RetornoInconformidade } from 'app/classes/retorno-inconformidade';
import { RESTResponseVO } from 'app/classes/restresponsevo';
import { ObservacaoInconformidade } from 'app/classes/observacao-inconformidade';

@Injectable({
  providedIn: 'root'
})
export class InconformidadeService extends CRUDService<Inconformidade>{

  public company: number;
  public unidade: number;
  public loggedUser: User;

  constructor(http: Http, public authGuard: AuthGuard) {
    super(http, environment.baseURL + 'App/v2/web/inconformidade', true);
  
    this.loggedUser = authGuard.loggedUser();
    this.company = this.loggedUser.selectedCompany.id;
    this.unidade = this.loggedUser.selectedBranch.id;
    this.listURL = environment.baseURL + "App/v2/web/inconformidade/buscarTodasInconformidades/"+this.company+"/"+this.unidade;
  } 


  getLista() {
    const url: string =
      (this.listURL === undefined && `${this.defaultUrl}`) || `${this.listURL}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoInconformidade;
        }
      })
      .catch(this.handleError);
  }

  getById(id: number) {
    const url = `${this.defaultUrl}/buscarPeloId/${id}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoInconformidade;
        }
      })
      .catch(this.handleError);
  }

  editarInconformidade(inconformidade: Inconformidade): Promise<RESTResponseVO<Inconformidade>> {
    const url = `${this.defaultUrl}/editar`;
    inconformidade.usuario = this.loggedUser;
    return this.enviarRequisicao(inconformidade, url);
  }

  atualizarObservacaoInfoformidade(inconformidade: ObservacaoInconformidade): Promise<any> {
    const url = `${this.defaultUrl}/${inconformidade.id}`;
    inconformidade.idUsuario = this.loggedUser.id;
    return this.http
      .patch(url, inconformidade)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json()  as RetornoInconformidade;
        }
      })
      .catch(this.handleError);
  }

  enviarRequisicao(inconformidade: Inconformidade, url: string): Promise<RESTResponseVO<Inconformidade>> {
    try {
      return this.http
        .post(url, JSON.stringify(inconformidade), { headers: this.headers })
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            var retorno: RetornoInconformidade = response.json() as RetornoInconformidade;
            var inconformidade: Inconformidade = retorno.inconformidade;
            if (inconformidade === null) {
              inconformidade = new Inconformidade();
            }
            inconformidade.id = 0;
            return inconformidade;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }

}
