import { CRUDService } from "../base/crud.service";
import { Http } from "@angular/http";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { AuthGuard } from "../../guards/auth.guard";
import { RESTResponseVO } from "app/classes/restresponsevo";
import { User } from "../../classes/user";
import { FiltroOpcoesIndicadores } from "app/classes/filtro-opcoes-indicadores";
import { RetornoAcionamentoRegra } from "app/classes/retorno-resumo-acionamento-regra";
import { FiltrosMonitoramentoJornada } from "app/classes/filtro-monitoramento-jornada";
import { RetornoMonitoramentoJornada } from "app/classes/retorno-jornada-motorista";
import { FiltrosJornadaMotorista } from "app/classes/filtro-jornada-motorista";

@Injectable({
  providedIn: "root",
})
export class ControleIndicadoresService extends CRUDService<FiltroOpcoesIndicadores> {
  public company: number;
  constructor(http: Http, public authGuard: AuthGuard) {
    super(http, environment.baseURL + "App/v2/web/controleIndicadores", true);
    var loggedUser: User = authGuard.loggedUser();
    this.company = loggedUser.selectedCompany.id;
    this.listURL =
      environment.baseURL +
      "App/v2/web/controleIndicadores/buscarIndicadoresFiltrados/";
  }

  buscarIndicadoresFiltrados(
    filtro: FiltroOpcoesIndicadores
  ): any {
    const loggedUser: User = this.authGuard.loggedUser();
    const url = `${this.defaultUrl}/buscarIndicadoresFiltrados`;
    return this.enviarRequisicao(filtro, url);
  }

  enviarRequisicao(
    filtro: FiltroOpcoesIndicadores,
    url: string
  ): any {
    try {
      return this.http
        .post(url, JSON.stringify(filtro), { headers: this.headers })
        .toPromise()
        .then((response) => {
          if (this.restResponseWrapper) {
            var retorno: RetornoAcionamentoRegra =
              response.json() as RetornoAcionamentoRegra;
            if (retorno === null) {
              retorno = new RetornoAcionamentoRegra();
            }
            return retorno;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }

  buscarIndicadoresFiltradosMonitoramentoJornada(filtro: FiltrosMonitoramentoJornada): Promise<RESTResponseVO<RetornoMonitoramentoJornada>> {
    const url = `${environment.baseURL}App/v2/web/jornadas/dashboardIndicadores`;
    return this.enviarRequisicaoMonitoramentoJornada(filtro, url);
  }

  enviarRequisicaoMonitoramentoJornada(filtro: FiltrosMonitoramentoJornada, url: string): Promise<RESTResponseVO<RetornoMonitoramentoJornada>> {
    try {
      return this.http
        .post(url, JSON.stringify(filtro), { headers: this.headers })
        .toPromise()
        .then(response => {
          var retorno: RetornoMonitoramentoJornada = response.json() as RetornoMonitoramentoJornada;
          if (retorno === null)
            retorno = new RetornoMonitoramentoJornada();

          return retorno;
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }

  buscarIndicadoresFiltradosJornadaMotorista(filtro: FiltrosJornadaMotorista): Promise<RESTResponseVO<any[]>> {
    const url = `${environment.baseURL}App/v2/web/jornadas/dashboard`;
    return this.enviarRequisicaoJornadaMotorista(filtro, url);
          }

  enviarRequisicaoJornadaMotorista(filtro: FiltrosJornadaMotorista, url: string): Promise<RESTResponseVO<any[]>> {
    try {
      return this.http
        .post(url, JSON.stringify(filtro), { headers: this.headers })
        .toPromise()
        .then(response => {
          var retorno: any[] = response.json() as any[];
          if (retorno === null)
            retorno = [];

          return retorno;
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }
}
