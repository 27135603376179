import {
  Compiler,
  Component,
  OnInit,
  ViewChild
} from "@angular/core";
import { InconformidadesVeiculo } from "app/classes/inconformidades-veiculo";
import { Veiculo } from "app/classes/veiculo";
import { VeiculoService } from "app/services/crud/veiculo.service";
import { ReportService } from "app/services/report/report.service";
import { UtilService } from "app/services/utils.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-detalhe-inconformidades",
  templateUrl: "./detalhes-inconformidades.component.html",
  styleUrls: ['./detalhes-inconformidades.component.css']
})
export class DetalhesInconformidadesComponent implements OnInit {
  dropdownList = [];
  dropdownListLocal = [];
  public selectedItems = [];
  public selectedItemsTiposInconformidades = [];
  public selectedItemsLocal = [];
  dropdownSettings: {};
  public filtro = { Inicio: "", Fim: "", PlacasVeiculos: [], TiposInconformidades: [] };
  public pdfSrc: any;
  listaDeVeiculos: Veiculo[] = [];
  veiculo: Veiculo;
  listaInformacaoRelatorio: any[];
  showVeiculos: boolean = false;
  showBotaoPesquisa: boolean = true;
  isLoading = false;
  public dataInicial: string;
  public dataFinal: string;
  eventoSensores: number = 0;
  readonly tiposInconformidades = [{ item_text: "Permanência", item_id: "Permanencia" }, { item_text: "Velocidade Máxima", item_id: "Velocidade Maxima" }];
  tiposInconformidadesSelecionados: { item_text: string, item_id: string }[] = [];
  latitude = -19.923395;
  longitude = -43.944809;
  mapa: any;
  zoomMapa = 8;
  mapMarkers: any[] = [{latitude: -19.916751666666666, longitude: -44.04675999999999}];
  inconformidades: InconformidadesVeiculo[] = [];
  dtOption: any = {};
  @ViewChild("dataTable") table;
  dataTable: any;

  constructor(
    private veiculoService: VeiculoService,
    private reportService: ReportService,
    private utilService: UtilService,
    private _compiler: Compiler
  ) {
    this._compiler.clearCache();
  }

  ngOnInit() {
    this.getVeiculos();
    this.prepareDataTable();
  }

  onMapReady(item: any) {
    this.mapa = item;
  }

  prepareDataTable() {
    try {
      this.dtOption = {
        iDisplayLength: 17,
        bPaginate: false,
        fixedHeader: true,
        bLengthChange: false,
        bFilter: true,
        bInfo: false,
        bAutoWidth: false,
        scrollY: 400,
        deferRender: true,
        scroller: true,
        rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('td', row).off('click');
          $('td', row).on('click', () => {
            self.onRowClick(index);
          });
          return row;
        },
        language: {
          lengthMenu: "Mostrando _MENU_ registros por pagina",
          zeroRecords: "Nenhum registro encontrado",
          search: "Pesquisar",
          oPaginate: {
            sNext: "Próximo",
            sPrevious: "Anterior",
            sFirst: "Primeiro",
            sLast: "Último",
          },
        },
        dom: "Bfrtip",
        buttons: ["csv", "excel", "print"],
      };
      this.dataTable = $(this.table.nativeElement);
      this.dataTable.DataTable(this.dtOption);
    } catch (error) {
      console.error(error);
    }
  }

  onRowClick(index: number) {
    this.setLocalizacao(this.inconformidades[index]);
  }

  getVeiculos() {
    this.veiculoService
      .getLista()
      .then((response) => {
        var data = response.listaDeVeiculos;
        this.dropdownList = [];
        this.dropdownListLocal = [];
        for (var i = 0; i < data.length; i++) {
          var veiculo = data[i];
          this.dropdownList.push({
            item_id: veiculo.id,
            item_text: veiculo.placa,
          });
        }

        this.dropdownSettings = {
          singleSelection: false,
          idField: "item_id",
          textField: "item_text",
          selectAllText: "Todos",
          unSelectAllText: "Desmarcar todos",
          itemsShowLimit: 3,
          allowSearchFilter: true,
          searchPlaceholderText: "Pesquisar",
        };
        this.showVeiculos = true;
      })
      .catch((error) => console.error(error));
  }

  async doSearch() {
    if (!this.dataInicial || !this.dataFinal) {
      return Swal.fire({
        position: 'top-end',
        type: 'error',
        title: `Erro`,
        text: 'Os campos "Data inicial" e "Data final" são obrigatórios',
        showConfirmButton: false,
        timer: 4000
      });
    }
    try {
      this.showBotaoPesquisa = false;
      this.isLoading = true;
      this.filtro.Inicio = this.dataInicial;
      this.filtro.Fim = this.dataFinal;
      this.filtro.PlacasVeiculos = this.listaDeVeiculos.map(
        (item) => item.placa
      );
      this.filtro.TiposInconformidades = this.tiposInconformidadesSelecionados.map(i => i.item_id);
      const response = await this.reportService
        .inconformidadesVeiculo(this.filtro)
        this.ajustarTabelaDeDetalhes(response);
    } catch (error) {
      console.error(error);
    } finally {
      this.showBotaoPesquisa = true;
      this.isLoading = false;
    }
  }
  
  onDeSelect(item: any) {
    this.removerVeiculoSelecionado(item);
  }

  onItemSelect(item: any) {
    this.inserirVeiculoSelecionado(item);
  }

  onSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirVeiculoSelecionado(items[i]);
    }
  }

  inserirVeiculoSelecionado(item: any): void {
    try {
      var veiculo = this.listaDeVeiculos.find((v) => v.id == item.item_id);
      if (!veiculo) {
        this.veiculo = new Veiculo();
        this.veiculo.id = item.item_id;
        this.veiculo.placa = item.item_text;
        this.listaDeVeiculos.push(this.veiculo);
      }
    } catch (error) {
      console.log(error);
    }
  }

  removerVeiculoSelecionado(item: any): void {
    try {
      let index = this.listaDeVeiculos.findIndex((v) => v.id == item.item_id);
      this.listaDeVeiculos.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }

  onDeSelectTiposInconformidades(item: any) {
    this.removerTiposInconformidadesSelecionado(item);
  }

  onItemSelectTiposInconformidades(item: any) {
    this.inserirTiposInconformidadesSelecionado(item);
  }

  onSelectAllTiposInconformidades(items: { item_id: string, item_text: string }[]) {
    console.log(items)
    items.forEach(item => {
      this.inserirTiposInconformidadesSelecionado(item);
    });
  }

  inserirTiposInconformidadesSelecionado(item: { item_id: string, item_text: string }): void {
    try {
      const selecionado = this.tiposInconformidadesSelecionados.find((v) => v.item_id == item.item_id);
      if (!selecionado) {
        this.tiposInconformidadesSelecionados.push(item);
      }
    } catch (error) {
      console.log(error);
    }
  }

  removerTiposInconformidadesSelecionado(item: { item_id: string }): void {
    try {
      let index = this.tiposInconformidadesSelecionados.findIndex((v) => v.item_id == item.item_id);
      this.tiposInconformidadesSelecionados.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }

  
  ajustarTabelaDeDetalhes(informacaoRelatorio: InconformidadesVeiculo[]) {
    try {
      this.inconformidades = informacaoRelatorio;
      this.dataTable.DataTable().clear().draw();
      informacaoRelatorio.forEach((item) => {
        const placa = item.placa || '-';
        const dataHora = this.utilService.formatDate(new Date(item.data_inconformidade), "DD/MM/YYYY HH:mm");
        const endereco = item.endereco_completo || '-';
        const dadosIncoformidade = item.nome_inconformidade;
        const nomeMotorista = item.nome_motorista || item.nome_motorista == ' ' ? '-' : item.nome_motorista;
        const tipo = item.tipo_inconformidade || '-';

        this.dataTable
          .DataTable()
          .row.add([
            placa,
            dataHora,
            dadosIncoformidade,
            endereco,
            nomeMotorista,
            tipo,
          ]);
      });
      this.dataTable.DataTable().draw();
    } catch (error) {
      console.log(error);
    }
  }
  setLocalizacao(item: InconformidadesVeiculo) {
    console.log(item)
    if (item.latitude && item.longitude) {
      this.latitude = +item.latitude; 
      this.longitude = +item.longitude
      this.mapMarkers = [{latitude: +item.latitude, longitude: +item.longitude}];
    }
    
  }
}
