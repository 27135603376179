import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Viagem } from 'app/classes/viagem';
import { CRUDService } from '../base/crud.service';
import { environment } from "../../../environments/environment";
import { RetornoViagem } from 'app/classes/retorno-viagem';
import { EnvioViagem } from 'app/classes/envio-viagem';

@Injectable({
  providedIn: 'root'
})
export class ViagemService extends CRUDService<Viagem>{

  constructor(http: Http) {
    super(http, environment.baseURL + 'App/v2/web/viagem', true);
  }


  getLista(placa:string) {
    const url = `${this.defaultUrl}/buscarPorVeiculo/`+placa;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoViagem;
        }
      })
      .catch(this.handleError);
  }

  gravar( listaViagem: Viagem[], listaIdExcluir:number[]){
    try {
      let envioViagem = new EnvioViagem();
      envioViagem.listaDeViagem = listaViagem;
      envioViagem.listaViagemExcluir = listaIdExcluir;
      const url = `${this.defaultUrl}/cadastrar`;
      return this.http
        .post(url, JSON.stringify(envioViagem), { headers: this.headers })
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            var retorno: RetornoViagem = response.json() as RetornoViagem;
            if (retorno === null) {
              retorno = new RetornoViagem();
            }
            retorno.id = 0;
            return retorno;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }

  protected handleError(error: any): Promise<any> {
    console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
    return Promise.reject(error.message || error);
  }

}
