import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contadores-jornada-motorista',
  templateUrl: './contadores-jornada-motorista.component.html',
  styleUrls: ['./contadores-jornada-motorista.component.scss']
})
export class ContadoresJornadaMotoristaComponent implements OnInit {

  @Input() modelRetorno: any;

  constructor() {}
  ngOnInit(): void {
  }

  // @Input()
  // ocorrencias: number = 0;

  // @Input()
  // locais: number = 0;

  // @Input()
  // veiculos: number = 0;

  // @Input()
  // motoristas: number = 0;

}
