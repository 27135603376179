import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DashboardHomeService } from 'app/services/dashboard/dashboard.service';
import { AuthGuard } from 'app/guards/auth.guard';
import { ValidadorService } from 'app/services/validador.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-map-details-alertas',
  templateUrl: './map-details-alertas.component.html',
  styleUrls: ['./map-details-alertas.component.scss']
})
export class MapDetailsAlertasComponent implements OnInit {

  @Output() onload: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public dadosVeiculo: any;
  formGroupFiltroAlertas: FormGroup;
  listaDeAlertas: any[] = [];
  isHiddenAlertas = false;
  noDataAlertas = false;
  noDataInconformidades = false;

  displayedColumnsAlertas: string[] = ['data_evento', 'evento'];
  dataSourceAlertas: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  tiposAlertas: any[] = [
    { text: 'Todos', value: 0 },
    { text: 'Entrada Cerca', value: 1 },
    { text: 'Saída', value: 2 },
    { text: 'Horímetro', value: 3 },
    { text: 'Permanência Cerca', value: 4 },
    { text: 'Hodômetro', value: 5 },
    { text: 'Velocidade Máxima', value: 6 },
    { text: 'Abertura de Bau', value: 7 },
    { text: 'Fechamento de Bau', value: 8 },
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private authGuard: AuthGuard,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    public validadorService: ValidadorService,
    private spinner: NgxSpinnerService,
    public formatter: NgbDateParserFormatter,
    private dashboardHomeService: DashboardHomeService
  ) {
    this.formGroupFiltroAlertas = this.formBuilder.group({
      inicio: [null, Validators.required],
      fim: [null, Validators.required],
      tipoAlerta: 0
    });
  }

  ngOnInit(): void {

  }

  filtrarAlertas() {
    const model = {
      idVeiculo: this.dadosVeiculo.idObjeto,
      inicio: this.formGroupFiltroAlertas.value.inicio,
      fim: this.formGroupFiltroAlertas.value.fim,
      tipoAlerta: this.formGroupFiltroAlertas.value.tipoAlerta
    };

    if (this.formGroupFiltroAlertas.invalid) {
      Object.keys(this.formGroupFiltroAlertas.controls).forEach(key => {
        this.formGroupFiltroAlertas.get(key).markAsTouched();
      });
    } else {
      this.onload.emit(true);
      this.dashboardHomeService.alertasVeiculo(model).then(response => {
        this.isHiddenAlertas = response.length === 0;
        this.noDataAlertas = response.length === 0;
        this.listaDeAlertas = response;
        this.dataSourceAlertas = new MatTableDataSource(response);
        this.dataSourceAlertas.paginator = this.paginator;
        this.dataSourceAlertas.sort = this.sort;
        this.onload.emit(false);
      }).catch((res) => {
        this.onload.emit(false);
        console.log('erro', res);
      });
    }
  }

}
