import { Sascar } from './../../../classes/sascar';
import { BaseListComponent } from '../../base/base-list/base-list.component';
import { OnInit, Component, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { UtilService } from '../../../services/utils.service';
import { DriverService } from '../../../services/crud/driver.service';
import { PDFService } from '../../../services/pdf.service';
import { AuthGuard } from '../../../guards/auth.guard';
import { Driver } from '../../../classes/driver';
import { JourneyService } from '../../../services/crud/journey.service';
import { EventsService } from '../../../services/crud/events.service';
import { SascarConfigService } from '../../../services/crud/sascar-config.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { RESTResponseVO } from '../../../classes/restresponsevo';
import { TrustedScriptString } from '@angular/core/src/sanitization/bypass';
import { Location } from '@angular/common';

@Component({
    selector: 'app-node-paginate',
    templateUrl: './sascar-config.component.html',
    styleUrls: ['./sascar-config.component.css']
})
export class SascarConfigComponent extends BaseListComponent
    implements OnInit, AfterViewChecked {
    public sascarForm: FormGroup = new FormGroup({});
    public driverList: Driver[];
    public searchObj: { driver?: Driver; };

    constructor(
        private utilService: UtilService,
        private driverService: DriverService,
        private journeyService: JourneyService,
        private eventsService: EventsService,
        private sascarConfigService: SascarConfigService,
        private authGuard: AuthGuard,
        private cdRef: ChangeDetectorRef,
        pdfService: PDFService,
        private fb: FormBuilder,
        private location: Location
    ) {
        super(utilService, pdfService, authGuard);
        this.searchObj = {};
    }
    public searchObject: {
      driver: Driver;
      driverStatus: string;
  } = { driver: undefined, driverStatus: undefined};
    ngOnInit() {
        this.loading = true;
        this.driverService.getListMinimo().then(response => {
            this.originalDriverList = response.response;
            this.driverList = response.response;

            this.driverList.forEach(driver => driver.nome = `${driver.nome} ${driver.sobrenome}`);

            this.loading = false;
            this.onStatusChange(this.searchObject.driverStatus);
        });
        this.sascarForm = this.fb.group({
          sascarUsuario: [],
          sascarSenha: [],
        });
    }

    load() {
      location.reload();
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    driverTemplate(r) {
        return `${r.nome}  ${r.sobrenome}`;
    }

    driverstTitle() {
        return `Motoristas (${this.driverList ? this.driverList.length : 0})`;
    }
    saveConfigs(){
        const objeto = new Sascar();
        objeto.senhaSascar = this.sascarForm.get('senhaSascar').value;
        //objeto.idVeiculoSascar = this.sascarForm.controls['senhaSascar'].value;
        objeto.usuarioSascar = this.sascarForm.get('usuarioSascar').value;
        this.loading = true;
        try{
          return this.sascarConfigService.saveSascarConfig( this.searchObj.driver.id,
            objeto).then(response => {
            this.errorMessage = response.error;
            if (!this.errorMessage) {
              this.errorMessage = "Motorista incluído na integração Sascar com sucesso";
              this.loading = false;
            } else {
              this.errorMessage = response.error;
              this.loading = false;
            }
          });
        }catch (e) {
          console.log(e);
          this.loading = false;
        }
    }
// Driver Search

originalDriverList: Driver[];

driverFormatter = (x: Driver) => x.nome + ' ' + x.sobrenome;

searchDriver = (text$: Observable<string>) =>
    text$
        .debounceTime(200)
        .map(term =>
            this.driverList
                .filter(v => v.nome.toLowerCase().indexOf(term.toLowerCase()) > -1)
                .slice(0, 10)
        );

    onStatusChange(value) {
      if (value !== '') {
          this.driverList = this.originalDriverList.filter(
              d => d.situacao === +value
          );
      } else {
          this.driverList = this.originalDriverList;
      }
  }
}
