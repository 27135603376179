import { Injectable } from '@angular/core';
import { CRUDService } from '../base/crud.service';
import { Http } from '@angular/http';
import { environment } from '../../../environments/environment';
import { DSR } from '../../classes/dsr';
import { RESTResponseVO } from '../../classes/restresponsevo';
import { DSRGridItemVO } from '../../classes/dsr-grid-item-vo';

@Injectable()
export class DSRService extends CRUDService<DSR> {
  constructor(http: Http) {
    super(http, environment.baseURL + 'App/v2/web/dsr', true);
  }

  driverDSR(start: Number, end: Number, driver: Number): Promise<RESTResponseVO<DSRGridItemVO[]>> {
    var url = environment.baseURL + 'App/v2/web/dsr/periodomotorista/' + start + '/' + end + '/' + driver;
    return this.http.get(url)
        .toPromise()
        .then(response => {
            return response.json() as RESTResponseVO<DSRGridItemVO[]>;
        })
        .catch(this.handleError);
  }

  buscarDsrAutomaticasMotorista(data: Number, driver: Number): Promise<RESTResponseVO<any>> {
    var url = environment.baseURL + 'App/v2/web/dsr/getDsrAutomaticasMotorista/' + data + '/' + driver;
    return this.http.get(url)
        .toPromise()
        .then(response => {
            return response.json() as RESTResponseVO<any>;
        })
        .catch(this.handleError);
  }
}