import { IDEntity } from './IDEntity';
import { Company } from './company';
import { Coordenada } from './coordenada';
import { ParametroLocal } from './parametro-local';
export class Local extends IDEntity  {
    nome: string;
    latitude: number;
    longitude: number;
    permiteDescanso: boolean;
    boundary: number;
    localPai: number;
    idGrupoLocais: number = 0;
    temFilho: boolean;
    ativo: boolean;
    mostrarNoMapaPrincipal: boolean;
    mostrarNomeNoMapa: boolean;
    notificaEvento: boolean;
    codigoUnico: number;
    funcao: number;
    endereco: string;
    bairro: string;
    complemento: string;
    cidade: string;
    uf: string;
    contato: string;
    telefone: string;
    tipo: number;
    cliente: Company;
    listaDeCoordenadas: Coordenada[];
    parametroLocal: ParametroLocal;
}
