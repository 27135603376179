import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { RESTResponseVO } from "../../classes/restresponsevo";
import { Nonconformity } from "../../classes/nonconformity";
import { Http } from "@angular/http";
import { NonconformityTreatment } from "../../classes/nonconformity-treatment";
import { NonconformityCalculusType } from "../../classes/nonconformity-calculus-type";
import { CalendarVO } from "../../classes/calendarvo";
import { OperationDiff } from "../../classes/operation-diff";
import { PromiseState } from "q";

@Injectable()
export class NonconformityService {
    constructor(private http: Http) { }

    requestOperation(target: any, type: string, start: number, end: number, driver: number, user: number): Promise<RESTResponseVO<OperationDiff>> {
        var url = environment.baseURL + 'App/v2/web/inconformidade/operation/' + type + '/' + start + '/' + end + '/' + driver + '/' + user;
        return this.http.post(url, target)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<OperationDiff>;
            })
            .catch(this.handleError);
    }

    calendar(start: number, end: number, driver: number, preview: boolean, fired: boolean, firedDate: number, user:number): Promise<RESTResponseVO<CalendarVO>> {
        var url = environment.baseURL + 'App/v2/web/inconformidade/calendar/' + start + '/' + end + '/' + driver + '/' + preview + '/' + fired + '/' + firedDate + '/' + user;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<CalendarVO>;
            })
            .catch(this.handleError);
    }

    nonconformityList(start: Number, end: Number, driver: Number, selectedTypes: NonconformityCalculusType[]): Promise<RESTResponseVO<Nonconformity[]>> {
        var url = environment.baseURL + 'App/v2/web/inconformidade/lista/' + start + '/' + end + '/' + driver + '?';
        selectedTypes.forEach(t => url += '&tipo=' + t.id);

        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<Nonconformity[]>;
            })
            .catch(this.handleError);
    }

    getTypes(): Promise<RESTResponseVO<NonconformityCalculusType[]>> {
        var url = environment.baseURL + 'App/v2/web/inconformidade/tipos';
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<NonconformityCalculusType[]>;
            })
            .catch(this.handleError);
    }

    uploadTreatments(treatments: NonconformityTreatment[], user: Number): Promise<RESTResponseVO<string>> {
        var url = environment.baseURL + 'App/v2/web/inconformidade/uploadTreatments/' + user;
        return this.http.post(url, treatments)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<string>;
            })
            .catch(this.handleError);
    }

    protected handleError(error: any): Promise<any> {
        console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
        return Promise.reject(error.message || error);
    }
}