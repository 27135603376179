import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { JourneyService } from "../../../services/crud/journey.service";
import { UtilService } from "../../../services/utils.service";

@Component({
    selector: "app-dynamic-striped-table",
    templateUrl: "./dynamic-striped-table.component.html",
    styleUrls: ["./dynamic-striped-table.component.css"]
})
export class DynamicStripedTableComponent implements OnInit {
    /** Representação do objeto alvo da tabela. */
    @Input() public selectedSlice: {
        tipoEvento: string;
        count: number;
        color: string;
        drivers: any[];
    };



    /** Construtor sobrecarregado para popular a tabela. */
    constructor(private router: Router, private journeyService: JourneyService, private utilService: UtilService) {
        this.selectedSlice = undefined;
    }

    ngOnInit() {
    }

    showJourney(jornadaId) {
        this.journeyService.getSingleSummary(jornadaId).then(response => {
            localStorage.setItem('journey', JSON.stringify({
                summary: response.response,
                period: this.utilService.formatDate(response.response.inicio, "DD/MM/YYYY"),
                returnURL: 'adm/main'
            }));

            this.router.navigate(['adm/journey/form'], { skipLocationChange: true });
        });
    }

    convertHex(hexa: string, opacity: number) {
        let hex = hexa.replace('#', '');
        let r = parseInt(hex.substring(0, 2), 16);
        let g = parseInt(hex.substring(2, 4), 16);
        let b = parseInt(hex.substring(4, 6), 16);

        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
    }

}
