export class Regex {
  // - dd/mm/yyyy
  public date: RegExp;
  public password: RegExp;
  public phoneNumber: RegExp;

  public static sDate: RegExp = /(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/g;
  public static sPassword: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*([\d]|[@$!%*?&"#'()+,-.:;<=>_|{}~^\]\[]))[A-Za-z\d@$!%*?&"#'()+,-.:;<=>_|{}~^\]\[]{8,20}$/g;
  public static sPhoneNumber: RegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;

  constructor() {
    this.date = Regex.sDate;
    this.password = Regex.sPassword;
    this.phoneNumber = Regex.sPhoneNumber;
  }
}
