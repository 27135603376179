import { Component, Inject, Input} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DetalheAcionamentoRegra } from 'app/classes/detalhe-acionamento-regra';
import * as XLSX from 'xlsx';

@Component({
  selector: 'detalhes-abertura-bau-fora-cerca',
  templateUrl: './detalhes-abertura-bau-fora-cerca.html',
  styleUrls: ['./detalhes-abertura-bau-fora-cerca.scss']
})
export class DetalhesAberturaBauForaCercaComponent {

  @Input()
  resumoAcionamento: DetalheAcionamentoRegra[] = [];

  resumoAcionamentoPaginado: DetalheAcionamentoRegra[] = [];

  length: number = this.resumoAcionamento.length;
  pageSize: number = 10;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  nomeTabela = "DetalhesAberturaBauForaCerca.xlsx";

  constructor(public dialogRef: MatDialogRef<DetalhesAberturaBauForaCercaComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) {
    
    this.resumoAcionamento = data.detalhesAberturaForaCerca;
    this.length = this.resumoAcionamento.length;

    this.paginarResultados(0);
  }

  exibirTabela: Boolean = true;

  exportexcel() {
    let element = document.getElementById('detalhes_Abertura_Fora_Cerca_Completo');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
 
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Tabela 1');
   
    XLSX.writeFile(wb, this.nomeTabela);
 
  }

  openMap(latitude, longitude) {   
    window.open(
      'http://maps.google.com/maps?q=' + latitude + ',' + longitude,
      '_blank'
    );   
  }

  page(event) {
    this.pageSize = event.pageSize;
    this.paginarResultados(event.pageIndex);
  }

  paginarResultados(pageIndex: number) {
    this.resumoAcionamentoPaginado = this.resumoAcionamento.slice(pageIndex * this.pageSize, (pageIndex + 1) * this.pageSize);
  }

  close() {
    this.dialogRef.close();
  }
}