import { BaseListComponent } from "../../../base/base-list/base-list.component";
import { Component, ViewEncapsulation } from "@angular/core";
import { UtilService } from "../../../../services/utils.service";
import { PDFService } from "../../../../services/pdf.service";
import { AuthGuard } from "../../../../guards/auth.guard";
import { MetasService } from "app/services/crud/meta.service";
import { ModeloVeiculoService } from "app/services/crud/modelo-veiculo.service";

@Component({
  templateUrl: '../../../base/base-list/base-list.component.html',
  styleUrls: ['../../../base/base-list/base-list.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class MetasListComponent extends BaseListComponent {
  constructor(
    private service: MetasService,
    private utilService: UtilService,
    private modeloVeiculoService: ModeloVeiculoService,
    pdfService: PDFService,
    authGuard: AuthGuard) {
    super(utilService, pdfService, authGuard);
    this.searchField = "meta";
  }

  ngOnInit() {
    this.pageTitle = "Cadastros | Metas";
    this.formPath = "/adm/metas/form";

    this.tableHeaders = [
      [
        this.createHeader("Descrição", 1, true),
        this.createHeader("Marca - Fabricante", 2, true),
      ]
    ];

    this.dataFields = [
      this.createField("descricao", "string"),
      this.createField("idModelo", "string"),
    ];

    this.service.getLista().then(response => {
      this.data = response.response.map((d) => {
        this.modeloVeiculoService.getById(d.idModelo).then(response => {
          d.idModelo = `${response.modelo.marca} - ${response.modelo.fabricante}`;
        });
        return d;
      });
      this.loading = false;
    });
  }
}
