import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MatSort,
  MatTableDataSource,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { TabelaDetalhesDoIndicador } from "app/classes/tabela-detalhes-do-indicador";
import * as XLSX from "xlsx";

@Component({
  selector: "detalhes-do-indicador",
  templateUrl: "./detalhes-do-indicador.component.html",
  styleUrls: ["./detalhes-do-indicador.component.scss"],
})
export class DetalhesDoIndicadorComponent implements AfterViewInit, OnInit {
  @Input()
  dados: Array<TabelaDetalhesDoIndicador> = [];
  porcentagemSelecionada: string;

  @ViewChild("TABLE") table: ElementRef;

  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<TabelaDetalhesDoIndicador>;

  displayedColumns = [
    "dataHora",
    "placa",
    "modeloFabricante",
    "idMotorista",
    "motorista",
    "duracao",
    "kmRodado",
    "enderecoCompleto",
    "localNoMapa",
  ];

  constructor(
    public dialogRef: MatDialogRef<DetalhesDoIndicadorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { dados: TabelaDetalhesDoIndicador[], porcentagemSelecionada: string },
    public dialog: MatDialog
  ) {
    this.porcentagemSelecionada = data.porcentagemSelecionada;
    this.dados = data.dados;
  }

  exibirTabela: boolean = true;

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.dados);
  }

  ngAfterViewInit() {
    this.dataSource.data = this.dados;
    this.dataSource.sort = this.sort;
  }

  exportAsExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Tabela Eficiência");

    XLSX.writeFile(wb, "Tabela_Eficiencia.xlsx");
  }

  close() {
    this.dialogRef.close();
  }

  openMap(localNoMapa: string) {
    const [ _latitude, _longitude ] = localNoMapa.split(" - ");
    const latitude = _latitude.replace("Latitude: ", "");
    const longitude = _longitude.replace("Longitude: ", "");
    window.open(`https://www.google.com.br/maps/place/${latitude},${longitude}/@${latitude},${longitude},15z`, "_blank");
  }
}

