import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { KeyValue } from "app/components/title-page/title-page.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertaService } from "app/services/crud/alerta.service";
import { Router } from "@angular/router";

import Swal from 'sweetalert2';
import { AuthGuard } from "app/guards/auth.guard";

@Component({
  selector: "app-cadastro-aviso",
  templateUrl: "./cadastro-aviso.component.html",
  styleUrls: ["./cadastro-aviso.component.css"],
})
export class CadastroAvisoComponent implements OnInit {
  content: any;
  tituloPage: string = "Cadastro de Avisos";
  keyValueList: KeyValue[] = [
    { key: "Gestão de Avisos", value: "adm/gestao-avisos" },
    { key: "Cadastro de Avisos", value: "adm/criar-avisos" },
  ];

  @ViewChild("summernote") summernoteElement: ElementRef;
  avisoForm: FormGroup;

  options: any = {
    placeholder: "Digite seu texto aqui...",
    tabsize: 2,
    height: 500,
    toolbar: [
      ["style", ["bold", "italic", "underline", "clear"]],
      ["para", ["ul", "ol"]],
      ["insert", ["link", "picture", "video"]],
    ],
    callbacks: {
      onChange: (contents: string) => {
        this.content = contents; // Atualiza o conteúdo no model
      },
      onKeydown: (e: any) => {
        if (e.keyCode === 13) {
          // Verifica se a tecla pressionada é Enter (keyCode 13)
          e.preventDefault(); // Impede o comportamento padrão da tecla Enter

          const range = document.getSelection().getRangeAt(0);
          const br = document.createElement("br");

          range.deleteContents(); // Remove qualquer conteúdo existente no range
          range.insertNode(br); // Insere a quebra de linha <br>

          // Move o cursor para após a quebra de linha
          range.setStartAfter(br);
          range.setEndAfter(br);
          range.collapse(false);
        }
      },
    },
  };
  constructor(private fb: FormBuilder, private alertaService: AlertaService, private router: Router,private authGuard: AuthGuard ) {}

  ngOnInit(): void {
    this.createBuildForm();
  }

  createBuildForm() {
    this.avisoForm = this.fb.group({
      titulo: ["", Validators.required],
      tipoAtualizacao: ["", Validators.required],
    });
  }

  enviarAviso() {
    const req = {
      mensagem: this.content,
      tipoAtualizacao: this.avisoForm.get("tipoAtualizacao").value,
      tituloAviso: this.avisoForm.get("titulo").value
    };

    if (this.avisoForm.valid && this.content !== null) {
      this.alertaService.saveAviso(req).then(response =>{
        if (response.error) {
          console.error(response.error);
      } else {
        let loggedUser = this.authGuard.loggedUser();
        this.alertaService.getTotalAlertaNovos(loggedUser.id).then(response =>{
          if (response.error) {
            console.error(response.error);
        } else {
            var count = response.response;
            this.alertaService.updateNotificationsCount(count);
        }
        })
         this.router.navigateByUrl('/adm/gestao-avisos')
      }
      })
    }
  }
}
