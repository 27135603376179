import { Injectable } from '@angular/core';
import { City } from '../../classes/city';
import { CRUDService } from '../base/crud.service';
import { Http } from '@angular/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class CityService extends CRUDService<City> {
  constructor(http: Http) {
    super(http, environment.baseURL + 'App/v2/web/cidades', true);
  }
}