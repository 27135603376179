import { BaseListComponent } from "../../../base/base-list/base-list.component";
import { Component, ViewEncapsulation } from "@angular/core";
import { MotiveService } from "../../../../services/crud/motive.service";
import { UtilService } from "../../../../services/utils.service";
import { PDFService } from "../../../../services/pdf.service";
import { AuthGuard } from "../../../../guards/auth.guard";

@Component({
    templateUrl: '../../../base/base-list/base-list.component.html',
    styleUrls: [ '../../../base/base-list/base-list.component.css' ],
    encapsulation: ViewEncapsulation.None
})
export class MotiveListComponent extends BaseListComponent {
    constructor(private motiveService: MotiveService,
        private utilService: UtilService,
        pdfService: PDFService,
        authGuard: AuthGuard) {
        super(utilService, pdfService, authGuard);
        this.searchField = "descricao";
    }

    ngOnInit() {
        this.pageTitle = "Cadastros | Motivos";
        this.formPath = "/adm/motive/form";

        this.tableHeaders = [
            [
                this.createHeader("ID", 0, true),
                this.createHeader("Descrição", 1, true)
            ]
        ];

        this.dataFields = [
            this.createField("id", "string"),
            this.createField("descricao", "string")
        ];

        this.motiveService.getList().then(response => {
            this.data = response.response;
            this.loading = false;
        });
    }
}