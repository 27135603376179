import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  ValidatorFn
} from '@angular/forms';

import { Validation } from '../../models/validation';
import { Regex } from '../../models/regex';
import { isNullOrUndefined } from 'util';

/*
  This Component is generic and supports input configs
    - It uses Angular Reactive Forms
*/

@Component({
  selector: 'viam-input',
  templateUrl: './viam-input.component.html',
  styleUrls: ['./viam-input.component.scss']
})
export class ViamInputComponent implements OnInit, OnChanges {
  public input: FormControl = new FormControl();

  @Input() // Must receive a parent FormGroup to use form validations
  public form: FormGroup;

  @Input() // Title / Description the will be showed to the user
  public title: string;

  @Input() // MaxLength / Characters maximum length
  public maxLength: string;

  @Input() // Placeholder the will be showed to the user
  public placeholder: string;

  @Input() // Defines the type of the input - *To use general types use "isNumeric | isPassword..." Inputs attributes
  public type: string;

  @Input() // Used by the "id" and "name" HTML attributes, used for Reactive Form Control too.
  public name: string;

  @Input() // The effective value that will be send to the parent model
  public model: any;

  @Output() // Reflects changes to Parent model
  public modelChange = new EventEmitter<any>();

  @Output() // Reflects changes to Parent model
  public keyDownEmitter = new EventEmitter<any>();

  @Output()
  public focus = new EventEmitter<any>();

  @Input() // Default css themes appliend on the component (allows create others)
  public theme: string;

  @Input() // Set readonly for the input
  public readonly: boolean;

  @Input() // Required validation
  public isRequired: boolean;

  @Input() // Just numeric values
  public isNumeric: boolean;

  @Input() // Hide caracters on the input
  public isPassword: boolean;

  @Input() // Allows password validation
  public passwordValidation: Validation[];

  @Input() // Show an eye icon, that allows the user to see the password
  public allowShowPass: boolean;

  @Input() // Show info about password Complexity
  public showPassComplexity: boolean;

  @Input() // Apply a regex for PhoneNumber
  public isPhoneNumber: boolean;

  @Input() // Apply disabled property to the input
  public isDisabled: boolean;

  @Input() // apply email validation
  public isEmail: boolean;

  @Input() // if shows input icon
  public useIcon: boolean;

  @Input() // To textarea elements
  public isTextarea: boolean;

  @Input() // Related to the textarea
  public rows: number;

  @Input() // Related to the textarea
  public cols: number;

  @Input() // Receives a regular expression and apply validation
  public regex: RegExp;

  @Input() // A list of validation types and related message errors
  public validations: Validation[];

  @Input() // Force input to match some external string (used for password confimation)
  public stringMatch: string;

  @Input() // Force input to match some external string (used for password confimation)
  public onKeyDown: boolean;

  @Input() // Force input to match some external string (used for password confimation)
  public allowStackPolicies: boolean;

  // Internal variable for match
  public match = true;

  public showTitle = true;

  constructor() {}

  ngOnInit() {
    if (this.isPassword) {
      this.type = 'password';
    } else if (this.isEmail) {
      this.type = 'email';
    } else {
      this.type = 'text';
    }

    if (isNullOrUndefined(this.theme)) {
      this.theme = 'theme-default';
    }
    if (isNullOrUndefined(this.allowShowPass)) {
      this.allowShowPass = false;
    }
    if (isNullOrUndefined(this.placeholder)) {
      this.placeholder = '';
    }
    if (isNullOrUndefined(this.readonly)) {
      this.readonly = false;
    }

    this.setValidations();

    this.configureControl();
  }

  ngOnChanges(changes: any) {
    if (this.input === undefined) {
      return;
    }

    this.input.setValue(this.model, { emitEvent: false });

    if (isNullOrUndefined(this.isDisabled)) {
      this.isDisabled = false;
    }
    if (this.isDisabled) {
      this.input.disable({ emitEvent: false });
    } else {
      this.input.enable({ emitEvent: false });
    }
  }

  setValidations() {
    const validators: ValidatorFn[] = [];

    if (this.isRequired) {
      validators.push(Validators.required);
      if (!this.validationExists('required')) {
        this.validations.push({
          type: 'required',
          message: 'Este campo é obrigatório.'
        });
      }
    }

    if (this.isEmail) {
      validators.push(Validators.email);
      if (!this.validationExists('email')) {
        this.validations.push({
          type: 'email',
          message: 'Por favor, forneça um email válido!'
        });
      }
    }

    if (this.isPassword && this.passwordValidation) {
      this.regex = Regex.sPassword;
      if (!this.validationExists('pattern')) {
        this.validations.push({
          type: 'pattern',
          message:
            'O password informado nao coincide com as regras de complexidade.'
        });
      }
    }

    if (this.isPhoneNumber) {
      this.regex = Regex.sPhoneNumber;
      if (!this.validationExists('pattern')) {
        this.validations.push({
          type: 'pattern',
          message: 'Por favor, forneça um número de telefone válido.'
        });
      }
    }

    if (!isNullOrUndefined(this.regex)) {
      // Should pass a string not a regex
      validators.push(
        Validators.pattern(
          this.regex
            .toString()
            .replace('/', '')
            .replace('/g', '')
        )
      );
    }

    this.input.setValidators(validators);
  }

  configureControl() {
    this.input.valueChanges.subscribe(value => this.onChange(value));

    if (!isNullOrUndefined(this.form)) {
      this.form.addControl(this.name, this.input);
    } // This line must be at the end
  }

  onChange(value: any) {
    this.model = value;

    if (!isNullOrUndefined(this.stringMatch)) {
      if (isNullOrUndefined(this.input.value) || this.input.value.length < 1) {
        this.removeError({ match: true });
      } else {
        this.match = this.model === this.stringMatch;

        if (!this.match) {
          this.addError({ match: true });
        } else {
          this.removeError({ match: true });
        }
      }
    }

    this.modelChange.emit(this.model);
  }

  onKeydown(event) {
    if (this.onKeyDown) {
      if (event.key === 'Enter') {
        event.preventDefault();
        this.keyDownEmitter.emit(event.target.value);
      }
    }
  }

  emitPolicy(event) {
    if (
      !isNullOrUndefined(this.form.controls.policy_number.value) &&
      this.form.controls.policy_number.status !== 'INVALID'
    ) {
      this.keyDownEmitter.emit(this.form.controls.policy_number.value);
    }
  }

  addError(error) {
    if (isNullOrUndefined(this.input.errors)) {
      this.input.setErrors(error);
    } else {
      const key: string = Object.keys(error)[0];
      this.input.errors[key] = true;
    }
  }

  removeError(error) {
    if (!isNullOrUndefined(this.input.errors)) {
      const key: string = Object.keys(error)[0];
      delete this.input.errors[key];
    }
  }

  tooglePassVisibility() {
    this.type = this.type === 'password' ? 'text' : 'password';
  }

  onFocus() {
    if (this.theme === 'login') {
      this.showTitle = false;
    }

    this.focus.emit();
  }

  onBlur() {
    if (this.theme === 'login') {
      if (isEmptyOrUndefined(this.model)) {
        this.showTitle = true;
      } else if (this.model.length < 1) {
        this.showTitle = true;
      } else {
        this.showTitle = false;
      }
    }
  }

  errorNames() {
    return Object.keys(this.input.errors);
  }

  getMessageValidation(type): string {
    const obj: Validation = this.validations.filter(
      validation => validation.type === type
    )[0];

    return !isNullOrUndefined(obj) ? obj.message : '';
  }

  validationExists(type) {
    if (isNullOrUndefined(this.validations)) {
      this.validations = [];
      return false;
    }

    return (
      this.validations.filter(validation => validation.type === type).length > 0
    );
  }
}

const isEmptyOrUndefined = value =>
  isNullOrUndefined(value) || value.length < 1;
