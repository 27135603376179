import { IDEntity } from "./IDEntity";
import { Company } from "./company";
import { Branch } from "./branch";

export class Paramcfg extends IDEntity {
    grcodigo: string;
    ticodigo: string;
    empresaId: Company;
    unidadeId: Branch;
    dado: string;
    nometela: string;
    desctela: string;
}