import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'contadores-indicadores',
  templateUrl: './contadores-indicadores.html',
  styleUrls: ['./contadores-indicadores.scss']
})
export class Contadoresindicadores {

  @Input()
  ocorrencias: number = 0;

  @Input()
  locais: number = 0;

  @Input()
  veiculos: number = 0;

  @Input()
  motoristas: number = 0;

  constructor() {}

  clickNoBotaoTeste() {
  } 
}
