import { IDEntity } from './IDEntity';
import { Address } from './address';
import { Shift } from './shift';
import { User } from './user';
import { Image } from './image';
import { Branch } from './branch';
import { MotoristasVeiculos } from './motoristas-veiculos';
import { Vacation } from './vacation';
import { TipoFuncionario } from './tipo-funcionario';

export class Driver extends IDEntity {
  nome: string;
  sobrenome: string;
  dataNascimento: Date;
  sexo: string;
  cpf: string;
  numeroCnh: string;
  validadeCnh: Date;
  dataAdmissao: Date;
  categoriaCnh: string;
  telefone: string;
  empresaId: number;
  enderecosId: Address;
  turno: Shift;
  turnosId: any;
  unidade: Branch;
  tipoFuncionarioId: any;
  tipoFuncionario: any;
  tipo: any;
  unidadesId: number;
  user: User;
  userId: number;
  imagemId: Image;
  situacao: number;
  dataDemissao: Date;
  veiculos: MotoristasVeiculos[];
  ferias: Vacation[];
  sascarId: number;
  numeroMatricula: string;
  macroId: number;
  ibutton: String;
  isAtivo: boolean;
  numeroChapa: string;
  cargo:String;
  matriculaEsocial: string;
  imeiCelular: string;
  ctps: string;

  constructor(id?: number, nome?: string, sobrenome?: string) {
    super(id);
    this.nome = nome;
    this.sobrenome = sobrenome;
  }
}
