import { OnInit, Component, ElementRef, ViewChild, AfterViewInit, Compiler } from "@angular/core";
import { Router } from "@angular/router";
import { AuthGuard } from "../../guards/auth.guard";
import { AmChartsService, AmChart } from "@amcharts/amcharts3-angular";
import { JourneyState } from "../../classes/journey-state";
import { JourneyStateService } from "../../services/crud/journey-state.service";
import { User } from "../../classes/user";
import { EventsService } from "../../services/crud/events.service";
import { DriverVO } from "../../classes/driver-vo";
import { JourneyService } from "../../services/crud/journey.service";
import { BaseListComponent } from "../base/base-list/base-list.component";
import { UtilService } from "../../services/utils.service";
import { PDFService } from "../../services/pdf.service";
import { Subscription, Observable, timer } from 'rxjs';
import { DynamicStripedTableComponent } from "../base/dynamic-striped-table/dynamic-striped-table.component";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
    selector: "app-daily-panel",
    templateUrl: "./panel.component.html",
    styleUrls: ["./panel.component.css"]
})
export class DailyPanelComponent extends BaseListComponent implements OnInit {

    @ViewChild('iframe') iframe: ElementRef;

    everySecond: Observable<number> = timer(0, 1000);
    private subscription: Subscription;
    private chart: AmChart;
    private stateList: JourneyState[];
    private driverTotal: number;
    private driverList: Map<string, { count: number; journeys: Number[]; color: string }>;
    public selectedSlice: {
        tipoEvento: string;
        count: number;
        color: string;
        journeys: string;
        drivers: DriverVO[];
    };

    public dynamicStripedTable: DynamicStripedTableComponent;
    public searchName: string;

    public dataProvider: any[];
    public showGraphic: boolean;
 
    ngOnInit(): void {
        this.driverTotal = 0;
        this.loading = true;
        this.spinner.show();
        this.dataProvider = new Array();
        this.showGraphic = true;
        this.subscription = this.everySecond.subscribe((seconds) => {
            this.getIdJornadaRest();
        });
    }

    constructor(
        private authGuard: AuthGuard,
        private router: Router,
        private AmCharts: AmChartsService,
        private jouneyStateService: JourneyStateService,
        private eventService: EventsService,
        private journeyService: JourneyService,
        private utilService: UtilService,
        private spinner: NgxSpinnerService,
        pdfService: PDFService,
        private _compiler: Compiler
    ) {
        super(utilService, pdfService, authGuard);
        this._compiler.clearCache();
    }


    ngAfterViewInit() {
        this.loadJourneyStates();
        this.iframe.nativeElement.setAttribute('src', this.eventService.getUrlDasboard());
    }

    ngOnDestroy() {
        if (this.chart) {
            this.AmCharts.destroyChart(this.chart);
        }
        this.subscription.unsubscribe();
    }

    loadData() {
        this.loadGeneralDetails();
        let loggedUser: User = this.authGuard.loggedUser();
        this.loading = true;
    }

    /**
     * METHOD 
     * Recebe os dados e renderiza o gráfico.
     */
    buildGraphic(dataProvider: any[]) {
        this.chart = this.AmCharts.makeChart("pizzaChart", {
            startDuration: 0,
            type: "pie",
            theme: "light",
            titles: [
                {
                    text: "Total de motoristas [" + this.driverTotal + "]",
                    size: 16
                }
            ],
            dataProvider: dataProvider,
            valueField: "count",
            titleField: "tipoEvento",
            colorField: "color",
            balloon: {
                fixedPosition: true
            },
            listeners: [
                {
                    event: "clickSlice",
                    method: e => {
                        this.selectedSlice = e.dataItem.dataContext;
                        this.selectedSlice.drivers = new Array();
                        this.journeyService
                            .retrieveDrivers(this.selectedSlice.journeys)
                            .then(response => {
                                this.selectedSlice.drivers = response.response;
                            });
                        this.dynamicStripedTable = new DynamicStripedTableComponent(this.router, this.journeyService, this.utilService);
                        this.dynamicStripedTable.selectedSlice = this.selectedSlice;
                        e.chart.validateData();
                    }
                }
            ]
        });
    }

    loadGeneralDetails() {
        let loggedUser: User = this.authGuard.loggedUser();
        this.loading = true;

        this.journeyService.getSummary(loggedUser.selectedCompany.id, loggedUser.selectedBranch.id)
            .then(response => {
                this.tableHeaders = [
                    [
                        this.createHeader("Telefone", 0, true),
                        this.createHeader("Motorista", 1, true),
                        this.createHeader("Unidade", 2, true),
                        this.createHeader("Início Jornada", 3, true),
                        this.createHeader("Último Lançamento", 4, true),
                        this.createHeader("Último Evento", 5, true)
                    ]
                ];

                this.dataFields = [
                    this.createField("telefone", "string"),
                    this.createField("nomeMotorista", "string"),
                    this.createField("unidade", "string"),
                    this.createField("inicio", "datetime"),
                    this.createField("ultimoLancamento", "datetime"),
                    this.createField("tipoEvento", "string")
                ];
                this.data = response.response;

                this.loading = false;
                this.spinner.hide();
            });
    }


    /**
     * METHOD
     * Controla o câmbio entre a exbição do Gráfico e a Lista de Motoristas.
     */
    toggleView() {
        let loggedUser: User = this.authGuard.loggedUser();
        this.journeyService.mandarMostrarTodosNaDashboard(loggedUser.selectedCompany.id, 
            loggedUser.selectedBranch.id).then(response => {
                if(response.response){
                   console.log("Envio de todos os carros ok")
                }
            }
        );
    }

    loadJourneyStates() {
        this.jouneyStateService.getList().then(response => {
            this.stateList = response.response;
            this.loadData();
        });
    }

    getIdJornadaRest(){
        let loggedUser: User = this.authGuard.loggedUser();
        this.journeyService.getIdJornada(loggedUser.selectedCompany.id, 
            loggedUser.selectedBranch.id, loggedUser.id).then(response => {
                if(response.response != 0){
                    this.showJourney(response.response);
                }
            }
           );
    }


    showJourney(jornadaId) {
        this.journeyService.getSingleSummary(jornadaId).then(response => {
            localStorage.setItem('journey', JSON.stringify({
                summary: response.response,
                period: this.utilService.formatDate(response.response.inicio, "DD/MM/YYYY"),
                returnURL: 'adm/main'
            }));

            this.router.navigate(['adm/journey/form'], { skipLocationChange: true });
        });
    }
}
