import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Filtro } from 'app/classes/filtro';
import { ReportService } from 'app/services/report/report.service';
import { Veiculo } from 'app/classes/veiculo';
import { VeiculoService } from 'app/services/crud/veiculo.service';


@Component({
  selector: 'app-produtividade-veiculo',
  templateUrl: './produtividade-veiculo.component.html',
  styleUrls: ['./produtividade-veiculo.component.css']
})
export class ProdutividadeVeiculoComponent implements OnInit {

  dropdownList = [];
  selectedItems = [];
  dropdownSettings: {};
  public filtro: Filtro;
  public mostrarPdf: boolean = false;
  public pdfSrc: any;
  listaDeVeiculos: Veiculo[] = [];
  veiculo: Veiculo;
  responseBase64: String;
  tipoEvento: number = 0;
  tipoRelatorio: String = "PDF";

  @ViewChild('iframe') iframe: ElementRef;

  constructor(private veiculoService: VeiculoService, private reportService: ReportService) { }

  ngOnInit() {
    try {
      this.filtro = new Filtro();
      this.veiculoService.getLista().then(response => {
        var data = response.listaDeVeiculos;
        this.dropdownList = [];
        for (var i = 0; i < data.length; i++) {
          var veiculo = data[i];
          this.dropdownList.push({ item_id: veiculo.id, item_text: veiculo.placa });
        }

        this.dropdownSettings = {
          singleSelection: false,
          idField: 'item_id',
          textField: 'item_text',
          selectAllText: 'Todos',
          unSelectAllText: 'Desmarcar todos',
          itemsShowLimit: 3,
          allowSearchFilter: true,
          searchPlaceholderText: 'Pesquisar'
        };
      });

    } catch (error) {
      console.error(error);
    }
  }


  onItemSelect(item: any) {
    this.inserirMotoristaSelecionado(item);
  }
  onSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirMotoristaSelecionado(items[i]);
    }
  }
  onDeSelect(item: any) {
    this.removerMotoristaSelecionado(item);
  }

  inserirMotoristaSelecionado(item: any): void {
    try {
      var ve = this.listaDeVeiculos.find(m => m.id == item.item_id);
      if (!ve) {
        this.veiculo = new Veiculo();
        this.veiculo.id = item.item_id;
        this.listaDeVeiculos.push(this.veiculo);
      }
    } catch (error) {
      console.log(error);
    }
  }

  removerMotoristaSelecionado(item: any): void {
    try {
      let index = this.listaDeVeiculos.findIndex(m => m.id == item.item_id);
      this.listaDeVeiculos.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }


  doSearch() {
    try {
      this.filtro.listaDeVeiculos = this.listaDeVeiculos;
      this.filtro.tipoArquivo = this.tipoRelatorio;
      if (this.tipoEvento && this.tipoEvento > 0) {
        this.filtro.tipoEventoMotorista = this.tipoEvento;
      }
      this.reportService.getReportRelatorioProdutividadeVeiculo(this.filtro).then(response => {
        this.iframe.nativeElement.setAttribute('src', response);
      });

    } catch (error) {
      console.error(error);
    }
  }

  trocarRadioButton(value) {
    try {
      this.tipoRelatorio = value;
    } catch (error) {
      console.log(error);
    }
  }

}
