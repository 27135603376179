import { Injectable } from '@angular/core';
import { CRUDService } from "../base/crud.service";
import { Http } from "@angular/http";
import { environment } from "../../../environments/environment";
import { AuthGuard } from "../../guards/auth.guard";
import { User } from '../../classes/user';
import { RESTResponseVO } from "app/classes/restresponsevo";
import { SascarVeiculo } from 'app/classes/sascar-veiculo';
import { RetornoSascarVeiculo } from 'app/classes/retorno-sascar-veiculo';

@Injectable({
  providedIn: 'root'
})
export class SascarVeiculoService extends CRUDService<SascarVeiculo>{

  public company: number;
  constructor(http: Http, public authGuard: AuthGuard) {
    super(http, environment.baseURL + 'App/v2/web/sascarveiculo', true);
    var loggedUser: User = authGuard.loggedUser();
    this.company = loggedUser.selectedCompany.id;
    this.listURL = environment.baseURL + "App/v2/web/sascarveiculo/buscarPelaEmpresa/"+ this.company;
  }

  getLista() {
    const url: string =
      (this.listURL === undefined && `${this.defaultUrl}`) || `${this.listURL}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoSascarVeiculo;
        }
      })
      .catch(this.handleError);
  }

  getById(id: number) {
    const url = `${this.defaultUrl}/buscarPeloId/${id}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoSascarVeiculo;
        }
      })
      .catch(this.handleError);
  }


  cadastrarSascarVeiculo(sascarVeiculo: SascarVeiculo): Promise<RESTResponseVO<SascarVeiculo>> {
    const url = `${this.defaultUrl}/cadastrar`;
    sascarVeiculo.empresa = this.company;
    return this.enviarRequisicao(sascarVeiculo, url);
  }


  editarSascarVeiculo(sascarVeiculo: SascarVeiculo): Promise<RESTResponseVO<SascarVeiculo>> {
    const url = `${this.defaultUrl}/editar`;
    sascarVeiculo.empresa = this.company;
    return this.enviarRequisicao(sascarVeiculo, url);
  }

  enviarRequisicao(sascarVeiculo: SascarVeiculo, url: string): Promise<RESTResponseVO<SascarVeiculo>> {
    try {
      return this.http
        .post(url, JSON.stringify(sascarVeiculo), { headers: this.headers })
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            var retorno: RetornoSascarVeiculo = response.json() as RetornoSascarVeiculo;
            var ret: SascarVeiculo = retorno.sascarVeiculo;
            if (ret === null) {
              ret = new SascarVeiculo();
            }
            ret.id = 0;
            return ret;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }

  protected handleError(error: any): Promise<any> {
    console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
    return Promise.reject(error.message || error);
  }
}
