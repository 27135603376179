import { Sascar } from './../../classes/sascar';
import { Injectable } from '@angular/core';
import { RESTResponseVO } from '../../classes/restresponsevo';
import { Http, Headers } from '@angular/http';
import { AuthGuard } from '../../guards/auth.guard';
import { environment } from '../../../environments/environment';

@Injectable()
export class SascarConfigService {
    constructor(private http: Http, public authGuard: AuthGuard) { }
    protected headers = new Headers({ 'Content-Type': 'application/json' });
    saveSascarConfig (operadorLancamento: number, sascar: Sascar){
      console.log(sascar);
        const url: string = environment.baseURL + 'App/v2/web/sascarconfig/'  + operadorLancamento;
        return this.http
            .post(url, sascar, { headers: this.headers })
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<Sascar>;
            })
            .catch(this.handleError);
    }

    protected handleError(error: any): Promise<any> {
        console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
        return Promise.reject(error.message || error);
    }
}
