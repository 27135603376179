import {Component, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthGuard } from 'app/guards/auth.guard';
import { User } from 'app/classes/user';
import { LiberacaoPontoService } from 'app/services/liberacao-ponto.service';
import { LiberacaoPonto, Qrcode } from 'app/classes/liberacao-ponto';
import { BranchService } from 'app/services/crud/branch.service';

@Component({
    selector: 'app-liberacao-ponto',
    templateUrl: './liberacao-ponto.component.html',
    styleUrls: ['./liberacao-ponto.component.css']
})
export class LiberacaoPontoComponent implements OnInit {
    
    public loading: boolean = false;
    public qrCode: string | undefined;
    public strQrCode : string;
    loggedUser: User;
    liberacaoPonto : LiberacaoPonto;
    verificandoLeitura : boolean = false;
    unidades: any;
    opcaoSelecionada: number;
    ultimoGerado : string;
    formGroup: FormGroup;

    constructor(
        private authGuard: AuthGuard,
        private fb: FormBuilder,
        private liberacaoPontoService: LiberacaoPontoService,
        private branchService : BranchService) 
    {}

    ngOnInit(): void {
      this.formGroup = this.fb.group({
        idUnidade: [null, [Validators.required]]
      });
      this.loggedUser =  this.authGuard.loggedUser();
      this.obterUnidades();
      if (this.loggedUser.selectedBranch.id > 0) {
        this.gerarNovoQrcode(this.loggedUser.selectedBranch.id);
      }
      this.iniciarTimer();
    }

    onChange(event) {
      if(this.opcaoSelecionada > 0) {
        this.gerarNovoQrcode(this.opcaoSelecionada);
      }
    }

    obterUnidades() {
      this.branchService.getList().then(response => {
        this.unidades = response.response;
        this.unidades.sort((a, b) => {
          if (a.apelido < b.apelido) {
              return -1;
          }
          if (a.apelido > b.apelido) {
              return 1;
          }
          return 0;
        });

        this.opcaoSelecionada = this.loggedUser.selectedBranch.id;
      });
  }

    iniciarTimer() {
      console.log('inicia setInterval de 5 em 5 segundos');
      setInterval(() => {
        if (this.loggedUser.selectedBranch.id > 0) {
          this.verificarLeituraQrcode(this.loggedUser.selectedBranch.id);
        } else {
          this.verificarLeituraQrcode(this.opcaoSelecionada);
        }
      }, 5000);
    }

    async gerarImagemQRCode(texto: string) {
      try {
        this.qrCode = await this.liberacaoPontoService.gerarImagemQRCode(texto, 400, 400);
      } catch (error) {
        console.error('Erro ao gerar QR code:', error);
      }
    }

    gerarNovoQrcode(idUnidade : number) {
      this.loading = true;
      console.log('gerando novo qrcode...');
      this.liberacaoPontoService.gerarQrcode(idUnidade)
            .then(response=> {
              this.liberacaoPonto = response;
              console.log(this.liberacaoPonto);
              if(this.liberacaoPonto) {
                if(this.liberacaoPonto.novoQrcode.code) {
                  this.strQrCode = this.liberacaoPonto.novoQrcode.code;
                  this.ultimoGerado = this.liberacaoPonto.novoQrcode.dataGeracao;
                  console.log(this.strQrCode);
                  this.gerarImagemQRCode(this.liberacaoPonto.novoQrcode.code);
                  this.verificandoLeitura = false;
                } 
              }
              this.loading = false;
            });
    }

    verificarLeituraQrcode(idUnidade : number) {
      if (!this.verificandoLeitura && this.strQrCode) {
        this.verificandoLeitura = true;
        console.log('verificando leitura do qrcode: ' + this.strQrCode);
        this.liberacaoPontoService.verificarLeituraQrcode(idUnidade, this.strQrCode)
              .then(response=> {
                this.liberacaoPonto = response;
                console.log(this.liberacaoPonto);
                if(this.liberacaoPonto) {
                  if(this.liberacaoPonto.codigoRetorno == 1) {
                    console.log('qrcode foi lido.');
                    this.gerarNovoQrcode(idUnidade);
                  } else {
                    this.verificandoLeitura = false;
                    console.log('qrcode ainda não foi lido.');
                  }
                }
              });
      }
    }

    refresh() {
      if (this.loggedUser.selectedBranch.id > 0) {
        this.gerarNovoQrcode(this.loggedUser.selectedBranch.id);
      } else {
        this.gerarNovoQrcode(this.opcaoSelecionada);
      }
    }
}