import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-new-totalizadores',
  templateUrl: './new-totalizadores.component.html',
})
export class NewTotalizadoresComponent implements OnInit {

  safeUrl: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer
  ) {
    const url = environment.baseWebUrlV2 + 'totalizadores';
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit() {
    
  }

}
