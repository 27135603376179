import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DatePipe } from '@angular/common';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';

import { Address } from '../../../../classes/address';
import { Company } from '../../../../classes/company';
import { City } from '../../../../classes/city';
import { CityService } from '../../../../services/crud/city.service';
import * as moment from 'moment';
import { BaseFormComponent } from '../../../base/base-form/base-form.component';
import { RESTResponseVO } from '../../../../classes/restresponsevo';
import { Branch } from '../../../../classes/branch';
import { BranchService } from '../../../../services/crud/branch.service';
import { AuthGuard } from '../../../../guards/auth.guard';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TipoFuncionarioService } from 'app/services/crud/tipo-funcionario.service';
import { TipoFuncionario } from 'app/classes/tipo-funcionario';
import { UtilService } from "../../../../services/utils.service";

@Component({
    selector: 'app-company-branch',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css'],
    providers: [DatePipe]
})
export class BranchFormComponent extends BaseFormComponent<Branch>{
    inputCtrl = new FormControl();
    formGroup: FormGroup;
    tipoFuncionarioList: TipoFuncionario[];

    constructor(private branchService: BranchService,
        private datePipe: DatePipe,
        private tipoFuncionarioService: TipoFuncionarioService,
        route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private authGuard: AuthGuard,
        private utils: UtilService,
        router: Router) {
        super(route, router, 'adm/branch/list');

        this.formGroup = this.formBuilder.group({
            tempoVeiculoInativo: [null]
        });
    }

    assembleObjectFromForm(): void {
    }

    loadAuxiliaryLists(): void {
        this.tipoFuncionarioService.getAll(this.authGuard.loggedUser().selectedCompany.id, this.authGuard.loggedUser().selectedBranch.id).then(response => {
            this.tipoFuncionarioList = response;
        });
    }

    loadObject(): void {
        if (this.activeID && (this.activeID !== 0)) {
            this.branchService.getById(this.activeID).then(response => {
                const resultItem: Branch = response.response;

                if (!resultItem.endereco) {
                    resultItem.endereco = new Address();
                }
                if (!resultItem.tipoFuncionario) {
                    resultItem.tipoFuncionario = new TipoFuncionario();
                }
                this.activeObject = resultItem;
                this.ajustaTimeComponent(resultItem.tempoVeiculoInativo);
                this.activeObject.limiteSemAtividades = this.utils.miliToHHmm(this.activeObject.limiteSemAtividades);
                this.activeObject.tempoDescansoDirecao = this.utils.miliToHHmm(this.activeObject.tempoDescansoDirecao);
                this.activeObject.intrajornadaLimiteRefeicao = this.utils.miliToHHmm(this.activeObject.intrajornadaLimiteRefeicao);
            });
        }
        this.loading = false;
    }

    ajustaTimeComponent(tempo) {
        if (tempo === undefined) { return; }
        if (tempo === null) { return; }
        if (tempo.length !== 4) { return; }
        const hora = tempo.substring(0, 2);
        const minutos = tempo.substring(2, 4);
        // tslint:disable-next-line:max-line-length
        const isoStr1 = `2022-07-21T${hora}:${minutos}:00.820Z`; // cria uma data porque o retorno é apenas uma string e a diretiva espera uma data
        const date2 = new Date(isoStr1.slice(0, -1));
        this.inputCtrl.setValue(date2); // faz o set no campo input mask
    }

    initObject(): void {
        this.activeObject = new Branch();
        this.activeObject.endereco = new Address();
        this.activeObject.tipoFuncionario = new TipoFuncionario();
        this.activeObject.empresaId = new Company();
        this.activeObject.empresaId.id = this.authGuard.loggedUser().selectedCompany.id;
        this.loading = false;
    }

    doCreate(t: Branch): Promise<RESTResponseVO<Branch>> {
        t.limiteSemAtividades = this.utils.HHmmToMili(t.limiteSemAtividades);
        t.tempoDescansoDirecao = this.utils.HHmmToMili(t.tempoDescansoDirecao);
        t.intrajornadaLimiteRefeicao = this.utils.HHmmToMili(t.intrajornadaLimiteRefeicao);
        return this.branchService.create(t);
    }

    doEdit(t: Branch): Promise<RESTResponseVO<Branch>> {
        t.limiteSemAtividades = this.utils.HHmmToMili(t.limiteSemAtividades);
        t.tempoDescansoDirecao = this.utils.HHmmToMili(t.tempoDescansoDirecao);
        t.intrajornadaLimiteRefeicao = this.utils.HHmmToMili(t.intrajornadaLimiteRefeicao);
        return this.branchService.update(t);
    }

    doDelete(t: Branch): Promise<RESTResponseVO<string>> {
        return this.branchService.delete(t.id);
    }

    setActiveID(): void {
        this.activeID = this.activeObject.id;
    }

    salvar(activeObject) {
        // tslint:disable-next-line:max-line-length
        if (this.inputCtrl.value !== null && this.inputCtrl.value !== undefined && this.inputCtrl.value !== '') {
            activeObject.tempoVeiculoInativo = moment(this.inputCtrl.value).format('HHmm');
        }
        this.btnSaveClick(activeObject);
    }
}
