import { Http } from '@angular/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { Meta } from 'app/classes/meta';
import { AuthGuard } from 'app/guards/auth.guard';
import { User } from 'app/classes/user';
import { CRUDService } from '../base/crud.service';

@Injectable({
  providedIn: 'root'
})
export class MetasService extends CRUDService<Meta> {

  public defaultUrlAspCore: string;
  public company: number;
  public unidade: number;

  constructor(public http: Http, public authGuard: AuthGuard) {
    super(http, environment.baseURL + 'App/v2/web/meta', true);
    var loggedUser: User = authGuard.loggedUser();
    this.company = loggedUser.selectedCompany.id;
    this.unidade = loggedUser.selectedBranch.id;
    this.listURL = environment.baseURL + "App/v2/web/meta/buscarMetas";
  }

  getLista() {
    const url: string = `${this.listURL}/${this.company}/${this.unidade}`;
    return this.http.get(url).toPromise().then(response => {
      if (this.restResponseWrapper) {
        return response.json();
      }
    })
      .catch(this.handleError);
  }

  getById(id: number) {
    const url = `${this.defaultUrl}/buscarPeloId/${id}`;
    return this.http.get(url).toPromise().then(response => {
      if (response) {
        return response.json();
      } else {
        return [];
      }
    })
      .catch(this.handleError);
  }

  create(meta: Meta) {
    const url = `${this.defaultUrl}/cadastrar`;
    try {
      return this.http
        .post(url, JSON.stringify(meta), { headers: this.headers })
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            var retorno = response.json();
            return retorno;
          }
        })
        .catch(this.handleError);
    } catch (error) {
      console.log(error);
    }
  }

  editarMeta(id: string, meta: Meta) {
    const url = `${this.defaultUrl}/editar/${id}`;
    return this.http.put(url, meta).toPromise().then(response => {
      if (response) {
        return response.json();
      } else {
        return [];
      }
    })
      .catch(this.handleError);
  }

  delete(id: string) {
    const url = `${this.defaultUrl}/${id}`;
    return this.http.get(url).toPromise().then(response => {
      if (response) {
        return response.json();
      } else {
        return [];
      }
    })
      .catch(this.handleError);
  }
}
