import { IDEntity } from './IDEntity';
import { VehicleType } from './vehicle-type';
import { Modelo } from './modelo';
import { Equipamento } from './equipamento';
import { Company } from './company';
import { Branch } from './branch';
import { Hodometro } from './hodometro';
import { Time } from '@angular/common';
export class Veiculo extends IDEntity {
    placa:string;
    cor:string;
    renavan:string;
    tiposDeVeiculo:VehicleType;
    anoFabricacao:string;
    anoModelo:string;
    chassi:string;
    dataCadastro:Date;
    observacao:string;
    velocidadeMaxima:number;
    velocidadeMaximaChuva:number;
	velocidadeMaximaDesacelaracao:number;
	velocidadeMaximaCurva:number;
    renavam:string;
    status:boolean;
    tipo:string;
    cidade:string;
    telefoneProprietario:string;
    usaEntradaDigitalUm:boolean;
    excessoVelocidade:boolean;
    antiJummer:boolean;
    bateriaCarroBaixa:boolean;
    faltaEnergiaPrincipal:boolean;
    panico:boolean;
    quantidadeDiasSemTrasmissao:boolean;
    roubo:boolean;
    semComunicacao:boolean;
    vinteQuatroHorasComunicacao:boolean;
    ativaRotaNoMapa:boolean;
    ativaValidacaoDeCerca:boolean;
    trocaDeHorimetro:string;
    rpmModoEconomicoMinimo:number;
    rpmModoEconomicoMaximo:number;
    rpmMaximo:number;
    rpmInicioFaixaAzul:number;
    rpmFimFaixaAzul:number;
    rpmInicioFaixaEconomica:number;
    rpmFimFaixaEconomica:number;
    rpmInicioFaixaVerde:number;
    rpmFimFaixaVerde:number;
    rpmInicioFaixaAmarela:number;
    rpmFimFaixaAmarela:number;
    rpmInicioMarchaLenta:number;
    rpmFimMarchaLenta:number;
    geraEnderecoAutomatico:boolean;
    validarIbutton:boolean;
    cargaMaxima:number;
    combustivel:string;
    gerarControleViagemAutomatico:boolean;
    distanciaMinimaViagemAutomatica:number;
    tipoEntradaDigital:string;
    ativarModuloTemperaturaCamaraFria:boolean;
    enviarNotificacaoTemperaturaAplicativo:boolean;
    temperaturaMinima:number;
    temperaturaMaxima:number;
    modelo:Modelo;
    equipamento:Equipamento;
    equipamentoDois:Equipamento;
    empresaVO:Company;
    tipoDeVeiculo:number;
    unidade: Branch;
    unidadeId:number;
    faturamentoFormatada:string;
    porcentagemFaturamento:number;
    tipoEntradaDigitalDois:string;
    usaEntradaDigitalDois:boolean;
    tipoEntradaDigitalTres:string;
    usaEntradaDigitalTres:boolean;
    tipoEntradaDigitalQuatro:string;
    usaEntradaDigitalQuatro:boolean;
    bloquearVeiculo:boolean;
    validarRota:boolean;
    ultimoHodometro:number;
    mostrarViam:boolean;
    listaHodometro:Hodometro[];
    tempoParadoForaLocalCadastrado: string;
    aberturaBauForaLocalCadastrado: boolean;
    limiteTempMinima: number;
    limiteTempMaxima: number;
    tempoVeiculoInativo:String;
}
