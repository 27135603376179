import { Component, OnInit } from '@angular/core';
import { AuthGuard } from '../../../guards/auth.guard';
import { User } from '../../../classes/user';
import { environment } from '../../../../environments/environment';
import { ChatService } from '../../../services/crud/chat.service';
import { Router } from '@angular/router';
import { AlertaService } from '../../../services/crud/alerta.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.css']
})
export class HeaderComponent implements OnInit {
    time: Date;
    loggedUser: User;
    notificationsCount: Observable<number>;
    showCountAlertas: boolean = false;

    constructor(private authGuard: AuthGuard,
        private chatService: ChatService,
        private alertaService:AlertaService,
        private router: Router) {
        window.setInterval(() => this.time = new Date(), 1000);
        this.loggedUser = this.authGuard.loggedUser();
    }

    ngOnInit() {
        let bsurl = "";
        let loggedUser = this.authGuard.loggedUser();

        if (!loggedUser.selectedCompany || !loggedUser.selectedBranch) {
            this.authGuard.logout();
            return;
        }

        this.chatService.getBoshServiceUrl(loggedUser.selectedCompany.id).then(response => {
            /**Essa bosta chamava o chat do openfire */
            if (response.error) {
                console.error(response.error);
            } else {
                bsurl = response.response;
            }
        });

        this.alertaService.getTotalAlertaNovos(loggedUser.id).then(response =>{
          if (response.error) {
            console.error(response.error);
        } else {
            var count = response.response;
            if(count > 0){
              this.showCountAlertas = true;
            }
            this.alertaService.updateNotificationsCount(count);
            this.notificationsCount = this.alertaService.getNotificationsCount();
        }
        })
    }

    public logout(): void {
        this.authGuard.logout();
    }

    public irParaTelaQuadroAviso(){
       this.router.navigateByUrl(`adm/quadro-alertas`);
    }
}
