import { Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FiltroOpcoesTelemetria } from "app/classes/filtro-opcoes-telemetria";
import { MapaEficiencia } from "app/classes/mapa-eficiencia";
import { ControleIndicadoresTelemetriaService } from "app/services/crud/controle-indicadores-telemetria.service";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import am4lang_pt_BR from "@amcharts/amcharts4/lang/pt_BR";
import { DetalhesEficienciaVeiculosComponent } from "../detalhes-eficiencia-veiculos/detalhes-eficiencia-veiculos";
import { MapaLocalizacaoVeiculoComponent } from "../mapa-localizacao-veiculo/mapa-localizacao-veiculo";

@Component({
  selector: "mapa-eficiencia-veiculos",
  templateUrl: "./mapa-eficiencia-veiculos.html",
  styleUrls: ["./mapa-eficiencia-veiculos.scss"],
})
export class MapaEficienciaVeiculoComponent implements OnInit {
  filtro = new FiltroOpcoesTelemetria();

  filtroAtual!: FiltroOpcoesTelemetria;

  retornoMapa: Array<MapaEficiencia> = [];

  dataAtual: Date;

  exibeBotao: boolean = true;

  chart: any;

  constructor(
    public dialogRef: MatDialogRef<MapaEficienciaVeiculoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public controleIndicadoresTelemetriaService: ControleIndicadoresTelemetriaService
  ) {
    this.filtro = data.filtro;
  }

  ngOnInit() {
    this.buscarMapaEficiencia(this.filtro);
  }

  buscarMapaEficiencia(filtro: FiltroOpcoesTelemetria) {
    this.dataAtual = new Date(this.filtro.dataFinal);
    this.controleIndicadoresTelemetriaService
      .buscarMapaEficiencia(filtro)
      .then((response) => {
        this.retornoMapa = response.listaMapaDeEficiencia;
        this.buildGraphic(this.retornoMapa);
      });
  }

  doNextOrPreviewDate(value: number) {
    var dataInicial = this.filtro.dataInicial;
    var dataInicialDate = new Date(dataInicial);
    var novaDataInicial = dataInicialDate.setDate(
      dataInicialDate.getDate() + value
    );
    var dataFinal = this.filtro.dataFinal;
    var dataFinalDate = new Date(dataFinal);
    var novaDataFinal = dataFinalDate.setDate(dataFinalDate.getDate() + value);
    this.filtro.dataInicial = novaDataInicial;
    this.filtro.dataFinal = novaDataFinal;

    this.buscarMapaEficiencia(this.filtro);

    this.dataAtual = new Date(this.filtro.dataFinal);
  }

  close() {
    this.dialogRef.close();
  }

  buildGraphic(dados: MapaEficiencia[]) {
    try {
      am4core.useTheme(am4themes_animated);
      this.chart = am4core.create("chartdiv", am4charts.XYChart);
      this.chart.data = dados
        .map((x) => {
          return {
            rpm: x.rpm,
            velocidade: x.velocidade,
            date: new Date(x.dataEvento),
            latitude: x.latitude,
            longitude: x.longitude,
          };
        })
        .sort((a, b) => a.date.getTime() - b.date.getTime());

      this.chart.cursor = new am4charts.XYCursor();

      let dateAxis = new am4charts.DateAxis();
      //dateAxis.gridIntervals.setAll([{ timeUnit: "minute", count: 30 }]);
      dateAxis.fontSize = 8;
      this.chart.xAxes.push(dateAxis);
      this.chart.seriesContainer.draggable = false;
      this.chart.seriesContainer.resizable = false;

      this.chart.cursor = new am4charts.XYCursor();
      this.chart.cursor.xAxis = dateAxis;
      this.chart.scrollbarX = new am4core.Scrollbar();

      let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;

      let valueAxis2 = this.chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis2.renderer.grid.template.location = 1;
      valueAxis2.min = 0;
      valueAxis2.renderer.opposite = true;

      let velocidadeSeries = this.chart.series.push(new am4charts.LineSeries());
      velocidadeSeries.name = "VELOCIDADE";
      velocidadeSeries.xAxis = dateAxis;
      velocidadeSeries.yAxis = valueAxis2;
      velocidadeSeries.dataFields.valueY = "velocidade";
      velocidadeSeries.dataFields.dateX = "date";
      velocidadeSeries.connect = false;
      velocidadeSeries.sequencedInterpolation = true;
      velocidadeSeries.defaultState.transitionDuration = 1500;
      velocidadeSeries.tensionX = 0.8;
      velocidadeSeries.tensionY = 1;
      var velocidadeCor = "#00c227";
      velocidadeSeries.stroke = am4core.color(velocidadeCor);
      velocidadeSeries.strokeWidth = 3;
      velocidadeSeries.tooltipText = "VELOCIDADE: {valueY}";

      velocidadeSeries.segments.template.interactionsEnabled = true;
      velocidadeSeries.segments.template.events.on(
        "hit",
        (ev) => {
          window.open(
            "http://maps.google.com/maps?q=" +
              ev.target.dataItem.component.tooltipDataItem.dataContext
                .latitude +
              "," +
              ev.target.dataItem.component.tooltipDataItem.dataContext
                .longitude,
            "_blank"
          );
        },
        this
      );

      let rpmSeries = this.chart.series.push(new am4charts.LineSeries());
      rpmSeries.name = "RPM";
      rpmSeries.xAxis = dateAxis;
      rpmSeries.yAxis = valueAxis;
      rpmSeries.dataFields.valueY = "rpm";
      rpmSeries.dataFields.dateX = "date";
      rpmSeries.sequencedInterpolation = true;
      rpmSeries.sequencedInterpolation = true;
      rpmSeries.defaultState.transitionDuration = 1500;
      rpmSeries.tensionX = 0.8;
      rpmSeries.tensionY = 1;
      rpmSeries.connect = false;
      var rpmCor = "#FF6600";
      rpmSeries.stroke = am4core.color(rpmCor);
      rpmSeries.strokeWidth = 3;
      rpmSeries.tooltipText = "RPM: {valueY}";
      rpmSeries.segments.template.interactionsEnabled = true;
      rpmSeries.segments.template.events.on(
        "hit",
        (ev) => {
          window.open(
            "http://maps.google.com/maps?q=" +
              ev.target.dataItem.component.tooltipDataItem.dataContext
                .latitude +
              "," +
              ev.target.dataItem.component.tooltipDataItem.dataContext
                .longitude,
            "_blank"
          );
        },
        this
      );

      //this.chart.exporting.menu = new am4core.ExportMenu();

      this.chart.legend = new am4charts.Legend();
    } catch (error) {
      console.log(error);
    }
  }

  createSeries(field, name, cor) {
    // Set up series
    let series = this.chart.series.push(new am4charts.LineSeries());
    series.name = name;
    series.dataFields.valueY = field;
    series.dataFields.dateX = "date";
    // series.sequencedInterpolation = true;
    series.connect = false;

    series.stroke = am4core.color(cor);
    series.columns.template.stroke = am4core.color(cor);
    series.columns.template.fill = am4core.color(cor);
    // Make it stacked
    // series.stacked = true;

    // Configure columns
    // series.columns.template.width = am4core.percent(60);
    // series.columns.template.tooltipText =
    //   "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}%";

    // Add label
    let labelBullet = series.bullets.push(new am4charts.LabelBullet());

    // if (field === "baixo") {
    //   labelBullet.label.text = "{horasIndisponivel} h - {valueY}%";
    // }
    // if (field === "medio") {
    //   labelBullet.label.text = "{horasOcioso} h - {valueY}%";
    // }
    // if (field === "alto") {
    //   labelBullet.label.text = "{horasFaturando} h - {valueY}%";
    // }

    // labelBullet.locationY = 0.5;
    // labelBullet.label.hideOversized = true;

    series.columns.template.events.on(
      "hit",
      function (ev) {
        const val = ev.target.dataItem.categoryX;
        this.criarTimeLine(val);
      },
      this
    );

    return series;
  }
}
