import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Headers, Http } from '@angular/http';
import { AuthGuard } from 'app/guards/auth.guard';
import { User } from 'app/classes/user';
import { RetornoRota } from 'app/classes/retorno-rota';
import { Rota } from 'app/classes/rota';
import { RESTResponseVO } from 'app/classes/restresponsevo';
import { CRUDService } from './base/crud.service';
import { Company } from 'app/classes/company';
import { HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class RotaService {

  public company: number;
  protected restResponseWrapper: boolean;
  public loggedUser: User;
  public authGuardM: AuthGuard;
  private headers = new Headers({ 'Content-Type': 'application/json' });
  private urlPrincial = environment.baseURL + 'App/v2/web/faturamento/';  // URL to web api desenv
  private listURL: String;

  constructor(private http: Http, public authGuard: AuthGuard) {
    this.authGuardM = authGuard;
    this.loggedUser = authGuard.loggedUser();
    this.company = this.loggedUser.selectedCompany.id;
    var branch: string = this.loggedUser.selectedBranch.id === 0 && '0' || this.loggedUser.selectedBranch.id.toString();
  }

  getLista(useBranch?: boolean) {
    try {
      this.loggedUser = this.authGuardM.loggedUser();
      this.company = this.loggedUser.selectedCompany.id;
      var branch: string = this.loggedUser.selectedBranch.id === 0 && '0' || this.loggedUser.selectedBranch.id.toString();
      let url = '';
      if(!useBranch){
        url = environment.baseURL + "App/v2/web/rota/listarRotas/" + this.company + "/" + branch;
      }else{
        url = environment.baseURL + "App/v2/web/rota/listarRotas/" + this.company + '/0';
      }
      return this.http
        .get(url)
        .toPromise()
        .then(response => {
          return response.json() as RetornoRota;
        })
        .catch(this.handleError);
    } catch (error) {
      console.error(error);
    }
  }

  consultarServicoGoogle(pathValues){
    try {
      var url = 'https://roads.googleapis.com/v1/snapToRoads?interpolate=true&key=AIzaSyBCd_YCL_S9kcWvbaQmBqbb2nAhCfZJHMc&path='+pathValues.join('|');

      return this.http
        .get(url)
        .toPromise()
        .then(response => {
          return response.json();
        })
        .catch(this.handleError);
    } catch (error) {
      console.error(error);
    }
  }

  cadastrarRota(rota: Rota): Promise<RESTResponseVO<Rota>> {
    var branch: string = this.loggedUser.selectedBranch.id === 0 && '0' || this.loggedUser.selectedBranch.id.toString();
    const url: string = environment.baseURL + "App/v2/web/rota/cadastrarRota";
    var empresa: Company = new Company();
    empresa.id = this.company;
    rota.cliente = empresa;
    rota.idUnidade = Number(branch);
    return this.enviarRequisicao(rota, url);
  }

 editarRota(rota: Rota): Promise<RESTResponseVO<Rota>> {
    var branch: string = this.loggedUser.selectedBranch.id === 0 && '0' || this.loggedUser.selectedBranch.id.toString();
    const url: string = environment.baseURL + "App/v2/web/rota/editarRota";
    var empresa: Company = new Company();
    empresa.id = this.company;
    rota.cliente = empresa;
    rota.idUnidade = Number(branch);
    return this.enviarRequisicao(rota, url);
  }


  excluirRota(rota: Rota): Promise<RESTResponseVO<Rota>> {
    var branch: string = this.loggedUser.selectedBranch.id === 0 && '0' || this.loggedUser.selectedBranch.id.toString();
    const url: string = environment.baseURL + "App/v2/web/rota/excluirRota";
    var empresa: Company = new Company();
    empresa.id = this.company;
    rota.cliente = empresa;
    rota.idUnidade = Number(branch);
    return this.enviarRequisicao(rota, url);
  }

  getById(id: number) {
    const url: string = environment.baseURL + "App/v2/web/rota/buscarRota/" + id;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        return response.json() as RetornoRota;
      })
      .catch(this.handleError);
  }

  gravarRegraRota(regra: any) {
    const url: string = environment.baseURL + "App/v2/web/rota/salvarRegrasRota";
    return this.http
      .post(url, JSON.stringify(regra), { headers: this.headers })
      .toPromise()
      .then(response => {
        return response.json();
      })
      .catch(this.handleError);
  }

  excluirRegraRota(regraId: number) {
    const url: string = environment.baseURL + `App/v2/web/rota/desativarRegrasRota/${regraId}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        return response.json();
      })
      .catch(this.handleError);
  }

  enviarRequisicao(rota: Rota, url: string): Promise<RESTResponseVO<Rota>> {
    try {
      return this.http
        .post(url, JSON.stringify(rota), { headers: this.headers })
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            var retorno: RetornoRota = response.json() as RetornoRota;
            var rotaN: Rota = retorno.rota;
            if (rotaN === null) {
              rotaN = new Rota();
            }
            rotaN.id = 0;
            return rotaN;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }

  protected handleError(error: any): Promise<any> {
    console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
    return Promise.reject(error.message || error);
  }
}


