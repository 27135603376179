import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Injectable()
export class ValidadorService {
    constructor() { }

    /** Retorna hint de tamanho do texto. Usada no mat-hint (html). */
    public getMaxLengthHint(control: any, max: number): string {
        const value = <string>control.value;
        if (max > 0) { return `${!!value ? value.length : 0} / ${max}`; } else { return ''; }
    }


    // -------------------------------------------------------------------------------------------------------------------------
    // validações do formulário
    mostrarCSSErro(campo: string, form: FormGroup) {
        return {
            'is-invalid': this.campoValido(campo, form),
            '': this.campoValido(campo, form)
        };
    }

    mostrarCSSErroDinamico(campo: string, form: FormGroup, validar: boolean) {
        if (validar) {
            return {
                'is-invalid': this.campoValido(campo, form),
                '': this.campoValido(campo, form)
            };
        } else {
            return '';
        }
    }

    campoValido(campo: string, form: FormGroup) {
        return !form.get(campo).valid &&
            form.get(campo).touched;
    }

    validarTodosCampos(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validarTodosCampos(control);
            }
        });
    }
}
