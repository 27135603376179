import { Component, OnInit } from "@angular/core";
import { FiltroOpcoesTelemetria } from "app/classes/filtro-opcoes-telemetria";
import { ItemEficienciaTelemetria, ListaDeDetalhesEficiencia } from "app/classes/ItemEficienciaTelemetria";
import { ControleIndicadoresTelemetriaService } from "app/services/crud/controle-indicadores-telemetria.service";

@Component({
  selector: "app-tela-indicador-telemetria",
  templateUrl: "./tela-indicador-telemetria.component.html",
  styleUrls: ["./tela-indicador-telemetria.component.css"],
})
export class TelaIndicadorTelemetriaComponent implements OnInit {
  public retorno: any;
  carregandoPesquisa: boolean = false;
  tabelaListaDeDetalhesEficiencia: ListaDeDetalhesEficiencia[] = [];
  labelTabelaListaDeDetalhesEficiencia: string = "";

  constructor(
    private controleIndicadoresTelemetriaService: ControleIndicadoresTelemetriaService
  ) {}

  filtroFront: FiltroOpcoesTelemetria;

  filtroDefault: FiltroOpcoesTelemetria = {
    idsUnidades: [],
    dataInicial: null,
    dataFinal: null,
    listaDeModelos: [],
    listaDeVeiculos: [],
    listaDeMotorista: [],
    veiculo: null,
  };

  ngOnInit(): void {
    this.pesquisarOperacao(this.filtroDefault);
  }

  doPesquisarOperacao(filtro: FiltroOpcoesTelemetria) {
    this.filtroFront = filtro;
    this.pesquisarOperacao(filtro);
  }

  definirTabelaListaDeDetalhesEficiencia(event: { label: string, item: ItemEficienciaTelemetria }) {
    if (event) {
      this.tabelaListaDeDetalhesEficiencia = event.item.listaDeDetalhesEficiencia;
      this.labelTabelaListaDeDetalhesEficiencia = event.label;
    } else {
      this.tabelaListaDeDetalhesEficiencia = [];
    }
    // console.log(event)
  }

  private pesquisarOperacao(filtro: FiltroOpcoesTelemetria) {
    this.carregandoPesquisa = true;

    this.controleIndicadoresTelemetriaService
      .buscarIndicadoresTelemetriaFiltrados(filtro)
      .then((response) => {
        this.retorno = response;
        this.carregandoPesquisa = false;
      });
  }
}
