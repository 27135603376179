import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DetalhesMonitoramentoJornadaAusenteComponent } from '../detalhes-monitoramento-jornada-ausente/detalhes-monitoramento-jornada-ausente.component';
import { DetalhesMonitoramentoJornadaDentroJornadaComponent } from '../detalhes-monitoramento-jornada-dentro-jornada/detalhes-monitoramento-jornada-dentro-jornada.component';
import { DetalhesMonitoramentoJornadaExtrapoladaComponent } from '../detalhes-monitoramento-jornada-extrapolada/detalhes-monitoramento-jornada-extrapolada.component';
import { DetalhesMonitoramentoJornadaParadaComponent } from '../detalhes-monitoramento-jornada-parada/detalhes-monitoramento-jornada-parada.component';

@Component({
  selector: 'app-contadores-monitoramento-jornada',
  templateUrl: './contadores-monitoramento-jornada.component.html',
  styleUrls: ['./contadores-monitoramento-jornada.component.scss']
})
export class ContadoresMonitoramentoJornadaComponent {

  @Input()
  quantidadeTotalMotoristas: any[];

  @Input()
  quantidadeInterjonadas: any[];

  @Input()
  quantidadeDentroJornada: any[];

  @Input()
  quantidadeJornadaExtrapolada: any[];

  @Input()
  quantidadeParadas: any[];

  constructor(public dialog: MatDialog) { }

  exibirListaDeAusentes() {
    this.dialog.open(DetalhesMonitoramentoJornadaAusenteComponent, { data: { resumoMonitoramentoJornadaAusente: this.quantidadeInterjonadas }, panelClass: 'indicadores-dialog' });
  }

  exibirListaDentroDaJornada() {
    this.dialog.open(DetalhesMonitoramentoJornadaDentroJornadaComponent, { data: { resumoMonitoramentoJornadaDentroJornada: this.quantidadeDentroJornada }, panelClass: 'indicadores-dialog' });
  }

  exibirListaJornadaExtrapolada() {
    this.dialog.open(DetalhesMonitoramentoJornadaExtrapoladaComponent, { data: { resumoMonitoramentoJornadaExtrapolada: this.quantidadeJornadaExtrapolada }, panelClass: 'indicadores-dialog' });
  }

  exibirListaParadas() {
      this.dialog.open(DetalhesMonitoramentoJornadaParadaComponent, { data: { resumoMonitoramentoParadas: this.quantidadeParadas }, panelClass: 'indicadores-dialog' });
  }
}
