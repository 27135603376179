import { Injectable } from "@angular/core";
import { RESTResponseVO } from "../../classes/restresponsevo";
import { JourneyState } from "../../classes/journey-state";
import { Http } from "@angular/http";
import { AuthGuard } from "../../guards/auth.guard";
import { environment } from "../../../environments/environment";

@Injectable()
export class JourneyStateService {
    constructor(private http: Http, public authGuard: AuthGuard) {
    }

    getList(): Promise<RESTResponseVO<JourneyState[]>> {
        var url: string =  environment.baseURL + 'App/v2/web/estadosJornada';
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<JourneyState[]>;
            })
            .catch(this.handleError);
    }

    protected handleError(error: any): Promise<any> {
        console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
        return Promise.reject(error.message || error);
    }
}