import { IDEntity } from './IDEntity';
import { Modelo } from './modelo';
export class EquipamentoPortatil extends IDEntity {
    id: number;
    dataCadastro: Date;
    imei: string;
    donoDoChip: string;
    donoDoAparelho: string;
    numeroCelular: string;
    numeroSerialChip: string;
    status: boolean;
    apelido: string;
    descricao: string;
    observacao: string;
    cliente: number;
    unidade: number;
    modelo: Modelo;
    integracaoSliic:boolean;
}
