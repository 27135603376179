import { Component, OnInit, ViewChild } from '@angular/core';
import { Filtro } from 'app/classes/filtro';
import { RankingMotoristaService } from 'app/services/crud/ranking-motorista.service';
declare var $;

@Component({
  selector: 'app-pontuacao-avancada',
  templateUrl: './pontuacao-avancada.component.html',
  styleUrls: ['./pontuacao-avancada.component.css']
})
export class PontuacaoAvancadaComponent implements OnInit {

  @ViewChild('dataTable') table;
  dataTable: any;
  dtOption: any = {};
  public filtro: Filtro;

  constructor(private rankingMotoristaService: RankingMotoristaService) { }

  ngOnInit() {
    try {
      this.filtro = new Filtro();
      this.dtOption = {
        "iDisplayLength": 7,
        "bPaginate": true,
        "bLengthChange": false,
        "bFilter": true,
        "bInfo": false,
        "bAutoWidth": false,
        "language": {
          "lengthMenu": "Mostrando _MENU_ registros por pagina",
          "zeroRecords": "Nenhum registro encontrado",
          "search": "Pesquisar",
          "oPaginate": {
            "sNext": "Próximo",
            "sPrevious": "Anterior",
            "sFirst": "Primeiro",
            "sLast": "Último"
          }
        }, "columnDefs": [
          //Largura das Colunas
          { "className": "text-center", targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] },
          { "width": "20%", targets: 0 },
          { "width": "8%", targets: 1 },
          { "width": "8%", targets: 2 },
          { "width": "8%", targets: 3 },
          { "width": "8%", targets: 4 },
          { "width": "8%", targets: 5 },
          { "width": "8%", targets: 6 },
          { "width": "8%", targets: 7 },
          { "width": "8%", targets: 8 },
          { "width": "8%", targets: 9 },
          { "width": "8%", targets: 10 }

        ],
        'rowCallback': function (row, data, index) {
          if (data[1] >= 1 && data[1] <= 4) {
            $(row).find('td:eq(1)').css('color', 'black');
            $(row).find('td:eq(1)').css('background-color', '#00FF7F');
          } else if (data[1] >= 5 && data[1] <= 7) {
            $(row).find('td:eq(1)').css('color', 'black');
            $(row).find('td:eq(1)').css('background-color', '#4169E1');
          } else {
            $(row).find('td:eq(1)').css('color', 'black');
            $(row).find('td:eq(1)').css('background-color', '#FF6347');
          }
        },
        'dom': 'Bfrtip',
        'buttons': [
          'csv', 'excel', 'print'
        ]
      };
      this.dataTable = $(this.table.nativeElement);
      this.dataTable.DataTable(this.dtOption);
    } catch (error) {
      console.log(error);
    }
  }


  doSearch() {
    try {
      this.rankingMotoristaService.getPontuacaoAvancada(this.filtro).then(response => {
        let listaRelatorioPontuacaoAvancada = response.listaRelatorioPontuacaoAvancada;
        this.dataTable.DataTable().clear().draw();
        if (listaRelatorioPontuacaoAvancada && listaRelatorioPontuacaoAvancada.length > 0) {
          listaRelatorioPontuacaoAvancada.forEach(e=>{
            this.dataTable.DataTable().row.add([e.nomeMotorista, e.nota, e.qtdeAcelaracaoBrusca, e.qtdeCurvaBruscaDireita,
               e.qtdeCurvaBruscaEsquerda, e.qtdeFreadaBrusca, e.qtdeExcessoVel80kmh, e.qtdeExcessoVel60kmhChuva, 
               e.qtdeMarchaLenta, e.qtdeDirecaoAcimaFaixaVerde, e.distanciaPercorrida]);
          });
          this.dataTable.DataTable().draw();
        }
        
      });
    } catch (error) {
      console.log(error);
    }
  }

}
