
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthGuard } from 'app/guards/auth.guard';
import { User } from 'app/classes/user';
import { ProcessoArquivo } from 'app/classes/processo-arquivo';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ProcessoArquivoService {
    public company: number;
    protected restResponseWrapper: boolean;
    public loggedUser: User;
    public authGuardM: AuthGuard;

    constructor(private http: HttpClient, public authGuard: AuthGuard) {
        this.authGuardM = authGuard;
        this.loggedUser = authGuard.loggedUser();
        this.company = this.loggedUser.selectedCompany.id;
    }


    getProcessoArquivo(tipoArquivo:string): Promise<ProcessoArquivo[]> {
        let url: string = environment.baseURL + 'App/v2/web/processo-arquivo/buscar-empresa/' + this.company+'/'+tipoArquivo;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response as ProcessoArquivo[];
            })
            .catch(this.handleError);
    }


    getArquivo(id: number): Promise<any> {
        
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json', 
              'Accept': '*/*',
              'observe': 'response'
            }),
          };

        let url: string = environment.baseURL + 'App/v2/web/processo-arquivo/buscar-zip/' + id;
        return this.http.get(url, {
            ...httpOptions,
            responseType: 'blob'})
            .toPromise()
            .then(response => {
                return response;
            })
            .catch(this.handleError);
      }


    protected handleError(error: any): Promise<any> {
        console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
        return Promise.reject(error.message || error);
      }

}