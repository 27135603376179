import { Component, OnInit, Compiler, ElementRef, ViewChild  } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Driver } from 'app/classes/driver';
import { DriverService } from 'app/services/crud/driver.service';
import * as firebaseApp from '../../../../assets/js/firebase/firebase-app.js'
import * as firebaseAnalytics from '../../../../assets/js/firebase/firebase-analytics.js'
import * as firebaseDataBase from '../../../../assets/js/firebase/firebase-database.js'
import { environment } from 'environments/environment.js';
import { DatePipe } from '@angular/common';
import { EventsService } from 'app/services/crud/events.service';
import { EventoCorrecao } from 'app/classes/evento-correcao';

@Component({
  selector: 'correcao-eventos',
  templateUrl: './correcao-eventos.component.html',
  styleUrls: ['./correcao-eventos.component.css']
})
export class CorrecaoEventosComponent implements OnInit {
  
  constructor(
    private driverService: DriverService,
    private router: Router,
    private datePipe: DatePipe,
    private eventsService: EventsService
  ) {
  }

  public driverList: Driver[];
  public originalDriverList: Driver[];
  public driverSelecionado: Driver;
  public manualStart: Date;
  public manualEnd: Date;
  app: any;
  analytics: any;
  dbRead:any;
  public listaEventosFirebase: any[] = [];
  datas: Date[] = [];
  public eventosServidor: EventoCorrecao[];
  errorMessage: string;
  public loading: boolean = false;

  ngOnInit() {
    try {
      this.app =  firebaseApp.initializeApp(environment.firebaseConfig);
      this.analytics = firebaseAnalytics.getAnalytics(this.app);
      this.dbRead = firebaseDataBase.getDatabase();

      this.driverService.getListMinimo().then(response => {
        this.originalDriverList = response.response;
        this.originalDriverList.sort((d1, d2) => d1.nome > d2.nome ? 1 : -1);
        this.driverList = this.originalDriverList;
    });

    } catch (error) {
      console.error(error);
    }

  }

  async doSearch() {
    try {
      if (this.manualStart <= this.manualEnd) {
        this.loading = true;
        let driverId = 0;
        if (this.driverSelecionado) {
          driverId = this.driverSelecionado.id;
        }

        //busca eventos do servidor CJM
        this.eventsService.getEventosCorrecao(driverId, this.manualStart.getTime(), this.manualEnd.getTime()).then(response => {
            this.loading = false;
            this.errorMessage = response.error;
            if (!this.errorMessage) {
              this.eventosServidor = response.response;

              //buca eventos no Firebase
              this.datas = this.getDatesBetween(this.manualStart, this.manualEnd);
              this.listaEventosFirebase = [];

              /*
              let motoristaStr = '';
              if (this.driverSelecionado) {
                motoristaStr = '/' + this.driverSelecionado.id;
              }
              */

              this.datas.forEach(async dia => {
                const diaStr = this.formatDate(dia);
                
                const messagesRef = firebaseDataBase.ref(this.dbRead, '/log/eventos/'+diaStr/*+motoristaStr*/);
                //const queryByReceiver = firebaseDataBase.query(messagesRef, firebaseDataBase.orderByChild('receiver'), firebaseDataBase.equalTo(userId+""));
                const snapshot = await firebaseDataBase.get(messagesRef);
                snapshot.forEach(childSnapshot => {
                    const motoristaId = childSnapshot.key;
                    const eventos = childSnapshot.val();

                    let ok = true;
                    if (this.driverSelecionado) {
                      ok = this.driverSelecionado.id == motoristaId;
                    }

                    if (ok) {
                      Object.keys(eventos).forEach(eventoId => {
                        const evento = eventos[eventoId];
                        const jsonStr = evento.json;
                
                        try {
                            const jsonObj = JSON.parse(jsonStr);
                            const eventoServidor = this.compararEvento(jsonObj.instanteEvento, jsonObj.motoristaId, jsonObj.tipoEvento.id);
                            
                            let idEvento = 0;
                            let idJornada = 0;
                            if (eventoServidor) {
                              idEvento = eventoServidor.id;
                              idJornada = eventoServidor.jornadaId;
                            }

                            const dados = {
                              instanteEvento: jsonObj.instanteEvento,
                              tipoEvento: jsonObj.tipoEvento.id,
                              descricaoEvento: jsonObj.tipoEvento.descricao,
                              unidadeId: jsonObj.unidadeId,
                              empresaId: jsonObj.empresaId,
                              motoristaId: jsonObj.motoristaId,
                              operadorLancamento: jsonObj.operadorLancamento.id,
                              sequenciaApp: jsonObj.sequenciaApp,
                              eventoIdServidor: idEvento,
                              eventoJornadaIdServidor: idJornada,
                              json: jsonStr
                            };

                            this.listaEventosFirebase.push(dados);
                        } catch (error) {
                            console.error(`Erro ao parsear JSON: ${jsonStr}`, error);
                        }
                      });
                    }
                });

              });
            }
        }).catch((reason) => {
            this.errorMessage = reason;
            this.loading = false;
        });

      }
    } catch (error) {
      console.error(error);
    }
  }

  driverTemplate(r) {
    return r.nome + " " + r.sobrenome;
  }

  getDatesBetween(startDate: Date, endDate: Date): Date[] {
    const dates: Date[] = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }

  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }

  compararEvento(data: any, motorista: any, tipoEvento: any): any {
    return this.eventosServidor.find(e => e.instanteEvento == data && e.tipoEventoId == tipoEvento && e.motoristaId == motorista);
  }
  
  inserirEventos() {
    let eventos : any[] = [];
    this.listaEventosFirebase.forEach(evento => {
      if (evento.eventoIdServidor == 0) {
        eventos.push(JSON.parse(evento.json));
      }
    });
    console.log('eventos a serem enviados', eventos);
    this.eventsService.criarEventosCorrecao(eventos).then(response => {
        this.errorMessage = response.error;
        if (!this.errorMessage) {
          console.log('eventos criados com sucesso.');
          this.doSearch();
        } else {
          console.log(this.errorMessage);
        }
    }).catch((reason) => {
        this.errorMessage = reason;
        console.log(this.errorMessage);
    });
  }

}
