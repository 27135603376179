import {
  Compiler,
  Component,
  OnInit,
  ViewChild
} from "@angular/core";
import { Motorista } from 'app/classes/motorista';
import { DriverService } from '../../../services/crud/driver.service';
import { ReportService } from "app/services/report/report.service";
import Swal from "sweetalert2";
import { Filtro } from "app/classes/filtro";
import { RelatorioInconformidades } from "app/classes/relatorio-inconformidades";
import { BranchService } from "app/services/crud/branch.service";
import { User } from "app/classes/user";
import { AuthGuard } from "app/guards/auth.guard";
import { Branch } from "app/classes/branch";

@Component({
  selector: "app-detalhe-inconformidades-viam",
  templateUrl: "./detalhes-inconformidades-viam.component.html",
  styleUrls: ['./detalhes-inconformidades-viam.component.css']
})
export class DetalhesInconformidadesViamComponent implements OnInit {
  driverList = [];
  dropdownList = [];
  dropdownListLocal = [];
  dropdownListUnidades =[]
  public selectedItems = [];
  public selectedItemsTiposInconformidades = [];
  public selectedItemsLocal = [];
  public selectedItemsUnidades = [];
  dropdownSettings: {};
  dropdownSettingsDois: {};
  dropdownSettingsUnidade: {};
  public filtro: Filtro;
  public pdfSrc: any;
  listaDeMotoristas: Motorista[] = [];
  listaDeUnidades: Branch[] = []
  motorista: Motorista;
  unidade: Branch;
  listaInformacaoRelatorio: any[];
  showMotoristas: boolean = false;
  showBotaoPesquisa: boolean = true;
  isLoading = false;
  public dataInicial: string;
  public dataFinal: string;
  situacao = 'null';
  eventoSensores: number = 0;
  loggedUser: User;

  readonly tiposInconformidades = [
    { item_text: "Jornada finalizada local desconhecido", item_id: "FIM_JORNADA_LOCAL_DESCONHECIDO" },
    { item_text: "Jornada iniciada local desconhecido", item_id: "INICIO_JORNADA_LOCAL_DESCONHECIDO" },
    { item_text: "Jornada sem marcação", item_id: "JORNADA_SEM_MARCACAO" },
    { item_text: "Jornada sem refeição", item_id: "JORNADA_SEM_REFEICAO" },
    { item_text: "Jornada tempo excedido", item_id: "JORNADA_TEMPO_EXCEDIDO" },
    { item_text: "Jornada fechada com lançamento de refeição atrasado", item_id: "JORNADA_SEM_REFEICAO_ACIMA_PARAMETRO" },
    { item_text: "Interjornada ideal", item_id: "INTERJORNADA_IDEAL" },
    { item_text: "Interjornada minima", item_id: "INTERJORNADA_MINIMA" },
    { item_text: "Refeição finalizada local desconhecido", item_id: "FIM_REFEICAO_LOCAL_DESCONHECIDO" },
    { item_text: "Refeição iniciada local desconhecido", item_id: "INICIO_REFEICAO_LOCAL_DESCONHECIDO" },
    { item_text: "Refeição inferior ao previsto no turno", item_id: "JORNADA_REFEICAO_INFERIOR_PREVISTO" }, 
    { item_text: "Refeição superior ao previsto no turno", item_id: "JORNADA_REFEICAO_SUPERIOR_PREVISTO" },
    { item_text: "Evento fim sem o respectivo Início", item_id: "INCONSISTENCIA_SISTEMICA_SEM_INICIO" },
    { item_text: "Evento com Início sem respectivo Fim", item_id: "INCONSISTENCIA_SISTEMICA_SEM_FIM" },
    { item_text: "Tempo à disposição", item_id: "SEM_ATIVIDADE_ACIMA_LIMITE" },
  ];
  tiposInconformidadesSelecionados: { item_text: string, item_id: string }[] = [];
  latitude = -19.923395;
  longitude = -43.944809;
  mapa: any;
  zoomMapa = 8;
  mapMarkers: any[] = [{ latitude: -19.916751666666666, longitude: -44.04675999999999 }];
  relatorioInconformidades: RelatorioInconformidades[] = [];
  dtOption: any = {};
  @ViewChild("dataTable") table;
  dataTable: any;

  constructor(
    private driverService: DriverService,
    private reportService: ReportService,
    private branchService : BranchService,
    private _compiler: Compiler,
    private authGuard: AuthGuard,
  ) {
    this._compiler.clearCache();
    this.loggedUser = this.authGuard.loggedUser();
  }

  ngOnInit() {
    this.filtro = new Filtro();
    this.getMotoristas();
    this.prepareDataTable();
    this.obterUnidades();
  }

  onMapReady(item: any) {
    this.mapa = item;
  }

  obterUnidades() {
    this.branchService.getList().then(response => {
      const unidades = response.response;
      this.dropdownListUnidades = [];

      for (var i = 0; i < unidades.length; i++) {
        const unidade = unidades[i];
        this.dropdownListUnidades.push({ item_id: unidade.id, item_text: unidade.apelido });
      }
    });

    this.dropdownSettingsUnidade = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Todos',
      unSelectAllText: 'Desmarcar todos',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      searchPlaceholderText: 'Pesquisar'
    };
  }

  prepareDataTable() {
    try {
      this.dtOption = {
        iDisplayLength: 17,
        bPaginate: false,
        fixedHeader: true,
        bLengthChange: false,
        bFilter: true,
        bInfo: false,
        bAutoWidth: false,
        scrollY: 400,
        deferRender: true,
        scroller: true,
        rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('td', row).off('click');
          $('td', row).on('click', () => {
            self.onRowClick(index);
          });
          return row;
        },
        language: {
          lengthMenu: "Mostrando _MENU_ registros por pagina",
          zeroRecords: "Nenhum registro encontrado",
          search: "Pesquisar",
          oPaginate: {
            sNext: "Próximo",
            sPrevious: "Anterior",
            sFirst: "Primeiro",
            sLast: "Último",
          },
        },
        dom: "Bfrtip",
        buttons: ["csv", "excel", "print"],
      };
      this.dataTable = $(this.table.nativeElement);
      this.dataTable.DataTable(this.dtOption);
    } catch (error) {
      console.error(error);
    }
  }

  onRowClick(index: number) {
    this.setLocalizacao(this.relatorioInconformidades[index]);
  }

  getMotoristas() {
    this.driverService.getListMinimo().then(response => {
      this.driverList = response.response;

      this.formartDriverList(this.driverList);
    })
      .catch((error) => console.error(error));;
  }

  async doSearch() {
    if (!this.dataInicial || !this.dataFinal) {
      return Swal.fire({
        position: 'top-end',
        type: 'error',
        title: `Erro`,
        text: 'Os campos "Data inicial" e "Data final" são obrigatórios',
        showConfirmButton: false,
        timer: 4000
      });
    }
    if(!this.listaDeUnidades || this.listaDeUnidades.length == 0) {
      return Swal.fire({
        position: 'top-end',
        type: 'error',
        title: `Erro`,
        text: 'O campo "Por Unidade" é obrigatório',
        showConfirmButton: false,
        timer: 4000
      });
    }
    try {
      this.showBotaoPesquisa = false;
      this.isLoading = true;
      this.filtro = new Filtro();
      this.filtro.dataInicialString = this.dataInicial;
      this.filtro.dataFinalString = this.dataFinal;
      this.filtro.listaDeMotoristas = this.listaDeMotoristas;
      this.filtro.idEmpresa = this.loggedUser.selectedCompany.id;
      this.filtro.situacao = this.situacao == 'null'? null : +this.situacao;
      this.filtro.listaDeUnidades = this.listaDeUnidades;
      const temp = [];
      this.tiposInconformidadesSelecionados.forEach((item) => item.item_id ? temp.push(item.item_id) : null);
      this.filtro.tiposInconformidades = temp;
      const response = await this.reportService
        .getReportRelatorioDetalhesIncoformidadeViam(this.filtro)
      this.ajustarTabelaDeDetalhes(response.listaRelatorioInconformidade);
    } catch (error) {
      console.error(error);
    } finally {
      this.showBotaoPesquisa = true;
      this.isLoading = false;
    }
  }

  onDeSelect(item: any) {
    this.removerMotoristaSelecionado(item);
  }

  onDeSelectUnidade(item: any) {
    this.removerUnidadeSelecionada(item);
  }

  onItemSelect(item: any) {
    this.inserirMotoristaSelecionado(item);
  }

  onItemSelectUnidade(item: any) {
    this.inserirUnidadeSelecionada(item);
  }

  onSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirMotoristaSelecionado(items[i]);
    }
  }

  onSelectAllUnidades(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirUnidadeSelecionada(items[i]);
    }
  }

  inserirUnidadeSelecionada(item: any): void {
    try {
      const unidade = this.listaDeUnidades.find(m => m.id == item.item_id);
      if (!unidade) {
        this.unidade = new Branch();
        this.unidade.id = item.item_id;
        this.listaDeUnidades.push(this.unidade);
      }
    } catch (error) {
      console.log(error);
    }
  }

  inserirMotoristaSelecionado(item: any): void {
    try {
      var moto = this.listaDeMotoristas.find(m => m.id == item.item_id);
      if (!moto) {
        this.motorista = new Motorista();
        this.motorista.id = item.item_id;
        this.listaDeMotoristas.push(this.motorista);
      }
    } catch (error) {
      console.log(error);
    }
  }

  removerMotoristaSelecionado(item: any): void {
    try {
      let index = this.listaDeMotoristas.findIndex(m => m.id == item.item_id);
      this.listaDeMotoristas.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }

  removerUnidadeSelecionada(item: any): void {
    try {
      const index = this.listaDeUnidades.findIndex(m => m.id == item.item_id);
      this.listaDeUnidades.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }

  onDeSelectTiposInconformidades(item: any) {
    this.removerTiposInconformidadesSelecionado(item);
  }

  onItemSelectTiposInconformidades(item: any) {
    this.inserirTiposInconformidadesSelecionado(item);
  }

  onSelectAllTiposInconformidades(items: { item_id: string, item_text: string }[]) {
    items.forEach(item => {
      this.inserirTiposInconformidadesSelecionado(item);
    });
  }

  onDeSelectAll(items: any){
    this. tiposInconformidadesSelecionados = [];
  }

  onDeSelectAllUnidades(items: any){
    this. listaDeUnidades = [];
  }

  inserirTiposInconformidadesSelecionado(item: { item_id: string, item_text: string }): void {
    try {
      const selecionado = this.tiposInconformidadesSelecionados.find((v) => v.item_id == item.item_id);
      if (!selecionado) {
        this.tiposInconformidadesSelecionados.push(item);
      }
    } catch (error) {
      console.log(error);
    }
  }

  removerTiposInconformidadesSelecionado(item: { item_id: string }): void {
    try {
      let index = this.tiposInconformidadesSelecionados.findIndex((v) => v.item_id == item.item_id);
      this.tiposInconformidadesSelecionados.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }


  ajustarTabelaDeDetalhes(relatorioInconformidades: RelatorioInconformidades[]) {
    try {
      this.relatorioInconformidades = relatorioInconformidades;
      this.dataTable.DataTable().clear().draw();
      relatorioInconformidades.forEach((item) => {
        const motorista = item.motorista;
        const dataHora = item.dataInconformidade;
        const dataEvento = item.dataEvento;
        const endereco = item.endereco || '-';
        const dadosIncoformidade = item.descricao;
        const tipo = item.tipoInconformidade || '-';

        this.dataTable
          .DataTable()
          .row.add([
            motorista,
            dataHora,
            dataEvento,
            dadosIncoformidade,
            endereco,
            tipo,
          ]);
      });
      this.dataTable.DataTable().draw();
    } catch (error) {
      console.log(error);
    }
  }
  setLocalizacao(item: RelatorioInconformidades) {
    console.log(item)
    if (item.latitude && item.longitude) {
      this.latitude = +item.latitude;
      this.longitude = +item.longitude
      this.mapMarkers = [{ latitude: +item.latitude, longitude: +item.longitude }];
    }
  }

  onStatusChange(value) {
    let list = [];
    if (value !== '' && value != "null") {
        list = this.driverList.filter(
            d => d.situacao === +value
        );
    } else {
        list = this.driverList;
    }

    this.formartDriverList(list);
  }

  formartDriverList(list) {
    this.dropdownList = [];
      for (var i = 0; i < list.length; i++) {
        var motorista = list[i];
        var nome = motorista.nome + " " + motorista.sobrenome;
        this.dropdownList.push({ item_id: motorista.id, item_text: nome });
      }
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Todos',
        unSelectAllText: 'Desmarcar todos',
        itemsShowLimit: 3,
        allowSearchFilter: true,
        searchPlaceholderText: 'Pesquisar'
      };
      this.dropdownSettingsDois = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Todos',
        unSelectAllText: 'Desmarcar todos',
        itemsShowLimit: 3,
        allowSearchFilter: true,
        searchPlaceholderText: 'Pesquisar'
      };
      this.showMotoristas = true;
  }
}
