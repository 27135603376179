import { IDEntity } from "./IDEntity";
import { User } from "./user";
import { Event } from "./event";
import { Driver } from "./driver";
import { Company } from "./company";

export class Inconformidade extends IDEntity{
     evento:Event;
     dataInconformidade:Date;
     status:boolean;
     lido:boolean;
     usuario:User;
     dataLeitura:Date;
     nome:String;
     observacao:String;
     motoristas:Driver;
     empresa:Company;
     unidade:number;
     idVeiculo:number;
     placa:String;
     dataInconformidadeFormatada:String;
     dataLeituraFormatada:String;
     nomeMotoristaCompleto:String;
     
}
