import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-intercorrencias-motoristas',
  templateUrl: './intercorrencias-motoristas.component.html',
  styleUrls: ['./intercorrencias-motoristas.component.scss']
})
export class IntercorrenciasMotoristasComponent implements OnInit {

  @Input() modelRetorno: any;

  constructor() { }

  ngOnInit() {
  }

}
