import { IDEntity } from "./IDEntity";
import { MetaOptions } from "./meta-options";

export class Meta extends IDEntity{
  descricao?: string;
  idEmpresa: number;
  meta?: string;
  idUnidade: number;
  idModelo: number;
  listaDePergunta: MetaOptions[];
}
