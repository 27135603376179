import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  MatOption,
  MatSelect,
  MatSort,
  MatTableDataSource,
} from "@angular/material";
import { MatPaginator } from '@angular/material/paginator';
import { EquipamentoPortatilService } from "app/services/crud/equipamento-portatil.service";
import { PDFService } from "app/services/pdf.service";
import { ReportService } from "app/services/report/report.service";
import * as moment from 'moment';
import Swal from "sweetalert2";
import { utils, writeFile } from "xlsx";

@Component({
  templateUrl: "./integracao-sllic.component.html",
  selector: "app-relatorio-integracao-sllic",
  styleUrls: ['./integracao-sllic.component.css']
})
export class RelatorioIntegracaoSllic implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  loading = false;
  placasControl = new FormControl();
  imeisControl = new FormControl();
  tiposControl = new FormControl();
  placasList = [];
  filteredPlacasList = [];
  imeisList = [];
  filteredImeisList = [];
  tiposList = ["Placas", "IMEIs"];
  periodoInicial: Date;
  periodoFinal: Date;
  noResultMessage = false;
  showResult = false;
  dataSource: MatTableDataSource<any>;
  dataToExport: any;
  displayedColumns: string[] = [
    "dataCadastro",
    "dataHoraRastreador",
    "idRastreador",
    "latitude",
    "longitude",
    "logradouro",
    "placa",
    "dataImportacao",
  ];

  constructor(
    private readonly pdfService: PDFService,
    private readonly equipamentoPortatilService: EquipamentoPortatilService,
    private readonly reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.obterVeiculos();
    this.obterImeis();
  }

  async obterVeiculos() {
    const response = await this.equipamentoPortatilService.getLista();
    this.placasList = response.listaEquipamentoPortatil.map((item) => ({
      item_id: item.apelido,
      item_text: item.apelido,
    }));
    this.filteredPlacasList = [...this.placasList]
  }

  async obterImeis() {
    try {
      const response = await this.equipamentoPortatilService.getLista();
      this.imeisList = response.listaEquipamentoPortatil.map(
        (item) => item.imei
      );
      this.filteredImeisList = [...this.imeisList]
    } catch (error) {
      console.log(error);
    }
  }

  selecionarTodos(matSelect: MatSelect) {
    const isSelected: boolean = matSelect.options
      .filter((item: MatOption) => item.value === 0)
      .map((item: MatOption) => item.selected)[0];

    if (isSelected) {
      matSelect.options.forEach((item: MatOption) => item.select());
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  exportExcel() {
    if (this.dataToExport) {
      const ws = utils.json_to_sheet(this.dataToExport);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Tabela Tempo de Direção");
      return writeFile(
        wb,
        `TempoDireção${this.periodoInicial}/${this.periodoFinal}.xlsx`
      );
    }
  }

  createHeader(
    name: string,
    dataIndex?: number,
    canSort?: boolean,
    colspan?: number,
    css?: string,
    tooltip?: string
  ) {
    if (canSort == null) canSort = false;
    if (colspan == null) colspan = 1;
    if (dataIndex == null) dataIndex = -1;
    if (css == null) css = "title-left";
    if (tooltip == null) tooltip = "";

    return {
      name: [name],
      canSort: canSort,
      colspan: colspan,
      dataIndex: dataIndex,
      css: css,
      tooltip: tooltip,
    };
  }

  exportPrint() {
    if (this.dataToExport) {
      const data = this.dataToExport;
      const dataFields = Object.keys(this.dataToExport[0]).map((field) => ({
        name: field,
        prefix: "",
        separator: "",
        sufix: "",
        type: "string",
      }));
      const headers = [
        [
          this.createHeader("Placa", 0, true),
          this.createHeader("Rastreador", 1, true),
          this.createHeader("Data Cadastro", 2, true),
          this.createHeader("Data Rastreador", 3, true),
          this.createHeader("Data Importação Sllic", 4, true),
          this.createHeader("Latitude", 5, true),
          this.createHeader("Longitude", 6, true),
          this.createHeader("Logradouro", 7, true),
        ],
      ];
      const pageOrientation = "landscape";
      this.pdfService.printList(data, dataFields, headers, pageOrientation);
    }
  }

  async doSearch() {
    if (!this.periodoInicial || !this.periodoFinal) {
      return Swal.fire({
        type: "error",
        title: "Erro",
        text: "Os campos 'Período Incial' e 'Período Final' são obrigatórios",
      });
    }

    if (!this.placasControl.value && !this.imeisControl.value) {
      return Swal.fire({
        type: "error",
        title: "Erro",
        text: "É obrigatório selecionar as placas ou os IMEIs",
      });
    }
    this.dataSource = new MatTableDataSource([]);
    this.dataToExport = [];
    this.noResultMessage = false;
    this.showResult = false;
    this.loading = true;
    const placas = this.placasControl.value || [];
    const imeis = this.imeisControl.value || [];
    const body = {
      dataInicialString: moment(this.periodoInicial).format(
        "DDMMYYYYHHmmss"
      ),
      dataFinalString: moment(this.periodoFinal).format(
        "DDMMYYYYHHmmss"
      ),
      listaDePlacas: [...placas],
      listaImei: [...imeis],
    };
    const bodyEncoded = encodeURIComponent(JSON.stringify(body));
    this.reportService
      .getIntegracaoSllic(bodyEncoded)
      .then((response) => {
        const parsed = JSON.parse(response._body);
        const tableData = parsed.integracaoSellicVOList.map(
          ({
            dataCadastro,
            dataHoraRastreador,
            idRastreador,
            latitude,
            longitude,
            logradouro,
            placa,
            dataImportacao,
          }) => {
            return {
              placa,
              idRastreador,
              dataCadastro: moment(dataCadastro).format(`DD/MM/YYYY HH:mm`),
              dataHoraRastreador:
                moment(dataHoraRastreador).format(`DD/MM/YYYY HH:mm`),
              dataImportacao:
                moment(dataImportacao).format(`DD/MM/YYYY HH:mm`),
              latitude,
              longitude,
              logradouro,
            };
          }
        );
        this.dataSource = new MatTableDataSource(tableData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataToExport = tableData;
        if (tableData.length > 0) this.showResult = true;
        if (tableData.length === 0) this.noResultMessage = true;
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
      });
  }
}
