import { IDEntity } from "./IDEntity";

export class Parametro extends IDEntity {
    tempoDirecao:String;
    inicioTempoDirecao:String;
    repetirAlertaTempoDirecao:boolean;
    tempoJornadaFinalizada:String;
    repetirAlertaTempoJornadaFinalizada:boolean;
    tempoRefeicao:String;
    repetirAlertaTempoRefeicao: boolean;
    jornadaTrabalhoExtrapolada : boolean;
    horasExtrasAcimaDuasHoras:boolean;
    horasExtrasAcimaQuatroHoras:boolean;
    refeicaoInferiorUmaHora:boolean;
    descansoAcimaOnzeHoras:boolean;
    descansoInferiorOnzeHoras:boolean;
    horasDisposicaoAcimadeTrintaMinutos:String;
    tempoDeEsperaAcimaDeUmaHora:boolean;
    tempoDeManutencaoAcimaDeUmaHora:boolean;
    tempoDeLancheAcimaDeQuinzeMinuto:boolean;
    emFiscalizacao:boolean;
    sinistro:boolean;
    pistaInterditada:boolean;
    habilitarLimiteRefeicao:boolean;
    habilitarControleDeDiarias:boolean;
    forcarKmViam:boolean;
    forcarCargaDescargaViam:boolean;
    relatorioResumoJornada:boolean;
    valorDiaria: number;
    calcularFeriado:boolean;
    calcularHorasExtrasDomingo:boolean;
    habilitarRelatorioDetalheApuracao:boolean;
    habilitarQuebraInterjornada:boolean;
    habilitarRmLobore:boolean;
    habilitarMatriculaChapa:boolean;
    valorDiariaNumerico:number;
    
  }
  