import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDate, NgbCalendar, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, debounceTime, distinctUntilChanged, finalize, switchMap, tap, map } from 'rxjs/operators';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs';
import { DashboardHomeService } from 'app/services/dashboard/dashboard.service';
import { AuthGuard } from 'app/guards/auth.guard';
import { JourneyService } from 'app/services/crud/journey.service';
import { DriverService } from 'app/services/crud/driver.service';
import { UtilService } from 'app/services/utils.service';
import { JourneyEventsService } from 'app/services/crud/journey-events.service';
import { EventsService } from 'app/services/crud/events.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-detalhar-jornada-motorista',
  templateUrl: './detalhar-jornada-motorista.component.html',
  styleUrls: ['./detalhar-jornada-motorista.component.scss']
})
export class DetalharJornadaMotoristaComponent implements OnInit {

  @Input() public dados: any;
  titulo: string;
  erromessage = '';
  formGroup: FormGroup;
  formGroupTrajeto: FormGroup;
  formGroupObservacoes: FormGroup;
  inSpinner = false;
  mensagem = '';
  fotosCarregadas: any[] = [];
  errorMsg: string;
  isLoadingResult: boolean;
  active = 1;
  detalhes: any = {};
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  mapMarkers: any[] = [];
  listaDeInconformidades: any[] = [];
  lng: any;
  lat: any;

  backgroundHeader = '#f5f5f5';

  constructor(
    public activeModal: NgbActiveModal,
    private authGuard: AuthGuard,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private dashboardHomeService: DashboardHomeService,
    private journeyService: JourneyService,
    private driverService: DriverService,
    private utilService: UtilService,
    private journeyEventsService: JourneyEventsService,
    private eventService: EventsService
  ) {
  }

  ngOnInit(): void {
    // if (!!this.dados) {
    //   // this.titulo = `${this.dados.ultimoEvento} - ${this.dados.nomeDoMotorista}`;
    //   this.backgroundHeader = this.dados.cor === '#CCCCC' ? '#CCCCCC' : this.dados.cor;
    //   this.doSearch(this.dados.idObjeto);
    // }
  }

  doSearch(id) {

    const startOfMonth = moment().startOf('month').format('X');
    const endOfMonth   = moment().endOf('month').format('X');
    // startDate = this.searchObject.month.start;
    // endDate = this.searchObject.month.end;

    // this.searchObject.manualEnd = undefined;
    // this.searchObject.manualStart = undefined;

    // this.journeyService.getSummaryByDriver(id, startDate, endDate)
    //   .then(
    //     response => {
    //       if (!response.error) {
    //         this.data = response.response;
    //         let start = this.searchObject.month ? new Date(this.searchObject.month.start) : this.searchObject.manualStart;
    //         let end = this.searchObject.month ? new Date(this.searchObject.month.end) : this.searchObject.manualEnd;

    //         this.period = this.utilService.formatDate(start, 'DD/MM/YYYY') + ' - ' + this.utilService.formatDate(end, 'DD/MM/YYYY');
    //       } else {
    //         this.data = [];
    //         this.errorMessage = response.error;
    //       }

    //       var journeyIds = [];

    //       this.data.forEach(d => {
    //         var format = 'DDMMYYYYhhmmss';
    // tslint:disable-next-line:max-line-length
    //         var id = this.utilService.formatDate(d.inicio, format) + ';' + this.utilService.formatDate(d.ultimoLancamento, format) + ';' + d.tipoEvento;
    //         if (journeyIds.includes(id)) {
    //           this.showDuplicates = true;
    //         } else {
    //           journeyIds.push(id);
    //         }

    //         if (!d.locked) {
    //           d.locked = false;
    //         }
    //       });

    //       this.loading = false;
    //       this.utilService.fixIcons();
    //     },
    //     error => {

    //     }
    //   );
  }


  close() {
    this.activeModal.close();
  }
}
