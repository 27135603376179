// export const environment = {
//   production: true,
//   baseURL: "https://api-cjm-dev.rodoviasolucoes.com.br/CJM/", // Java
//   baseURLChat: "https://api-cjm-dev.rodoviasolucoes.com.br/CJM/", // Openfire
//   credentialsURL: 'https://api-cjm-dev.rodoviasolucoes.com.br/CJM/App/v2/web/chat/credentials/', // Openfire
//   baseHref: "/",
//   baseAspCoreURL: 'https://api-dev.rodoviasolucoes.com.br/api/v1', // .Net6
//   baseWebUrl: 'https://web-dev.rodoviasolucoes.com.br',
//   baseURlDashboardRodovia: 'https://web-dev.rodoviasolucoes.com.br/dashboardrodovia',
//   baseURLMonitoramento:'https://app.rodoviasolucoes.com.br/telemetria/'
// };

export const environment = {
  production: true,
  baseURL: "https://api-digital.rodoviasolucoes.com.br/CJM/", // Java
  baseURLChat: "https://api-digital.rodoviasolucoes.com.br/CJM/", // Openfire
  credentialsURL: 'https://app.rodoviasolucoes.com.br/CJM/App/v2/web/chat/credentials/', // Openfire
  baseHref: "/",
  baseAspCoreURL: 'https://api-hmg.rodoviasolucoes.com.br/api/v1', // .Net6
  baseWebUrl: 'https://web-dev.rodoviasolucoes.com.br',
  baseWebUrlV2: 'https://d3ngs1ybei3jyp.cloudfront.net/',
  baseURlDashboardRodovia: 'https://web-dev.rodoviasolucoes.com.br/dashboardrodovia',
  baseUrlNovaDashboard: 'http://167.71.190.120:8080/dashboard/',
  baseURLMonitoramento: 'https://app.rodoviasolucoes.com.br/telemetria/',
  firebaseConfig: {
    apiKey: "AIzaSyCRwyAfpjjRyIZwNmU4lvk4FzqZfzOdC8I",
    authDomain: "rodoviaviam-5cf5a.firebaseapp.com",
    databaseURL: "https://rodoviaviam-5cf5a-default-rtdb.firebaseio.com",
    projectId: "rodoviaviam-5cf5a",
    storageBucket: "rodoviaviam-5cf5a.appspot.com",
    messagingSenderId: "365158190068",
    appId: "1:365158190068:web:fe4df9df79c9a3ffba07a1",
    measurementId: "G-FBY7SYEH73"
  }
};
