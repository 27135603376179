import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Driver } from 'app/classes/driver';
import { FiltrosJornadaMotorista } from 'app/classes/filtro-jornada-motorista';
import { FiltroOpcoesIndicadores } from 'app/classes/filtro-opcoes-indicadores';
import { GrupoLocais } from 'app/classes/grupoLocais';
import { Local } from 'app/classes/local';
import { Motorista } from 'app/classes/motorista';
import { SituacaoMotorista } from 'app/classes/situacao-motorista';
import { User } from 'app/classes/user';
import { AuthGuard } from 'app/guards/auth.guard';
import { ControleIndicadoresService } from 'app/services/crud/controle-indicadores.service';
import { DriverService } from 'app/services/crud/driver.service';
import { GrupoLocaisService } from 'app/services/crud/grupo-locais.service';
import { LocalService } from 'app/services/crud/local.service';
import { MotoristaService } from 'app/services/crud/motorista.service';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-filtro-situacao-e-motorista',
  templateUrl: './filtro-situacao-e-motorista.component.html',
  styleUrls: ['./filtro-situacao-e-motorista.component.scss']
})
export class FiltroSituacaoEMotoristaComponent implements OnInit, OnDestroy {

  @Input() admModel: any;
  @Output() admModelChange = new EventEmitter<any>();
  alterouModel(value: any) {
    this.admModelChange.emit(value);
  }

  unidades: any[];
  situacoesMotoristaCarregados: SituacaoMotorista[];
  motoristasCarregados: Motorista[];
  exibirFiltros: Boolean = true;
  loggedUser: User;
  _onDestroy = new Subject<void>();

  formFiltro = this.fb.group({
    idUnidade: [0],
    dataInicial: [null],
    dataFinal: [null],
    idSituacaoMotorista: [0],
    listaDeMotoristas: [0]
  });

  lastFormValue: FiltrosJornadaMotorista = {
    idUnidade: 0,
    dataInicial: null,
    dataFinal: null,
    idSituacaoMotorista: 0,
    listaDeMotoristas: []
  };

  dropdownList = [];
  dropdownSettings: {};
  unidadesCarregadas: any[];
  motoristasSelecionados = [];

  public motoristas: Driver[];

  constructor(private motoristaService: MotoristaService, private authGuard: AuthGuard, private fb: FormBuilder, private controleIndicadoresService: ControleIndicadoresService, private driverService: DriverService) {
    this.carregarMotoristas();
  }

  ngOnInit() {
    this.unidades = [];
    this.situacoesMotoristaCarregados = [];
    this.motoristasCarregados = [];

    this.loggedUser = this.authGuard.loggedUser();

    this.buscarUnidades();
    this.buscarMotoristas();

    this.formFiltro.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe((value) => {
        this.lastFormValue = this.formFiltro.value;
      });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  buscarMotoristas() {
    var ret: Promise<any> = this.motoristaService.getLista();
    ret.then(res => {
      this.motoristasCarregados = res.listaDeLocal;
    })
  }

  buscarUnidades() {
    if (this.loggedUser.selectedCompany) {
      this.unidades = this.loggedUser.branchMap[this.loggedUser.selectedCompany.name];
    }
  }

  escodeOuExibeDiv() {
    this.exibirFiltros = !this.exibirFiltros;
  }

  onPesquisar() {
    this.pesquisar(this.formFiltro.value);
  }

  pesquisar(filtro: FiltrosJornadaMotorista) {
    if (filtro == undefined)
      filtro = new FiltrosJornadaMotorista();

    filtro.listaDeMotoristas = [];
    filtro.idUnidade = this.formFiltro.value.idUnidade;
    filtro.dataInicial = this.formFiltro.value.dataInicial;
    filtro.dataFinal = this.formFiltro.value.dataFinal;

    this.motoristasSelecionados.forEach(function (motorista) {
      filtro.listaDeMotoristas.push(motorista.item_id);
    });

    this.controleIndicadoresService.buscarIndicadoresFiltradosJornadaMotorista(filtro).then(response => {
      console.log("Response Jornada Motorista: ");
      console.log(response);

      // this.retorno = response;

      // if (filtro.turno != '') {
      //   this.retorno.response.totalFaltaFolgaDsr = this.retorno.response.totalFaltaFolgaDsr.filter(t => t.turno == filtro.turno);
      //   this.retorno.response.totalDeParadas = this.retorno.response.totalDeParadas.filter(t => t.turno == filtro.turno);
      //   this.retorno.response.totalDentroDaJornadaPadrao = this.retorno.response.totalDentroDaJornadaPadrao.filter(t => t.turno == filtro.turno);
      //   this.retorno.response.totalJornadaExtrapolada = this.retorno.response.totalJornadaExtrapolada.filter(t => t.turno == filtro.turno);
      //   this.retorno.response.listaDeJornadaAbertas = this.retorno.response.listaDeJornadaAbertas.filter(t => t.turno == filtro.turno);
      // }

      // if (filtro.idUnidade != 0) {
      //   // this.retorno.response.totalFaltaFolgaDsr = this.retorno.response.totalFaltaFolgaDsr.filter(t => t.turno == filtro.turno);
      //   // this.retorno.response.totalDeParadas = this.retorno.response.totalDeParadas.filter(t => t.turno == filtro.turno);
      //   // this.retorno.response.totalDentroDaJornadaPadrao = this.retorno.response.totalDentroDaJornadaPadrao.filter(t => t.turno == filtro.turno);
      //   // this.retorno.response.totalJornadaExtrapolada = this.retorno.response.totalJornadaExtrapolada.filter(t => t.turno == filtro.turno);
      //   // this.retorno.response.listaDeJornadaAbertas = this.retorno.response.listaDeJornadaAbertas.filter(t => t.turno == filtro.turno);
      // }

      // if (filtro.horaInicio || filtro.horaFim) {
      //   this.retorno.response.listaDeJornadaAbertas.forEach(jornadaAberta => {

      //     if (jornadaAberta.listaEventoJornada.length > 0) {
      //       var contemInicio = jornadaAberta.listaEventoJornada.filter(eventoJornada => eventoJornada.dataInicialMilesegundos >= filtro.horaInicio).length > 0;
      //       var contemFim = filtro.horaFim == null || jornadaAberta.listaEventoJornada[jornadaAberta.listaEventoJornada.length - 1].dataFinalMilesegundos <= filtro.horaFim;

      //       if (!contemInicio || !contemFim)
      //         jornadaAberta.listaEventoJornada = [];
      //     }
      //   });

      //   this.retorno.response.listaDeJornadaAbertas = this.retorno.response.listaDeJornadaAbertas.filter(jornadaAberta => jornadaAberta.listaEventoJornada.length > 0);
      // }


      this.alterouModel(response.response);
    });
  }

  carregarMotoristas() {
    try {
      this.driverService.getListMinimo().then(response => {
        this.motoristas = response.response;
        this.dropdownList = [];
        this.motoristasSelecionados = [];
        for (var i = 0; i < this.motoristas.length; i++) {
          let l = this.motoristas[i];
          this.dropdownList.push({ item_id: l.id, item_text: l.nome + ' ' + l.sobrenome });
        }
        this.dropdownSettings = {
          singleSelection: false,
          idField: 'item_id',
          textField: 'item_text',
          selectAllText: 'Todos',
          unSelectAllText: 'Desmarcar todos',
          itemsShowLimit: 3,
          allowSearchFilter: true,
          searchPlaceholderText: 'Pesquisar'
        };
      });
    } catch (error) {
      console.log(error);
    }
  }

  onItemSelect(item: any) {
    this.motoristasSelecionados.push(item);
    console.log(this.motoristasSelecionados.length);
  }

  onSelectAll(items: any) {
    this.motoristasSelecionados = [];
    this.motoristasSelecionados = items;
    console.log(this.motoristasSelecionados.length);
  }

  onDeSelectAll(items: any) {
    this.motoristasSelecionados = [];
    console.log(this.motoristasSelecionados.length);
  }
  onDeSelect(item: any) {
    let index = this.motoristasSelecionados.findIndex(v => v.item_id == item.item_id);
    this.motoristasSelecionados.splice(index, 1);
    console.log(this.motoristasSelecionados.length);
  }

}
