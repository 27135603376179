import { CRUDService } from "../base/crud.service";
import { Motive } from "../../classes/motive";
import { Http } from "@angular/http";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { RESTResponseVO } from "../../classes/restresponsevo";
import { User } from "../../classes/user";
import { AuthGuard } from "../../guards/auth.guard";

@Injectable()
export class MotiveService extends CRUDService<Motive> {
    constructor(http: Http, public authGuard: AuthGuard) {
        super(http, environment.baseURL + 'App/v2/web/motivo', true);

        var loggedUser: User = authGuard.loggedUser();
        var company: Number = loggedUser.selectedCompany.id;
        this.listURL = environment.baseURL + "App/v2/web/motivo/lista/" + company;
    }
}