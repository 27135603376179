import { BaseListComponent } from "../../../base/base-list/base-list.component";
import { Component, ViewEncapsulation } from "@angular/core";
import { UtilService } from "../../../../services/utils.service";
import { PDFService } from "../../../../services/pdf.service";
import { AuthGuard } from "../../../../guards/auth.guard";
import { EquipamentoPortatilService } from "app/services/crud/equipamento-portatil.service";


@Component({
  templateUrl: '../../../base/base-list/base-list.component.html',
  styleUrls: ['../../../base/base-list/base-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EquipamentoPortatilListComponent extends BaseListComponent {

  constructor(private equipamentoService: EquipamentoPortatilService,
    private utilService: UtilService,
    pdfService: PDFService,
    authGuard: AuthGuard) {
    super(utilService, pdfService, authGuard);
    this.searchField = "";
  }
  
  ngOnInit() {
    this.pageTitle = "Cadastros | Equipamento Portátil";
      this.formPath = "/adm/equipamentoportatil/form";

      this.tableHeaders = [
          [
              this.createHeader("ID", 0, true),
              this.createHeader("IMEI", 1, true),
              this.createHeader("Placa", 2, true),
              this.createHeader("Modelo", 3, true) ,
              this.createHeader("Status", 4, true)         ]
      ];

      this.dataFields = [
          this.createField("id", "string"),
          this.createField("imei", "string"),
          this.createField("apelido", "string"),
          this.createField("modelo.fabricante", "string"),
          this.createField("status", "boolean")
      ];

      this.equipamentoService.getLista().then(response => {
          this.data = response.listaEquipamentoPortatil;
          this.loading = false;
      });
  }

}
