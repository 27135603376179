import { DetalheEficienciaVeiculo } from './detalhe-eficiencia-veiculo';
import { ResumoContadoresTelemetria } from './resumo-contadores-telemetria';
import { ResumoEficienciaTelemetria } from './resumo-eficiencia-telemetria';
import { Retorno } from './retorno';

export class RetornoTelemetriaFront extends Retorno {
   
    contadoresIndicadoresTelemetria: ResumoContadoresTelemetria = new ResumoContadoresTelemetria();
    indicadoresEficienciaTelemetria: ResumoEficienciaTelemetria = new ResumoEficienciaTelemetria(); 
    consumoCombustivel: number = 0;
    listaDetalhesEficienciaVeiculo: Array<DetalheEficienciaVeiculo> = [];
    detalhesEficienciaVeiculoTopDez: Array<DetalheEficienciaVeiculo> = [];
}
