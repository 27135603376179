export class DetalheEficienciaVeiculo {
  amarelaSemAcelaracaoPorcentagem: string = "";
  amarelaComAcelaracaoPorcentagem: string = "";
  batendoTransmissaoSemAcelaracaoPorcentagem: string = "";
  batendoTransmissaoComAcelaracaoPorcentagem: string = "";
  consumoEmLitros: number = 0;
  economiaSemAcelaracaoPorcentagem: string = "";
  economiaComAcelaracaoPorcentagem: string = "";
  erro: string = "";
  horasLigado: number = 0;
  horasOcioso: number = 0;
  kmLitros: number = 0;
  kmRodado: number = 0;
  limiteDeVelocidade: number = 0;
  marchaLentaParadoPorcentagem: string = "";
  marchaLentaDeslocandoPorcentagem: string = "";
  modelo: string = "";
  placa: string = "-";
  rpmMaximoPorcentagem: string = "";
  sobrerotacaoSemAcelaracaoPorcentagem: string = "";
  sobrerotacaoComAcelaracaoPorcentagem: string = "";
  superEconomiaSemAcelaracaoPorcentagem: string = "";
  superEconomiaComAcelaracaoPorcentagem: string = "";
}
