import { Component } from "@angular/core";
import { Equipamento } from "../../../../classes/equipamento";
import { BaseFormComponent } from "../../../base/base-form/base-form.component";
import { ActivatedRoute, Router } from "@angular/router";
import { EquipamentoService } from "../../../../services/crud/equipamento.service";
import { ModeloEquipamentoService } from "../../../../services/crud/modelo-equipamento.service";
import { RESTResponseVO } from "../../../../classes/restresponsevo";
import { AuthGuard } from "../../../../guards/auth.guard";
import { RetornoEquipamento } from "../../../../classes/retorno-equipamento";
import { RetornoModelo } from "../../../../classes/retorno-modelo";
import { Modelo } from "app/classes/modelo";
 
@Component({
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})

export class EquipamentoFormComponent extends BaseFormComponent<Equipamento> {
  retornoModelo: RetornoModelo;
  _listaDeModelo: Modelo[];
  constructor(private equipamentoService: EquipamentoService,
    private modeloEquipamentoService: ModeloEquipamentoService,
    private authGuard: AuthGuard,
    route: ActivatedRoute,
    private router: Router) { super(route, router, 'adm/equipamento/list'); 
    this.buscarModeloEquipamento();
  }

  assembleObjectFromForm(): void {
  }

  loadAuxiliaryLists(): void {
  }

  loadObject(): void {

    if (this.activeID && (this.activeID !== 0)) {
      this.equipamentoService.getById(this.activeID).then(response => {
        var resultItem: RetornoEquipamento = response;
        this.activeObject = resultItem.equipamento;
      });
    }else{
      this.router.navigate(['adm/equipamento/list']);
    }
    this.loading = false;
  }

  initObject(): void {
    this.activeObject = new Equipamento();
    this.loading = false;
    this.activeObject.modelo = new Modelo();
    this.buscarModeloEquipamento();
  }

  doCreate(t: Equipamento): Promise<RESTResponseVO<Equipamento>>  {
     return this.equipamentoService.cadastrarEquipamento(t);
  }

  doEdit(t: Equipamento): Promise<RESTResponseVO<Equipamento>> {
    try {
      return this.equipamentoService.editarEquipamento(t);
    } catch (e) {
      console.error(e);
    }

  }

  doDelete(t: Equipamento): Promise<RESTResponseVO<string>> {
    return null;
  }

  setActiveID(): void {
    try {
      if(this.activeObject == undefined){
        this.activeID = 0;
      }else{
        this.activeID = this.activeObject.id;
      }
    } catch (e) {
      console.log(e);
    }

  }

  get listaOrigemEquipamento(){
    return this.equipamentoService.getOrigemEquipamento();
  }

  get listaDeModeloEquipamento(){
    return this._listaDeModelo;
  }

  buscarModeloEquipamento(){
    try{
      var ret: Promise<any> = this.modeloEquipamentoService.getLista();
      ret.then(res =>{
        this._listaDeModelo = res.listaDeModelo;
      });
    }catch(e){
      console.error(e);
    }
  }

}
