import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';
import { MatSort, MatTableDataSource, MatDatepicker, MatSelect, MatOption, MatTable } from '@angular/material';
import { DriverService } from 'app/services/crud/driver.service';
import { AuthGuard } from 'app/guards/auth.guard';
import { DatePipe } from '@angular/common';
import { ReportService } from 'app/services/report/report.service';
import { UtilService } from 'app/services/utils.service';
import { utils, writeFile } from 'xlsx';
import { PDFService } from 'app/services/pdf.service';
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { BranchService } from "app/services/crud/branch.service";

@Component({
  selector: 'app-relatorio-situacao-veiculos',
  templateUrl: './relatorio-situacao-veiculos.component.html',
  styleUrls: ['./relatorio-situacao-veiculos.component.css']
})
export class RelatorioSituacaoVeiculosComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public placaControl = new FormControl();
  public unidadeControl = new FormControl();
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[] = ['situacao', 'ultmotorista', 'ultdatahoramotorista', 'placa', 'ultlocalizacao', 'ulttransmissao'];
  public placaList: any[] = [];
  public unidadeList: any[] = [];
  public unidadesSelecionadasList: any[] = [];
  public situacaoSelecionada: number = -1;
  
  public originalPlacaList: any[];
  public originalUnidadeList: any[];
  public empresaId: number;
  public unidadeId: number;
  public idCliente: number;
  public unidadeName: string;
  public dataToExport: any[] = [];
  public showResult: boolean = false;
  public loading: boolean = false;
  public selectedVeiculos: any[] = [];

  constructor(private driverService: DriverService,
    private authGuard: AuthGuard,
    private veiculoService: VeiculoService,
    private datePipe: DatePipe,
    private reportService: ReportService,
    private utilService: UtilService,
    private pdfService: PDFService,
    private branchService: BranchService) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.unidadeId = this.authGuard.loggedUser().selectedBranch.id;
    this.empresaId = this.authGuard.loggedUser().selectedCompany.id;
    this.unidadeName = this.authGuard.loggedUser().selectedBranch.name;
    this.idCliente = this.authGuard.loggedUser().id;
    this.getUnidades();
  }

  getVeiculos() {
    this.placaList = [];
    this.veiculoService
      .getListaVeiculoPorUnidade(this.unidadesSelecionadasList, this.situacaoSelecionada)
      .then((response) => {
        var data = response.listaDeVeiculos ? response.listaDeVeiculos : [];
        for (let i = 0; i < data.length; i++) {
          this.placaList.push({
            id: data[i].id,
            placa: data[i].placa,
          });
        };
        this.originalPlacaList = this.placaList;
      })
      .catch((error) => console.error(error));
  }

  getUnidades() {
    this.branchService.getList().then(response => {
      const unidades = response.response;
      for (var i = 0; i < unidades.length; i++) {
        this.unidadeList.push({
          id: unidades[i].id,
          apelido: unidades[i].apelido
        });
      };
      this.originalUnidadeList = this.unidadeList;
    }).catch((error) => console.error(error));
  }

  onChangeListaDeUnidade(event){
    try {
      this.unidadesSelecionadasList = [];
      if(event !== undefined || event.value !== undefined){
        this.unidadesSelecionadasList.push(event.value.id);
      }
      this.getVeiculos();
    } catch (error) {
      console.error(error);
    }
  }


  onChangeListaDeSituacao(event){
    this.situacaoSelecionada = -1;
    try {
      this.situacaoSelecionada = event.value;
      this.getVeiculos();
    } catch (error) {
      console.error(error);
    }
  }


  disableExecutarButton() {
    return  this.unidadeControl === undefined || this.placaControl === undefined;
  }

  showExportsButtons() {
    return this.dataToExport.length > 0;
  }

  selecionarTodos(matSelect: MatSelect) {
    const isSelected: boolean = matSelect.options
      .filter((item: MatOption) => item.value === 0)
      .map((item: MatOption) => item.selected)
    [0];

    if (isSelected) {
      matSelect.options.forEach((item: MatOption) => item.select());
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  exportExcel() {
    if (this.dataToExport.length > 0) {
      const ws = utils.json_to_sheet(this.dataToExport);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'Relatório Situação de Veículos');
      return writeFile(wb, `RelatorioSituacaoVeiculos${Math.random()}/${Math.random()}.xlsx`);
    }
  }

  createHeader(
    name: string,
    dataIndex?: number,
    canSort?: boolean,
    colspan?: number,
    css?: string,
    tooltip?: string
  ) {
    if (canSort == null) canSort = false;
    if (colspan == null) colspan = 1;
    if (dataIndex == null) dataIndex = -1;
    if (css == null) css = 'title-left';
    if (tooltip == null) tooltip = '';

    return {
      name: [name],
      canSort: canSort,
      colspan: colspan,
      dataIndex: dataIndex,
      css: css,
      tooltip: tooltip
    };
  }

  exportPrint() {
    const data = this.dataToExport.map((item) => {
      return {
        situacao: item.situacao,
        ultmotorista: item.ultmotorista,
        ultdatahoramotorista: item.ultdatahoramotorista,
        placa: item.placa,
        ultlocalizacao: item.ultlocalizacao,
        ulttransmissao: item.ulttransmissao
      }
    });
    const dataFields = Object.keys(data[0]).map((field) => ({
      name: field,
      prefix: '',
      separator: "",
      sufix: "",
      type: "string",
    }));
    const headers = [
      [
        this.createHeader("Situação", 0, true),
        this.createHeader("Últ.Motorista", 1, true),
        this.createHeader("Últ.data/hora Motorista", 2, true),
        this.createHeader("Placa", 3, true),
        this.createHeader("Últ.Localização", 4, true),
        this.createHeader("Últ.Trasmissão", 5, true)
      ]
    ];
    const pageOrientation = 'landscape';
    this.pdfService.printList(
      data,
      dataFields,
      headers,
      pageOrientation
    );
  }

  async doSearch() {
    this.dataToExport = [];
    this.loading = true;
    const placas = [];
    this.placaControl.value.forEach((placa) => placa.id ? placas.push({ id: placa.id }) : null);
    const body = {
      listaDeVeiculos: placas
    };
    this.reportService.RelatorioSituacaoVeiculo(JSON.stringify(body))
      .then(response => {
        const parsed = JSON.parse(response._body)
        if (parsed.codigoRetorno === 110) {
          const listaInformacaoRelatorio = parsed.listaInformacaoRelatorio ? parsed.listaInformacaoRelatorio : [];
          const tableData = listaInformacaoRelatorio.map((item) => {
            return {
              situacao: item.campo01,
              ultmotorista: item.campo02,
              ultdatahoramotorista: item.campo03,
              placa: item.campo04,
              ultlocalizacao: item.campo05,
              ulttransmissao: item.campo06
            }
          })
          this.dataSource = new MatTableDataSource(tableData);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataToExport = tableData;
        }
        this.loading = false;
      })

  }
}