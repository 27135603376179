import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSort, MatTableDataSource, MatDatepicker, MatSelect, MatOption, MatCheckbox } from '@angular/material';
import { Filtro } from 'app/classes/filtro';
import { AuthGuard } from 'app/guards/auth.guard';
import { LocalService } from 'app/services/crud/local.service';
import { RankingMotoristaService } from 'app/services/crud/ranking-motorista.service';
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { ReportService } from 'app/services/report/report.service';
import * as moment from 'moment';
import { ModalTempoPeriodoParadoComponent } from './modal-tempo-periodo-parado.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { utils, writeFile } from 'xlsx';
import { PDFService } from 'app/services/pdf.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';

@Component({
  selector: 'app-tempo-periodo-parado',
  templateUrl: './tempo-periodo-parado.component.html',
  styleUrls: ['./tempo-periodo-parado.component.css']
})
export class TempoPeriodoParadoComponent implements OnInit {

  isLoading: boolean = false;
  formGroup: FormGroup;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('selectVeiculos') selectVeiculos: MatSelect;
  veiculos: any[] = [];
  locais: any[] = [];
  xpandStatus = false;
  public veiculos2: any;
  public filteredList5: any;
  public locais2: any;
  public filteredList6: any;
  minDate: any;
  maxDate: any;
  disabled: any;
  showSpinners: any;
  showSeconds: any;
  stepHour: any;
  stepMinute: any;
  stepSecond: any;
  apresentarSomenteLocaisNaoCadastrados = false;
  incluirLocaisNaoCadastrados = false;
  filtroRelatorioDetalhes = {
    'filtroVO': null,
    'relatorioVO': null,
    'tipo': null
 }

  displayedColumns: string[] = [
    'unidade',
    'placa',
    'quantidadeOcorrencia',
    'tempoTotal',
  ];
  dataSource: MatTableDataSource<any>;
  public filtro: Filtro;
  contentToExport: any;
  pageOrientation: string = 'portrait';

  constructor(
    private formBuilder: FormBuilder,
    private veiculoService: VeiculoService,
    private localService: LocalService,
    private reportService: ReportService,
    private authGuard: AuthGuard,
    private modalService: NgbModal,
    private pdfService: PDFService,
  ) { }

  ngOnInit() {

    let dataInicio = new Date();
    dataInicio.setHours(0,0,0,0);

    let dataFim = new Date();
    dataFim.setHours(23,59,59,999);

    this.formGroup = this.formBuilder.group({
      dataInicial: [dataInicio, [Validators.required]],
      dataFinal: [dataFim, [Validators.required]],
      veiculos: [[]],
      locais: [[]],
      horaMaxima: [null, [Validators.required]],
      horaMinima: [null, [Validators.required]],
      apresentarSomenteLocaisNaoCadastrados: [[]],
      incluirLocaisNaoCadastrados: [[]]
    });
    this.listarVeiculos();
    this.listarLocais();
  }

  filterVeiculos(filtro: any) {
    return filtro;
  }

  exportCSV() {
    if (this.contentToExport) {
      const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Tempo periodo parado',
        useBom: true,
        noDownload: false,
        headers: ["Unidade", "Placa", "Quantidade de ocorrências", "Tempo total"]
      };

      new AngularCsv(this.contentToExport, "TempoPeriodoParado", csvOptions);
    }
  }

  exportExcel() {
    if (this.contentToExport) {
      const ws = utils.json_to_sheet(this.contentToExport);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'Tabela de Retorno');
      return writeFile(wb, 'TempoPeriodoParado.xlsx');
    }
  }

  createHeader(
    name: string,
    dataIndex?: number,
    canSort?: boolean,
    colspan?: number,
    css?: string,
    tooltip?: string
  ) {
    if (canSort == null) canSort = false;
    if (colspan == null) colspan = 1;
    if (dataIndex == null) dataIndex = -1;
    if (css == null) css = 'title-left';
    if (tooltip == null) tooltip = '';

    return {
      name: [name],
      canSort: canSort,
      colspan: colspan,
      dataIndex: dataIndex,
      css: css,
      tooltip: tooltip
    };
  }

  exportPrint() {
    if (this.contentToExport) {
      const data = this.contentToExport;
      const dataFields = Object.keys(this.contentToExport[0]).map((field) => ({
        name: field,
        prefix: '',
        separator: "",
        sufix: "",
        type: "string",
      }));
      const headers = [
        [
          this.createHeader("Unidade", 0, true),
          this.createHeader("Placa", 1, true),
          this.createHeader("Quantidade de ocorrências", 2, true),
          this.createHeader("Tempo total", 3, true),
        ]
      ];
      const pageOrientation = 'portrait';
      this.pdfService.printList(
        data,
        dataFields,
        headers,
        pageOrientation
      );
    }
  }

  listarLocais() {
    this.isLoading = true;
    this.localService.getListaLocaisSemCoordenadas().then(response => {
      this.locais = response.listaDeLocal;
      this.isLoading = false;
      this.locais2 = response.listaDeLocal;
      this.filteredList6 = this.locais2.slice()
    });
  }

  abrirModal(row: any) {
    const modalRef = this.modalService.open(ModalTempoPeriodoParadoComponent, { size: <any>'xl', backdrop: 'static', keyboard: false, windowClass: 'vehicle-modal' });
    modalRef.componentInstance.dados = row;
    modalRef.result.then((result) => {
      if (result) {
      }
    }).catch((res) => { });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  listarVeiculos() {
    this.veiculoService.getLista().then(response => {
      this.veiculos = response.listaDeVeiculos;
      this.veiculos2 = response.listaDeVeiculos;
      this.filteredList5 = this.veiculos2.slice()
    });
  }

  selecionarTodos(matSelect: MatSelect) {
    const isSelected: boolean = matSelect.options
      .filter((item: MatOption) => item.value === 0)
      .map((item: MatOption) => item.selected)
    [0];

    if (isSelected) {
      matSelect.options.forEach((item: MatOption) => item.select());
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  doSearch() {
    if (this.formGroup.value.horaMinima === null || !this.formGroup.value.horaMinima) {
      return;
    }
    if (this.formGroup.value.horaMaxima === null || !this.formGroup.value.horaMaxima) {
      return;
    }
    if (this.formGroup.value.dataInicial === null || !this.formGroup.value.dataInicial) {
      return;
    }
    if (this.formGroup.value.dataFinal === null || !this.formGroup.value.dataFinal) {
      return;
    }

    this.filtroRelatorioDetalhes.filtroVO = null;
    this.filtroRelatorioDetalhes.filtroVO = null;

    const tempoMaximo = this.formGroup.value.horaMinima.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }).split(':');
    const tempoMinimo = this.formGroup.value.horaMaxima.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }).split(':');
    const secondsMaximo = (+tempoMaximo[0]) * 60 * 60 + (+tempoMaximo[1]) * 60 + (+tempoMaximo[2]);
    const secondsMinimo = (+tempoMinimo[0]) * 60 * 60 + (+tempoMinimo[1]) * 60 + (+tempoMinimo[2]);
    if (Number(secondsMinimo) < Number(secondsMaximo)) {
      this.formGroup.get('horaMinima').setErrors({ 'tempoInvalido': true });
      return;
    } else {
      this.formGroup.get('horaMinima').setErrors(null);
    }
    const dataInicial = this.formGroup.value.horaMinima.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const dataFinal = this.formGroup.value.horaMaxima.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    try {
      const tempoPeriodoParado = {
        dataInicial: moment(this.formGroup.value.dataInicial).valueOf(),
        dataFinal: moment(this.formGroup.value.dataFinal).valueOf(),
        listaDeVeiculos: this.formGroup.value.veiculos[0] ? this.formGroup.value.veiculos.map((veiculo) => ({ id: veiculo })) : this.veiculos.map((veiculo) => ({ id: veiculo.id })),
        listaDeLocais: this.formGroup.value.locais[0] ? this.formGroup.value.locais.map((local) => ({ id: local })) : this.locais.map((local) => ({ id: local.id })),
        dataInicialString: dataInicial,
        dataFinalString: dataFinal,
        idUnidade: this.authGuard.loggedUser().selectedBranch.id,
        apenasLocalNaoCadastrado: this.apresentarSomenteLocaisNaoCadastrados,
        incluirLocalNaoCadastrado: this.incluirLocaisNaoCadastrados
      };
      this.isLoading = true;
      this.filtroRelatorioDetalhes.filtroVO = tempoPeriodoParado;
      this.reportService.getReportPeriodoParado(tempoPeriodoParado).then(response => {
        this.filtroRelatorioDetalhes.relatorioVO = response;
        let listaPeriodoParado;
        if (response) {
          listaPeriodoParado = response.listaPeriodoParado;
        }
        const result = listaPeriodoParado.map((ocorrencia) => {
          if (ocorrencia.quantidadeOcorrencia > 0) {
            if (ocorrencia.unidade === null) {
              ocorrencia.unidade = 0;
            }
            return ocorrencia;
          }
          return null;
        }).filter((ocorrencia) => ocorrencia !== null);
        this.isLoading = false;
        this.dataSource = new MatTableDataSource(result);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.contentToExport = result.map((ocorrencia) => {
          return {
            unidade: ocorrencia.unidade,
            placa: ocorrencia.placa,
            quantidadeOcorrencia: ocorrencia.quantidadeOcorrencia,
            tempoTotal: ocorrencia.tempoTotal,
          }
        });
        this.xpandStatus = true;
      });
    } catch (error) {
      console.log(error);
    }
  }

  apresentarLocaisCheckChange(event) {
    if(event.checked) {
      this.incluirLocaisNaoCadastrados = false;
    }
  }

  incluirLocaisCheckChange(event) {
    if(event.checked) {
      this.apresentarSomenteLocaisNaoCadastrados = false;
    }
  }

  exportRelatorioDetalhes(tipo: string) {
    try {
      if(this.filtroRelatorioDetalhes && this.filtroRelatorioDetalhes.filtroVO && 
         this.filtroRelatorioDetalhes.relatorioVO) {
          this.filtroRelatorioDetalhes.tipo = tipo;

          this.reportService.getReportPeriodoParadoDetalhe(this.filtroRelatorioDetalhes)
          .then(response => {
          this.isLoading = false;
          if(response && response.url) {
            window.open(response.url, "_blank");
          }
          });
      } else {
        this.isLoading = false;
      }
    } catch (error) {
      this.isLoading = false;
    }
  }
}