import { Component, OnInit } from '@angular/core';
import { AuthGuard } from 'app/guards/auth.guard';
import { User } from 'app/classes/user';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-new-cadastro-unidade',
  templateUrl: './new-cadastro-unidade.component.html',
})
export class NewCadastroUnidadeComponent implements OnInit {

  empresaAtiva: number;
  unidadeAtiva: number;
  loggedUser: User;
  safeUrl: SafeResourceUrl;

  constructor(
    private authGuard: AuthGuard,
    private sanitizer: DomSanitizer
  ) {
    this.loggedUser =  this.authGuard.loggedUser();
    this.empresaAtiva = this.loggedUser.selectedCompany.id;
    this.unidadeAtiva = this.loggedUser.selectedBranch.id;
    const url = environment.baseWebUrlV2 + this.loggedUser.id + `/listagem-unidades/${this.empresaAtiva}`;
    console.log(url);
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit() {

  }

}
