import { Component, Input } from '@angular/core';

@Component({
  selector: 'contadores-indicadores-telemetria',
  templateUrl: './contadores-indicadores-telemetria.html',
  styleUrls: ['./contadores-indicadores-telemetria.scss']
})
export class ContadoresIndicadoresTelemetria {

  @Input()
  veiculos: number = 0;

  @Input()
  motoristas: number = 0;

  @Input()
  kmRodado: number = 0;

  @Input()
  litrosConsumidos: number = 0;

  @Input()
  kmLitro: number = 0;

  @Input()
  horasOciosas: number = 0;

  @Input()
  horasLigado: number = 0;

  constructor() {}

}
