import { Component, OnInit, Compiler,ViewChild } from '@angular/core';
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { ReportService } from 'app/services/report/report.service';
import { Veiculo } from 'app/classes/veiculo';
import { Filtro } from 'app/classes/filtro';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import * as am4plugins_bullets from "@amcharts/amcharts4/plugins/bullets";
import am4lang_pt_BR from "@amcharts/amcharts4/lang/pt_BR";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { DetalheTelemetria } from 'app/classes/detalhe-telemetria';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-detalhe-telemetria',
  templateUrl: './detalhe-telemetria.component.html',
  styleUrls: ['./detalhe-telemetria.component.css']
})
export class DetalheTelemetriaComponent implements OnInit {

  @ViewChild('content') content;
  public showGraphic: boolean;
  dropdownList = [];
  public selectedItems = [];
  dropdownSettings: {};
  public filtro: Filtro;
  public pdfSrc: any;
  listaDeVeiculos: Veiculo[] = [];
  veiculo: Veiculo;
  listaInformacaoRelatorio: any[];
  showVeiculos: boolean = false;
  showBotaoPesquisa: boolean = false;
  showGrafico: boolean = false;
  public dataProvider: any[];
  listaDeDetalheTrelemetria: any[];
  listaDetalheoriginal:DetalheTelemetria[];
  public detalheTelemetriaSelecionado: DetalheTelemetria;

  constructor(private veiculoService: VeiculoService,
    private reportService: ReportService, 
    private modalService: NgbModal,
    private _compiler: Compiler) {
    this._compiler.clearCache();
  }

  ngOnInit() {
    try {
      this.filtro = new Filtro();
      this.veiculoService.getLista().then(response => {
        var data = response.listaDeVeiculos;
        this.dropdownList = [];
        for (var i = 0; i < data.length; i++) {
          var veiculo = data[i];
          this.dropdownList.push({ item_id: veiculo.id, item_text: veiculo.placa });

        }
        this.dropdownSettings = {
          singleSelection: true,
          idField: 'item_id',
          textField: 'item_text',
          selectAllText: 'Todos',
          unSelectAllText: 'Desmarcar todos',
          itemsShowLimit: 3,
          allowSearchFilter: true,
          searchPlaceholderText: 'Pesquisar'
        };
        this.showVeiculos = true;
        this.detalheTelemetriaSelecionado = new DetalheTelemetria();
      });

    } catch (error) {
      console.error(error);
    }

  }

  onItemSelect(item: any) {
    this.inserirVeiculoSelecionado(item);
  }
  onSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirVeiculoSelecionado(items[i]);
    }
  }
  onDeSelect(item: any) {
    this.removerVeiculoSelecionado(item);
  }

  inserirVeiculoSelecionado(item: any): void {
    try {
      var veiculo = this.listaDeVeiculos.find(v => v.id == item.item_id);
      if (!veiculo) {
        this.veiculo = new Veiculo();
        this.veiculo.id = item.item_id;
        this.veiculo.placa = item.item_text;
      }
    } catch (error) {
      console.log(error);
    }
  }

  removerVeiculoSelecionado(item: any): void {
    try {
      let index = this.listaDeVeiculos.findIndex(v => v.id == item.item_id);
      this.listaDeVeiculos.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }


  buildGraphic() {
    try {
      am4core.useTheme(am4themes_animated);
      let chart = am4core.create("chartdiv", am4plugins_timeline.SerpentineChart);
      chart.curveContainer.padding(100, 20, 50, 20);
      chart.levelCount = 3;
      chart.yAxisRadius = am4core.percent(20);
      chart.yAxisInnerRadius = am4core.percent(2);
      chart.maskBullets = false;

      chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm";
      chart.dateFormatter.dateFormat = "HH";

      chart.data = this.listaDeDetalheTrelemetria;

      chart.fontSize = 10;
      chart.tooltipContainer.fontSize = 10;

      let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis() as any);
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.grid.template.disabled = true;
      categoryAxis.renderer.labels.template.paddingRight = 25;
      categoryAxis.renderer.minGridDistance = 10;

      let dateAxis = chart.xAxes.push(new am4charts.DateAxis() as any);
      dateAxis.renderer.minGridDistance = 70;
      dateAxis.baseInterval = { count: 30, timeUnit: "minute" };
      dateAxis.renderer.tooltipLocation = 0;
      dateAxis.renderer.line.strokeDasharray = "1,4";
      dateAxis.renderer.line.strokeOpacity = 0.5;
      dateAxis.tooltip.background.fillOpacity = 0.2;
      dateAxis.tooltip.background.cornerRadius = 5;
      dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
      dateAxis.tooltip.label.paddingTop = 7;
      dateAxis.endLocation = 0;
      dateAxis.startLocation = -0.5;

      let labelTemplate = dateAxis.renderer.labels.template;
      labelTemplate.verticalCenter = "middle";
      labelTemplate.fillOpacity = 0.4;
      labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor("background");
      labelTemplate.background.fillOpacity = 1;
      labelTemplate.padding(7, 7, 7, 7);

      let series = chart.series.push(new am4plugins_timeline.CurveColumnSeries());
      series.columns.template.height = am4core.percent(20);

      series.dataFields.openDateX = "start";
      series.dataFields.dateX = "end";
      series.dataFields.categoryY = "category";
      series.id = "id";
      series.baseAxis = categoryAxis;
      series.columns.template.propertyFields.fill = "color"; // get color from data
      series.columns.template.propertyFields.stroke = "color";
      series.columns.template.strokeOpacity = 0;
      series.columns.template.fillOpacity = 0.6;
      series.columns.template.events.on("hit", function (ev) {
        this.abrirTelaDeDetalhes(ev.target.dataItem.index);
      }, this);


      let textBullet = series.bullets.push(new am4charts.LabelBullet());
      textBullet.label.propertyFields.text = "text";
      textBullet.disabled = true;
      textBullet.propertyFields.disabled = "textDisabled";
      textBullet.label.strokeOpacity = 0;
      textBullet.dy = -20;
      textBullet.label.textAlign = "middle";

      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.align = "center"
      chart.scrollbarX.width = am4core.percent(75);
      chart.scrollbarX.opacity = 0.5;

      let cursor = new am4plugins_timeline.CurveCursor();
      chart.cursor = cursor;
      cursor.xAxis = dateAxis;
      cursor.yAxis = categoryAxis;
      cursor.lineY.disabled = true;
      cursor.lineX.strokeDasharray = "1,4";
      cursor.lineX.strokeOpacity = 1;

      dateAxis.renderer.tooltipLocation2 = 0;
      categoryAxis.cursorTooltipEnabled = false;


      let label = chart.createChild(am4core.Label);
      label.text = "Detalhes da Telemetria."
      label.isMeasured = false;
      label.y = am4core.percent(40);
      label.x = am4core.percent(50);
      label.horizontalCenter = "middle";
      label.fontSize = 20;
      this.showGrafico = true;
    } catch (error) {
      console.error(error);
    }
  }

  doSearch() {
    this.showBotaoPesquisa = true;
    try {
      this.filtro.veiculo = this.veiculo;
      this.reportService.getReportDetalhesTelemetria(this.filtro).then(ret => {
        this.listaDeDetalheTrelemetria = new Array();;
        this.listaDetalheoriginal = ret.listaDetalheTelemetria;
        this.listaDetalheoriginal.forEach(dt => {
          if (dt.resumo === "NSA") {
            this.listaDeDetalheTrelemetria.push({
              category: "",
              start: dt.start,
              end: dt.end,
              color: dt.color
            });
          } else {
            this.listaDeDetalheTrelemetria.push({
              category: "",
              start: dt.start,
              end: dt.end,
              color: dt.color,
              text: dt.resumo,
              textDisabled: false
            });
          }
        });
        this.buildGraphic();
        this.showGrafico = true;
        this.showBotaoPesquisa = false;
      });
    } catch (error) {
      console.error(error);
    }
  }


  abrirTelaDeDetalhes(index: number) {
    this.detalheTelemetriaSelecionado = this.listaDetalheoriginal[index];
    if(this.detalheTelemetriaSelecionado.resumo != "NSA"){
      this.modalService.open(this.content, { centered: true, size: 'sm' });
    }
  }

}
