import { Injectable } from '@angular/core';
import { CRUDService } from '../base/crud.service';
import { Http } from '@angular/http';
import { FederativeUnit } from '../../classes/federative-unit';
import { environment } from '../../../environments/environment';

@Injectable()
export class FederativeUnitService extends CRUDService<FederativeUnit> {
  constructor(http: Http) {
    super(http, environment.baseURL + 'App/v2/web/unidadesfederativas', true);
  }
}