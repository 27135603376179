import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-map-motorista-details',
  templateUrl: './modal-detalhes-ocorrencia-component.html',
  styleUrls: ['./modal-detalhes-ocorrencia-component.css']
})
export class ModalDetalhesOcorrenciaComponent implements OnInit {

  @Input() public dados: any;
  @Input() public tipo: any;
  @ViewChild(MatSort) sort: MatSort;
  titulo: string;

  displayedColumns: string[] = [
    'local',
    'inicioOcorrencia',
    'placa',
    'motorista',
    'atrasoHoras'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private paginator: MatPaginatorIntl
  ) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.dados.map((o) => ({
      local: o.nomeLocal !== null ? o.nomeLocal : 'Dado não encontrado',
      inicioOcorrencia: o.dataRegistro !== null ? o.dataRegistro : 'Dado não encontrado',
      placa: o.placa !== null ? o.placa : 'Dado não encontrado',
      motorista: o.nomeMotorista !== null ? o.nomeMotorista : 'Dado não encontrado',
      atrasoHoras: o.segundosAtraso !== null ? this.getHours(o.segundosAtraso) : 'Dado não encontrado'
    })));
    this.dataSource.sort = this.sort;
    if (this.tipo === 'ocorrenciasAbrirBau') {
      this.titulo = 'Atrasos para início de descarga(Abrir baú)';
    }
    if (this.tipo === 'ocorrenciasFechacaoBau') {
      this.titulo = 'Atrasos para fim de descarga(Fechar baú)';
    }
    if (this.tipo === 'ocorrenciasSaidaLoja') {
      this.titulo = 'Atrasos para saída do local(Sair da loja)';
    }
  }

  getHours(seconds) {
    var date = new Date(null);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 8);
  }

  close() {
    this.activeModal.close();
  }
}
