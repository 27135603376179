import { IDEntity } from "./IDEntity";
import { Motive } from "./motive";

export class DriverStatus extends IDEntity {
    instanteLancamento: Date;
    operadorLancamento: number;
    situacaoAnterior: number;
    situacaoInserida: number;
    motorista: number;
    empresaId: number;
    motivo: string;

    /* UI */
    motive: Motive;
}