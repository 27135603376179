import { Component, OnInit, ViewChild, Compiler } from "@angular/core";
import { BaseListComponent } from "../base/base-list/base-list.component";
import { UtilService } from "../../services/utils.service";
import { PDFService } from "../../services/pdf.service";
import { AuthGuard } from "../../guards/auth.guard";
import { Driver } from "../../classes/driver";
import { Observable } from "rxjs/Observable";
import { DriverService } from "../../services/crud/driver.service";
import { NonconformityService } from "../../services/crud/nonconformity.service";
import { Nonconformity } from "../../classes/nonconformity";
import { NgbModal } from "../../../../node_modules/@ng-bootstrap/ng-bootstrap";

import * as moment from 'moment';
import 'moment/locale/pt-br';
import { NonconformityTreatment } from "../../classes/nonconformity-treatment";
import { NonconformityCalculusType } from "../../classes/nonconformity-calculus-type";
import { DualListComponent } from "../../../../node_modules/angular-dual-listbox";
import { JourneyService } from "../../services/crud/journey.service";
import { JourneySummary } from "../../classes/journey-summary";

@Component({
    templateUrl: "./nonconformity.component.html",
    styleUrls: ["./nonconformity.component.css"]
})
export class NonconformityComponent extends BaseListComponent implements OnInit {

    constructor(private utilService: UtilService,
        private driverService: DriverService,
        private nonconformityService: NonconformityService,
        private journeyService: JourneyService,
        pdfService: PDFService,
        private modalService: NgbModal,
        private authGuard: AuthGuard,  private _compiler: Compiler) {
        super(utilService, pdfService, authGuard);
        this.searchField = "";
        this._compiler.clearCache();
    }

    public driverList: Driver[];
    public monthList: number[];
    public searchObject: { driver: Driver, start: Date, end: Date, selectedTypes: NonconformityCalculusType[] };
    public selectedRow: Nonconformity;
    public treatment: NonconformityTreatment;
    public treatmentList: NonconformityTreatment[];
    public showTreatment: boolean;
    public nonconformityTypes: NonconformityCalculusType[];
    public journeyList: JourneySummary[];

    public modalErrorMessage: string;
    public modalInfoMessage: string;

    public journeyLoading: boolean;

    @ViewChild('dialogContent') dialogContent;
    @ViewChild('messageDialog') messageDialog;

    public listboxFormat = { add: 'Adicionar', remove: 'Remover', all: 'Todos', none: 'Nenhum', direction: DualListComponent.LTR, draggable: true, locale: 'pt-br' };

    ngOnInit() {
        this.loading = true;

        this.data = Array();
        this.searchObject = { driver: undefined, start: undefined, end: undefined, selectedTypes: Array() };
        this.driverService.getListMinimo().then(response => {
            this.driverList = response.response;
            this.driverList.sort((d1, d2) => d1.nome > d2.nome ? 1 : -1);
            
            this.nonconformityService.getTypes().then(tResponse => {
                this.nonconformityTypes = tResponse.response;

                this.monthList = Array.from(Array(12).keys());
                this.loading = false;
            });
        });
        this.treatmentList = Array();
        this.showTreatment = false;

        this.tableHeaders = [
            [
                this.createHeader("Data Inicio", 0, true),
                this.createHeader("Data Final", 1, true),
                this.createHeader("Tipo", 2, true),
                this.createHeader("Inconformidade", 3, true)
            ]
        ];

        this.dataFields = [
            this.createField("startDate", "date"),
            this.createField("endDate", "date"),
            this.createField("calculusType.description", "string"),
            this.createField("description", "string"),

        ];
    }

    doSearch() {
        this.resetMessages();

        if (!this.searchObject) {
            this.errorMessage = "Favor preencher os campos do formulário.";
        } else if (!this.searchObject.driver) {
            this.errorMessage = "Favor selecionar um motorista.";
        } else if (this.searchObject.selectedTypes.length === 0) {
            this.errorMessage = "Favor selecionar ao menos um tipo de inconformidade";
        } else if (!this.searchObject.start || !this.searchObject.end) {
            this.errorMessage = "Data inicial ou final inválidas ou não preenchidas!";
        } else {
            this.loading = true;

            let start = this.searchObject.start.getTime();
            let end = this.searchObject.end.getTime();

            this.nonconformityService.nonconformityList(start, end, this.searchObject.driver.id, this.searchObject.selectedTypes).then(response => {
                this.errorMessage = response.error;
                if (!this.errorMessage) {
                    this.data = response.response;
                    this.data.forEach(d => d.treated = false);
                }
                this.loading = false;
            });
        }
    }

    uploadTreatments() {
        this.resetMessages();

        this.loading = true;

        if (this.treatmentList && this.treatmentList.length > 0) {
            this.treatmentList.forEach(t => {
                delete t.nonconformity;
                delete t.description;
            });

            this.nonconformityService.uploadTreatments(this.treatmentList, this.authGuard.loggedUser().id).then(response => {
                this.errorMessage = response.error;
                if (!this.errorMessage) {
                    this.ngOnInit();
                    this.successMessage = "Tratamentos salvos com sucesso!";
                }
                this.loading = false;
            });
        } else {
            this.errorMessage = "Nenhum tratamento presente na lista!";
            this.loading = false;
        }
    }

    removeTreatment(row: NonconformityTreatment) {
        row.nonconformity.treated = false;
        this.treatmentList = this.treatmentList.filter(t => t !== row);
    }

    toggleTreatment() {
        this.showTreatment = !this.showTreatment;
    }

    modalAddToStart(hours: number) {
        if (hours < 0) {
            this.treatment.endDate = moment(this.treatment.startDate).add(hours, 'hours').format('YYYY-MM-DD[T]HH:mm:ss');
        } else {
            this.treatment.endDate = moment(this.treatment.endDate).add(hours, 'hours').format('YYYY-MM-DD[T]HH:mm:ss');
        }
    }

    showMessageModal() {
        this.modalService.open(this.messageDialog, { centered: true });
    }

    dateDiff(start, end) {
        var ms = moment(end).diff(moment(start));
        var d = moment.duration(ms);
        return Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
    }

    retrieveInterjourneyStart(start) {
        var mStart = moment(start).utc().subtract({ hours: 11, seconds: 1 });
        return mStart.toDate();
    }

    retrieveInterjourneyEnd(start) {
        var end = moment(start).utc().subtract(1, 'seconds');
        return end.toDate();
    }

    showDetailsModal(row: Nonconformity) {
        this.selectedRow = row;
        this.treatment = new NonconformityTreatment();

        if (this.selectedRow.type === "PERFECT_DSR_SCENARIO") {
            this.treatment.startDate = moment(this.selectedRow.startDate).format('YYYY-MM-DD[T]HH:mm:ss');
            this.treatment.endDate = moment(this.selectedRow.endDate).format('YYYY-MM-DD[T]HH:mm:ss');
            this.treatment.nonconformity = row;
        } else if (this.selectedRow.type === "PRESUMABLE_DSR") {
            this.selectedRow.dateDiff = this.dateDiff(this.selectedRow.startDate, this.selectedRow.endDate);
        } else if (this.selectedRow.type === "DSRHE" || this.selectedRow.type === "NO_SUNDAY_DSR") {
            this.journeyLoading = true;
            this.journeyService.getSummaryByDriver(this.searchObject.driver.id, this.searchObject.start.getTime(), this.searchObject.end.getTime()).then(response => {
                if (!response.error) {
                    this.journeyList = response.response.filter((j, i) => j.dsrHE === false || !j.dsrHE).sort((a, b) => a.worked - b.worked).map((j, i) => {
                        j.inicio = new Date(j.inicio);
                        return j;
                    });
                } else {
                    this.journeyList = [];
                    this.errorMessage = response.error;
                }
                this.loading = false;
                this.journeyLoading = false;
                this.utilService.fixIcons();
            });
            this.treatment.nonconformity = row;
        } else if (this.selectedRow.type === "MANUAL_DSR_CREATION" || this.selectedRow.type === "DSR_WITH_COMPENSATION") {
            this.treatment.nonconformity = row;
        }

        this.treatment.type = this.selectedRow.treatmentType;
        this.treatment.description = this.selectedRow.treatmentDescription;
        this.treatment.driverId = this.searchObject.driver.id;
        this.treatment.userId = this.authGuard.loggedUser().id;

        this.modalService.open(this.dialogContent, { centered: true, size: 'lg' }).result.then(result => {
            if (this.treatment.type === "MANUAL_DSR_CREATION" || this.treatment.type === "DSR_WITH_COMPENSATION") {
                if (!this.treatment.startDate || !this.treatment.endDate) {
                    this.modalErrorMessage = "Datas início e final são de preenchimento obrigatório!";
                    this.showMessageModal();
                    return;
                } else {
                    this.treatment.startDate = moment(this.treatment.startDate, "YYYY-MM-DD[T]HH:mm:ss").toDate();
                    this.treatment.endDate = moment(this.treatment.endDate, "YYYY-MM-DD[T]HH:mm:ss").toDate();

                    if (this.treatment.startDate > this.treatment.endDate || this.treatment.startDate === this.treatment.endDate) {
                        this.modalErrorMessage = "Data início maior ou igual que data final!";
                        this.showMessageModal();
                        return;
                    }
                }
            } else if (this.treatment.type == "MARK_DSR_HE") {
                if (!this.treatment.journeyId) {
                    this.modalErrorMessage = "Jornada é de preenchimento obrigatório!";
                    this.showMessageModal();
                    return;
                }                
            } else {
                this.modalErrorMessage = "Tratamento não disponível no momento!";
                this.showMessageModal();
                return;
            }

            this.treatment.nonconformity.treated = true;
            this.treatmentList.push(this.treatment);
            this.treatment = undefined;

            this.modalInfoMessage = "Tratamento adicionado a lista com sucesso!";
            this.showMessageModal();
        }, (reason) => {
            this.selectedRow = undefined;
            this.treatment = undefined;
        });
    }

    driverFormatter = (x: Driver) => x.nome + ' ' + x.sobrenome;

    searchDriver = (text$: Observable<string>) =>
        text$
            .debounceTime(200)
            .distinctUntilChanged()
            .map(term => term.length < 2 ? [] : this.driverList.filter(v => v.nome.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10));

    driverTemplate(r) {
        return r.nome + " " + r.sobrenome;
    }

    monthTemplate(m) {
        let d = new Date();
        d.setMonth(m);

        let locale = "pt-br";

        return d.toLocaleDateString(locale, { month: "long" });
    }
}