import { FiltroModelosVeiculos } from "./filtro-modelos-veiculos";
import { FiltroPlacas } from "./filtro-placas";
import { Vehicle } from "./vehicle";

export class FiltroOpcoesTelemetria {
  idsUnidades: [];
  dataInicial: number = 0;
  dataFinal: number = 0;
  veiculo: Vehicle = new Vehicle();
  listaDeModelos: FiltroModelosVeiculos[];
  listaDeVeiculos: FiltroPlacas[];
  listaDeMotorista: [];
}
