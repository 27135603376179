import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MonitoramentoMotorista } from 'app/classes/monitoramento-motorista';
import { JourneyService } from 'app/services/crud/journey.service';
import { UtilService } from 'app/services/utils.service';

@Component({
  selector: 'app-analises-jornada-em-aberto',
  templateUrl: './analises-jornada-em-aberto.component.html',
  styleUrls: ['./analises-jornada-em-aberto.component.scss']
})

export class AnalisesJornadaEmAbertoComponent implements OnInit {

  @Input() motoristas: MonitoramentoMotorista[];

  filtros: any[] = [{ id: 1, filtro: 'Horas Crescente' }, { id: 2, filtro: 'Horas Decrescente' }, { id: 3, filtro: 'Motoristas Crescente' }, { id: 4, filtro: 'Motoristas Decrescente' }];
  selected: string;
  preencheuTable: boolean = false;

  @ViewChild("meuCanvas") elemento: ElementRef;

  constructor(
    private router: Router,
    private journeyService: JourneyService,
    private utilService: UtilService,
  ) {
  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      if (this.motoristas) {
        this.colorirTable();
      }
    }, 100);
  }

  ngOnInit() {
  }

  getTotal(total, item) {
    return total + item.segundos;
  }

  onChangeSelect(){
    if (this.motoristas && this.motoristas.length > 0) {
      if(this.selected == "1"){
        this.motoristas.sort((a, b) => {

          let total = a.listaEventoJornada.reduce(this.getTotal, 0);
          let fa = total;
          total = 0;
          total = b.listaEventoJornada.reduce(this.getTotal, 0);
          let fb = total;

          if (fa > fb) {
              return -1;
          }
          if (fa < fb) {
              return 1;
          }
          return 0;
        });
      }else if(this.selected == "2"){
        this.motoristas.sort((a, b) => {

          let total = a.listaEventoJornada.reduce(this.getTotal, 0);
          let fa = total;
          total = 0;
          total = b.listaEventoJornada.reduce(this.getTotal, 0);
          let fb = total;

          if (fa < fb) {
              return -1;
          }
          if (fa > fb) {
              return 1;
          }
          return 0;
        });
      }else if(this.selected == "3"){
        this.motoristas.sort((a, b) => {
          let fa = a.nome.toLowerCase(),
              fb = b.nome.toLowerCase();

          if (fa < fb) {
              return -1;
          }
          if (fa > fb) {
              return 1;
          }
          return 0;
        });
      }else if(this.selected == "4"){
        this.motoristas.sort((a, b) => {
          let fa = a.nome.toLowerCase(),
              fb = b.nome.toLowerCase();

          if (fa > fb) {
              return -1;
          }
          if (fa < fb) {
              return 1;
          }
          return 0;
        });
      }
    }
  }

  colorirTable() {
    if (this.motoristas && this.motoristas.length > 0) {
      this.preencheuTable = true;
      this.motoristas.map(motorista => {
        var horas = {
          hora: 0,
          minuto: 0
        }
        var ultimaHoraPreenchida = 0;
        motorista.listaEventoJornada.map(hora => {
          hora.motorista_id = motorista.motoristaId;
          var splitHoraDe = hora.dataInicial.split(":");
          var splitHoraAte = hora.dataFinal.split(":");

          var minutosTrabalhado = hora.segundos / 60;
          var horasTrabalhado = Math.floor(minutosTrabalhado / 60);
          minutosTrabalhado = Math.floor(minutosTrabalhado % 60);

          splitHoraDe = [("00" + horas.hora).slice(-2), ("00" + horas.minuto).slice(-2)];

          horas.hora += horasTrabalhado;
          if (horas.minuto + minutosTrabalhado >= 60) {
            var minutos = horas.minuto + minutosTrabalhado;
            horas.hora += Math.floor(minutos / 60);
            horas.minuto = Math.floor(minutos % 60);
          } else {
            horas.minuto += minutosTrabalhado;
          }

          splitHoraAte = [("00" + horas.hora).slice(-2), ("00" + horas.minuto).slice(-2)];
          //splitHoraAte = [("00" + horasTrabalhado).slice(-2), ("00" + minutosTrabalhado).slice(-2)];

          for (var i = parseInt(splitHoraDe[0]); i <= parseInt(splitHoraAte[0]); i++) {
            var elementoHora = document.getElementById("motorista" + hora.motorista_id + "hora" + i);
            ultimaHoraPreenchida = i;

            if (elementoHora) {
              if (i == parseInt(splitHoraDe[0]) && parseInt(splitHoraDe[1]) > 0) {
                var background = elementoHora != null ? elementoHora.getAttribute("style") : null;
                if (background != null && background != "") {
                  background = background.replace("background: linear-gradient(to right, ", "");
                  background = background.replace(");", "");

                  if (parseInt(splitHoraDe[1]) > 0) {
                    if (parseInt(splitHoraAte[1]) > 0 && i == parseInt(splitHoraAte[0])) {
                      elementoHora.setAttribute("style", "background: linear-gradient(to right, " + background + ", " +
                        this.retornaCorTipoTrabalho(hora.evento) + " " + (this.calculoTamanhoCor(parseInt(splitHoraDe[1])) + 1) + "%, "
                        + this.retornaCorTipoTrabalho(hora.evento) + " " + this.calculoTamanhoCor(parseInt(splitHoraAte[1])) + "%);");
                    } else {
                      elementoHora.setAttribute("style", "background: linear-gradient(to right, " + background + ", " +
                        this.retornaCorTipoTrabalho(hora.evento) + " " + (this.calculoTamanhoCor(parseInt(splitHoraDe[1])) + 1) + "%, "
                        + this.retornaCorTipoTrabalho(hora.evento) + " 100%);");
                    }
                  }
                } else {
                  elementoHora.setAttribute("style", "background: linear-gradient(to right, " + this.retornaCorTipoTrabalho(hora.evento) + " 0%, " + this.retornaCorTipoTrabalho(hora.evento) + " 100%);");
                }
              } else if (i == parseInt(splitHoraAte[0])) {
                var background = elementoHora.style.background;
                if (background != "") {
                  background = background.replace("background: linear-gradient(to right, ", "");
                  background = background.replace(");", "");
                  var porcentagemUltimoParametro = background[background.length - 3] + background[background.length - 2]
                  if (porcentagemUltimoParametro != "00") {
                    elementoHora.setAttribute("style", "background: linear-gradient(to right, " + background + ", " + this.retornaCorTipoTrabalho(hora.evento) + "" + (this.calculoTamanhoCor(parseInt(splitHoraDe[1])) + 1) + "%, " + this.retornaCorTipoTrabalho(hora.evento) + " " + this.calculoTamanhoCor(parseInt(splitHoraAte[1])) + "%);");
                  }
                } else {
                  if (parseInt(splitHoraDe[1]) > 0 && i == parseInt(splitHoraDe[0])) {
                    if (parseInt(splitHoraAte[1]) > 0) {
                      elementoHora.setAttribute("style", "background: linear-gradient(to right, " +
                        this.retornaCorTipoTrabalho(hora.evento) + " " + (this.calculoTamanhoCor(parseInt(splitHoraDe[1])) + 1) + "%, "
                        + this.retornaCorTipoTrabalho(hora.evento) + " " + this.calculoTamanhoCor(parseInt(splitHoraAte[1])) + "%);");
                    } else {
                      // elementoHora.setAttribute("style", "background: linear-gradient(to right, " +
                      // this.retornaCorTipoTrabalho(hora.tipo) + " " + (this.calculoTamanhoCor(parseInt(splitHoraDe[1])) + 1) + "%, "
                      // + this.retornaCorTipoTrabalho(hora.tipo) + " 100%);");
                    }
                  } else {
                    if (parseInt(splitHoraAte[1]) > 0) {
                      elementoHora.setAttribute("style", "background: linear-gradient(to right, " +
                        this.retornaCorTipoTrabalho(hora.evento) + " 0%, "
                        + this.retornaCorTipoTrabalho(hora.evento) + " " + this.calculoTamanhoCor(parseInt(splitHoraAte[1])) + "%);");
                    } else {
                      // elementoHora.setAttribute("style", "background: linear-gradient(to right, " +
                      // this.retornaCorTipoTrabalho(hora.tipo) + " 0%, "
                      // + this.retornaCorTipoTrabalho(hora.tipo) + " 100%);");
                    }
                  }
                }
              } else {
                elementoHora.setAttribute("style", "background: linear-gradient(to right, " + this.retornaCorTipoTrabalho(hora.evento) + " 0%, " + this.retornaCorTipoTrabalho(hora.evento) + " 100%);");
              }
            }
          }

        });

        for (var i = ultimaHoraPreenchida; i <= 23; i++) {
          var elementoHora = document.getElementById("motorista" + motorista.motoristaId + "hora" + i);

          if (elementoHora) {
            var background = elementoHora.getAttribute("style");
            if (background != "" && background) {
              background = background.replace("background: linear-gradient(to right, ", "");
              background = background.replace(");", "");

              var porcentagemUltimoParametro = background[background.length - 3] + background[background.length - 2]

              if (porcentagemUltimoParametro != "00") {
                elementoHora.setAttribute("style", "background: linear-gradient(to right, " + background + ", " + this.retornaCorTipoTrabalho("Sem Jornada") + " " + porcentagemUltimoParametro + "%, " + this.retornaCorTipoTrabalho("Sem Jornada") + " 100%);");
              }
            } else {
              elementoHora.setAttribute("style", "background: linear-gradient(to right, " + this.retornaCorTipoTrabalho("Sem Jornada") + " 0%, " + this.retornaCorTipoTrabalho("Sem Jornada") + " 100%);");
            }
          }
        }
      });
    }
  }

  retornaCorTipoTrabalho(cor: string) {
    if (cor == "Disponível") {
      return "#A9A9A9";
    } else if (cor == "Direção") {
      return "#FFD700";
    } else if (cor == "Em Espera") {
      return "#008000";
    } else if (cor == "Paradas") {
      return "#8B008B";
    } else if (cor == "Refeição") {
      return "#0000CD";
    } else if (cor == "Sem Jornada") {
      return "#EEF5F9";
    }
  }

  calculoTamanhoCor(minutos: number) {
    return Math.floor((100 * minutos) / 60);
  }

  showJourney(jornadaId) {
    this.journeyService.getSingleSummary(jornadaId).then(response => {
      localStorage.setItem('journey', JSON.stringify({
        summary: response.response,
        period: this.utilService.formatDate(response.response.inicio, "DD/MM/YYYY"),
        returnURL: 'adm/main'
      }));

      this.router.navigate(['adm/journey/form'], { skipLocationChange: true });
    });
  }
}
