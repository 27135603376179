import { Component, Input} from '@angular/core';
import { MatDialog } from '@angular/material';
import { DetalheAcionamentoRegra } from 'app/classes/detalhe-acionamento-regra';
import { ResumoDetalheAcionamentoRegra } from 'app/classes/resumo-detalhe-acionamento-regra';
import { DetalhesAcionamentosLocaisComponent } from '../detalhes-acionamentos-locais/detalhes-acionamentos-locais';
import { DetalhesIndicadoresLocaisComponent } from '../detalhes-indicadores-locais/detalhes-indicadores-locais';

@Component({
  selector: 'ranking-locais',
  templateUrl: './ranking-locais.html',
  styleUrls: ['./ranking-locais.scss']
})
export class RankingLocais {
  
  @Input()
  listaTopDez: ResumoDetalheAcionamentoRegra[];

  @Input()
  resumoPorLocal: ResumoDetalheAcionamentoRegra[];

  @Input()
  detalhesPorLocal: DetalheAcionamentoRegra[];

  constructor(public dialog: MatDialog) {}

  exibirTabela: Boolean = true;

  escodeOuExibeDiv() {
    this.exibirTabela = !this.exibirTabela;
  }
  
  exibirListaDeLojas() {
    this.dialog.open(DetalhesIndicadoresLocaisComponent, { data: { resumoPorLocal: this.resumoPorLocal, detalhesPorLocal: this.detalhesPorLocal }, panelClass: 'indicadores-dialog' });
  }

  exibirDetalheLocal(idLocal: number) {
    var itensFiltrados = this.detalhesPorLocal.filter(x => x.idLocal == idLocal);
    this.dialog.open(DetalhesAcionamentosLocaisComponent, { data: { detalhesPorLocal: itensFiltrados }, panelClass: 'indicadores-dialog' });
  }
}