import { BaseListComponent } from "../../../base/base-list/base-list.component";
import { Component, ViewEncapsulation } from "@angular/core";
import { LocalService } from "../../../../services/crud/local.service";
import { UtilService } from "../../../../services/utils.service";
import { PDFService } from "../../../../services/pdf.service";
import { AuthGuard } from "../../../../guards/auth.guard";


@Component({
  templateUrl: '../../../base/base-list/base-list.component.html',
  styleUrls: [ '../../../base/base-list/base-list.component.css' ],
  encapsulation: ViewEncapsulation.None
})

export class LocalListComponent extends BaseListComponent {
  constructor(private localService: LocalService,
      private utilService: UtilService,
      pdfService: PDFService,
      authGuard: AuthGuard) {
      super(utilService, pdfService, authGuard);
      this.searchField = "nome";
  }

  ngOnInit() {
    this.pageTitle = "Cadastros | Local";
      this.formPath = "/adm/local/form";

      this.tableHeaders = [
          [
              this.createHeader("ID", 0, true),
              this.createHeader("Identificação", 1, true),
              this.createHeader("Cidade", 2, true),
              this.createHeader("Estado", 3, true),
              this.createHeader("Visível no Mapa", 4, true),
              this.createHeader("Ativo", 5, true)
          ]
      ];

      this.dataFields = [
          this.createField("id", "string"),
          this.createField("nome", "string"),
          this.createField("cidade", "string"),
          this.createField("uf", "string"),
          this.createField("mostrarNoMapaPrincipal", "boolean"),
          this.createField("ativo", "boolean")
      ];

      this.localService.getListaLocaisSemCoordenadas().then(response => {
          this.data = response.listaDeLocal;
          this.loading = false;
      });
  }

}
