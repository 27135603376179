import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { Filtro } from 'app/classes/filtro';
import { ReportService } from 'app/services/report/report.service';
import { Veiculo } from 'app/classes/veiculo';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


@Component({
  selector: 'app-tempo-transporte-grafico',
  templateUrl: './tempo-transporte-grafico.component.html',
  styleUrls: ['./tempo-transporte-grafico.component.css']
})
export class TempoTransporteGraficoComponent implements OnInit {

  @ViewChild('iframe') iframe: ElementRef;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: {};
  public filtro: Filtro;
  public mostrarPdf: boolean = false;
  public pdfSrc: any;
  listaDeVeiculos: Veiculo[] = [];
  veiculo: Veiculo;
  responseBase64: String;
  funcao: number = 2;
  showGrafico: boolean = false;
  totalDeViagens: number = 0;
  pesoMedio: number = 45;
  public peso: String;
  totalProducao: number = 0;
  erro: String;

  constructor(private veiculoService: VeiculoService, private reportService: ReportService) { }

  ngOnInit() {
    try {
      this.filtro = new Filtro();
      this.veiculoService.getLista().then(response => {
        var data = response.listaDeVeiculos;
        this.dropdownList = [];
        for (var i = 0; i < data.length; i++) {
          var veiculo = data[i];
          this.dropdownList.push({ item_id: veiculo.id, item_text: veiculo.placa });
        }

        this.dropdownSettings = {
          singleSelection: false,
          idField: 'item_id',
          textField: 'item_text',
          selectAllText: 'Todos',
          unSelectAllText: 'Desmarcar todos',
          itemsShowLimit: 3,
          limitSelection: 10,
          allowSearchFilter: true,
          searchPlaceholderText: 'Pesquisar'
        };
      });

    } catch (error) {
      console.error(error);
    }
  }

  onItemSelect(item: any) {
    this.inserirVeiculoSelecionado(item);
  }
  onSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirVeiculoSelecionado(items[i]);
    }
  }
  onDeSelect(item: any) {
    this.removerVeiculoSelecionado(item);
  }

  inserirVeiculoSelecionado(item: any): void {
    try {
      var veiculo = this.listaDeVeiculos.find(v => v.id == item.item_id);
      if (!veiculo) {
        this.veiculo = new Veiculo();
        this.veiculo.id = item.item_id;
        this.veiculo.placa = item.item_text;
        this.listaDeVeiculos.push(this.veiculo);
      }
    } catch (error) {
      console.log(error);
    }
  }

  removerVeiculoSelecionado(item: any): void {
    try {
      let index = this.listaDeVeiculos.findIndex(v => v.id == item.item_id);
      this.listaDeVeiculos.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }


  doSearch() {
    try {
      this.filtro.listaDeVeiculos = this.listaDeVeiculos;
      this.reportService.getReportTempoTransporteGrafico(this.filtro).then(response => {
        let retorno = response.relatorioGrafico;
        let lista = retorno.listaTempoTransporteGrafico;
        this.carregarGraficoDois(lista);
        this.showGrafico = true;

      });
    } catch (error) {
      console.error(error);
    }
  }

  carregarGrafico() {
    try {
      am4core.useTheme(am4themes_animated);
      // Themes end

      let data = [{
        "locais": "Britador",
        "totaldeminutos": 500,
        "pie": [{
          "value": 250,
          "title": "PC-05"
        }, {
          "value": 150,
          "title": "Cat-1234"
        }, {
          "value": 100,
          "title": "Cat-123"
        }]
      }, {
        "locais": "Mina Pedra Preta",
        "totaldeminutos": 300,
        "pie": [{
          "value": 80,
          "title": "PC-05"
        }, {
          "value": 130,
          "title": "Cat-1234"
        }, {
          "value": 90,
          "title": "Cat-123"
        }]
      }, {
        "locais": "Mina Aterro",
        "totaldeminutos": 200,
        "pie": [{
          "value": 75,
          "title": "PC-05"
        }, {
          "value": 55,
          "title": "Cat-1234"
        }, {
          "value": 70,
          "title": "Cat-123"
        }]
      }];


      // Create chart instance
      let chart = am4core.create("chartdivtempoviagem", am4charts.XYChart);
      chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

      // Add data
      chart.data = data;

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "locais";
      categoryAxis.renderer.grid.template.disabled = true;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = "Minutos (M)";
      valueAxis.min = 0;
      valueAxis.renderer.baseGrid.disabled = true;
      valueAxis.renderer.grid.template.strokeOpacity = 0.07;

      // Create series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "totaldeminutos";
      series.dataFields.categoryX = "locais";
      series.tooltip.pointerOrientation = "vertical";


      let columnTemplate = series.columns.template;
      // add tooltip on column, not template, so that slices could also have tooltip
      columnTemplate.column.tooltipText = "Local: {categoryX}\nMinutos: {valueY}";
      columnTemplate.column.tooltipY = 0;
      columnTemplate.column.cornerRadiusTopLeft = 20;
      columnTemplate.column.cornerRadiusTopRight = 20;
      columnTemplate.strokeOpacity = 0;


      // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
      columnTemplate.adapter.add("fill", function (fill, target) {
        let color = chart.colors.getIndex(target.dataItem.index * 3);
        return color;
      });

      // create pie chart as a column child
      let pieChart = series.columns.template.createChild(am4charts.PieChart);
      pieChart.width = am4core.percent(80);
      pieChart.height = am4core.percent(80);
      pieChart.align = "center";
      pieChart.valign = "middle";
      pieChart.dataFields.data = "pie";

      let pieSeries = pieChart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "title";
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;
      pieSeries.slices.template.stroke = am4core.color("#ffffff");
      pieSeries.slices.template.strokeWidth = 1;
      pieSeries.slices.template.strokeOpacity = 0;

      pieSeries.slices.template.adapter.add("fill", function (fill, target) {
        return am4core.color("#ffffff")
      });

      pieSeries.slices.template.adapter.add("fillOpacity", function (fillOpacity, target) {
        return (target.dataItem.index + 1) * 0.2;
      });

      pieSeries.hiddenState.properties.startAngle = -90;
      pieSeries.hiddenState.properties.endAngle = 270;

    } catch (error) {
      console.error(error);
    }
  }

  carregarGraficoDois(lista) {
    try {
      am4core.useTheme(am4themes_animated);
      // Themes end



      let chart = am4core.create('chartdivgraficodois', am4charts.XYChart)
      chart.colors.step = 2;

      chart.legend = new am4charts.Legend()
      chart.legend.position = 'top'
      chart.legend.paddingBottom = 20
      chart.legend.labels.template.maxWidth = 95

      let xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      xAxis.dataFields.category = 'category'
      xAxis.renderer.cellStartLocation = 0.1
      xAxis.renderer.cellEndLocation = 0.9
      xAxis.renderer.grid.template.location = 0;
      

      let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
      yAxis.min = 0;


      chart.data = lista
      this.createSeries('vazio', 'Viagens Vazias', chart, xAxis);
      this.createSeries('cheio', 'Viagens Cheias', chart, xAxis);
  

    } catch (error) {

    }
  }


  arrangeColumns(chart, xAxis) {

    let series = chart.series.getIndex(0);

    let w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
    if (series.dataItems.length > 1) {
      let x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
      let x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
      let delta = ((x1 - x0) / chart.series.length) * w;
      if (am4core.isNumber(delta)) {
        let middle = chart.series.length / 2;

        let newIndex = 0;
        chart.series.each(function (series) {
          if (!series.isHidden && !series.isHiding) {
            series.dummyData = newIndex;
            newIndex++;
          }
          else {
            series.dummyData = chart.series.indexOf(series);
          }
        })
        let visibleCount = newIndex;
        let newMiddle = visibleCount / 2;

        chart.series.each(function (series) {
          let trueIndex = chart.series.indexOf(series);
          let newIndex = series.dummyData;

          let dx = (newIndex - trueIndex + middle - newMiddle) * delta

          series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
          series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
        })
      }
    }
  }



 createSeries(value, name, chart, xAxis) {
  let series = chart.series.push(new am4charts.ColumnSeries())
  series.dataFields.valueY = value
  series.dataFields.categoryX = 'category'
  series.name = name

  series.events.on("hidden", this.arrangeColumns(chart, xAxis));
  series.events.on("shown", this.arrangeColumns(chart, xAxis));

  let bullet = series.bullets.push(new am4charts.LabelBullet())
  bullet.interactionsEnabled = false
  bullet.dy = 30;
  bullet.label.text = '{valueY} horas'
  bullet.label.fill = am4core.color('#ffffff')

  return series;
}

}
