export class Portaria671 {
    id: number;
    tipoIdentificador: number; // (CNPJ = 1, CPF = 2)
    cnpj: string;
    cpf: string;
    email: string;
    razaoSocial: string;
    inpi: string;
    nomePtrp: string;
    versaoPtrp: string;
    status: boolean;
    versaoLayoutAej: string;
    versaoLayoutAfd: string;
}