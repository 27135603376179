import { IDEntity } from './IDEntity';
import { Company } from './company';
import { Modelo } from './modelo';

export class Equipamento extends IDEntity {
    dataCadastro:Date;
    imei:string;
    numeroCelular: string;
    numeroSerial: string;
    observacao: string;
    operadora: string;
    status: boolean;
    tipoEquipamento: string;
    tipoChip: string;
    modelo: Modelo;
    equipamentoAlocado: boolean;
    empresa: Company;
}
