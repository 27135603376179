import { Headers, Http } from '@angular/http';
import { RESTResponseVO } from '../../classes/restresponsevo';

export abstract class CRUDService<T extends any> {
  protected headers = new Headers({ 'Content-Type': 'application/json' });

  public createURL: string;
  public listURL: string;
  public updateURL: string;

  constructor(
    protected http: Http,
    protected defaultUrl: string,
    protected restResponseWrapper: boolean,
    private pk?: string
  ) {
    if (!pk) this.pk = 'id';
  }

  create(item: T): Promise<RESTResponseVO<T>> {
    const url: string =
      (this.createURL === undefined && `${this.defaultUrl}`) ||
      `${this.createURL}`;
      console.log(JSON.stringify(item));
    return this.http
      .post(url, JSON.stringify(item), { headers: this.headers })
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RESTResponseVO<T>;
        }
      })
      .catch(this.handleError);
  }

  getList(): Promise<RESTResponseVO<T[]>> {
    const url: string =
      (this.listURL === undefined && `${this.defaultUrl}`) || `${this.listURL}`;
      return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RESTResponseVO<T[]>;
        }
      })
      .catch(this.handleError);
  }

  getById(id: number): Promise<RESTResponseVO<T>> {
    const url = `${this.defaultUrl}/${id}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RESTResponseVO<T>;
        }
      })
      .catch(this.handleError);
  }


  
  update(item: T): Promise<RESTResponseVO<T>> {
    const url: string =
      ((this.updateURL === undefined && `${this.defaultUrl}/`) ||
        `${this.updateURL}/`) + item[this.pk];
    return this.http
      .put(url, JSON.stringify(item), { headers: this.headers })
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RESTResponseVO<T>;
        }
      })
      .catch(this.handleError);
  }

  delete(id: any): Promise<RESTResponseVO<string>> {
    const url = `${this.defaultUrl}/${id}`;
    return this.http
      .delete(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RESTResponseVO<string>;
        }
      })
      .catch(this.handleError);
  }

  protected handleError(error: any): Promise<any> {
    console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
    return Promise.reject(error.message || error);
  }
}
