import { Injectable } from '@angular/core';
import { CRUDService } from '../base/crud.service';
import { Http } from '@angular/http';
import { VehicleType } from '../../classes/vehicle-type';
import { environment } from '../../../environments/environment';
import { RESTResponseVO } from "../../classes/restresponsevo";
import { User } from "../../classes/user";
import { AuthGuard } from "../../guards/auth.guard";

@Injectable()
export class VehicleTypeService extends CRUDService<VehicleType> {
  constructor(http: Http, public authGuard: AuthGuard) {
    super(http, environment.baseURL + 'App/v2/web/tiposdeveiculo', true);
    var loggedUser: User = authGuard.loggedUser();
    this.listURL = environment.baseURL +
      "App/v2/web/tiposdeveiculo/lista/" +
      loggedUser.selectedCompany.id;
  }

  public getListByCompany() {
    var url: string = this.listURL;

    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        return response.json() as RESTResponseVO<string>;
      })
      .catch(this.handleError);
  }
}