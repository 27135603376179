import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseListComponent } from '../../../base/base-list/base-list.component';
import { UtilService } from '../../../../services/utils.service';
import { PDFService } from '../../../../services/pdf.service';
import { AuthGuard } from '../../../../guards/auth.guard';
import { TipoRankingService } from 'app/services/crud/tipo-ranking.service';

@Component({
    templateUrl: '../../../base/base-list/base-list.component.html',
    styleUrls: [ '../../../base/base-list/base-list.component.css' ],
    encapsulation: ViewEncapsulation.None
})
export class TipoRankingListComponent extends BaseListComponent {

  constructor(private tipoRankingService: TipoRankingService,
      private utilService: UtilService,
      pdfService: PDFService,
      authGuard: AuthGuard) {
      super(utilService, pdfService, authGuard);
      this.searchField = "titulo";
  }

  ngOnInit() {
    this.pageTitle = "Cadastros | Tipo de Ranking";
    this.formPath = "/adm/tipo-ranking/form";

    this.tableHeaders = [
        [
            this.createHeader("ID", 0, true),
            this.createHeader("Descrição", 1, true),
            this.createHeader("Status", 2, true),
        ]
    ];

    this.dataFields = [
        this.createField("id", "string"),
        this.createField("descricao", "string"),
        this.createField("status", "boolean")
    ];

    this.tipoRankingService.getLista().then(response => {
        this.data = response.listaDeTipoRanking;
        this.loading = false;
    });
}

}
