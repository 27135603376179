import { Address } from './address';
import { Company } from './company';
import { IDEntity } from './IDEntity';
import { TipoFuncionario } from './tipo-funcionario';

export class Branch extends IDEntity {
  identificacao: string;
  apelido: string;
  endereco: Address;
  tipoFuncionario: TipoFuncionario;
  diaInicioApuracao: number;
  diaFimApuracao: number;
  empresaId: Company;
  chatBroadcast: string;
  valorDiaria: number;
  usarTurnoQuatroDois: boolean;
  mostrarCompensacaoGradeJornada: boolean;
  mostrarAbonarHorasGradeJornada: boolean;
  mostrarHorasExtrasCinquentaPorcento: boolean;
  habilitarTratamentoInterjornada: boolean;
  habilitarMatriculaChapa: boolean;
  tempoAtualizarTela: String;
  tempoVeiculoInativo: String;
  tempoEspera: boolean | null | String;
  //NOVOS CAMPOS 1473 remover em outros locais assim que testar
  habilitarCompensacaoInterjornada: boolean;
  interjourneyCompensationLimit: number;
  interjourneyCompensateStandby: boolean;
  considersInterjourney: boolean;
  halfInterjourney: boolean;
  habilitarQuebraInterjornada:boolean;
  habilitarCompensacaoInterjornadaTempoEspera:boolean;
  habilitarCompensacaoInterjornadaTipoJornada:boolean;
  habilitarTratamentoDescarga:boolean;
  habilitarTratamentoCarga:boolean;
  habilitarTratamentoAguardandoCarga:boolean;
  habilitarTratamentoAguardandoDescarga:boolean;
  habilitarTratamentoFiscalizacao:boolean;
  //Novos campos  1514
  tempoDirecaoContinua:number;
  tempoJornadaSemRefeicao:number;
  tempoRefeicaoInferiorPrevistoTurno:number;
  tempoRefeicaoSuperiorPrevistoTurno:number;
  //Novos campos 159
  habilitarDiaSemMarcacao:boolean;
  //Novos campos 519
  habilitarBonificacao30minDirecaoExtrapolada:boolean;
  horaExtraInterjornada:number;
  limiteSemAtividades:any;
  //Novos campos 600
  habilitarColunaExtraTrabalhada:boolean;
  habilitarColunaExtraEspera:boolean;
  habilitarColunaTotalHrExcedente:boolean;
  habilitarColunaTempoDeEspera:boolean;
  habilitarIndicadorAjuste:boolean;
  viamConsideraFolga:boolean;
  viamConsideraHoraAbonada:boolean;

  habilitarColunaCarregamento:boolean;
  habilitarColunaDescarregamento:boolean;
  habilitarColunaFiscalizacao:boolean;


  /* UI */
  css: string;

  tempoDescansoDirecao:any;
  abonarCompensarDuasHoras:boolean;
  intrajornadaLimiteRefeicao:any;
}
