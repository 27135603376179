import { BaseListComponent } from "../../../base/base-list/base-list.component";
import { Component, ViewEncapsulation } from "@angular/core";
import { ModeloEquipamentoService } from "../../../../services/crud/modelo-equipamento.service";
import { UtilService } from "../../../../services/utils.service";
import { PDFService } from "../../../../services/pdf.service";
import { AuthGuard } from "../../../../guards/auth.guard";


@Component({
  templateUrl: '../../../base/base-list/base-list.component.html',
  styleUrls: [ '../../../base/base-list/base-list.component.css' ],
  encapsulation: ViewEncapsulation.None
})

export class ModeloEquipamentoListComponent extends BaseListComponent {
  constructor(private modeloEquipamentoService: ModeloEquipamentoService,
      private utilService: UtilService,
      pdfService: PDFService,
      authGuard: AuthGuard) {
      super(utilService, pdfService, authGuard);
      this.searchField = "marca";
  }

  ngOnInit() {
      this.pageTitle = "Cadastros | Modelo de Equipamento";
      this.formPath = "/adm/modelo-equipamento/form";

      this.tableHeaders = [
          [
              this.createHeader("ID", 0, true),
              this.createHeader("Modelo", 1, true),
              this.createHeader("Fabricante", 2, true),
              this.createHeader("Portátil", 3, true),
          ]
      ];

      this.dataFields = [
          this.createField("id", "string"),
          this.createField("fabricante", "string"),
          this.createField("marca", "string"),
          this.createField("portatil", "boolean")
      ];

      this.modeloEquipamentoService.getLista().then(response => {
          this.data = response.listaDeModelo;
          this.loading = false;
      });
  }
}
