import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Headers, Http } from '@angular/http';
import { Faturamento } from 'app/classes/faturamento';
import { Filtro } from 'app/classes/filtro';
import { DetalheGrafico } from 'app/classes/detalhe-grafico';

@Injectable({
  providedIn: 'root'
})
export class FaturamentoService {

  private headers = new Headers({ 'Content-Type': 'application/json' });
  private urlPrincial = environment.baseURL + 'App/v2/web/faturamento/';  // URL to web api desenv

  constructor(private http: Http) { }


  getResumoFaturamento(filtro: Filtro){
    try {
      var ulrRelatorio = this.urlPrincial + "faturamentoresumido/";
      var json = JSON.stringify(filtro);
      return this.http
        .post(ulrRelatorio, json, { headers: this.headers })
        .toPromise()
        .then(response => {
          var faturamento: Faturamento = response.json() as Faturamento;
          if (faturamento) {
            return faturamento;
          }
        }).catch(this.handleError);
    } catch (error) {
      console.error(error);
    }
  }

  getTimelineFaturamento(filtro: Filtro){
    try {
      var ulrRelatorio = this.urlPrincial + "buscarTimelinePorPlaca/";
      var json = JSON.stringify(filtro);
      return this.http
        .post(ulrRelatorio, json, { headers: this.headers })
        .toPromise()
        .then(response => {
          var faturamento: Faturamento = response.json() as Faturamento;
          if (faturamento) {
            return faturamento;
          }
        }).catch(this.handleError);
    } catch (error) {
      console.error(error);
    }
  }


  getDetelheColuna(filtro: Filtro){
    try {
      var ulrRelatorio = this.urlPrincial + "buscarDetalheDoGrafico/";
      var json = JSON.stringify(filtro);
      return this.http
      .post(ulrRelatorio, json, { headers: this.headers })
      .toPromise()
      .then(response => {
        var detalheGrafico: DetalheGrafico = response.json() as DetalheGrafico;
        if (detalheGrafico) {
          return detalheGrafico;
        }
      }).catch(this.handleError);
    } catch (error) {
      console.error(error);
    }
  }


  protected handleError(error: any): Promise<any> {
    console.error('Ocorreu um erro: ', error); // TODO: for demo purposes only
    return Promise.reject(error.message || error);
  }


  getExcelTimeline(faturamento:Faturamento){
    try {
      var ulrRelatorio = this.urlPrincial + "gerarExcelTimeline/";
      var json = JSON.stringify(faturamento);
      return this.http
      .post(ulrRelatorio, json, { headers: this.headers })
      .toPromise()
      .then(response => {
        var urlDoExcel: String = response.json() as String;
        if (urlDoExcel) {
          return urlDoExcel;
        }
      }).catch(this.handleError);
    } catch (error) {
      console.error(error);
    }
  }

}
