import { Component, OnInit, Compiler, ElementRef, ViewChild  } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material';
import { DatePipe } from '@angular/common';
import { TaskLogsService } from 'app/services/administrador/task-logs.service.js';
import { TaskLog } from 'app/classes/task-log.js';
import { MatDialog } from '@angular/material/dialog';
import { TaskLogsDialogComponent } from './task-logs-dialog.component';

@Component({
  selector: 'task-logs',
  templateUrl: './task-logs.component.html',
  styleUrls: ['./task-logs.component.css']
})
export class TaskLogsComponent implements OnInit {
  
  constructor(
    private datePipe: DatePipe,
    private taskLogsService: TaskLogsService,
    public dialog: MatDialog
  ) {
    this.dataSource = new MatTableDataSource<any>([]);
  }

  public statusSelecionado: number;
  public manualStart: Date;
  public manualEnd: Date;
  datas: Date[] = [];
  public tasksList: TaskLog[];
  errorMessage: string;
  public loading: boolean = false;

  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = ['id', 'processId', 'processName', 'status', 'startTime', 'endTime', 'actions'];

  ngOnInit() {

  }

  async doSearch() {
    try {
      let status = "Todos";
      if (this.statusSelecionado == 1) {
        status = "Pendente";
      } else if (this.statusSelecionado == 2) {
        status = "Sucesso";
      } else if (this.statusSelecionado == 3) {
        status = "Falha";
      }

      if (this.manualStart <= this.manualEnd) {
        this.loading = true;
        this.taskLogsService.getListaTaskLogs(this.manualStart.getTime(), this.manualEnd.getTime(), status).then(response => {
            this.loading = false;
            this.errorMessage = response.error;
            if (!this.errorMessage) {
              this.tasksList = response.response;
              this.dataSource.data = this.tasksList;
            }
        }).catch((reason) => {
            this.errorMessage = reason;
            this.loading = false;
        });

      }
    } catch (error) {
      console.error(error);
    }
  }

  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }
  
  reenviar(id: number) {
    this.loading = true;
    this.taskLogsService.reenviarTaskLogs(id).then(response => {
      this.loading = false;  
      this.errorMessage = response.error;
        if (!this.errorMessage) {
          console.log('reenviado com sucesso.');
          this.doSearch();
        } else {
          console.log(this.errorMessage);
        }
    }).catch((reason) => {
      this.loading = false;
        this.errorMessage = reason;
        console.log(this.errorMessage);
    });
    
  }

  showTraceback(traceback: string) {
    const dialogRef = this.dialog.open(TaskLogsDialogComponent, {
      data: {
          message: traceback
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {}
    });
  }

}
