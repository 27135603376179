import { Component, Input} from '@angular/core';

@Component({
  selector: 'quadro-vazio2-component',
  templateUrl: './quadro-vazio2-component.html',
  styleUrls: ['./quadro-vazio2-component.scss']
})
export class QuadroVazioDoisComponent {
  
  constructor() {}

}