import { IDEntity } from "./IDEntity";
import { JourneyEvent } from "./journey-event";
import { HoldingPoint } from "./holding-point";
import { User } from "./user";
import { Vehicle } from "./vehicle";
import { AllowanceReason } from "./allowance-reason";
import { DayNight } from "./day-night";

export class Event extends IDEntity {
    instanteEvento: Date;
    instanteLancamento: Date;
    instanteOriginal: Date;
    tipoEvento: JourneyEvent;
    jornada: Number;
    localEventoEspera: HoldingPoint;
    operadorLancamento: User;
    operadorAlteracao: User;
    veiculoMotor: Vehicle;
    veiculoCarga01: Vehicle;
    veiculoCarga02: Vehicle;
    longitude: Number;
    latitude: Number;
    origem: string;
    justificativa: string;
    motivoAbono: AllowanceReason;
    removido: boolean;
    versaoOrigem: string;
    fimEvento: Date;
    fimEventoId: Number;
    dayNight: DayNight;
    gpsDataHora: number;
    gpsAccuracy: number;
}