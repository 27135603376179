import { Injectable } from "@angular/core";
import { Http } from '@angular/http';
import { environment } from "environments/environment";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AlertaService {
  protected headers = new Headers({ 'Content-Type': 'application/json' });
  listURL: any;
  loggedUser: any;
  private notificationsCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public notificationsCount$: Observable<number> = this.notificationsCountSubject.asObservable();

  constructor(public http: Http) {
    this.listURL = environment.baseURL + '/App/v2/web/alertas';
  }


  saveAviso(req: any) {
    const url = `${this.listURL}/create`;
    return this.http
      .post(url,req)
      .toPromise()
      .then(response => {
        return response.json();
      })
      .catch(this.handleError);
  }

  updateNotificationsCount(count: number): void {
    this.notificationsCountSubject.next(count);
  }

  getNotificationsCount(): Observable<number> {
    return this.notificationsCount$;
  }

  getAllAlertas(idUser: number,page) {
    const url = `${this.listURL}/${idUser}?page=${page}&size=5`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        return response.json();
      })
      .catch(this.handleError);
  }


  deleteAlertaById(alertaId: number) {
    const url = `${this.listURL}/${alertaId}`;
    return this.http
      .delete(url)
      .toPromise()
      .then(response => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getAllAvisos(page,size) {
    const url = `${this.listURL}?page=${page}&size=${size}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        return response.json();
      })
      .catch(this.handleError);
  }

  marcadoComoLido(idUser: number,alertaId: number) {
    const url = `${this.listURL}/marcadoLido/${alertaId}/${idUser}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getTotalAlertaNovos(idUser: number) {
    const url = `${this.listURL}/count/${idUser}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        return response.json();
      })
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('Ocorreu um erro: ', error);
    return Promise.reject(error.message || error);
  }
}
