import { Component, OnInit, ViewChild } from '@angular/core';

import { BaseListComponent } from '../../base/base-list/base-list.component';
import { UtilService } from '../../../services/utils.service';
import { PDFService } from '../../../services/pdf.service';
import { JourneyService } from '../../../services/crud/journey.service';
import { DriverService } from '../../../services/crud/driver.service';
import { Driver } from '../../../classes/driver';
import { Observable } from 'rxjs/Observable';
import { JourneyEventsService } from '../../../services/crud/journey-events.service';
import { AuthGuard } from '../../../guards/auth.guard';
import { WebserverService } from '../../../services/crud/sascar-webserver.service';

@Component({
    templateUrl: './sascar-webserver.component.html',
    styleUrls: ['./sascar-webserver.component.css']
})
export class SascarWebserverComponent extends BaseListComponent {
    constructor(
        private webserverService: WebserverService,
        private driverService: DriverService,
        private utilService: UtilService,
        private journeyService: JourneyService,
        private journeyEventsService: JourneyEventsService,
        pdfService: PDFService,
        private authGuard: AuthGuard,

    ) {
        super(utilService, pdfService, authGuard);
        this.searchField = '';
    }

    public searchObject: {
        driver: Driver;
        driverStatus: string;
        manualStart: Date;
        manualEnd: Date;
        searchType: string;
    } = { driver: undefined, driverStatus: undefined, manualEnd: undefined, manualStart: undefined, searchType: 'D' };
    public period: string;
       @ViewChild('confirmDialog') confirmDialog;
    ngOnInit() {
        this.pageTitle = 'Webserver Sascar | Importação';
        this.formPath = '/adm/sascar-webserver';
        this.loading = true;
        this.driverService.getListMinimo().then(response => {
            this.originalDriverList = response.response;
            this.driverList = response.response;

            this.journeyEventsService.getAllowances().then(r1 => {

              this.loading = false;
                if (localStorage.getItem('webserver')) {
                    let temp: any = JSON.parse(localStorage.getItem('webserver'));
                    if (temp.searchObject && temp.data) {
                        this.searchObject = temp.searchObject;
                        var start, end;
                            start = new Date(this.searchObject.manualStart);
                            end = new Date(this.searchObject.manualEnd);
                            this.period = this.utilService.formatDate(start, 'DD/MM/YYYY') + ' - '
                            + this.utilService.formatDate(end, 'DD/MM/YYYY');
                        this.data = temp.data;
                        this.onStatusChange(this.searchObject.driverStatus);
                      this.doSearch();
                    }
                }
            });
        });
        this.sortType = 'inicio';
    }

    isAdmin() {
        return this.authGuard.isAdmin();
    }

    requestWithRefreshList(promise) {
        this.errorMessage = undefined;
        this.loading = true;
        promise
            .then(response => { })
            .catch(reason => {
                this.errorMessage = reason;
                this.loading = false;
            });
    }
    doSascar(){
      this.loading = true;
      let startDate, endDate;
            startDate = new Date(this.searchObject.manualStart).getTime();
            endDate = new Date(this.searchObject.manualEnd).getTime();
      try{
        return this.webserverService.saveSascarWebServerEvent(this.searchObject.driver.id, this.authGuard.loggedUser().id,
         startDate, endDate).then(response => {
            this.errorMessage = response.error;
            if (!this.errorMessage) {
              this.loading = false;
              this.searchObject.searchType = 'D';
              this.searchObject.manualStart = null;
              this.searchObject.manualEnd = null;
              this.searchObject.driver = undefined;
              this.searchObject.driverStatus = undefined;
              this.errorMessage = "Importação Sascar 48 horas concluído com sucesso";
            } else {
              this.errorMessage = response.error;
              this.loading = false;
            }
          });
        }catch (e) {
          console.log(e);
          this.loading = false;
        }
    }

    doSearch() {
        this.loading = true;
        let startDate, endDate;
            startDate = new Date(this.searchObject.manualStart).getTime();
            endDate = new Date(this.searchObject.manualEnd).getTime();
            this.journeyService.getSummaryByDriver(this.searchObject.driver.id, startDate, endDate)
            .then(
                response => {
                    this.data = [];
                    this.errorMessage = response.error;
                    var journeyIds = [];
                    this.data.forEach(d => {
                        var format = 'DDMMYYYYhhmmss';
                        var id = this.utilService.formatDate(d.inicio, format) + ';' +
                        this.utilService.formatDate(d.ultimoLancamento, format) + ';' + d.tipoEvento;
                        journeyIds.push(id);
                        if (!d.locked) {
                            d.locked = false;
                        }
                    });
                    this.loading = false;
                    this.utilService.fixIcons();
                },
                error => {
                }
            );
    }

    // Driver Search
    driverList: Driver[];
    originalDriverList: Driver[];
    driverFormatter = (x: Driver) => x.nome + ' ' + x.sobrenome;
    searchDriver = (text$: Observable<string>) =>
        text$
            .debounceTime(200)
            .map(term =>
                this.driverList
                    .filter(v => v.nome.toLowerCase().indexOf(term.toLowerCase()) > -1)
                    .slice(0, 10)
            );

    driverTemplate(r) {
        return r.nome + ' ' + r.sobrenome;
    }

    onStatusChange(value) {
        if (value !== '') {
            this.driverList = this.originalDriverList.filter(
                d => d.situacao === +value
            );
        } else {
            this.driverList = this.originalDriverList;
        }
    }

}
