import { Input, Component } from "@angular/core";

@Component({
    selector: 'loading-container',
    template: `
        <section [hidden]="!loading" class="loading">
            <div class="loading-container">
                <div class="brand-text brand-big">
                    <img src="./assets/img/logo.png" />
                </div>
                <i class="fa fa-spinner fa-spin" style="color: white;"></i>
            </div>
        </section>
    `
  })
  export class LoadingContainerComponent {
      @Input() loading: boolean;
  }