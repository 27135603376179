import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dash-versoes',
  templateUrl: './dash-versoes.component.html'
})
export class DashVersoesComponent implements OnInit {

  versao = 'v1';

  constructor(
    private _activatedRoute: ActivatedRoute) {
    this.versao = this._activatedRoute.snapshot.params.versao
    console.log(this.versao);    

  }

  ngOnInit() {
  }

}
