import { City } from "./city";
import { Company } from "./company";
import { VehicleType } from "./vehicle-type";

export class Vehicle {
    id: string;
    placa: string;
    marca: string;
    modelo: string;
    cor: string;
    renavan: string;
    cidadeRegistro: City;
    empresaId: Number;
    tiposDeVeiculo: VehicleType;

}