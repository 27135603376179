import { Component, OnInit } from '@angular/core';
import {User} from '../../classes/user';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {AuthGuard} from '../../guards/auth.guard';
import { environment } from 'environments/environment.dev';

@Component({
  selector: 'app-painel-controle-jornadas',
  templateUrl: './dashboard-inconformidades.component.html',
})
export class DashboardInconformidadesComponent implements OnInit {

  empresaAtiva: number;
  loggedUser: User;
  safeUrl: SafeResourceUrl;

  constructor(
    private authGuard: AuthGuard,
    private sanitizer: DomSanitizer
  ) {
    this.loggedUser =  this.authGuard.loggedUser();
    this.empresaAtiva = this.loggedUser.selectedCompany.id;
    const baseUrl = 'https://metabase.rodoviasolucoes.com.br/public/dashboard/8d16e14b-d793-4676-b52d-eff87dc42ab2';
    const queryParams = `?empresa=${this.empresaAtiva}&unidade=&motorista=&texto=#hide_parameters=empresa`;
    const url = `${baseUrl}${queryParams}`;
    console.log(url);
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit() {

  }

}
