import { Arquivo } from './arquivo';
import { IDEntity } from './IDEntity';
export class Contratos extends IDEntity {
    dataCadastro: Date;
    status: number;
    item: String;
    tarefa: String;
    responsavel: String;
    expectativaInicio: Date;
    dataInicio: Date;
    dias: number;
    dataFinal: Date;
    expectativaFim: Date;
    produto: String;
    quantidade: number;
    observacao: String;
    empresa: number;
    tipoSistema: String;
    diasCorrentes: number;
    totalFaturando: number;
    totalImplantando: number;
    totalInadimplente: number;
    totalDesenvolvendo: number;
    totalNovaLicenca: number;
    totalTreinamento: number;
    totalCancelado: number;
    nomeEmpresa: String;
    cor: String;
    revenda: String;
    valorImplatacao: number;
    valorUnidade: number;
    valorContrato: number;
    listaDeArquivos: Arquivo[];
    implantandoAtrasado: number = 0;
    inadimplenteAtrado: number = 0;
    desenvolvendoAtrasado: number = 0;
    novalicencaAtrasado: number = 0;
    treinamentoAtrasado: number = 0;
    canceladoAtrasado: number = 0;
    teste: number = 0;
    testeAtrasado: number = 0;

}
