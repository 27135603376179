import { Component, ViewEncapsulation } from '@angular/core';
import { BaseListComponent } from 'app/components/base/base-list/base-list.component';
import { SascarVeiculoService } from 'app/services/crud/sascar-veiculo.service';
import { UtilService } from "../../../../services/utils.service";
import { PDFService } from "../../../../services/pdf.service";
import { AuthGuard } from "../../../../guards/auth.guard";

@Component({
    templateUrl: '../../../base/base-list/base-list.component.html',
    styleUrls: [ '../../../base/base-list/base-list.component.css' ],
    encapsulation: ViewEncapsulation.None
})
export class SascarVeiculoListComponent extends BaseListComponent {

  constructor(private sascarVeiculoService: SascarVeiculoService,
      private utilService: UtilService,
      pdfService: PDFService,
      authGuard: AuthGuard) {
      super(utilService, pdfService, authGuard);
      this.searchField = "placa";
  }


 ngOnInit() {
    this.pageTitle = "Cadastros | Sascar Veiculo";
      this.formPath = "/adm/sascarveiculo/form";

      this.tableHeaders = [
          [
              this.createHeader("Placa", 1, true),
              this.createHeader("ID Sascar", 2, true)
          ]
      ];

      this.dataFields = [
          this.createField("placa", "string"),
          this.createField("idSascar", "string")
      ];

      this.sascarVeiculoService.getLista().then(response => {
          this.data = response.listaSascarVeiculo;
          this.loading = false;
      });
  }

}
