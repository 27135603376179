import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSort, MatTableDataSource, MatDatepicker, MatSelect, MatOption, MatTable } from '@angular/material';
import { Filtro } from 'app/classes/filtro';
import { AuthGuard } from 'app/guards/auth.guard';
import { LocalService } from 'app/services/crud/local.service';
import { RankingMotoristaService } from 'app/services/crud/ranking-motorista.service';
import { VeiculoService } from 'app/services/crud/veiculo.service';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { utils, writeFile } from 'xlsx';
import { PDFService } from 'app/services/pdf.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';

// Services
import { ReportService } from 'app/services/report/report.service';

@Component({
  selector: 'app-exportacao-grade-jornada',
  templateUrl: './exportacao-grade-jornada.html',
  styleUrls: ['./exportacao-grade-jornada.css']
})
export class ExportacaoGradeJornadaComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public statusControl = new FormControl();
  public layoutControl = new FormControl();
  public searchType = 'D';
  public manualStart: Date;
  public manualEnd: Date;
  public loading: boolean;
  public empresaId: number;
  public userId: number;
  public displayedColumns: string[] = ['layout', 'data'];
  public dataSource: MatTableDataSource<any>;

  constructor(
    private reportService: ReportService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.loading = true;
    this.statusControl.setValue('');
    this.layoutControl.setValue('1');

    this.setEmpresaUserId();
    this.getHistoricoExportacao();
  }

  async getHistoricoExportacao() {
    const layoutNumber = Number(this.layoutControl.value);
    const resposta = this.reportService.getHistoricoExportacaoGradeJornada(this.empresaId, layoutNumber, 0, 20);
    let newHistory = [];
    (await resposta).map((item) => {
      const obj = {
        dataExportacao: this.datePipe.transform(item.dataExportacao , 'dd-MM-yyyy'),
        layout: item.layout === '1' ? 'Senior - Ronda' : ''
      }
      newHistory.push(obj);
    });
    this.dataSource = new MatTableDataSource(newHistory);
    this.dataSource.paginator = this.paginator;
    this.loading = false;
  }

  setEmpresaUserId() {
    const storage = JSON.parse(localStorage.getItem("currentUser"));
    this.empresaId = storage.selectedCompany.id;
    this.userId = storage.id;
  }

  download(filename, content) {
    var pom = document.createElement('a');
    pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
    pom.setAttribute('download', filename);
    pom.click();
}

  doSearch() {
    const body = {
      userId: this.userId,
      layout: Number(this.layoutControl.value),
      situacao: this.statusControl.value === '' ? null : Number(this.statusControl.value),
      dataInicio: this.datePipe.transform(this.manualStart, 'yyyy-MM-dd'),
      dataFim: this.datePipe.transform(this.manualEnd, 'yyyy-MM-dd'),
      codigoRelogio: "701"
    };
    this.reportService.ExportacaoGradeJornada(this.empresaId, body)
      .then(response => this.download(`layout-${body.dataInicio}-${body.dataFim}.txt`, response._body)
    );
  }

  disableExecutarButton() {
    return this.manualStart === undefined || this.manualEnd === undefined;
  }

}
