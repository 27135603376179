import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';
import { MatSort, MatTableDataSource, MatDatepicker, MatSelect, MatOption, MatTable } from '@angular/material';
import { DriverService } from 'app/services/crud/driver.service';
import { AuthGuard } from 'app/guards/auth.guard';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ReportService } from 'app/services/report/report.service';
import { UtilService } from 'app/services/utils.service';
import { utils, writeFile } from 'xlsx';
import { PDFService } from 'app/services/pdf.service';
import { VeiculoService } from 'app/services/crud/veiculo.service';

@Component({
  selector: 'app-detalhes-percurso-velocidade',
  templateUrl: './detalhes-percurso-velocidade.component.html',
  styleUrls: ['./detalhes-percurso-velocidade.component.css']
})
export class DetalhesPercursoVelocidadeComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public lat = -19.912998;
  public lng = -43.940933;
  public mapa: any;
  public zoomMapa = 7;

  public placaControl = new FormControl();
  public velMinControl = new FormControl();
  public velMaxControl = new FormControl();
  public periodoInicial: Date;
  public periodoFinal: Date;
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[] = ['ignicao', 'motorista', 'placa', 'dataHora', 'velocidade', 'rpm', 'posicaoPedal', 'endereco', 'local'];
  public placaList: any[] = [];
  public originalPlacaList: any[];
  public empresaId: number;
  public unidadeId: number;
  public idCliente: number;
  public unidadeName: string;
  public dataToExport: any[] = [];
  public showResult: boolean = false;
  public loading: boolean = false;
  public selectedVeiculos: any[] = [];

  constructor(private driverService: DriverService, private authGuard: AuthGuard, private veiculoService: VeiculoService,
    private datePipe: DatePipe, private reportService: ReportService, private utilService: UtilService, private pdfService: PDFService,) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.unidadeId = this.authGuard.loggedUser().selectedBranch.id;
    this.empresaId = this.authGuard.loggedUser().selectedCompany.id;
    this.unidadeName = this.authGuard.loggedUser().selectedBranch.name;
    this.idCliente = this.authGuard.loggedUser().id;

    this.getVeiculos();
  }

  getVeiculos() {
    this.veiculoService
      .getLista()
      .then((response) => {
        var data = response.listaDeVeiculos ? response.listaDeVeiculos : [];
        for (let i = 0; i < data.length; i++) {
          this.placaList.push({
            id: data[i].id,
            placa: data[i].placa,
          });
        };
        this.originalPlacaList = this.placaList;
      })
      .catch((error) => console.error(error));
  }

  disableExecutarButton() {
    return this.periodoInicial === undefined || this.periodoFinal === undefined || this.placaControl === undefined;
  }

  showExportsButtons() {
    return this.dataToExport.length > 0;
  }

  selecionarTodos(matSelect: MatSelect) {
    const isSelected: boolean = matSelect.options
      .filter((item: MatOption) => item.value === 0)
      .map((item: MatOption) => item.selected)
    [0];

    if (isSelected) {
      matSelect.options.forEach((item: MatOption) => item.select());
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  exportExcel() {
    if (this.dataToExport.length > 0) {
      const ws = utils.json_to_sheet(this.dataToExport);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'Relatório Percurso e Velocidade');
      return writeFile(wb, `RelatorioPercursoVelocidade${moment(this.periodoInicial).format(`DDMMYYYYHHmm`)}/${moment(this.periodoFinal).format(`DDMMYYYYHHmm`)}.xlsx`);
    }
  }

  createHeader(
    name: string,
    dataIndex?: number,
    canSort?: boolean,
    colspan?: number,
    css?: string,
    tooltip?: string
  ) {
    if (canSort == null) canSort = false;
    if (colspan == null) colspan = 1;
    if (dataIndex == null) dataIndex = -1;
    if (css == null) css = 'title-left';
    if (tooltip == null) tooltip = '';

    return {
      name: [name],
      canSort: canSort,
      colspan: colspan,
      dataIndex: dataIndex,
      css: css,
      tooltip: tooltip
    };
  }

  exportPrint() {
    const data = this.dataToExport.map((item) => {
      return {
        motorista: item.motorista,
        placa: item.placa,
        ignicao: item.ignicao,
        dataHora: item.dataHora,
        velocidade: item.velocidade,
        rpm: item.rpm,
        posicaoPedal: item.posicaoPedal,
        endereco: item.endereco,
        local: item.local
      }
    });
    const dataFields = Object.keys(data[0]).map((field) => ({
      name: field,
      prefix: '',
      separator: "",
      sufix: "",
      type: "string",
    }));
    const headers = [
      [
        this.createHeader("Motorista", 0, true),
        this.createHeader("Placa", 1, true),
        this.createHeader("Ignição", 2, true),
        this.createHeader("Data/Hora", 3, true),
        this.createHeader("Velocidade", 4, true),
        this.createHeader("RPM", 5, true),
        this.createHeader("Posição Pedal", 6, true),
        this.createHeader("Endereço", 7, true),
        this.createHeader("Local", 8, true)
      ]
    ];
    const pageOrientation = 'landscape';
    this.pdfService.printList(
      data,
      dataFields,
      headers,
      pageOrientation
    );
  }

  obterCorChave(row: any) {
    if (row.ignicao === 'A') {
      return '#268917';
    } else {
      return '#cc3632';
    }
  }

  obterToolTip(row: any) {
    if (row.ignicao === 'A') {
      return 'Ligado';
    } else {
      return 'Desligado';
    }
  }

  onMapReady(item: any) {
    this.mapa = item;
  }

  exibirNoMapa(row) {
    this.lat = row.latitude;
    this.lng = row.longitude;
  }

  async doSearch() {
    this.dataToExport = [];
    this.loading = true;
    const placas = [];
    this.placaControl.value.forEach((placa) => placa.id ? placas.push({ id: placa.id }) : null);
    const body = {
      dataInicialString: moment(this.periodoInicial).format(`DDMMYYYYHHmm`),
      dataFinalString: moment(this.periodoFinal).format(`DDMMYYYYHHmm`),
      listaDeVeiculos: placas,
      idCliente: this.idCliente,
      velocidadeMinima: this.velMinControl.value,
      velocidadeMaxima: this.velMaxControl.value
    };
    this.reportService.RelatorioPercursoVelocidade(JSON.stringify(body))
      .then(response => {
        const parsed = JSON.parse(response._body)
        if (parsed.codigoRetorno === 110) {
          const listaInformacaoRelatorio = parsed.informacaoRelatorio.listaInformacaoRelatorio ? parsed.informacaoRelatorio.listaInformacaoRelatorio : [];
          const tableData = listaInformacaoRelatorio.map((item) => {
            const placaNome = this.placaList.find((veiculo) => veiculo.id === Number(item.campo10));
            return {
              motorista: item.campo09 === '-1' ? 'Sem motorista associado' : item.campo11,
              placa: placaNome ? placaNome.placa : '-',
              ignicao: item.campo01,
              dataHora: item.campo02,
              velocidade: `${item.campo03} km/h`,
              endereco: item.campo04,
              local: item.campo08,
              latitude: item.campo05,
              longitude: item.campo06,
              rpm: item.campo12,
              posicaoPedal: item.campo13
            }
          })
          this.dataSource = new MatTableDataSource(tableData);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataToExport = tableData;
        }
        this.loading = false;
      })

  }
}