import { OnInit, Output, EventEmitter, Component, ElementRef, ViewChild, AfterViewInit, Compiler, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthGuard } from '../../guards/auth.guard';
import { AmChartsService, AmChart } from '@amcharts/amcharts3-angular';
import { JourneyState } from '../../classes/journey-state';
import { JourneyStateService } from '../../services/crud/journey-state.service';
import { EventsService } from '../../services/crud/events.service';
import { DriverVO } from '../../classes/driver-vo';
import { JourneyService } from '../../services/crud/journey.service';
import { BaseListComponent } from '../base/base-list/base-list.component';
import { UtilService } from '../../services/utils.service';
import { PDFService } from '../../services/pdf.service';
import { Subscription, Observable, timer, interval } from 'rxjs';
import { DynamicStripedTableComponent } from '../base/dynamic-striped-table/dynamic-striped-table.component';
import { HighchartsService } from 'app/services/highcharts.service';
import { DashboardHomeService } from 'app/services/dashboard/dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardMapFilterComponent } from '../dashboard-map-filter/dashboard-map-filter.component';
import { takeWhile } from 'rxjs/operators';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { MapDetailsComponent } from '../map-details/map-details.component';
import { catchError, debounceTime, distinctUntilChanged, finalize, switchMap, tap, map } from 'rxjs/operators';
import * as moment from 'moment';
import { of } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { MapEquipamentoDetailsComponent } from '../map-equipamento-details/map-equipamento-details.component';
import { MapMotoristaDetailsComponent } from '../map-motorista-details/map-motorista-details.component';
import { MouseEvent, GoogleMapsAPIWrapper, AgmMap } from '@agm/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatAccordion } from '@angular/material/expansion';
// tslint:disable-next-line:max-line-length
import { ModalConfigurarPainelDashboardComponent } from 'app/shared/components/modal-configurar-painel-dashboard/modal-configurar-painel-dashboard.component';
import { RotaService } from '../../services/rota.service';
import { EventDetailsComponent } from '../event/event-details.component';
import { ObservacoesIncoformidadesComponent } from '../observacoes-inconformidades/observacoes-inconformidades.component';

@Component({
  selector: 'app-daily-panel-dashboard',
  templateUrl: './panel-dashboard.component.html',
  styleUrls: ['./panel-dashboard.component.css']
})
export class DailyPanelDashboardComponent extends BaseListComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('iframe') iframe: ElementRef;
  @ViewChild('charts') chartEl: ElementRef;
  @ViewChild('charts2') chartE2: ElementRef;
  @ViewChild('charts3') chartE3: ElementRef;
  @ViewChild('charts4') chartE4: ElementRef;
  @ViewChild(AgmMap) map: AgmMap;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  // ---------------------------------------------

  
  displayedColumnsDefault : string[] = ['ign', 'GPS',
    'nomeDoMotorista', 'descricaoVeiculo', 'placa', 'localizacaoCompleta', 'voltagem',
    'velocidade', 'data_Transmissao', 'nomeCercaRota', 'rota'];

  displayedColumnsViaM = ['matricula', 'nome', 'ultimoEvento', 'dataEvento',
    'endereco','statusAtual', 'unidade', 'turno'];

  displayedColumnsInconfViaSat = ['placa', 'nome', 'tipo', 'descricao',
    'data', 'endereco', 'unidade', 'acao', 'checkLinha']

  displayInconformidadeViaSat = false;

  displayedColumns: string[];


  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  exibirTextoCerca = false;

  tituloViaSat = '';
  tituloInconformidadesViaSat = '';
  tituloInconformidadesViaM = '';
  tituloViaM = '';
  checkSelectAll = false;

  // --------------------------------

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtInstance: Promise<DataTables.Api>;

  public items$: Observable<any[]>;
  public input$ = new Subject<string | null>();

  public itemsp$: Observable<any[]>;
  public inputp$ = new Subject<string | null>();

  public itemsm$: Observable<any[]>;
  public inputm$ = new Subject<string | null>();

  // listas grafico inconformidade
  listaDeInconformidadeViaSat: any[] = [];
  listaDeInconformidadeViaM: any[] = [];
  listaAlertaViaSat: any[] = [];
  desativarReset = true;
  // listas grafico via M
  listaDelistaDeEventosViam: any[] = [];
  listaDeEventosJornada: any[] = [];
  zoomMapa = 3;

  clusterOptions = {
    imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    gridSize: 100,
    zoomOnClick: true,
    maxZoom: 10,
  };

  lat = -19.912998;
  lng = -43.940933;

  habilitarAtualizar: boolean;

  polygonPts: any[] = [];
  cercasFiltro: any[] = [];
  panelOpenState: boolean;
  panelOpenState1: boolean;
  panelOpenState2: boolean;
  exibirmensagem = false;

  isCollapsed = true;

  placas: any[] = [];
  locais: any[] = [];
  motoristas: any[] = [];

  parametrosChart: any = {
    desligados: 0,
    ativos: 0,
    inativos: 0,
    portateis: 0
  };

  atualizador: any;
  rotas: any[] = [];
  dadosDashboard: any;
  dadosTabela: any[] = [];
  mapMarkers: any[] = [];
  mapMarkersFiltro: any[] = [];
  markers: any[] = [];
  openFilter = false;
  erromessage = '';
  formGroup: FormGroup;
  formGroupAtualizacao: FormGroup;
  cercas: any[] = [];
  ptsList: string;
  rota = '';
  dadosGraficoViaM: any;
  dadosGraficoViaSat: any;
  dadosGraficoInconformidadesViaM: any;
  dadosGraficoInconformidadesViaSat: any;
  noData = false;
  desabilitarSettings = false;
  rotasMap: any;
  rotasMapDropdown: any[] = [];
  marcadores: any[] = [];
  mapa: any;
  polylines: any[] = [];
  mapMarkersOriginal: any[] = [];
  public filteredListRotas: any;

  dtOptions: DataTables.Settings = {};

  everySecond: Observable<number> = timer(0, 1000);
  private subscription: Subscription;
  private chart: AmChart;
  private stateList: JourneyState[];
  private driverTotal: number;
  private driverList: Map<string, { count: number; journeys: Number[]; color: string }>;
  public selectedSlice: {
    tipoEvento: string;
    count: number;
    color: string;
    journeys: string;
    drivers: DriverVO[];
  };

  public dynamicStripedTable: DynamicStripedTableComponent;
  public searchName: string;

  public dataProvider: any[];
  public showGraphic: boolean;
  isFiltering = false;


  constructor(
    private highcharts: HighchartsService,
    private authGuard: AuthGuard,
    private AmCharts: AmChartsService,
    private router: Router,
    private formBuilder: FormBuilder,
    private jouneyStateService: JourneyStateService,
    private eventService: EventsService,
    private journeyService: JourneyService,
    private utilService: UtilService,
    private dashboardHomeService: DashboardHomeService,
    pdfService: PDFService,
    private modalService: NgbModal,
    private _compiler: Compiler,
    private spinner: NgxSpinnerService,
    private rotaService: RotaService
    // private mapsWrapper: GoogleMapsAPIWrapper
  ) {
    super(utilService, pdfService, authGuard);
    this._compiler.clearCache();

    this.formGroup = this.formBuilder.group({
      motorista: true,
      frota: true,
      locais: true,
      localModel: [],
      motoristaModel: [],
      frotaModel: []
    });
    this.buscarLocaisGrid();
    this.buscarRotasGrid();

    this.items$ = this.input$.pipe(
      map((term) => this.searchLocais(term))
    );

    this.itemsp$ = this.inputp$.pipe(
      map((term) => this.searchPlacas(term))
    );

    this.itemsm$ = this.inputm$.pipe(
      map((term) => this.searchMotoristas(term))
    );
    this.montarCercasMapa();

    const loggedUser = this.authGuard.loggedUser();
    this.desabilitarSettings = loggedUser.selectedBranch.id === 0;

    this.habilitarAtualizar = loggedUser.habilitarAtualizacaoDashboard;
    this.formGroupAtualizacao = this.formBuilder.group({
      habilitarAtualizacaoDashboard: loggedUser.habilitarAtualizacaoDashboard
    });
  }

  openAll() {
    this.accordion.openAll();
  }

  closeAll() {
    this.accordion.closeAll();
  }


  configurarInconformidades(): void {
    // tslint:disable-next-line:max-line-length
    const modalRef = this.modalService.open(ModalConfigurarPainelDashboardComponent, { size: <any>'sm', backdrop: 'static', keyboard: false, windowClass: 'vehicle-modal' });
    modalRef.componentInstance.dados = null;
    modalRef.result.then((result) => {
      if (result) {
      }
    }).catch((res) => { });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  changeAtualizacao(event) {
    const loggedUser = this.authGuard.loggedUser();
    try {
      this.dashboardHomeService.alterarParamentroAtualizacaoDashboard(loggedUser.id, event.checked).then(result => {
        if (result.error.length > 0) {
          this.formGroupAtualizacao.patchValue({
            habilitarAtualizacaoDashboard: false
          });
          this.habilitarAtualizar = false;
          Swal.fire({
            type: 'error',
            title: 'Ops...',
            text: result.error,
            showConfirmButton: true,
            confirmButtonText: 'Ok',
          });
        } else {
          this.habilitarAtualizar = event.checked;
          this.formGroupAtualizacao.patchValue({
            habilitarAtualizacaoDashboard: event.checked
          });
          Swal.fire({
            position: 'top-end',
            type: 'success',
            title: 'Sucesso.',
            text: 'Configuração de atualização do dashboard alterada com sucesso!',
            showConfirmButton: false,
            timer: 1500
          });
        }
      });
      localStorage.setItem('currentUser', JSON.stringify({ ...loggedUser, habilitarAtualizacaoDashboard: event.checked }));
      this.handleAtualizadorDashboard();
    } catch (error) {
      console.error(error);
    }
  }

  buscarCercas() {
    const loggedUser = this.authGuard.loggedUser();
    this.dashboardHomeService.cercasFiltroMapa(loggedUser.selectedCompany.id).then(result => {
      this.polygonPts = result;
      this.cercasFiltro = result;
    });
  }

  searchLocais(term: string | null): any[] {
    const searchTerm = term ? term : '';
    return this.locais.filter((item) => {
      return item.nome.toLowerCase().startsWith(searchTerm.toLowerCase());
    });
  }

  searchPlacas(term: string | null): any[] {
    const searchTerm = term ? term : '';
    return this.placas.filter((item) => {
      return item.placa.toLowerCase().startsWith(searchTerm.toLowerCase());
    });
  }

  searchMotoristas(term: string | null): any[] {
    const searchTerm = term ? term : '';
    return this.motoristas.filter((item) => {
      return item.nomeDoMotorista.toLowerCase().startsWith(searchTerm.toLowerCase());
    });
  }

  ngOnInit(): void {

    this.driverTotal = 0;
    this.loading = true;
    this.dataProvider = new Array();
    this.showGraphic = true;
    this.displayedColumns = this.displayedColumnsDefault;

    this.handleAtualizadorDashboard();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      language: {
        emptyTable: 'Nenhum registro encontrado',
        info: 'Mostrando de _START_ até _END_ de _TOTAL_ registros',
        infoEmpty: 'Mostrando 0 até 0 de 0 registros',
        infoFiltered: '(Filtrados de _MAX_ registros)',
        infoPostFix: '',
        lengthMenu: '_MENU_ resultados por página',
        loadingRecords: 'Carregando...',
        processing: 'Processando...',
        zeroRecords: 'Nenhum registro encontrado',
        search: 'Pesquisar',
        paginate: {
          next: 'Próximo',
          previous: 'Anterior',
          first: 'Primeiro',
          last: 'Último'
        },
        aria: {
          sortAscending: ': Ordenar colunas de forma ascendente',
          sortDescending: ': Ordenar colunas de forma descendente'
        }
      }
    };
    // this.onChanges();
    this.criarDashboard();
  }

  handleAtualizadorDashboard() {
    const loggedUser = this.authGuard.loggedUser();

    const milisegundos = moment.duration(moment(`${loggedUser.tempoAtualizacaoDashboard}`, 'HH:mm').format('HH:mm')).asMilliseconds();
    this.atualizador = setInterval(() => {
      if (this.habilitarAtualizar) {
        this.criarDashboard(true);
      }
    }, milisegundos);
  }

  obterToolTip(row: any) {
    if (row.estaInativo) {
      return 'Inativo';
    } else {
      if (row.ignicao_Ativa) { return 'Ligado'; } else { return 'Desligado'; }
    }
  }

  exibirNoMapa(row: any, redirecionar = false) {
    this.mapMarkers = [];
    this.zoomMapa = 8;
    if(row.latitude && row.longitude ) {
      this.lat = Number(row.latitude);
      this.lng = Number(row.longitude);
      
      if(redirecionar)
        this.abrirMapaAba(row.longitude, row.latitude);

      setTimeout(() => {
        this.mapMarkers.push(row);
      }, 5);
    }
  }

  obterCorChave(row: any) {
    if (row.estaInativo) {
      return '#7d818c';
    } else {
      if (row.ignicao_Ativa) { return '#268917'; } else { return '#cc3632'; }
    }
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    clearInterval(this.atualizador);
  }

  getTextoLocalization(row) {
    //  if (row.estaEmUmaCerca) {
    return `${row.endereco} ${row.cercaAtual}`;
    // } else {
    //     return row.endereco;
    // }
  }

  onChanges(): void {
    this.formGroup.valueChanges.subscribe(val => {
      this.mapMarkers = [];
      this.polygonPts = [];
      this.dadosTabela = [];
      this.zoomMapa = 3;
      // itens mapa

      const locais = val.locais ? this.cercasFiltro : [];
      const veiculos = val.frota ? this.markers.filter(x => x.tipoFiltro === 1) : [];
      const motoristas = val.motorista ? this.markers.filter(x => x.tipoFiltro === 3) : [];
      const filtrados = [];

      setTimeout(() => {
        this.polygonPts = [...locais];
        this.mapMarkers = [...motoristas, ...veiculos];
        this.dataSource = new MatTableDataSource(this.mapMarkers);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 10);

      if ((val.frotaModel && val.frotaModel.length > 0) ||
        (val.localModel && val.localModel.length > 0) ||
        (val.motoristaModel && val.motoristaModel.length > 0)) {
        this.mapMarkers = [];
      }

    });
  }

  // ============================================  metodos datatable monitoramento  ============================================

  getImagemIgnicao(ligado: boolean) {
    if (ligado) { return './../../assets/img/img-chave-ligada.png'; } else {
      return './../../assets/img/img-chave-desligada.png';
    }
  }

  abrirMapaAba(lon, lat) {
    window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lon}`, '_blank');
  }

  buscarRotasGrid() {
    const loggedUser = this.authGuard.loggedUser();
    try {
      this.dashboardHomeService.buscarRotasGrid(loggedUser.selectedCompany.id, loggedUser.selectedBranch.id).then(result => {
        this.rotas = result;
      });
    } catch (error) {
      console.error(error);
    }
  }

  changeMapaRotas(event: any) {
    if (this.rotasMapDropdown.length == 0 && event) {
      this.rotaService.getLista(true).then(result => {
        if (result.mensagem == 'Lista de Rota Cerregada com sucesso!') {
          this.rotasMap = result;
          this.rotasMapDropdown = this.rotasMap.listaDeRota.map((item) => {
            return { id: item.id, name: item.nome };
          });
        }
      });
    } else {
      this.limparRota();
    }
  }

  obterInfoRota(rota: any) {
    this.rotaService.getById(rota.id).then(result => {
      const listaCordenadas = result.rota.listaDeCoordenadas.map((item) => {
        return { latitude: item.latitude, longitude: item.longitude };
      });
      this.limparRota();
      this.ajustarGoogleMaps(listaCordenadas);
    });
  }

  ajustarGoogleMaps(listaCordenadas) {
    try {
      var pathLL = [];
      listaCordenadas.forEach(c => {
        var a1 = new google.maps.LatLng(c.latitude, c.longitude);
        pathLL.push(a1);
      });

      var markerInicial = new google.maps.Marker({
        position: new google.maps.LatLng(listaCordenadas[0].latitude, listaCordenadas[0].longitude),
        map: this.mapa,
        icon: "http://maps.google.com/mapfiles/markerA.png",
      });
      this.marcadores.push(markerInicial);

      var markerFinal = new google.maps.Marker({
        position: new google.maps.LatLng(listaCordenadas[(listaCordenadas.length - 1)].latitude, listaCordenadas[(listaCordenadas.length - 1)].longitude),
        map: this.mapa,
        icon: "http://maps.google.com/mapfiles/markerB.png",
      });
      this.marcadores.push(markerFinal);

      var flightPath = new google.maps.Polyline({
        path: pathLL,
        geodesic: true,
        strokeColor: "#0000FF",
        strokeOpacity: 2.0,
        strokeWeight: 3,
      });
      flightPath.setMap(this.mapa);
      this.polylines.push(flightPath);
      var center = new google.maps.LatLng(listaCordenadas[0].latitude, listaCordenadas[0].longitude);
      this.mapa.panTo(center);
      this.mapa.setZoom(10);
    } catch (error) {
      console.error(error);
      
    }
  }

  limparRota() {
    try {
      this.limparMarcadores();
    } catch (error) {
      console.log(error);
    }
  }

  limparMarcadores() {
    try {
      this.marcadores.forEach(m => {
        m.setMap(null);
      });
      this.marcadores = [];
      this.polylines.forEach(m => {
        m.setMap(null);
      });
      this.polylines = [];
    } catch (error) {

    }
  }


  buscarLocaisGrid() {
    const loggedUser = this.authGuard.loggedUser();
    try {
      this.dashboardHomeService.cercasFiltroMapa(loggedUser.selectedCompany.id).then(result => {
        this.locais = result;
      });
    } catch (error) {
      console.error(error);
    }
  }

  montarCercasMapa() {
    const loggedUser = this.authGuard.loggedUser();
    try {
      this.dashboardHomeService.montarCercasMapa(loggedUser.selectedCompany.id).then(result => {
        this.polygonPts = result;
        this.cercasFiltro = result;
      });
    } catch (error) {
      console.error(error);
    }
  }

  onLabelRotaChange(event, item) {
    this.spinner.show();
    try {
      this.dashboardHomeService.addRotaVeiculo(item.idObjeto, event.idrota).then(result => {
        this.spinner.hide();
      });
    } catch (error) {
      this.spinner.hide();
      console.error(error);
    }
  }

  onRemove(event: any, item: any) {
    this.spinner.show();
    try {
      this.dashboardHomeService.removeRotaVeiculo(item.idObjeto, event.value.idrota).then(result => {
        this.spinner.hide();
      });
    } catch (error) {
      this.spinner.hide();
      console.error(error);
    }
  }

  // ===========================================  metodos modal filtro  ========================================================

  onFrotaChange(event) {
    this.mapMarkers = [];
    if (event.length > 0) {
      this.markers.filter((item) => {
        event.forEach(element => {
          if (element.placa.toLowerCase() === item.placa.toLowerCase() && item.tipoFiltro === 1) {
            this.mapMarkers.push(item);
          }
        });
      });
      this.zoomMapa = 8;
      this.dataSource = new MatTableDataSource(this.mapMarkers);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else {
      this.zoomMapa = 6;
      this.mapMarkers = this.markers;
    }
  }

  onMotoristaChange(event) {
    this.mapMarkers = [];
    if (event.length > 0) {
      this.markers.filter((item) => {
        event.forEach(element => {
          if (element.nomeDoMotorista.toLowerCase() === item.nomeDoMotorista.toLowerCase() && item.tipoFiltro === 3) {
            this.mapMarkers.push(item);
          }
        });
      });
      this.zoomMapa = 8;
      this.dataSource = new MatTableDataSource(this.mapMarkers);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else {
      this.zoomMapa = 6;
      this.mapMarkers = this.markers;
    }
  }

  onLabelChange(event) {
    const lista = [];
    this.polygonPts = [];
    event.forEach(element => {
      lista.push(element.id);
    });
    setTimeout(() => {
      if (lista.length > 0) {
        this.dashboardHomeService.coordenadas(lista).then(result => {
          if (result.length > 0) {
            this.zoomMapa = 14;
            this.lat = result[0].lat;
            this.lng = result[0].lng;
          }
          this.polygonPts = result;
        });
      } else {
        this.zoomMapa = 6;
        this.polygonPts = this.cercasFiltro;
      }
    }, 80);

  }

  // ===================================  Metodos do Mapa  =================================================================

  changeMapaLocais(event) {
    this.polygonPts = [];
    const locais = event ? this.cercasFiltro : [];
    this.polygonPts = [...locais];
  }

  changeMapaMotorista(event) {
    this.mapMarkers = [];
    this.dadosTabela = [];
    const veiculos = this.markers.filter(x => x.tipoFiltro === 1);
    const motoristas = event ? this.markers.filter(x => x.tipoFiltro === 3) : [];
    this.mapMarkers = [...motoristas, ...veiculos];
    this.dataSource = new MatTableDataSource(this.mapMarkers);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  changeMapaFrota(event) {
    this.mapMarkers = [];
    this.dadosTabela = [];
    const veiculos = event ? this.markers.filter(x => x.tipoFiltro === 1) : [];
    const motoristas = this.markers.filter(x => x.tipoFiltro === 3);
    this.mapMarkers = [...motoristas, ...veiculos];
    this.dataSource = new MatTableDataSource(this.mapMarkers);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  markerDragEnd(mapMarker, event) {
  }

  onZoomChange(event) {
    if (event > 14) { this.exibirTextoCerca = true; } else { this.exibirTextoCerca = false; }
  }

  onMapReady(mapa) {
    mapa.setOptions({
      zoomControl: 'true',
      // zoomControlOptions: {
      //     position: google.maps.ControlPosition.TOP_RIGHT
      // }
    });
    this.mapa = mapa;
  }

  onMarkerClickEvent(event, i) {
    if (event.icone === 'PORTATIL') {
      // tslint:disable-next-line:max-line-length
      const modalRef = this.modalService.open(MapEquipamentoDetailsComponent, { size: <any>'xl', backdrop: 'static', keyboard: false, windowClass: 'vehicle-modal' });
      modalRef.componentInstance.dados = event;
      modalRef.result.then((result) => {
        if (result) {
        }
      }).catch((res) => { });
    } else if (event.icone === 'MOTORISTA' || event.icone === 'CELULAR') {
      // tslint:disable-next-line:max-line-length
      const modalRef = this.modalService.open(MapMotoristaDetailsComponent, { size: <any>'xl', backdrop: 'static', keyboard: false, windowClass: 'vehicle-modal' });
      modalRef.componentInstance.dados = event;
      modalRef.result.then((result) => {
        if (result) {
        }
      }).catch((res) => { });
    } else {
      // tslint:disable-next-line:max-line-length
      const modalRef = this.modalService.open(MapDetailsComponent, { size: <any>'xl', backdrop: 'static', keyboard: false, windowClass: 'vehicle-modal' });
      modalRef.componentInstance.dados = event;
      modalRef.result.then((result) => {
        if (result) {
        }
      }).catch((res) => { });
    }
  }

  async limparRotas() {
    const confirmacao = await Swal.fire({
      type: "question",
      title: "Atenção!",
      text: "Ao clicar nessa opção irá limpar todas as rotas dos veículos, deseja continuar?",
      confirmButtonText: "Sim",
      showCancelButton: true,
      cancelButtonText: "Não",
    });
    if (confirmacao.value) {
      this.spinner.show();
      this.dashboardHomeService.excluirRotasVeiculos(this.placas).then(result => {
        this.spinner.hide();
        Swal.fire({
          position: 'top-end',
          type: 'success',
          title: 'Sucesso.',
          text: 'Rotas excluidas com sucesso!',
          showConfirmButton: false,
          timer: 1500
        });
      }).catch((ex) => { this.spinner.hide(); });
    }
  }

  abrirLocalizacao(row) {
    // tslint:disable-next-line:max-line-length
    const modalRef = this.modalService.open(MapDetailsComponent, { size: <any>'xl', backdrop: 'static', keyboard: false, windowClass: 'vehicle-modal' });
    modalRef.componentInstance.dados = row;
    modalRef.result.then((result) => {
      if (result) {
      }
    }).catch((res) => { });
  }

  openModalObservcao(id: number) {

    const check = document.querySelector('#checkAcaoItem_' + id.toString()) as any;
    const linhas = [];
    if(check) {
      check.checked = check;
    }

    const checkboxes = document.querySelectorAll('input[id^="checkAcaoItem_"]');

    checkboxes.forEach((checkbox: any) => {
      if(checkbox.checked === true) {
        linhas.push(checkbox.value);
      }
    });

    const dados = this.dataSource.data.filter(c=> linhas.includes(c.id.toString()));
    
    const modalRef = this.modalService.open(ObservacoesIncoformidadesComponent, 
      { size: <any>'xl', backdrop: 'static', keyboard: false, windowClass: 'vehicle-modal' });
    modalRef.componentInstance.dados = dados;
    modalRef.result.then((result) => {
      if (result) {
      }
    }).catch((res) => { });
  }

  abrirDetalheEvento(row) {
    
    const modalRef = this.modalService.open(EventDetailsComponent, { size: <any>'xl', backdrop: 'static', keyboard: false, windowClass: 'vehicle-modal' });
    modalRef.componentInstance.dados = row;
    modalRef.result.then((result) => {
      if (result) {
      }
    }).catch((res) => { });
  }


  // ========================================  Metodos Graficos  ====================================================

  combineArray(array1, array2) {
    const ajuste = [...array1, ...array2];
    return ajuste;
  }

  overlayCreated(event) {
  }
  polygonCreated(event) {
  }

  gerarGraficoViaM() {
    this.spinner.show();
    const loggedUser = this.authGuard.loggedUser();
    this.dashboardHomeService.graficoViaMNovo(loggedUser.selectedCompany.id, loggedUser.selectedBranch.id).then(result => {
      this.spinner.hide();
      this.graficoViaM(result);
      this.dadosGraficoViaM = [];

      if(result && result.length) {
        result.forEach(element => {
          this.dadosGraficoViaM.push(...element.listaDeMotoristas);
        });    
      }
      const dadosTabela = this.combineArray(this.dadosTabela, this.dadosGraficoViaM);
      this.dadosTabela = dadosTabela;
      this.mapMarkers = dadosTabela.filter(c=> c.latitude && c.longitude);
      this.markers = dadosTabela;
      this.zoomMapa = 3;
    });
  }

  gerarGraficoViaSat(isRefreshing = false) {
    this.spinner.show();
    const loggedUser = this.authGuard.loggedUser();
    this.dashboardHomeService.graficoViaSat(loggedUser.selectedCompany.id, loggedUser.selectedBranch.id, 0).then(result => {
      this.spinner.hide();
      const loggedUser = this.authGuard.loggedUser();
      const currentBranch = loggedUser.selectedBranch.id;
      if (currentBranch > 0) {
        result.grafico = result.grafico.length ? result.grafico.filter(item => item.name != "Portateis") : result.grafico;
      }
      this.graficoViaSat(result.grafico);
      this.dadosGraficoViaSat = result.mapa;
      const dadosTabela = this.combineArray(this.dadosTabela, result.mapa.map((event) => {
        if (event.nomeDoMotorista) {
          event.nomeDoMotorista = `Motorista: ${event.nomeDoMotorista}`;
        } else if (!event.nomeDoMotorista && event.ibutton && event.ibutton != '0' && event.ibutton != '00') {
          event.nomeDoMotorista = `IButton: ${event.ibutton}`;
        } else {
          event.nomeDoMotorista = 'Nenhum';
        }
        return event;
      }));

      this.dadosTabela = dadosTabela;
      this.mapMarkers = dadosTabela;
      this.markers = dadosTabela;

      result.mapa.forEach(element => {
        this.placas.push({ id: element.idObjeto, placa: element.placa });
      });

      if (isRefreshing && this.isFiltering) return;

      this.dataSource = new MatTableDataSource(this.dadosGraficoViaSat);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  gerarGraficoInconformidadesViaSat() {
    this.spinner.show();
    const loggedUser = this.authGuard.loggedUser();
    this.dashboardHomeService.graficoInconformidadesViaSat(loggedUser.selectedCompany.id, loggedUser.selectedBranch.id)
    .then(async (result) => {
      this.spinner.hide();
      this.graficoAlertasIncormidadesViaSat(result.grafico);
      this.dadosGraficoInconformidadesViaSat = result.mapa;
      // const dadosTabela = this.combineArray(this.dadosTabela, result.mapa);
      // this.dadosTabela = dadosTabela;
      // this.mapMarkers = dadosTabela;
      // this.markers = dadosTabela;

      result.mapa.forEach(element => {
        this.placas.push({ id: element.idObjeto, placa: element.placa });
      });

      // this.dataSource = new MatTableDataSource(dadosTabela);
      // this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
    });
  }

  gerarGraficoInconformidadesViaM() {
    this.spinner.show();
    const loggedUser = this.authGuard.loggedUser();
    this.dashboardHomeService.graficoInconformidadesViaM(loggedUser.selectedCompany.id, loggedUser.selectedBranch.id).then(result => {
      this.spinner.hide();
      this.graficoAlertasIncormidadesViaM(result.grafico);
      this.dadosGraficoInconformidadesViaM = result.mapa;
      // const dadosTabela = this.combineArray(this.dadosTabela, result.mapa);
      // this.dadosTabela = dadosTabela;
      // this.mapMarkers = dadosTabela;
      // this.markers = dadosTabela;

      result.mapa.forEach(element => {
        this.motoristas.push({ id: element.idObjeto, nomeDoMotorista: element.nomeDoMotorista });
      });

      // this.dataSource = new MatTableDataSource(dadosTabela);
      // this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
    });
  }

  selecionarViaSat() {
    this.displayInconformidadeViaSat = false;
    this.displayedColumns = this.displayedColumnsDefault;
    this.markers = this.dadosGraficoViaSat;
    this.dataSource = new MatTableDataSource(this.dadosGraficoViaSat);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  selecionarInconformidadesViaSat() {
    this.displayInconformidadeViaSat = true;
    this.displayedColumns = this.displayedColumnsInconfViaSat;
    this.markers = this.dadosGraficoInconformidadesViaSat;
    this.dataSource = new MatTableDataSource(this.dadosGraficoInconformidadesViaSat);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  selecionarInconformidadesViaM() {
    this.displayInconformidadeViaSat = false;
    this.displayedColumns = this.displayedColumnsDefault;
    this.markers = this.dadosGraficoInconformidadesViaM;
    this.dataSource = new MatTableDataSource(this.dadosGraficoInconformidadesViaM);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  selecionarViaM() {
    this.displayInconformidadeViaSat = false;
    this.displayedColumns = this.displayedColumnsViaM;
    this.markers = this.dadosGraficoViaM;
    this.dataSource = new MatTableDataSource(this.dadosGraficoViaM);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  graficoViaSat(data) {
    const total = data.map(o => o.quantidade).reduce((a, c) => a + c);
    this.tituloViaSat = `Via-Sat [${total}]`;
    const config = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: null
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b>'
      },
      accessibility: {
        point: {
          valueSuffix: ''
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        },
        series: {
          turboThreshold: 3000,
          cursor: 'pointer',
          point: {
            events: {
              click: function (e) {
                const p = e.point;
                this.graficoViaSatMethod(p);
              }.bind(this)
            }
          }
        }
      },
      series: [{
        name: `Via-Sat`,
        colorByPoint: true,
        data: data
      }],
      lang:{noData:'Sem dados a exibir!'}
    };

    this.highcharts.createChart(this.chartEl.nativeElement, config);
  }

  graficoAlertasIncormidadesViaSat(data) {
    const total = data.map(o => o.quantidade).reduce((a, c) => a + c);
    this.tituloInconformidadesViaSat = `Inconformidades Via-Sat [${total}]`;
    this.checkSelectAll = false;

    const dta = data.map(c=> {return {
      name: c.name,
      y: c.y,
      color: c.cor,
      markers: c.listaDeIncormidades
    }});

    const config = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: null
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b>'
      },
      accessibility: {
        point: {
          valueSuffix: ''
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        },
        series: {
          turboThreshold: 3000,
          cursor: 'pointer',
          point: {
            events: {
              click: function (e) {
                const p = e.point;
                this.graficoAlertasInconformidadesViaSatMethod(p);
              }.bind(this)
            }
          }
        }
      },
      series: [{
        name: `Inconformidades Via-Sat`,
        colorByPoint: true,
        data: dta
      }],
      lang:{noData:'Sem dados a exibir!'}
    };

    this.highcharts.createChart(this.chartE2.nativeElement, config);
  }

  graficoAlertasIncormidadesViaM(data) {
    const total = data.length === 0 ? 0 : data.map(o => o.quantidade).reduce((a, c) => a + c);
    this.tituloInconformidadesViaM = `Inconformidades Via-M [${total}]`;
    const config = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: null
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b>'
      },
      accessibility: {
        point: {
          valueSuffix: ''
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        },
        series: {
          turboThreshold: 3000,
          cursor: 'pointer',
          point: {
            events: {
              click: function (e) {
                const p = e.point;
                this.graficoAlertasInconformidadesViaMMethod(p);
              }.bind(this)
            }
          }
        }
      },
      series: [{
        name: `Inconformidades Via-M`,
        colorByPoint: true,
        data: data
      }],
      lang:{noData:'Sem dados a exibir!'}
    };

    this.highcharts.createChart(this.chartE3.nativeElement, config);
  }

  graficoViaM(data) {
    
    const total = data.length === 0 ? 0 : data.map(o => o.total).reduce((a, c) => a + c);

    this.tituloViaM = `Via-M [${total}]`;

    const dta = data.map(c=> {return {
      name: c.nome,
      y: c.listaDeMotoristas.length,
      color: c.cor,
      markers: c.listaDeMotoristas
    }});

    
    const config = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: null
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b>'
      },
      accessibility: {
        point: {
          valueSuffix: ''
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        },
        series: {
          turboThreshold: 3000,
          cursor: 'pointer',
          point: {
            events: {
              click: function (e) {
                const p = e.point;
                this.graficoViaMMethod(p);
              }.bind(this)
            }
          }
        }
      },
      series: [{
        name: `Via-M`,
        colorByPoint: true,
        data: dta
      }],
      lang:{noData:'Sem dados a exibir!'}
    };

    this.highcharts.createChart(this.chartE4.nativeElement, config);
  }

  graficoViaSatMethod(item) {
    this.displayInconformidadeViaSat = false;
    this.displayedColumns = this.displayedColumnsDefault;
    this.desativarReset = false;
    this.montarMapa({ parametro: item.options.parametroFiltro, markers: item.options.markers });
  }

  graficoViaMMethod(item) {
    this.displayInconformidadeViaSat = false;
    this.displayedColumns = this.displayedColumnsViaM;
    this.desativarReset = false;
    this.mapMarkers = [];
    this.mapMarkers = item.options.markers.filter(x => x.longitude && x.latitude);
    const tabela = item.options.markers;
    this.dataSource = new MatTableDataSource(tabela);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  graficoAlertasInconformidadesViaMMethod(item) {
    this.displayInconformidadeViaSat = false;
    this.displayedColumns = this.displayedColumnsDefault;
    this.desativarReset = false;
    this.montarMapa({ parametro: item.options.parametroFiltro, markers: item.options.markers });
  }

  graficoAlertasInconformidadesViaSatMethod(item) {
    this.displayInconformidadeViaSat = true;
    this.displayedColumns = this.displayedColumnsInconfViaSat;
    this.checkSelectAll = false
    this.desativarReset = false;
    this.mapMarkers = [];
    this.mapMarkers = item.options.markers.filter(x => x.longitude && x.latitude);
    const tabela = item.options.markers;
    this.dataSource = new MatTableDataSource(tabela);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  montarMapa(model: any) {
    this.spinner.show();
    try {
      this.dashboardHomeService.montarMapa(model).then(result => {
        this.spinner.hide();
        this.dadosTabela = result;
        this.mapMarkers = result;
        // this.markers = result;
        this.zoomMapa = 3;

        this.displayInconformidadeViaSat = false;
        this.displayedColumns = this.displayedColumnsDefault;

        this.dataSource = new MatTableDataSource(result);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
      this.isFiltering = true;
    } catch (error) {
      this.spinner.hide();
      console.error(error);
    }
  }

  // ==================================================================================================================

  resetarFiltro() {
    this.isFiltering = false;
    this.mapMarkers = this.markers;
    this.dataSource = new MatTableDataSource(this.markers);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  criarDashboard(isRefreshing = false) {
    this.dadosTabela = [];
    this.mapMarkers = [];
    this.markers = [];
    this.desativarReset = !isRefreshing;
    this.buscarLocaisGrid();
    this.gerarGraficoViaM();
    this.gerarGraficoViaSat(isRefreshing);
    this.gerarGraficoInconformidadesViaSat();
    this.gerarGraficoInconformidadesViaM();
  }

  getImageMapMarker(mapMarker) {
    if (mapMarker.tipo === 'MOTORISTA') {
      switch (mapMarker.cor) {
        case '#CCCCCC':
          return './../../assets/img/mapa/celular/pin-cinza.png';
        case '#CCCCC':
          return './../../assets/img/mapa/celular/pin-cinza.png';
        case '#FDB836':
          return './../../assets/img/mapa/celular/pin-amarelo.png';
        case '#0000FF':
          return './../../assets/img/mapa/celular/pin-azul.png';
        case '#FF0000':
          return './../../assets/img/mapa/celular/pin-vermelho.png';
        default:
          return './../../assets/img/mapa/celular/pin-vermelho.png';
      }
    } else if (mapMarker.tipo === 'CAMINHAO') {
      if (mapMarker.estaInativo) {
        return './../../assets/img/mapa/caminhao/pin-cinza.png';
      } else {
        if (mapMarker.ignicao_Ativa) {
          return './../../assets/img/mapa/caminhao/pin-verde.png';
        } else {
          return './../../assets/img/mapa/caminhao/pin-vermelho.png';
        }
      }
      // switch (mapMarker.cor) {
      //     case '#CCCCCC':
      //         return './../../assets/img/mapa/caminhao/pin-cinza.png';
      //     case '#CCCCC':
      //         return './../../assets/img/mapa/caminhao/pin-cinza.png';
      //     case '#FFFF00':
      //         return './../../assets/img/mapa/caminhao/pin-amarelo.png';
      //     case '#0000FF':
      //         return './../../assets/img/mapa/caminhao/pin-azul.png';
      //     case '#FF0000':
      //         return './../../assets/img/mapa/caminhao/pin-vermelho.png';
      //     default:
      //         return './../../assets/img/mapa/caminhao/pin-vermelho.png';
      // }
    } else if (mapMarker.tipo === 'CARRO') {
      switch (mapMarker.cor) {
        case '#CCCCCC':
          return './../../assets/img/mapa/carro/carro-cinza.png';
        case '#CCCCC':
          return './../../assets/img/mapa/carro/carro-cinza.png';
        case '#FFFF00':
          return './../../assets/img/mapa/carro/carro-amarelo.png';
        case '#0000FF':
          return './../../assets/img/mapa/carro/carro-azul.png';
        case '#FF0000':
          return './../../assets/img/mapa/carro/carro-red.png';
        default:
          return './../../assets/img/mapa/carro/carro-red.png';
      }
    } else if (mapMarker.tipo === 'MOTO') {
      switch (mapMarker.cor) {
        case '#CCCCCC':
          return './../../assets/img/mapa/moto/pin-cinza.png';
        case '#CCCCC':
          return './../../assets/img/mapa/moto/pin-cinza.png';
        case '#FFFF00':
          return './../../assets/img/mapa/moto/pin-amarelo.png';
        case '#0000FF':
          return './../../assets/img/mapa/moto/pin-azul.png';
        case '#FF0000':
          return './../../assets/img/mapa/moto/pin-vermelho.png';
        default:
          return './../../assets/img/mapa/moto/pin-vermelho.png';
      }
    } else if (mapMarker.tipo === 'CELULAR') {
      switch (mapMarker.cor) {
        case '#CCCCCC':
          return './../../assets/img/mapa/celular/pin-cinza.png';
        case '#CCCCC':
          return './../../assets/img/mapa/celular/pin-cinza.png';
        case '#FFFF00':
          return './../../assets/img/mapa/celular/pin-amarelo.png';
        case '#0000FF':
          return './../../assets/img/mapa/celular/pin-azul.png';
        case '#FF0000':
          return './../../assets/img/mapa/celular/pin-vermelho.png';
        default:
          return './../../assets/img/mapa/celular/pin-vermelho.png';
      }
    } else if (mapMarker.tipo === 'ONIBUS') {
      switch (mapMarker.cor) {
        case '#CCCCCC':
          return './../../assets/img/mapa/onibus/pin-cinza.png';
        case '#CCCCC':
          return './../../assets/img/mapa/onibus/pin-cinza.png';
        case '#FFFF00':
          return './../../assets/img/mapa/onibus/pin-amarelo.png';
        case '#0000FF':
          return './../../assets/img/mapa/onibus/pin-azul.png';
        case '#FF0000':
          return './../../assets/img/mapa/onibus/pin-vermelho.png';
        default:
          return './../../assets/img/mapa/onibus/pin-vermelho.png';
      }
    } else if (mapMarker.tipo === 'PEPETE') {
      switch (mapMarker.cor) {
        case '#CCCCCC':
          return './../../assets/img/mapa/pepete/pin-cinza.png';
        case '#CCCCC':
          return './../../assets/img/mapa/pepete/pin-cinza.png';
        case '#FFFF00':
          return './../../assets/img/mapa/pepete/pin-amarelo.png';
        case '#0000FF':
          return './../../assets/img/mapa/pepete/pin-azul.png';
        case '#FF0000':
          return './../../assets/img/mapa/pepete/pin-vermelho.png';
        default:
          return './../../assets/img/mapa/pepete/pin-vermelho.png';
      }
    } else if (mapMarker.tipo === 'TRATOR') {
      switch (mapMarker.cor) {
        case '#CCCCCC':
          return './../../assets/img/mapa/trator/pin-cinza.png';
        case '#CCCCC':
          return './../../assets/img/mapa/trator/pin-cinza.png';
        case '#FFFF00':
          return './../../assets/img/mapa/trator/pin-amarelo.png';
        case '#0000FF':
          return './../../assets/img/mapa/trator/pin-azul.png';
        case '#FF0000':
          return './../../assets/img/mapa/trator/pin-vermelho.png';
        default:
          return './../../assets/img/mapa/trator/pin-vermelho.png';
      }
    } else if (mapMarker.tipo === 'PORTATIL') {
      return './../../assets/img/mapa/portatil/pin-portatil.png';
    } else {
      return './../../assets/img/mapa/caminhao/pin-vermelho.png';
    }
  }

  checkUncheckInconformidadesHeader(event) {
    let check = false;

    if(event.target.checked) {
      check = true;
    }

    const checkboxes = document.querySelectorAll('input[id^="checkAcaoItem_"]');

    checkboxes.forEach((checkbox: any) => {
      checkbox.checked = check;
    });
  }

  checkUncheckInconformidadesItem(event) {
    if(!event.target.checked) {
      this.checkSelectAll = false;
    }
  }
}
