import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { NavigationExtras, Router } from '@angular/router';
import { KeyValue } from 'app/components/title-page/title-page.component';
import { AlertaService } from 'app/services/crud/alerta.service';
import { ModalConfirmDeleteComponent } from '../modal-confirm-delete/modal-confirm-delete.component';
import { AuthGuard } from 'app/guards/auth.guard';

@Component({
  selector: 'app-gestao-avisos',
  templateUrl: './gestao-avisos.component.html',
  styleUrls: ['./gestao-avisos.component.css']
})
export class GestaoAvisosComponent implements OnInit {
  avisosList: any;
  tituloPage: string = "Gestão de Avisos";
  keyValueList: KeyValue[] = [
    { key: "Gestão de Avisos", value: "/adm/gestao-avisos" },
  ];

  displayedColumns: string[] = ['id', 'tituloAviso', 'tipoAtualizacao', 'dataRegistro','actions'];
  dataSource = new MatTableDataSource<any>([]);
  totalPages: number[];
  currentPage: number = 0;


  constructor(private cdr: ChangeDetectorRef,private router: Router,private alertaService: AlertaService,private dialog: MatDialog,private authGuard: AuthGuard ) {
    this.dataSource = new MatTableDataSource<any>([]);
  }

  ngOnInit() {
    this.getAllAvisos();
  }


  getAllAvisos(page = 0,size = 15){

    this.alertaService.getAllAvisos(page,size).then((response)=>{
      if (response.error) {
        console.error(response.error);
      } else {
        if(response.response){
          this.avisosList = response.response.content;
          this.dataSource.data = this.avisosList;
          this.currentPage =  response.response.number;
          this.generateTotalPages(response.response.totalPages);
          this.cdr.detectChanges();

        }
      }
    })
  }

  navigateCriarAviso(){
    const navigationExtras: NavigationExtras = {
      queryParams: { 'refresh': this.ngOnInit() }  // Adiciona um parâmetro de consulta com um valor único
    };
    this.router.navigate(['adm/criar-avisos'], navigationExtras);
  }

  montarTextoTipoAtualizacao(tipoAtualizacao): string {
    switch (tipoAtualizacao) {
      case 'CorrecaoBug':
        return 'Correção de Bug';
      case 'AtualizacaoSeguranca':
        return 'Atualização de Segurança';
      case 'MelhoriaUsabilidade':
        return 'Melhoria de Usabilidade';
      case 'AvisoManutencaoProgramada':
        return 'Aviso de Manutenção Programada';
      case 'InformacaoNovoRecurso':
        return 'Informação sobre Novo Recurso';
      case 'InterrupcaoServico':
        return 'Notificação de Interrupção do Serviço';
      case 'FeedbackUsuarioImplementado':
        return 'Feedback do Usuário Implementado';
      default:
        return '';
    }
  }

  deleteAviso(id: number): void {
    console.log(`Deletando aviso com ID ${id}`);
    const dialogRef = this.dialog.open(ModalConfirmDeleteComponent);

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.alertaService.deleteAlertaById(id).then((response)=>{
          if(response.response){
            let loggedUser = this.authGuard.loggedUser();
            this.ngOnInit();
            this.alertaService.getTotalAlertaNovos(loggedUser.id).then(response =>{
              if (response.error) {
                console.error(response.error);
            } else {
                var count = response.response;
                this.alertaService.updateNotificationsCount(count);
            }
            })
          }
        })
      } else {
        console.log('Exclusão cancelada.');
      }
    });

  }

  onPageChange(page): void {
    this.getAllAvisos(page);
  }

  generateTotalPages(sizePages){
    this.totalPages = [];
    for(let i = 0; i < sizePages; i++){
      this.totalPages.push(i);
    }
  }
}
