import { IDEntity } from "./IDEntity";
import { Company } from "./company";
import { WaitingPoint } from "./waiting-point";

export class TaxAndInspectBarrier extends IDEntity {
    tipoBarreira:number;
    empresaId: Company;
    pontosDeEsperaId: WaitingPoint;
    longitude: Number;
    latitude: Number;
}