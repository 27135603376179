import { Component } from "@angular/core";
import { BaseFormComponent } from "../../../base/base-form/base-form.component";
import { ActivatedRoute, Router } from "@angular/router";
import { RESTResponseVO } from "../../../../classes/restresponsevo";
import { Macros } from "../../../../classes/macros";
import { MacrosService } from "../../../../services/crud/macros.service";
import { AuthGuard } from "../../../../guards/auth.guard";
import { RetornoMacros } from "app/classes/retorno-macros";
import { EventoMacros } from "app/classes/evento-macros";

@Component({
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class MacrosFormComponent extends BaseFormComponent<Macros> {

  private listaDeEventosMacros: EventoMacros[] = [];
  private newEventoMacros: EventoMacros;
  public isTipoJornda = false;
  public isTipoEvento = true;
  constructor(private service: MacrosService,
    route: ActivatedRoute,
    private authGuard: AuthGuard,
    private router: Router) { super(route, router, 'adm/macros/list'); }

  assembleObjectFromForm(): void {
  }

  loadAuxiliaryLists(): void {
  }

  loadObject(): void {
    if (this.activeID && (this.activeID !== 0)) {
      this.service.getById(this.activeID).then(response => {
        this.listaDeEventosMacros = [];
        var resultItem: RetornoMacros = response;
        this.activeObject = resultItem.macros;
        this.onChange(this.activeObject.tipo);
        if(resultItem.macros.listaDeEventos){
          for(let i = 0; i < resultItem.macros.listaDeEventos.length; i++){
            this.newEventoMacros = resultItem.macros.listaDeEventos[i];
            this.listaDeEventosMacros.push(this.newEventoMacros);
          }
          this.newEventoMacros = new EventoMacros();
        }
        
      });
    } else {
      this.router.navigate(['adm/macros/list']);
    }
    this.loading = false;
  }


  initObject(): void {
    this.activeObject = new Macros();
    this.loading = false;
    this.newEventoMacros = new EventoMacros();
  }


  doCreate(t: Macros): Promise<RESTResponseVO<Macros>> {
    t.listaDeEventos = this.listaDeEventosMacros;
    return this.service.cadastrarMacros(t);
  }

  doEdit(t: Macros): Promise<RESTResponseVO<Macros>> {
    try {
      t.listaDeEventos = this.listaDeEventosMacros;
      return this.service.editarMacros(t);
    } catch (e) {
      console.error(e);
    }

  }

  doDelete(t: Macros): Promise<RESTResponseVO<string>> {
    return null;
  }


  setActiveID(): void {
    try {
      if (this.activeObject == undefined) {
        this.activeID = 0;
      } else {
        this.activeID = this.activeObject.id;
      }
    } catch (e) {
      console.log(e);
    }

  }


  deleteEventoValue(index) {
    this.listaDeEventosMacros.splice(index, 1);
  }

  addEventoValue(){
    this.listaDeEventosMacros.push(this.newEventoMacros);
    this.newEventoMacros = new EventoMacros();
  }

  onChange(value){
    if(value === "1" || value === 1){
      this.isTipoJornda = false;
      this.isTipoEvento = true;
    }else{
      this.isTipoJornda = true;
      this.isTipoEvento = false;
    }
  }
}

