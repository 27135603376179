import { Component, OnInit, Compiler } from '@angular/core';
import { AmChartsService, AmChart } from "@amcharts/amcharts3-angular";
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { ReportService } from 'app/services/report/report.service';
import { Veiculo } from 'app/classes/veiculo';
import { Filtro } from 'app/classes/filtro';



@Component({
  selector: 'app-grafico-producao-equipamento',
  templateUrl: './grafico-producao-equipamento.component.html',
  styleUrls: ['./grafico-producao-equipamento.component.css']
})
export class GraficoProducaoEquipamentoComponent implements OnInit {
  public showGraphic: boolean;
  private chart: AmChart;
  dropdownList = [];
  public selectedItems = [];
  dropdownSettings: {};
  public filtro: Filtro;
  public pdfSrc: any;
  listaDeVeiculos: Veiculo[] = [];
  veiculo: Veiculo;
  listaInformacaoRelatorio: any[];
  showVeiculos: boolean = false;
  showBotaoPesquisa: boolean = false;
  showGrafico: boolean = false;
  public dataProvider: any[];

  constructor(private veiculoService: VeiculoService,
    private reportService: ReportService,
    private AmCharts: AmChartsService,
    private _compiler: Compiler) {
    this._compiler.clearCache();
  }

  ngOnInit() {
    try {
      this.buildGraphic(null);
      this.filtro = new Filtro();
      this.veiculoService.getLista().then(response => {
        var data = response.listaDeVeiculos;
        this.dropdownList = [];
        for (var i = 0; i < data.length; i++) {
          var veiculo = data[i];
          this.dropdownList.push({ item_id: veiculo.id, item_text: veiculo.placa });

        }
        this.dropdownSettings = {
          singleSelection: false,
          idField: 'item_id',
          textField: 'item_text',
          selectAllText: 'Todos',
          unSelectAllText: 'Desmarcar todos',
          itemsShowLimit: 3,
          allowSearchFilter: true,
          searchPlaceholderText: 'Pesquisar'
        };
        this.showVeiculos = true;
      });

    } catch (error) {
      console.error(error);
    }

  }


  onItemSelect(item: any) {
    this.inserirVeiculoSelecionado(item);
  }
  onSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirVeiculoSelecionado(items[i]);
    }
  }
  onDeSelect(item: any) {
    this.removerVeiculoSelecionado(item);
  }

  doSearch() {
    this.showBotaoPesquisa = true;
    try {
      this.filtro.listaDeVeiculos = this.listaDeVeiculos;
      this.reportService.getGraficoProducaoEquipamento(this.filtro).then(response => {
        this.listaInformacaoRelatorio = response;
        this.dataProvider = new Array();
        this.listaInformacaoRelatorio.forEach(v => {
          if (v.campo02) {
            var des = v.campo02;
            if (des.includes(",")) {
              des = des.replace(",", ".");
            }
            var oci = v.campo03;
            if (oci.includes(",")) {
              oci = oci.replace(",", ".");
            }

            var tra = v.campo04;
            if (tra.includes(",")) {
              tra = tra.replace(",", ".");
            }

            this.dataProvider.push({
              placa: v.campo01,
              desligado: Number(des),
              ocioso: Number(oci),
              trabalhou: Number(tra)
            });
          }

        });
        this.buildGraphic(this.dataProvider);
        this.showBotaoPesquisa = false;
        this.showGrafico = true;
      });
    } catch (error) {
      console.error(error);
    }
  }


  inserirVeiculoSelecionado(item: any): void {
    try {
      var veiculo = this.listaDeVeiculos.find(v => v.id == item.item_id);
      if (!veiculo) {
        this.veiculo = new Veiculo();
        this.veiculo.id = item.item_id;
        this.veiculo.placa = item.item_text;
        this.listaDeVeiculos.push(this.veiculo);
      }
    } catch (error) {
      console.log(error);
    }
  }

  removerVeiculoSelecionado(item: any): void {
    try {
      let index = this.listaDeVeiculos.findIndex(v => v.id == item.item_id);
      this.listaDeVeiculos.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }

  /**
    * METHOD 
    * Recebe os dados e renderiza o gráfico.
    */
  buildGraphic(dataProvider: any[]) {
    try {


      this.chart = this.AmCharts.makeChart("chartdiv", {
        type: "serial",
        theme: "none",
        legend: {
          horizontalGap: 10,
          maxColumns: 1,
          position: "right",
          useGraphSettings: true,
          markerSize: 10
        },
        dataProvider: dataProvider,
        valueAxes: [{
          stackType: "regular",
          axisAlpha: 0.3,
          gridAlpha: 0
        }],
        graphs: [{
          balloonText: "<b>[[title]]</b><br><span style='font-size:14px'>[[category]]: <b>[[value]]</b></span>",
          fillAlphas: 0.8,
          labelText: "[[value]]",
          lineAlpha: 0.3,
          title: "Desligado",
          type: "column",
          color: "#000000",
          valueField: "desligado"
        }, {
          balloonText: "<b>[[title]]</b><br><span style='font-size:14px'>[[category]]: <b>[[value]]</b></span>",
          fillAlphas: 0.8,
          labelText: "[[value]]",
          lineAlpha: 0.3,
          title: "Ocioso",
          type: "column",
          color: "#000000",
          valueField: "ocioso"
        }, {
          balloonText: "<b>[[title]]</b><br><span style='font-size:14px'>[[category]]: <b>[[value]]</b></span>",
          fillAlphas: 0.8,
          labelText: "[[value]]",
          lineAlpha: 0.3,
          title: "Trabalhou",
          type: "column",
          color: "#000000",
          valueField: "trabalhou"
        }],
        categoryField: "placa",
        categoryAxis: {
          gridPosition: "start",
          axisAlpha: 0,
          gridAlpha: 0,
          position: "left"
        },
        export: {
          enabled: true,
          menu: [{
            class: "export-main",
            menu: ["PNG", "JPG", "CSV"]
          }]
        }

      });

      this.chart.write("chartdiv");
    } catch (error) {
      console.error(error);
    }
  }

}
