import { IDEntity } from "./IDEntity";
import { Veiculo } from "./veiculo";
import { EquipamentoPortatil } from "./equipamento-portatil";
import { Motorista } from "./motorista";
import { Local } from "./local";
import { Branch } from "./branch";
;

export class Filtro extends IDEntity {
    dataInicial: Date;
    dataInicialString: String;
    dataFinal: Date;
    dataFinalString: String;
    velocidade: number;
    ignicaoAtiva:boolean;
    buscarPelaVelocidadeMaximaDoVeiculo:boolean;
    listaDeVeiculos: Veiculo[];
    listaDeLocais: Local[];
    listaDeUnidades: Branch[];
    veiculo:Veiculo;
    motorista:Motorista;
    listaDeMotoristas: Motorista[];
    equipamentoPortatil: EquipamentoPortatil;
    tipoArquivo:String;
    nomeColuna:String;
    tipoDeCerca:number;
    horasDiariaManutencao:number;
    idCliente:number;
    idUnidade:number;
    paradaMaxima:number;
    tipoSensor:number;
    velocidadeMinima:number;
    /**
     *  1 = entrada de cerca
     *  2 = saida de cerca
     *  3 = sensor porta motorista
     *  4 = sensor porta bau
     */
    tipoEventoSensor:number;
    tipoEventoMotorista:number;
    tempoParado:number;
    tiposInconformidades:String[];
    localOrigem:Local;
    localDestino:Local;
    idEmpresa: number;
    situacao: number;

}