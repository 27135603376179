import { Http } from '@angular/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DashboardHomeService {

    public defaultUrl: string;
    public defaultUrlAspCore: string;
    private headers = new Headers({ 'Content-Type': 'application/json' });

    constructor(public http: Http) {
        this.defaultUrl = environment.baseURL + 'App/v2/web';
        this.defaultUrlAspCore = environment.baseAspCoreURL + '/Dashboard';
    }

    // tslint:disable-next-line:max-line-length
    // ================================================================= graficos =================================================================

    inconformidadesVeiculo(model: any) {
        const url = `${this.defaultUrlAspCore}/inconformidades-veiculo`;
        return this.http
            .post(url, model)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    // obterIButtonTransmissao(idVeiculo: number) {
    //   const url = `${environment.baseAspCoreURL}/transmissao?idVeiculo=${idVeiculo}&ignicaoAtiva=true&inicio=2022-01-01&fim=2023-01-01`;
    //   return this.http
    //     .get(url)
    //     .toPromise()
    //     .then(response => {
    //       if (response) {
    //         return response.json();
    //       } else {
    //         return [];
    //       }
    //     })
    // }

    // buscarMotoristaPorIdIButton(idIButton: number) {
    //   const url = `${environment.baseAspCoreURL}/motorista/${idIButton}`;
    //   return this.http
    //     .get(url)
    //     .toPromise()
    //     .then(response => {
    //       if (response) {
    //         return response.json();
    //       } else {
    //         return [];
    //       }
    //     })
    //     .catch(this.handleError);
    // }

    alertasVeiculo(model: any) {
        const url = `${this.defaultUrlAspCore}/alertas-veiculo`;
        return this.http
            .post(url, model)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    updateRotasVeiculos(idVeiculo: number, rotas: any) {
        const url = `${this.defaultUrlAspCore}/update-rotas/${idVeiculo}`;
        return this.http
            .post(url, rotas)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    addRotaVeiculo(idVeiculo: number, idRota: number) {
        const url = `${this.defaultUrlAspCore}/add-rota/${idVeiculo}/${idRota}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    removeRotaVeiculo(idVeiculo: number, idRota: number) {
        const url = `${this.defaultUrlAspCore}/remove-rota/${idVeiculo}/${idRota}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    excluirRotasVeiculos(veiculos: any) {
        const url = `${this.defaultUrlAspCore}/excluir-rotas`;
        return this.http
            .post(url, veiculos)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    graficoViaSatPromise(idEmpresa: number, idUnidade: number, minutos: number): Promise<any> {
        const url = `${this.defaultUrlAspCore}/via-sat/${idEmpresa}/${idUnidade}`;
        return new Promise((resolve, reject) => {
            this.http
                .get(url)
                .toPromise()
                .then(response => {
                    if (response) {
                        return response.json();

                    } else {
                        return [];
                    }
                    resolve(response);
                })
                .catch(this.handleError);
        });
    }

    graficoViaSat(idEmpresa: number, idUnidade: number, minutos: number) {
        const url = `${this.defaultUrlAspCore}/via-sat/${idEmpresa}/${idUnidade}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    graficoViaM(idEmpresa: number, idUnidade: number) {
        const url = `${this.defaultUrlAspCore}/via-m/${idEmpresa}/${idUnidade}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    graficoViaMNovo(idEmpresa: number, idUnidade: number){
        
        const url = `${this.defaultUrlAspCore}/eventoAtual/${idEmpresa}/${idUnidade}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    graficoInconformidadesViaSat(idEmpresa: number, idUnidade: number) {
        const url = `${this.defaultUrlAspCore}/inconformidades-via-sat/${idEmpresa}/${idUnidade}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    cercasFiltroMapa(idEmpresa: number) {
        const url = `${this.defaultUrlAspCore}/locais-filtros/${idEmpresa}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    graficoInconformidadesViaM(idEmpresa: number, idUnidade: number) {
        const url = `${this.defaultUrlAspCore}/inconformidades-via-m/${idEmpresa}/${idUnidade}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    montarMapa(model: any) {
        const url = `${this.defaultUrlAspCore}/filtro-dados-mapa`;
        return this.http
            .post(url, model)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    coordenadas(model: any) {
        const url = `${this.defaultUrlAspCore}/coordenadas`;
        return this.http
            .post(url, model)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    montarTelaMapa(model: any) {
        const url = `${this.defaultUrlAspCore}/dados-mapa`;
        return this.http
            .post(url, model)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    // tslint:disable-next-line:max-line-length
    // ================================================================= Metodos do GRID =================================================================

    montarCercasMapa(idEmpresa: number) {
        const url = `${this.defaultUrlAspCore}/locais/${idEmpresa}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    buscarRotasGrid(idEmpresa: number, idUnidade: number) {
        const url = `${this.defaultUrlAspCore}/rotas/${idEmpresa}/${idUnidade}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    excluirRotaVeiculo(idRotaVeiculo: number, idDoVeiculo: number) {
        const url = `${this.defaultUrl}/dashboard/excluirRotaVeiculo/${idRotaVeiculo}/${idDoVeiculo}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }


    alterarParamentroAtualizacaoDashboard(idUsuario: any, status: boolean) {
        const url = `${this.defaultUrl}/users/atualizacaodashboard/${idUsuario}/${status}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    salvarRotaVeiculo(model: any) {
        const url = `${this.defaultUrl}/dashboard/salvarRotaVeiculo`;
        return this.http
            .post(url, model)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    salvarConfiguracoesInconformidades(idUnidade: number, model: any) {
        const url = `${this.defaultUrlAspCore}/update-configuracoes-inconformidades/${idUnidade}`;
        return this.http
            .post(url, model)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    obterConfiguracoesInconformidades(idUnidade: number) {
        const url = `${this.defaultUrlAspCore}/obter-configuracoes-inconformidades/${idUnidade}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    buscarDadosGrid(model: any) {
        const url = `${this.defaultUrl}/dashboard/buscarDadosGrid`;
        return this.http
            .post(url, model)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }


    // tslint:disable-next-line:max-line-length
    // ================================================================= Modal Veiculo =================================================================

    executarComando(comando: number, idVeiculoSelecionado: number) {
        const url = `${this.defaultUrl}/dashboard/comando/${comando}/${idVeiculoSelecionado}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }


    buscarTrajettoPorPeriodo(idVeiculoSelecionado: number, dateInicial: any, dateFinal: any) {
        const url = `${this.defaultUrl}/dashboard/buscarTrajettoPorPeriodo/${idVeiculoSelecionado}/${dateInicial}/${dateFinal}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    cadastrarRotaVeiculo(model: any) {
        const url = `${this.defaultUrl}/dashboard/salvarRotaVeiculo`;
        return this.http
            .post(url, model)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    salvarObservacao(model: any) {
        const url = `${this.defaultUrl}/observacao/cadastrar`;
        return this.http
            .post(url, model)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    // tslint:disable-next-line:max-line-length
    // ================================================================= Modal Veiculo =================================================================



    marcarComoLido(idInconformidade: number, idUsuario: number) {
        const url = `${this.defaultUrl}/inconformidade/marcarComoLido/${idInconformidade}/${idUsuario}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    marcarImagemComoVista(idImagem: number) {
        const url = `${this.defaultUrl}/dashboard/editarFotoParaVista/${idImagem}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    buscarTrajettoUltimas24horas(idVeiculoSelecionado: number) {
        const url = `${this.defaultUrl}/dashboard/buscarTrajettoUltimas24horas/${idVeiculoSelecionado}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    carregarFotos(idMotorista: number) {
        const url = `${this.defaultUrl}/dashboard/buscarFotosNaoVistas/${idMotorista}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    getDadosDashboard(idEmpresa: number, idUnidade: number) {
        const url = `${this.defaultUrl}/monitoramento/buscarListaDeVeiculoPorEmpresa/${idEmpresa}/${idUnidade}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    getDadosMonitoramento(idEmpresa: number, idUnidade: number) {
        const url = `${this.defaultUrl}/monitoramento/buscarListaDeVeiculoPorEmpresa/${idEmpresa}/${idUnidade}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    getDadosDetalhesVeiculo(id: number) {
        const url = `${this.defaultUrl}/dashboard/buscarDadosDoVeiculo/${id}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    enviarComandoBloqueio(model: any) {
        const url = `${this.defaultUrl}/dashboard/enviarcomando`;
        return this.http
            .post(url, model)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    getDadosDetalhesEquipamento(id: number) {
        const url = `${this.defaultUrl}/dashboard/buscarDadosDoEquipamentoPortatil/${id}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    getDadosDetalhesMotoristas(id: number) {
        const url = `${this.defaultUrl}/dashboard/buscarDadosDoViam/${id}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    getDadosMotoristas(idEmpresa: number, idUnidade: number) {
        const url = `${this.defaultUrl}/eventos/eventoAtual/${idEmpresa}/${idUnidade}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    getDadosInconformidades(idEmpresa: number, idUnidade: number) {
        const url = `${this.defaultUrl}/inconformidade/buscarInconformidadesDoDia/${idEmpresa}/${idUnidade}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                if (response) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('Ocorreu um erro: ', error);
        return Promise.reject(error.message || error);
    }
}
