import { Injectable } from '@angular/core';
import { CRUDService } from '../base/crud.service';
import { Http } from '@angular/http';
import { environment } from '../../../environments/environment';
import { AuthGuard } from '../../guards/auth.guard';
import { User } from '../../classes/user';
import { TurnoEscala } from 'app/classes/turno-escala';
import { RetornoTurnoEscala } from 'app/classes/retorno-turno-escala';
import { TurnoEscalaMotorista } from 'app/classes/turno-escala-motorista';
import { RESTResponseVO } from 'app/classes/restresponsevo';
import { RetornoTurnoEscalaMotorista } from 'app/classes/retorno-turno-escala-motorista';

@Injectable()
export class TurnoEscalaService extends CRUDService<TurnoEscala> {
  public company: number;
  public loggedUser: User;
  public authGuardM: AuthGuard;

  constructor(http: Http,
    private authGuard: AuthGuard) {
    super(http, environment.baseURL + 'App/v2/web/turnoescala', true);

    this.authGuardM = authGuard;
    this.loggedUser = authGuard.loggedUser();
    this.company = this.loggedUser.selectedCompany.id;

    this.listURL = environment.baseURL + 'App/v2/web/turnoescala/lista/' + this.company
  }

  getBuscarPorParametros() {
    this.loggedUser = this.authGuardM.loggedUser();
    this.company = this.loggedUser.selectedCompany.id;
    var branch: string = this.loggedUser.selectedBranch.id === 0 && '0' || this.loggedUser.selectedBranch.id.toString();
    let url: string = environment.baseURL + 'App/v2/web/turnoescala/buscarPorParametros/' + this.company + '/' + branch;
    return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoTurnoEscala;
        }
      })
      .catch(this.handleError);
  }

  salvarTurnoEscalaMotorista(turnoEscalaMotorista: TurnoEscalaMotorista): Promise<RESTResponseVO<TurnoEscalaMotorista>> {
    try {
      let url: string = environment.baseURL + 'App/v2/web/turnoescalamotorista/salvar/'
      return this.http
        .post(url, JSON.stringify(turnoEscalaMotorista), { headers: this.headers })
        .toPromise()
        .then(response => {
          if (this.restResponseWrapper) {
            return TurnoEscalaMotorista;
          }
        })
        .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }


  buscarTurnoEscalaMotorista(idMotorista: number){
    try {
      let url: string = environment.baseURL + 'App/v2/web/turnoescalamotorista/buscarListaTurnoEscalaMotorista/'+idMotorista;
      return this.http
      .get(url)
      .toPromise()
      .then(response => {
        if (this.restResponseWrapper) {
          return response.json() as RetornoTurnoEscalaMotorista;
        }
      })
      .catch(this.handleError);
    } catch (e) {
      console.error(e);
    }
  }
}