import { BaseListComponent } from "../../../base/base-list/base-list.component";
import { Component, ViewEncapsulation } from "@angular/core";
import { DriverService } from "../../../../services/crud/driver.service";
import { UtilService } from "../../../../services/utils.service";
import { PDFService } from "../../../../services/pdf.service";
import { BranchService } from "../../../../services/crud/branch.service";
import { ShiftService } from "../../../../services/crud/shift.service";
import { AuthGuard } from "../../../../guards/auth.guard";

@Component({
    templateUrl: '../../../base/base-list/base-list.component.html',
    styleUrls: [ '../../../base/base-list/base-list.component.css' ],
    encapsulation: ViewEncapsulation.None
})
export class DriverListComponent extends BaseListComponent {
    constructor(private service: DriverService,
        private utilService: UtilService,
        private branchService: BranchService,
        private shiftService: ShiftService,
        pdfService: PDFService,
        authGuard: AuthGuard) {
        super(utilService, pdfService, authGuard);
        this.searchField = "nome";
    }

    ngOnInit() {
        this.loading = true;

        this.pageTitle = "Cadastros | Motorista";
        this.formPath = "/adm/driver/form";

        let i: number = 0;
        this.tableHeaders = [
            [
                this.createHeaderMultipleLine(["Matricula"], 0, true),
                this.createHeaderMultipleLine(["Nome"], 1, true),
                this.createHeaderMultipleLine(["Sobrenome"], 2, true),
                this.createHeaderMultipleLine(["CPF"], 3, true),
                this.createHeaderMultipleLine(["Número", "CNH"], 4, true),
                this.createHeaderMultipleLine(["Categoria", "CNH"], 5, true),
                this.createHeaderMultipleLine(["Validade", "CNH"], 6, true),
                this.createHeaderMultipleLine(["Telefone"], 7, true),
                this.createHeaderMultipleLine(["Unidade"], 8, true),
                this.createHeaderMultipleLine(["Turno"], 9, true),
                this.createHeaderMultipleLine(["Escala"], 10, true),
                this.createHeaderMultipleLine(["Ativo"], 1, true)
            ]
        ];

        this.dataFields = [
            this.createField("numeroMatricula", "string"),
            this.createField("nome", "string"),
            this.createField("sobrenome", "string"),
            this.createField("cpf", "string"),
            this.createField("numeroCnh", "string"),
            this.createField("categoriaCnh", "string"),
            this.createField("validadeCnh", "date"),
            this.createField("telefone", "string"),
            this.createField("unidade.apelido", "string"),
            this.createField("turno.identificacao", "string"),
            this.createField("nomeEscala", "string"),
            this.createField("isAtivo", "boolean")
        ];

        this.branchService.getList().then(branchResponse => {
            let branches = branchResponse.response;
            this.shiftService.getList().then(shiftResponse => {
                let shifts = shiftResponse.response;
                this.service.getList().then(response => {
                    this.data = response.response.map(m => {
                        if (m.unidadesId) m.unidade = branches.filter(b => b.id === m.unidadesId)[0];
                        if (m.turnosId) m.turno = shifts.filter(t => t.id === m.turnosId)[0];
                        return m;
                    });
                    this.loading = false;
                });
            });

        })


    }
}
