import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { Filtro } from 'app/classes/filtro';
import { ReportService } from 'app/services/report/report.service';
import { Veiculo } from 'app/classes/veiculo';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { NumeroDeViagemRelatorio } from 'app/classes/numero-de-viagem-relatorio';

@Component({
  selector: 'app-estimativa-producao',
  templateUrl: './estimativa-producao.component.html',
  styleUrls: ['./estimativa-producao.component.css']
})
export class EstimativaProducaoComponent implements OnInit {

  @ViewChild('iframe') iframe: ElementRef;
  @Input() peso:string;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: {};
  public filtro: Filtro;
  public mostrarPdf: boolean = false;
  public pdfSrc: any;
  listaDeVeiculos: Veiculo[] = [];
  veiculo: Veiculo;
  responseBase64: String;
  funcao: number = 2;
  showGrafico: boolean = false;
  totalDeViagens: number = 0;
  pesoMedio:number = 45;
  totalProducao:number = 0;
 
  constructor(private veiculoService: VeiculoService, private reportService: ReportService) { }

  ngOnInit() {
    try {
      this.filtro = new Filtro();
      this.veiculoService.getLista().then(response => {
        var data = response.listaDeVeiculos;
        this.dropdownList = [];
        for (var i = 0; i < data.length; i++) {
          var veiculo = data[i];
          this.dropdownList.push({ item_id: veiculo.id, item_text: veiculo.placa });
        }


        this.dropdownSettings = {
          singleSelection: false,
          idField: 'item_id',
          textField: 'item_text',
          selectAllText: 'Todos',
          unSelectAllText: 'Desmarcar todos',
          itemsShowLimit: 3,
          limitSelection: 10,
          allowSearchFilter: true,
          searchPlaceholderText: 'Pesquisar'
        };
      });

    } catch (error) {
      console.error(error);
    }
  }


  onItemSelect(item: any) {
    this.inserirVeiculoSelecionado(item);
  }
  onSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.inserirVeiculoSelecionado(items[i]);
    }
  }
  onDeSelect(item: any) {
    this.removerVeiculoSelecionado(item);
  }

  inserirVeiculoSelecionado(item: any): void {
    try {
      var veiculo = this.listaDeVeiculos.find(v => v.id == item.item_id);
      if (!veiculo) {
        this.veiculo = new Veiculo();
        this.veiculo.id = item.item_id;
        this.veiculo.placa = item.item_text;
        this.listaDeVeiculos.push(this.veiculo);
      }
    } catch (error) {
      console.log(error);
    }
  }

  removerVeiculoSelecionado(item: any): void {
    try {
      let index = this.listaDeVeiculos.findIndex(v => v.id == item.item_id);
      this.listaDeVeiculos.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }

  doSearch() {
    try {
      console.log(this.peso);
      this.filtro.listaDeVeiculos = this.listaDeVeiculos;
      this.filtro.tipoDeCerca = this.funcao;
      this.reportService.getNumeroDeViagem(this.filtro).then(response => {
        this.montarGrafico(response);
      });
      
      //this.montarGrafico(null);
    } catch (error) {
      console.error(error);
    }
  }

  montarGrafico(_numeroDeViagemRelatorio: NumeroDeViagemRelatorio) {
    try {
      am4core.useTheme(am4themes_animated);
      let chart = am4core.create("chartdivnumeroviagem", am4charts.XYChart);
      //

      // Increase contrast by taking evey second color
      chart.colors.step = 2;



      // Create axes
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.minGridDistance = 50;


      this.ajustarCampos(chart, _numeroDeViagemRelatorio);


      let toleneladasAxis = chart.yAxes.push(new am4charts.ValueAxis());
      toleneladasAxis.title.text = "Toneladas";


      let distanceSeries = chart.series.push(new am4charts.ColumnSeries());
      distanceSeries.dataFields.valueY = "totalKilos";
      distanceSeries.dataFields.dateX = "date";
      distanceSeries.yAxis = toleneladasAxis;
      distanceSeries.tooltipText = "{valueY} ton";
      distanceSeries.name = "Total Carregado";
      distanceSeries.columns.template.fillOpacity = 0.7;
      distanceSeries.columns.template.propertyFields.strokeDasharray = "dashLength";
      distanceSeries.columns.template.propertyFields.fillOpacity = "alpha";
      distanceSeries.showOnInit = true;

      // Add legend
      chart.legend = new am4charts.Legend();

      // Add cursor
      chart.cursor = new am4charts.XYCursor();


      this.showGrafico = true;

    } catch (error) {
      console.error(error);
    }
  }


  ajustarCampos(chart, _numeroDeViagemRelatorio) {
    try {

      this.totalDeViagens = _numeroDeViagemRelatorio.totalDeViagens;
      this.totalProducao = this.pesoMedio * this.totalDeViagens;
      let contOpcao: number = 1;
      for (var i = 0; i < _numeroDeViagemRelatorio.listaDePlacas.length; i++) {
        var vec = "vec" + (i + 1);
        switch (contOpcao) {
          case 1: {
            this.createAxisAndSeries(vec, _numeroDeViagemRelatorio.listaDePlacas[i], "circle", chart);
          } break;
          case 2: {
            this.createAxisAndSeries(vec, _numeroDeViagemRelatorio.listaDePlacas[i], "triangle", chart);
          } break;
          case 3: {
            this.createAxisAndSeries(vec, _numeroDeViagemRelatorio.listaDePlacas[i], "rectangle", chart);
          } break;
        }
        contOpcao++;

        if (contOpcao > 3) {
          contOpcao = 1;
        }

      }
      chart.data = this.generateChartData(_numeroDeViagemRelatorio, _numeroDeViagemRelatorio.listaDePlacas.length);
    } catch (error) {
      console.error(error);
    }
  }


  // Create series
  createAxisAndSeries(field, name, opcao, chart) {
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (chart.yAxes.indexOf(valueAxis) != 0) {
      valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
    }

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = field;
    series.dataFields.dateX = "date";
    series.strokeWidth = 2;
    series.yAxis = valueAxis;
    series.name = name;
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.tensionX = 0.8;
    series.showOnInit = true;

    let interfaceColors = new am4core.InterfaceColorSet();

    switch (opcao) {
      case "triangle":
        
        let bullet = series.bullets.push(new am4charts.Bullet());
        bullet.width = 12;
        bullet.height = 12;
        bullet.horizontalCenter = "middle";
        bullet.verticalCenter = "middle";
        
        let triangle = bullet.createChild(am4core.Triangle);
        triangle.stroke = interfaceColors.getFor("background");
        triangle.strokeWidth = 2;
        triangle.direction = "top";
        triangle.width = 12;
        triangle.height = 12;
        break;
      case "rectangle":
        bullet = series.bullets.push(new am4charts.Bullet());
        bullet.width = 10;
        bullet.height = 10;
        bullet.horizontalCenter = "middle";
        bullet.verticalCenter = "middle";

        let rectangle = bullet.createChild(am4core.Rectangle);
        rectangle.stroke = interfaceColors.getFor("background");
        rectangle.strokeWidth = 2;
        rectangle.width = 10;
        rectangle.height = 10;
        break;
      default:
        bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.stroke = interfaceColors.getFor("background");
        bullet.circle.strokeWidth = 2;
        break;
    }

    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.strokeWidth = 2;
    valueAxis.renderer.line.stroke = series.stroke;
    valueAxis.renderer.labels.template.fill = series.stroke;
    valueAxis.renderer.opposite = true;
  }


  generateChartData(_numeroDeViagemRelatorio, quantideDeVeiculos) {
    let chartData = [];
    let totalKilos = 230;

    _numeroDeViagemRelatorio.listaDeDadosNumeroDeViagem.forEach(element => {
      let dados = element;
      let newDate = new Date(dados.data);
      switch (quantideDeVeiculos) {
        case 1: {
          totalKilos = (dados.vec1) * this.pesoMedio;
          chartData.push({
            date: newDate,
            vec1: dados.vec1,
            totalKilos: totalKilos
          });
        } break;
        case 2: {
          totalKilos = (dados.vec1 + dados.vec2) * this.pesoMedio;
          chartData.push({
            date: newDate,
            vec1: dados.vec1,
            vec2: dados.vec2,
            totalKilos: totalKilos
          });
        } break;
        case 3: {
          totalKilos = (dados.vec1 + dados.vec2 + dados.vec3) * this.pesoMedio;
          chartData.push({
            date: newDate,
            vec1: dados.vec1,
            vec2: dados.vec2,
            vec3: dados.vec3,
            totalKilos: totalKilos
          });
        } break;
        case 4: {
          totalKilos = (dados.vec1 + dados.vec2 + dados.vec3 + dados.vec4) * this.pesoMedio;
          chartData.push({
            date: newDate,
            vec1: dados.vec1,
            vec2: dados.vec2,
            vec3: dados.vec3,
            vec4: dados.vec4,
            totalKilos: totalKilos
          });
        } break;
        case 5: {
          totalKilos = (dados.vec1 + dados.vec2 + dados.vec3 + dados.vec4 + dados.vec5) * this.pesoMedio;
          chartData.push({
            date: newDate,
            vec1: dados.vec1,
            vec2: dados.vec2,
            vec3: dados.vec3,
            vec4: dados.vec4,
            vec5: dados.vec5,
            totalKilos: totalKilos
          });
        } break;
        case 6: {
          totalKilos = (dados.vec1 + dados.vec2 + dados.vec3 + dados.vec4 + dados.vec5 + dados.vec6) * this.pesoMedio;
          chartData.push({
            date: newDate,
            vec1: dados.vec1,
            vec2: dados.vec2,
            vec3: dados.vec3,
            vec4: dados.vec4,
            vec5: dados.vec5,
            vec6: dados.vec6,
            totalKilos: totalKilos
          });
        } break;
        case 7: {
          totalKilos = (dados.vec1 + dados.vec2 + dados.vec3 + dados.vec4 + dados.vec5 + dados.vec6 + dados.vec7) * this.pesoMedio;
          chartData.push({
            date: newDate,
            vec1: dados.vec1,
            vec2: dados.vec2,
            vec3: dados.vec3,
            vec4: dados.vec4,
            vec5: dados.vec5,
            vec6: dados.vec6,
            vec7: dados.vec7,
            totalKilos: totalKilos
          });
        } break;
        case 8: {
          totalKilos = (dados.vec1 + dados.vec2 + dados.vec3 + dados.vec4 + dados.vec5 + dados.vec6 + dados.vec7 + dados.vec8) * this.pesoMedio;
          chartData.push({
            date: newDate,
            vec1: dados.vec1,
            vec2: dados.vec2,
            vec3: dados.vec3,
            vec4: dados.vec4,
            vec5: dados.vec5,
            vec6: dados.vec6,
            vec7: dados.vec7,
            vec8: dados.vec8,
            totalKilos: totalKilos
          });
        } break;
        case 9: {
          totalKilos = (dados.vec1 + dados.vec2 + dados.vec3 + dados.vec4 + dados.vec5 + dados.vec6 + dados.vec7 + dados.vec8 + dados.vec9) * this.pesoMedio;
          chartData.push({
            date: newDate,
            vec1: dados.vec1,
            vec2: dados.vec2,
            vec3: dados.vec3,
            vec4: dados.vec4,
            vec5: dados.vec5,
            vec6: dados.vec6,
            vec7: dados.vec7,
            vec8: dados.vec8,
            vec9: dados.vec9,
            totalKilos: totalKilos
          });
        } break;
        case 10: {
          totalKilos = (dados.vec1 + dados.vec2 + dados.vec3 + dados.vec4 + dados.vec5 + dados.vec6 + dados.vec7 + dados.vec8 + dados.vec9 + dados.vec10) * this.pesoMedio;
          chartData.push({
            date: newDate,
            vec1: dados.vec1,
            vec2: dados.vec2,
            vec3: dados.vec3,
            vec4: dados.vec4,
            vec5: dados.vec5,
            vec6: dados.vec6,
            vec7: dados.vec7,
            vec8: dados.vec8,
            vec9: dados.vec9,
            vec10: dados.vec10,
            totalKilos: totalKilos
          });
        } break;
      }
    });
    /*
        return [
          { date: new Date(2018, 0, 1, 14, 0, 0, 0), dev1: 450, dev2: 362, dev3: 699 },
          { date: new Date(2018, 0, 1, 15, 0, 0, 0), dev1: 269, dev2: 450, dev3: 841 },
          { date: new Date(2018, 0, 1, 16, 0, 0, 0), dev1: 700, dev2: 358, dev3: 698 },
          { date: new Date(2018, 0, 2, 14, 0, 0, 0), dev1: 710, dev2: 458, dev3: 608 }
        ];
        */
    return chartData;
  }

}
