import { IDEntity } from './IDEntity';
export class Viagem extends IDEntity{
    placa:String;
    dataCadastro:Date;
    meta:number;
    faturamento:number;
    projecao:number;
    mediaFaturamento:number;
    dispersao:number;
    quantidadeManifesto:number;
    inicioDaViagem:Date;
    fimDaViagem:Date;
    isNovo:boolean = false;
}
