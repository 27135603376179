import { IDEntity } from "./IDEntity";
import { JourneyState } from "./journey-state";
import { Company } from "./company";

export class JourneyEvent extends IDEntity {
    descricao: string;
    color: string;
    empresaId: Company;
    objGeraEstado: JourneyState;
    geraEstado: Number;
    icone: string;
}