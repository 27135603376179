import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { environment } from "../../../environments/environment";
import {of} from 'rxjs'
import { PainelIndicadores } from 'app/classes/painel-indicadores';
import { PainelIndicadoresTimeline } from 'app/classes/painel-indicadores-timeline';
import { Retorno } from 'app/classes/retorno';


@Injectable({
  providedIn: 'root'
})
export class PainelIndicadoresService {

    defaultUrl: string;
    headers = new Headers({ 'Content-Type': 'application/json' });
    
    constructor(private http: Http) {
        this.defaultUrl = environment.baseUrlNovaDashboard + 'app/v1/dashboard';
    }

    getPainel(codigoEmpresa: number, codigoUnidade: number, turno: number) {
      try {

        return this.http.get(`${this.defaultUrl}/consulta/indicadores/${codigoEmpresa}/${codigoUnidade}/${turno}`)
        
        .toPromise()
        .then(response => {
          if (response) {
            return response.json() as PainelIndicadores;
        } else {
            return {};
        }
        }).catch(this.handleError);
      } catch {

      }
    }

    getTimeline(idJornada: number) {
      try {
        return this.http.get(`${this.defaultUrl}/consulta/timeline/${idJornada}`)
        .toPromise()
        .then(response => {
          if (response) {
            return response.json() as PainelIndicadoresTimeline;
        } else {
            return {};
        }
        }).catch(this.handleError);
      } catch {

      }
    }

    enviarNotificacao(token: string, mensagem: string) {
      try {
        var notificacao = {
          "token": token,
          "mensagem": mensagem
        }

        return this.http.post(`${this.defaultUrl}/chat/enviarNotificacao`, notificacao)
        .toPromise()
        .then(response => {
          if (response) {
            return response.json() as Retorno;
        } else {
            return {};
        }
        }).catch(this.handleError);
      } catch {

      }
    }

    private handleError(error: any): Promise<any> {
      console.error('Ocorreu um erro: ', error);
      return Promise.reject(error.message || error);
  }

}
