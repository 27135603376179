import { BaseListComponent } from "../../../base/base-list/base-list.component";
import { Component, ViewEncapsulation } from "@angular/core";
import { MacrosService } from "../../../../services/crud/macros.service";
import { UtilService } from "../../../../services/utils.service";
import { PDFService } from "../../../../services/pdf.service";
import { AuthGuard } from "../../../../guards/auth.guard";
@Component({
  templateUrl: '../../../base/base-list/base-list.component.html',
  styleUrls: [ '../../../base/base-list/base-list.component.css' ],
  encapsulation: ViewEncapsulation.None
})

export class MacrosListComponent extends BaseListComponent {

  constructor(private macrosService: MacrosService,
      private utilService: UtilService,
      pdfService: PDFService,
      authGuard: AuthGuard) {
      super(utilService, pdfService, authGuard);
      this.searchField = "nome";
  }

 

 ngOnInit() {
    this.pageTitle = "Cadastros | Macros";
      this.formPath = "/adm/macros/form";

      this.tableHeaders = [
          [
              this.createHeader("ID", 0, true),
              this.createHeader("Nome", 1, true),
              this.createHeader("Ativo", 2, true)
          ]
      ];

      this.dataFields = [
          this.createField("id", "string"),
          this.createField("nome", "string"),
          this.createField("ativo", "boolean")
      ];

      this.macrosService.getLista().then(response => {
          this.data = response.listaDeMacros;
          this.loading = false;
      });
  }
}
