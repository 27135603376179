import { Time } from "@angular/common";

export class FiltrosMonitoramentoJornada {

    idUnidade: number;
    listaDeMotoristas: any[];
    turno: string;
    horaInicio: Time;
    horaFim: Time;
    tempoAtualizar: number;

    constructor() {

    }
}