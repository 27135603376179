import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';
import { MatSort, MatTableDataSource, MatDatepicker, MatSelect, MatOption, MatTable } from '@angular/material';
import { DriverService } from 'app/services/crud/driver.service';
import { Driver } from 'app/classes/driver';
import { AuthGuard } from 'app/guards/auth.guard';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ReportService } from 'app/services/report/report.service';
import { UtilService } from 'app/services/utils.service';
import { utils, writeFile } from 'xlsx';
import { PDFService } from 'app/services/pdf.service';
import { VeiculoService } from 'app/services/crud/veiculo.service';
import { BranchService } from 'app/services/crud/branch.service';
import Swal from 'sweetalert2';
import { VehicleTypeService } from 'app/services/crud/vehicle-type.service';

@Component({
  selector: 'app-tempo-direcao',
  templateUrl: './tempo-direcao.component.html',
  styleUrls: ['./tempo-direcao.component.css']
})
export class TempoDirecaoComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public motoristaControl = new FormControl();
  public placasControl = new FormControl();
  public unidadesControl = new FormControl();
  public periodoInicial: Date;
  public periodoFinal: Date;
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[] = ['motorista', 'ibutton', 'unidade', 'placa', 'dataInicio', 'dataFim', 'tempoTotal'];
  public driverList: any[];
  public filteredDriverList: Driver[];
  public placasList: any[];
  public filteredPlacasList: any[];
  public unidadesList: any[];
  public filteredUnidadesList: any[];
  public originalDriverList: any[];
  public empresaId: number;
  public unidadeId: number;
  public unidadeName: string;
  public dataToExport: any;
  public showResult: boolean = false;
  public loading: boolean = false;
  public noResultMessage: boolean = false;
  public identificarIbutton: boolean = false;
  public identificarMotorista: boolean = false;
  public limiteTempoLigado: string;
  public filterVeiculosList = [];

  constructor (
    private driverService: DriverService, 
    private authGuard: AuthGuard, 
    private reportService: ReportService, 
    private utilService: UtilService, 
    private pdfService: PDFService, 
    private veiculoService: VeiculoService,
    private branchService: BranchService,
  ) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.unidadeId = this.authGuard.loggedUser().selectedBranch.id;
    this.empresaId = this.authGuard.loggedUser().selectedCompany.id;
    this.unidadeName = this.authGuard.loggedUser().selectedBranch.name;

    this.obterMotoristas();
    this.obterPlacas();
    this.obterUnidades();
  }

  async obterMotoristas() {
    const response = await this.driverService.getMotoristas();
    this.originalDriverList = response.response.map(item => ({ item_text: `${item.nome} ${item.sobrenome}`, item_id: item.id }));
    this.driverList = [ ...this.originalDriverList ];
  }

  async obterUnidades() {
    const response = await this.branchService.getList();
    this.unidadesList = response.response.map(item => ({ item_id: item.id, item_text: item.identificacao }));
    this.filteredUnidadesList = [ ... this.unidadesList ];
  }

  async obterPlacas() {
    const response = await this.veiculoService.getLista();
    this.placasList = response.listaDeVeiculos.map(item => ({ item_id: item.id, item_text: item.placa }));
    this.filteredPlacasList = [ ...this.placasList ];
  }

  selecionarTodos(matSelect: MatSelect) {
    const isSelected: boolean = matSelect.options
      .filter((item: MatOption) => item.value === 0)
      .map((item: MatOption) => item.selected)[0];

    if (isSelected) {
      matSelect.options.forEach((item: MatOption) => item.select());
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  exportExcel() {
    if (this.dataToExport) {
      const ws = utils.json_to_sheet(this.dataToExport);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'Tabela Tempo de Direção');
      return writeFile(wb, `TempoDireção${this.periodoInicial}/${this.periodoFinal}.xlsx`);
    }
  }

  createHeader(
    name: string,
    dataIndex?: number,
    canSort?: boolean,
    colspan?: number,
    css?: string,
    tooltip?: string
  ) {
    if (canSort == null) canSort = false;
    if (colspan == null) colspan = 1;
    if (dataIndex == null) dataIndex = -1;
    if (css == null) css = 'title-left';
    if (tooltip == null) tooltip = '';

    return {
      name: [name],
      canSort: canSort,
      colspan: colspan,
      dataIndex: dataIndex,
      css: css,
      tooltip: tooltip
    };
  }

  exportPrint() {
    if (this.dataToExport) {
      const data = this.dataToExport;
      const dataFields = Object.keys(this.dataToExport[0]).map((field) => ({
        name: field,
        prefix: '',
        separator: "",
        sufix: "",
        type: "string",
      }));
      const headers = [
        [
          this.createHeader("Motorista", 0, true),
          this.createHeader("Ibutton", 1, true),
          this.createHeader("Unidade", 2, true),
          this.createHeader("Placa", 3, true),
          this.createHeader("Data/Hora Início Ignição", 4, true),
          this.createHeader("Data/Hora Fim Ignição", 5, true),
          this.createHeader("Tempo Total Ligado", 6, true),
        ]
      ];
      const pageOrientation = 'portrait';
      this.pdfService.printList(
        data,
        dataFields,
        headers,
        pageOrientation
      );
    }
  }

  convertToSeconds(time: string) {
    if (!time) return null;
    const hours = Number(time.slice(0, 2));
    const minutes = Number(time.slice(2, 4));
    const totalSeconds = hours * 3600 + minutes * 60;
    return totalSeconds;
  }

  async doSearch() {
    if (!this.periodoInicial || !this.periodoFinal) {
      return Swal.fire({
        type: 'error',
        title: 'Erro',
        text: "Os campos 'Período Incial' e 'Período Final' são obrigatórios",
      });
    }
    this.noResultMessage = false;
    this.showResult = false;
    this.loading = true;
    const motoristas = this.motoristaControl.value ? this.motoristaControl.value.map((motorista) => { return motorista.item_id }).filter((motorist) => motorist) : [];
    const placas = this.placasControl.value ? this.placasControl.value.map(item => item.item_text) : [];
    const unidades = this.unidadesControl.value || [];
    const body = {
      empresaId: this.empresaId,
      listaMotorista: motoristas,
      listaUnidade: unidades,
      identificarIbutton: this.identificarIbutton,
      identificarMotorista: this.identificarMotorista,
      dataInicialStr: moment(this.periodoInicial).format("DDMMYYYYHHmmss"),
      dataFinalStr: moment(this.periodoFinal).format("DDMMYYYYHHmmss"),
      limiteTempoLigado: this.convertToSeconds(this.limiteTempoLigado) || 0,
      listaPlacas: placas
    };
    const bodyEncoded = encodeURI(JSON.stringify(body));
    this.reportService.ExportacaoTempoDirecao(bodyEncoded)
      .then(response => {
        const parsed = JSON.parse(response._body)
        const tableData = parsed.map((item) => {
          return {
            motorista: item.nomeMotorista,
            ibutton: item.ibutton || "",
            unidade: this.unidadeName,
            placa: item.placa,
            dataInicio: moment(item.dataHoraInicioIgnicao).format(`DD/MM/YYYY HH:mm`),
            dataFim: moment(item.dataHoraFimIgnicao).format(`DD/MM/YYYY HH:mm`),
            tempoTotal: this.utilService.secondsToHHmm(item.totalTempoLigado)
          }
        })
        this.dataSource = new MatTableDataSource(tableData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataToExport = tableData;
        if (tableData.length > 0) this.showResult = true;
        if (tableData.length === 0) this.noResultMessage = true;
        this.loading = false;
      }).catch(error => {
        this.loading = false;
      });
  }
}