import { indexOf } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { array } from '@amcharts/amcharts4/core';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Driver } from 'app/classes/driver';
import { FiltrosMonitoramentoJornada } from 'app/classes/filtro-monitoramento-jornada';
import { Turno } from 'app/classes/turno';
import { User } from 'app/classes/user';
import { AuthGuard } from 'app/guards/auth.guard';
import { ControleIndicadoresService } from 'app/services/crud/controle-indicadores.service';
import { DriverService } from 'app/services/crud/driver.service';
import { ShiftService } from 'app/services/crud/shift.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-filtro-monitoramento-jornada',
  templateUrl: './filtro-monitoramento-jornada.component.html',
  styleUrls: ['./filtro-monitoramento-jornada.component.scss']
})
export class FiltroMonitoramentoJornadaComponent implements OnInit, OnDestroy {

  @Input() admModel: string;
  @Output() admModelChange = new EventEmitter<string>();
  alterouModel(value: string) {
    this.admModelChange.emit(value);
  }

  @Output()
  public onPesquisar = new EventEmitter<any>();
  public retorno: any;

  dropdownList = [];
  dropdownSettings: {};
  unidadesCarregadas: any[];
  motoristasSelecionados = [];
  public idTurno = 0;
  public idUnidade = 0;
  turnosCarregados: Turno[];
  exibirFiltros: Boolean = true;
  loggedUser: User;
  _onDestroy = new Subject<void>();

  formFiltro = this.fb.group({
    idUnidade: [0],
    listaDeMotoristas: [0],
    turno: [''],
    horaInicio: [null],
    horaFim: [null],
    tempoAtualizar: [0]
  });

  lastFormValue: FiltrosMonitoramentoJornada = {
    listaDeMotoristas: []
    , turno: ''
    , idUnidade: 0
    , horaInicio: null
    , horaFim: null
    , tempoAtualizar: 0
  };

  public motoristas: Driver[];

  constructor(private authGuard: AuthGuard, private fb: FormBuilder, private controleIndicadoresService: ControleIndicadoresService, private driverService: DriverService
    , private shiftService: ShiftService) {
    this.carregarMotoristas()
  }

  filtroDefault: FiltrosMonitoramentoJornada = {
    listaDeMotoristas: this.motoristasSelecionados
    , idUnidade: 0
    , turno: ''
    , horaFim: null
    , horaInicio: null
    , tempoAtualizar: 0
  };

  ngOnInit() {
    this.pesquisar(this.filtroDefault);

    this.unidadesCarregadas = [];
    this.motoristasSelecionados = [];
    this.turnosCarregados = [];

    this.loggedUser = this.authGuard.loggedUser();

    this.obterUnidades();
    this.buscarTurnos();

    this.formFiltro.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe((value) => {
        this.lastFormValue = this.formFiltro.value;
      });
  }

  doPesquisar(filtro: FiltrosMonitoramentoJornada) {
    this.pesquisar(filtro);
  }

  pesquisarHtml(){
    try{
      this.pesquisar(this.filtroDefault);
    }catch(error){
      console.error(error);
    }
  }

  pesquisar(filtro: FiltrosMonitoramentoJornada) {  

    if (filtro == undefined)
      filtro = new FiltrosMonitoramentoJornada();

    filtro.listaDeMotoristas = [];
    filtro.idUnidade = this.formFiltro.value.idUnidade;
    filtro.turno = this.formFiltro.value.turno;
    filtro.horaInicio = this.formFiltro.value.horaInicio;
    filtro.horaFim = this.formFiltro.value.horaFim;

    this.motoristasSelecionados.forEach(function (motorista) {
      filtro.listaDeMotoristas.push(motorista.item_id);
    });

    this.controleIndicadoresService.buscarIndicadoresFiltradosMonitoramentoJornada(filtro).then(response => {
      this.retorno = response;

      if (filtro.turno != '') {
        this.retorno.response.totalFaltaFolgaDsr = this.retorno.response.totalFaltaFolgaDsr.filter(t => t.turno == filtro.turno);
        this.retorno.response.totalDeParadas = this.retorno.response.totalDeParadas.filter(t => t.turno == filtro.turno);
        this.retorno.response.totalDentroDaJornadaPadrao = this.retorno.response.totalDentroDaJornadaPadrao.filter(t => t.turno == filtro.turno);
        this.retorno.response.totalJornadaExtrapolada = this.retorno.response.totalJornadaExtrapolada.filter(t => t.turno == filtro.turno);
        this.retorno.response.listaDeJornadaAbertas = this.retorno.response.listaDeJornadaAbertas.filter(t => t.turno == filtro.turno);
      }

      if (filtro.idUnidade != 0) {
        // this.retorno.response.totalFaltaFolgaDsr = this.retorno.response.totalFaltaFolgaDsr.filter(t => t.turno == filtro.turno);
        // this.retorno.response.totalDeParadas = this.retorno.response.totalDeParadas.filter(t => t.turno == filtro.turno);
        // this.retorno.response.totalDentroDaJornadaPadrao = this.retorno.response.totalDentroDaJornadaPadrao.filter(t => t.turno == filtro.turno);
        // this.retorno.response.totalJornadaExtrapolada = this.retorno.response.totalJornadaExtrapolada.filter(t => t.turno == filtro.turno);
        // this.retorno.response.listaDeJornadaAbertas = this.retorno.response.listaDeJornadaAbertas.filter(t => t.turno == filtro.turno);
      }

      if (filtro.horaInicio || filtro.horaFim) {
        this.retorno.response.listaDeJornadaAbertas.forEach(jornadaAberta => {

          if (jornadaAberta.listaEventoJornada.length > 0) {
            var contemInicio = jornadaAberta.listaEventoJornada.filter(eventoJornada => eventoJornada.dataInicialMilesegundos >= filtro.horaInicio).length > 0;
            var contemFim = filtro.horaFim == null || jornadaAberta.listaEventoJornada[jornadaAberta.listaEventoJornada.length - 1].dataFinalMilesegundos <= filtro.horaFim;

            if (!contemInicio || !contemFim)
              jornadaAberta.listaEventoJornada = [];
          }
        });

        this.retorno.response.listaDeJornadaAbertas = this.retorno.response.listaDeJornadaAbertas.filter(jornadaAberta => jornadaAberta.listaEventoJornada.length > 0);
      }


      this.alterouModel(this.retorno);
    });
  }

  setarTempoDeAtualizacao() {
    setInterval(() => {
      $('#btnPesquisar').click();
    }, this.formFiltro.value.tempoAtualizar * 60000)
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  obterUnidades() {
    if (this.loggedUser.selectedCompany) {
      this.unidadesCarregadas = this.loggedUser.branchMap[this.loggedUser.selectedCompany.name];
    }
  }

  buscarTurnos() {
    var ret: Promise<any> = this.shiftService.getList();
    ret.then(res => {
      this.turnosCarregados = res.response;
    })
  }

  carregarMotoristas() {
    try {
      this.driverService.getListMinimo().then(response => {
        this.motoristas = response.response.filter(m => m.situacao == 1);
        this.dropdownList = [];
        this.motoristasSelecionados = [];
        for (var i = 0; i < this.motoristas.length; i++) {
          let l = this.motoristas[i];
          this.dropdownList.push({ item_id: l.id, item_text: l.nome + ' ' + l.sobrenome });
        }
        this.dropdownSettings = {
          singleSelection: false,
          idField: 'item_id',
          textField: 'item_text',
          selectAllText: 'Todos',
          unSelectAllText: 'Desmarcar todos',
          itemsShowLimit: 3,
          allowSearchFilter: true,
          searchPlaceholderText: 'Pesquisar'
        };
      });
    } catch (error) {
      console.log(error);
    }
  }

  onItemSelect(item: any) {
    this.motoristasSelecionados.push(item);
    console.log(this.motoristasSelecionados.length);
  }

  onSelectAll(items: any) {
    this.motoristasSelecionados = [];
    this.motoristasSelecionados = items;
    console.log(this.motoristasSelecionados.length);
  }

  onDeSelectAll(items: any) {
    this.motoristasSelecionados = [];
    console.log(this.motoristasSelecionados.length);
  }
  onDeSelect(item: any) {
    let index = this.motoristasSelecionados.findIndex(v => v.item_id == item.item_id);
    this.motoristasSelecionados.splice(index, 1);
    console.log(this.motoristasSelecionados.length);
  }

  escodeOuExibeDiv() {
    this.exibirFiltros = !this.exibirFiltros;
  }
}
