import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-modal-confirm-delete',
  templateUrl: './modal-confirm-delete.component.html',
  styleUrls: ['./modal-confirm-delete.component.css']
})
export class ModalConfirmDeleteComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ModalConfirmDeleteComponent>) {}

  close(confirmed: boolean): void {
    this.dialogRef.close(confirmed);
  }

  ngOnInit(): void {

  }

}
